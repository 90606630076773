import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Button,
  Avatar,
  Fade,
  Container,
} from "@material-ui/core";
import {
  useParams,
  useHistory,
  useLocation,
  Route,
  Switch,
} from "react-router-dom";
import SearchGroup from "../Search/SearchGroup";
import { useQuery, useMutation } from "@apollo/client";
import GroupMetaBadge from "./GroupMetaBadge";
import GroupActionButtons from "./GroupActionButtons";
import CreateInvitationForm from "./CreateInvitationForm";
import { useDispatch } from "react-redux";
import { actionTypes } from "../index.reducer";
import { GET_GROUP } from "../../consts/queries";
import { JOIN_GROUP, ACCEPT_GROUP_INVITATION } from "../../consts/mutations";
import ListingsSearchController from "../Search/ListingsSearchController";
import Hyperlink from "../Common/Hyperlink";
import { getUserFullName } from "../../utils/common";
import PageHead from "../Common/PageHead";
import GroupSidebarTab from "./GroupSidebarTab";
import BookRequestsSearchController from "../Requests/BookRequestsSearchController";
import ErrorPage from "../Error/ErrorPage";
import BooksIcon from "@material-ui/icons/Book";
import BookRequestIcon from "../Requests/BookRequestIcon";
import GroupDiscussions from "../Discussions/GroupDiscussions";
import Discussion from "../Discussions/Discussion";
import BookChatIcon from "../Discussions/BookChatIcon";
import { useLoader, useLoggedInUser, useScrollTop } from "../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  flyer: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      borderRadius: 0,
    },
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  groupAttrIcon: {
    marginRight: theme.spacing(1),
  },
  groupAttrs: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  banner: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.primary.main,
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      borderRadius: 0,
    },
    marginBottom: theme.spacing(2),
  },
  logo: {
    height: "150px",
    width: "150px",
    objectFit: "contain",
    border: "5px solid white",
    backgroundColor: "white",
  },
  logoContainer: {
    justifyContent: "center",
    height: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  headingInnerContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  imageContainer: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: 100,
    height: "150px",
    width: "150px",
  },
  searchGroup: {
    paddingTop: theme.spacing(3),
    background: theme.palette.gradient.light,
    paddingBottom: "20px",
  },
  searchController: {
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
    paddingTop: theme.spacing(2),
  },
  groupName: {
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      textAlign: "center",
    },
  },
  groupDescription: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      textAlign: "center",
    },
    fontSize: 16,
  },
  groupOwner: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  ownerLabel: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.grey[700],
  },
  ownerHyperlink: {
    color: theme.palette.primary.main,
  },
  sidebar: {
    [theme.breakpoints.up("md")]: {
      borderRight: `1px solid ${theme.palette.primary.light}`,
    },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: theme.spacing(2),
    },
  },
  tabsContainer: {
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
}));

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export default function Group() {
  const classes = useStyles();
  const { groupId, invitationKey } = useParams();
  const queryParams = useQueryParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInUser = useLoggedInUser();
  const { loading, error, data } = useQuery(GET_GROUP, {
    variables: { groupId, invitationKey },
  });
  const [showInviteForm, setShowInviteForm] = useState(false);
  const [joinGroup, { loading: joiningGroup }] = useMutation(JOIN_GROUP, {
    refetchQueries: [{ query: GET_GROUP, variables: { groupId } }],
  });
  const [acceptInvitation, { loading: acceptingInvitation }] = useMutation(
    ACCEPT_GROUP_INVITATION,
    {
      refetchQueries: [{ query: GET_GROUP, variables: { groupId } }],
    }
  );

  useScrollTop();

  const sidebarTabs = [
    {
      title: "Books",
      path: `/group/${groupId}`,
      pathMatchRegex: new RegExp(`^/group/${groupId}(/listings)?$`),
      icon: <BooksIcon />,
    },
    {
      title: "Book Requests",
      path: `/group/${groupId}/book-requests`,
      pathMatchRegex: new RegExp(`^/group/${groupId}/book-requests`),
      icon: <BookRequestIcon />,
    },
    {
      title: "BookChat",
      path: `/group/${groupId}/bookchats`,
      pathMatchRegex: new RegExp(
        `^/group/${groupId}/(bookchats|(bookchat/.+))`
      ),
      icon: <BookChatIcon />,
    },
  ];

  const dispatchErrorMessage = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });

  useEffect(() => {
    if (invitationKey && !loggedInUser) {
      const { pathname, search } = history.location;
      return history.push("/login", { redirectPath: `${pathname}${search}` });
    }
  }, [invitationKey, history, loggedInUser]);

  useLoader(loading || joiningGroup || acceptingInvitation);

  if (error) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the group you were looking for. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  if (!data) return null;
  const { group } = data;

  if (!group) {
    return (
      <ErrorPage
        title="Unavailable"
        description="This group you were looking for does not exist!"
        statusCode={404}
      />
    );
  }
  const onClickJoinGroup = (groupId) => () => {
    const { pathname, search } = history.location;
    if (!loggedInUser)
      return history.push("/login", { redirectPath: `${pathname}${search}` });
    joinGroup({
      variables: { groupId },
    })
      .then(({ data }) => {
        if (!data?.joinGroup.isCurrentUserAMember) {
          dispatchErrorMessage(
            "An error occurred while joining the group. Please try again!"
          );
        }
      })
      .catch(() => {
        dispatchErrorMessage(
          "An error occurred while joining the group. Please try again!"
        );
      });
  };

  const onClickAcceptInvitation = () => {
    acceptInvitation({
      variables: { invitationKey },
    })
      .then(({ data: invitationData }) => {
        if (invitationData?.acceptGroupInvitation) {
          history.push(`/group/${group.id}`);
        } else {
          dispatchErrorMessage(
            "An error occurred while accepting the invitation. Please try later!"
          );
        }
      })
      .catch(() => {
        dispatchErrorMessage(
          "An error occurred while accepting the invitation. Please try later!"
        );
      });
  };

  const isPublicGroup = group.visibility === "PUBLIC";
  const showVisibleGroupBanner =
    (isPublicGroup || group.isCurrentUserOwner) && !group.isCurrentUserAMember;

  const enableActions = group.isCurrentUserAMember;
  const showInvitationBanner = !group.isCurrentUserAMember && !!invitationKey;
  const disableSearchResults = !isPublicGroup && !group.isCurrentUserAMember;

  const onClickInvite = () => setShowInviteForm(true);
  const onLeaveGroup = () => {
    if (!isPublicGroup && !group.isCurrentUserOwner) history.push("/listings");
  };
  const onDeleteGroup = () => history.push("/groups");

  let visibleGroupBannerText = "";
  if (isPublicGroup) {
    visibleGroupBannerText =
      "This is a public group! You can join to get access to the full content of the group, and interact with the group members.";
  } else {
    visibleGroupBannerText =
      "This is a private group created by you. You can join back to be able to interact with the group members";
  }
  const visibleGroupBanner = (
    <Grid container className={classes.banner}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Typography variant="body1" align="center">
                {visibleGroupBannerText}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Button
                disableElevation
                disabled={joiningGroup}
                variant="contained"
                color="primary"
                onClick={onClickJoinGroup(group.id)}
              >
                Join Group
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const invitationBanner = (
    <Grid container className={classes.banner} spacing={2}>
      <Grid container item xs={12} justifyContent="center">
        <Typography variant="body1" align="center">
          Welcome to {group.name}! You have been invited to this group. If you
          accept the invitation, you will be added to the group and will be able
          to view/share books in the group.
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Button
          disabled={acceptingInvitation}
          variant="contained"
          color="primary"
          onClick={onClickAcceptInvitation}
        >
          Accept Invitation
        </Button>
      </Grid>
    </Grid>
  );

  const isCurrentPathMatches = (targetPathRegex) => {
    const currentPath = history.location.pathname;
    return targetPathRegex.test(currentPath);
  };

  return (
    <>
      <PageHead title={`${group.name} | bibliocircle`} />
      {group.isCurrentUserAMember ? (
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          className={classes.searchGroup}
        >
          <SearchGroup
            groupScope={group.id}
            placeholder={`Search books in ${group.name}`}
          />
        </Grid>
      ) : null}
      <CreateInvitationForm
        open={showInviteForm}
        onClose={() => setShowInviteForm(false)}
        groupId={group.id}
      />
      <Container maxWidth="xl">
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Grid container>
              <Grid container className={classes.flyer}>
                <Grid item>
                  <Grid
                    container
                    spacing={3}
                    className={classes.headingInnerContainer}
                  >
                    <Grid item>
                      <Grid
                        container
                        className={classes.logoContainer}
                        alignItems="center"
                      >
                        <Grid item className={classes.imageContainer}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Fade in>
                              <Avatar
                                src={
                                  group.logoImage || "/group-placeholder.svg"
                                }
                                className={classes.logo}
                              />
                            </Fade>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={8}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Typography
                                variant="h4"
                                className={classes.groupName}
                                color="textPrimary"
                              >
                                {group.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                className={classes.groupDescription}
                              >
                                {group.description}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container className={classes.groupOwner}>
                                <Grid item className={classes.ownerLabel}>
                                  <Typography variant="body2">
                                    Created by
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Hyperlink
                                    to={`/profile/${group.owner?.id}`}
                                    className={classes.ownerHyperlink}
                                  >
                                    <Typography variant="body2">
                                      {getUserFullName(group.owner)}
                                    </Typography>
                                  </Hyperlink>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.groupAttrs}
                        >
                          <Grid item>
                            <GroupMetaBadge
                              group={group}
                              className={classes.groupMetaBadge}
                            />
                          </Grid>
                        </Grid>
                        {enableActions ? (
                          <Grid container item xs={12}>
                            <GroupActionButtons
                              group={group}
                              onClickInvite={onClickInvite}
                              onLeaveGroup={onLeaveGroup}
                              onDeleteGroup={onDeleteGroup}
                            />
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {showVisibleGroupBanner ? visibleGroupBanner : null}
              {showInvitationBanner ? invitationBanner : null}
            </Grid>
          </Grid>
          {disableSearchResults ? null : (
            <Grid item xs={11} className={classes.searchController}>
              <Grid container spacing={4} alignItems="flex-start">
                <Grid item xs={12} md={2} className={classes.sidebar}>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    className={classes.tabsContainer}
                  >
                    {sidebarTabs.map((tabData) => (
                      <Grid item key={tabData.path}>
                        <GroupSidebarTab
                          title={tabData.title}
                          icon={tabData.icon}
                          to={tabData.path}
                          selected={isCurrentPathMatches(
                            tabData.pathMatchRegex
                          )}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Switch>
                    <Route
                      path="/group/:groupId/book-requests"
                      render={(props) => (
                        <BookRequestsSearchController
                          key={props.location.key}
                          searchArgs={{
                            groupScope: groupId,
                            query: queryParams.get("q"),
                            isbn: queryParams.get("isbn"),
                            location: queryParams.get("location"),
                            language: queryParams.get("language"),
                            priceModelType: queryParams.get("priceModelType"),
                            pagination: {
                              expiryDateOrder:
                                queryParams.get("expiryDateOrder"),
                            },
                          }}
                          noResultsMessage="No one has requested any books yet"
                        />
                      )}
                    />
                    <Route
                      path="/group/:groupId/bookchats"
                      render={(props) => (
                        <GroupDiscussions key={props.location.key} />
                      )}
                    />
                    <Route
                      path="/group/:groupId/bookchat/:discussionId"
                      render={(props) => (
                        <Grid container key={props.location.key}>
                          <Grid item xs={12} md={10}>
                            <Discussion />
                          </Grid>
                        </Grid>
                      )}
                    />
                    <Route
                      path="/group/:groupId"
                      render={(props) => (
                        <ListingsSearchController
                          key={props.location.key}
                          searchArgs={{
                            query: queryParams.get("q"),
                            location: queryParams.get("location"),
                            category: queryParams.get("category"),
                            priceModelType: queryParams.get("priceModelType"),
                            groupScope: groupId,
                            language: queryParams.get("language"),
                            minPrice: queryParams.get("minPrice"),
                            maxPrice: queryParams.get("maxPrice"),
                          }}
                          noResultsMessage="No one has shared any books yet"
                          showEndOfResultsBanner={false}
                          xs={12}
                          md={6}
                          lg={6}
                          xl={4}
                        />
                      )}
                    />
                    <Route>
                      <ErrorPage
                        title="Not Found"
                        statusCode={404}
                        description="The page you are looking for could not be found in this group!"
                      />
                    </Route>
                  </Switch>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}
