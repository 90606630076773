import { t } from "@lingui/macro";
import { PLACEHOLDER_IMAGES, priceModelTypes } from "../../consts";
import { createFeedPostDataExtractor } from "./transformerUtils";

export default function UserCreatedAdvertisementTransformer(feedPost) {
  const extractor = createFeedPostDataExtractor(feedPost.primaryContent?.data);
  const isShop = extractor.get("isShop") === "1";

  let activityText;
  switch (extractor.get("priceModelType")) {
    case priceModelTypes.SALE:
      activityText =
        +extractor.get("price") > 0
          ? t`posted an item for sale`
          : t`is giving away an item for free 🎁`;
      break;
    case priceModelTypes.RENT:
      activityText = t`posted an item for rent`;
      break;
    case priceModelTypes.EXCHANGE:
      activityText = t`is looking to exchange an item`;
      break;
    default:
      activityText = t`posted an item`;
  }

  return {
    actorFullName: feedPost.actor.name,
    actorImage: feedPost.actor.image || PLACEHOLDER_IMAGES.USER,
    actorPath: isShop
      ? `/shop/${extractor.get("shopIdentifier")}`
      : `/profile/${feedPost.actor.id}`,
    activityText,
    resourcePath: `/listing/${extractor.get("listingId")}`,
    primaryContent: {
      title: extractor.get("listingTitle"),
      content: extractor.get("listingDescription"),
      images: feedPost.primaryContent?.images || [],
    },
    timestamp: feedPost.activityTimestamp,
  };
}
