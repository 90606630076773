import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    border: "none",
    backgroundColor: theme.palette.grey[100],
    width: "100%",
    marginBottom: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: 0,
  },
  bookRequests: {
    paddingRight: "5px",
  },
  bookRequestCard: {
    marginBottom: theme.spacing(2),
  },
}));

export function BookRequestsCardLoader() {
  const classes = useStyles();
  return (
    <div className={`ph-item ${classes.item}`}>
      <div>
        <div className="ph-row">
          <div className="ph-col-8 big"></div>
          <div className="ph-col-6"></div>
          <div className="ph-col-12"></div>
        </div>
      </div>
    </div>
  );
}

export function BookRequestsLoader() {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="flex-start"
      className={classes.bookRequests}
    >
      <Grid item xs={12} className={classes.bookRequestCard}>
        <BookRequestsCardLoader />
      </Grid>
      <Grid item xs={12} className={classes.bookRequestCard}>
        <BookRequestsCardLoader />
      </Grid>
      <Grid item xs={12} className={classes.bookRequestCard}>
        <BookRequestsCardLoader />
      </Grid>
    </Grid>
  );
}
