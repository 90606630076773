import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {
  Grid,
  Typography,
  makeStyles,
  useTheme,
  Button,
} from "@material-ui/core";
import OrderItem from "./OutgoingOrderItem";
import Address from "../../Common/Addresses/Address";
import PhoneNumber from "../../Common/PhoneNumbers/PhoneNumber";
import ConfirmPrompt from "../../Common/ConfirmPrompt";
import PriceTag from "../../Common/PriceTag";
import StoreIcon from "@material-ui/icons/Store";
import { green, orange } from "@material-ui/core/colors";
import { useMutation } from "@apollo/client";
import { CANCEL_ORDER } from "../../../consts/mutations";
import { useDispatch } from "react-redux";
import { actionTypes } from "../../index.reducer";
import Hyperlink from "../../Common/Hyperlink";
import { orderStatuses } from "../../consts";
import Banner from "../../Common/Banner";
import { useLoader } from "../../../utils/hooks";
import { Trans } from "@lingui/macro";
import { getUserFullName } from "../../../utils/common";

const { OPEN, ACCEPTED, READY_FOR_PICKUP, DISPATCHED } = orderStatuses;

const useStyles = makeStyles((theme) => ({
  orderHeading: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2),
    borderRadius: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 0`,
  },
  orderRecipientLink: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  sidebar: {
    backgroundColor: theme.palette.grey[100],
  },
  statusTitle: {
    fontWeight: "bold",
  },
  statusDescription: {},
  statusContainer: {},
  orderCard: {
    backgroundColor: theme.palette.grey[100],
    paddingBottom: theme.spacing(4),
    borderRadius: theme.spacing(2),
    border: "1px solid",
    borderColor: theme.palette.grey[200],
  },
  orderContainer: {
    padding: theme.spacing(3),
  },
  cancelOrderButtonContainer: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      marginBottom: theme.spacing(2),
    },
  },
  desktopOnly: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  individualSaleBanner: {
    color: orange[900],
  },
}));

export default function OutgoingOrderCard({ order, showMessageThread }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isIndividualSale = !order.shop && order.seller;
  const [cancelOrderDecisionPending, setCancelOrderDecisionPending] =
    useState(false);
  const [cancelOrder, { loading: cancellingOrder }] = useMutation(CANCEL_ORDER);
  useLoader(cancellingOrder);

  const openCancelOrderPrompt = () => setCancelOrderDecisionPending(true);
  const closeCancelOrderPrompt = () => setCancelOrderDecisionPending(false);

  const handleCancelOrder = (cancelOrderConfirmed) => {
    closeCancelOrderPrompt();
    if (!cancelOrderConfirmed) return;
    cancelOrder({
      variables: { id: order.id },
    })
      .then(({ data }) => {
        if (data?.cancelOrder) {
          dispatch({
            type: actionTypes.INFO_MESSAGE,
            message: "Successfully cancelled the order",
          });
        } else {
          dispatch({
            type: actionTypes.ERROR_MESSAGE,
            message:
              "Something went wrong while cancelling the order! Please try again later",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ERROR_MESSAGE,
          message: "Failed to cancel order! Please try again later",
        });
      });
  };

  const getFormattedOrderStatus = (orderStatus) => {
    switch (orderStatus) {
      case "OPEN":
        return {
          title: "Pending",
          description: "Waiting for seller to accept this order",
          color: theme.palette.primary.main,
        };
      case "ACCEPTED":
        return {
          title: "Accepted",
          description: "Seller accepted the order. Processing.",
          color: green[800],
        };
      case "REJECTED":
        return {
          title: "Rejected",
          description:
            "Seller is unable to serve this order at the moment. Please contact the seller directly for more information.",
          color: theme.palette.secondary.main,
        };
      case "DISPATCHED":
        return {
          title: "On the way!",
          description:
            "Your order has been dispatched and is on the way to your delivery address",
          color: green[800],
        };
      case "DELIVERED":
        return {
          title: "Delivered",
          description: "Your order is delivered",
          color: green[800],
        };
      case "READY_FOR_PICKUP":
        return {
          title: "Ready for Pick-up",
          description: "Your order is ready for pick-up",
          color: green[800],
        };
      case "CANCELLED":
        return {
          title: "Cancelled",
          description: "This order is canceled",
          color: theme.palette.grey[700],
        };
      default:
        return {
          title: "Unknown",
          description:
            "This order status is unknown. Please contact support if you believe this is a mistake.",
          color: theme.palette.secondary.main,
        };
    }
  };

  const status = getFormattedOrderStatus(order.status);
  const { userContactNumbers, deliveryPreference } = order;
  const contactNumbers = userContactNumbers?.length ? userContactNumbers : [];
  const deliveryPrefType = deliveryPreference?.type;
  const isPickUp = deliveryPrefType === "PICK_UP_AT_STORE";
  const isDelivery = deliveryPrefType === "HOME_DELIVERY";
  const enableCancelOrder = [OPEN, ACCEPTED, READY_FOR_PICKUP].includes(
    order.status
  );
  const isOrderAwaitingDelivery = [
    OPEN,
    ACCEPTED,
    DISPATCHED,
    READY_FOR_PICKUP,
  ].includes(order.status);

  return (
    <Grid container className={classes.orderCard}>
      <Grid item xs={12} className={classes.orderHeading}>
        <Grid container spacing={1}>
          <Grid item>
            {order.shop ? (
              <Typography className={classes.heading} variant="subtitle2">
                Order from{" "}
                <Hyperlink
                  className={classes.orderRecipientLink}
                  target="_blank"
                  to={`/shop/${order.shop.identifier}`}
                >
                  {order.shop.name}
                </Hyperlink>
              </Typography>
            ) : (
              <Typography className={classes.heading} variant="subtitle2">
                Order from{" "}
                <Hyperlink
                  className={classes.orderRecipientLink}
                  target="_blank"
                  to={`/profile/${order.seller.id}`}
                >
                  {getUserFullName(order.seller)}
                </Hyperlink>
              </Typography>
            )}
          </Grid>
          <Grid item className={classes.desktopOnly}>
            &bull;
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">
              Order ID{" "}
              <Hyperlink
                className={classes.orderRecipientLink}
                target="_blank"
                to={`/order/${order.id}`}
              >
                # {(order.id || "").toUpperCase()}
              </Hyperlink>
            </Typography>
          </Grid>
          {isIndividualSale && (
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                className={classes.individualSaleBanner}
              >
                <Typography variant="body2">
                  <Trans>
                    Items in this order are sold by an individual seller. Please
                    contact them to arrange the payment and the delivery of the
                    items.
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        className={classes.orderContainer}
        direction="row-reverse"
      >
        <Grid
          container
          item
          xs={12}
          lg={4}
          alignContent="flex-start"
          className={classes.sidebar}
        >
          <Grid container item xs={12}>
            <Grid container spacing={3} justifyContent="center">
              <Grid container item xs={12} className={classes.statusContainer}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.statusTitle}
                    style={{ color: status.color }}
                    variant="h6"
                    align="center"
                  >
                    {status.title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className={classes.statusDescription}
                    style={{ color: status.color }}
                    variant="body2"
                    align="center"
                  >
                    {status.description}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} lg={10}>
                <PriceTag price={order.totalValue} label="Order Value" />
              </Grid>
              {isOrderAwaitingDelivery && (
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item xs={10}>
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        align="center"
                      >
                        <Trans>
                          Please contact the seller regarding the payment
                          methods they accept. (e.g, cash on delivery/bank
                          deposit &amp; etc.)
                        </Trans>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {order.shop && isDelivery && !order.deliveryAddress && (
                <Grid item xs={12}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={10}>
                      <Banner
                        type="warning"
                        message="This order is a delivery, but it seems that the delivery address is either removed from your profile or is not specified. Please contact the seller to let them know if the delivery address should be changed."
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {order.deliveryAddress && (
                <Grid item xs={12}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={10}>
                      <Typography variant="body2" align="center">
                        Delivery Address
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Address address={order.deliveryAddress} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {order.shop && isPickUp && (
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid
                      container
                      item
                      xs={10}
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                    >
                      <StoreIcon />
                      <Typography variant="subtitle2" align="center">
                        This order needs to be picked up at the store
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!!contactNumbers.length && (
                <Grid item xs={12}>
                  <Grid spacing={2} container justifyContent="center">
                    <Grid item xs={10}>
                      <Typography variant="body2" align="center">
                        How seller can contact you
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container justifyContent="center" spacing={2}>
                            {contactNumbers.map((contactNumber) => (
                              <Grid key={contactNumber.id} item xs={10}>
                                <PhoneNumber
                                  phoneNumber={contactNumber.phoneNumber}
                                  justifyContent="center"
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!showMessageThread && (
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Hyperlink to={`/order/${order.id}`}>
                        <Typography variant="subtitle2" color="primary">
                          Messages ({order.messagesCount || 0})
                        </Typography>
                      </Hyperlink>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography variant="body2" align="center">
                  Order placed on{" "}
                  {dayjs(order.createdAt).format("MMMM D, YYYY [at] h:mm A")}
                </Typography>
              </Grid>
              {enableCancelOrder && (
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  className={classes.cancelOrderButtonContainer}
                >
                  <ConfirmPrompt
                    open={cancelOrderDecisionPending}
                    title="Cancel Order"
                    onClose={closeCancelOrderPrompt}
                    question="Are you sure you want to cancel this order?"
                    onAction={handleCancelOrder}
                  />
                  <Button
                    size="large"
                    variant="contained"
                    disableElevation
                    onClick={openCancelOrderPrompt}
                    disabled={cancellingOrder}
                  >
                    Cancel order
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8}>
          {order.items.map((item) => (
            <OrderItem
              key={item.listing.id}
              orderId={order.id}
              item={item.listing}
              quantity={item.quantity}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

OutgoingOrderCard.propTypes = {
  order: PropTypes.object,
  showMessageThread: PropTypes.bool,
};
