import React from "react";
import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import LandingPage from "./LandingPage";
import { useHistory } from "react-router-dom";
import PageHead from "../Application/Common/PageHead";

const useStyles = makeStyles((theme) => ({
  inputField: {
    backgroundColor: "white",
    width: "100%",
    marginBottom: "10px",
  },
  contentHeading: {
    marginBottom: "5vh",
    marginTop: "10vh",
    fontWeight: "bold",
  },
  contentDescription: {
    padding: theme.spacing(3),
  },
  plainLink: {
    color: "white",
    textDecoration: "none",
  },
  actionButtonContainer: {
    margin: theme.spacing(10),
  },
  goBackToLoginButton: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.getContrastText(theme.palette.grey[800]),
    border: "none",
  },
}));

function ResetPasswordConfirmation() {
  const classes = useStyles();
  const history = useHistory();

  const goToLogin = () => history.push("/login");
  return (
    <LandingPage coverImage="/mailbox.svg">
      <PageHead title="Reset Password | bibliocircle" />
      <Grid container justifyContent="flex-start">
        <Grid item container justifyContent="center">
          <Grid item xs={10}>
            <Typography
              variant="h3"
              align="center"
              className={classes.contentHeading}
            >
              Email on the way!
            </Typography>
          </Grid>
        </Grid>
        <Grid item container justifyContent="center">
          <Grid container xs={10} md={12}>
            <Typography
              align="center"
              variant="h6"
              className={classes.contentDescription}
            >
              We sent you an email with the instructions to reset your password.
              Please check your inbox, as well as your junk/spam folder in case
              if you couldn't find the email in the inbox.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          className={classes.actionButtonContainer}
        >
          <Button
            className={classes.goBackToLoginButton}
            variant="contained"
            size="large"
            onClick={goToLogin}
            disableElevation
          >
            Go Back To Login
          </Button>
        </Grid>
      </Grid>
    </LandingPage>
  );
}

export default ResetPasswordConfirmation;
