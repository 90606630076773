import { Grid, makeStyles } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => {
  const arrowButton_base = {
    margin: theme.spacing(0.5),
  };

  return {
    arrowButton_default: {
      ...arrowButton_base,
      color: theme.palette.primary.main,
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.darker,
      },
    },
    arrowButton_disabled: {
      ...arrowButton_base,
      color: theme.palette.grey[500],
    },
  };
});

export default function ReorderButtons({
  isStart,
  isEnd,
  onMoveUp,
  onMoveDown,
}) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <ArrowDropUp
        onClick={!isStart && onMoveUp}
        className={
          isStart ? classes.arrowButton_disabled : classes.arrowButton_default
        }
      />
      <ArrowDropDown
        onClick={!isEnd && onMoveDown}
        className={
          isEnd ? classes.arrowButton_disabled : classes.arrowButton_default
        }
      />
    </Grid>
  );
}

ReorderButtons.propTypes = {
  isStart: PropTypes.bool.isRequired,
  isEnd: PropTypes.bool.isRequired,
  onMoveUp: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
};
