import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  makeStyles,
  IconButton,
  InputAdornment,
  Paper,
  Button,
  Collapse,
} from "@material-ui/core";
import BootstrapInput from "../Common/BootstrapInput";
import DropDown from "../Common/DropDown";
import { useHistory } from "react-router-dom";
import * as consts from "../consts";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PriceRangeDropDown from "./PriceRangeDropDown";
import { Trans, t } from "@lingui/macro";

const locations = [
  {
    default: true,
    label: <Trans>All Sri Lanka</Trans>,
    value: 0,
  },
  ...consts.locations,
];
const categories = [
  {
    default: true,
    label: <Trans>Any Category</Trans>,
    value: 0,
  },
  ...consts.categories,
];
const priceModelTypes = [
  {
    default: true,
    label: <Trans>Sale/Rent/Exchange</Trans>,
    value: 0,
  },
  ...consts.searchGroupPriceModelTypes,
];
const conditions = [
  {
    default: true,
    label: <Trans>New/Used</Trans>,
    value: 0,
  },
  ...consts.bookConditions,
];
const languages = [
  {
    default: true,
    label: <Trans>Any Language</Trans>,
    value: 0,
  },
  ...consts.languages,
];

const useStyles = makeStyles((theme) => ({
  searchButton: {
    marginLeft: "20px",
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.getContrastText(theme.palette.grey[800]),
  },
  searchFiltersGroup: {
    color: theme.palette.text.primary,
  },
  searchGroupContainer: {
    maxWidth: "2000px",
  },
  searchFiltersButtonContainer: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-start",
    },
  },
  filtersContainer: {
    paddingTop: theme.spacing(2),
  },
  collapsibleContainer: {
    width: "100%",
  },
}));

const DEFAULT_MIN_PRICE = 0;
const DEFAULT_MAX_PRICE = 20000;

const isNumericParam = (str) => /^[0-9]+$/.test(str || "");

export default function SearchGroup({
  groupScope,
  shopScope,
  listingCollectionScope,
  placeholder,
  filterPanelOpen,
}) {
  const history = useHistory();
  const classes = useStyles();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [locationFilter, setLocationFilter] = useState(0);
  const [categoryFilter, setCategoryFilter] = useState(0);
  const [priceRangeFilter, setPriceRangeFilter] = useState({
    min: DEFAULT_MIN_PRICE,
    max: DEFAULT_MAX_PRICE,
  });
  const [priceModelTypeFilter, setPriceModelTypeFilter] = useState(0);
  const [conditionFilter, setConditionFilter] = useState(0);
  const [languageFilter, setLanguageFilter] = useState(0);

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location?.search);
    setSearchQuery(searchParams.get("q"));
    setLocationFilter(searchParams.get("location") || 0);
    setCategoryFilter(searchParams.get("category") || 0);
    setPriceModelTypeFilter(searchParams.get("priceModelType") || 0);
    setConditionFilter(searchParams.get("condition") || 0);
    setLanguageFilter(searchParams.get("language") || 0);

    const minPriceParam = isNumericParam(searchParams.get("minPrice"))
      ? +searchParams.get("minPrice")
      : DEFAULT_MIN_PRICE;
    const maxPriceParam = isNumericParam(searchParams.get("maxPrice"))
      ? +searchParams.get("maxPrice")
      : DEFAULT_MAX_PRICE;

    setPriceRangeFilter({ min: minPriceParam, max: maxPriceParam });
  }, [history.location]);

  useEffect(() => {
    setFiltersOpen(filterPanelOpen);
  }, [filterPanelOpen]);

  const toggleFilters = () => setFiltersOpen((isOpen) => !isOpen);

  const doSearch = () => {
    const queryParams = {
      minPrice: priceRangeFilter.min,
      maxPrice: priceRangeFilter.max,
    };
    if (searchQuery) queryParams.q = searchQuery.trim();
    if (locationFilter) queryParams.location = locationFilter;
    if (priceModelTypeFilter) queryParams.priceModelType = priceModelTypeFilter;
    if (conditionFilter) queryParams.condition = conditionFilter;
    if (categoryFilter) queryParams.category = categoryFilter;
    if (languageFilter) queryParams.language = languageFilter;

    const queryString = new URLSearchParams(queryParams).toString();
    if (groupScope) {
      history.push(`/group/${groupScope}/listings?${queryString}`);
    } else if (shopScope) {
      history.push(`/shop/${shopScope.identifier}/listings?${queryString}`);
    } else if (listingCollectionScope) {
      history.push(
        `/collection/${listingCollectionScope}/listings?${queryString}`
      );
    } else {
      history.push(`/listings?${queryString}`);
    }
  };

  const onSearchChange = (event) => setSearchQuery(event.target.value);
  const onLocationFilterChange = (event) =>
    setLocationFilter(event.target.value);
  const onCategoryFilterChange = (event) =>
    setCategoryFilter(event.target.value);
  const onPriceModelFilterChange = (event) =>
    setPriceModelTypeFilter(event.target.value);
  const onConditionFilter = (event) => setConditionFilter(event.target.value);

  const onLanguageFilterChange = (event) =>
    setLanguageFilter(event.target.value);

  const onPriceFilterChange = (range) => {
    const [min, max] = range;
    setPriceRangeFilter({ min, max });
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) doSearch();
  };

  const placeHolderText = placeholder
    ? placeholder
    : t`Search for books: Title, ISBN, Publisher or Author`;

  return (
    <Grid
      container
      className={classes.searchGroupContainer}
      justifyContent="center"
    >
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={10} lg={5}>
          <Paper>
            <BootstrapInput
              fullWidth
              onChange={onSearchChange}
              onKeyDown={onKeyDown}
              value={searchQuery || ""}
              placeholder={placeHolderText}
              endAdornment={
                <InputAdornment>
                  <IconButton onClick={doSearch}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            ></BootstrapInput>
          </Paper>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={12}
          lg={1}
          className={classes.searchFiltersButtonContainer}
        >
          <Grid item>
            <Button
              endIcon={<ExpandMoreIcon />}
              variant="text"
              color="default"
              disableElevation
              onClick={toggleFilters}
            >
              <Trans>Filters</Trans>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Collapse
        component={Grid}
        lg={11}
        xs={10}
        in={filtersOpen}
        className={classes.collapsibleContainer}
      >
        <Grid
          className={classes.filtersContainer}
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} lg={11}>
            <Grid
              spacing={3}
              container
              justifyContent="center"
              alignItems="center"
              className={classes.searchFiltersGroup}
            >
              <Grid item xs={12} sm={8} lg={2} className={classes.searchFilter}>
                <DropDown
                  value={locationFilter}
                  onChange={onLocationFilterChange}
                  options={locations}
                />
              </Grid>
              <Grid item xs={12} sm={8} lg={2} className={classes.searchFilter}>
                <DropDown
                  value={categoryFilter}
                  onChange={onCategoryFilterChange}
                  options={categories}
                />
              </Grid>
              <Grid item xs={12} sm={8} lg={2} className={classes.searchFilter}>
                <DropDown
                  value={languageFilter}
                  onChange={onLanguageFilterChange}
                  options={languages}
                />
              </Grid>
              <Grid item xs={12} sm={8} lg={2} className={classes.searchFilter}>
                <PriceRangeDropDown
                  onChange={onPriceFilterChange}
                  value={[priceRangeFilter.min, priceRangeFilter.max]}
                />
              </Grid>
              <Grid item xs={12} sm={8} lg={2} className={classes.searchFilter}>
                <DropDown
                  value={priceModelTypeFilter}
                  onChange={onPriceModelFilterChange}
                  options={priceModelTypes}
                />
              </Grid>
              <Grid item xs={12} sm={8} lg={2} className={classes.searchFilter}>
                <DropDown
                  value={conditionFilter}
                  onChange={onConditionFilter}
                  options={conditions}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={1}>
            <Grid container alignItems="center" justifyContent="center">
              <Button
                variant="contained"
                color="default"
                disableElevation
                disableRipple
                size="small"
                onClick={doSearch}
              >
                <Trans>Apply Filters</Trans>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
}

SearchGroup.propTypes = {
  groupScope: PropTypes.string,
  shopScope: PropTypes.string,
  listingCollectionScope: PropTypes.string,
  placeholder: PropTypes.string,
};
