import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import Currency from "./Currency";
import { capitalCase } from "change-case";
import { priceModelTypes } from "../consts";

const createUseStyles = ({ isXs }) =>
  makeStyles((theme) => {
    const priceTag_base = {
      padding: theme.spacing(0.5),
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      borderRadius: `0 0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    };

    const priceModelType_base = {
      borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
      border: "1px solid",
      borderColor: theme.palette.primary.light,
      backgroundColor: theme.palette.primary.extraLighter,
    };

    return {
      priceTag_xs: {
        ...priceTag_base,
        borderRadius: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
        "& *": {
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
      priceTag_sm: {
        borderStyle: "",
        ...priceTag_base,
        "& *": {
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
      priceTag_md: {
        ...priceTag_base,
        padding: theme.spacing(1),
        "& *": {
          fontSize: "20px",
          fontWeight: "bold",
        },
      },
      priceModelType_xs: {
        ...priceModelType_base,
        borderRadius: `${theme.spacing(1)}px 0 0 ${theme.spacing(1)}px`,
        ...(!isXs && { borderRight: 0 }),
        padding: theme.spacing(0.2),
        color: theme.palette.primary.main,
        "& *": {
          fontWeight: "bold",
          fontSize: "12px",
        },
      },
      priceModelType_sm: {
        ...priceModelType_base,
        padding: theme.spacing(0.5),
        color: theme.palette.primary.main,
        "& *": {
          fontWeight: "bold",
          fontSize: "12px",
        },
      },
      priceModelType_md: {
        ...priceModelType_base,
        padding: theme.spacing(1),
        color: theme.palette.primary.main,
        "& *": {
          fontWeight: "bold",
          fontSize: "15px",
        },
      },
      exchangePriceModel: {
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1),
      },
      originalPrice: {
        textDecoration: "line-through",
        fontSize: "0.7em",
        color: theme.palette.primary.contrastText,
      },
    };
  });

export default function PriceTag({
  price,
  originalPrice,
  size,
  priceModelType,
  label,
}) {
  const isXs = size === "xs";
  const useStyles = createUseStyles({ isXs });
  const classes = useStyles();
  const priceTagClass = classes[`priceTag_${size}`];
  const priceModelTypeClass = classes[`priceModelType_${size}`];
  const isRent = priceModelType === priceModelTypes.RENT;
  const isExchange = priceModelType === priceModelTypes.EXCHANGE;

  const getPriceTagLabel = () => {
    if (label) {
      return <Typography variant="caption">{label}</Typography>;
    }

    return isXs ? (
      <Typography variant="caption">{capitalCase(priceModelType)}</Typography>
    ) : (
      <Typography variant="body2">For {capitalCase(priceModelType)}</Typography>
    );
  };

  return (
    <Grid container>
      <Grid
        container
        item
        xs={isXs && !isExchange ? 4 : 12}
        justifyContent="center"
        alignItems="center"
        className={`${priceModelTypeClass} ${
          isExchange ? classes.exchangePriceModel : ""
        }`}
      >
        {getPriceTagLabel()}
      </Grid>
      {!isExchange && (
        <>
          <Grid item xs={isXs ? 8 : 12}>
            <Grid container className={priceTagClass}>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Grid container justifyContent="center">
                      <Typography>
                        <Currency value={price} />{" "}
                        {isRent && price > 0 ? "/ day" : null}
                      </Typography>
                    </Grid>
                  </Grid>
                  {!!originalPrice && originalPrice !== price && (
                    <Grid item>
                      <Grid container justifyContent="center">
                        <Typography className={classes.originalPrice}>
                          <Currency value={originalPrice} />{" "}
                          {isRent && originalPrice > 0 ? "/ day" : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

PriceTag.propTypes = {
  price: PropTypes.number,
  size: PropTypes.oneOf(["sm", "md"]),
  priceModelType: PropTypes.string,
  label: PropTypes.string,
};

PriceTag.defaultProps = {
  price: 0,
  size: "md",
  priceModelType: priceModelTypes.SALE,
};
