import React, { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  Container,
  Button,
  InputLabel,
  FormControl,
  withStyles,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import BootstrapInput from "../../Common/BootstrapInput";
import ImageUploader from "../../Common/ImageUploader";
import PageTitle from "../../Common/PageTitle";
import PropTypes from "prop-types";
import AddressManager from "../../Common/Addresses/AddressManager";
import PhoneNumberManager from "../../Common/PhoneNumbers/PhoneNumberManager";
import { useDispatch } from "react-redux";
import { actionTypes } from "../../index.reducer";
import { stripTypename } from "../../../utils/common";
import { useLocation } from "react-use";

const useStyles = makeStyles((theme) => ({
  editorSection: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
  },
  title: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  imageUploader: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3),
  },
  createListingBtnContainerDesktop: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  createListingBtnContainerMobile: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  contactData: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(5),
  },
  emailPrefs: {
    paddingLeft: theme.spacing(1),
  },
}));

const Label = withStyles((theme) => ({
  root: {
    fontSize: "20px",
    color: theme.palette.grey[700],
  },
}))(InputLabel);

const Field = withStyles({
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
})(FormControl);

export default function ProfileEditor({ user, disabled, onSubmit }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const emailPrefsRef = React.createRef(null);
  const location = useLocation();
  // Form state
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [emailPreferences, setEmailPreferences] = useState({});
  const [privacyPreferences, setPrivacyPreferences] = useState({});

  // Form validation state
  const [invalidFirstName, setInvalidFirstName] = useState(false);
  const [invalidCurrentPassword, setInvalidCurrentPassword] = useState(false);
  const [invalidNewPassword, setInvalidNewPassword] = useState(false);
  const [invalidNewPasswordConfirm, setInvalidNewPasswordConfirm] =
    useState(false);

  // Event handlers
  const onChangeFirstName = (event) => {
    setInvalidFirstName(false);
    setFirstName(event.target.value);
  };
  const onChangeLastName = (event) => setLastName(event.target.value);
  const onChangeDescription = (event) => setDescription(event.target.value);
  const onChangeCurrentPassword = (event) =>
    setCurrentPassword(event.target.value);
  const onChangeNewPassword = (event) => setNewPassword(event.target.value);
  const onChangeNewPasswordConfirm = (event) =>
    setNewPasswordConfirm(event.target.value);
  const onAttachProfilePicture = ([image]) => {
    setProfilePicture(image);
  };
  const onChangeEmailPreference = (event) => {
    // We cannot use event.target.value inside setEmailPreferences callback function
    // because setEmailPreferences is asynchronous, and the event might not be available
    // when it's executed due to react SyntheticEvent pooling.
    // More: https://forum.freecodecamp.org/t/functional-setstate-causes-null-error/342392/2
    const prefName = event.target.value;
    const isSelected = event.target.checked;
    setEmailPreferences((prefs) => ({
      ...prefs,
      [prefName]: isSelected,
    }));
  };

  const onChangePrivacyPreference = (event) => {
    const prefName = event.target.value;
    const isSelected = event.target.checked;
    setPrivacyPreferences((prefs) => ({
      ...prefs,
      [prefName]: isSelected,
    }));
  };

  useEffect(() => {
    if (location.hash.includes("#email-preferences") && emailPrefsRef.current) {
      emailPrefsRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [emailPrefsRef, location]);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setDescription(user.description);
      setEmailPreferences(user.emailPreferences || {});
      setPrivacyPreferences(user.privacyPreferences || {});
    }
  }, [user]);

  const validateform = () => {
    let formValid = true;
    if (!firstName) {
      setInvalidFirstName(true);
      formValid = false;
    }
    if (newPassword) {
      if (!currentPassword) {
        setInvalidCurrentPassword(true);
        formValid = false;
      }
      if (!newPasswordConfirm) {
        setInvalidNewPasswordConfirm(true);
        formValid = false;
      }
    }

    return formValid;
  };

  const dispatchErrorMessage = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });

  const onFormSubmit = () => {
    if (!validateform()) {
      dispatchErrorMessage("One or more required fields are missing!");
      return;
    }
    const update = {
      firstName,
      lastName,
      description,
      emailPreferences: stripTypename(emailPreferences),
      privacyPreferences: stripTypename(privacyPreferences),
    };

    if (newPassword) {
      if (newPassword === newPasswordConfirm) {
        update.currentPassword = currentPassword;
        update.newPassword = newPassword;
      } else {
        setInvalidNewPasswordConfirm(true);
        setInvalidNewPassword(true);
        dispatchErrorMessage("Passwords do not match");
        return;
      }
    }

    if (profilePicture) {
      update.profilePicture = profilePicture;
    }

    onSubmit(update);
  };

  const emailPreferencesSection = (
    <Grid item xs={12} className={classes.editorSection} ref={emailPrefsRef}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageTitle variant="h5">Email Preferences</PageTitle>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            You can configure which updates do you like to receive via email
            below.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            direction="column"
            className={classes.emailPrefs}
          >
            <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      value="orderNotifications"
                      checked={!!emailPreferences?.orderNotifications}
                      onChange={onChangeEmailPreference}
                    />
                  }
                  label={
                    <Grid container direction="column">
                      <Typography variant="body1">
                        Order Notifications{" "}
                      </Typography>
                      <Typography color="textSecondary" variant="caption">
                        Updates/Messages related to orders etc.
                      </Typography>
                    </Grid>
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      value="bookRequestNotifications"
                      checked={!!emailPreferences?.bookRequestNotifications}
                      onChange={onChangeEmailPreference}
                    />
                  }
                  label={
                    <Grid container direction="column">
                      <Typography variant="body1">
                        Book Request Alerts
                      </Typography>
                      <Typography color="textSecondary" variant="caption">
                        Potential matches for book requests
                      </Typography>
                    </Grid>
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const privacyPreferencesSection = (
    <Grid item xs={12} className={classes.editorSection} ref={emailPrefsRef}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageTitle variant="h5">Privacy Preferences</PageTitle>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.emailPrefs}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value="activityListedOnFeed"
                    checked={!!privacyPreferences?.activityListedOnFeed}
                    onChange={onChangePrivacyPreference}
                  />
                }
                label={
                  <Grid container direction="column">
                    <Typography variant="body1">
                      Display my public activity on the feed
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      Unchecking this would permanently delete any previous
                      visible activity from the feed
                    </Typography>
                  </Grid>
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const passwordChangeSection = (
    <Grid item xs={12} className={classes.editorSection}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageTitle variant="h5">Change Password</PageTitle>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Field className={classes.spannedInput}>
              <Label shrink color="primary" htmlFor="bootstrap-input">
                Current Password
              </Label>
              <BootstrapInput
                type="password"
                error={invalidCurrentPassword}
                onChange={onChangeCurrentPassword}
                disabled={disabled}
              />
            </Field>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Field className={classes.spannedInput}>
              <Label shrink color="primary" htmlFor="bootstrap-input">
                New Password
              </Label>
              <BootstrapInput
                type="password"
                error={invalidNewPassword}
                onChange={onChangeNewPassword}
                disabled={disabled}
              />
            </Field>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Field className={classes.spannedInput}>
              <Label shrink color="primary" htmlFor="bootstrap-input">
                Confirm New Password
              </Label>
              <BootstrapInput
                type="password"
                error={invalidNewPasswordConfirm}
                onChange={onChangeNewPasswordConfirm}
                disabled={disabled}
              />
            </Field>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const submitButton = (
    <Grid item>
      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={onFormSubmit}
        disabled={disabled}
        disableElevation
      >
        Save Profile
      </Button>
    </Grid>
  );

  return (
    <Container maxWidth="xl">
      <Grid container justifyContent="center">
        <Grid item xs={11} md={12} className={classes.title}>
          <Grid container justifyContent="center">
            <PageTitle>Edit Profile</PageTitle>
          </Grid>
        </Grid>
        <Grid item xs={11} md={12}>
          <Grid container justifyContent="center" spacing={8}>
            <Grid item xs={12} md={6}>
              <Grid container alignContent="flex-start">
                <Grid container spacing={3}>
                  <Grid item xs={12} className={classes.editorSection}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <PageTitle variant="h5">Your Details</PageTitle>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field className={classes.spannedInput}>
                          <Label
                            shrink
                            color="primary"
                            htmlFor="bootstrap-input"
                          >
                            First Name
                          </Label>
                          <BootstrapInput
                            inputProps={{ maxlength: 30 }}
                            error={invalidFirstName}
                            value={firstName}
                            onChange={onChangeFirstName}
                            disabled={disabled}
                          />
                        </Field>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field className={classes.spannedInput}>
                          <Label
                            shrink
                            color="primary"
                            htmlFor="bootstrap-input"
                          >
                            Last Name
                          </Label>
                          <BootstrapInput
                            inputProps={{ maxlength: 50 }}
                            value={lastName}
                            onChange={onChangeLastName}
                            disabled={disabled}
                          />
                        </Field>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field className={classes.spannedInput}>
                          <Label
                            shrink
                            color="primary"
                            htmlFor="bootstrap-input"
                          >
                            Email
                          </Label>
                          <BootstrapInput disabled type="email" value={email} />
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field className={classes.spannedInput}>
                          <Label
                            shrink
                            color="primary"
                            htmlFor="bootstrap-input"
                          >
                            Few words about you...
                          </Label>
                          <BootstrapInput
                            multiline
                            inputProps={{ maxlength: 500 }}
                            rows={5}
                            value={description}
                            onChange={onChangeDescription}
                            disabled={disabled}
                          />
                        </Field>
                      </Grid>
                    </Grid>
                  </Grid>
                  {user.hasPassword && passwordChangeSection}
                  {emailPreferencesSection}
                  {privacyPreferencesSection}
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.createListingBtnContainerDesktop}
                >
                  <Grid container justifyContent="center">
                    {submitButton}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={3}
              justifyContent="flex-start"
              alignContent="flex-start"
              className={classes.additionalInfo}
              spacing={5}
            >
              <Grid item xs={12} className={classes.imageUploader}>
                <ImageUploader
                  limit={1}
                  onChange={onAttachProfilePicture}
                  buttonText="Attach new profile picture"
                  disabled={disabled}
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  item
                  spacing={5}
                  className={classes.contactData}
                >
                  <Grid item container spacing={2}>
                    <Grid item xs={12}>
                      <PageTitle variant="h6">Contact Numbers</PageTitle>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid item>
                          <PhoneNumberManager disabled={disabled} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  spacing={5}
                  className={classes.contactData}
                >
                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      <PageTitle variant="h6">Addresses</PageTitle>
                    </Grid>
                    <Grid item xs={12}>
                      <AddressManager disabled={disabled} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          className={classes.createListingBtnContainerMobile}
        >
          {submitButton}
        </Grid>
      </Grid>
    </Container>
  );
}

ProfileEditor.propTypes = {
  user: PropTypes.object,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};
