import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    border: "none",
    width: "100%",
    marginBottom: 0,
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.spacing(2),
  },
  profilePicture: {
    [theme.breakpoints.up("md")]: {
      width: "80px",
      height: "80px",
    },
    width: "60px",
    height: "60px",
  },
  bookRequests: {
    padding: theme.spacing(2),
  },
}));

export function UsersCardLoader() {
  const classes = useStyles();
  return (
    <div className={`ph-item ${classes.item}`}>
      <div className="ph-col-2">
        <div className={`ph-avatar   small ${classes.profilePicture}`}></div>
      </div>
      <div>
        <div className="ph-row">
          <div className="ph-col-8 big"></div>
          <div className="ph-col-12"></div>
          <div className="ph-col-6"></div>
        </div>
      </div>
    </div>
  );
}

export function UsersLoader({ xs, md, lg, xl }) {
  return (
    <Grid item container spacing={2} justifyContent="flex-start">
      <Grid item container xs={xs} md={md} lg={lg} xl={xl}>
        <UsersCardLoader />
      </Grid>
      <Grid item container xs={xs} md={md} lg={lg} xl={xl}>
        <UsersCardLoader />
      </Grid>
      <Grid item container xs={xs} md={md} lg={lg} xl={xl}>
        <UsersCardLoader />
      </Grid>
    </Grid>
  );
}
