import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import Address from "../Common/Addresses/Address";
import PhoneNumber from "../Common/PhoneNumbers/PhoneNumber";

export default function ShopAboutPage({ shop }) {
  if (!shop) return null;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          {!!shop.addresses?.length && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6">Our Locations</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {shop.addresses.map((address) => (
                      <Grid item xs={10} md={6} lg={4} xl={3} key={address.id}>
                        <Address address={address} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!!shop.contactNumbers?.length && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6">Contact Us</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {shop.contactNumbers.map(({ id, phoneNumber }) => (
                      <Grid item xs={8} md={6} lg={4} xl={3} key={id}>
                        <PhoneNumber phoneNumber={phoneNumber} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

ShopAboutPage.propTypes = {
  shop: PropTypes.object,
};
