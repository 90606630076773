import React from "react";
import { Trans } from "@lingui/macro";

export const locations = [
  { label: <Trans>Ampara</Trans>, value: "Ampara" },
  { label: <Trans>Anuradhapura</Trans>, value: "Anuradhapura" },
  { label: <Trans>Badulla</Trans>, value: "Badulla" },
  { label: <Trans>Batticaloa</Trans>, value: "Batticaloa" },
  { label: <Trans>Colombo</Trans>, value: "Colombo" },
  { label: <Trans>Galle</Trans>, value: "Galle" },
  { label: <Trans>Gampaha</Trans>, value: "Gampaha" },
  { label: <Trans>Hambantota</Trans>, value: "Hambantota" },
  { label: <Trans>Jaffna</Trans>, value: "Jaffna" },
  { label: <Trans>Kalutara</Trans>, value: "Kalutara" },
  { label: <Trans>Kandy</Trans>, value: "Kandy" },
  { label: <Trans>Kegalle</Trans>, value: "Kegalle" },
  { label: <Trans>Kilinochchi</Trans>, value: "Kilinochchi" },
  { label: <Trans>Kurunegala</Trans>, value: "Kurunegala" },
  { label: <Trans>Mannar</Trans>, value: "Mannar" },
  { label: <Trans>Matale</Trans>, value: "Matale" },
  { label: <Trans>Matara</Trans>, value: "Matara" },
  { label: <Trans>Moneragala</Trans>, value: "Moneragala" },
  { label: <Trans>Mullaitivu</Trans>, value: "Mullaitivu" },
  { label: <Trans>Nuwara Eliya</Trans>, value: "Nuwara Eliya" },
  { label: <Trans>Polonnaruwa</Trans>, value: "Polonnaruwa" },
  { label: <Trans>Puttalam</Trans>, value: "Puttalam" },
  { label: <Trans>Ratnapura</Trans>, value: "Ratnapura" },
  { label: <Trans>Trincomalee</Trans>, value: "Trincomalee" },
  { label: <Trans>Vavuniya</Trans>, value: "Vavuniya" },
];

export const categories = [
  { label: <Trans>Action and Adventure</Trans>, value: "ACTION_AND_ADVENTURE" },
  { label: <Trans>Alternate History</Trans>, value: "ALTERNATE_HISTORY" },
  { label: <Trans>Anthology</Trans>, value: "ANTHOLOGY" },
  { label: <Trans>Arts and Architecture</Trans>, value: "ART_ARCHITECTURE" },
  {
    label: <Trans>Autobiography and Memoir</Trans>,
    value: "AUTOBIOGRAPHY_AND_MEMOIR",
  },
  { label: <Trans>Biography</Trans>, value: "BIOGRAPHY" },
  {
    label: <Trans>Business and Economics</Trans>,
    value: "BUSINESS_AND_ECONOMICS",
  },
  { label: <Trans>Chick Lit</Trans>, value: "CHICK_LIT" },
  { label: <Trans>Children's</Trans>, value: "CHILDRENS" },
  { label: <Trans>Classic</Trans>, value: "CLASSIC" },
  { label: <Trans>Comics</Trans>, value: "COMICS" },
  { label: <Trans>Coming-of-age</Trans>, value: "COMING_OF_AGE" },
  { label: <Trans>Computers and Tech</Trans>, value: "COMPUTERS_AND_TECH" },
  { label: <Trans>Contemporary</Trans>, value: "CONTEMPORARY" },
  { label: <Trans>Cooking</Trans>, value: "COOKING" },
  { label: <Trans>Crime</Trans>, value: "CRIME" },
  { label: <Trans>Diary</Trans>, value: "DIARY" },
  { label: <Trans>Dictionary</Trans>, value: "DICTIONARY" },
  {
    label: <Trans>Education and Reference</Trans>,
    value: "EDUCATION_AND_REFERENCE",
  },
  { label: <Trans>Encyclopedia</Trans>, value: "ENCYCLOPEDIA" },
  { label: <Trans>Entertainment</Trans>, value: "ENTERTAINMENT" },
  { label: <Trans>Fantasy</Trans>, value: "FANTASY" },
  { label: <Trans>Fairy Tale</Trans>, value: "FAIRYTALE" },
  { label: <Trans>Graphic Novel</Trans>, value: "GRAPHIC_NOVEL" },
  { label: <Trans>Guide</Trans>, value: "GUIDE" },
  { label: <Trans>Health and Fitness</Trans>, value: "HEALTH_AND_FITNESS" },
  { label: <Trans>History</Trans>, value: "HISTORY" },
  { label: <Trans>Historical Fiction</Trans>, value: "HISTORICAL_FICTION" },
  { label: <Trans>Hobbies and Crafts</Trans>, value: "HOBBIES_AND_CRAFTS" },
  { label: <Trans>Horror</Trans>, value: "HORROR" },
  { label: <Trans>Home and Garden</Trans>, value: "HOME_AND_GARDEN" },
  { label: <Trans>Humour</Trans>, value: "HUMOUR" },
  { label: <Trans>Journal</Trans>, value: "JOURNAL" },
  { label: <Trans>Law and Politics</Trans>, value: "LAW_AND_POLITICS" },
  {
    label: <Trans>Literature and Fiction</Trans>,
    value: "LITERATURE_AND_FICTION",
  },
  { label: <Trans>Math</Trans>, value: "MATH" },
  { label: <Trans>Medical</Trans>, value: "MEDICAL" },
  { label: <Trans>Music</Trans>, value: "MUSIC" },
  { label: <Trans>Mysteries</Trans>, value: "MYSTERIES" },
  { label: <Trans>Non-Fiction</Trans>, value: "NON_FICTION" },
  { label: <Trans>Paranormal Romance</Trans>, value: "PARANORMAL_ROMANCE" },
  { label: <Trans>Parenting</Trans>, value: "PARENTING" },
  { label: <Trans>Performing Arts</Trans>, value: "PERFORMING_ARTS" },
  { label: <Trans>Philosophy</Trans>, value: "PHILOSOPHY" },
  { label: <Trans>Picture Book</Trans>, value: "PICTURE_BOOK" },
  { label: <Trans>Poetry</Trans>, value: "POETRY" },
  { label: <Trans>Political Thriller</Trans>, value: "POLITICAL_THRILLER" },
  { label: <Trans>Prayer</Trans>, value: "PRAYER" },
  { label: <Trans>Psychology</Trans>, value: "PSYCHOLOGY" },
  {
    label: <Trans>Religion and Spirituality</Trans>,
    value: "RELIGION_AND_SPIRITUALITY",
  },
  { label: <Trans>Review</Trans>, value: "REVIEW" },
  { label: <Trans>Romance</Trans>, value: "ROMANCE" },
  { label: <Trans>Satire</Trans>, value: "SATIRE" },
  { label: <Trans>Science</Trans>, value: "SCIENCE" },
  { label: <Trans>Science Fiction</Trans>, value: "SCIENCE_FICTION" },
  { label: <Trans>Self Help</Trans>, value: "SELF_HELP" },
  { label: <Trans>Short Story</Trans>, value: "SHORT_STORY" },
  { label: <Trans>Social Sciences</Trans>, value: "SOCIAL_SCIENCES" },
  { label: <Trans>Sports and Leisure</Trans>, value: "SPORTS" },
  { label: <Trans>Suspense</Trans>, value: "SUSPENSE" },
  { label: <Trans>Textbook</Trans>, value: "TEXTBOOK" },
  { label: <Trans>Thriller</Trans>, value: "THRILLER" },
  { label: <Trans>Travel</Trans>, value: "TRAVEL" },
  { label: <Trans>True Crime</Trans>, value: "TRUE_CRIME" },
  { label: <Trans>Western</Trans>, value: "WESTERN" },
  { label: <Trans>Young Adult</Trans>, value: "YOUNG_ADULT" },
];

export const languages = [
  { label: <Trans>English</Trans>, value: "ENGLISH" },
  { label: <Trans>Sinhala</Trans>, value: "SINHALA" },
  { label: <Trans>Tamil</Trans>, value: "TAMIL" },
  { label: <Trans>Other</Trans>, value: "OTHER" },
];

export const priceModelTypes = {
  SALE: "SALE",
  RENT: "RENT",
  EXCHANGE: "EXCHANGE",
};

export const searchGroupPriceModelTypes = [
  { label: <Trans>Sale</Trans>, value: priceModelTypes.SALE },
  { label: <Trans>Rent</Trans>, value: priceModelTypes.RENT },
  { label: <Trans>Exchange</Trans>, value: priceModelTypes.EXCHANGE },
];

export const buyingPriceModelTypes = [
  { label: <Trans>Buy</Trans>, value: "SALE", default: true },
  { label: <Trans>Rent</Trans>, value: "RENT" },
  { label: <Trans>Exchange</Trans>, value: "EXCHANGE" },
];

export const BOOK_CONDITIONS = {
  NEW: "NEW",
  USED: "USED",
};

export const bookConditions = [
  { label: <Trans>New</Trans>, value: BOOK_CONDITIONS.NEW, default: true },
  { label: <Trans>Used</Trans>, value: BOOK_CONDITIONS.USED },
];

export const listingConditions = {
  NEW: <Trans>New</Trans>,
  USED: <Trans>Used</Trans>,
};

export const reportTypes = [
  { label: <Trans>Advertisement</Trans>, value: "LISTING" },
  { label: <Trans>Book Request</Trans>, value: "BOOK_REQUEST" },
  { label: <Trans>Critique</Trans>, value: "CRITIQUE" },
  { label: <Trans>Message</Trans>, value: "MESSAGE" },
  { label: <Trans>Discussion</Trans>, value: "DISCUSSION" },
];

export const reportingReasons = [
  {
    label: <Trans>Inappropriate Content</Trans>,
    value: "INAPPROPRIATE_CONTENT",
    default: true,
  },
  { label: <Trans>Spam</Trans>, value: "SPAM" },
  { label: <Trans>Fake Content</Trans>, value: "FAKE_CONTENT" },
  { label: <Trans>Copyright Violation</Trans>, value: "COPYRIGHT_VIOLATION" },
  { label: <Trans>Something Else (Please specify)</Trans>, value: "OTHER" },
];

export const orderStatuses = {
  OPEN: "OPEN",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  DISPATCHED: "DISPATCHED",
  DELIVERED: "DELIVERED",
  READY_FOR_PICKUP: "READY_FOR_PICKUP",
  CANCELLED: "CANCELLED",
};

export const TEMP_LISTING_INACTIVE_REASONS = [
  {
    label: <Trans>This item was sold.</Trans>,
    value: "SOLD_OUT",
    shortText: <Trans>Sold out</Trans>,
    sellerDescription: (
      <Trans>
        Users will be able to access this advertisement via direct links.
        However, users won't be able to interact with this advertisement.
      </Trans>
    ),
    buyerDescription: <Trans>This item is currently sold out.</Trans>,
  },
  {
    label: <Trans>This item is rented out, and temporarily unavailable.</Trans>,
    value: "RENTED_OUT",
    shortText: <Trans>Rented out</Trans>,
    sellerDescription: (
      <Trans>
        Users will be able to access this advertisement via direct links.
        However, users won't be able to interact with this advertisement.
      </Trans>
    ),
    buyerDescription: (
      <Trans>
        This item is rented-out and temporarily unavailable. Please check again
        later, or contact the seller directly.
      </Trans>
    ),
  },
  {
    label: <Trans>This item is temporarily not in stock.</Trans>,
    value: "NOT_IN_STOCK",
    shortText: <Trans>Not in stock</Trans>,
    sellerDescription: (
      <Trans>
        Users will be able to access this advertisement via direct links.
        However, users won't be able to interact with this advertisement.
      </Trans>
    ),
    buyerDescription: (
      <Trans>
        This item is currently not in stock. Please check again later.
      </Trans>
    ),
  },
  {
    label: <Trans>Other reason</Trans>,
    shortText: null,
    value: "OTHER", // Back-end value for this is null
    sellerDescription: (
      <Trans>
        If you select this option, this advertisement won't be visible to anyone
        other than you.
      </Trans>
    ),
    buyerDescription: null,
  },
];

export const GRAPHQL_ERRORS = {
  FORBIDDEN: "FORBIDDEN",
  UNAUTHENTICATED: "UNAUTHENTICATED",
};

export const USER_ROLES = {
  ADMINISTRATOR: "ADMINISTRATOR",
};

export const DB_VALIDATION_ERRORS = {
  DUPLICATE: "DUPLICATE",
};

export const STORY_GENRES = {
  ACTION_AND_ADVENTURE: {
    code: "ACTION_AND_ADVENTURE",
    image: "/genres/adventure.jpg",
    text: <Trans>Action and Adventure</Trans>,
  },
  ALTERNATE_HISTORY: {
    code: "ALTERNATE_HISTORY",
    image: "/genres/alternate-history.jpg",
    text: <Trans>Alternate History</Trans>,
  },
  AUTOBIOGRAPHY_AND_MEMOIR: {
    code: "AUTOBIOGRAPHY_AND_MEMOIR",
    image: "/genres/autobiography.jpg",
    text: <Trans>Autobiography and Memoir</Trans>,
  },
  BIOGRAPHY: {
    code: "BIOGRAPHY",
    image: "/genres/biography.jpg",
    text: <Trans>Biography</Trans>,
  },
  CHILDRENS: {
    code: "CHILDRENS",
    image: "/genres/childrens.jpg",
    text: <Trans>Children's</Trans>,
  },
  CLASSIC: {
    code: "CLASSIC",
    image: "/genres/classic.jpg",
    text: <Trans>Classic</Trans>,
  },
  COMING_OF_AGE: {
    code: "COMING_OF_AGE",
    image: "/genres/coming-of-age.jpg",
    text: <Trans>Coming-of-age</Trans>,
  },
  CONTEMPORARY: {
    code: "CONTEMPORARY",
    image: "/genres/contemporary.jpg",
    text: <Trans>Contemporary</Trans>,
  },
  CRIME: {
    code: "CRIME",
    image: "/genres/crime.jpeg",
    text: <Trans>Crime</Trans>,
  },
  DIARY: {
    code: "DIARY",
    image: "/genres/diary.jpg",
    text: <Trans>Diary</Trans>,
  },
  FANTASY: {
    code: "FANTASY",
    image: "/genres/fantasy.jpg",
    text: <Trans>Fantasy</Trans>,
  },
  HISTORY: {
    code: "HISTORY",
    image: "/genres/history.jpg",
    text: <Trans>History</Trans>,
  },
  HISTORICAL_FICTION: {
    code: "HISTORICAL_FICTION",
    image: "/genres/historical-fiction.jpg",
    text: <Trans>Historical Fiction</Trans>,
  },
  HORROR: {
    code: "HORROR",
    image: "/genres/horror.jpg",
    text: <Trans>Horror</Trans>,
  },
  HUMOUR: {
    code: "HUMOUR",
    image: "/genres/humour.jpg",
    text: <Trans>Humour</Trans>,
  },
  LITERATURE_AND_FICTION: {
    code: "LITERATURE_AND_FICTION",
    image: "/genres/literature.jpg",
    text: <Trans>Literature and Fiction</Trans>,
  },
  MYSTERIES: {
    code: "MYSTERIES",
    image: "/genres/mystery.jpg",
    text: <Trans>Mysteries</Trans>,
  },
  NON_FICTION: {
    code: "NON_FICTION",
    image: "/genres/nonfiction.jpg",
    text: <Trans>Non-Fiction</Trans>,
  },
  POETRY: {
    code: "POETRY",
    image: "/genres/poetry.jpg",
    text: <Trans>Poetry</Trans>,
  },
  POLITICAL_THRILLER: {
    code: "POLITICAL_THRILLER",
    image: "/genres/political-thriller.jpg",
    text: <Trans>Political Thriller</Trans>,
  },
  RELIGION_AND_SPIRITUALITY: {
    code: "RELIGION_AND_SPIRITUALITY",
    image: "/genres/religion.jpg",
    text: <Trans>Religion and Spirituality</Trans>,
  },
  ROMANCE: {
    code: "ROMANCE",
    image: "/genres/romance.jpg",
    text: <Trans>Romance</Trans>,
  },
  SCIENCE_FICTION: {
    code: "SCIENCE_FICTION",
    image: "/genres/sciencefiction.jpg",
    text: <Trans>Science Fiction</Trans>,
  },
  SUSPENSE: {
    code: "SUSPENSE",
    image: "/genres/suspense.jpg",
    text: <Trans>Suspense</Trans>,
  },
  THRILLER: {
    code: "THRILLER",
    image: "/genres/thriller.jpg",
    text: <Trans>Thriller</Trans>,
  },
  WESTERN: {
    code: "WESTERN",
    image: "/genres/western.jpg",
    text: <Trans>Western</Trans>,
  },
  YOUNG_ADULT: {
    code: "YOUNG_ADULT",
    image: "/genres/youngadult.jpg",
    text: <Trans>Young Adult</Trans>,
  },
};

export const STORY_AUDIENCE_CATEGORY = {
  ALL_AGES: "ALL_AGES",
  CHILDREN: "CHILDREN",
  YOUNG_ADULT: "YOUNG_ADULT",
  NEW_ADULT: "NEW_ADULT",
  ADULT: "ADULT",
};

export const COPYRIGHT_TYPE = {
  ALL_RIGHTS_RESERVED: "ALL_RIGHTS_RESERVED",
  PUBLIC_DOMAIN: "PUBLIC_DOMAIN",
  CC_BY: "CC_BY",
  CC_BY_SA: "CC_BY_SA",
  CC_BY_ND: "CC_BY_ND",
  CC_BY_NC: "CC_BY_NC",
  CC_BY_NC_SA: "CC_BY_NC_SA",
  CC_BY_NC_ND: "CC_BY_NC_ND",
};

export const COPYRIGHT_DESCRIPTIONS = {
  ALL_RIGHTS_RESERVED:
    "No part of this story may be reproduced, distributed, or transmitted in any form or by any means, including copying, audio recording, or other methods, without the prior written permission of the author, except in the case of brief quotations embodied in critical reviews permitted by copyright law.",
  PUBLIC_DOMAIN:
    "This story, including its content, is free for use by anyone for any purpose without restriction under copyright law.",
  CC_BY:
    "You may distribute, remix, adapt, and build upon this story, even commercially, as long as you credit the author for the original creation.",
  CC_BY_SA:
    "You may remix, adapt, and build upon this story even for commercial purposes, as long as you credit the author and license your new creations under the identical terms.",
  CC_BY_ND:
    "You may reuse the story for any purpose, including commercially; however, it cannot be shared with others in adapted form, and credit must be provided to the author.",
  CC_BY_NC:
    "You may remix, adapt, and build upon this story non-commercially, and although your new works must also acknowledge the author and be non-commercial, you don’t have to license your derivative works on the same terms.",
  CC_BY_NC_SA:
    "You may remix, adapt, and build upon this story non-commercially, as long as you credit the author and license your new creations under the identical terms.",
  CC_BY_NC_ND:
    "You may distribute this story as long as you credit the author, but you can’t change the story in any way or use it commercially.",
};

export const copyRightTypes = [
  { label: <Trans>All Rights Reserved</Trans>, value: "ALL_RIGHTS_RESERVED" },
  { label: <Trans>Public Domain</Trans>, value: "PUBLIC_DOMAIN" },
  { label: "Creative Commons Attribution", value: "CC_BY" },
  {
    label: "Creative Commons Attribution-ShareAlike",
    value: "CC_BY_SA",
  },
  {
    label: "Creative Commons Attribution-NoDerivs",
    value: "CC_BY_ND",
  },
  {
    label: "Creative Commons Attribution-NonCommercial",
    value: "CC_BY_NC",
  },
  {
    label: "Creative Commons Attribution-NonCommercial-ShareAlike",
    value: "CC_BY_NC_SA",
  },
  {
    label: "Creative Commons Attribution-NonCommercial-NoDerivs",
    value: "CC_BY_NC_ND",
  },
];

export const storyAudienceTypes = [
  { label: <Trans>All Ages</Trans>, value: "ALL_AGES" },
  { label: <Trans>Children (age 6 and below)</Trans>, value: "CHILDREN" },
  { label: <Trans>Middle-grade (age 7 - 13)</Trans>, value: "MIDDLE_GRADE" },
  { label: <Trans>Young Adult (age 14+)</Trans>, value: "YOUNG_ADULT" },
  { label: <Trans>New Adult (age 18+)</Trans>, value: "NEW_ADULT" },
  { label: <Trans>Adult (age 25+)</Trans>, value: "ADULT" },
];

export const storyGenres = Object.values(STORY_GENRES).map((genreObj) => ({
  label: genreObj.text,
  value: genreObj.code,
}));

export const DISPLAYED_GENRES_COUNT = 5;

export const SHOP_PRICING_TIERS = {
  FREE: "Free",
  STARTER: "Starter",
  EARLY_BIRD: "Early Bird",
  ADVANCED: "Advanced",
  PREMIUM_BUSINESS: "Premium Business",
};

export const PLACEHOLDER_IMAGES = {
  USER: "./user-placeholder.svg",
};

export const IMAGE_SIZES = {
  XXS: "xxs",
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  XL: "xl",
  BOOK_COVER_LG: "bookcoverlg",
};
