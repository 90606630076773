import {
  Container,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import GroupAvatar from "../../Common/GroupAvatar";
import Hyperlink from "../../Common/Hyperlink";
import PageTitle from "../../Common/PageTitle";
import ShopAvatar from "../../Common/ShopAvatar";
import CritiquesSearchController from "../../Critique/CritiquesSearchController";
import DiscussionsSearchController from "../../Discussions/DiscussionsSearchController";
import GroupsSearchController from "../../Group/GroupsSearchController";
import ListingCollectionsSearchController from "../../ListingCollections/ListingCollectionsSearchController";
import BookRequestsSearchController from "../../Requests/BookRequestsSearchController";
import ShopsSearchController from "../../Shop/ShopsSearchController";
import ListingsSearchController from "../ListingsSearchController";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import SearchGroup from "../SearchGroup";
import FeaturedStories from "../../Stories/Story/FeaturedStories";
import { useLoggedInUser } from "../../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  logo: {
    maxHeight: theme.spacing(6) * 2,
  },
  pageTitle: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
    borderRadius: theme.spacing(2),
  },
  searchGroup: {
    backgroundColor: theme.palette.primary.main,
    paddingBottom: "20px",
    width: "100%",
  },
  coverContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "30vh",
    [theme.breakpoints.down("sm")]: {
      height: "40vh",
    },
    zIndex: -1,
  },
  coverImage: {
    width: "100%",
    objectFit: "contain",
  },
  readMoreLink: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    textAlign: "right",
  },
  sectionTitle: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    textAlign: "left",
  },
  pageTitleText: {
    color: theme.palette.primary.main,
    fontSize: "36px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
  section: {
    [theme.breakpoints.up("md")]: {
      backgroundColor: "rgba(245, 245, 245, 0.8)",
      padding: theme.spacing(1),
      borderRadius: theme.spacing(2),
    },
  },
  homepageContainer: {
    overflow: "hidden",
  },
}));

const getGreeting = () => {
  try {
    const currentTime = new Date();
    if (!currentTime || !dayjs(currentTime).isValid()) {
      return "Hello";
    }

    const splitAfternoon = 12;
    const splitEvening = 17;
    const currentHour = parseFloat(dayjs(currentTime).format("HH"));

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      return t`Good afternoon`;
    } else if (currentHour >= splitEvening) {
      return t`Good evening`;
    }
    return t`Good morning`;
  } catch {
    return t`Hello`;
  }
};

export default function HomePage() {
  const classes = useStyles();
  const history = useHistory();
  const loggedInUser = useLoggedInUser();
  const firstName = loggedInUser?.firstName || t`my friend`;

  const heading = (
    <Grid container className={classes.pageTitle}>
      <Grid item xs={12}>
        <Grid container className={classes.coverContainer}></Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center" direction="column">
          <Grid item>
            <PageTitle
              variant="h4"
              align="center"
              className={classes.pageTitleText}
            >
              {getGreeting()}, {firstName}!{" "}
              <Trans>Let’s discover what’s new on bibliocircle.</Trans>
            </PageTitle>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const getSectionTitle = (title) => (
    <PageTitle variant="h6" className={classes.sectionTitle}>
      {title}
    </PageTitle>
  );

  const getViewMoreLink = (linkText, path) => (
    <Grid container>
      <Grid item xs={12}>
        <Hyperlink to={path}>
          <Link color="primary">
            <Typography className={classes.readMoreLink} variant="subtitle2">
              {linkText}
            </Typography>
          </Link>
        </Hyperlink>
      </Grid>
    </Grid>
  );

  const discussionsSection = (
    <Grid container className={classes.section}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {getSectionTitle(t`Readers are discussing...`)}
          </Grid>
          <Grid item xs={12}>
            <DiscussionsSearchController
              fetchPolicy="cache-first"
              key={history.location.key}
              compactCards
              fixedResultsCount={3}
              searchArgs={{}}
              descriptionMaxLength={150}
            />
          </Grid>
          <Grid container>
            {getViewMoreLink(t`More discussions in bookchat...`, "/bookchats")}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const critiquesSection = (
    <Grid container className={classes.section}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {getSectionTitle(t`Reviews & Critiques`)}
          </Grid>
          <Grid item xs={12}>
            <CritiquesSearchController
              fetchPolicy="cache-first"
              key={history.location.key}
              fixedResultsCount={3}
              searchArgs={{}}
            />
          </Grid>
          <Grid container>
            {getViewMoreLink(t`Read more critiques...`, "/critiques")}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const shopsSection = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {getSectionTitle(t`Shops`)}
      </Grid>
      <Grid item xs={12}>
        <ShopsSearchController
          fetchPolicy="cache-first"
          key={history.location.key}
          fixedResultsCount={5}
          searchArgs={{}}
          xs={12}
          component={ShopAvatar}
          spacing={2}
        />
      </Grid>
      <Grid container>{getViewMoreLink(t`More shops...`, "/shops")}</Grid>
    </Grid>
  );

  const groupsSection = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {getSectionTitle(t`Public Groups`)}
      </Grid>
      <Grid item xs={12}>
        <GroupsSearchController
          fetchPolicy="cache-first"
          key={history.location.key}
          component={GroupAvatar}
          spacing={2}
          fixedResultsCount={5}
          xs={12}
          searchArgs={{}}
        />
      </Grid>
      <Grid container>{getViewMoreLink(t`More groups...`, "/groups")}</Grid>
    </Grid>
  );

  const collectionsSection = (
    <Grid container className={classes.section}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {getSectionTitle(t`Collections`)}
          </Grid>
          <Grid item xs={12}>
            <ListingCollectionsSearchController
              fetchPolicy="cache-first"
              key={history.location.key}
              fixedResultsCount={2}
              searchArgs={{}}
              xs={12}
              md={6}
            />
          </Grid>
          <Grid container>
            {getViewMoreLink(t`More collections...`, "/collections")}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const bookRequestsSection = (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {getSectionTitle(t`Readers are looking for...`)}
      </Grid>
      <Grid item xs={12}>
        <BookRequestsSearchController
          fetchPolicy="cache-first"
          key={history.location.key}
          listOnly
          fixedResultsCount={5}
          searchArgs={{}}
        />
      </Grid>
      <Grid container>
        {getViewMoreLink(t`More book requests...`, "/book-requests")}
      </Grid>
    </Grid>
  );

  const featuredStoriesSection = (
    <Grid container className={classes.featuredStories}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {getSectionTitle(t`Featured Stories`)}
          </Grid>
          <Grid item xs={12}>
            <FeaturedStories />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {heading}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center">
                        <SearchGroup />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={3}
                        className={classes.homepageContainer}
                      >
                        <Grid item xs={12}>
                          {featuredStoriesSection}
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={5}>
                              {discussionsSection}
                            </Grid>
                            <Grid item xs={12} md={7}>
                              {critiquesSection}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Grid container spacing={4}>
                                <Grid item xs={12}>
                                  {bookRequestsSection}
                                </Grid>
                                <Grid item xs={12} sm={6} md={12}>
                                  {shopsSection}
                                </Grid>
                                <Grid item xs={12} sm={6} md={12}>
                                  {groupsSection}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              {collectionsSection}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          {getSectionTitle(t`Recently Added Books`)}
                        </Grid>
                        <Grid item xs={12} lg={12}>
                          <ListingsSearchController
                            cachePolicy="cache-first"
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={4}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
