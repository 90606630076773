import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    border: "none",
    width: "100%",
    backgroundColor: theme.palette.grey[100],
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  profilePicture: {
    height: "64px",
    width: "64px",
  },
}));

export function ChatGroupsCardLoader() {
  const classes = useStyles();
  return (
    <div className={`ph-item ${classes.item}`}>
      <div>
        <div className="ph-row">
          <div className="ph-col-8 small"></div>
        </div>
      </div>
    </div>
  );
}

export function ChatGroupsLoader({ xs, md, lg, xl }) {
  const classes = useStyles();
  return (
    <Grid item container justifyContent="flex-start" className={classes.groups}>
      <Grid item container xs={xs} md={md} lg={lg} xl={xl}>
        <ChatGroupsCardLoader />
      </Grid>
      <Grid item container xs={xs} md={md} lg={lg} xl={xl}>
        <ChatGroupsCardLoader />
      </Grid>
      <Grid item container xs={xs} md={md} lg={lg} xl={xl}>
        <ChatGroupsCardLoader />
      </Grid>
    </Grid>
  );
}
