import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles, Avatar, Fade } from "@material-ui/core";
import GroupMetaBadge from "./GroupMetaBadge";
import Hyperlink from "../Common/Hyperlink";

const useStyles = makeStyles((theme) => {
  const groupCardContentStyles = {
    backgroundColor: theme.palette.grey[200],
    border: "2px solid",
    borderColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    position: "relative",
    "&:hover": {
      backgroundColor: theme.palette.primary.lighter,
      borderColor: theme.palette.primary.light,
    },
  };
  return {
    groupCard: {
      display: "flex",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(1.5),
      },
    },
    groupCardContentWithBadge: {
      ...groupCardContentStyles,
      "&::after": {
        content: '"Member"',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        [theme.breakpoints.down("md")]: {
          fontSize: "10px",
          width: "50px",
          height: "20px",
        },
        color: theme.palette.primary.main,
        padding: "",
        position: "absolute",
        top: 0,
        right: 0,
        borderRadius: `0 ${theme.spacing(1)}px 0`,
        width: "80px",
        height: "30px",
        backgroundColor: theme.palette.primary.lighter,
      },
    },
    groupCardContent: {
      ...groupCardContentStyles,
    },
    logoImage: {
      marginRight: theme.spacing(2),
    },
    logoContainer: {
      height: "100%",
    },
    logo: {
      [theme.breakpoints.up("sm")]: {
        maxWidth: "60px",
      },
      maxWidth: "40px",
      objectFit: "contain",
    },
    logoImageAvatar: {
      [theme.breakpoints.up("sm")]: {
        width: 60,
        height: 60,
      },
      width: 40,
      height: 40,
    },
    groupName: {
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      fontSize: "18px",
      fontWeight: "bold",
      color: theme.palette.grey[800],
    },
    groupDescription: {
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    groupDescriptionContainer: {
      marginBottom: theme.spacing(1),
    },
  };
});

export default function GroupCard({ group }) {
  const classes = useStyles();
  const trimDescription = (description) => {
    const desc = description || "";
    return desc.length <= 80 ? desc : desc.substring(0, 80) + "...";
  };
  return (
    <Hyperlink to={`/group/${group.id}`} className={classes.groupCard}>
      <Grid
        container
        className={
          group.isCurrentUserAMember
            ? classes.groupCardContentWithBadge
            : classes.groupCardContent
        }
      >
        <Grid item className={classes.logoImage}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            className={classes.logoContainer}
          >
            {group.logoImage ? (
              <Fade in>
                <Avatar
                  alt={group.name}
                  src={`${group.logoImage}/xs`}
                  className={classes.logoImageAvatar}
                />
              </Fade>
            ) : (
              <Fade in>
                <Avatar
                  src="/group-placeholder.svg"
                  className={classes.logoImageAvatar}
                />
              </Fade>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={9}
          sm={10}
          md={9}
          xl={10}
          className={classes.groupDetailsContainer}
        >
          <Grid item xs={12}>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.groupName}
            >
              {group.name}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.groupDescriptionContainer}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classes.groupDescription}
            >
              {trimDescription(group.description)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <GroupMetaBadge group={group} />
          </Grid>
        </Grid>
      </Grid>
    </Hyperlink>
  );
}

GroupCard.propTypes = {
  group: PropTypes.object.isRequired,
};
