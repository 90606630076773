import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  makeStyles,
  Button,
  FormHelperText,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import InputLabel from "../Common/InputLabel";
import FormControlField from "../Common/FormControlField";
import BookManager from "../Listing/BookManager";
import { actionTypes } from "../index.reducer";
import Required from "../Common/Required";
import Banner from "../Common/Banner";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_DISCUSSION } from "../../consts/queries";
import BootstrapInputBordered from "../Common/BootstrapInputBordered";
import {
  extractFieldError,
  getFormattedError,
  validateObject,
} from "../../utils/schemaValidator";
import discussionSchema from "./discussion.schema";
import { dataURLtoBlob, isDataUrl } from "../../utils/fileOperations";
import { IMAGE_SERVER_URL } from "../../config";
import { CREATE_DISCUSSION, UPDATE_DISCUSSION } from "../../consts/mutations";
import PageTitle from "../Common/PageTitle";
import PageHead from "../Common/PageHead";
import GroupSelector from "../Group/GroupSelector";
import { usePrompt, useQueryParams, useRequireLogin } from "../../utils/hooks";
import { stripBookMetaProps } from "../../utils/common";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  editorTitle: {
    fontWeight: "bold",
  },
  editorSection: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
  },
  title: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  messageText: {
    lineHeight: 1.5,
  },
}));

export default function DiscussionEditor() {
  const classes = useStyles();
  const queryParams = useQueryParams();
  const groupScope = queryParams.get("groupScope");
  const { discussionId } = useParams();
  const isUpdateOperation = !!discussionId;
  const history = useHistory();
  const dispatch = useDispatch();
  useRequireLogin();

  const [discussionTitle, setDiscussionTitle] = useState(null);
  const [message, setMessage] = useState(null);
  const [books, setBooks] = useState([]);
  const [targetGroupId, setTargetGroupId] = useState(groupScope);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [showDelayBanner, setShowDelayBanner] = useState(false);
  const [isPageDirty, setPageDirty] = useState(false);

  usePrompt("Are you sure you want to leave the page?", isPageDirty);
  useEffect(() => setPageDirty(true), []);

  const [createDiscussion] = useMutation(CREATE_DISCUSSION);
  const [updateDiscussion] = useMutation(UPDATE_DISCUSSION);
  const dispatchErrorMessage = useCallback(
    (message) =>
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message,
      }),
    [dispatch]
  );

  const [
    getDiscussion,
    {
      loading: fetchingDiscussion,
      error: fetchDiscussionError,
      data: discussionData,
    },
  ] = useLazyQuery(GET_DISCUSSION);

  useEffect(() => {
    if (isUpdateOperation) {
      getDiscussion({ variables: { id: discussionId } });
    }
  }, [isUpdateOperation, discussionId, getDiscussion]);

  useEffect(() => {
    if (discussionData?.discussion) {
      const { discussion } = discussionData;
      if (discussion.group && discussion.group.id !== groupScope) return; // Prevent populating discussions from other groups
      setDiscussionTitle(discussion.title);
      setMessage(discussion.message);
      setBooks(discussion.books);
    }
  }, [discussionData, groupScope]);

  useEffect(() => {
    if (fetchDiscussionError) {
      dispatchErrorMessage(
        "Unable to fetch the discussion for editing. Please try again later."
      );
    }
  }, [fetchDiscussionError, dispatchErrorMessage]);

  // Form State Actions
  const handleChangeDiscussionTitle = (event) => {
    setDiscussionTitle(event.target.value);
  };
  const handleChangeMessage = (event) => setMessage(event.target.value);
  const handleChangeBooks = useCallback((books) => setBooks(books), []);

  const formSubmittingNotice = (
    <Grid item xs={12} className={classes.formSubmittingNotice}>
      <Grid item>
        <Banner
          align="center"
          message="Please be patient until we process images and submit your discussion..."
        />
      </Grid>
    </Grid>
  );

  const getValidatedDiscussion = (discussion) => {
    setValidationErrors([]);
    const { validatedObject, errors } = validateObject(
      discussion,
      discussionSchema
    );
    if (errors.length) {
      setValidationErrors(errors);
      return false;
    } else {
      return validatedObject;
    }
  };

  const getFieldError = (fieldName) =>
    extractFieldError(validationErrors, fieldName);

  const submitDiscussion = () => {
    const formData = {
      title: discussionTitle,
      message,
      books,
    };

    const validatedDiscussion = getValidatedDiscussion(formData);
    if (!validatedDiscussion) {
      dispatchErrorMessage(
        "Some of the fields contain invalid values. Please check again!"
      );
      return;
    }

    setPageDirty(false);
    setFormSubmitting(true);
    // If form submission take more than 1 second, display the banner to convince the
    // user to wait.
    const delayTimer = setTimeout(() => {
      setShowDelayBanner(true);
    }, 1000);

    Promise.all(
      formData.books.map((book) => {
        return Promise.all(
          book.images.map((dataUrl) => {
            if (isDataUrl(dataUrl)) {
              const formData = new FormData();
              const imageBlob = dataURLtoBlob(dataUrl);
              if (!imageBlob) return Promise.resolve();
              formData.append("file", imageBlob);
              return fetch(`${IMAGE_SERVER_URL}/upload/xl`, {
                method: "POST",
                body: formData,
                credentials: "include",
              }).then((response) => {
                if (response.status === 201) {
                  return response.json();
                }
                return Promise.reject("failed to upload image");
              });
            } else {
              return { fileName: dataUrl };
            }
          })
        )
          .then((mediaContents) =>
            mediaContents.map(
              (media) => (media.fileName || "").split("/").pop() // Extract the image name from absolute URL
            )
          )
          .then((images) => ({ ...book, images }));
      })
    )
      .then((books) => {
        if (isUpdateOperation) {
          return updateDiscussion({
            variables: {
              discussion: {
                id: discussionId,
                ...validatedDiscussion,
                books: books.map(stripBookMetaProps),
              },
            },
          });
        } else {
          return createDiscussion({
            variables: {
              discussion: {
                ...validatedDiscussion,
                books: books.map(stripBookMetaProps),
                groupId: targetGroupId,
              },
            },
          });
        }
      })
      .then(({ data }) => {
        if (!data?.discussion?.id) {
          dispatchErrorMessage(
            `Something went wrong while ${
              isUpdateOperation ? "updating" : "creating"
            } the discussion. Please try again later!`
          );
        } else {
          if (targetGroupId) {
            history.push(
              `/group/${targetGroupId}/bookchat/${data.discussion.id}`
            );
          } else {
            history.push(`/bookchat/${data.discussion.id}`);
          }
        }
      })
      .catch(() => {
        dispatchErrorMessage(
          `Something went wrong while ${
            isUpdateOperation ? "updating" : "creating"
          } the discussion. Please try again later!`
        );
      })
      .finally(() => {
        setFormSubmitting(false);
        setShowDelayBanner(false);
        clearTimeout(delayTimer);
      });
  };

  const getBookManager = () => {
    // Book manager doesn't re-render based on the 'books'. Therefore, books property
    // has to be passed when books are available.
    if (isUpdateOperation) {
      if (discussionData?.discussion) {
        return (
          <BookManager
            newOnly
            books={discussionData?.discussion?.books || []}
            disabled={fetchingDiscussion || formSubmitting}
            onUpdateBooks={handleChangeBooks}
          />
        );
      }
    } else {
      return (
        <BookManager
          newOnly
          books={books || []}
          disabled={fetchingDiscussion || formSubmitting}
          onUpdateBooks={handleChangeBooks}
        />
      );
    }
  };

  const onSetPublic = () => {
    setTargetGroupId(null);
  };

  const onChangeTargetGroupId = (groupId) => {
    setTargetGroupId(groupId);
  };

  const editorTitle = discussionId ? t`Edit Discussion` : t`Start Discussion`;
  return (
    <Grid container justifyContent="center">
      {isUpdateOperation ? (
        <PageHead
          title={t`Edit Discussion - ${discussionTitle} | bibliocircle`}
        />
      ) : (
        <PageHead title={t`Start Discussion | bibliocircle`} />
      )}
      <Grid item xs={11} sm={10} lg={12} className={classes.title}>
        <Grid container justifyContent="center">
          <PageTitle>{editorTitle}</PageTitle>
        </Grid>
      </Grid>
      <Grid item xs={11} sm={10} lg={8}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container className={classes.editorSection}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControlField className={classes.spannedInput}>
                          <InputLabel
                            shrink
                            color="primary"
                            htmlFor="bootstrap-input"
                          >
                            <Required>
                              <Trans>Title</Trans>
                            </Required>
                          </InputLabel>
                          <BootstrapInputBordered
                            inputProps={{ maxlength: 200 }}
                            value={discussionTitle}
                            onChange={handleChangeDiscussionTitle}
                            error={getFieldError("title")}
                            disabled={fetchingDiscussion || formSubmitting}
                          />
                          <FormHelperText hidden={!getFieldError("title")}>
                            <Typography variant="caption">
                              {getFormattedError(getFieldError("title"))}
                            </Typography>
                          </FormHelperText>
                        </FormControlField>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlField className={classes.spannedInput}>
                          <InputLabel
                            shrink
                            color="primary"
                            htmlFor="bootstrap-input"
                          >
                            <Required>
                              <Trans>Message</Trans>
                            </Required>
                          </InputLabel>
                          <BootstrapInputBordered
                            className={classes.messageText}
                            inputProps={{ maxlength: 10000 }}
                            value={message}
                            onChange={handleChangeMessage}
                            disabled={fetchingDiscussion || formSubmitting}
                            error={getFieldError("message")}
                            multiline
                            rows={10}
                          />
                          <FormHelperText hidden={!getFieldError("message")}>
                            <Typography variant="caption">
                              {getFormattedError(getFieldError("message"))}
                            </Typography>
                          </FormHelperText>
                        </FormControlField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.editorSection}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          <Trans>
                            Is your discussion about a book? If so, you can add
                            the book details to let others discover your
                            discussion easily. You can also add more than one
                            book if they are related to the discussion.
                          </Trans>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {getBookManager()}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.editorSection}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          {isUpdateOperation
                            ? t`You cannot change the location of this discussion now.`
                            : t`Select where you want to post this discussion in.`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <GroupSelector
                          radio
                          sharePublic={!targetGroupId}
                          targetGroups={targetGroupId ? [targetGroupId] : []}
                          isCurrentUserAMember={true}
                          onSelectPublic={onSetPublic}
                          onAddTargetGroup={onChangeTargetGroupId}
                          disabled={isUpdateOperation}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {showDelayBanner && (
                <Grid item xs={12}>
                  {formSubmittingNotice}
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={submitDiscussion}
                    disabled={fetchingDiscussion || formSubmitting}
                  >
                    {isUpdateOperation
                      ? t`Update Discussion`
                      : t`Start Discussion`}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
