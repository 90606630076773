import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { capitalCase } from "change-case";

const useStyles = makeStyles((theme) => ({
  levelsContainer: {
    padding: theme.spacing(2),
  },
  levels: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.extraLighter,
    border: "1px solid",
    borderColor: theme.palette.primary.light,
    "& *": {
      fontWeight: "bold",
    },
  },
  levelTitle: {
    fontSize: "16px",
    color: theme.palette.grey[800],
  },
  veriticalDividerContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none",
    },
  },
  horizontalDividerContainer: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  divider: {
    backgroundColor: theme.palette.primary.light,
  },
  pointStats: {
    color: theme.palette.primary.main,
  },
}));

const simplifyUserPoints = (points) => {
  if (points < 1000) return `${points}`;
  return `${(points / 1000).toFixed(1)}k`;
};

export default function UserLevels({ levels }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.levelsContainer}>
      <Grid item xs={12} className={classes.levels}>
        <Grid container spacing={2}>
          <Grid item md xs={12}>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  align="center"
                  className={classes.levelTitle}
                >
                  {capitalCase(levels.criticTitle)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  align="center"
                  className={classes.pointStats}
                >
                  {simplifyUserPoints(levels.criticPoints)} Points &bull; Level{" "}
                  {levels.criticLevel}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid md xs={12} className={classes.horizontalDividerContainer}>
            <Divider variant="middle" className={classes.divider} />
          </Grid>
          <Grid
            md={1}
            container
            justifyContent="center"
            xs={12}
            className={classes.veriticalDividerContainer}
          >
            <Divider
              variant="middle"
              orientation="vertical"
              className={classes.divider}
            />
          </Grid>
          <Grid item md xs={12}>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  align="center"
                  className={classes.levelTitle}
                >
                  {capitalCase(levels.bibliophileTitle)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  align="center"
                  className={classes.pointStats}
                >
                  {simplifyUserPoints(levels.bibliophilePoints)} Points &bull;
                  Level {levels.bibliophileLevel}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

UserLevels.propTypes = {
  levels: PropTypes.object.isRequired,
};
