import React, { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  Tabs,
  Tab,
  Typography,
  Link,
} from "@material-ui/core";
import ShopHeading from "./ShopHeading";
import { useMutation, useQuery } from "@apollo/client";
import { GET_SHOP } from "../../consts/queries";
import { useParams, Switch, Route, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actionTypes } from "../index.reducer";
import SearchGroup from "../Search/SearchGroup";
import ShopHomePageContent from "./ShopHomePageContent";
import ShopSearch from "./ShopSearch";
import ShopTabs from "./ShopTabs";
import ShopOrders from "../Orders/IncomingOrders";
import Hyperlink from "../Common/Hyperlink";
import ViewShopIcon from "@material-ui/icons/Web";
import OrdersIcon from "@material-ui/icons/ShoppingBasket";
import InactiveAdsIcon from "@material-ui/icons/VisibilityOff";
import MobileAddIcon from "@material-ui/icons/AddCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import UpdateShop from "./UpdateShop";
import {
  TOGGLE_FAVOURITE_SHOP,
  TOGGLE_FOLLOW_SHOP,
} from "../../consts/mutations";
import { GENERIC_ALERT_ERROR_MESSAGE } from "../../config";
import ShopAboutPage from "./ShopAboutPage";
import PageHead from "../Common/PageHead";
import ErrorPage from "../Error/ErrorPage";
import { useLoader, useLoggedInUser } from "../../utils/hooks";
import ShopInactiveAds from "./ShopAdminPages/ShopInactiveAds";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  headingContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  searchGroup: {
    paddingTop: theme.spacing(3),
  },
  searchGroupWithBackdrop: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    background: "rgba(250, 250, 250, 0.6)",
  },
  shopContent: {
    marginTop: theme.spacing(3),
    overflow: "hidden",
  },
  shopOrders: {
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(3),
    },
  },
  adminTab: {
    margin: theme.spacing(0.5),
    border: "1px solid",
    borderColor: theme.palette.grey[100],
    minWidth: "130px",
    backgroundColor: "rgba(250, 250, 250, 0.7)",
    borderRadius: theme.spacing(2),
    "&:hover": {
      backgroundColor: "rgba(250, 250, 250, 0.9)",
      borderColor: theme.palette.grey[300],
    },
  },
  updateShopForm: {
    marginTop: theme.spacing(3),
  },
  desktopTabContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    paddingTop: theme.spacing(2),
  },
  desktopOnly: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobileOnly: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  adminTabsContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  shopCover: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginBottom: theme.spacing(3),
  },
  shopClosedBanner: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2),
    },
    paddingTop: theme.spacing(3),
    backgroundColor: theme.palette.secondary.extraLighter,
    padding: theme.spacing(2),
  },
  shopClosedBannerText: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
  shopContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}));

export default function Shop() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { shopIdentifier } = useParams();
  const loggedInUser = useLoggedInUser();
  const [isAdminView, setAdminView] = useState(false);
  const { loading, error, data } = useQuery(GET_SHOP, {
    variables: { identifier: shopIdentifier },
  });
  const [toggleFavouriteShop] = useMutation(TOGGLE_FAVOURITE_SHOP);
  const [toggleFollowShop] = useMutation(TOGGLE_FOLLOW_SHOP);

  const getTabPageUrl = (relativePath) =>
    `/shop/${shopIdentifier}${relativePath}`;

  useEffect(() => {
    if (data?.shop) {
      setAdminView(data.shop.owner?.id === loggedInUser?.id);
    }
  }, [dispatch, loggedInUser, data]);
  useLoader(loading);

  if (error) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the shop you were looking for. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  if (!data) return null;
  const { shop } = data;

  if (!shop) {
    return (
      <ErrorPage
        title={t`Not Found`}
        description={t`The shop you are looking for does not exist!`}
        statusCode={404}
      />
    );
  }

  const adminTabs = [
    {
      id: "VIEW_SHOP",
      relativePath: "",
      label: t`View Shop`,
      icon: <ViewShopIcon />,
    },
    {
      id: "MANAGE_ORDERS",
      relativePath: "/orders",
      label: t`Manage Orders`,
      icon: <OrdersIcon />,
    },
    {
      id: "INACTIVE_ADS",
      relativePath: "/inactive-ads",
      label: t`Inactive Ads`,
      icon: <InactiveAdsIcon />,
    },
    {
      id: "SHOP_SETTINGS",
      relativePath: "/edit",
      label: t`Shop Settings`,
      icon: <SettingsIcon />,
    },
  ];

  const getCurrentAdminTabId = () => {
    const path = history.location.pathname;
    try {
      const [, relativePath] = path.match(
        new RegExp(`/shop/${shopIdentifier}/(.+)`)
      );
      return adminTabs.find((tab) => tab.relativePath === `/${relativePath}`)
        .id;
    } catch (err) {
      return adminTabs[0].id;
    }
  };

  const handleToggleFollowShop = () => {
    if (!loggedInUser) {
      const { pathname, search } = history.location;
      return history.push("/login", { redirectPath: `${pathname}${search}` });
    }

    toggleFollowShop({
      variables: {
        shopId: shop.id,
      },
    }).catch(() => {
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message: GENERIC_ALERT_ERROR_MESSAGE,
      });
    });
  };

  const handleToggleFavouriteShop = () => {
    if (!loggedInUser) {
      const { pathname, search } = history.location;
      return history.push("/login", { redirectPath: `${pathname}${search}` });
    }

    toggleFavouriteShop({
      variables: {
        shopId: shop.id,
      },
    }).catch(() => {
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message: GENERIC_ALERT_ERROR_MESSAGE,
      });
    });
  };

  const editShopRoute = (
    <Route path="/shop/:shopIdentifier/edit">
      <PageHead title={`Settings - ${shop.name} | bibliocircle`} />
      <Grid container className={classes.updateShopForm}>
        <UpdateShop />
      </Grid>
    </Route>
  );

  const shopOrdersRoute = (
    <Route path="/shop/:shopIdentifier/orders">
      <PageHead title={`Orders - ${shop.name} | bibliocircle`} />
      <Grid container justifyContent="center" className={classes.shopOrders}>
        <Grid item xs={12} lg={11}>
          <ShopOrders shopId={shop.id} key={history.location.key} />
        </Grid>
      </Grid>
    </Route>
  );

  const inactiveAdsRoute = (
    <Route path="/shop/:shopIdentifier/inactive-ads">
      <ShopInactiveAds shop={shop} />
    </Route>
  );

  return (
    <Grid container>
      <Grid
        container
        className={classes.shopCover}
        style={{
          backgroundImage: `url(${shop.coverImage ?? "/background.jpg"})`,
        }}
      >
        {shop.closed && (
          <Grid item xs={12} className={classes.shopClosedBanner}>
            <Grid container justifyContent="center" alignItems="center">
              <Typography
                type="body1"
                className={classes.shopClosedBannerText}
                color="secondary"
              >
                {isAdminView ? (
                  <>
                    <Trans>
                      You have disabled online ordering therefore your customers
                      won't be able to place orders. If you want to re-enable
                      online ordering, you can do so in
                    </Trans>{" "}
                    <Hyperlink to={`/shop/${shop.identifier}/edit`}>
                      <Link color="secondary" underline="always">
                        <Trans>shop settings</Trans>
                      </Link>
                    </Hyperlink>
                    .
                  </>
                ) : (
                  <Trans>
                    {shop.name} is not temporarily accepting online orders.
                  </Trans>
                )}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            className={classes.searchGroupWithBackdrop}
          >
            <SearchGroup
              shopScope={{
                id: shop.id,
                identifier: shop.identifier,
              }}
              placeholder={`Search in ${shop.name}`}
            />
          </Grid>
          <Grid container justifyContent="center">
            {isAdminView && (
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="center"
                  className={classes.adminTabsContainer}
                >
                  <Tabs
                    value={getCurrentAdminTabId()}
                    textColor="primary"
                    TabIndicatorProps={{ style: { display: "none" } }}
                    orientation="horizontal"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      fullWidth
                      className={classes.adminTab}
                      icon={<MobileAddIcon />}
                      component={Hyperlink}
                      to={`/listing/create?shopScope=${shop.id}`}
                      disableRipple
                      label={t`Create Ad`}
                    />
                    {adminTabs.map((tab) => (
                      <Tab
                        key={tab.id}
                        fullWidth
                        className={classes.adminTab}
                        icon={tab.icon}
                        component={Hyperlink}
                        to={getTabPageUrl(tab.relativePath)}
                        value={tab.id}
                        disableRipple
                        label={tab.label}
                      />
                    ))}
                  </Tabs>
                </Grid>
              </Grid>
            )}
            <Grid item xs={11} lg={12}>
              <Grid container item justifyContent="center">
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} lg={isAdminView ? 10 : 8}>
                      <Grid
                        container
                        className={classes.headingContainer}
                        justifyContent="center"
                      >
                        <Grid item xs={12}>
                          <ShopHeading
                            shop={shop}
                            onClickToggleFavourites={handleToggleFavouriteShop}
                            onClickToggleFollow={handleToggleFollowShop}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Switch>
          {isAdminView && [editShopRoute, shopOrdersRoute, inactiveAdsRoute]}
          <Route>
            <PageHead title={`${shop.name} | bibliocircle`} />
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={isAdminView ? 11 : 10}>
                <Grid container className={classes.shopContainer}>
                  <Grid item xs={12}>
                    <ShopTabs shop={shop} viewAsAdmin={isAdminView} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container className={classes.shopContent}>
                      <Switch>
                        <Route
                          path="/shop/:shopIdentifier/listings"
                          render={(props) => (
                            <ShopSearch shop={shop} key={props.location.key} />
                          )}
                        />
                        <Route path="/shop/:shopIdentifier/about">
                          <Grid container>
                            <ShopAboutPage shop={shop} />
                          </Grid>
                        </Route>
                        <Route path="/shop/:shopIdentifier">
                          <ShopHomePageContent
                            shop={shop}
                            viewAsAdmin={isAdminView}
                          />
                        </Route>
                      </Switch>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Route>
        </Switch>
      </Grid>
    </Grid>
  );
}
