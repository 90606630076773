export function validateObject(obj, schema) {
  if (!schema?.validate) {
    throw new Error("provided schema is not a valid");
  }
  const result = schema.validate(obj, { abortEarly: false });
  const validatedObject = result?.value;
  const invalid =
    Array.isArray(result?.error?.details) && result.error.details.length;
  const errors = invalid ? result.error.details : [];
  return {
    validatedObject,
    isValid: !invalid,
    errors,
  };
}

export async function validateObjectAsync(obj, schema) {
  if (!schema?.validate) {
    throw new Error("provided schema is not a valid");
  }

  let validatedObject = null;
  let errors = [];
  try {
    validatedObject = await schema.validateAsync(obj, { abortEarly: false });
  } catch (err) {
    errors = err.details || [];
  }
  const invalid = errors.length;
  return {
    validatedObject,
    isValid: !invalid,
    errors,
  };
}

const errorMap = {
  "any.custom": "Field value is invalid",
  "any.invalid": "Field value is invalid",
  "any.only": "",
  "any.ref": "",
  "any.required": "This field is required",
  "any.unknown": "",
  "array.min": "Please select at least one",
  "date.base": "Field value should be a valid date",
  "identifier.unavailable":
    "This is already taken. Please select a different identifier for the URL",
  "number.base": "Field value should be a number",
  "number.greater": "",
  "number.infinity": "",
  "number.integer": "Field value should be an integer",
  "number.less": "",
  "number.max": "",
  "number.min": "",
  "number.multiple": "",
  "number.negative": "",
  "number.port": "",
  "number.positive": "",
  "number.precision": "",
  "number.unsafe": "",
  "string.alphanum": "",
  "string.base64": "",
  "string.base": "Field value should be a valid text",
  "string.creditCard": "",
  "string.dataUri": "",
  "string.domain": "",
  "string.email": "Field value should be a valid email",
  "string.empty": "",
  "string.guid": "",
  "string.hexAlign": "",
  "string.hex": "",
  "string.hostname": "",
  "string.ipVersion": "",
  "string.ip": "",
  "string.isoDate": "",
  "string.isoDuration": "",
  "string.length": "",
  "string.lowercase": "",
  "string.max": "",
  "string.min": "",
  "string.normalize": "",
  "string.pattern.base": "Field value format is invalid",
  "string.pattern.name": "",
  "string.pattern.invert.base": "",
  "string.pattern.invert.name": "",
  "string.token": "",
  "string.trim": "",
  "string.uppercase": "",
  "string.uri": "",
  "string.uriCustomScheme": "",
  "string.uriRelativeOnly": "",
};

export function extractFieldError(validationErrors, fieldName) {
  // This function doesn't support extracting field errors for
  // individual array items when Joi.array().items(<schema>) is used.
  // TODO: Room for improvement. Removing array elements for the moment.
  return validationErrors.find(
    (e) =>
      e.path
        .filter((pathComponent) => !(typeof pathComponent === "number"))
        .join(".") === fieldName
  );
}

export function excludeFieldError(validationErrors, fieldName) {
  return validationErrors.filter((e) => e.path.join(".") !== fieldName);
}

export function getFormattedError(joiError) {
  return errorMap[joiError?.type] || "Field value is invalid";
}
