import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MediaContentViewer from "../../Common/MediaContentViewer";

const useStyles = makeStyles((theme) => ({
  coverImageContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
  },
  coverImage: {
    width: "100%",
    height: "200px",
    objectFit: "contain",
  },
  placeholderIcon: {
    color: theme.palette.primary.main,
  },
  itemContent: {
    color: theme.palette.grey[800],
    paddingLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  bookCard: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  priceTagContainer: {
    maxWidth: 150,
    marginBottom: theme.spacing(1),
  },
  actionButtonsContainer: {
    marginTop: theme.spacing(1),
  },
  bookDescription: {
    whiteSpace: "pre-wrap",
  },
}));

export default function BookCard({
  book,
  onClickEdit,
  onClickRemove,
  disabled,
}) {
  const classes = useStyles();
  const cardClass = classes.bookCard;

  const actionButtons = (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      spacing={1}
      className={classes.actionButtonsContainer}
    >
      <Grid item>
        <Button
          startIcon={<EditIcon />}
          color="primary"
          variant="text"
          size="small"
          onClick={onClickEdit}
          disabled={disabled}
        >
          Update Book Details
        </Button>
      </Grid>
      <Grid item>
        <Button
          startIcon={<DeleteIcon />}
          color="secondary"
          variant="text"
          size="small"
          onClick={onClickRemove}
          disabled={disabled}
        >
          Remove
        </Button>
      </Grid>
    </Grid>
  );

  const bookCard = (
    <Grid container className={cardClass}>
      <Grid container item xs={12} md={4} alignItems="center">
        <Grid
          item
          className={classes.coverImageContainer}
          justifyContent="center"
          alignContent="stretch"
          alignItems="center"
        >
          <MediaContentViewer urls={book.images} />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={8}
        alignContent="flex-start"
        spacing={1}
        className={classes.itemContent}
      >
        <Grid container item xs={12}>
          <Typography variant="h6">{book.title}</Typography>
        </Grid>
        <Grid container item xs={12} className={classes.bookDescription}>
          <Typography variant="body2">{book.description}</Typography>
        </Grid>
        {actionButtons}
      </Grid>
    </Grid>
  );

  return bookCard;
}

BookCard.propTypes = {
  book: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func,
  onClickRemove: PropTypes.func,
  disabled: PropTypes.bool,
};
