import { Grid, makeStyles, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import BootstrapInput from "../../Common/BootstrapInput";
import PageHead from "../../Common/PageHead";
import debounce from "lodash.debounce";
import ListingsSearchController from "../../Search/ListingsSearchController";

const useStyles = makeStyles((theme) => ({
  inactiveAds: {
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(3),
    },
  },
  searchBar: {
    marginBottom: theme.spacing(3),
  },
}));

export default function ShopInactiveAds({ shop }) {
  const history = useHistory();
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState(null);
  const onChangeSearchQuery = (event) => setSearchQuery(event.target.value);
  const debouncedSearch = debounce(onChangeSearchQuery, 800);

  return (
    <>
      <PageHead title={`Inactive Ads - ${shop.name} | bibliocircle`} />
      <Grid container className={classes.inactiveAds} justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.searchBar}>
              <Grid container justifyContent="center">
                <Grid item xs={12} lg={10}>
                  <Paper elevation={2}>
                    <BootstrapInput
                      fullWidth
                      placeholder="Search Inactive Ads"
                      bordered
                      onChange={debouncedSearch}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <ListingsSearchController
                  key={history.location.key}
                  cachePolicy="cache-first"
                  searchArgs={{
                    query: searchQuery,
                    shopScope: shop.id,
                    active: false,
                  }}
                  noResultsMessage="No results"
                  showEndOfResultsBanner={false}
                  xs={12}
                  md={6}
                  lg={6}
                  xl={4}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
