import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { t } from "@lingui/macro";
import ShopsSearchController from "../../Shop/ShopsSearchController";

export default function UserFollowedShopsPage({ searchQuery }) {
  return (
    <Grid container>
      <ShopsSearchController
        xs={12}
        md={6}
        searchArgs={{ query: searchQuery, isFollowed: true }}
        noResultsMessage={t`No followed shops found!`}
      />
    </Grid>
  );
}

UserFollowedShopsPage.propTypes = {
  searchQuery: PropTypes.string,
};
