import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Grid, Typography, makeStyles, Button } from "@material-ui/core";
import { GET_LISTINGS_IN_CART } from "../../consts/queries";
import PageTitle from "../Common/PageTitle";
import NoResults from "../Common/NoResultsImage";
import { actionTypes } from "../index.reducer";
import { useDispatch } from "react-redux";
import groupBy from "lodash.groupby";
import CartGroup from "./CartGroup";
import { CREATE_ORDER } from "../../consts/mutations";
import { useHistory } from "react-router-dom";
import { BC_CART_COOKIE_NAME } from "../../config";
import PageHead from "../Common/PageHead";
import ClearCartIcon from "@material-ui/icons/RemoveShoppingCart";
import { useLoader, useLoggedInUser } from "../../utils/hooks";
import { t, Trans } from "@lingui/macro";
import { useLazyQuery, useMutation } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  orderSummaryTitle: {
    fontWeight: "bold",
    color: theme.palette.grey[700],
  },
  cartTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  shopName: {
    fontWeight: "bold",
    color: theme.palette.grey[600],
  },
}));

const getCartItems = (cart, listings) => {
  return listings
    .filter((listing) => cart.some((item) => item.listingId === listing.id))
    .map((listing) => ({
      listing,
      quantity: cart.find((item) => item.listingId === listing.id).quantity,
    }));
};

const cartEmpty = (cart) => {
  return !cart || (Array.isArray(cart) && !cart.length);
};

export default function Checkout() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedInUser = useLoggedInUser();
  const [createOrder, { loading: creatingOrderInProgress }] =
    useMutation(CREATE_ORDER);
  const [cookies, setCookie, removeCookie] = useCookies([BC_CART_COOKIE_NAME]);
  const [cartItems, setCartItems] = useState([]);
  const cart = cookies[BC_CART_COOKIE_NAME] || [];
  const isCartEmpty = cartEmpty(cart);
  useLoader(creatingOrderInProgress);

  const handleClearCart = () => {
    removeCookie(BC_CART_COOKIE_NAME, { path: "/" });
  };

  const updateQuantityInCookie = (listingId, newQuantity) => {
    setCookie(
      BC_CART_COOKIE_NAME,
      cart.map((item) => {
        if (item.listingId === listingId) item.quantity = newQuantity;
        return item;
      }),
      { path: "/" }
    );
  };

  const onChangeItemQuantity = (listingId) => (quantity) => {
    updateQuantityInCookie(listingId, quantity > 1 ? quantity : 1);
  };

  const removeItemsFromCart = (listingIds = []) => {
    setCookie(
      BC_CART_COOKIE_NAME,
      cart.filter((item) => !listingIds.includes(item.listingId)),
      { path: "/" }
    );
  };

  const removeItemFromCart = (listingId) => removeItemsFromCart([listingId]);

  const [getListings, { loading, error, data }] =
    useLazyQuery(GET_LISTINGS_IN_CART);
  useLoader(loading);

  useEffect(() => {
    dispatch({
      type: actionTypes.EMIT_ERROR,
    });
  }, [dispatch, error]);

  useEffect(() => {
    if (!isCartEmpty) {
      getListings({
        variables: {
          listingIds: cart.map((item) => item.listingId),
        },
      });
    }
  }, [getListings, isCartEmpty, cart]);

  useEffect(() => {
    if (!isCartEmpty) {
      const listings = data?.searchListings?.listings || [];
      if (listings.length) {
        setCartItems(getCartItems(cart, listings));
      }
    }
  }, [data, cart, isCartEmpty]);

  const cartItemsByShop = groupBy(cartItems, (item) => {
    const shopId = item.listing?.shop?.id;
    const sellerId = item.listing?.seller?.id;
    if (shopId) return shopId;
    if (sellerId) return sellerId;
    return null;
  });

  const onPlaceOrder = (orderContent) => {
    const { pathname, search } = history.location;
    if (!loggedInUser)
      return history.push("/login", { redirectPath: `${pathname}${search}` });
    createOrder({
      variables: {
        order: orderContent,
      },
    })
      .then(({ data: responseData }) => {
        const createdOrder = responseData.createOrder;
        dispatch({
          type: actionTypes.INFO_MESSAGE,
          message: t`Successfully placed the order!`,
        });
        if (createdOrder) {
          removeItemsFromCart(orderContent.items.map((item) => item.id));
          history.push(`/order/${createdOrder.id}`);
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ERROR_MESSAGE,
          message: t`Failed to place order! Please try again later`,
        });
      });
  };

  const cartEmptyContent = (
    <Grid item container xs={12} spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h5" color="primary" align="center">
          <Trans>Your shopping cart is empty!</Trans>
        </Typography>
      </Grid>
      <Grid item xs={10} sm={6} lg={4}>
        <NoResults />
      </Grid>
    </Grid>
  );

  const getCartGroup = (groupedCartItems) => {
    if (!groupedCartItems?.length) return null;
    const shop = groupedCartItems[0].listing.shop;
    const individualSeller = groupedCartItems[0].listing.seller;
    return (
      <CartGroup
        key={shop?.id || individualSeller?.id}
        items={groupedCartItems}
        shop={shop}
        seller={individualSeller}
        onChangeItemQuantity={onChangeItemQuantity}
        onRemoveCartItem={removeItemFromCart}
        onPlaceOrder={onPlaceOrder}
      />
    );
  };

  return (
    <Grid container justifyContent="center">
      <PageHead title="Shopping Cart | bibliocircle" />
      <Grid container item xs={11} md={10} spacing={3}>
        <Grid item xs={12} className={classes.cartTitle}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container alignItems="center">
                <PageTitle variant="h4">
                  <Trans>Shopping Cart</Trans>
                </PageTitle>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  startIcon={<ClearCartIcon />}
                  onClick={handleClearCart}
                >
                  <Trans>Empty Cart</Trans>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isCartEmpty
            ? cartEmptyContent
            : Object.values(cartItemsByShop).map(getCartGroup)}
        </Grid>
      </Grid>
    </Grid>
  );
}
