import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { GET_RECOMMENDED_LISTINGS } from "../../../consts/queries";
import ListingCardMobile from "../../Listing/ListingCardMobile";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: "bold",
  },
}));

export default function RecommendedListings({ listingId, showDivider }) {
  const classes = useStyles();
  const { data } = useQuery(GET_RECOMMENDED_LISTINGS, {
    variables: { listingId },
    skip: !listingId,
    fetchPolicy: "cache-first",
  });

  if (!listingId) return null;
  if (!data?.recommendedListings?.length) return null;
  const { recommendedListings } = data;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {showDivider && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container>
                  <Typography variant="h6" className={classes.title}>
                    <Trans>You may also like</Trans>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {recommendedListings.map((listing) => (
                    <Grid item xs={12} sm={6}>
                      <ListingCardMobile
                        item={listing}
                        key={listing.id}
                        clickable
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

RecommendedListings.propTypes = {
  listingId: PropTypes.string.isRequired,
  showDivider: PropTypes.bool,
};
