import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Button, makeStyles } from "@material-ui/core";
import MediaContainer from "../MediaContentViewer/MediaContainer";
import AddPhotoIcon from "@material-ui/icons/AddPhotoAlternate";
import DropZone from "./dropZone";
import DropDialog from "./dropDialog";

const useStyles = makeStyles((theme) => ({
  additionalInfo: {
    paddingTop: "50px",
  },
  dialogMediaPreview: {
    marginBottom: theme.spacing(2),
  },
  imageUploaderContainer: {
    paddingTop: theme.spacing(2),
  },
}));

export default function ImageUploader({
  buttonText,
  onChange,
  onDrop,
  onDelete,
  limit,
  variant,
  initialImages,
  disabled,
}) {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [images, setImages] = useState([]);
  let imageViewer;
  const acceptedFiles = [
    "image/jpeg",
    "image/png",
    "image/bmp",
    "image/jpg",
    "image/gif",
  ];

  const openDropDialog = () => setDialogOpen(true);
  const closeDropDialog = () => setDialogOpen(false);

  const parseImageFile = (file) => {
    return new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = (evt) => {
        const dataUrl = evt.target.result;
        resolve(dataUrl);
      };
      fileReader.readAsDataURL(file);
    });
  };

  const onAttachImages = (files) => {
    closeDropDialog();
    Promise.all(files.map((file) => parseImageFile(file)))
      .then((images) => {
        onChange(images);
        setImages(images);
      })
      .catch(() => {});
  };

  const handleDrop = (files) => {
    closeDropDialog();
    Promise.all(files.map((file) => parseImageFile(file)))
      .then((images) => {
        onDrop(images);
        setImages(images);
      })
      .catch(() => {});
  };

  const handleDelete = (file, index) => {
    parseImageFile(file).then((image) => onDelete(image, index));
  };

  if (!images.length) {
    imageViewer = (
      <>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignContent="center"
        >
          <Grid item>
            <AddPhotoIcon style={{ fontSize: 96 }} color="primary" />
          </Grid>
        </Grid>
      </>
    );
  } else {
    imageViewer = <MediaContainer urls={images} />;
  }

  const dropZone = (
    <Grid container>
      <DropZone
        limit={limit}
        onChange={onAttachImages}
        onDrop={handleDrop}
        onDelete={handleDelete}
        acceptedFiles={acceptedFiles}
        initialFiles={initialImages}
        dropZoneText={buttonText}
        disabled={disabled}
      />
    </Grid>
  );

  const dropDialog = (
    <Grid container>
      <DropDialog
        limit={limit}
        open={dialogOpen}
        onClose={closeDropDialog}
        onSave={onAttachImages}
        initialFiles={initialImages}
        disabled={disabled}
      />
      <Grid container item xs={12} className={classes.dialogMediaPreview}>
        {imageViewer}
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Button
          variant="contained"
          disableElevation
          color="primary"
          component="label"
          size="small"
          className={classes.uploadButton}
          onClick={openDropDialog}
          disabled={disabled}
        >
          {buttonText}
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Grid container className={classes.imageUploaderContainer}>
      <Grid item xs={12}>
        {variant === "zone" ? dropZone : dropDialog}
      </Grid>
    </Grid>
  );
}

ImageUploader.propTypes = {
  buttonText: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  limit: PropTypes.number,
  variant: PropTypes.oneOf(["zone", "dialog"]).isRequired,
  initialImages: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

ImageUploader.defaultProps = {
  onChange() {},
  onDelete() {},
  onDrop() {},
  limit: 3,
  initialImages: [],
  disabled: false,
};
