import { combineReducers } from "redux";
import userReducer from "./Auth/auth.reducer";
import { applicationReducer } from "./Application/index.reducer";

const rootReducer = combineReducers({
  user: userReducer,
  application: applicationReducer,
});

export default rootReducer;
