import { Avatar, Grid, makeStyles, Tab, Tabs } from "@material-ui/core";
import React from "react";
import Hyperlink from "./Hyperlink";

const useStyles = makeStyles((theme) => ({
  tab: {
    margin: theme.spacing(0.5),
    minWidth: "130px",
    borderRadius: theme.shape.borderRadius,
    transitionDuration: 200,
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      transitionDuration: 200,
    },
    color: theme.palette.text.primary,
  },
  tabsContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

export default function ScrollableLinkTabs({ links, scrollButtons }) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          className={classes.tabsContainer}
        >
          <Tabs
            textColor="primary"
            TabIndicatorProps={{ style: { display: "none" } }}
            orientation="horizontal"
            variant="scrollable"
            scrollButtons={scrollButtons || "on"}
          >
            {links.map((link) => (
              <Tab
                onClick={link.onClickHandler}
                key={link.title}
                fullWidth
                className={classes.tab}
                icon={
                  <Avatar
                    className={classes.iconAvatar}
                    style={{
                      backgroundColor: link.iconBackgroundColor,
                    }}
                  >
                    <link.icon
                      className={classes.icon}
                      style={{ color: link.iconColor }}
                    />
                  </Avatar>
                }
                component={Hyperlink}
                to={link.path}
                disableRipple
                label={link.title}
              />
            ))}
          </Tabs>
        </Grid>
      </Grid>
    </Grid>
  );
}
