import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CanvaIcon from "./CanvaIcon";
import { useDispatchErrorMessage } from "../../../utils/hooks";
import ImageFadeIn from "../../Common/ImageFadeIn";

const useStyles = makeStyles((theme) => ({
  coverImage: {
    // aspect ratio 47 : 75
    width: 200,
    height: 319,
    objectFit: "cover",
  },
  coverImageContainer: {
    justifyContent: "center",
    width: "100%",
    minHeight: 319,
    backgroundColor: theme.palette.grey[100],
    border: "3px dashed",
    borderColor: theme.palette.grey[300],
  },
}));

const imageUrlToDataUrl = (imgUrl) => {
  return fetch(imgUrl)
    .then((r) => r.blob())
    .then((blob) => {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    });
};

export default function CreateBookCoverButtons({
  onAttach,
  onRemove,
  buttonProps,
  initialImage,
  canvaEnabled,
}) {
  const classes = useStyles();
  const [image, setImage] = useState(null);
  const dispatchErrorMessage = useDispatchErrorMessage();

  useEffect(() => {
    setImage(initialImage);
  }, [initialImage]);

  const onClickDesign = () => {
    if (!window.canvaApi) return;
    window.canvaApi.createDesign({
      design: {
        type: "BookCover",
      },
      onDesignPublish: function ({ exportUrl }) {
        imageUrlToDataUrl(exportUrl)
          .then((dataUrl) => {
            setImage(dataUrl);
            onAttach(dataUrl);
          })
          .catch((err) => {
            dispatchErrorMessage(
              "Unable to attach generated cover image via Canva!"
            );
          });
      },
    });
  };

  const getImageViewer = () => (
    <Grid container className={classes.coverImageContainer} alignItems="center">
      {image && <ImageFadeIn src={image} className={classes.coverImage} />}
    </Grid>
  );

  const onAttachImage = (event) => {
    Array.from(event.target.files).forEach((file) => {
      // Creating a file reader for each file due to:
      // https://stackoverflow.com/questions/24843508/filereader-error-the-object-is-already-busy-reading-blobs
      const fileReader = new FileReader();
      fileReader.onload = (evt) => {
        const dataUrl = evt.target.result;
        setImage(dataUrl);
        onAttach(dataUrl);
      };
      fileReader.readAsDataURL(file);
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {getImageViewer()}
      </Grid>
      <Grid item xs={12} justifyContent="center">
        <Grid container spacing={1}>
          {canvaEnabled && (
            <Grid item xs={6}>
              <Button
                fullWidth
                startIcon={<CanvaIcon />}
                variant="text"
                disableElevation
                disableRipple
                {...buttonProps}
                onClick={onClickDesign}
              >
                Design on Canva
              </Button>
            </Grid>
          )}
          <Grid item xs={canvaEnabled ? 6 : 12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              component="label"
              disableElevation
              disableRipple
              className={classes.uploadButton}
              {...buttonProps}
            >
              Attach Custom Image
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={onAttachImage}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

CreateBookCoverButtons.propTypes = {
  onAttach: PropTypes.func,
};

CreateBookCoverButtons.defaultProps = {
  onAttach() {},
};
