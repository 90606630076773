import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Hyperlink from "../Common/Hyperlink";

const useStyles = makeStyles((theme) => {
  const baseTabStyles = {
    cursor: "pointer",
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: 40,
    display: "table",
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  };

  const tabStyles = {
    ...baseTabStyles,
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.text.primary,
  };
  const primaryTabStyles = {
    ...baseTabStyles,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
  };

  return {
    tab: tabStyles,
    tabSelected: {
      ...tabStyles,
      boxShadow: `0 -2px ${theme.palette.grey[900]} inset`,
    },
    primaryTab: primaryTabStyles,
    primaryTabSelected: {
      ...primaryTabStyles,
      boxShadow: `0 -2px ${theme.palette.grey[900]} inset`,
    },
    iconContainer: {
      marginRight: theme.spacing(1),
      "& *": {
        fontSize: 16,
      },
    },
  };
});

export default function ShopTab({
  url,
  title,
  tags,
  pageId,
  shopIdentifier,
  primary,
  icon,
  selected,
}) {
  const classes = useStyles();
  let tabClass = null;
  let tabTarget = null;
  if (selected) {
    tabClass = primary ? classes.primaryTabSelected : classes.tabSelected;
  } else {
    tabClass = primary ? classes.primaryTab : classes.tab;
  }

  if (url) {
    tabTarget = url;
  } else {
    const queryString = new URLSearchParams({
      tags: tags.join(),
      page: pageId,
    }).toString();

    tabTarget = `/shop/${shopIdentifier}/listings?${queryString}`;
  }

  return (
    <Grid item>
      <Hyperlink to={tabTarget} className={tabClass}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          alignContent="stretch"
          className="title"
        >
          <Grid item>
            <Grid
              container
              alignItems="center"
              className={classes.iconContainer}
            >
              {icon}
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">{title}</Typography>
          </Grid>
        </Grid>
      </Hyperlink>
    </Grid>
  );
}

ShopTab.propTypes = {
  url: PropTypes.string,
  shopIdentifier: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  primary: PropTypes.bool,
  icon: PropTypes.object,
  selected: PropTypes.bool,
  pageId: PropTypes.string,
};

ShopTab.defaultProps = {
  url: null,
  tags: [],
};
