import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_SHOP } from "../../consts/mutations";
import { useHistory, useParams } from "react-router-dom";
import { IMAGE_SERVER_URL } from "../../config";
import ShopEditor from "./ShopEditor";
import { GET_SHOP_FOR_EDITING } from "../../consts/queries";
import { dataURLtoBlob, isDataUrl } from "../../utils/fileOperations";
import { DB_VALIDATION_ERRORS } from "../consts";
import { useDispatchErrorMessage, useLoader } from "../../utils/hooks";

export default function UpdateShop() {
  const dispatchErrorMessage = useDispatchErrorMessage();
  const history = useHistory();
  const { shopIdentifier } = useParams();
  const {
    loading: fetchingShop,
    error: fetchShopError,
    data: shopData,
  } = useQuery(GET_SHOP_FOR_EDITING, {
    variables: { identifier: shopIdentifier },
  });
  const [updateShop, { loading }] = useMutation(UPDATE_SHOP);
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);

  useEffect(() => {
    if (fetchShopError) {
      dispatchErrorMessage(
        "Something went wrong while fetching the shop for editing. Please try again later."
      );
    }
  }, [fetchShopError, dispatchErrorMessage]);

  useLoader(loading || fetchingShop || formSubmitInProgress);

  const uploadImage = (dataUrl, uploadSize = "md") => {
    if (!dataUrl) return Promise.resolve();
    const formData = new FormData();
    const logoBlob = dataURLtoBlob(dataUrl);
    if (!logoBlob) return Promise.resolve();
    formData.append("file", logoBlob);
    return fetch(`${IMAGE_SERVER_URL}/upload/${uploadSize}`, {
      method: "POST",
      body: formData,
      credentials: "include",
    })
      .then((response) => {
        // TODO: handle rate limit errors 429
        if (response.status === 201) return response.json();
        throw Error("failed to upload image!");
      })
      .then((logoImage) => logoImage?.fileName);
  };

  const handleUpdateShop = (shop) => {
    setFormSubmitInProgress(true);

    let logoImageUploadPromise = Promise.resolve(null);
    if (shop.logoImage) {
      if (isDataUrl(shop.logoImage)) {
        // Image was updated by the user
        logoImageUploadPromise = uploadImage(shop.logoImage, "md");
      } else {
        try {
          // Image was not updated by the user, hence use the existing file name
          logoImageUploadPromise = Promise.resolve(
            shop.logoImage.split("/").pop()
          );
        } catch {}
      }
    }

    let coverImageUploadPromise = Promise.resolve(null);
    if (shop.coverImage) {
      if (isDataUrl(shop.coverImage)) {
        // Image was updated by the user
        coverImageUploadPromise = uploadImage(shop.coverImage, "xxl");
      } else {
        try {
          // Image was not updated by the user, hence use the existing file name
          coverImageUploadPromise = Promise.resolve(
            shop.coverImage.split("/").pop()
          );
        } catch {}
      }
    }

    Promise.all([logoImageUploadPromise, coverImageUploadPromise])
      .then(([logoImage, coverImage]) => {
        const updatedDetails = {
          ...shop,
          logoImage,
          coverImage,
        };
        return updateShop({
          variables: {
            shop: updatedDetails,
          },
        });
      })
      .then(({ data }) => {
        history.push(`/shop/${data.updateShop.identifier}`);
      })
      .catch((err) => {
        if (err.graphQLErrors?.[0]?.code === DB_VALIDATION_ERRORS.DUPLICATE) {
          dispatchErrorMessage(
            "A shop already exists with the provided public URL. Please choose another identifier for your URL!"
          );
        } else {
          dispatchErrorMessage(
            "Something went wrong while updating the shop. Please contact us if the issue persists."
          );
        }
      })
      .finally(() => {
        setFormSubmitInProgress(false);
      });
  };

  return (
    <>
      {shopData?.shop?.id && (
        <ShopEditor
          shop={shopData.shop}
          onSave={handleUpdateShop}
          disabled={formSubmitInProgress}
        />
      )}
    </>
  );
}
