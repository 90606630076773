import { PLACEHOLDER_IMAGES } from "../../consts";
import { createFeedPostDataExtractor } from "./transformerUtils";

export default function UserPublishedStoryChapterTransformer(feedPost) {
  const primaryExtractor = createFeedPostDataExtractor(
    feedPost.primaryContent?.data
  );
  const secondaryExtractor = createFeedPostDataExtractor(
    feedPost.secondaryContent?.data
  );
  const chapterTitle = primaryExtractor.get("chapterTitle");
  const storyId = secondaryExtractor.get("storyId");
  return {
    actorFullName: feedPost.actor.name,
    actorImage: feedPost.actor.image || PLACEHOLDER_IMAGES.USER,
    actorPath: `/profile/${feedPost.actor.id}`,
    activityText: `published a new chapter on story "${secondaryExtractor.get(
      "storyTitle"
    )}"`,
    resourcePath: storyId ? `/story/${storyId}` : "",
    primaryContent: {
      title: chapterTitle ? `New Chapter · ${chapterTitle}` : null,
      content: null,
      images: feedPost.primaryContent?.images || [],
    },
    secondaryContentReason: null,
    secondaryContent: {
      title: secondaryExtractor.get("storyTitle"),
      content: secondaryExtractor.get("storyAbstract"),
      images: feedPost.secondaryContent?.images || [],
    },
    timestamp: feedPost.activityTimestamp,
  };
}
