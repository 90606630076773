import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Hyperlink from "../../Common/Hyperlink";
import ImageFadeIn from "../../Common/ImageFadeIn";
import { Rating } from "@material-ui/lab";
import { green } from "@material-ui/core/colors";
import { Explicit as MatureIcon } from "@material-ui/icons";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  coverImageContent: {
    maxWidth: "70px",
  },
  coverImageContainer: {
    // A hacky way to make sure the top of the
    // cover image is in-line with top of the title text
    paddingTop: theme.spacing(1),
  },
  coverImage: {
    maxHeight: 80,
    maxWidth: "100%",
    objectFit: "contain",
    borderRadius: theme.shape.borderRadius,
    boxShadow: `2px 2px ${theme.palette.grey[300]}`,
  },
  card: {
    padding: theme.spacing(0.5),
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  subtitle: {
    fontWeight: "bold",
    color: theme.palette.grey[700],
    marginBottom: theme.spacing(0.5),
  },
  metadata: {
    fontSize: 12,
    marginBottom: theme.spacing(0.5),
  },
  reviewsCount: { fontSize: 12 },
  reviewsContainer: {
    marginBottom: theme.spacing(1),
  },
  abstract: {
    fontSize: 14,
  },
  completeBadge: {
    fontWeight: "bold",
    color: green[600],
  },
  matureTitleIcon: {
    fontSize: 14,
    marginLeft: theme.spacing(0.5),
  },
}));

export default function SmallStoryCard({ story }) {
  const classes = useStyles();
  return (
    <Hyperlink to={`/story/${story.id}`}>
      <Grid container className={classes.card}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3} className={classes.coverImageContent}>
              <Grid
                container
                className={classes.coverImageContainer}
                justifyContent="center"
              >
                <Grid item>
                  <ImageFadeIn
                    src={`${story.coverImage}/xs`}
                    className={classes.coverImage}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      className={classes.title}
                    >
                      {story.title}
                    </Typography>
                    {story.matureContent && (
                      <MatureIcon
                        color="secondary"
                        className={classes.matureTitleIcon}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    className={classes.subtitle}
                  >
                    {story.subtitle}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    className={classes.metadata}
                  >
                    <Trans>{story.chaptersCount} Chapters &bull;</Trans>{" "}
                    {story.isComplete ? (
                      <span className={classes.completeBadge}>
                        <Trans>Complete</Trans>
                      </span>
                    ) : (
                      <Trans>Ongoing</Trans>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    className={classes.reviewsContainer}
                  >
                    <Grid item>
                      <Grid container alignItems="center">
                        <Rating
                          readOnly
                          size="small"
                          value={story.averageRating}
                          precision={0.1}
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center">
                        <Typography
                          variant="body2"
                          color="textPrimary"
                          className={classes.reviewsCount}
                        >
                          <Trans>
                            {story.reviewsCount} Rating
                            {story.reviewsCount === 1 ? "" : "s"}
                          </Trans>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Hyperlink>
  );
}

SmallStoryCard.propTypes = {
  story: PropTypes.object.isRequired,
};
