import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import {
  GET_LISTING,
  GET_ORDERED_LISTING_REVISION,
} from "../../consts/queries";
import ListingViewer from "./ListingViewer";
import { useLoader, useLoggedInUser, useQueryParams } from "../../utils/hooks";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Hyperlink from "../Common/Hyperlink";
import OutgoingOrderCard from "../Orders/OutgoingOrders/OutgoingOrderCard";
import IncomingOrderCard from "../Orders/IncomingOrders/IncomingOrderCard";
import PageHead from "../Common/PageHead";
import ErrorPage from "../Error/ErrorPage";
import SearchGroup from "../Search/SearchGroup";

const useStyles = makeStyles((theme) => ({
  orderRevisionBanner: {
    backgroundColor: theme.palette.primary.lighter,
    padding: theme.spacing(3),
  },
  orderRevisionBannerLink: {
    color: theme.palette.primary.main,
  },
  searchGroup: {
    paddingTop: theme.spacing(3),
    background: theme.palette.gradient.light,
    paddingBottom: "20px",
  },
}));

export default function Listing() {
  const classes = useStyles();
  const loggedInUser = useLoggedInUser();
  const { listingId } = useParams();
  const queryParams = useQueryParams();
  const orderId = queryParams.get("orderId");
  const isOrderRevision = !!orderId;
  let isOwnOrderRevision = false;
  let OrderComponent = OutgoingOrderCard;

  const fetchLatestListingRevision = useLazyQuery(GET_LISTING, {
    variables: {
      id: listingId,
    },
  });

  const fetchOrderedListingRevision = useLazyQuery(
    GET_ORDERED_LISTING_REVISION,
    {
      variables: {
        orderId,
        listingId,
      },
    }
  );

  const useFetchListing = isOrderRevision
    ? fetchOrderedListingRevision
    : fetchLatestListingRevision;
  const [fetchListing, { loading, error, data }] = useFetchListing;

  useEffect(() => {
    fetchListing();
  }, [fetchListing]);

  useLoader(loading);

  if (error) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the book you were looking for. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  let listing;
  if (!data) return null;

  if (isOrderRevision) {
    const { orderListingRevision } = data;
    listing = orderListingRevision?.listing;

    isOwnOrderRevision =
      orderListingRevision?.order?.user?.id === loggedInUser?.id;
    OrderComponent = isOwnOrderRevision ? OutgoingOrderCard : IncomingOrderCard;
  } else {
    listing = data.listing;
  }

  if (!listing) {
    return (
      <ErrorPage
        title="Unavailable"
        description="This item is either removed by its owner, or does not exist."
        statusCode={404}
      />
    );
  }

  return (
    <Grid container>
      <PageHead
        title={`${listing.title} | bibliocircle`}
        description={listing.description || listing.books[0]?.description}
      />
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        className={classes.searchGroup}
      >
        <SearchGroup />
      </Grid>
      {isOrderRevision && (
        <Grid container className={classes.orderRevisionBanner}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid container item xs={12} justifyContent="center">
                <Grid item xs={12} lg={10} xl={10}>
                  <OrderComponent order={data.orderListingRevision.order} />
                </Grid>
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                <Grid item xs={12} lg={10} xl={10}>
                  <Typography variant="body1">
                    The following advertisement is snapshot taken when the order
                    was placed, and might have been updated since then. Please{" "}
                    <Hyperlink
                      className={classes.orderRevisionBannerLink}
                      to={`/listing/${listingId}`}
                    >
                      click here
                    </Hyperlink>{" "}
                    to view the up-to-date advertisement.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <ListingViewer listing={listing} readOnly={!!orderId} />
      </Grid>
    </Grid>
  );
}

Listing.propTypes = {
  listing: PropTypes.object.isRequired,
};
