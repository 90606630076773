import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormHelperText,
  Button,
  Typography,
  makeStyles,
} from "@material-ui/core";
import FormControlField from "../FormControlField";
import InputLabel from "../InputLabel";
import BootstrapInputBordered from "../BootstrapInputBordered";
import DropDown from "../DropDown";
import { reportingReasons, reportTypes } from "../../consts";
import { useDispatch } from "react-redux";
import { REPORT_CONTENT } from "../../../consts/mutations";
import reportSchema from "./report.schema";
import {
  validateObject,
  extractFieldError,
} from "../../../utils/schemaValidator";
import { actionTypes } from "../../index.reducer";
import { useLoggedInUser } from "../../../utils/hooks";
import { useMutation } from "@apollo/client";

const useStyles = makeStyles(() => ({
  reportText: {
    lineHeight: 1.5,
  },
}));

export default function Reporting({ type, resourceId, open, onClose }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const loggedInUser = useLoggedInUser();
  const emailRequired = !loggedInUser?.email;
  const reportingTypeTitle = reportTypes.find(
    (rType) => rType.value === type
  )?.label;
  const defaultReportingReason = reportingReasons.find(
    (reason) => reason.default === true
  )?.value;

  const [reportReason, setReportReason] = useState(defaultReportingReason);
  const [reporterEmail, setReporterEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  const dispatchMessage = (message) =>
    dispatch({
      type: actionTypes.INFO_MESSAGE,
      message,
    });

  const clearForm = () => {
    setReportReason(defaultReportingReason);
    setReporterEmail(null);
    setMessage(null);
    setValidationErrors([]);
  };

  const [reportItem, { loading: reportingInProgress, error: reportingError }] =
    useMutation(REPORT_CONTENT);

  const handleChangeReportReason = (event) =>
    setReportReason(event.target.value);
  const handleChangeReporterEmail = (event) =>
    setReporterEmail(event.target.value);
  const handleChangeMessage = (event) => setMessage(event.target.value);

  const getValidatedReport = () => {
    setValidationErrors([]);
    const { validatedObject, errors } = validateObject(
      {
        resourceId,
        type,
        reason: reportReason,
        message,
        reporterEmail: emailRequired ? reporterEmail : loggedInUser.email,
      },
      reportSchema
    );
    if (errors.length) {
      setValidationErrors(errors);
      return false;
    } else {
      return validatedObject;
    }
  };

  const submitReport = () => {
    const validatedReport = getValidatedReport();
    if (validatedReport) {
      reportItem({
        variables: {
          report: validatedReport,
        },
      })
        .then(() => {
          dispatchMessage(
            "Thank you for reporting this! We will review this content and will get back to you if necessary."
          );
          onClose();
        })
        .catch(() => {})
        .finally(clearForm);
    }
  };

  const getFieldError = (fieldName) =>
    extractFieldError(validationErrors, fieldName);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      disableScrollLock
    >
      <DialogTitle>Report {reportingTypeTitle}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlField>
              <InputLabel shrink color="primary">
                Reason
              </InputLabel>
              <DropDown
                bordered
                options={reportingReasons}
                onChange={handleChangeReportReason}
                value={reportReason}
                disabled={reportingInProgress}
              />
            </FormControlField>
          </Grid>
          {emailRequired && (
            <Grid item xs={12}>
              <FormControlField>
                <InputLabel shrink color="primary">
                  Your Email Address
                </InputLabel>
                <BootstrapInputBordered
                  onChange={handleChangeReporterEmail}
                  error={getFieldError("reporterEmail")}
                  value={reporterEmail}
                  disabled={reportingInProgress}
                  type="email"
                />
                <FormHelperText error hidden={!getFieldError("reporterEmail")}>
                  A vaild email is required
                </FormHelperText>
              </FormControlField>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlField>
              <InputLabel shrink color="primary">
                Message
              </InputLabel>
              <BootstrapInputBordered
                placeholder="Why do you think this content should not be on bibliocircle?"
                onChange={handleChangeMessage}
                value={message}
                error={getFieldError("message")}
                disabled={reportingInProgress}
                className={classes.reportText}
                multiline
                rows={5}
              />
              <FormHelperText error hidden={!getFieldError("message")}>
                Message is required!
              </FormHelperText>
            </FormControlField>
          </Grid>
          {reportingError && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="secondary">
                Unfortunately we couldn't submit your report at this moment!
                Please try again later or report us via email.
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          size="large"
          onClick={onClose}
          disabled={reportingInProgress}
        >
          Cancel
        </Button>
        <Button
          variant="text"
          color="primary"
          size="large"
          onClick={submitReport}
          disabled={reportingInProgress}
        >
          Submit Report
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Reporting.propTypes = {
  type: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

Reporting.defaultProps = {
  open: false,
  onClose() {},
};
