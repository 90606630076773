import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  makeStyles,
  Container,
  Button,
  InputLabel,
  FormControl,
  withStyles,
  FormHelperText,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import BootstrapInput from "../Common/BootstrapInput";
import ImageUploader from "../Common/ImageUploader";
import LargeSwitch from "../Common/LargeSwitch";
import PageTitle from "../Common/PageTitle";
import PhoneNumberManager from "../Common/PhoneNumbers/PhoneNumberManager";
import AddressManager from "../Common/Addresses/AddressManager";
import {
  extractFieldError,
  getFormattedError,
  validateObjectAsync,
} from "../../utils/schemaValidator";
import DeliveryPreferenceEditor from "./DeliveryPreferenceEditor";
import Required from "../Common/Required";
import { t, Trans } from "@lingui/macro";
import ShopPremiumFeature from "./ShopPremiumFeature";
import { ADVANCED_UP_SHOP_TIERS } from "./shop-tiers.consts";
import getShopSchema from "./shop.schema";
import {
  useDispatchErrorMessage,
  useDispatchInfoMessage,
  usePromisifiedQuery,
  usePrompt,
} from "../../utils/hooks";
import { stripTypename } from "../../utils/common";
import { green } from "@material-ui/core/colors";
import { useMutation } from "@apollo/client";
import { TOGGLE_CLOSE_SHOP } from "../../consts/mutations";
import { CHECK_SHOP_IDENTIFIER_AVAILABILITY } from "../../consts/queries";
import { ValidationError } from "@hapi/joi";
import UnavailableIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  editorSection: {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
  title: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  imageUploader: {
    borderRadius: theme.shape.borderRadius,
  },
  createShopBtnContainerDesktop: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  createShopBtnContainerMobile: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  desktopOnly: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobileOnly: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  ordersEnabledConfig: {
    backgroundColor: green[50],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  ordersEnabledLabel: {
    fontWeight: "bold",
    color: green[800],
  },
  ordersDisabledConfig: {
    backgroundColor: theme.palette.secondary.extraLighter,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  ordersDisabledLabel: {
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  shopUrlPreview: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: "1px solid",
    borderColor: theme.palette.grey[300],
    wordWrap: "break-word",
  },
  identifierPrefix: {
    marginRight: theme.spacing(1),
  },
  identifierTaken: {
    marginLeft: theme.spacing(1),
  },
  identifierTakenIcon: {
    maxWidth: "28px",
  },
}));

const Label = withStyles((theme) => ({
  root: {
    fontSize: "20px",
    color: theme.palette.grey[700],
  },
}))(InputLabel);

const Field = withStyles({
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
})(FormControl);

export default function ShopEditor({ shop, onSave, disabled }) {
  const classes = useStyles();
  const theme = useTheme();
  const isUpdateOperation = !!shop;
  const dispatchErrorMessage = useDispatchErrorMessage();
  const dispatchInfoMessage = useDispatchInfoMessage();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [shopName, setShopName] = useState(null);
  const [shopDescription, setShopDescription] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [identifier, setIdentifier] = useState(null);
  const [contactNumberIds, setContactNumberIds] = useState([]);
  const [addressIds, setAddressIds] = useState([]);
  const [onlineOrderingEnabled, setOnlineOrderingEnabled] = useState([]);
  const [deliveryPreferences, setDeliveryPreferences] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [isPageDirty, setPageDirty] = useState(false);
  const { shopIdentifier } = useParams();
  const checkShopIdentifierAvailability = usePromisifiedQuery(
    CHECK_SHOP_IDENTIFIER_AVAILABILITY
  );
  const getFieldError = (fieldName) =>
    extractFieldError(validationErrors, fieldName);

  usePrompt("Are you sure you want to leave the page?", isPageDirty);
  useEffect(() => setPageDirty(true), []);

  const [toggleCloseShop] = useMutation(TOGGLE_CLOSE_SHOP);

  useEffect(() => {
    if (shop) {
      setShopName(shop.name);
      setShopDescription(shop.description);
      setLogoImage(shop.logoImage);
      setCoverImage(shop.coverImage);
      setIdentifier(shop.identifier);
      setContactNumberIds(shop.contactNumbers.map((contact) => contact.id));
      setAddressIds(shop.addresses.map((addr) => addr.id));
      setDeliveryPreferences(shop.deliveryPreferences);
      setOnlineOrderingEnabled(!shop.closed);
    }
  }, [shop]);

  const getSanitisedIdentifier = (rawStr) => {
    if (typeof rawStr !== "string") return "";
    return rawStr
      .replace(/[^a-z0-9-]/gi, "")
      .trim()
      .toLowerCase();
  };

  const onBlurName = (event) => {
    if (isUpdateOperation) return;
    const name = (event.target.value || "").trim();
    if (!identifier && name) {
      setIdentifier(getSanitisedIdentifier(name));
    }
  };

  const onChangeName = (event) => {
    setShopName(event.target.value);
  };

  const onChangeDescription = (event) => setShopDescription(event.target.value);
  const onChangeLogoImage = ([logoImg]) => {
    if (logoImg && logoImg !== shop?.logoImage) {
      setLogoImage(logoImg);
    }
  };
  const onDeleteLogoImage = () => setLogoImage(null);
  const onChangeCoverImage = ([coverImg]) => {
    if (coverImg && coverImg !== shop?.coverImage) {
      setCoverImage(coverImg);
    }
  };
  const onDeleteCoverImage = () => setCoverImage(null);

  const onChangeIdentifier = (event) =>
    setIdentifier(getSanitisedIdentifier(event.target.value));
  const onChangeContactNumberIds = (contactNumberId, selected) => {
    if (selected) {
      setContactNumberIds((contactIds) =>
        Array.from(new Set([...contactIds, contactNumberId]))
      );
    } else {
      setContactNumberIds((contactIds) =>
        contactIds.filter((cId) => cId !== contactNumberId)
      );
    }
  };
  const onChangeAddressIds = (addressId, selected) => {
    if (selected) {
      setAddressIds((addrIds) => Array.from(new Set([...addrIds, addressId])));
    } else {
      setAddressIds((addrIds) => addrIds.filter((aId) => aId !== addressId));
    }
  };
  const onChangeDeliveryPreferences = useCallback(
    (prefs) => setDeliveryPreferences(prefs),
    [] //eslint-disable-line react-hooks/exhaustive-deps
  );

  const onChangeOrderingEnabled = (event) => {
    setOnlineOrderingEnabled(event.target.checked);
    const orderingSwitchError = t`Something went wrong while changing the option. Please try again, or contact support.`;
    if (!shop?.id) return;
    toggleCloseShop({
      variables: {
        id: shop.id,
      },
    })
      .then(({ data }) => {
        if (data?.toggleCloseShop) {
          if (data.toggleCloseShop.closed) {
            setOnlineOrderingEnabled(false);
            dispatchInfoMessage(
              t`Online Ordering is now enabled. Customers will be able to place orders on your shop.`
            );
          } else {
            setOnlineOrderingEnabled(true);
            dispatchInfoMessage(
              t`Online Ordering is now DISABLED. Customers will NOT be able to place orders on your shop.`
            );
          }
          return;
        }
        dispatchErrorMessage(orderingSwitchError);
      })
      .catch((err) => {
        dispatchErrorMessage(orderingSwitchError);
      });
  };

  const isIdentifierAvailable = async (chosenIdentifier) => {
    try {
      const { data } = await checkShopIdentifierAvailability({
        identifier: chosenIdentifier,
        currentIdentifier: shop?.identifier,
      });

      // Skip validation if the back-end sent null for availabilityResult
      return data.availabilityResult ? data.availabilityResult.available : true;
    } catch {
      // Ignore if the availability check request failed
      return true;
    }
  };

  const identifierAvailabilityValidator = async (sIdentifier) => {
    const available = await isIdentifierAvailable(sIdentifier);
    if (!available) {
      throw new ValidationError("Provided identifier is not available", [
        {
          type: "identifier.unavailable",
          path: ["identifier"],
        },
      ]);
    }
  };

  const getValidatedShop = async (rawShop) => {
    setValidationErrors([]);
    const shopSchema = getShopSchema(identifierAvailabilityValidator);
    const { validatedObject, errors } = await validateObjectAsync(
      rawShop,
      shopSchema
    );
    if (errors.length) {
      setValidationErrors(errors);
      return false;
    } else {
      return validatedObject;
    }
  };

  const onClickSave = async () => {
    const rawShopData = {
      name: shopName,
      description: shopDescription,
      logoImage,
      coverImage,
      identifier,
      contactNumberIds,
      addressIds,
      deliveryPreferences: Array.isArray(deliveryPreferences)
        ? deliveryPreferences.map(stripTypename)
        : [],
    };
    const validatedShop = await getValidatedShop(rawShopData);
    if (!validatedShop) {
      dispatchErrorMessage(
        "Some of the fields contain invalid values. Please check again!"
      );
      return;
    }
    setPageDirty(false);
    if (isUpdateOperation) {
      onSave({
        ...validatedShop,
        id: shop.id,
      });
    } else {
      onSave(validatedShop);
    }
  };

  const editorTitle = shopIdentifier ? null : t`Create Shop`;

  const onlineOrdersSwitch = shop ? (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={
          onlineOrderingEnabled
            ? classes.ordersEnabledConfig
            : classes.ordersDisabledConfig
        }
      >
        <Grid item>
          <Typography
            variant="subtitle1"
            className={
              onlineOrderingEnabled
                ? classes.ordersEnabledLabel
                : classes.ordersDisabledLabel
            }
          >
            {onlineOrderingEnabled
              ? t`Online Ordering is enabled`
              : t`Online Ordering is disabled`}
          </Typography>
        </Grid>
        <Grid item>
          <LargeSwitch
            onChange={onChangeOrderingEnabled}
            checked={onlineOrderingEnabled}
          />
        </Grid>
      </Grid>
    </Grid>
  ) : null;

  return (
    <Container maxWidth="xl">
      <Grid container justifyContent="center">
        {editorTitle && (
          <Grid item xs={12} lg={10} className={classes.title}>
            <Grid container justifyContent="center">
              <PageTitle>{editorTitle}</PageTitle>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          item
          xs={12}
          lg={11}
          justifyContent="center"
          spacing={isSmallScreen ? 3 : 8}
          alignItems="flex-start"
        >
          <Grid item xs={12} lg={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.mobileOnly}>
                <Grid container>{onlineOrdersSwitch}</Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid container className={classes.editorSection}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <PageTitle variant="h5">
                          <Trans>Shop Details</Trans>
                        </PageTitle>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="textSecondary" variant="body2">
                          <Trans>Provide details about your shop</Trans>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Field>
                          <Label shrink color="primary">
                            <Required>
                              <Trans>Shop Name</Trans>
                            </Required>
                          </Label>
                          <BootstrapInput
                            value={shopName}
                            onChange={onChangeName}
                            onBlur={onBlurName}
                            disabled={disabled}
                            inputProps={{ maxlength: 150 }}
                            error={getFieldError("name")}
                          />
                          <FormHelperText hidden={getFieldError("name")}>
                            <Trans>
                              Shop name is your shop's visible name on
                              bibliocircle
                            </Trans>
                          </FormHelperText>
                          <FormHelperText error hidden={!getFieldError("name")}>
                            {getFormattedError(getFieldError("name"))}
                          </FormHelperText>
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field>
                          <Label shrink color="primary">
                            <Trans>Description</Trans>
                          </Label>
                          <BootstrapInput
                            multiline
                            rows={5}
                            inputProps={{ maxlength: 300 }}
                            value={shopDescription}
                            disabled={disabled}
                            onChange={onChangeDescription}
                          />
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography
                                  color="textSecondary"
                                  variant="body1"
                                >
                                  <Trans>Shop Logo</Trans>
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography
                                  color="textSecondary"
                                  variant="caption"
                                >
                                  <Trans>
                                    This is your shop's logo. This image will be
                                    displayed wherever your shop is represented
                                    such as your shop, your ads.
                                  </Trans>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} className={classes.imageUploader}>
                            <ImageUploader
                              limit={1}
                              variant="zone"
                              onDrop={onChangeLogoImage}
                              onDelete={onDeleteLogoImage}
                              initialImages={
                                shop?.logoImage ? [shop.logoImage] : []
                              }
                              buttonText={t`Drag and drop or click to attach your shop's logo`}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <ShopPremiumFeature
                          shopTier={shop?.tier}
                          featureTiers={ADVANCED_UP_SHOP_TIERS}
                        >
                          <Grid container>
                            <Grid item xs={12}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography
                                    color="textSecondary"
                                    variant="body1"
                                  >
                                    <Trans>Shop Cover Image</Trans>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    color="textSecondary"
                                    variant="caption"
                                  >
                                    <Trans>
                                      This image will be displayed on your
                                      shop's heading behind the logo and the
                                      information.
                                    </Trans>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes.imageUploader}
                            >
                              <ImageUploader
                                limit={1}
                                variant="zone"
                                onDrop={onChangeCoverImage}
                                onDelete={onDeleteCoverImage}
                                initialImages={
                                  shop?.coverImage ? [shop.coverImage] : []
                                }
                                buttonText={t`Drag and drop or click to attach your shop's cover image`}
                              />
                            </Grid>
                          </Grid>
                        </ShopPremiumFeature>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Label color="primary">
                              <Typography variant="body1">
                                <Required>
                                  <Trans>Customise Shop URL</Trans>
                                </Required>
                              </Typography>
                            </Label>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container alignItems="center" spacing={1}>
                              <Grid item xs={12}>
                                <Grid container alignItems="center">
                                  <Grid
                                    item
                                    className={classes.identifierPrefix}
                                  >
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                    >
                                      https://bibliocircle.com/shop/
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <BootstrapInput
                                      fullWidth
                                      inputProps={{ maxlength: 150 }}
                                      value={identifier}
                                      onChange={onChangeIdentifier}
                                      error={getFieldError("identifier")}
                                      disabled={disabled}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              {getFieldError("identifier") && (
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    alignItems="center"
                                    className={classes.identifierTaken}
                                  >
                                    <Grid
                                      item
                                      xs={1}
                                      className={classes.identifierTakenIcon}
                                    >
                                      <Grid container alignItems="center">
                                        <UnavailableIcon color="secondary" />
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={11}>
                                      <Grid container alignItems="center">
                                        <Typography
                                          variant="body2"
                                          color="secondary"
                                        >
                                          {getFormattedError(
                                            getFieldError("identifier")
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <FormHelperText>
                              <Trans>
                                This name is to uniquely identify your shop's
                                URL. You can change this later too. (special
                                characters are not allowed)
                              </Trans>
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container className={classes.shopUrlPreview}>
                          <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary">
                              <Trans>
                                Your shop's URL will look like:{" "}
                                <b>
                                  https://bibliocircle.com/shop/{identifier}
                                </b>
                              </Trans>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid container className={classes.editorSection}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <PageTitle variant="h5">
                            <Trans>Delivery Options</Trans>
                          </PageTitle>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color="textSecondary" variant="body2">
                            <Trans>
                              Please select available delivery options for your
                              books. These delivery options will be displayed in
                              your advertisements
                            </Trans>
                          </Typography>
                        </Grid>
                        <DeliveryPreferenceEditor
                          deliveryPreferences={deliveryPreferences}
                          onChange={onChangeDeliveryPreferences}
                          disabled={disabled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="center"
                  className={classes.createShopBtnContainerDesktop}
                >
                  <Grid item>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      disableElevation
                      disabled={disabled}
                      onClick={onClickSave}
                    >
                      {shopIdentifier ? t`Update Shop` : t`Create Shop`}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={4}
            justifyContent="flex-start"
            alignContent="flex-start"
            className={classes.additionalInfo}
            spacing={5}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.desktopOnly}>
                <Grid container>{onlineOrdersSwitch}</Grid>
              </Grid>
              <Grid
                alignContent="flex-start"
                container
                item
                xs={12}
                sm={8}
                md={6}
                lg={12}
                spacing={5}
              >
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      <Trans>Contact Numbers</Trans>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      <Trans>
                        Please select the contact number to be displayed on top
                        of the shop page.
                      </Trans>
                    </Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <PhoneNumberManager
                      selectable
                      selectedNumbers={contactNumberIds}
                      onChangeSelection={onChangeContactNumberIds}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                alignContent="flex-start"
                container
                item
                xs={12}
                sm={8}
                md={6}
                lg={12}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      <Trans>Addresses</Trans>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      <Trans>
                        Please select at least one address to be displayed on
                        top of the shop page. If the desired address is not
                        listed below, you can click "Edit Addresses" below to
                        add a new address. Created addresses will be
                        automatically saved in your profile.
                      </Trans>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormHelperText error hidden={!getFieldError("addressIds")}>
                      {getFieldError("addressIds")?.type === "array.min" &&
                        t`Please provide at least one address`}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12}>
                    <AddressManager
                      error={getFieldError("addressIds")}
                      selectable
                      selectedAddresses={addressIds}
                      onChangeSelection={onChangeAddressIds}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            className={classes.createShopBtnContainerMobile}
          >
            <Grid item>
              <Button
                size="large"
                variant="contained"
                color="primary"
                disabled={disabled}
                onClick={onClickSave}
              >
                {shopIdentifier ? t`Update Shop` : t`Create Shop`}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

ShopEditor.propTypes = {
  onSave: PropTypes.func,
};

ShopEditor.defaultProps = {
  onSave() {},
};
