import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import BookPlaceholderIcon from "@material-ui/icons/LocalLibrary";
import PriceTag from "../../Common/PriceTag";
import Hyperlink from "../../Common/Hyperlink";
import ImageFadeIn from "../../Common/ImageFadeIn";

const useStyles = makeStyles((theme) => ({
  coverImageContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    padding: theme.spacing(2),
    position: "relative",
    borderRadius: "4px",
  },
  coverImage: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "100px",
    },
    height: "100%",
    width: "120px",
    objectFit: "contain",
  },
  placeholderIcon: {
    color: theme.palette.primary.main,
  },
  itemContent: {
    color: theme.palette.grey[800],
    paddingLeft: "20px",
  },
  orderItemCard: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  priceTagContainer: {
    [theme.breakpoints.up("md")]: {
      maxWidth: 150,
      marginBottom: theme.spacing(1),
      justifyContent: "flex-start",
    },
    justifyContent: "center",
  },
  shopName: {
    fontWeight: "bold",
  },
  bookPackBanner: {
    backgroundColor: theme.palette.grey[600],
    opacity: 0.95,
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "15%",
    borderRadius: "0 0 4px 4px",
  },
  bookPackBannerText: {
    color: theme.palette.background.paper,
    fontWeight: "bold",
  },
  itemTitle: {
    fontSize: 18,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  quantity: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
}));

export default function IncomingOrderItem({ orderId, item, quantity }) {
  const classes = useStyles();
  let thumbnail;
  if (item.coverImage) {
    thumbnail = (
      <ImageFadeIn
        src={item.coverImage}
        alt={item.title}
        className={classes.coverImage}
      />
    );
  } else {
    thumbnail = (
      <BookPlaceholderIcon
        className={classes.placeholderIcon}
        style={{ fontSize: "80px" }}
      />
    );
  }

  const thumbnailContainer = (
    <Grid container item xs={12} md={2} alignItems="center">
      <Grid
        item
        className={classes.coverImageContainer}
        justifyContent="center"
        alignContent="stretch"
        alignItems="center"
      >
        {thumbnail}
        {item.booksCount > 1 && (
          <Grid
            container
            item
            className={classes.bookPackBanner}
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              className={classes.bookPackBannerText}
              variant="subtitle2"
              align="center"
            >
              {item.booksCount} Books Pack
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const orderItem = (
    <Grid container item xs={12} className={classes.orderItemCard}>
      {thumbnailContainer}
      <Grid
        container
        item
        xs={12}
        md={7}
        alignContent="flex-start"
        spacing={1}
        className={classes.itemContent}
      >
        <Grid container item xs={12} justifyContent="flex-start">
          <Typography variant="h6" className={classes.itemTitle}>
            {item.title}
          </Typography>
        </Grid>
        <Grid
          container
          item
          alignContent="flex-end"
          className={classes.priceTagContainer}
        >
          <PriceTag price={item.price} size="sm" />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={3}
        alignItems="flex-start"
        spacing={1}
        className={classes.quantity}
      >
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          spacing={1}
          justifyContent="center"
        >
          <Grid container item xs={12} justifyContent="center">
            <Typography variant="body2" color="primary">
              Quantity: {quantity}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Hyperlink to={`/listing/${item.id}?orderId=${orderId}`}>
      {orderItem}
    </Hyperlink>
  );
}

IncomingOrderItem.propTypes = {
  orderId: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  quantity: PropTypes.number,
};
