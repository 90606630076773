import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import MessageThread from "../Messages/MessageThread";
import { GET_CHAT_GROUP } from "../../consts/queries";
import { getChatGroupTopic } from "./chat-utils";
import Banner from "../Common/Banner";
import { SEND_CHAT_MESSAGE } from "../../consts/mutations";
import { useLoader, useLoggedInUser } from "../../utils/hooks";
import { useMutation, useQuery } from "@apollo/client";

export default function ChatThread({ chatGroupId }) {
  const loggedInUser = useLoggedInUser();
  const { loading, error, data } = useQuery(GET_CHAT_GROUP, {
    variables: {
      id: chatGroupId,
    },
    skip: !loggedInUser,
  });
  const [sendChatMessage] = useMutation(SEND_CHAT_MESSAGE);

  useLoader(loading);

  if (error) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Banner
            type="error"
            title="Something went wrong!"
            message="We're sorry! Something went wrong while displaying your chats! Please try again later."
          />
        </Grid>
      </Grid>
    );
  }

  if (!data) return null;
  const { chatGroup } = data;

  const handleSendChatMessage = (message) => {
    return sendChatMessage({
      variables: {
        chatGroupId: chatGroup.id,
        message,
      },
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {chatGroup && (
          <MessageThread
            heading={getChatGroupTopic(chatGroup, loggedInUser.id)}
            threadId={chatGroup.threadId}
            inputPlaceholder="Type your message here"
            onSendMessage={handleSendChatMessage}
            variant="chat"
            noMessagesPlaceholder={
              <Banner
                title="Start a Chat"
                message="This chat is empty. Type a message and send to start the conversation."
              />
            }
          />
        )}
      </Grid>
    </Grid>
  );
}

ChatThread.propTypes = {
  chatGroupId: PropTypes.string.isRequired,
};
