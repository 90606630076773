import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import Hyperlink from "../Common/Hyperlink";
import UserAvatar from "../Common/UserAvatar";
import dayjs from "dayjs";
import PrivateIcon from "@material-ui/icons/VisibilityOff";
import ImageFadeIn from "../Common/ImageFadeIn";

const useStyles = makeStyles((theme) => {
  return {
    listingCollectionCard: {
      display: "flex",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(0.5),
      },
    },
    listingCollectionCardContent: {
      backgroundColor: theme.palette.grey[200],
      border: "2px solid",
      borderColor: theme.palette.grey[200],
      borderRadius: theme.spacing(2),
      position: "relative",
      "&:hover": {
        backgroundColor: theme.palette.primary.lighter,
        borderColor: theme.palette.primary.light,
        borderRadius: theme.spacing(2),
      },
    },
    collectionName: {
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
    },
    visibilityIcon: {
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      fontSize: "18px",
    },
    collectionDescription: {
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
    collectionDescriptionContainer: {
      marginBottom: theme.spacing(1),
    },
    timestamp: {
      fontSize: "12px",
      color: theme.palette.grey[500],
      width: "100%",
      textAlign: "right",
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
    visibility: {
      textAlign: "right",
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
    privateIcon: {
      fontSize: 14,
    },
    visibilityTypography: {
      justifyContent: "flex-end",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
    },
    visibilityContainer: {
      marginBottom: theme.spacing(0.5),
    },
    listingCollectionCardDetails: {
      padding: theme.spacing(2),
      "&:hover": {
        backgroundColor: theme.palette.primary.lighter,
        borderColor: theme.palette.primary.light,
        borderRadius: theme.spacing(2),
      },
    },
    coverImage: {
      maxHeight: "150px",
      minHeight: "150px",
      width: "100%",
      objectFit: "cover",
      borderRadius: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 0`,
    },
  };
});

export default function ListingCollectionCard({ listingCollection }) {
  const classes = useStyles();
  const trimDescription = (description) => {
    const desc = description || "";
    return desc.length <= 80 ? desc : desc.substring(0, 80) + "...";
  };
  return (
    <Hyperlink
      to={`/collection/${listingCollection.id}`}
      className={classes.listingCollectionCard}
    >
      <Grid container className={classes.listingCollectionCardContent}>
        {listingCollection.coverImage && (
          <Grid item xs={12}>
            <ImageFadeIn
              src={`${listingCollection.coverImage}/lg`}
              className={classes.coverImage}
              alt={listingCollection.title}
            />
          </Grid>
        )}
        <Grid item xs={12} className={classes.listingCollectionCardDetails}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="textPrimary"
                className={classes.collectionName}
              >
                {listingCollection.title}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.collectionDescriptionContainer}
            >
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.collectionDescription}
              >
                {trimDescription(listingCollection.description)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={6}>
                  <UserAvatar
                    user={listingCollection.owner}
                    clickable={false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container justifyContent="flex-end">
                    {!listingCollection.public && (
                      <Grid
                        item
                        xs={12}
                        className={classes.visibilityContainer}
                      >
                        <Typography
                          variant="body2"
                          color="primary"
                          className={classes.visibility}
                        >
                          <Grid
                            container
                            className={classes.visibilityTypography}
                            spacing={1}
                            alignContent="stretch"
                            alignItems="center"
                          >
                            <Grid item>
                              <Grid container alignItems="center">
                                <PrivateIcon className={classes.privateIcon} />
                              </Grid>
                            </Grid>
                            <Grid item>Private</Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography variant="body2" className={classes.timestamp}>
                        {dayjs(listingCollection.createdAt).format(
                          "MMMM D, YYYY"
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Hyperlink>
  );
}

ListingCollectionCard.propTypes = {
  listingCollection: PropTypes.object.isRequired,
};
