export const isWebViewAvailable = () => {
  return typeof window.ReactNativeWebView?.postMessage === "function";
};

export const userLoggedIn = ({ userId }) => {
  isWebViewAvailable() &&
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        eventName: "USER_LOGGED_IN",
        data: {
          userId,
        },
      })
    );
};

export const userLoggedOut = ({ userId }) => {
  isWebViewAvailable() &&
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        eventName: "USER_LOGGED_OUT",
        data: {
          userId,
        },
      })
    );
};

export const initiateGoogleLogin = () => {
  isWebViewAvailable() &&
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        eventName: "INITIATE_GOOGLE_LOGIN",
      })
    );
};

export const setupMobileMessageBridge = (fn) => {
  document.addEventListener("message", fn);
};
