import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontWeight: "bold",
    color: theme.palette.grey[700],
  },
}));

export default function PageTitle(props) {
  const classes = useStyles();
  return (
    <Typography
      align={props.align}
      className={`${classes.titleText} ${props.className}`}
      variant={props.variant}
    >
      {props.children}
    </Typography>
  );
}

PageTitle.propTypes = {
  variant: PropTypes.string,
};

PageTitle.defaultProps = {
  variant: "h4",
};
