import React from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import PageHead from "../Common/PageHead";
import BookRequestDetails from "./BookRequestDetails";

const useStyles = makeStyles((theme) => ({
  listingsContainer: {
    marginTop: theme.spacing(3),
  },
}));

export default function BookRequestMatches() {
  const classes = useStyles();
  const history = useHistory();
  const { bookRequestId } = useParams();

  const navigateToBookRequestsPage = () => {
    history.push("/book-requests");
  };

  return (
    <Container maxWidth="xl">
      <Grid container justifyContent="center" direction="row-reverse">
        <PageHead title="Book Request Results | bibliocircle" />
        <Grid item xs={10} lg={8} className={classes.listingsContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <BookRequestDetails
                bookRequestId={bookRequestId}
                showResultsCountBanner
                showMatchedListings
                afterDelete={navigateToBookRequestsPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
