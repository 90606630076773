import { PLACEHOLDER_IMAGES, priceModelTypes } from "../../consts";
import { createFeedPostDataExtractor } from "./transformerUtils";

export default function UserCreatedBookRequestTransformer(feedPost) {
  const extractor = createFeedPostDataExtractor(feedPost.primaryContent?.data);
  const priceModelTypeText =
    extractor.get("priceModelType") === priceModelTypes.SALE
      ? "to buy"
      : "to rent";
  const bookCountText =
    +extractor.get("count") > 1
      ? `${extractor.get("count")} copies of a book`
      : "a book";
  const postTitle = extractor.get("authors").length
    ? `${extractor.get("bookTitle")} - ${extractor.get("authors")}`
    : extractor.get("bookTitle");
  return {
    actorFullName: feedPost.actor.name,
    actorImage: feedPost.actor.image || PLACEHOLDER_IMAGES.USER,
    actorPath: `/profile/${feedPost.actor.id}`,
    activityText: `is looking for ${bookCountText} ${priceModelTypeText}`,
    resourcePath: `/book-request/${extractor.get("bookRequestId")}`,
    primaryContent: {
      title: postTitle,
      content: extractor.get("message"),
      images: feedPost.primaryContent?.images || [],
    },
    timestamp: feedPost.activityTimestamp,
  };
}
