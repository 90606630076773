import React from "react";
import { withStyles, TextField } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { grey } from "@material-ui/core/colors";

const CustomTextField = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${grey[300]}`,
    padding: "8px 12px",
    margin: 0,
  },
}))(TextField);

export default function DatePicker(props) {
  return (
    <KeyboardDatePicker
      TextFieldComponent={CustomTextField}
      {...props}
      InputProps={{ disableUnderline: true }}
    />
  );
}
