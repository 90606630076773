import Joi from "@hapi/joi";

export default Joi.object({
  id: Joi.string().optional(),
  title: Joi.string().trim().required(),
  tags: Joi.array().items(Joi.string()).min(1).required(),
  featuredCount: Joi.number().min(1).required(),
  titleBackgroundColor: Joi.string().allow("", null).optional(),
  contentBackgroundColor: Joi.string().allow("", null).optional(),
});
