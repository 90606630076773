import { useMutation } from "@apollo/client";
import { Button, FormHelperText, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import BootstrapInputBordered from "../Application/Common/BootstrapInputBordered";
import FormControlField from "../Application/Common/FormControlField";
import InputLabel from "../Application/Common/InputLabel";
import Required from "../Application/Common/Required";
import { actionTypes } from "../Application/index.reducer";
import { SEND_CONTACT_MESSAGE } from "../consts/mutations";
import { isValidEmail } from "../utils/common";

export default function ContactForm() {
  const dispatch = useDispatch();
  const [sendMessage, { loading }] = useMutation(SEND_CONTACT_MESSAGE);
  const [emailAddress, setEmailAddress] = useState(null);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [subject, setSubject] = useState(null);
  const [message, setMessage] = useState(null);
  const [invalidMessage, setInvalidMessage] = useState(false);

  const onChangeEmail = (event) => setEmailAddress(event.target.value);
  const onChangeMessage = (event) => setMessage(event.target.value);
  const onChangeSubject = (event) => setSubject(event.target.value);

  const clearForm = () => {
    setEmailAddress("");
    setSubject("");
    setMessage("");
    setInvalidEmail(false);
    setInvalidMessage(false);
  };

  const onSubmit = () => {
    if (!isValidEmail(emailAddress) || !message) {
      setInvalidEmail(!isValidEmail(emailAddress));
      setInvalidMessage(!message);
      return;
    }
    sendMessage({
      variables: {
        messageDetails: {
          subject,
          emailAddress,
          message,
          userAgent: window.navigator.userAgent,
        },
      },
    })
      .then(() => {
        dispatch({
          type: actionTypes.INFO_MESSAGE,
          message:
            "Thank you for your message! We will get back to you via your provided email address.",
        });
        clearForm();
      })
      .catch(() => {
        dispatch({
          type: actionTypes.ERROR_MESSAGE,
          message:
            "Something went wrong while sending the message. Please try again later, or send your message via facebook.",
        });
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControlField>
          <InputLabel shrink color="primary">
            <Required>Your Email Address</Required>
          </InputLabel>
          <BootstrapInputBordered
            inputProps={{ maxlength: 150 }}
            value={emailAddress}
            onChange={onChangeEmail}
            error={invalidEmail}
            disabled={loading}
          />
          {invalidEmail && (
            <FormHelperText>
              <Typography color="secondary" variant="caption">
                A valid email address is required
              </Typography>
            </FormHelperText>
          )}
        </FormControlField>
      </Grid>
      <Grid item xs={12}>
        <FormControlField>
          <InputLabel shrink color="primary">
            Subject
          </InputLabel>
          <BootstrapInputBordered
            inputProps={{ maxlength: 150 }}
            value={subject}
            onChange={onChangeSubject}
            disabled={loading}
          />
        </FormControlField>
      </Grid>
      <Grid item xs={12}>
        <FormControlField>
          <InputLabel shrink color="primary">
            <Required>Message to us</Required>
          </InputLabel>
          <BootstrapInputBordered
            inputProps={{ maxlength: 500 }}
            value={message}
            multiline
            rows={5}
            onChange={onChangeMessage}
            error={invalidMessage}
            disabled={loading}
          />
          {invalidMessage && (
            <FormHelperText>
              <Typography color="secondary" variant="caption">
                Message is required
              </Typography>
            </FormHelperText>
          )}
        </FormControlField>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onSubmit}
          disabled={loading}
        >
          Send Message
        </Button>
      </Grid>
    </Grid>
  );
}
