import { PLACEHOLDER_IMAGES } from "../../consts";

export default function UserSignedUpTransformer(feedPost) {
  return {
    actorFullName: feedPost.actor.name,
    actorImage: feedPost.actor.image || PLACEHOLDER_IMAGES.USER,
    actorPath: `/profile/${feedPost.actor.id}`,
    activityText:
      "joined bibliocircle 🎉 Follow them to get notifications on their activities.",
    resourcePath: `/profile/${feedPost.actor.id}`,
    primaryContent: {
      title: null,
      content: null,
      images: [], // don't show images
    },
    noContent: true,
    timestamp: feedPost.activityTimestamp,
  };
}
