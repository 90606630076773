import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { orange, red } from "@material-ui/core/colors";
const useStyles = makeStyles((theme) => {
  const alertContainer_base = {
    borderRadius: theme.spacing(1),
  };
  return {
    alertContainer_success: {
      ...alertContainer_base,
      backgroundColor: theme.palette.primary.lighter,
      borderColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
    alertContainer_warning: {
      ...alertContainer_base,
      color: orange[900],
      backgroundColor: orange[100],
      borderColor: orange[200],
    },
    alertContainer_error: {
      ...alertContainer_base,
      backgroundColor: red[50],
      color: red[900],
      borderColor: red[200],
    },
    alertContainer_info: {
      ...alertContainer_base,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.light,
      backgroundColor: theme.palette.primary.lighter,
    },
    alertContainer_basic: {
      ...alertContainer_base,
      color: theme.palette.grey[800],
      borderColor: theme.palette.grey[400],
      backgroundColor: theme.palette.grey[200],
    },
    alertContainer_dark: {
      ...alertContainer_base,
      color: theme.palette.grey[800],
      borderColor: theme.palette.grey[400],
      backgroundColor: theme.palette.grey[300],
    },
  };
});

export default function Banner({
  type,
  message,
  title,
  align,
  fullWidth,
  rounded,
}) {
  const classes = useStyles();
  const containerClass = classes[`alertContainer_${type}`];
  const overrideStyles = {};
  if (fullWidth) overrideStyles.width = "100%";
  if (!rounded) overrideStyles.borderRadius = 0;

  return (
    <Box p={3} className={containerClass} style={overrideStyles}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {title && (
              <Grid item xs={12}>
                <Typography variant="h6" align={align}>
                  {title}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="body2" align={align}>
                {message}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

Banner.propTypes = {
  type: PropTypes.oneOf(["success", "warning", "error", "info"]),
  title: PropTypes.string,
  message: PropTypes.string,
  fullWidth: PropTypes.bool,
  rounded: PropTypes.bool,
};

Banner.defaultProps = {
  type: "info",
  title: null,
  message: null,
  fullWidth: false,
  rounded: true,
};
