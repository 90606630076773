import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  InputAdornment,
  IconButton,
  Paper,
  Button,
  Container,
} from "@material-ui/core";
import PageTitle from "../Common/PageTitle";
import { useHistory, useLocation } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import BootstrapInput from "../Common/BootstrapInput";
import ListingCollectionsSearchController from "./ListingCollectionsSearchController";
import Hyperlink from "../Common/Hyperlink";
import PageHead from "../Common/PageHead";
import CollectionsIcon from "./CollectionsIcon";
import { useScrollTop, useLoginPrompt } from "../../utils/hooks";
import ImageFadeIn from "../Common/ImageFadeIn";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  collectionDiscoverer: {
    marginTop: theme.spacing(3),
  },
  logo: {
    maxHeight: theme.spacing(6) * 2,
  },
  pageTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  collectionSearchBox: {
    width: "100%",
  },
  collectionSearchBoxInput: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    "&::placeholder": {
      color: theme.palette.primary.main,
    },
  },
  collectionSearchContainer: {
    marginBottom: theme.spacing(2),
  },
}));

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export default function ListingCollections() {
  const classes = useStyles();
  const history = useHistory();
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const queryParams = useQueryParams();
  const [searchQuery, setSearchQuery] = useState("");

  useScrollTop([history.location.key]);

  const onKeyDown = (event) => {
    if (event.keyCode === 13) searchCollections();
  };

  const searchCollections = () => {
    const queryParams = {};
    if (searchQuery) queryParams.q = searchQuery;
    const queryString = new URLSearchParams(queryParams).toString();
    history.push(`/collections?${queryString}`);
  };
  const onChangeSearchQuery = (event) => setSearchQuery(event.target.value);
  const onClickCreateCollection = createLoginPromptEventHandler({
    redirectPath: "/collection/create",
  });

  const collectionsSearchBar = (
    <Grid
      container
      justifyContent="center"
      className={classes.collectionSearchContainer}
    >
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={9}>
            <Paper>
              <BootstrapInput
                name="q"
                className={classes.collectionSearchBox}
                defaultValue={queryParams.get("q")}
                placeholder={t`Search other readers' collections`}
                bordered
                onChange={onChangeSearchQuery}
                onKeyDown={onKeyDown}
                endAdornment={
                  <InputAdornment>
                    <IconButton
                      color="primary"
                      variant="contained"
                      onClick={searchCollections}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              ></BootstrapInput>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Container maxWidth="xl">
      <PageHead title={t`Collections | bibliocircle`} />
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        className={classes.collectionDiscoverer}
      >
        <Grid container item xs={11} lg={10} spacing={2}>
          <Grid container item xs={12} className={classes.pageTitle}>
            <Grid container spacing={2} alignItems="center" direction="column">
              <Grid item>
                <Grid container justifyContent="center">
                  <ImageFadeIn
                    src="/logo/logo_collections.svg"
                    alt="Discover Collections"
                    className={classes.logo}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <PageTitle variant="subtitle1" align="center">
                  <Trans>
                    Collections are a great way to organize and showcase your
                    book advertisements.
                  </Trans>
                </PageTitle>
              </Grid>
              <Grid item>
                <Button
                  onClick={onClickCreateCollection}
                  component={Hyperlink}
                  to="/collection/create"
                  startIcon={<CollectionsIcon />}
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  <Trans>Create Collection</Trans>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            {collectionsSearchBar}
          </Grid>
          <Grid container item xs={12}>
            <ListingCollectionsSearchController
              key={history.location.key}
              xs={12}
              md={6}
              lg={6}
              xl={6}
              searchArgs={{
                query: queryParams.get("q"),
              }}
              noResultsMessage={t`No collections found!`}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
