import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Route, Switch } from "react-router-dom";
import Hyperlink from "../Common/Hyperlink";
import GlobalNotifications from "./GlobalNotifications";
import NotificationIcon from "@material-ui/icons/NotificationImportant";

const useStyles = makeStyles((theme) => ({
  page: {
    marginTop: theme.spacing(4),
  },
}));

const routes = [
  {
    title: "Global Notifications",
    path: "/admin/global-notifications",
    component: <GlobalNotifications />,
  },
];

export default function Admin() {
  const classes = useStyles();
  return (
    <Container maxWidth="xl" className={classes.page}>
      <Switch>
        {routes.map((route) => (
          <Route key={route.path} path={route.path}>
            {route.component}
          </Route>
        ))}
        <Route>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6">Administrator</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <List>
                  {routes.map((route) => (
                    <ListItem
                      key={route.path}
                      button
                      component={Hyperlink}
                      to={route.path}
                    >
                      <ListItemIcon>
                        <NotificationIcon />
                      </ListItemIcon>
                      <ListItemText primary={route.title} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Route>
      </Switch>
    </Container>
  );
}
