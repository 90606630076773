import React from "react";
import { Grid } from "@material-ui/core";
import OutgoingOrdersSearchController from "../../Orders/OutgoingOrders/OutgoingOrdersSearchController";

export default function UserOutgoingOrdersPage() {
  return (
    <Grid container>
      <OutgoingOrdersSearchController />
    </Grid>
  );
}
