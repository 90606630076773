import {
  BottomNavigation,
  BottomNavigationAction,
  Drawer,
  Grid,
  makeStyles,
} from "@material-ui/core";
import MenuOpenIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useEffect, useState } from "react";
import CritiqueIcon from "./Critique/CritiqueIcon";
import BookIcon from "./Listing/BookIcon";
import StoryIcon from "./Stories/StoryIcon";
import Hyperlink from "./Common/Hyperlink";
import Sidebar from "./Common/Sidebar";
import BookChatIcon from "./Discussions/BookChatIcon";

const useStyles = makeStyles((theme) => ({
  navigation: {
    position: "fixed",
    bottom: 0,
    zIndex: 1300,
    width: "100%",
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[10],
  },
  drawerContent: {
    paddingBottom: theme.spacing(8),
  },
  swipeableDrawer: {
    overflow: "scroll",
  },
  menuOpen: {
    backgroundColor: theme.palette.primary.lighter,
    //   borderRadius: theme.spacing(1)
  },
}));

export default function MobileBottomNavigation() {
  const classes = useStyles();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const toggleMobileDrawer = () => setMobileDrawerOpen((isOpen) => !isOpen);
  const hideMobileDrawer = () => setMobileDrawerOpen(false);

  useEffect(() => {
    /**
     * Hack to prevent mobile pull-to-refresh activation when scrolling
     * up on the mobile drawer.
     */
    if (mobileDrawerOpen) {
      window.scrollBy(0, 0.5);
    } else {
      window.scrollBy(0, -0.5);
    }
  }, [mobileDrawerOpen]);

  const mobileDrawer = (
    <Drawer
      anchor="bottom"
      open={mobileDrawerOpen}
      onClose={toggleMobileDrawer}
      onOpen={toggleMobileDrawer}
      className={classes.swipeableDrawer}
    >
      <Grid
        container
        onClick={toggleMobileDrawer}
        onKeyDown={toggleMobileDrawer}
        className={classes.drawerContent}
      >
        <Grid item xs={12}>
          <Sidebar />
        </Grid>
      </Grid>
    </Drawer>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        {mobileDrawer}
        <BottomNavigation showLabels className={classes.navigation}>
          <BottomNavigationAction
            component={Hyperlink}
            to="/listings"
            label="Books"
            onClick={hideMobileDrawer}
            icon={<BookIcon />}
          />
          <BottomNavigationAction
            component={Hyperlink}
            to="/critiques"
            label="Critiques"
            onClick={hideMobileDrawer}
            icon={<CritiqueIcon />}
          />
          <BottomNavigationAction
            label="Menu"
            onClick={toggleMobileDrawer}
            className={mobileDrawerOpen && classes.menuOpen}
            icon={mobileDrawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
          />
          <BottomNavigationAction
            component={Hyperlink}
            to="/bookchats"
            label="Discussions"
            onClick={hideMobileDrawer}
            icon={<BookChatIcon />}
          />
          <BottomNavigationAction
            component={Hyperlink}
            to="/stories"
            label="Stories"
            onClick={hideMobileDrawer}
            icon={<StoryIcon />}
          />
        </BottomNavigation>
      </Grid>
    </Grid>
  );
}
