import React from "react";
import PropTypes from "prop-types";
import { makeStyles, LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loader: {
    zIndex: 99999,
    position: "fixed",
    top: 0,
    left: 0,
    height: "5px",
    width: "100%",
    backgroundColor: theme.palette.gradient.navbar,
  },
}));

export default function Loader({ loading }) {
  const classes = useStyles();
  return loading ? (
    <LinearProgress color="primary" className={classes.loader} />
  ) : null;
}

Loader.propTypes = {
  loading: PropTypes.bool,
};
