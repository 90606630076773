import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles, Avatar, Fade } from "@material-ui/core";
import Hyperlink from "../Common/Hyperlink";

const useStyles = makeStyles((theme) => {
  const shopCardContentStyles = {
    backgroundColor: theme.palette.grey[200],
    border: "2px solid",
    borderColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    position: "relative",
    "&:hover": {
      backgroundColor: theme.palette.primary.lighter,
      borderColor: theme.palette.primary.light,
    },
  };
  return {
    shopCard: {
      display: "flex",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(1.5),
      },
    },
    shopCardContent: {
      ...shopCardContentStyles,
    },
    logoContainer: {
      height: "100%",
    },
    logo: {
      maxWidth: "60px",
      objectFit: "contain",
    },
    logoImageAvatar: {
      width: 60,
      height: 60,
    },
    emptyShopText: {
      color: theme.palette.grey[400],
      fontWeight: "bold",
    },
    nonEmptyShopText: {
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
    shopDescription: {
      marginBottom: theme.spacing(0.5),
    },
  };
});

export default function ShopCard({ shop }) {
  const classes = useStyles();

  return (
    <Hyperlink to={`/shop/${shop.identifier}`} className={classes.shopCard}>
      <Grid container className={classes.shopCardContent}>
        <Grid item xs={3} sm={2} md={3} xl={2}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            className={classes.logoContainer}
          >
            {shop.logoImage ? (
              <Fade in>
                <Avatar
                  alt={shop.name}
                  src={`${shop.logoImage}/xs`}
                  className={classes.logoImageAvatar}
                />
              </Fade>
            ) : (
              <Fade in>
                <Avatar
                  className={classes.logoImageAvatar}
                  src="/shop-placeholder.svg"
                />
              </Fade>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={9}
          sm={10}
          md={9}
          xl={10}
          className={classes.shopDetailsContainer}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" color="textPrimary">
                {shop.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.shopDescription}
              >
                {(shop.regions || []).join(", ")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Hyperlink>
  );
}

ShopCard.propTypes = {
  shop: PropTypes.object.isRequired,
};
