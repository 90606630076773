import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  makeStyles,
  Container,
  Button,
  InputLabel,
  FormControl,
  withStyles,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import BootstrapInput from "../Common/BootstrapInput";
import ImageUploader from "../Common/ImageUploader";
import PageTitle from "../Common/PageTitle";
import { useMutation, useLazyQuery } from "@apollo/client";
import { IMAGE_SERVER_URL } from "../../config";
import { useDispatch } from "react-redux";
import { actionTypes } from "../index.reducer";
import { GET_GROUP_FOR_EDITING } from "../../consts/queries";
import { CREATE_GROUP, UPDATE_GROUP } from "../../consts/mutations";
import { dataURLtoBlob } from "../../utils/fileOperations";
import Required from "../Common/Required";
import PageHead from "../Common/PageHead";
import { useLoader, useRequireLogin } from "../../utils/hooks";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  editorSection: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
  },
  title: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  createGroupBtnContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
}));

const Label = withStyles((theme) => ({
  root: {
    fontSize: "20px",
    color: theme.palette.grey[700],
  },
}))(InputLabel);

const Field = withStyles({
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
})(FormControl);

export default function GroupEditor() {
  const [
    getGroup,
    { loading: retrievingGroup, error: retrieveGroupError, data: groupData },
  ] = useLazyQuery(GET_GROUP_FOR_EDITING);

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const isUpdateOperation = !!groupId;
  const [invalidGroupName, setInvalidGroupName] = useState(false);
  const [invalidGroupDescription, setInvalidGroupDescription] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [isPrivateGroup, setPrivateGroup] = useState(false);
  const [groupName, setGroupName] = useState(null);
  const [groupDescription, setGroupDescription] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const [originalLogoImage, setOriginalLogoImage] = useState(null);
  useRequireLogin();

  const dispatchErrorMessage = useCallback(
    (message) =>
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message,
      }),
    [dispatch]
  );

  const [createGroup, { data: createGroupResponse }] =
    useMutation(CREATE_GROUP);
  if (createGroupResponse?.createGroup?.id) {
    history.push(`/group/${createGroupResponse.createGroup.id}`);
  }

  const [updateGroup, { data: updateGroupResponse }] =
    useMutation(UPDATE_GROUP);
  if (updateGroupResponse?.updateGroup?.id) {
    history.push(`/group/${updateGroupResponse.updateGroup.id}`);
  }

  useEffect(() => {
    if (retrieveGroupError) {
      dispatchErrorMessage(
        "Something went wrong while fetching the group. Please try again later!"
      );
    }
  }, [dispatchErrorMessage, retrieveGroupError]);
  useLoader(formSubmitting || retrievingGroup);

  useEffect(() => {
    if (isUpdateOperation) {
      getGroup({ variables: { groupId } });
    }
  }, [isUpdateOperation, groupId, getGroup]);

  useEffect(() => {
    if (groupData?.group) {
      const { group } = groupData;
      setGroupName(group.name);
      setGroupDescription(group.description);
      setPrivateGroup(group.visibility === "PRIVATE");
      setLogoImage(group.logoImage);
      setOriginalLogoImage(group.logoImage);
    }
  }, [groupData]);

  const uploadLogo = () => {
    if (!logoImage) return Promise.resolve();
    const formData = new FormData();
    const logoBlob = dataURLtoBlob(logoImage);
    if (!logoBlob) return Promise.resolve();
    formData.append("file", logoBlob);
    return fetch(`${IMAGE_SERVER_URL}/upload/md`, {
      method: "POST",
      body: formData,
      credentials: "include",
    }).then((response) => {
      // TODO: handle rate limit errors 429
      if (response.status === 201) return response.json();
      throw Error("failed to upload image!");
    });
  };

  const setGroupVisibilityPrivate = (isPrivateGroup) => () =>
    setPrivateGroup(isPrivateGroup);
  const handleChangeName = (event) => {
    setInvalidGroupName(false);
    setGroupName(event.target.value);
  };
  const handleChangeDescription = (event) => {
    setInvalidGroupDescription(false);
    setGroupDescription(event.target.value);
  };
  const handleAttachImage = ([image]) => {
    setLogoImage(image);
  };
  const handleRemoveImage = () => {
    setLogoImage(null);
  };

  const handleSubmitForm = () => {
    if (!groupName) setInvalidGroupName(true);
    if (!groupDescription) setInvalidGroupDescription(true);
    if (!groupName || !groupDescription) {
      dispatchErrorMessage("All fields are required!");
      return;
    }

    setFormSubmitting(true);

    let imageUploadPromise = Promise.resolve(null);

    if (logoImage) {
      if (logoImage !== originalLogoImage) {
        // Image was updated by the user
        imageUploadPromise = uploadLogo();
      } else {
        // Image was not updated by the user, hence use the existing file name
        imageUploadPromise = Promise.resolve({
          fileName: logoImage.split("/").pop(),
        });
      }
    }

    imageUploadPromise
      .then((logo) => {
        let logoImage = logo?.fileName || null;
        if (isUpdateOperation) {
          const updatedGroupData = {
            id: groupId,
            name: groupName,
            description: groupDescription,
            visibility: isPrivateGroup ? "PRIVATE" : "PUBLIC",
            logoImage,
          };
          return updateGroup({
            variables: {
              group: updatedGroupData,
            },
          }).catch(() => {
            dispatchErrorMessage(
              "Something went wrong while updating the group. Please try again later"
            );
          });
        } else {
          return createGroup({
            variables: {
              group: {
                name: groupName,
                description: groupDescription,
                visibility: isPrivateGroup ? "PRIVATE" : "PUBLIC",
                logoImage,
              },
            },
          }).catch(() => {
            dispatchErrorMessage(
              "Something went wrong while creating the group. Please try again later"
            );
          });
        }
      })
      .finally(() => {
        setFormSubmitting(false);
      });
  };

  const editorTitle = groupId ? t`Edit Group` : t`Create Group`;

  const renderImageUploader = () => {
    if (isUpdateOperation && !groupData?.group) return null;
    const logoImage = groupData?.group?.logoImage;
    return (
      <ImageUploader
        variant="zone"
        limit={1}
        initialImages={logoImage ? [logoImage] : []}
        onDrop={handleAttachImage}
        onDelete={handleRemoveImage}
        buttonText={groupId ? t`Attach new logo` : t`Attach logo`}
      />
    );
  };

  return (
    <Container maxWidth="xl">
      {isUpdateOperation ? (
        <PageHead title={`Edit Group - ${groupName} | bibliocircle`} />
      ) : (
        <PageHead title={t`Create Group | bibliocircle`} />
      )}
      <Grid container justifyContent="center">
        <Grid
          container
          item
          xs={12}
          className={classes.title}
          justifyContent="center"
        >
          <Grid item>
            <PageTitle>{editorTitle}</PageTitle>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="center" spacing={3}>
          <Grid container item xs={12} sm={10} md={10} lg={6}>
            <Grid container>
              <Grid container item xs={12} className={classes.editorSection}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PageTitle variant="h5">
                      <Trans>Group Details</Trans>
                    </PageTitle>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="textSecondary" variant="body2">
                      <Trans>Provide details about your group</Trans>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field className={classes.spannedInput}>
                      <Label shrink color="primary" htmlFor="bootstrap-input">
                        <Required>
                          <Trans>Group Name</Trans>
                        </Required>
                      </Label>
                      <BootstrapInput
                        inputProps={{ maxlength: 150 }}
                        error={invalidGroupName}
                        onChange={handleChangeName}
                        value={groupName}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field className={classes.spannedInput}>
                      <Label shrink color="primary" htmlFor="bootstrap-input">
                        <Required>
                          <Trans>Short Description</Trans>
                        </Required>
                      </Label>
                      <BootstrapInput
                        inputProps={{ maxlength: 200 }}
                        placeholder={t`What is this group about?`}
                        error={invalidGroupDescription}
                        onChange={handleChangeDescription}
                        value={groupDescription}
                      />
                    </Field>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.editorSection}>
                <Grid container item xs={12} justifyContent="flex-start">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <PageTitle variant="h5">
                        <Trans>Visibility</Trans>
                      </PageTitle>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={!isPrivateGroup}
                              color="primary"
                              onClick={setGroupVisibilityPrivate(false)}
                            />
                          }
                          label={
                            <Typography variant="body2">
                              <Trans>Public Group</Trans>
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={isPrivateGroup}
                              color="primary"
                              onClick={setGroupVisibilityPrivate(true)}
                            />
                          }
                          label={
                            <Typography variant="body2">
                              <Trans>Private Group</Trans>
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.editorSection}>
                <Grid container item xs={12} justifyContent="flex-start">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <PageTitle variant="h5">
                        <Trans>Group Logo</Trans>
                      </PageTitle>
                    </Grid>
                    <Grid item xs={12}>
                      {renderImageUploader()}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              className={classes.createGroupBtnContainer}
            >
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={formSubmitting}
                  onClick={handleSubmitForm}
                >
                  {groupId ? t`Update Group` : t`Create Group`}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
