import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    border: "none",
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(1),
    width: "100%",
    marginBottom: 0,
  },
  shopLogo: {
    height: "64px",
    width: "64px",
  },
}));

export function ShopsCardLoader() {
  const classes = useStyles();
  return (
    <div className={`ph-item ${classes.item}`}>
      <div className="ph-col-2">
        <div className={`ph-avatar small ${classes.shopLogo}`}></div>
      </div>
      <div>
        <div className="ph-row">
          <div className="ph-col-8 big"></div>
          <div className="ph-col-6"></div>
          <div className="ph-col-12"></div>
        </div>
      </div>
    </div>
  );
}

export function ShopsLoader({ xs, md, lg, xl }) {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={3}
      justifyContent="flex-start"
      className={classes.shops}
    >
      <Grid item container xs={xs} md={md} lg={lg} xl={xl}>
        <ShopsCardLoader />
      </Grid>
      <Grid item container xs={xs} md={md} lg={lg} xl={xl}>
        <ShopsCardLoader />
      </Grid>
      <Grid item container xs={xs} md={md} lg={lg} xl={xl}>
        <ShopsCardLoader />
      </Grid>
    </Grid>
  );
}
