import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { UPDATE_APP_NOTIFICATION } from "../../consts/mutations";
import {
  GET_APP_NOTIFICATION_BY_ID,
  GET_NOTIFICATIONS_COUNT,
} from "../../consts/queries";
import { useLoader } from "../../utils/hooks";
import ErrorPage from "../Error/ErrorPage";
import { getNotificationTargetUrl } from "./notificationParser";

export default function MobilePushNotificationRedirector() {
  const params = useParams();
  const history = useHistory();
  const [updateAppNotification] = useMutation(UPDATE_APP_NOTIFICATION);
  const { data, error, loading } = useQuery(GET_APP_NOTIFICATION_BY_ID, {
    variables: { notificationId: params.notificationId },
  });
  useLoader(loading);

  useEffect(() => {
    updateAppNotification({
      variables: {
        notification: {
          id: params.notificationId,
          read: true,
          clicked: true,
        },
      },
      refetchQueries: [
        {
          query: GET_NOTIFICATIONS_COUNT,
        },
      ],
    }).catch(() => {});
  }, [params, updateAppNotification]);

  if (error) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="It looks like it's our fault, not yours. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }
  if (!data) return null;
  const { getAppNotificationById: notification } = data;
  history.push(getNotificationTargetUrl(notification));
  return null;
}
