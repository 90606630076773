import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import NoResults from "../Common/NoResultsImage";
import { FEED_PAGINATION_LIMIT } from "../../config";
import { useQuery } from "@apollo/client";
import { GET_FEED } from "../../consts/queries";
import ErrorPage from "../Error/ErrorPage";
import { useLoader } from "../../utils/hooks";
import FeedPost from "./FeedPost";
import { FeedLoader } from "../Common/LoadingPlaceholders/FeedLoader";

const useStyles = makeStyles((theme) => ({
  emptyFeedPlaceholder: {
    padding: theme.spacing(3),
  },
  errorBanner: {
    padding: theme.spacing(3),
  },
  emptyFeedPlaceholderText: {
    fontWeight: "bold",
    textAlign: "center",
  },
}));

export default function FeedPostList({ fetchPolicy, xs, md, lg, xl }) {
  const classes = useStyles();
  const fetchOptions = {
    variables: {
      pagination: {
        limit: FEED_PAGINATION_LIMIT,
        offset: 0,
      },
    },
  };

  if (fetchPolicy) fetchOptions.fetchPolicy = fetchPolicy;
  const {
    loading,
    error: getFeedError,
    data,
    fetchMore,
  } = useQuery(GET_FEED, fetchOptions);
  useLoader(loading);

  const loadingPlaceholder = <FeedLoader xs={xs} md={md} lg={lg} xl={xl} />;

  if (getFeedError) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the feed due to a problem. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  if (loading && !data) {
    return (
      <Grid container justifyContent="center">
        {loadingPlaceholder}
      </Grid>
    );
  }

  if (!data?.getFeed) return null;

  const {
    getFeed: { feed, pagination },
  } = data;

  const loadMorePosts = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: FEED_PAGINATION_LIMIT,
          offset: feed.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          getFeed: {
            __typename: prev.getFeed.__typename,
            feed: [...prev.getFeed.feed, ...fetchMoreResult.getFeed.feed],
            pagination: fetchMoreResult.getFeed.pagination,
          },
        });
      },
    }).catch(() => {});
  };

  const emptyFeedPlaceholder = (
    <Grid
      container
      justifyContent="center"
      className={classes.emptyFeedPlaceholder}
    >
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="h5"
          color="primary"
          className={classes.emptyFeedPlaceholderText}
        >
          That's it for now :)
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={6} lg={4}>
          <NoResults />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      {!feed.length ? emptyFeedPlaceholder : null}
      <InfiniteScroll
        style={{ overflow: "none !important" }}
        next={loadMorePosts}
        hasMore={feed.length < pagination.totalCount}
        loader={<FeedLoader xs={xs} md={md} lg={lg} xl={xl} />}
        dataLength={feed.length}
      >
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {feed.map((feedPost) => (
              <Grid key={feedPost.key} item xs={12}>
                <FeedPost feedPost={feedPost} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </InfiniteScroll>
    </Grid>
  );
}

FeedPostList.propTypes = {
  searchArgs: PropTypes.object,
};

FeedPostList.defaultProps = {
  searchArgs: {},
};
