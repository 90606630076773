import PropTypes from "prop-types";

export default function Currency({ value, displayZero }) {
  if (!value && !displayZero) return "Free!!";
  return Intl.NumberFormat("en-LK", {
    style: "currency",
    currency: "LKR",
  })
    .format(value)
    .replace("LKR", "Rs.");
}

Currency.propTypes = {
  value: PropTypes.number,
};
