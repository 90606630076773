import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => {
  return {
    image: {
      animation: "fadeIn ease 0.5s",
      "-webkit-animation": "fadeIn ease 0.5s",
      "-moz-animation": "fadeIn ease 0.5s",
      "-o-animation": "fadeIn ease 0.5s",
      "-ms-animation": "fadeIn ease 0.5s",
    },
  };
});

export default function Alert({ ...props }) {
  const classes = useStyles();
  return (
    <img
      alt="bibliocircle"
      {...props}
      className={`${classes.image} ${props.className}`}
    />
  );
}

Alert.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
};
