import { Button, Grid, makeStyles, SwipeableDrawer } from "@material-ui/core";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ChatThread from "./ChatThread";
import ChatGroupsSearchController from "./ChatGroupsSearchController";
import Banner from "../Common/Banner";
import ChatsIcon from "@material-ui/icons/Forum";
import PageHead from "../Common/PageHead";

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    marginTop: theme.spacing(3),
  },
  chatThread: {
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
    borderRadius: theme.shape.borderRadius,
  },
  smallScreenDrawer: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  largeScreenChatGroups: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  openChatsButtonContainer: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    marginBottom: theme.spacing(1),
  },
  mobileChatDrawerContent: {
    maxWidth: "80vw",
  },
}));

export default function Chat() {
  const classes = useStyles();
  const { threadId, chatGroupId } = useParams();
  const [mobileChatGroupsOpen, setMobileChatGroupsOpen] = useState(false);

  const toggleChatGroupsMobile = () =>
    setMobileChatGroupsOpen((isOpen) => !isOpen);
  const chatGroups = (
    <Grid container>
      <Grid item xs={12} className={classes.chatGroupsContainer}>
        <Grid container>
          <ChatGroupsSearchController
            selectedThreadId={threadId}
            onClickChatGroupCard={toggleChatGroupsMobile}
          />
        </Grid>
      </Grid>
    </Grid>
  );
  const mobileDrawer = (
    <SwipeableDrawer
      className={classes.smallScreenDrawer}
      open={mobileChatGroupsOpen}
      onClose={toggleChatGroupsMobile}
      onOpen={toggleChatGroupsMobile}
      hysteresis={0.1}
      disableBackdropTransition
      disableSwipeToOpen
    >
      <Grid container className={classes.mobileChatDrawerContent}>
        <Grid item xs={12}>
          {chatGroups}
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
  return (
    <Grid container justifyContent="center" className={classes.chatContainer}>
      <PageHead title="Chat | bibliocircle" />
      <Grid item xs={11} className={classes.openChatsButtonContainer}>
        <Grid container justifyContent="center">
          <Grid item xs={6}>
            <Button
              onClick={toggleChatGroupsMobile}
              fullWidth
              variant="text"
              color="primary"
              size="small"
              startIcon={<ChatsIcon />}
              disableElevation
            >
              Open Chats
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={11} md={11} lg={8}>
        <Grid container spacing={2} justifyContent="center">
          {mobileDrawer}
          <Grid item xs={3} className={classes.largeScreenChatGroups}>
            {chatGroups}
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid container className={classes.chatThread}>
              {chatGroupId ? (
                <ChatThread chatGroupId={chatGroupId} />
              ) : (
                <Grid container>
                  <Grid item xs={12}>
                    <Banner
                      title="Here's a Tip!"
                      message="You can send a chat message to a user by visiting their profile, or through their advertisements."
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
