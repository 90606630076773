import UserCreatedCritiqueTransformer from "./UserCreatedCritiqueTransformer";
import UserCreatedDiscussionTransformer from "./UserCreatedDiscussionTransformer";
import UserPublishedStoryChapterTransformer from "./UserPublishedStoryChapterTransformer";
import UserPublishedStoryTransformer from "./UserPublishedStoryTransformer";
import UserVotedCritiqueTransformer from "./UserVotedCritiqueTransformer";
import UserVotedDiscussionTransformer from "./UserVotedDiscussionTransformer";
import UserCommentedDiscussionTransformer from "./UserCommentedDiscussionTransformer";
import UserJoinedPublicGroupTransformer from "./UserJoinedPublicGroupTransformer";
import UserCreatedGroupTransformer from "./UserCreatedGroupTransformer";
import UserCreatedBookRequestTransformer from "./UserCreatedBookRequestTransformer";
import UserReviewedStoryTransformer from "./StoryReviewedTransformer";
import StoryFeaturedTransformer from "./StoryFeaturedTransformer";
import UserCreatedAdvertisementTransformer from "./UserCreatedAdvertisementTransformer";
import UserSignedUpTransformer from "./UserSignedUpTransformer";

const transformers = {
  USER_CREATED_CRITIQUE: UserCreatedCritiqueTransformer,
  USER_CREATED_DISCUSSION: UserCreatedDiscussionTransformer,
  USER_PUBLISHED_STORY: UserPublishedStoryTransformer,
  USER_PUBLISHED_STORY_CHAPTER: UserPublishedStoryChapterTransformer,
  USER_VOTED_CRITIQUE: UserVotedCritiqueTransformer,
  USER_VOTED_DISCUSSION: UserVotedDiscussionTransformer,
  USER_COMMENTED_DISCUSSION: UserCommentedDiscussionTransformer,
  USER_JOINED_PUBLIC_GROUP: UserJoinedPublicGroupTransformer,
  USER_CREATED_GROUP: UserCreatedGroupTransformer,
  USER_CREATED_BOOK_REQUEST: UserCreatedBookRequestTransformer,
  USER_REVIEWED_STORY: UserReviewedStoryTransformer,
  STORY_FEATURED: StoryFeaturedTransformer,
  USER_CREATED_ADVERTISEMENT: UserCreatedAdvertisementTransformer,
  USER_SIGNED_UP: UserSignedUpTransformer,
};

export const getFeedPostTransformer = (feedPostType) => {
  return transformers[feedPostType];
};
