import React, { useState } from "react";
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import PageTitle from "../Common/PageTitle";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ListingsSearchController from "../Search/ListingsSearchController";
import { useMutation, useQuery } from "@apollo/client";
import { GET_LISTING_COLLECTION } from "../../consts/queries";
import { useDispatch } from "react-redux";
import { actionTypes } from "../index.reducer";
import SearchGroup from "../Search/SearchGroup";
import { getUserFullName } from "../../utils/common";
import UserAvatar from "../Common/UserAvatar";
import Hyperlink from "../Common/Hyperlink";
import ConfirmPrompt from "../Common/ConfirmPrompt";
import { DELETE_LISTING_COLLECTION } from "../../consts/mutations";
import PageHead from "../Common/PageHead";
import PrivateIcon from "@material-ui/icons/VisibilityOff";
import ErrorPage from "../Error/ErrorPage";
import { useLoader, useLoggedInUser, useScrollTop } from "../../utils/hooks";
import ImageFadeIn from "../Common/ImageFadeIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import { FacebookShareButton } from "react-share";

const useStyles = makeStyles((theme) => ({
  collectionDiscoverer: {
    marginTop: theme.spacing(3),
  },
  searchGroup: {
    paddingTop: theme.spacing(3),
    background: theme.palette.gradient.light,
    paddingBottom: "20px",
  },
  pageTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  coverImage: {
    borderRadius: theme.spacing(1),
    width: "100%",
  },
  collectionSearchBox: {
    width: "100%",
  },
  collectionSearchBoxInput: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    "&::placeholder": {
      color: theme.palette.primary.main,
    },
  },
  collectionSearchContainer: {
    marginBottom: theme.spacing(2),
  },
  ownerAvatarLabel: {
    color: theme.palette.grey[700],
  },
  pageVisibilityChip: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(3),
    color: theme.palette.primary.main,
  },
  privateIcon: {
    fontSize: 16,
    marginRight: theme.spacing(1),
  },
  listingDescription: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
  },
}));

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export default function ListingCollections() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInUser = useLoggedInUser();
  const { collectionId } = useParams();
  const queryParams = useQueryParams();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [deleteCollectionDecisionPending, setDeleteCollectionDecisionPending] =
    useState(false);
  const { loading, error, data } = useQuery(GET_LISTING_COLLECTION, {
    variables: { id: collectionId },
  });
  const [deleteCollection] = useMutation(DELETE_LISTING_COLLECTION);
  const startAction = () => setActionInProgress(true);
  const endAction = () => setActionInProgress(false);
  const isListingSearch =
    history.location?.pathname === `/collection/${collectionId}/listings`;

  useScrollTop();

  const showDeleteCollectionPrompt = () =>
    setDeleteCollectionDecisionPending(true);

  const handleDeleteCollectionDecision = (accept) => {
    setDeleteCollectionDecisionPending(false);
    if (accept) {
      startAction();
      deleteCollection({
        variables: {
          id: collectionId,
        },
      })
        .then(() => {
          dispatch({
            type: actionTypes.INFO_MESSAGE,
            message: "Successfully deleted the collection!",
          });
          history.push("/collections");
        })
        .catch(() => {
          dispatch({
            type: actionTypes.ERROR_MESSAGE,
            message:
              "Something went wrong while deleting the collection. Please try again later.",
          });
        })
        .finally(endAction);
    }
  };
  useLoader(loading);

  if (error) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the collection you were looking for. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  if (!data) return null;
  const { listingCollection } = data;
  if (!listingCollection) {
    return (
      <ErrorPage
        title="Unavailable"
        description="This collection is either removed, or does not exist!"
        statusCode={404}
      />
    );
  }

  const listingOwnerName = getUserFullName(listingCollection.owner);
  const isOwnCollection =
    loggedInUser && loggedInUser.id === listingCollection.owner?.id;
  return (
    <>
      <PageHead title={`${listingCollection.title} | bibliocircle`} />
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        className={classes.searchGroup}
      >
        <SearchGroup
          listingCollectionScope={listingCollection.id}
          placeholder={`Search in ${listingOwnerName}'s collection`}
        />
      </Grid>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              className={classes.collectionDiscoverer}
            >
              <Grid item xs={11} lg={10}>
                <Grid container spacing={4}>
                  <Grid item xs={12} className={classes.pageTitle}>
                    <Grid
                      container
                      spacing={3}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={12}>
                        <Grid
                          spacing={2}
                          container
                          alignItems="center"
                          alignContent="center"
                          justifyContent="center"
                        >
                          {!listingCollection.public && (
                            <Grid item xs={12}>
                              <Grid container justifyContent="center">
                                <Typography
                                  variant="subtitle2"
                                  align="center"
                                  className={classes.pageVisibilityChip}
                                >
                                  <Grid
                                    container
                                    alignContent="stretch"
                                    alignItems="center"
                                  >
                                    <Grid item>
                                      <Grid container alignItems="center">
                                        <PrivateIcon
                                          className={classes.privateIcon}
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid item>Private</Grid>
                                  </Grid>
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item xs={12}>
                                <Grid container justifyContent="center">
                                  <Grid item>
                                    <Grid container>
                                      <Grid
                                        item
                                        component={Hyperlink}
                                        to={`/collection/${collectionId}`}
                                      >
                                        <PageTitle variant="h4" align="center">
                                          {listingCollection.title}
                                        </PageTitle>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={12}>
                                <Grid
                                  container
                                  spacing={1}
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  {isOwnCollection && (
                                    <>
                                      <Grid item>
                                        <Button
                                          size="small"
                                          component={Hyperlink}
                                          to={`/collection/${collectionId}/edit`}
                                          variant="outlined"
                                          color="primary"
                                          disabled={actionInProgress}
                                        >
                                          Edit Collection
                                        </Button>
                                      </Grid>
                                      <Grid item>
                                        <ConfirmPrompt
                                          open={deleteCollectionDecisionPending}
                                          onAction={
                                            handleDeleteCollectionDecision
                                          }
                                          question="Are you sure you want to delete this collection?"
                                        />
                                        <Button
                                          onClick={showDeleteCollectionPrompt}
                                          size="small"
                                          variant="outlined"
                                          color="secondary"
                                          disabled={actionInProgress}
                                        >
                                          Delete Collection
                                        </Button>
                                      </Grid>
                                    </>
                                  )}
                                  <Grid item>
                                    <FacebookShareButton
                                      url={window.location.href}
                                      hashtag="#bibliocircle"
                                    >
                                      <Button
                                        variant="text"
                                        color="primary"
                                        fullWidth
                                        disableElevation
                                        disableRipple
                                        startIcon={<FacebookIcon />}
                                      >
                                        Share On Facebook
                                      </Button>
                                    </FacebookShareButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {listingCollection.description && (
                            <Grid item>
                              <Typography
                                variant="body1"
                                align="center"
                                className={classes.listingDescription}
                              >
                                {listingCollection.description}
                              </Typography>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignContent="center"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Grid item>
                                    <Typography
                                      align="right"
                                      variant="caption"
                                      className={classes.ownerAvatarLabel}
                                    >
                                      A Collection By
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <UserAvatar
                                      user={listingCollection.owner}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {listingCollection.coverImage && !isListingSearch && (
                        <Grid
                          item
                          xs={12}
                          style={{ width: "100%" }}
                          justifyContent="center"
                        >
                          <Grid container>
                            <Grid item xs={12}>
                              <ImageFadeIn
                                src={listingCollection.coverImage}
                                alt={listingCollection.title}
                                className={classes.coverImage}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <ListingsSearchController
                          searchArgs={{
                            query: queryParams.get("q"),
                            location: queryParams.get("location"),
                            category: queryParams.get("category"),
                            priceModelType: queryParams.get("priceModelType"),
                            listingCollectionScope: collectionId,
                            language: queryParams.get("language"),
                            minPrice: queryParams.get("minPrice"),
                            maxPrice: queryParams.get("maxPrice"),
                          }}
                          noResultsMessage="No matching books found in this collection yet"
                          showEndOfResultsBanner={false}
                          cachePolicy="cache-first"
                          xs={12}
                          md={6}
                          lg={6}
                          xl={4}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
