import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  makeStyles,
  Button,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import LandingPage from "./LandingPage";
import BootstrapInput from "../Application/Common/BootstrapInput";
import { INITIATE_RESET_PASSWORD } from "../consts/mutations";
import { useDispatch } from "react-redux";
import { actionTypes } from "../Application/index.reducer";
import { BACKEND_URL } from "../config";
import Hyperlink from "../Application/Common/Hyperlink";
import LoginIcon from "@material-ui/icons/ExitToApp";
import PageHead from "../Application/Common/PageHead";
import { isValidEmail } from "../utils/common";
import { useLoader } from "../utils/hooks";
import { initiateGoogleLogin, isWebViewAvailable } from "../mobile/events";

const useStyles = makeStyles((theme) => ({
  inputField: {
    backgroundColor: "white",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
  },
  contentHeading: {
    fontWeight: "bold",
  },
  contentDescription: {
    padding: theme.spacing(3),
    textAlign: "center",
  },
  confirmBtnContainer: {
    border: "none",
  },
  confirmBtn: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.getContrastText(theme.palette.grey[800]),
  },
  resetPasswordFormContainer: {
    paddingTop: "5vh",
    paddingBottom: "5vh",
  },
  formHelperText: {
    color: theme.palette.background.paper,
  },
}));

function ResetPasswordInitiate() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [initiateResetPassword, { loading }] = useMutation(
    INITIATE_RESET_PASSWORD
  );
  const dispatchErrorMessage = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });

  useLoader(loading);
  const onChangeEmail = (event) => setEmail(event.target.value);

  function onClickResetPassword() {
    setInvalidEmail(false);
    if (isValidEmail(email)) {
      initiateResetPassword({
        variables: {
          email: email.trim(),
        },
      })
        .then(() => history.push("/resetpassword/confirm"))
        .catch(() => {
          dispatchErrorMessage(
            "Error occurred while resetting password. Please try again later!"
          );
        });
    } else {
      setInvalidEmail(true);
    }
  }

  const onKeyDown = (event) => {
    if (event.keyCode === 13) onClickResetPassword();
  };

  const notifyMobileLogin = (event) => {
    initiateGoogleLogin();
    if (isWebViewAvailable()) {
      event.preventDefault();
    }
  };

  return (
    <LandingPage coverImage="/forgotpassword.svg">
      <PageHead title="Reset Password | bibliocircle" />
      <Grid
        container
        justifyContent="center"
        className={classes.resetPasswordFormContainer}
        spacing={6}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={10}>
              <Typography
                variant="h3"
                align="center"
                className={classes.contentHeading}
              >
                Reset Password
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Typography variant="h6" className={classes.contentDescription}>
              Forgot your password? No big deal. Type your email address, we
              will send you a magic link to reset your password via email
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={10} sm={6}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <BootstrapInput
                        placeholder="Email"
                        className={classes.inputField}
                        onKeyDown={onKeyDown}
                        error={invalidEmail}
                        onChange={onChangeEmail}
                        disabled={loading}
                      ></BootstrapInput>
                      <FormHelperText
                        className={classes.formHelperText}
                        hidden={!invalidEmail}
                      >
                        A valid email address is required!
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        className={classes.confirmBtnContainer}
                      >
                        <Button
                          variant="contained"
                          onClick={onClickResetPassword}
                          disableElevation
                          className={classes.confirmBtn}
                        >
                          Confirm
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} md={8} xl={6}>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12}>
                          <Typography variant="subtitle2" align="center">
                            If you are a returning user,
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container justifyContent="center">
                            <Button
                              variant="contained"
                              fullWidth
                              disableElevation
                              className={classes.loginTypeButton}
                              component={Hyperlink}
                              to="/login"
                            >
                              Login with Password
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={8} xl={6}>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12}>
                          <Typography variant="subtitle2" align="center">
                            If you are a Google user,
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            fullWidth
                            disableElevation
                            className={classes.loginTypeButton}
                            href={`${BACKEND_URL}/auth/google/login`}
                            onClick={notifyMobileLogin}
                          >
                            Login with Google
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center">
                        <Grid item xs={8}>
                          <Grid container justifyContent="center">
                            <Button
                              variant="text"
                              fullWidth
                              endIcon={<LoginIcon />}
                              disableElevation
                              style={{ color: "white" }}
                              component={Hyperlink}
                              to={"/"}
                            >
                              Continue as a guest
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LandingPage>
  );
}

export default ResetPasswordInitiate;
