import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MakeLink from "linkifyjs/react";
import Book from "./Book";
import UserAvatar from "../Common/UserAvatar";
import FavouriteIcon from "@material-ui/icons/Favorite";
import ReportIcon from "@material-ui/icons/Flag";
import DeleteIcon from "@material-ui/icons/Delete";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import VisibleIcon from "@material-ui/icons/Visibility";
import HiddenIcon from "@material-ui/icons/VisibilityOff";
import EditIcon from "@material-ui/icons/Edit";
import PublicIcon from "@material-ui/icons/Public";
import DeliveryIcon from "@material-ui/icons/LocalShipping";
import CartIcon from "@material-ui/icons/ShoppingCart";
import StoreIcon from "@material-ui/icons/Store";
import Address from "../Common/Addresses/Address";
import PhoneNumber from "../Common/PhoneNumbers/PhoneNumber";
import GroupAvatar from "../Common/GroupAvatar";
import Hyperlink from "../Common/Hyperlink";
import ShopAvatar from "../Common/ShopAvatar";
import Reporting from "../Common/Reporting";
import PriceTag from "../Common/PriceTag";
import {
  Grid,
  Typography,
  makeStyles,
  Container,
  Button,
  Chip,
  Tooltip,
  Divider,
  useTheme,
  useMediaQuery,
  Link,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import {
  TOGGLE_FAVOURITE,
  TOGGLE_ACTIVE_LISTING,
  DELETE_LISTING,
} from "../../consts/mutations";
import { GET_USER_FAVOURITES } from "../../consts/queries";
import { GENERIC_ALERT_ERROR_MESSAGE } from "../../config";
import ChatButton from "../Chat/ChatButton";
import DeactivateListingDialog from "./DeactivateListingDialog";
import Banner from "../Common/Banner";
import { priceModelTypes, TEMP_LISTING_INACTIVE_REASONS } from "../consts";
import { useHistory } from "react-router-dom";
import ConfirmPrompt from "../Common/ConfirmPrompt";
import FacebookShareButton from "../Common/FacebookShareButton";
import RecommendedListings from "../Recommendation/Listings/RecommendedListings";
import { t, Trans } from "@lingui/macro";
import {
  useAddToCart,
  useCurrentPath,
  useDispatchErrorMessage,
  useDispatchInfoMessage,
  useLoggedInUser,
  useLoginPrompt,
} from "../../utils/hooks";
import { addToCartEnabled } from "../../utils/common";
import { amber, orange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  listing: {
    color: theme.palette.grey[800],
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(3),
    },
  },
  listingTitleContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingRight: "25px",
    },
    "& *": {
      fontWeight: "bold",
    },
  },
  listingDescriptionText: {
    whiteSpace: "pre-wrap",
  },
  listingActionBtn: {
    width: "100%",
    color: theme.palette.grey[800],
  },
  shopClosedBanner: {
    backgroundColor: amber[50],
    border: `1px solid ${amber[100]}`,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  shopClosedBannerText: {
    fontWeight: "bold",
    color: orange[900],
  },
  deliveryIcon: {
    marginRight: theme.spacing(1),
    fontSize: 32,
  },
  listingBadge: {
    marginRight: theme.spacing(1),
  },
  listingTitleText: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  listingHeading: {
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  deliveryPreference: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
  deliveryPreferenceNotes: {
    textAlign: "center",
    fontSize: "12px",
  },
  desktopOnly: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobileOnly: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  facebookShareButton: {
    width: "100%",
  },
  notes: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  notesHeading: { fontWeight: "bold" },
  notesContent: {
    whiteSpace: "pre-wrap",
  },
  notesLinkClass: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default function ListingViewer({ listing, readOnly }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatchErrorMessage = useDispatchErrorMessage();
  const dispatchInfoMessage = useDispatchInfoMessage();
  const addToCart = useAddToCart();
  const theme = useTheme();
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const loggedInUser = useLoggedInUser();
  const currentPath = useCurrentPath();
  const [reporterOpen, setReporterOpen] = useState(false);
  const [deleteDecisionPending, setDeleteDecisionPending] = useState(false);
  const [deactivateDecisionPending, setDeactivateDecisionPending] =
    useState(false);
  const [isFavouriteListing, setIsFavouriteListing] = useState(false);
  const openReporter = () => setReporterOpen(true);
  const closeReporter = () => setReporterOpen(false);
  const toggleDeactivateDecisionPending = () =>
    setDeactivateDecisionPending((isOpen) => !isOpen);
  const isOwnListing = loggedInUser?.id === listing.seller.id;

  const [deleteListing, { loading: deletingListing }] =
    useMutation(DELETE_LISTING);
  const [toggleFavourite, { loading: togglingFavourite }] = useMutation(
    TOGGLE_FAVOURITE,
    {
      refetchQueries: [
        {
          query: GET_USER_FAVOURITES,
          variables: { favouritedUserScope: loggedInUser?.id },
        },
      ],
    }
  );

  const [toggleActiveListing, { loading: togglingActiveListing }] = useMutation(
    TOGGLE_ACTIVE_LISTING
  );

  useEffect(() => {
    setIsFavouriteListing(listing.isFavourite);
  }, [listing]);

  const handleToggleFavourite = createLoginPromptEventHandler({
    redirectPath: currentPath,
    callback: () => {
      toggleFavourite({
        variables: { listingId: listing.id },
      })
        .then(({ data }) => {
          setIsFavouriteListing(data?.toggleFavouriteListing?.isFavourite);
        })
        .catch(() => {
          dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
        });
    },
  });

  const handleToggleActiveListing = (reason) => {
    const successMessage = listing.active
      ? t`This advertisement is now deactivated`
      : t`This advertisement is now active and visible on bibliocircle`;
    toggleActiveListing({
      // make sure we don't set deactivate reason when activating the listing
      variables: { id: listing.id, reason: !listing.active ? null : reason },
    })
      .then(() => {
        dispatchInfoMessage(successMessage);
      })
      .catch((err) => {
        dispatchErrorMessage(
          t`Unable to toggle favourite at the moment. Please try again later!`
        );
      });
  };

  const handleDeactivateListing = () => {
    toggleDeactivateDecisionPending();
  };

  const onDeactivateDecision = (reason) => {
    handleToggleActiveListing(reason);
    toggleDeactivateDecisionPending();
  };

  const handleActivateListing = () => handleToggleActiveListing(null); // set deactivate reason to null, when activating

  const handleAddToCart = createLoginPromptEventHandler({
    redirectPath: currentPath,
    callback: () => {
      addToCart(listing);
    },
  });

  const handleClickDelete = () => {
    setDeleteDecisionPending(true);
  };

  const handleDeleteDecision = (accept) => {
    setDeleteDecisionPending(false);
    if (accept) {
      return deleteListing({
        variables: { id: listing.id },
      })
        .then(() => {
          history.push(`/listings`);
          dispatchInfoMessage(t`Your advertisement was deleted successfully!`);
        })
        .catch((err) => {
          dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
        });
    }
  };

  const getFavouriteButton = (isFavourite = false) => {
    const icon = isFavourite ? <FavouriteIcon /> : <FavoriteBorderIcon />;
    return (
      <Grid container justifyContent="center">
        <Button
          disableElevation
          startIcon={icon}
          color="default"
          variant="contained"
          onClick={handleToggleFavourite}
          className={classes.listingActionBtn}
          disabled={togglingFavourite}
        >
          {isFavourite ? t`Remove from Favourites` : t`Add to Favourites`}
        </Button>
      </Grid>
    );
  };

  const getToggleActiveListingButton = (active = true) => {
    if (!loggedInUser) return null;
    const icon = active ? <HiddenIcon /> : <VisibleIcon />;
    const tooltip = active
      ? t`Deactivated ads won't be visible in search results.`
      : t`Activate this ad so that others can find it via search results`;
    return (
      <Grid container justifyContent="center">
        <DeactivateListingDialog
          open={deactivateDecisionPending}
          onCancel={toggleDeactivateDecisionPending}
          onSubmit={onDeactivateDecision}
        />
        <Tooltip title={tooltip}>
          <Button
            disableElevation
            startIcon={icon}
            color="default"
            variant="text"
            onClick={active ? handleDeactivateListing : handleActivateListing}
            className={classes.listingActionBtn}
            disabled={togglingActiveListing}
          >
            {active ? t`Deactivate Ad` : t`Activate Ad`}
          </Button>
        </Tooltip>
      </Grid>
    );
  };

  const addToCartButton = (
    <Grid container justifyContent="center">
      <Button
        disableElevation
        startIcon={<CartIcon />}
        variant="contained"
        onClick={handleAddToCart}
        className={classes.listingActionBtn}
      >
        <Trans>Add to Cart</Trans>
      </Button>
    </Grid>
  );

  let contactPersonTitle = t`Owner`;
  if (
    [priceModelTypes.RENT, priceModelTypes.EXCHANGE].includes(
      listing.priceModelType
    )
  )
    contactPersonTitle = t`Renter`;
  if (listing.priceModelType === priceModelTypes.SALE)
    contactPersonTitle = t`Seller`;

  const getInactiveStatusBanner = () => {
    let title = t`Unavailable`;
    let description = t`This advertisement is deactivated.`;
    const reasonsMap = TEMP_LISTING_INACTIVE_REASONS.reduce((result, curr) => {
      result[curr.value] = {
        code: curr.value,
        description: curr.buyerDescription,
        shortText: curr.shortText,
      };
      return result;
    }, {});
    switch (listing?.inactiveReason) {
      case reasonsMap.SOLD_OUT.code:
        title = reasonsMap.SOLD_OUT.shortText;
        description = reasonsMap.SOLD_OUT.description;
        break;
      case reasonsMap.RENTED_OUT.code:
        title = reasonsMap.RENTED_OUT.shortText;
        description = reasonsMap.RENTED_OUT.description;
        break;
      case reasonsMap.NOT_IN_STOCK.code:
        title = reasonsMap.NOT_IN_STOCK.shortText;
        description = reasonsMap.NOT_IN_STOCK.description;
        break;
      default:
    }

    return <Banner title={title} type="error" message={description} />;
  };

  const editListingButton = (
    <Grid container justifyContent="center">
      <Button
        disableElevation
        component={Hyperlink}
        to={`/listing/${listing.id}/edit`}
        startIcon={<EditIcon />}
        color="default"
        variant="text"
        className={classes.listingActionBtn}
      >
        <Trans>Edit Ad</Trans>
      </Button>
    </Grid>
  );

  const reportListingButton = (
    <Grid container justifyContent="center">
      <Tooltip
        title={t`Is the listing content inappropriate? Let us know!`}
        aria-label={t`Is the listing content inappropriate? Let us know!`}
      >
        <Button
          disableElevation
          color="secondary"
          fullWidth
          startIcon={<ReportIcon />}
          onClick={openReporter}
        >
          <Trans>Report this</Trans>
        </Button>
      </Tooltip>
    </Grid>
  );

  const deleteListingButton = (
    <Grid container justifyContent="center">
      <Button
        disableElevation
        color="secondary"
        fullWidth
        disabled={deletingListing}
        startIcon={<DeleteIcon />}
        onClick={handleClickDelete}
      >
        <Trans>Delete Ad</Trans>
      </Button>
    </Grid>
  );

  const contactNumbersSection = (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Typography variant="subtitle2">
            {t`Contact ${contactPersonTitle}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} lg={12}>
        {loggedInUser ? (
          <Grid container justifyContent="center" spacing={2}>
            {listing.contactNumbers.map((contactNumber) => (
              <Grid key={contactNumber.id} item xs={12}>
                <PhoneNumber
                  phoneNumber={contactNumber.phoneNumber}
                  justifyContent="center"
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container>
            <Banner
              fullWidth
              type="basic"
              message={
                <Typography variant="body2" color="textSecondary">
                  <Trans>
                    <Hyperlink
                      to={{
                        pathname: "/login",
                        state: {
                          redirectPath: `${window.location.pathname}${window.location.search}`,
                        },
                      }}
                    >
                      <Link color="primary">Log in</Link>
                    </Hyperlink>{" "}
                    to view contact numbers.
                  </Trans>
                </Typography>
              }
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const getDeliveryDetailsSection = (shop) => {
    if (
      !Array.isArray(shop.deliveryPreferences) ||
      !shop.deliveryPreferences.length
    )
      return null;
    const homeDelivery = shop.deliveryPreferences.find(
      (pref) => pref.type === "HOME_DELIVERY"
    );
    const pickUp = shop.deliveryPreferences.find(
      (pref) => pref.type === "PICK_UP_AT_STORE"
    );

    const defaultHomeDeliveryNotes = t`This item can be delivered.`;
    const defaultpickUpNotes = t`This book can be picked up from ${shop?.name}`;
    return (
      <Grid container spacing={2}>
        {homeDelivery && (
          <Grid item xs={12}>
            <Grid container className={classes.deliveryPreference}>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <DeliveryIcon className={classes.deliveryIcon} />
                  <Typography variant="subtitle2">
                    <Trans>Home Delivery</Trans>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.deliveryPreferenceNotes}
                >
                  {homeDelivery.notes || defaultHomeDeliveryNotes}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {pickUp && (
          <Grid item xs={12}>
            <Grid container className={classes.deliveryPreference}>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <StoreIcon className={classes.deliveryIcon} />
                  <Typography variant="subtitle2">
                    <Trans>Available for pick-up</Trans>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.deliveryPreferenceNotes}
                >
                  {pickUp.notes || defaultpickUpNotes}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const shopAvatarSection = (
    <Grid container item xs={12} spacing={2}>
      <Grid container item xs={12} justifyContent="center">
        <Typography variant="subtitle2">
          <Trans>Available in</Trans>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <ShopAvatar shop={listing.shop} />
        </Grid>
      </Grid>
    </Grid>
  );

  const listingContactNumbersView = (
    <Grid container item xs={12}>
      {contactNumbersSection}
    </Grid>
  );

  const listingAddressesView = (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Typography variant="subtitle2">
            <Trans>Available locations</Trans>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={12}>
        {loggedInUser ? (
          <Grid container spacing={2}>
            {listing.addresses.map((address) => (
              <Grid key={address.id} item xs={12}>
                <Grid container justifyContent="center">
                  <Address address={address} />
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container>
            <Banner
              fullWidth
              type="basic"
              message={
                <Typography variant="body2" color="textSecondary">
                  <Trans>
                    <Hyperlink
                      to={{
                        pathname: "/login",
                        state: {
                          redirectPath: `${window.location.pathname}${window.location.search}`,
                        },
                      }}
                    >
                      <Link color="primary">Log in</Link>
                    </Hyperlink>{" "}
                    to view addresses.
                  </Trans>
                </Typography>
              }
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const visibilityGroupsView = (
    <Grid container item xs={12} alignItems="flex-start" spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Typography variant="subtitle2">
            <Trans>Shared in</Trans>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {listing.groups.map((group) => (
            <Grid key={group.id} item xs={12}>
              <GroupAvatar group={group} size="sm" />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );

  const tagsView = (
    <Grid container alignItems="flex-start">
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Typography variant="subtitle2">
                <Trans>Tags</Trans>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={1}>
              {listing.tags.map((tag) => (
                <Grid key={tag} item>
                  <Hyperlink to={`/listings?tags=${tag}`}>
                    <Chip size="small" label={tag} clickable />
                  </Hyperlink>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <ConfirmPrompt
        open={deleteDecisionPending}
        onAction={handleDeleteDecision}
        question={t`Are you sure you want delete this listing?`}
      />
      <Container maxWidth="xl">
        <Reporting
          type="LISTING"
          resourceId={listing.id}
          open={reporterOpen}
          onClose={closeReporter}
        />
        <Grid item xs={12} className={classes.listing}>
          <Grid
            container
            justifyContent="center"
            alignItems="flex-start"
            spacing={5}
          >
            <Grid item xs={12} lg={9}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.listingHeading}
                    spacing={1}
                  >
                    <Grid
                      item
                      xs={isSmallDevice && 12}
                      className={classes.listingTitleContainer}
                    >
                      <Typography
                        variant="h4"
                        className={classes.listingTitleText}
                      >
                        {listing.title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {listing.public && (
                        <Chip
                          className={classes.listingBadge}
                          icon={<PublicIcon />}
                          label={t`Public`}
                          color="primary"
                          size="small"
                        />
                      )}
                      {!listing.active && (
                        <Chip
                          className={classes.listingBadge}
                          icon={<HiddenIcon />}
                          label={t`Inactive`}
                          color="default"
                          size="small"
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Typography
                      variant="body1"
                      className={classes.listingDescriptionText}
                    >
                      {listing.description}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={5}>
                    {listing.books.map((book, i) => (
                      <Grid item xs={12} key={book.id}>
                        <Grid container spacing={5}>
                          <Grid item xs={12}>
                            <Book book={book} />
                          </Grid>
                          {i < listing.books.length - 1 && (
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                {isOwnListing && listing.notes && (
                  <Grid item xs={12}>
                    <Grid container className={classes.notes}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              variant="h6"
                              className={classes.notesHeading}
                            >
                              <Trans>Private Notes</Trans>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              className={classes.notesContent}
                            >
                              <MakeLink
                                options={{
                                  attributes: {
                                    className: classes.notesLinkClass,
                                  },
                                }}
                              >
                                {listing.notes}
                              </MakeLink>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="caption" color="secondary">
                              <Trans>* Only you can see this</Trans>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} className={classes.desktopOnly}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <RecommendedListings
                        listingId={listing?.id}
                        showDivider
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={3} className={classes.listingMeta}>
              <Grid container alignContent="flex-start" justifyContent="center">
                <Grid item xs={12} md={6} lg={10}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Grid container justifyContent="center">
                            <Typography variant="subtitle2">
                              <Trans>Posted By</Trans>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container justifyContent="center" spacing={1}>
                            <Grid item xs={12}>
                              <Grid container justifyContent="center">
                                <UserAvatar
                                  user={listing.seller}
                                  justifyContent="center"
                                />
                              </Grid>
                            </Grid>
                            {!isOwnListing && (
                              <Grid item xs={12}>
                                <Grid container justifyContent="center">
                                  <ChatButton
                                    initialMessage={window.location.href}
                                    recipient={listing.seller}
                                    label={t`Message ${contactPersonTitle}`}
                                    buttonProps={{
                                      variant: "text",
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container justifyContent="center">
                        <Grid item xs={12} sm={6} lg={12}>
                          <PriceTag
                            price={listing.price}
                            originalPrice={listing.originalPrice}
                            priceModelType={listing.priceModelType}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {!listing.active && (
                      <Grid item xs={12}>
                        <Grid container justifyContent="center">
                          <Grid item xs={12}>
                            {getInactiveStatusBanner()}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {!readOnly && (
                      <Grid item xs={12}>
                        <Grid container justifyContent="center">
                          <Grid item xs={12} sm={6} lg={12}>
                            <Grid container spacing={1}>
                              {listing.shop?.closed && (
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    className={classes.shopClosedBanner}
                                  >
                                    <Typography
                                      variant="body1"
                                      className={classes.shopClosedBannerText}
                                    >
                                      <Trans>
                                        {listing.shop.name} is not temporarily
                                        accepting orders. Please check again
                                        later.
                                      </Trans>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )}
                              {addToCartEnabled(listing) &&
                                !listing.shop?.closed && (
                                  <Grid item xs={12}>
                                    {addToCartButton}
                                  </Grid>
                                )}
                              {listing.active && (
                                <Grid item xs={12}>
                                  {getFavouriteButton(isFavouriteListing)}
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <FacebookShareButton
                                  className={classes.facebookShareButton}
                                />
                              </Grid>
                              {loggedInUser && (
                                <>
                                  {isOwnListing && (
                                    <Grid item xs={12}>
                                      {editListingButton}
                                    </Grid>
                                  )}
                                  {isOwnListing && (
                                    <Grid item xs={12}>
                                      {getToggleActiveListingButton(
                                        listing.active
                                      )}
                                    </Grid>
                                  )}
                                </>
                              )}
                              {!isOwnListing && (
                                <Grid item xs={12}>
                                  {reportListingButton}
                                </Grid>
                              )}
                              {isOwnListing && (
                                <Grid item xs={12}>
                                  {deleteListingButton}
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {listing.shop && (
                      <>
                        <Grid item xs={12}>
                          {shopAvatarSection}
                        </Grid>
                        {listing.active && (
                          <Grid item xs={12}>
                            {getDeliveryDetailsSection(listing.shop)}
                          </Grid>
                        )}
                      </>
                    )}
                    {listing.active && (
                      <React.Fragment>
                        {!!listing.contactNumbers.length && (
                          <Grid item xs={12}>
                            {listingContactNumbersView}
                          </Grid>
                        )}
                        {!!listing.addresses.length && (
                          <Grid item xs={12}>
                            {listingAddressesView}
                          </Grid>
                        )}
                      </React.Fragment>
                    )}
                    {!!listing.groups.length && (
                      <Grid item xs={12}>
                        {visibilityGroupsView}
                      </Grid>
                    )}
                    {!!listing.tags.length && (
                      <Grid item xs={12}>
                        {tagsView}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.mobileOnly}>
              <RecommendedListings listingId={listing?.id} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

ListingViewer.propTypes = {
  listing: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  loggedInUser: PropTypes.object,
  onClickAddToCart: PropTypes.func,
  onClickToggleFavourites: PropTypes.func,
  onClickReport: PropTypes.func,
  onClickEdit: PropTypes.func,
};
