import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import BootstrapInputBordered from "../BootstrapInputBordered";
import { grey } from "@material-ui/core/colors";
import Required from "../Required";

const useStyles = makeStyles((theme) => ({
  spannedInput: {
    width: "100%",
  },
}));

export default function PhoneNumberEditorDialog({
  contactNumber,
  onSave,
  open,
  onClose,
}) {
  const classes = useStyles();
  const [phoneNumberId, setPhoneNumberId] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isDefault, setIsDefault] = useState(false);

  useEffect(() => {
    setPhoneNumberId(contactNumber.id);
    setPhoneNumber(contactNumber.phoneNumber);
    setIsDefault(contactNumber.isDefault);
  }, [contactNumber]);

  const onChangePhoneNumber = (event) => setPhoneNumber(event.target.value);
  const onChangeIsDefault = (event) => setIsDefault(event.target.checked);

  const onClickSave = () => {
    const contactNumberData = {
      phoneNumber,
      isDefault,
    };
    if (phoneNumberId) contactNumberData.id = phoneNumberId;

    onSave(contactNumberData, () => {
      // Clear state
      setPhoneNumberId(null);
      setPhoneNumber("");
      setIsDefault(false);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick={true}
      maxWidth="xs"
      disableScrollLock={true}
    >
      <DialogTitle>Phone Number</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl className={classes.spannedInput}>
              <InputLabel shrink color="primary">
                <Required>Phone Number</Required>
              </InputLabel>
              <BootstrapInputBordered
                bordered
                value={phoneNumber}
                onChange={onChangePhoneNumber}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDefault}
                  onChange={onChangeIsDefault}
                  color="primary"
                />
              }
              label="Set this contact as the default for future listings"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: grey[600] }}>
          Discard
        </Button>
        <Button onClick={onClickSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PhoneNumberEditorDialog.propTypes = {
  contactNumber: PropTypes.object,
  onSave: PropTypes.func,
};

PhoneNumberEditorDialog.defaultProps = {
  contactNumber: {},
  onSave() {},
  open() {},
  onClose() {},
};
