import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Container,
  Divider,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useLocation } from "react-use";
import { GET_STORY } from "../../../consts/queries";
import {
  useDispatchErrorMessage,
  useDispatchInfoMessage,
  useLoader,
  useLoggedInUser,
  useScrollTop,
} from "../../../utils/hooks";
import ErrorPage from "../../Error/ErrorPage";
import LargeStoryCard from "./LargeStoryCard";
import StoryReviewsSearchController from "./StoryReview/StoryReviewsSearchController";
import PublishIcon from "@material-ui/icons/Public";
import StoryIcon from "../StoryIcon";
import Hyperlink from "../../Common/Hyperlink";
import StoryReviewEditorDialog from "./StoryReview/StoryReviewEditorDiaglog";
import SuggestedStories from "./SuggestedStories";
import CompleteIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";
import { amber, green } from "@material-ui/core/colors";
import {
  DELETE_STORY,
  TOGGLE_EDITOR_PICK_STORY,
  TOGGLE_FEATURE_STORY,
  TOGGLE_MARK_STORY_AS_COMPLETE,
  TOGGLE_PUBLISH_STORY,
} from "../../../consts/mutations";
import { GENERIC_ALERT_ERROR_MESSAGE } from "../../../config";
import ConfirmPrompt from "../../Common/ConfirmPrompt";
import DropDownIcon from "@material-ui/icons/ArrowDropDown";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import ChapterSelection from "./ChapterSelection";
import { t, Trans } from "@lingui/macro";
import PageHead from "../../Common/PageHead";
import { USER_ROLES } from "../../consts";
import {
  Loyalty as EditorsPickIcon,
  Star as FeaturedIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => {
  return {
    page: {
      marginTop: theme.spacing(4),
    },
    storyManageBanner: {
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.grey[100],
    },
    desktopOnly: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    mobileOnly: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    publishedStoryNote: {
      color: green[600],
    },
    unpublishedStoryNote: {
      color: amber[900],
    },
    storyStatusNoteIcon: {
      marginRight: theme.spacing(0.5),
      fontSize: 18,
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    storyActionsPanel: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        flexDirection: "row",
      },
      justifyContent: "space-between",
    },
    actionButtonContent: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    adminIcon: {
      backgroundColor: green[600],
      color: theme.palette.background.paper,
      "&:hover": {
        backgroundColor: green[800],
      },
    },
  };
});

export default function Story() {
  const classes = useStyles();
  const history = useHistory();
  const loggedInUser = useLoggedInUser();
  const isAdmin = loggedInUser?.role === USER_ROLES.ADMINISTRATOR;
  const location = useLocation();
  const reviewsRef = React.createRef(null);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [deleteStoryDecisionPending, setDeleteStoryDecisionPending] =
    useState(false);
  const [
    togglePublishStoryDecisionPending,
    setTogglePublishStoryDecisionPending,
  ] = useState(false);
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] = useState(null);
  const { storyId } = useParams();
  const { loading, error, data } = useQuery(GET_STORY, {
    variables: {
      id: storyId,
    },
  });
  const [deleteStory] = useMutation(DELETE_STORY);
  const [togglePublishStory] = useMutation(TOGGLE_PUBLISH_STORY);
  const [toggleMarkStoryAsComplete] = useMutation(
    TOGGLE_MARK_STORY_AS_COMPLETE
  );
  const [toggleFeatureStory] = useMutation(TOGGLE_FEATURE_STORY);
  const [toggleEditorsPick] = useMutation(TOGGLE_EDITOR_PICK_STORY);

  useLoader(loading);
  useScrollTop();
  const dispatchErrorMessage = useDispatchErrorMessage();
  const dispatchInfoMessage = useDispatchInfoMessage();
  const startAction = () => setActionInProgress(true);
  const endAction = () => setActionInProgress(false);
  const handleOpenMoreActions = (event) =>
    setMoreActionsAnchorEl(event.currentTarget);
  const handleCloseMoreActions = () => setMoreActionsAnchorEl(null);

  useEffect(() => {
    if (location.hash.includes("#reviews") && reviewsRef.current) {
      reviewsRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [reviewsRef, location]);

  if (error) {
    return (
      <ErrorPage
        title={t`Something's not right!`}
        description={t`We could not fetch the story you were looking for. Please try later. Sorry for the inconvenience!`}
        statusCode={500}
      />
    );
  }

  if (!data) return null;
  const { story } = data;

  if (!story) {
    return (
      <ErrorPage
        title={t`Unavailable`}
        description={t`This story is either removed, or does not exist.!`}
        statusCode={404}
      />
    );
  }

  const handleDeleteStoryDecision = (confirmed) => {
    handleCloseMoreActions();
    setDeleteStoryDecisionPending(false);
    if (!confirmed) return;
    deleteStory({
      variables: { id: story.id },
    })
      .then(() => {
        setTimeout(() => {
          dispatchInfoMessage(t`Your story "${story.title}" was deleted!`);
        });
        history.push(`/profile/${loggedInUser?.id}/page/stories`);
      })
      .catch(() => {
        dispatchErrorMessage(
          t`Unable to delete the story at the moment. Please try again later!`
        );
      });
  };

  const onClickDeleteStory = () => setDeleteStoryDecisionPending(true);
  const onClickTogglePublishStory = () =>
    setTogglePublishStoryDecisionPending(true);

  const handleTogglePublishStoryDecision = (confirmed) => {
    setTogglePublishStoryDecisionPending(false);
    if (!confirmed) return;
    startAction();
    togglePublishStory({
      variables: {
        id: story.id,
      },
    })
      .then(({ data }) => {
        dispatchInfoMessage(
          data?.togglePublishStory?.published
            ? t`Story was published!`
            : t`Story was unpublished!`
        );
      })
      .catch(() => {
        dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
      })
      .finally(endAction);
  };

  const onClickToggleMarkAsComplete = () => {
    startAction();
    toggleMarkStoryAsComplete({
      variables: {
        id: story.id,
      },
    })
      .then(({ data }) => {
        dispatchInfoMessage(
          data?.toggleMarkStoryAsComplete?.isComplete
            ? t`Story was marked as complete!`
            : t`Story unmarked as complete!`
        );
      })
      .catch(() => {
        dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
      })
      .finally(endAction);
  };

  const onClickToggleFeatureStory = () => {
    startAction();
    toggleFeatureStory({
      variables: {
        id: story.id,
      },
    })
      .then(({ data }) => {
        if (data?.toggleFeatureStory) {
          dispatchInfoMessage(
            data.toggleFeatureStory.featured
              ? t`Story is featured!`
              : t`Story is removed from featured stories!`
          );
        }
      })
      .catch(() => {
        dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
      })
      .finally(endAction);
  };

  const onClickEditorsPick = () => {
    startAction();
    toggleEditorsPick({
      variables: {
        id: story.id,
      },
    })
      .then(({ data }) => {
        if (data?.toggleEditorsPick) {
          dispatchInfoMessage(
            data.toggleEditorsPick.editorsPick
              ? t`Story is selected as editor's pick!`
              : t`Story is removed from editor's picks!`
          );
        }
      })
      .catch(() => {
        dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
      })
      .finally(endAction);
  };

  const isAuthor = loggedInUser && loggedInUser.id === story.author?.id;
  const manageStoryBanner = (
    <Grid container className={classes.storyManageBanner}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          className={classes.storyActionsPanel}
          spacing={2}
        >
          {story.published ? (
            <Grid item className={classes.actionButtonContent}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                className={classes.publishedStoryNote}
              >
                <CompleteIcon className={classes.storyStatusNoteIcon} />
                <Typography variant="subtitle2" align="center">
                  <Trans>This story is published.</Trans>
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid item className={classes.actionButtonContent}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                className={classes.unpublishedStoryNote}
              >
                <InfoIcon className={classes.storyStatusNoteIcon} />
                <Typography variant="subtitle2" align="center">
                  {!!story.chaptersCount ? (
                    <Trans>
                      This story is not published, and only you can see the
                      story content.
                    </Trans>
                  ) : (
                    <Trans>
                      This story is empty. Write and publish at least one
                      chapter.
                    </Trans>
                  )}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Grid container justifyContent="center">
              {!story.isComplete && (
                <Grid item className={classes.actionButtonContent}>
                  <Button
                    disabled={actionInProgress}
                    variant="contained"
                    disableElevation
                    disableRipple
                    color="primary"
                    component={Hyperlink}
                    to={`/story/${storyId}/chapter/new`}
                    startIcon={<StoryIcon />}
                  >
                    <Trans>Write New Chapter</Trans>
                  </Button>
                </Grid>
              )}
              <Grid item className={classes.actionButtonContent}>
                <Tooltip title={t`Edit basic story details`}>
                  <Button
                    disabled={actionInProgress}
                    component={Hyperlink}
                    to={`/story/${story.id}/edit`}
                    variant="text"
                    disableElevation
                    disableRipple
                    color="primary"
                    startIcon={<EditIcon />}
                  >
                    <Trans>Edit Details</Trans>
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item className={classes.actionButtonContent}>
                <ConfirmPrompt
                  title={
                    story.published ? t`Unpublish Story` : t`Publish Story`
                  }
                  question={
                    story.published
                      ? t`Are you sure you want to unpublish story? The readers won't be able to see this story anymore.`
                      : t`Are you sure you want to publish this story? Your followers may be notified.`
                  }
                  open={togglePublishStoryDecisionPending}
                  onAction={handleTogglePublishStoryDecision}
                />

                <Tooltip
                  title={t`Others will be able to read your story on bibliocircle only when you publish it.`}
                >
                  <Button
                    disabled={
                      actionInProgress ||
                      (!story.chaptersCount && !story.published)
                    }
                    variant="text"
                    disableElevation
                    disableRipple
                    color={story.published ? "secondary" : "primary"}
                    onClick={onClickTogglePublishStory}
                    startIcon={<PublishIcon />}
                  >
                    {story.published ? (
                      <Trans>Unpublish Story</Trans>
                    ) : (
                      <Trans>Publish Story</Trans>
                    )}
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item className={classes.actionButtonContent}>
                <Tooltip
                  title={
                    story.isComplete
                      ? t`When a story is "Ongoing", it means that there will be more chapters coming.`
                      : t`When a story is "Complete", it means that no more chapters will be available in the story.`
                  }
                >
                  <Button
                    disabled={
                      actionInProgress ||
                      (!story.chaptersCount && !story.isComplete)
                    }
                    variant="text"
                    disableElevation
                    disableRipple
                    color="primary"
                    onClick={onClickToggleMarkAsComplete}
                    startIcon={<CompleteIcon />}
                  >
                    {story.isComplete ? (
                      <Trans>Mark as Ongoing</Trans>
                    ) : (
                      <Trans>Mark As Complete</Trans>
                    )}
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item className={classes.actionButtonContent}>
                <Button
                  disabled={actionInProgress}
                  variant="text"
                  disableElevation
                  disableRipple
                  color="primary"
                  endIcon={<DropDownIcon />}
                  onClick={handleOpenMoreActions}
                >
                  <Trans>More</Trans>
                </Button>
                <ConfirmPrompt
                  title={t`Delete Story Permanently`}
                  question={t`Are you sure you want to delete this story permanently? This action cannot be undone.`}
                  open={deleteStoryDecisionPending}
                  onAction={handleDeleteStoryDecision}
                />
                <Menu
                  anchorEl={moreActionsAnchorEl}
                  keepMounted
                  open={!!moreActionsAnchorEl}
                  onClose={handleCloseMoreActions}
                  className={classes.userMenu}
                  disableScrollLock
                >
                  <MenuItem onClick={onClickDeleteStory}>
                    <DeleteIcon
                      className={classes.menuIcon}
                      color="secondary"
                    />
                    <Typography variant="body2" color="secondary">
                      <Trans>Delete Story Permanently</Trans>
                    </Typography>
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const adminBanner = (
    <Grid container className={classes.storyManageBanner}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="subtitle2">Admin Actions : </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disableRipple
              size="small"
              disabled={actionInProgress || !story.published}
              startIcon={<FeaturedIcon />}
              className={classes.adminIcon}
              onClick={onClickToggleFeatureStory}
            >
              {story.featured ? "Unfeature This" : "Feature This"}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disableRipple
              size="small"
              disabled={actionInProgress || !story.published}
              startIcon={<EditorsPickIcon />}
              className={classes.adminIcon}
              onClick={onClickEditorsPick}
            >
              {story.editorsPick
                ? "Remove as Editor's Pick"
                : "Select as Editor's Pick"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Container maxWidth="xl" className={classes.page}>
      <PageHead title={`${story.title} | bibliocircle stories`} />
      <Grid container justifyContent="center" spacing={3}>
        {isAuthor && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {manageStoryBanner}
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={9}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <LargeStoryCard
                    clickable={false}
                    story={story}
                    readingCheckpoint={story.currentUserReadingCheckpoint}
                    finishedCheckpoint={story.finishedStoryReadingCheckpoint}
                    showFacebookShareButton
                    showReportButton
                    showCopyrightNotice
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={3}>
              <ChapterSelection story={story} manageMode={isAuthor} />
            </Grid>
          </Grid>
        </Grid>
        {isAdmin && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {adminBanner}
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={9}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    ref={reviewsRef}
                  >
                    <Grid item>
                      <Typography variant="h6" color="textPrimary">
                        <Trans>Reviews and Ratings</Trans>
                      </Typography>
                    </Grid>
                    {!isAuthor && (
                      <Grid item>
                        <StoryReviewEditorDialog
                          storyId={story.id}
                          currentUserReview={story.currentUserReview}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <StoryReviewsSearchController
                    searchArgs={{ storyId: story.id }}
                    xs={12}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={3}>
              <SuggestedStories storyId={story.id} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
