import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import LargeStoryCard from "../Story/LargeStoryCard";

export default function ReadingCheckpointsCard({ checkpoint }) {
  if (!checkpoint) return null;
  return (
    <Grid container>
      <LargeStoryCard
        story={checkpoint.story}
        clickable
        readingCheckpoint={checkpoint}
        showReadButton
      />
    </Grid>
  );
}

ReadingCheckpointsCard.propTypes = {
  checkpoint: PropTypes.object.isRequired,
};
