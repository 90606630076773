import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { stripTypename } from "../../utils/common";
import ReorderButtons from "../Common/ReorderButtons";

const useStyles = makeStyles((theme) => ({
  sectionBar: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[100],
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  sectionTitle: {
    fontWeight: "bold",
  },
}));

export default function SectionReorderDialog({
  open,
  onClose,
  onSave,
  sections,
}) {
  const classes = useStyles();
  const [reorderedSections, setReorderedSections] = useState([]);

  useEffect(() => {
    setReorderedSections([...sections]);
  }, [sections]);

  const onReorderButtonClick = (current, type) => {
    const updatedSections = [...reorderedSections];

    if (type === "up") {
      // current would be always greater than 0, as up on 0th is disabled
      [updatedSections[current - 1], updatedSections[current]] = [
        updatedSections[current],
        updatedSections[current - 1],
      ];
    } else if (type === "down") {
      // current would be never be the last as down on last is disabled
      [updatedSections[current], updatedSections[current + 1]] = [
        updatedSections[current + 1],
        updatedSections[current],
      ];
    }

    setReorderedSections(updatedSections);
  };

  const onSaveReorderedSections = () => {
    onSave(reorderedSections.map((section) => stripTypename(section)));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reorder Sections</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {reorderedSections.map((section, i) => (
            <Grid item xs={12} key={section.id}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                className={classes.sectionBar}
              >
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    className={classes.sectionTitle}
                  >
                    {section.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <ReorderButtons
                    isStart={i === 0}
                    isEnd={i === sections.length - 1}
                    onMoveUp={() => onReorderButtonClick(i, "up")}
                    onMoveDown={() => onReorderButtonClick(i, "down")}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Discard and Close
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          size="small"
          onClick={onSaveReorderedSections}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
