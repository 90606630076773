import React from "react";
import { Grid } from "@material-ui/core";
import { t } from "@lingui/macro";
import UsersSearchController from "../UsersSearchController";

export default function FollowersPage() {
  return (
    <Grid container>
      <UsersSearchController
        xs={12}
        md={8}
        lg={6}
        xl={4}
        searchArgs={{
          isFollower: true,
        }}
        noResultsMessage={t`You don't have any followers yet!`}
      />
    </Grid>
  );
}
