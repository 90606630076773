import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IMAGE_SERVER_URL } from "../../config";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import ListingEditor from "./ListingEditor";
import { actionTypes } from "../index.reducer";
import {
  CREATE_LISTING,
  SAVE_ADVERTISEMENT_PREFERENCES,
} from "../../consts/mutations";
import {
  GET_USER_CONTACTS,
  GET_ADVERTISEMENT_PREFERENCES,
} from "../../consts/queries";
import { dataURLtoBlob } from "../../utils/fileOperations";
import PageHead from "../Common/PageHead";
import {
  useLoader,
  useLoggedInUser,
  usePromisifiedQuery,
  useRequireLogin,
} from "../../utils/hooks";
import { t } from "@lingui/macro";

export default function CreateListing() {
  const [createListing] = useMutation(CREATE_LISTING);
  const [saveAdvertisementPreferences] = useMutation(
    SAVE_ADVERTISEMENT_PREFERENCES
  );

  const queryParams = new URLSearchParams(useLocation().search);
  const groupScope = queryParams.get("groupScope");
  const shopScope = queryParams.get("shopScope");
  const history = useHistory();
  const loggedInUser = useLoggedInUser();
  const dispatch = useDispatch();
  useRequireLogin();

  const goToListing = (listingId) => history.push(`/listing/${listingId}`);

  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
  const [initialShopId, setInitialShopId] = useState();
  const [addressIds, setAddressIds] = useState([]);
  const [contactNumberIds, setContactNumberIds] = useState([]);
  useLoader(formSubmitInProgress);

  const getUserContacts = usePromisifiedQuery(GET_USER_CONTACTS);
  const getAdvertisementPreferences = usePromisifiedQuery(
    GET_ADVERTISEMENT_PREFERENCES
  );

  useEffect(() => {
    const setUserAdvertisementPreferences = async () => {
      const { data: userContactData } = await getUserContacts({
        id: loggedInUser.id,
      });
      const userData = userContactData?.user;
      if (!userData) return;

      let initialAddressIds = [];
      let initialContactNumberIds = [];

      const { data: advertisementPreferencesData } =
        await getAdvertisementPreferences({ id: loggedInUser?.id });
      const preferencesData =
        advertisementPreferencesData?.user?.advertisementPreferences;
      if (preferencesData) {
        initialAddressIds = preferencesData.preferredAddressIds;
        initialContactNumberIds = preferencesData.preferredContactNumberIds;
        setInitialShopId(preferencesData.preferredShopId);
      }

      // assign defaults
      if (!initialAddressIds.length) {
        initialAddressIds = userData.addresses
          .filter((addr) => addr.isDefault)
          .map((addr) => addr.id);
      }
      if (!initialContactNumberIds.length) {
        initialContactNumberIds = userData.contactNumbers
          .filter((contact) => contact.isDefault)
          .map((contact) => contact.id);
      }

      setAddressIds(initialAddressIds);
      setContactNumberIds(initialContactNumberIds);
    };

    if (loggedInUser) {
      try {
        setUserAdvertisementPreferences();
      } catch (e) {}
    }
  }, [loggedInUser, getUserContacts, getAdvertisementPreferences]);

  const dispatchErrorMessage = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });

  const submitNewListing = (formData) => {
    return Promise.all(
      formData.books.map((book) => {
        return Promise.all(
          book.images.map((dataUrl) => {
            const formData = new FormData();
            const imageBlob = dataURLtoBlob(dataUrl);
            if (!imageBlob) return Promise.resolve();
            formData.append("file", imageBlob);
            return fetch(`${IMAGE_SERVER_URL}/upload/lg`, {
              method: "POST",
              body: formData,
              credentials: "include",
            }).then((response) => {
              if (response.status === 201) {
                return response.json();
              }
              return Promise.reject("failed to upload image");
            });
          })
        )
          .then((mediaContents) => mediaContents.map((media) => media.fileName))
          .then((images) => ({ ...book, images }));
      })
    )
      .then((books) => {
        return createListing({
          variables: {
            listing: {
              title: formData.listingTitle,
              description: formData.listingDescription,
              groupIds: formData.shareTargetGroups,
              originalPrice: +formData.originalPrice,
              price: +formData.price,
              priceModelType: formData.priceModelType,
              public: formData.public,
              addressIds: formData.listingAddressIds,
              contactNumberIds: formData.listingContactNumberIds,
              tags: formData.tags,
              shopId: formData.shopId,
              books,
              notes: formData.notes,
            },
          },
        });
      })
      .then(({ data }) => {
        if (!data?.createListing?.id) {
          dispatchErrorMessage(
            "Something went wrong while creating the listing. Please try again later!"
          );
        } else {
          if (formData.savePreferences) {
            saveAdvertisementPreferences({
              variables: {
                preferences: {
                  preferredShopId: formData.shopId,
                  preferredContactNumberIds: formData.listingContactNumberIds,
                  preferredAddressIds: formData.listingAddressIds,
                },
              },
            }).catch(() => {});
          }

          goToListing(data.createListing.id);
        }
      })
      .catch(() => {
        dispatchErrorMessage(
          "Something went wrong while creating the listing. Please try again later!"
        );
      });
  };

  const submitListing = (formData) => {
    setFormSubmitInProgress(true);
    return submitNewListing(formData).finally(() => {
      setFormSubmitInProgress(false);
    });
  };

  if (!loggedInUser) return null;
  return (
    <>
      <PageHead title="Share a book | bibliocircle" />
      <ListingEditor
        groupScope={groupScope}
        shopScope={shopScope}
        onSubmit={submitListing}
        editorTitle={t`Create Advertisement`}
        submitButtonText={t`Create Advertisement`}
        initialShopId={initialShopId}
        initialAddressIds={addressIds}
        initialContactNumberIds={contactNumberIds}
        disabled={formSubmitInProgress}
      />
    </>
  );
}
