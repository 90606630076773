import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  makeStyles,
  Avatar,
  Fade,
  Button,
} from "@material-ui/core";
import Hyperlink from "../Common/Hyperlink";
import { getUserFullName } from "../../utils/common";
import { capitalCase } from "change-case";
import CheckIcon from "@material-ui/icons/CheckCircle";
import { useDispatch } from "react-redux";
import { TOGGLE_FOLLOW_USER } from "../../consts/mutations";
import { useMutation } from "@apollo/client";
import { actionTypes } from "../index.reducer";
import { GENERIC_ALERT_ERROR_MESSAGE } from "../../config";
import { useLoggedInUser } from "../../utils/hooks";

const useStyles = makeStyles((theme) => {
  const userCardContentStyles = {
    backgroundColor: theme.palette.grey[200],
    border: "2px solid",
    borderColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    position: "relative",
    "&:hover": {
      backgroundColor: theme.palette.primary.lighter,
      borderColor: theme.palette.primary.light,
    },
  };
  return {
    userCard: {
      display: "flex",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(1.5),
      },
    },
    userCardContent: {
      ...userCardContentStyles,
    },
    avatarContainer: {
      height: "100%",
    },
    logo: {
      [theme.breakpoints.up("md")]: {
        width: "80px",
      },
      width: "60px",
      objectFit: "contain",
    },
    profilePictureAvatar: {
      width: 60,
      height: 60,
      [theme.breakpoints.up("md")]: {
        width: 80,
        height: 80,
      },
    },
    emptyUserText: {
      color: theme.palette.grey[400],
      fontWeight: "bold",
    },
    nonEmptyUserText: {
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
    userDescription: {
      marginBottom: theme.spacing(0.5),
    },
    followButton: {
      minWidth: 105,
    },
  };
});

export default function UserCard({ user }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loggedInUser = useLoggedInUser();
  const isOwnCard = loggedInUser && loggedInUser.id === user.id;

  const [toggleFollowUser, { loading: toggleFollowUserPending }] =
    useMutation(TOGGLE_FOLLOW_USER);

  const handleToggleFollowUser = (event) => {
    toggleFollowUser({
      variables: {
        targetUserId: user.id,
      },
    }).catch(() => {
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message: GENERIC_ALERT_ERROR_MESSAGE,
      });
    });
    event.preventDefault();
  };

  const trimDescription = (description) => {
    const desc = description || "";
    return desc.length <= 120 ? desc : desc.substring(0, 120) + "...";
  };

  return (
    <Hyperlink to={`/profile/${user.id}`} className={classes.userCard}>
      <Grid
        container
        justifyContent="flex-start"
        className={classes.userCardContent}
      >
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Grid container justifyContent="flex-start" spacing={1}>
                <Grid item xs={3} sm={2} md={3} xl={2}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    className={classes.avatarContainer}
                  >
                    {user.profilePicture ? (
                      <Fade in>
                        <Avatar
                          alt={user.name}
                          src={user.profilePicture}
                          className={classes.profilePictureAvatar}
                        />
                      </Fade>
                    ) : (
                      <Fade in>
                        <Avatar
                          className={classes.profilePictureAvatar}
                          src="/user-placeholder.svg"
                        />
                      </Fade>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={9}
                  sm={10}
                  md={9}
                  xl={10}
                  className={classes.userDetailsContainer}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6" color="textPrimary">
                        {getUserFullName(user)}
                      </Typography>
                    </Grid>
                    {!!user.description && (
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          className={classes.userDescription}
                        >
                          {trimDescription(user.description)}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography variant="caption" color="textSecondary">
                        {capitalCase(user.levels.bibliophileTitle)} &bull; Level{" "}
                        {user.levels.bibliophileLevel}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption" color="textSecondary">
                        {capitalCase(user.levels.criticTitle)} &bull; Level{" "}
                        {user.levels.criticLevel}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!isOwnCard && (
              <Grid item xs={12} sm={3}>
                <Grid container justifyContent="flex-end">
                  <Button
                    disabled={toggleFollowUserPending}
                    className={classes.followButton}
                    variant="contained"
                    size="small"
                    color={user.isFollowee ? "primary" : "default"}
                    disableElevation
                    disableRipple
                    onClick={handleToggleFollowUser}
                    endIcon={user.isFollowee ? <CheckIcon /> : null}
                  >
                    {user.isFollowee ? "Following" : "Follow"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Hyperlink>
  );
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
};
