import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    border: "none",
    backgroundColor: theme.palette.grey[200],
    width: "100%",
    padding: "0 !important",
    margin: 0,
  },
}));

export function StoryChapterCardLoader() {
  const classes = useStyles();
  return (
    <div className={`ph-item ${classes.item}`}>
      <div className="ph-row">
        <div className="ph-col-8 big"></div>
        <div className="ph-col-12"></div>
        <div className="ph-col-6"></div>
        <div className="ph-col-3"></div>
      </div>
    </div>
  );
}

export function StoryChaptersLoader({ xs, sm, md, lg, xl }) {
  return (
    <Grid spacing={1} container justifyContent="flex-start">
      <Grid item container xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <StoryChapterCardLoader />
      </Grid>
      <Grid item container xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <StoryChapterCardLoader />
      </Grid>
      <Grid item container xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <StoryChapterCardLoader />
      </Grid>
    </Grid>
  );
}
