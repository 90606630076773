import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import StoriesSearchController from "./StoriesSearchController";

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  heading: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
    fontSize: 32,
  },
  subheading: {
    fontSize: 18,
    color: theme.palette.text.secondary,
  },
}));

export default function BibliocircleStoryPicks() {
  const classes = useStyles();
  return (
    <Container maxWidth="xl">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" className={classes.header}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                align="center"
                className={classes.heading}
              >
                bibliocircle's story picks
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                align="center"
                className={classes.subheading}
              >
                Here are our favourite stories from various categories!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StoriesSearchController
            noResultsMessage="No stories"
            searchArgs={{ editorsPick: true, published: true }}
            xs={12}
            sm={6}
            lg={4}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
