import React from "react";
import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useScrollTop } from "../../utils/hooks";
import FeaturedStories from "./Story/FeaturedStories";
import StoryHomePageSection from "./Story/StoryHomePageSection";
import AllStories from "./Story/AllStories";
import RecommendedStoriesForUser from "./Story/RecommendedStoriesForUser";
import CategoryDiscoverer from "./Story/CategoryDiscoverer";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  featuredSectionTitle: {
    textAlign: "center",
    fontWeight: "bold",
  },
  highlightsSection: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  categoryDiscoverer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export default function StoriesHomePageContent() {
  const classes = useStyles();
  const history = useHistory();
  useScrollTop([history.location.key]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container className={classes.highlightsSection}>
              <Grid item xs={12} lg={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs>
                            <Divider />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="h4"
                              className={classes.featuredSectionTitle}
                            >
                              <Trans>Featured Stories</Trans>
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Divider />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FeaturedStories />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <RecommendedStoriesForUser />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.categoryDiscoverer}>
              <Grid item xs={12}>
                <CategoryDiscoverer />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <StoryHomePageSection
              title={t`bibliocircle's Picks`}
              searchArgs={{ editorsPick: true, published: true }}
              moreStoriesLinkText={t`See all bibliocircle's picks`}
              moreStoriesLinkPath="/stories/list/bibliocircle-picks"
              noResultsMessage={t`No bibliocircle's picks at the moment`}
            />
          </Grid>
          <Grid item xs={12}>
            <AllStories />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
