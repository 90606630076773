import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import NoResults from "../../Common/NoResultsImage";
import { STORIES_PAGINATION_LIMIT } from "../../../config";
import { useQuery } from "@apollo/client";
import { SEARCH_STORIES } from "../../../consts/queries";
import { MediumStoriesLoader } from "../../Common/LoadingPlaceholders/MediumStoriesLoader";
import { SmallStoriesLoader } from "../../Common/LoadingPlaceholders/SmallStoriesLoader";
import ErrorPage from "../../Error/ErrorPage";
import { useLoader } from "../../../utils/hooks";
import MediumStoryCard from "./MediumStoryCard";
import SmallStoryCard from "./SmallStoryCard";

const useStyles = makeStyles((theme) => ({
  noStoriesPlaceholder: {
    padding: theme.spacing(3),
  },
  errorBanner: {
    padding: theme.spacing(3),
  },
  noStoriesPlaceholderText: {
    fontWeight: "bold",
    textAlign: "center",
  },
}));

const cardComponents = {
  sm: SmallStoryCard,
  md: MediumStoryCard,
};
const loaderComponents = {
  sm: SmallStoriesLoader,
  md: MediumStoriesLoader,
};

export default function StoriesSearchController({
  cardSize,
  fetchPolicy,
  fixedResultsCount,
  searchArgs,
  noResultsMessage,
  xs,
  md,
  lg,
  xl,
}) {
  const classes = useStyles();
  const StoriesLoader = loaderComponents[cardSize] || MediumStoriesLoader;
  const StoryCard = cardComponents[cardSize] || MediumStoryCard;
  const fetchOptions = {
    variables: {
      ...searchArgs,
      pagination: {
        limit: fixedResultsCount || STORIES_PAGINATION_LIMIT,
        offset: 0,
      },
    },
  };

  if (fetchPolicy) fetchOptions.fetchPolicy = fetchPolicy;
  const {
    loading,
    error: fetchStoriesError,
    data,
    fetchMore,
  } = useQuery(SEARCH_STORIES, fetchOptions);
  useLoader(loading);

  const loadingPlaceholder = <StoriesLoader xs={xs} md={md} lg={lg} xl={xl} />;

  if (fetchStoriesError) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the stories due to a problem. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  if (loading && !data) {
    return (
      <Grid container justifyContent="center">
        {loadingPlaceholder}
      </Grid>
    );
  }

  const {
    searchStories: { stories, pagination },
  } = data;

  const loadMoreStories = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: STORIES_PAGINATION_LIMIT,
          offset: stories.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          searchStories: {
            __typename: prev.searchStories.__typename,
            stories: [
              ...prev.searchStories.stories,
              ...fetchMoreResult.searchStories.stories,
            ],
            pagination: fetchMoreResult.searchStories.pagination,
          },
        });
      },
    }).catch(() => {});
  };

  const noStoriesPlaceholder = (
    <Grid
      container
      justifyContent="center"
      className={classes.noStoriesPlaceholder}
    >
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="h5"
          color="primary"
          className={classes.noStoriesPlaceholderText}
        >
          {noResultsMessage ||
            "Sorry, we could not find the stories you are looking for!"}
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={6} lg={4}>
          <NoResults />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      {!stories.length ? noStoriesPlaceholder : null}
      <InfiniteScroll
        style={{ overflow: "none !important" }}
        next={!fixedResultsCount && loadMoreStories}
        hasMore={stories.length < pagination.totalCount}
        loader={
          !fixedResultsCount && (
            <StoriesLoader xs={xs} md={md} lg={lg} xl={xl} />
          )
        }
        dataLength={stories.length}
      >
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {stories.map((story) => (
              <Grid key={story.id} item xs={xs} md={md} lg={lg} xl={xl}>
                <StoryCard story={story} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </InfiniteScroll>
    </Grid>
  );
}

StoriesSearchController.propTypes = {
  searchArgs: PropTypes.object,
};

StoriesSearchController.defaultProps = {
  searchArgs: {},
  cardSize: "md",
};
