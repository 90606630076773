import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import MessagesSearchController from "./MessagesSearchController";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { TOGGLE_FOLLOW_MESSAGE_THREAD } from "../../consts/mutations";
import { useDispatch } from "react-redux";
import { actionTypes } from "../index.reducer";
import FollowIcon from "@material-ui/icons/Notifications";
import {
  CHAT_PAGINATION_LIMIT,
  GENERIC_ALERT_ERROR_MESSAGE,
  MESSAGES_PAGINATION_LIMIT,
} from "../../config";
import { GET_MESSAGES, GET_MESSAGE_THREAD } from "../../consts/queries";
import Banner from "../Common/Banner";
import Hyperlink from "../Common/Hyperlink";
import { useHistory } from "react-router-dom";
import TextEditor from "../Common/TextEditor";
import { useLoggedInUser } from "../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  toggleFollowThreadButton: {
    minWidth: 146,
  },
  loginLink: {
    color: theme.palette.primary.main,
  },
  messageText: {
    lineHeight: 1.5,
    borderWidth: "1px",
    borderColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    width: "100%",
    padding: theme.spacing(2),
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
    outline: "none",
    maxWidth: "100%",
    minWidth: "100%",
    minHeight: theme.spacing(8),
  },
}));

export default function MessageThread({
  threadId,
  inputPlaceholder,
  sendButtonText,
  sendButtonJustify,
  heading,
  variant,
  noMessagesPlaceholder,
  onSendMessage,
  disabled,
  closed,
  threadClosureTitle,
  threadClosureMessage,
  inputRowsCount,
}) {
  const classes = useStyles();
  const history = useHistory();
  const loggedInUser = useLoggedInUser();
  const isChatVariant = variant === "chat";
  const dispatch = useDispatch();
  const { loading: fetchingMessageThreadInfo, data: messageThreadInfo } =
    useQuery(GET_MESSAGE_THREAD, { variables: { id: threadId } });
  const [toggleFollowThread, { loading: toggleFollowingThread }] = useMutation(
    TOGGLE_FOLLOW_MESSAGE_THREAD
  );
  const [refreshMessageThread] = useLazyQuery(GET_MESSAGES, {
    fetchPolicy: "cache-and-network",
  });
  const [draftMessage, setDraftMessage] = useState("");
  const [sendMessageInProgress, setSendMessageInProgress] = useState(false);
  const isThreadFollowed = messageThreadInfo?.messageThread?.isFollowed;

  const onChangeDraftMessage = (event) => setDraftMessage(event.target.value);
  const dispatchErrorMessage = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });

  const onClickSendMessage = () => {
    if (!draftMessage) return;
    setSendMessageInProgress(true);
    const paginationLimit = isChatVariant
      ? CHAT_PAGINATION_LIMIT
      : MESSAGES_PAGINATION_LIMIT;
    Promise.resolve()
      .then(() => onSendMessage(draftMessage))
      .then(() => {
        refreshMessageThread({
          variables: {
            threadId,
            inverse: isChatVariant,
            pagination: {
              limit: paginationLimit,
              offset: 0,
            },
          },
        });
        setDraftMessage("");
      })
      .catch(() => {
        dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
      })
      .finally(() => setSendMessageInProgress(false));
  };

  const onClickToggleFollowThread = () => {
    toggleFollowThread({
      variables: {
        id: threadId,
      },
    }).catch(() => {
      dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
    });
  };

  const threadHeading = (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h6">{heading}</Typography>
      </Grid>
      {loggedInUser && (
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant={isThreadFollowed ? "contained" : "outlined"}
                color="primary"
                size="small"
                disableElevation
                disabled={toggleFollowingThread || fetchingMessageThreadInfo}
                startIcon={<FollowIcon />}
                onClick={onClickToggleFollowThread}
                className={classes.toggleFollowThreadButton}
              >
                {isThreadFollowed ? "Unfollow Thread" : "Follow Thread"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );

  const messageEditor = (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextEditor
          rowsMin={inputRowsCount}
          value={draftMessage}
          placeholder={inputPlaceholder}
          onChange={onChangeDraftMessage}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent={sendButtonJustify}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={sendMessageInProgress || disabled}
              onClick={onClickSendMessage}
            >
              {sendButtonText}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const currentPath = `${history.location.pathname}${history.location.search}`;

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {threadHeading}
          </Grid>
          <Grid item xs={12}>
            <MessagesSearchController
              searchArgs={{ threadId }}
              xs={12}
              variant={variant}
              noMessagesPlaceholder={noMessagesPlaceholder}
            />
          </Grid>
          <Grid item xs={12}>
            {closed && (
              <Banner
                title={threadClosureTitle}
                message={threadClosureMessage}
              />
            )}
            {!closed && loggedInUser && messageEditor}
            {!closed && !loggedInUser && (
              <Banner
                type="basic"
                message={
                  <Typography variant="subtitle2">
                    Please{" "}
                    <Hyperlink
                      to={`/login?redirectPath=${encodeURIComponent(
                        currentPath
                      )}`}
                      className={classes.loginLink}
                    >
                      log in
                    </Hyperlink>{" "}
                    to reply.
                  </Typography>
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

MessageThread.propTypes = {
  threadId: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string,
  sendButtonText: PropTypes.string,
  sendButtonJustify: PropTypes.string,
  threadClosureTitle: PropTypes.string,
  threadClosureMessage: PropTypes.string,
  heading: PropTypes.string,
  onSendMessage: PropTypes.func,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  closed: PropTypes.bool,
  inputRowsCount: PropTypes.number,
};

MessageThread.defaultProps = {
  sendButtonText: "Send Message",
  sendButtonJustify: "center",
  disabled: false,
  closed: false,
  heading: "Messages",
  onSendMessage: () => Promise.resolve(),
  inputRowsCount: 8,
};
