import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { t } from "@lingui/macro";
import CritiquesSearchController from "../../Critique/CritiquesSearchController";

export default function UserCritiques({ userId, published, searchQuery }) {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10} lg={8} xl={12}>
        <CritiquesSearchController
          searchArgs={{
            query: searchQuery,
            criticId: userId,
            published: !!published,
          }}
          noResultsMessage={t`No Critiques Found!`}
          xs={12}
          xl={6}
        />
      </Grid>
    </Grid>
  );
}

UserCritiques.propTypes = {
  userId: PropTypes.string.isRequired,
  searchQuery: PropTypes.string,
};
