import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import UserAvatar from "../Common/UserAvatar";
import dayjs from "dayjs";
import Linkify from "./Linkify";
import ReportIcon from "@material-ui/icons/Flag";
import DeleteIcon from "@material-ui/icons/Delete";
import Reporting from "../Common/Reporting";
import ConfirmPrompt from "../Common/ConfirmPrompt";

const useStyles = makeStyles((theme) => {
  const chatMessageCardBase = {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  };
  const defaultMessageCard = {
    ...chatMessageCardBase,
    borderRadius: theme.spacing(2),
  };
  return {
    messageCard_default: {
      ...defaultMessageCard,
      backgroundColor: theme.palette.grey[200],
    },
    ownMessageCard_default: {
      ...defaultMessageCard,
      backgroundColor: theme.palette.grey[200],
    },
    messageCard_chat: {
      ...chatMessageCardBase,
      backgroundColor: theme.palette.grey[100],
    },
    ownMessageCard_chat: {
      ...chatMessageCardBase,
      backgroundColor: theme.palette.primary.extraLighter,
    },
    messageTimestamp: {
      fontSize: 12,
      color: theme.palette.grey[500],
    },
    messageTextContainer: {
      whiteSpace: "pre-wrap",
      wordWrap: "break-all",
    },
    reportButtonContainer: {
      justifyContent: "flex-end",
    },
    deleteButtonContainer: {
      justifyContent: "flex-end",
    },
  };
});

export default function MessageCard({
  message,
  ownMessage,
  variant,
  onDelete,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [reporterOpen, setReporterOpen] = useState(false);
  const [awaitingDeletionDecision, setAwaitingDeletionDecision] =
    useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);
  const variantSuffix = variant === "chat" ? "chat" : "default";

  const toggleOpenReporter = () => setReporterOpen((isOpen) => !isOpen);

  const handleDeleteDiscussion = (confirmed) => {
    setActionInProgress(true);
    setAwaitingDeletionDecision(false);
    if (confirmed && onDelete) {
      Promise.resolve()
        .then(() => onDelete(message))
        .finally(() => {
          setActionInProgress(false);
        });
    } else {
      setActionInProgress(false);
    }
  };

  const onClickDelete = () => {
    setAwaitingDeletionDecision(true);
  };

  return (
    <Grid
      container
      className={
        ownMessage
          ? `${classes[`ownMessageCard_${variantSuffix}`]}`
          : `${classes[`messageCard_${variantSuffix}`]}`
      }
    >
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              className={classes.messageTextContainer}
            >
              <Linkify>{message.message}</Linkify>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <Grid item xs={6}>
                <UserAvatar
                  variant={isMobile ? "minimal" : "classic"}
                  user={message.user}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      {!ownMessage && (
                        <Grid item xs={12}>
                          <Reporting
                            type="MESSAGE"
                            resourceId={message.id}
                            open={reporterOpen}
                            onClose={toggleOpenReporter}
                          />
                          <Grid
                            container
                            className={classes.reportButtonContainer}
                          >
                            <Tooltip title="Is this inappropriate on bibliocircle? Let us know.">
                              <Button
                                variant="text"
                                color="secondary"
                                size="small"
                                startIcon={<ReportIcon />}
                                onClick={toggleOpenReporter}
                              >
                                Report
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      )}
                      {ownMessage && (
                        <Grid item xs={12}>
                          <ConfirmPrompt
                            open={awaitingDeletionDecision}
                            onAction={handleDeleteDiscussion}
                            question="Are you sure you want to delete this message. This action cannot be undone!"
                          />
                          <Grid
                            container
                            className={classes.deleteButtonContainer}
                          >
                            <Button
                              variant="text"
                              size="small"
                              startIcon={<DeleteIcon />}
                              onClick={onClickDelete}
                              disabled={actionInProgress}
                            >
                              Delete
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      align="right"
                      className={classes.messageTimestamp}
                    >
                      {dayjs(message.createdAt).format(
                        isMobile ? "MMMM D, YYYY" : "MMMM D, YYYY [at] h:mm A"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

MessageCard.propTypes = {
  message: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  ownMessage: PropTypes.bool,
  variant: PropTypes.string,
};

MessageCard.defaultProps = {
  message: {},
};
