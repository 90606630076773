import { t } from "@lingui/macro";
import { PLACEHOLDER_IMAGES } from "../../consts";
import { createFeedPostDataExtractor } from "./transformerUtils";

export default function UserCommentedDiscussionTransformer(feedPost) {
  const primaryDataExtractor = createFeedPostDataExtractor(
    feedPost.primaryContent?.data
  );
  const secondaryDataExtractor = createFeedPostDataExtractor(
    feedPost.secondaryContent?.data
  );
  const discussionId = secondaryDataExtractor.get("discussionId");
  return {
    actorFullName: feedPost.actor.name,
    actorImage: feedPost.actor.image || PLACEHOLDER_IMAGES.USER,
    actorPath: `/profile/${feedPost.actor.id}`,
    activityText: t`replied in a discussion`,
    resourcePath: discussionId ? `/bookchat/${discussionId}` : "",
    primaryContent: {
      title: null,
      content: primaryDataExtractor.get("messageContent"),
      images: feedPost.primaryContent?.images || [],
    },
    secondaryContent: {
      title: secondaryDataExtractor.get("discussionTitle"),
      content: secondaryDataExtractor.get("discussionContent"),
      images: feedPost.secondaryContent?.images || [],
    },
    secondaryContentReason: "Discussion",
    timestamp: feedPost.activityTimestamp,
  };
}
