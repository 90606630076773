import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { BC_AUTH_REDIRECT_COOKIE_NAME } from "../config";

export default function OAuthRedirect() {
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies([
    BC_AUTH_REDIRECT_COOKIE_NAME,
  ]);
  const redirectPath = cookies[BC_AUTH_REDIRECT_COOKIE_NAME];
  if (redirectPath) {
    history.push(redirectPath);
  } else {
    history.push("/");
  }
  setTimeout(() => {
    removeCookie(BC_AUTH_REDIRECT_COOKIE_NAME, {
      path: "/",
      domain: window.location.hostname,
    });
  });
  return null;
}
