import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  InputAdornment,
  IconButton,
  Paper,
  InputLabel,
  Button,
  Container,
} from "@material-ui/core";
import PageTitle from "../Common/PageTitle";
import { useHistory, useLocation } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import ShopIcon from "@material-ui/icons/Store";
import BootstrapInput from "../Common/BootstrapInput";
import ShopsSearchController from "./ShopsSearchController";
import FormControlField from "../Common/FormControlField";
import { locations } from "../consts";
import DropDown from "../Common/DropDown";
import PageHead from "../Common/PageHead";
import Hyperlink from "../Common/Hyperlink";
import { useLoginPrompt, useScrollTop } from "../../utils/hooks";
import ImageFadeIn from "../Common/ImageFadeIn";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  shopDiscoverer: {
    marginTop: theme.spacing(3),
  },
  logo: {
    maxHeight: theme.spacing(6) * 2,
  },
  pageTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: "center",
  },
  shopSearchBox: {
    width: "100%",
  },
  shopSearchBoxInput: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    "&::placeholder": {
      color: theme.palette.primary.main,
    },
  },
  shopSearchContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const shopLocations = [
  {
    default: true,
    label: "All Sri Lanka",
    value: 0,
  },
  ...locations,
];

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export default function DiscoverShops() {
  const classes = useStyles();
  const history = useHistory();
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const queryParams = useQueryParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [shopLocation, setShopLocation] = useState(0);
  useScrollTop([history.location.key]);

  const onKeyDown = (event) => {
    if (event.keyCode === 13) searchShops();
  };

  const searchShops = () => {
    const queryParams = {};
    if (searchQuery) queryParams.q = searchQuery;
    if (shopLocation) queryParams.location = shopLocation;
    const queryString = new URLSearchParams(queryParams).toString();
    history.push(`/shops?${queryString}`);
  };
  const onChangeSearchQuery = (event) => setSearchQuery(event.target.value);
  const onChangeShopLocation = (event) => setShopLocation(event.target.value);
  const onClickCreateShop = createLoginPromptEventHandler({
    redirectPath: "/shop/create",
  });

  const shopsSearchBar = (
    <Grid
      container
      justifyContent="center"
      className={classes.shopSearchContainer}
    >
      <Grid item xs={12}>
        <Grid container alignItems="flex-end" spacing={2}>
          <Grid item xs={12} sm={8} md={9}>
            <Paper>
              <BootstrapInput
                name="q"
                className={classes.shopSearchBox}
                defaultValue={queryParams.get("q")}
                placeholder={t`Search shops`}
                bordered
                onChange={onChangeSearchQuery}
                onKeyDown={onKeyDown}
                endAdornment={
                  <InputAdornment>
                    <IconButton
                      color="primary"
                      variant="contained"
                      onClick={searchShops}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              ></BootstrapInput>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <FormControlField>
              <InputLabel shrink color="primary" htmlFor="bootstrap-input">
                <Trans>Location</Trans>
              </InputLabel>
              <DropDown
                value={shopLocation}
                onChange={onChangeShopLocation}
                options={shopLocations}
                label="All Sri Lanka"
                defaultValue={queryParams.get("location")}
                bordered={true}
              />
            </FormControlField>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Container maxWidth="xl">
      <PageHead title="Discover Shops | bibliocircle" />
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        className={classes.shopDiscoverer}
      >
        <Grid container item xs={11} lg={10}>
          <Grid item xs={12} className={classes.pageTitle}>
            <Grid container alignItems="center" direction="column" spacing={2}>
              <Grid item>
                <Grid container justifyContent="center">
                  <ImageFadeIn
                    src="/logo/logo_shops.svg"
                    alt="Discover Shops"
                    className={classes.logo}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <PageTitle variant="subtitle1" align="center">
                  <Trans>
                    A hassle-free way of setting up your own Book Shop online.
                  </Trans>
                </PageTitle>
              </Grid>
              <Grid item>
                <Button
                  onClick={onClickCreateShop}
                  component={Hyperlink}
                  to="/shop/create"
                  startIcon={<ShopIcon />}
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  <Trans>Create Shop</Trans>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            {shopsSearchBar}
          </Grid>
          <Grid container item xs={12}>
            <ShopsSearchController
              key={history.location.key}
              xs={12}
              md={6}
              lg={6}
              xl={6}
              searchArgs={{
                query: queryParams.get("q"),
                location: queryParams.get("location"),
              }}
              noResultsMessage={t`No shops found!`}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
