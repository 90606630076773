import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginPage from "./Auth/Login";
import ResetPasswordInitiatePage from "./Auth/ResetPasswordInitiate";
import ResetPasswordConfirmationPage from "./Auth/ResetPasswordConfirmation";
import SignUpPage from "./Auth/SignUp";
import Application from "./Application";
import { useDispatch, useSelector } from "react-redux";
import { AUTH_REFRESH } from "./Auth/auth.actionTypes";
import {
  ALERT_AUTOHIDE_DURATION,
  BACKEND_URL,
  BC_AUTH_COOKIE_NAME,
} from "./config";
import SignUpConfirmation from "./Auth/SignUpConfirmation";
import EmailVerification from "./Auth/EmailVerification";
import ResetPassword from "./Auth/ResetPassword";
import OAuthRedirect from "./Auth/OAuthRedirect";
import { useCookies } from "react-cookie";
import "./App.css";
import { actionTypes } from "./Application/index.reducer";
import Alert from "./Application/Common/Alert";
import ErrorPage from "./Application/Error/ErrorPage";
import LoginPrompt from "./LoginPrompt";
import { setupMobileMessageBridge, userLoggedIn } from "./mobile/events";

setupMobileMessageBridge((message) => {
  (async () => {
    try {
      const { type, data } = message.data;
      if (type === "GOOGLE_LOGIN_SUCCESS") {
        const { accessToken } = data;
        const res = await fetch(
          `${BACKEND_URL}/auth/google/mobile/authenticate`,
          {
            method: "post",
            body: JSON.stringify({ accessToken }),
            credentials: "include",
            headers: { "Content-Type": "application/json" },
          }
        );

        if (res.status === 200) {
          const { id } = await res.json();
          userLoggedIn({ userId: id });
          // redirect user to the homepage
          window.location.href = window.location.origin;
        } else {
          // TODO: make this a better error
          alert("Login Failed!");
        }
      }
    } catch (err) {}
  })();
});

function App() {
  const dispatch = useDispatch();
  const application = useSelector((state) => state.application);
  const [cookies] = useCookies([BC_AUTH_COOKIE_NAME]);
  const token = cookies[BC_AUTH_COOKIE_NAME];

  useEffect(() => {
    dispatch({
      type: AUTH_REFRESH,
      token,
    });
  }, [token, dispatch]);

  const dismissMessage = (id) => () => {
    dispatch({
      type: actionTypes.DISMISS_MESSAGE,
      messageId: id,
    });
  };

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/signup">
            <SignUpPage />
          </Route>
          <Route path="/signup/confirm">
            <SignUpConfirmation />
          </Route>
          <Route exact path="/resetpassword">
            <ResetPasswordInitiatePage />
          </Route>
          <Route exact path="/resetpassword/:resetPasswordToken/validate">
            <ResetPassword />
          </Route>
          <Route exact path="/resetpassword/confirm">
            <ResetPasswordConfirmationPage />
          </Route>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/email/verify/:verificationId">
            <EmailVerification />
          </Route>
          <Route path="/auth/failure/redirect">
            <ErrorPage
              title="Unauthorised"
              description="Authentication Failed!"
              statusCode={403}
              showNavigationButton
              navigationButtonText="Go To Home Page"
              navigationButtonUrl="/"
            />
          </Route>
          <Route path="/auth/success/redirect">
            <OAuthRedirect />
          </Route>
          <Route path="/">
            <Application />
          </Route>
        </Switch>

        <LoginPrompt />
      </Router>
      {application.alerts.map((alert) => (
        <Alert
          open={true}
          type={alert?.type}
          message={alert?.message}
          autoHideDuration={alert.autoHideDuration || ALERT_AUTOHIDE_DURATION}
          key={alert.id}
          onClose={dismissMessage(alert.id)}
        />
      ))}
    </>
  );
}

export default App;
