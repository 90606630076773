import React, { useEffect, useRef } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../Application/index.reducer";
import { STORY_GENRES } from "../Application/consts";
import { GET_STORY_READING_PREFERENCES } from "../consts/queries";
import { useCookies } from "react-cookie";
import Cookies from "universal-cookie";
import { BC_CART_COOKIE_NAME } from "../config";
import { t } from "@lingui/macro";

export function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export function useLoggedInUser() {
  return useSelector((state) => state.user);
}

export function usePromisifiedQuery(query) {
  const client = useApolloClient();
  return React.useCallback(
    (variables) =>
      client.query({
        query: query,
        variables: variables,
        fetchPolicy: "network-only",
      }),
    [client] // eslint-disable-line react-hooks/exhaustive-deps
  );
}

export function usePrompt(message, shouldBlock) {
  const history = useHistory();
  const unblock = useRef(null);

  useEffect(() => {
    if (shouldBlock) {
      unblock.current = history.block(message);
    } else {
      unblock.current = null;
    }
    return () => {
      if (unblock.current) {
        unblock.current();
      }
    };
  }, [shouldBlock, history, message]);
}

export function useScrollTop(dependencies = []) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, dependencies || []);
}

export function useLoader(loading) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: actionTypes.LOADING,
      status: loading,
    });

    return () => {
      dispatch({
        type: actionTypes.LOADING,
        status: false,
      });
    };
  }, [dispatch, loading]);
}

export function useCurrentPath() {
  const history = useHistory();
  const { pathname, search, hash } = history.location;
  return `${pathname}${search}${hash}`;
}

export function useRequireLogin() {
  const loggedInUser = useLoggedInUser();
  const history = useHistory();

  useEffect(() => {
    const { pathname, search, hash } = history.location;
    if (!loggedInUser)
      history.replace("/login", {
        redirectPath: `${pathname}${search}${hash}`,
      });
  }, [history, loggedInUser]);
}

export function useDispatchErrorMessage() {
  const dispatch = useDispatch();
  return (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });
}

export function useDispatchInfoMessage() {
  const dispatch = useDispatch();
  return (message) =>
    dispatch({
      type: actionTypes.INFO_MESSAGE,
      message,
    });
}

export function useLoginPrompt() {
  const dispatch = useDispatch();
  const loggedInUser = useLoggedInUser();
  const application = useSelector((state) => state.application);

  const promptLogin = ({ redirectPath } = {}) =>
    dispatch({
      type: actionTypes.PROMPT_LOGIN,
      redirectPath,
    });

  const dismissLoginPrompt = () =>
    dispatch({
      type: actionTypes.DISMISS_PROMPT_LOGIN,
    });

  const createLoginPromptEventHandler = ({ redirectPath, callback } = {}) => (
    event
  ) => {
    if (!loggedInUser) {
      promptLogin({ redirectPath });
      event && event.preventDefault();
    } else {
      callback && callback(event);
    }
  };

  return {
    isPrompted: application.loginRequired,
    onLoginRedirectPath: application.onLoginRedirectPath,
    promptLogin,
    createLoginPromptEventHandler,
    dismissLoginPrompt,
  };
}

export function useStoryReadingPreferences({ skip } = {}) {
  const loggedInUser = useLoggedInUser();
  let genres;
  const { loading, error, data: readingPreferences } = useQuery(
    GET_STORY_READING_PREFERENCES,
    {
      variables: {
        id: loggedInUser?.id,
      },
      fetchPolicy: "cache-first",
      skip: skip || !loggedInUser?.id,
    }
  );

  if (!readingPreferences?.user) {
    genres = null;
  } else {
    const { preferredStoryGenres } = readingPreferences.user;
    genres = preferredStoryGenres?.length
      ? preferredStoryGenres
          .map((genre) => STORY_GENRES[genre])
          .filter((genreObj) => !!genreObj)
      : [];
  }

  return { loading, error, genres };
}

export function useDisableCopyPaste() {
  const copyEventHandler = (event) => {
    event.clipboardData.setData(
      "text/plain",
      "Copying story content on bibliocircle is not allowed!"
    );

    event.preventDefault();
  };

  useEffect(() => {
    document.addEventListener("copy", copyEventHandler, false);
    return () => {
      document.removeEventListener("copy", copyEventHandler);
    };
  }, []);
}

export function useAddToCart() {
  const dispatch = useDispatch();
  const [, setCookie] = useCookies([BC_CART_COOKIE_NAME]);
  return (listing) => {
    const cookies = new Cookies();
    const currentItem = {
      listingId: listing.id,
      price: listing.price,
      quantity: 1,
    };
    const shoppingCart = cookies.get(BC_CART_COOKIE_NAME);
    if (Array.isArray(shoppingCart)) {
      const isItemInCart = shoppingCart.some(
        (item) => item.listingId === listing.id
      );
      if (isItemInCart) {
        setCookie(
          BC_CART_COOKIE_NAME,
          shoppingCart.map((item) => {
            if (item.listingId === listing.id) item.quantity += 1;
            return item;
          }),
          { path: "/" }
        );
      } else {
        setCookie(BC_CART_COOKIE_NAME, [...shoppingCart, currentItem], {
          path: "/",
        });
      }
    } else {
      setCookie(BC_CART_COOKIE_NAME, [currentItem], { path: "/" });
    }
    dispatch({
      type: actionTypes.INFO_MESSAGE,
      message: t`Added to cart!`,
    });
  };
}
