import Joi from "@hapi/joi";

export default Joi.object({
  firstName: Joi.string().required().trim(),
  lastName: Joi.string().required().trim(),
  email: Joi.string()
    .trim()
    .lowercase()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string().required(),
  confirmedPassword: Joi.ref("password"),
});
