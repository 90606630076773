import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import PublicIcon from "@material-ui/icons/Public";
import PrivateIcon from "@material-ui/icons/Lock";
import GroupIcon from "@material-ui/icons/Group";
import BookIcon from "@material-ui/icons/MenuBook";
import UsersSearchController from "../Profile/UsersSearchController";

const useStyles = makeStyles((theme) => ({
  groupAttrIcon: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  },
  mobileOnly: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  desktopOnly: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  badge: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(2),
    },
    paddingRight: theme.spacing(1),
  },
  membersBadge: {
    cursor: "pointer",
  },
}));

export default function GroupMetaBadge({ group, color }) {
  const { visibility, membersCount, listingsCount, id: groupId } = group;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [usersListOpen, setUsersListOpen] = useState(false);
  const publicGroupBadge = (
    <Grid container justifyContent="center" alignItems="center">
      <PublicIcon color={color} className={classes.groupAttrIcon} />
      <Typography color={color} variant="body2" className={classes.desktopOnly}>
        Public Group
      </Typography>
    </Grid>
  );

  const toggleUsersList = () => setUsersListOpen((isOpen) => !isOpen);

  const privateGroupBadge = (
    <Grid container justifyContent="center" alignItems="center">
      <PrivateIcon color={color} className={classes.groupAttrIcon} />
      <Typography color={color} variant="body2" className={classes.desktopOnly}>
        Private Group
      </Typography>
    </Grid>
  );

  const membersBadge = (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.membersBadge}
    >
      <GroupIcon color={color} className={classes.groupAttrIcon} />
      <Typography variant="body2" color={color}>
        {membersCount}
        <span className={classes.desktopOnly}>
          {" "}
          Member{membersCount === 1 ? "" : "s"}
        </span>
      </Typography>
    </Grid>
  );

  const listingsBadge = (
    <Grid container justifyContent="center" alignItems="center">
      <BookIcon color={color} className={classes.groupAttrIcon} />
      <Typography variant="body2" color={color}>
        {listingsCount}
        <span className={classes.desktopOnly}>
          {" "}
          Book{listingsCount === 1 ? "" : "s"}
        </span>
      </Typography>
    </Grid>
  );

  return (
    <Grid container alignItems="center">
      <Grid item className={classes.badge}>
        {visibility === "PUBLIC" ? publicGroupBadge : privateGroupBadge}
      </Grid>
      <Grid item className={classes.badge} onClick={toggleUsersList}>
        {membersBadge}
      </Grid>
      {typeof listingsCount === "number" && (
        <Grid item className={classes.badge}>
          {listingsBadge}
        </Grid>
      )}
      <Dialog
        open={usersListOpen}
        onClose={toggleUsersList}
        disableBackdropClick={true}
        maxWidth="xs"
        disableScrollLock={true}
        fullScreen={isSmallScreen}
        fullWidth
      >
        <DialogContent id="membersListContainer">
          <UsersSearchController
            scrollableTargetId="membersListContainer"
            searchArgs={{ groupScope: groupId }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleUsersList}>close</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

GroupMetaBadge.propTypes = {
  group: PropTypes.object,
  visibility: PropTypes.oneOf(["PUBLIC", "PRIVATE"]).isRequired,
  membersCount: PropTypes.number,
  listingsCount: PropTypes.number,
  color: PropTypes.string,
};

GroupMetaBadge.defaultProps = {
  membersCount: 0,
  color: "primary",
};
