import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Avatar,
  makeStyles,
  useTheme,
  Fade,
  Link,
} from "@material-ui/core";
import Hyperlink from "../Common/Hyperlink";
import AnnounceLoader from "../Common/AnnounceLoader";
import NotificationIcon from "@material-ui/icons/Notifications";
import BookIcon from "@material-ui/icons/Book";
import GroupIcon from "@material-ui/icons/Group";
import ShopIcon from "@material-ui/icons/Store";
import NewIcon from "@material-ui/icons/NewReleases";
import UserIcon from "@material-ui/icons/Person";
import CakeIcon from "@material-ui/icons/Cake";
import OrderIcon from "@material-ui/icons/LocalMall";
import FollowIcon from "@material-ui/icons/SupervisedUserCircle";
import BookRequestIcon from "../Requests/BookRequestIcon";

import {
  green,
  blue,
  red,
  orange,
  yellow,
  grey,
  purple,
  deepPurple,
  pink,
} from "@material-ui/core/colors";
import { getNotificationTargetUrl } from "./notificationParser";
import CritiqueIcon from "../Critique/CritiqueIcon";
import BookChatIcon from "../Discussions/BookChatIcon";
import CollectionsIcon from "../ListingCollections/CollectionsIcon";
import ChatIcon from "../Chat/ChatIcon";
import dayjs from "dayjs";
import StoryIcon from "../Stories/StoryIcon";
import { isAbsoluteUrl } from "../../utils/common";

const useStyles = makeStyles((theme) => {
  const baseCardClass = {
    padding: theme.spacing(1.5),
    paddingLeft: 0,
    cursor: "pointer",
  };
  return {
    unreadNotification: {
      ...baseCardClass,
      backgroundColor: theme.palette.primary.extraLighter,
      "&:hover": {
        backgroundColor: theme.palette.primary.lighter,
      },
      "&:active": {
        backgroundColor: theme.palette.primary.light,
      },
    },
    readNotification: {
      ...baseCardClass,
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },
      "&:active": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    markReadButton: {
      color: theme.palette.primary.lighter,
      "&:hover": {
        color: theme.palette.primary.light,
      },
    },
    timestamp: {
      color: theme.palette.grey[500],
      fontSize: 10,
      textAlign: "right",
    },
  };
});

export default function NotificationCard({ notification, onClick }) {
  const classes = useStyles();
  const theme = useTheme();
  const notificationIcons = {
    GENERIC_NOTIFICATION: <NotificationIcon style={{ color: green[700] }} />,
    BOOK_NOTIFICATION: <BookIcon style={{ color: blue[700] }} />,
    GROUP_NOTIFICATION: <GroupIcon style={{ color: red[700] }} />,
    SHOP_NOTIFICATION: <ShopIcon style={{ color: orange[700] }} />,
    BOOK_REQUEST_NOTIFICATION: <NewIcon style={{ color: yellow[800] }} />,
    USER_NOTIFICATION: <UserIcon style={{ color: deepPurple[700] }} />,
    WELCOME_NOTIFICATION: <CakeIcon style={{ color: deepPurple[600] }} />,
    USER_FOLLOWER_LISTING_NOTIFICATION: (
      <BookIcon style={{ color: green[600] }} />
    ),
    SHOP_FOLLOWER_LISTING_NOTIFICATION: (
      <BookIcon style={{ color: green[600] }} />
    ),
    GROUP_FOLLOWER_LISTING_NOTIFICATION: (
      <BookIcon style={{ color: green[600] }} />
    ),
    USER_FOLLOWER_BOOK_REQUEST_NOTIFICATION: (
      <BookRequestIcon style={{ color: purple[500] }} />
    ),
    GROUP_FOLLOWER_BOOK_REQUEST_NOTIFICATION: (
      <BookRequestIcon style={{ color: purple[500] }} />
    ),
    NEW_ORDER_NOTIFICATION: <OrderIcon style={{ color: green[600] }} />,
    ORDER_CANCELLED_NOTIFICATION: <OrderIcon style={{ color: red[800] }} />,
    ORDER_STATUS_CHANGE_NOTIFICATION: (
      <OrderIcon style={{ color: theme.palette.primary.main }} />
    ),
    NEW_ORDER_MESSAGE_NOTIFICATION: (
      <BookChatIcon style={{ color: orange[700] }} />
    ),
    NEW_DISCUSSION_REPLY_NOTIFICATION: (
      <BookChatIcon style={{ color: orange[700] }} />
    ),
    NEW_DISCUSSION_NOTIFICATION: (
      <BookChatIcon style={{ color: orange[700] }} />
    ),
    NEW_CHAT_MESSAGE_NOTIFICATION: (
      <ChatIcon style={{ color: theme.palette.primary.main }} />
    ),
    NEW_LISTING_COLLECTION_NOTIFICATION: (
      <CollectionsIcon style={{ color: pink[800] }} />
    ),
    NEW_CRITIQUE_NOTIFICATION: <CritiqueIcon style={{ color: red[800] }} />,
    NEW_FOLLOWER_NOTIFICATION: <FollowIcon style={{ color: green[800] }} />,
    NEW_STORY_NOTIFICATION: <StoryIcon style={{ color: blue[600] }} />,
    NEW_STORY_REVIEW_NOTIFICATION: <StoryIcon style={{ color: blue[600] }} />,
    NEW_STORY_CHAPTER_NOTIFICATION: <StoryIcon style={{ color: blue[600] }} />,
    NEW_STORY_CHAPTER_COMMENT_NOTIFICATION: (
      <StoryIcon style={{ color: blue[600] }} />
    ),
    GLOBAL_ANNOUNCEMENT_NOTIFICATION: <AnnounceLoader color={purple[500]} />,
  };
  const cardClass = notification.read
    ? classes.readNotification
    : classes.unreadNotification;

  const notificationTarget = getNotificationTargetUrl(notification);
  const isNotificationAbsoluteUrl = isAbsoluteUrl(notificationTarget);

  const notificationCard = (
    <Grid container className={cardClass}>
      <Grid item xs={2}>
        <Grid container alignItems="flex-start" justifyContent="center">
          <Fade in>
            <Avatar style={{ backgroundColor: grey[300] }}>
              {notificationIcons[notification.type] ||
                notificationIcons.GENERIC_NOTIFICATION}
            </Avatar>
          </Fade>
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textPrimary">
              {notification.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" color="textSecondary">
              {notification.message}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.timestamp}>
              {dayjs(notification.createdAt).format("MMMM D, YYYY [at] HH:mm")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
  return isNotificationAbsoluteUrl ? (
    <Link underline="none" href={notificationTarget} onClick={onClick}>
      {notificationCard}
    </Link>
  ) : (
    <Hyperlink to={notificationTarget} onClick={onClick}>
      {notificationCard}
    </Hyperlink>
  );
}

NotificationCard.propTypes = {
  notification: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

NotificationCard.defaultProps = {
  onClick() {},
};
