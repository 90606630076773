import gql from "graphql-tag";

export const CREATE_ADDRESS = gql`
  mutation CreateAddress($address: CreateAddressInput!) {
    createAddress(address: $address) {
      id
    }
  }
`;
export const UPDATE_ADDRESS = gql`
  mutation UpdateAddress($address: UpdateAddressInput!) {
    updateAddress(address: $address) {
      id
    }
  }
`;
export const DELETE_ADDRESS = gql`
  mutation DeleteAddress($id: String!) {
    deleteAddress(id: $id) {
      id
    }
  }
`;

export const CREATE_CONTACT_NUMBER = gql`
  mutation CreateContactNumber($contactNumber: CreateContactNumberInput!) {
    createContactNumber(contactNumber: $contactNumber) {
      id
      userId
      phoneNumber
      isPrimary
      isDefault
    }
  }
`;
export const UPDATE_CONTACT_NUMBER = gql`
  mutation UpdateContactNumber($contactNumber: UpdateContactNumberInput!) {
    updateContactNumber(contactNumber: $contactNumber) {
      id
      userId
      phoneNumber
      isPrimary
      isDefault
    }
  }
`;
export const DELETE_CONTACT_NUMBER = gql`
  mutation DeleteContactNumber($id: String!) {
    deleteContactNumber(id: $id) {
      id
    }
  }
`;

export const CREATE_GROUP_INVITATION = gql`
  mutation CreateGroupInvitation($groupId: String!) {
    createGroupInvitation(groupId: $groupId) {
      id
      invitationKey
    }
  }
`;

export const JOIN_GROUP = gql`
  mutation JoinGroup($groupId: String!) {
    joinGroup(groupId: $groupId) {
      id
      isCurrentUserAMember
    }
  }
`;

export const LEAVE_GROUP = gql`
  mutation LeaveGroup($groupId: String!) {
    leaveGroup(groupId: $groupId) {
      id
      isCurrentUserAMember
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation DeleteGroup($groupId: String!) {
    deleteGroup(groupId: $groupId) {
      id
      deleted
    }
  }
`;

export const ACCEPT_GROUP_INVITATION = gql`
  mutation AcceptGroupInvitation($invitationKey: String!) {
    acceptGroupInvitation(invitationKey: $invitationKey) {
      id
    }
  }
`;

export const CREATE_LISTING = gql`
  mutation CreateListing($listing: CreateListingInput!) {
    createListing(listing: $listing) {
      id
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation CreateGroup($group: CreateGroupInput!) {
    createGroup(group: $group) {
      id
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup($group: UpdateGroupInput!) {
    updateGroup(group: $group) {
      id
      name
      description
      visibility
      logoImage
    }
  }
`;

export const TOGGLE_FAVOURITE = gql`
  mutation ToggleFavouriteListing($listingId: String!) {
    toggleFavouriteListing(listingId: $listingId) {
      isFavourite
    }
  }
`;

export const TOGGLE_ACTIVE_LISTING = gql`
  mutation ToggleActiveListing($id: String!, $reason: String) {
    toggleActiveListing(id: $id, reason: $reason) {
      id
      active
      inactiveReason
    }
  }
`;

export const UPDATE_LISTING = gql`
  mutation UpdateListing($listing: UpdateListingInput!) {
    updateListing(listing: $listing) {
      id
      title
      description
      public
      groups {
        id
        visibility
        name
        logoImage
      }
      isFavourite
      seller {
        id
        firstName
        lastName
        profilePicture
        levels {
          bibliophilePoints
          bibliophileLevel
          bibliophileTitle
        }
      }
      shop {
        id
        identifier
        name
        logoImage
        contactNumbers {
          id
        }
        addresses {
          id
        }
        deliveryPreferences {
          type
          notes
        }
      }
      price
      originalPrice
      priceModelType
      addresses {
        id
        addressLine1
        addressLine2
        addressLine3
        city
      }
      contactNumbers {
        id
        phoneNumber
      }
      coverImage
      books {
        id
        title
        subtitle
        description
        isbn
        url
        publisher
        pageCount
        language
        category
        condition
        authors {
          name
        }
        images
        year
      }
      tags
      notes
      active
      inactiveReason
      createdAt
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateUser($user: UpdateUserInput!) {
    updateUser(user: $user) {
      id
      firstName
      lastName
      email
      profilePicture
      preferredStoryGenres
      emailPreferences {
        orderNotifications
        bookRequestNotifications
      }
      privacyPreferences {
        activityListedOnFeed
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($user: CreateUserInput!, $params: CreateUserParamsInput) {
    createUser(user: $user, params: $params) {
      firstName
      lastName
      email
      profilePicture
      googleId
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_BOOK_REQUEST = gql`
  mutation CreateBookRequest($bookRequest: CreateBookRequestInput!) {
    createBookRequest(bookRequest: $bookRequest) {
      id
      user {
        id
        firstName
        lastName
        profilePicture
      }
      title
      authors {
        id
        name
      }
      groups {
        id
        name
      }
      publisher
      year
      isbn
      language
      message
      priceModelType
      expiresAt
      count
      contactNumbers {
        id
        phoneNumber
      }
    }
  }
`;

export const UPDATE_BOOK_REQUEST = gql`
  mutation UpdateBookRequest($bookRequest: UpdateBookRequestInput!) {
    updateBookRequest(bookRequest: $bookRequest) {
      id
      user {
        id
        firstName
        lastName
        profilePicture
      }
      title
      authors {
        id
        name
      }
      publisher
      year
      isbn
      language
      message
      priceModelType
      expiresAt
      count
      contactNumbers {
        id
        phoneNumber
      }
      groups {
        id
        name
      }
      expiresAt
      searchResultsCount
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_BOOK_REQUEST = gql`
  mutation DeleteBookRequest($id: String!) {
    deleteBookRequest(id: $id) {
      id
      deleted
    }
  }
`;

export const DELETE_LISTING = gql`
  mutation DeleteListing($id: String!) {
    deleteListing(id: $id) {
      deleted
    }
  }
`;

export const CREATE_SHOP = gql`
  mutation CreateShop($shop: CreateShopInput!) {
    createShop(shop: $shop) {
      id
      identifier
    }
  }
`;

export const UPDATE_SHOP = gql`
  mutation UpdateShop($shop: UpdateShopInput!) {
    updateShop(shop: $shop) {
      id
      identifier
      name
      description
      logoImage
      coverImage
      addresses {
        id
        addressLine1
        addressLine2
        addressLine3
        city
      }
      contactNumbers {
        id
        phoneNumber
      }
      deliveryPreferences {
        type
        notes
      }
      sections {
        id
      }
    }
  }
`;

export const CREATE_SHOP_SECTION = gql`
  mutation CreateShopSection($shopId: String!, $section: CreateSectionInput!) {
    createShopSection(shopId: $shopId, section: $section) {
      id
      name
      description
      logoImage
      identifier
      owner {
        id
        firstName
        lastName
        profilePicture
      }
      addresses {
        id
        addressLine1
        addressLine2
        addressLine3
        city
      }
      contactNumbers {
        id
        phoneNumber
      }
      sections {
        id
        title
        tags
        featuredCount
      }
      pages {
        id
        title
        tags
      }
      about
      deleted
    }
  }
`;

export const UPDATE_SHOP_SECTION = gql`
  mutation UpdateShopSection($shopId: String!, $section: UpdateSectionInput!) {
    updateShopSection(shopId: $shopId, section: $section) {
      id
      name
      description
      logoImage
      identifier
      owner {
        id
        firstName
        lastName
        profilePicture
      }
      addresses {
        id
        addressLine1
        addressLine2
        addressLine3
        city
      }
      contactNumbers {
        id
        phoneNumber
      }
      sections {
        id
        title
        tags
        featuredCount
        titleBackgroundColor
        contentBackgroundColor
      }
      pages {
        id
        title
        tags
      }
      about
      deleted
    }
  }
`;

export const DELETE_SHOP_SECTION = gql`
  mutation DeleteShopSection($shopId: String!, $sectionId: String!) {
    deleteShopSection(shopId: $shopId, sectionId: $sectionId) {
      id
      name
      description
      logoImage
      identifier
      owner {
        id
        firstName
        lastName
        profilePicture
      }
      addresses {
        id
        addressLine1
        addressLine2
        addressLine3
        city
      }
      contactNumbers {
        id
        phoneNumber
      }
      sections {
        id
        title
        tags
        featuredCount
      }
      pages {
        id
        title
        tags
      }
      about
      deleted
    }
  }
`;

export const UPDATE_SHOP_PAGES = gql`
  mutation UpdateShopPages($shopId: String!, $pages: [CreatePageInput!]!) {
    updateShopPages(shopId: $shopId, pages: $pages) {
      id
      name
      description
      logoImage
      identifier
      owner {
        id
        firstName
        lastName
        profilePicture
      }
      addresses {
        id
        addressLine1
        addressLine2
        addressLine3
        city
      }
      contactNumbers {
        id
        phoneNumber
      }
      sections {
        id
        title
        tags
        featuredCount
      }
      pages {
        id
        title
        tags
      }
      about
      deleted
    }
  }
`;

export const UPDATE_APP_NOTIFICATION = gql`
  mutation UpdateAppNotification($notification: UpdateAppNotificationInput!) {
    updateAppNotification(notification: $notification) {
      id
      read
    }
  }
`;

export const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  mutation MarkAllNotificationsRead {
    markAllNotificationsRead {
      count
    }
  }
`;

export const REPORT_CONTENT = gql`
  mutation ReportContent($report: CreateReportInput!) {
    reportContent(report: $report) {
      id
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($verificationArgs: VerifyEmailInput!) {
    verifyEmail(verificationArgs: $verificationArgs) {
      id
      verified
      user {
        firstName
      }
    }
  }
`;

export const INITIATE_RESET_PASSWORD = gql`
  mutation CreatePasswordResetToken($email: String!) {
    createPasswordResetToken(email: $email) {
      id
    }
  }
`;

export const VALIDATE_PASSWORD_RESET_TOKEN = gql`
  mutation ValidatePasswordResetToken(
    $verificationArgs: VerifyPasswordResetTokenInput!
  ) {
    validatePasswordResetToken(verificationArgs: $verificationArgs) {
      id
      user {
        id
        firstName
      }
      expired
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($passwordUpdate: ResetPasswordInput!) {
    resetPassword(passwordUpdate: $passwordUpdate) {
      id
      firstName
      lastName
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation CreateOrder($order: CreateOrderInput!) {
    createOrder(order: $order) {
      id
    }
  }
`;

export const ACCEPT_ORDER = gql`
  mutation AcceptOrder($id: String!) {
    acceptOrder(id: $id) {
      id
      totalValue
      deliveryPreference {
        id
        type
        notes
      }
      userContactNumbers {
        id
        phoneNumber
      }
      deliveryAddress {
        id
        addressLine1
        addressLine2
        addressLine3
        city
      }
      user {
        id
        firstName
        lastName
      }
      shop {
        id
        name
        logoImage
      }
      items {
        quantity
        listing {
          id
          title
          public
          groups {
            id
            name
          }
          seller {
            id
            firstName
            lastName
          }
          shop {
            id
            name
          }
          price
          originalPrice
          priceModelType
          coverImage
          booksCount
          createdAt
        }
      }
      status
      createdByUser {
        id
        firstName
        lastName
      }
      updatedByUser {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;

export const REJECT_ORDER = gql`
  mutation RejectOrder($id: String!) {
    rejectOrder(id: $id) {
      id
      totalValue
      deliveryPreference {
        id
        type
        notes
      }
      userContactNumbers {
        id
        phoneNumber
      }
      deliveryAddress {
        id
        addressLine1
        addressLine2
        addressLine3
        city
      }
      user {
        id
        firstName
        lastName
      }
      shop {
        id
        name
        logoImage
      }
      items {
        quantity
        listing {
          id
          title
          public
          groups {
            id
            name
          }
          seller {
            id
            firstName
            lastName
          }
          shop {
            id
            name
          }
          price
          originalPrice
          priceModelType
          coverImage
          booksCount
          createdAt
        }
      }
      status
      createdByUser {
        id
        firstName
        lastName
      }
      updatedByUser {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;

export const SET_ORDER_DISPATCHED = gql`
  mutation SetOrderDispatched($id: String!) {
    setOrderDispatched(id: $id) {
      id
      totalValue
      deliveryPreference {
        id
        type
        notes
      }
      userContactNumbers {
        id
        phoneNumber
      }
      deliveryAddress {
        id
        addressLine1
        addressLine2
        addressLine3
        city
      }
      user {
        id
        firstName
        lastName
      }
      shop {
        id
        name
        logoImage
      }
      items {
        quantity
        listing {
          id
          title
          public
          groups {
            id
            name
          }
          seller {
            id
            firstName
            lastName
          }
          shop {
            id
            name
          }
          price
          originalPrice
          priceModelType
          coverImage
          booksCount
          createdAt
        }
      }
      status
      createdByUser {
        id
        firstName
        lastName
      }
      updatedByUser {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;

export const SET_ORDER_DELIVERED = gql`
  mutation SetOrderDelivered($id: String!) {
    setOrderDelivered(id: $id) {
      id
      totalValue
      deliveryPreference {
        id
        type
        notes
      }
      userContactNumbers {
        id
        phoneNumber
      }
      deliveryAddress {
        id
        addressLine1
        addressLine2
        addressLine3
        city
      }
      user {
        id
        firstName
        lastName
      }
      shop {
        id
        name
        logoImage
      }
      items {
        quantity
        listing {
          id
          title
          public
          groups {
            id
            name
          }
          seller {
            id
            firstName
            lastName
          }
          shop {
            id
            name
          }
          price
          originalPrice
          priceModelType
          coverImage
          booksCount
          createdAt
        }
      }
      status
      createdByUser {
        id
        firstName
        lastName
      }
      updatedByUser {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;

export const SET_ORDER_READY_FOR_PICKUP = gql`
  mutation SetOrderReadyForPickup($id: String!) {
    setOrderReadyForPickup(id: $id) {
      id
      totalValue
      deliveryPreference {
        id
        type
        notes
      }
      userContactNumbers {
        id
        phoneNumber
      }
      deliveryAddress {
        id
        addressLine1
        addressLine2
        addressLine3
        city
      }
      user {
        id
        firstName
        lastName
      }
      shop {
        id
        name
        logoImage
      }
      items {
        quantity
        listing {
          id
          title
          public
          groups {
            id
            name
          }
          seller {
            id
            firstName
            lastName
          }
          shop {
            id
            name
          }
          price
          originalPrice
          priceModelType
          coverImage
          booksCount
          createdAt
        }
      }
      status
      createdByUser {
        id
        firstName
        lastName
      }
      updatedByUser {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation CancelOrder($id: String!) {
    cancelOrder(id: $id) {
      id
      totalValue
      deliveryPreference {
        id
        type
        notes
      }
      userContactNumbers {
        id
        phoneNumber
      }
      deliveryAddress {
        id
        addressLine1
        addressLine2
        addressLine3
        city
      }
      user {
        id
        firstName
        lastName
      }
      shop {
        id
        name
        logoImage
      }
      items {
        quantity
        listing {
          id
          title
          public
          groups {
            id
            name
          }
          seller {
            id
            firstName
            lastName
          }
          shop {
            id
            name
          }
          price
          originalPrice
          priceModelType
          coverImage
          booksCount
          createdAt
        }
      }
      status
      createdByUser {
        id
        firstName
        lastName
      }
      updatedByUser {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;

export const TOGGLE_FAVOURITE_SHOP = gql`
  mutation ToggleFavouriteShop($shopId: String!) {
    toggleFavouriteShop(shopId: $shopId) {
      id
      isFavourite
    }
  }
`;

export const TOGGLE_FOLLOW_SHOP = gql`
  mutation ToggleFollowShop($shopId: String!) {
    toggleFollowShop(shopId: $shopId) {
      id
      identifier
      isFollowed
    }
  }
`;

export const TOGGLE_FOLLOW_USER = gql`
  mutation ToggleFollowUser($targetUserId: String!) {
    toggleFollowUser(targetUserId: $targetUserId) {
      id
      isFollowee
    }
  }
`;

export const TOGGLE_FOLLOW_GROUP = gql`
  mutation ToggleFollowGroup($groupId: String!) {
    toggleFollowGroup(groupId: $groupId) {
      id
      isFollowed
    }
  }
`;

export const CREATE_ORDER_MESSAGE = gql`
  mutation CreateOrderMessage($message: String!, $orderId: String!) {
    createOrderMessage(message: $message, orderId: $orderId) {
      id
      threadId
      user {
        id
        firstName
        lastName
        profilePicture
      }
      message
      createdAt
    }
  }
`;

export const TOGGLE_FOLLOW_MESSAGE_THREAD = gql`
  mutation ToggleFollowMessageThread($id: String!) {
    toggleFollowMessageThread(id: $id) {
      id
      messagesCount
      isFollowed
    }
  }
`;

export const CREATE_CHAT_GROUP = gql`
  mutation CreateChatGroup($chatGroup: CreateChatGroupInput!) {
    createChatGroup(chatGroup: $chatGroup) {
      id
      topic
      threadId
      participants {
        id
        firstName
        lastName
        profilePicture
      }
    }
  }
`;

export const UPDATE_CHAT_GROUP = gql`
  mutation UpdateChatGroup($chatGroup: UpdateChatGroupInput!) {
    updateChatGroup(chatGroup: $chatGroup) {
      id
      topic
      threadId
      participants {
        id
        firstName
        lastName
        profilePicture
      }
    }
  }
`;

export const SEND_CHAT_MESSAGE = gql`
  mutation SendChatMessage($chatGroupId: String!, $message: String!) {
    createChatMessage(chatGroupId: $chatGroupId, message: $message) {
      id
      threadId
      user {
        id
        firstName
        lastName
        profilePicture
      }
      message
      createdAt
    }
  }
`;

export const CREATE_LISTING_COLLECTION = gql`
  mutation CreateListingCollection(
    $listingCollection: CreateListingCollectionInput!
  ) {
    createListingCollection(listingCollection: $listingCollection) {
      id
      title
      description
      owner {
        id
        firstName
        lastName
        profilePicture
      }
      tags
      public
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_LISTING_COLLECTION = gql`
  mutation UpdateListingCollection(
    $listingCollection: UpdateListingCollectionInput!
  ) {
    updateListingCollection(listingCollection: $listingCollection) {
      id
      title
      description
      owner {
        id
        firstName
        lastName
        profilePicture
      }
      tags
      public
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_LISTING_COLLECTION = gql`
  mutation DeleteListingCollection($id: String!) {
    deleteListingCollection(id: $id) {
      id
      deleted
    }
  }
`;

export const CREATE_CRITIQUE = gql`
  mutation CreateCritique($critique: CreateCritiqueInput!) {
    createCritique(critique: $critique) {
      id
      title
      content
      book {
        title
        subtitle
        year
        authors {
          name
        }
        isbn
        publisher
        language
      }
      criticRecommends
      critic {
        id
        firstName
        lastName
        profilePicture
      }
      votesCount
      hasVoted
      rating
      coverImage
      published
      containsSpoilers
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_CRITIQUE = gql`
  mutation UpdateCritique($critique: UpdateCritiqueInput!) {
    updateCritique(critique: $critique) {
      id
      title
      content
      book {
        title
        subtitle
        year
        authors {
          name
        }
        isbn
        publisher
        language
      }
      criticRecommends
      critic {
        id
        firstName
        lastName
        profilePicture
      }
      votesCount
      hasVoted
      rating
      coverImage
      published
      containsSpoilers
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_CRITIQUE = gql`
  mutation DeleteCritique($id: String!) {
    deleteCritique(id: $id) {
      id
      deleted
    }
  }
`;

export const TOGGLE_VOTE_CRITIQUE = gql`
  mutation ToggleVoteCritique($critiqueId: String!) {
    toggleVoteCritique(critiqueId: $critiqueId) {
      id
      votesCount
      hasVoted
    }
  }
`;

export const SEND_CONTACT_MESSAGE = gql`
  mutation SendContactMessage($messageDetails: ContactMessageInput!) {
    sendMessage(messageDetails: $messageDetails) {
      received
    }
  }
`;

export const CREATE_DISCUSSION = gql`
  mutation CreateDiscussion($discussion: CreateDiscussionInput!) {
    discussion: createDiscussion(discussion: $discussion) {
      id
    }
  }
`;

export const UPDATE_DISCUSSION = gql`
  mutation UpdateDiscussion($discussion: UpdateDiscussionInput!) {
    discussion: updateDiscussion(discussion: $discussion) {
      id
      user {
        id
        firstName
        lastName
        profilePicture
        levels {
          bibliophilePoints
          bibliophileLevel
          bibliophileTitle
        }
      }
      group {
        id
        name
        logoImage
      }
      threadId
      title
      message
      coverImage
      books {
        id
        title
        subtitle
        description
        isbn
        url
        publisher
        pageCount
        language
        category
        condition
        authors {
          name
        }
        images
        year
      }
      votesCount
      hasVoted
      closed
      createdAt
      updatedAt
    }
  }
`;

export const REPLY_DISCUSSION = gql`
  mutation ReplyDiscussion($discussionId: String!, $message: String!) {
    replyDiscussion(discussionId: $discussionId, message: $message) {
      id
      threadId
      user {
        id
        firstName
        lastName
        profilePicture
      }
      message
      createdAt
    }
  }
`;

export const TOGGLE_VOTE_DISCUSSION = gql`
  mutation ToggleVoteDiscussion($discussionId: String!) {
    toggleVoteDiscussion(discussionId: $discussionId) {
      id
      votesCount
      hasVoted
    }
  }
`;

export const DELETE_DISCUSSION = gql`
  mutation DeleteDiscussion($id: String!) {
    deleteDiscussion(id: $id) {
      id
      deleted
    }
  }
`;

export const TOGGLE_CLOSE_DISCUSSION = gql`
  mutation ToggleCloseDiscussion($id: String!) {
    toggleCloseDiscussion(id: $id) {
      id
      closed
    }
  }
`;

export const CREATE_CURATED_BOOK = gql`
  mutation CreatedCuratedBook($book: CreateCuratedBookInput!) {
    curatedBook: createCuratedBook(book: $book) {
      id
    }
  }
`;

export const UPDATE_CURATED_BOOK = gql`
  mutation UpdateCuratedBook($book: UpdateCuratedBookInput!) {
    curatedBook: updateCuratedBook(book: $book) {
      id
    }
  }
`;

export const DELETE_MESSAGE = gql`
  mutation DeleteMessage($id: String!, $threadId: String!) {
    deletedMessage: deleteMessage(id: $id, threadId: $threadId) {
      id
      threadId
      user {
        id
        firstName
        lastName
        profilePicture
      }
      message
      createdAt
    }
  }
`;

export const MAKE_STORY_READING_CHECKPOINT = gql`
  mutation MakeStoryCheckpoint(
    $storyReadingCheckpoint: StoryReadingCheckpointInput!
  ) {
    makeStoryCheckpoint(storyReadingCheckpoint: $storyReadingCheckpoint) {
      id
      story {
        id
      }
      chapter {
        id
      }
      finished
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_STORY = gql`
  mutation CreateStory($story: CreateStoryInput!) {
    createStory(story: $story) {
      id
      author {
        firstName
        lastName
        profilePicture
      }
      title
      subtitle
      abstract
      characters
      coverImage
      tags
      genre
      averageRating
      chaptersCount
      currentUserReview {
        rating
        review
        containsSpoilers
        createdAt
        updatedAt
      }
      audienceCategory
      language
      copyrightType
      matureContent
      isComplete
      published
      deleted
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_STORY = gql`
  mutation UpdateStory($story: UpdateStoryInput!) {
    updateStory(story: $story) {
      id
      author {
        id
        firstName
        lastName
        profilePicture
      }
      title
      subtitle
      abstract
      characters
      coverImage
      tags
      genre
      averageRating
      reviewsCount
      chaptersCount
      currentUserReview {
        id
        rating
        review
        containsSpoilers
        createdAt
        updatedAt
      }
      currentUserReadingCheckpoint {
        id
        chapter {
          id
          chapterNumber
        }
      }
      audienceCategory
      language
      copyrightType
      matureContent
      isComplete
      published
      deleted
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_STORY = gql`
  mutation DeleteStory($id: String!) {
    deleteStory(id: $id) {
      id
      deleted
    }
  }
`;

export const TOGGLE_FEATURE_STORY = gql`
  mutation ToggleFeatureStory($id: String!) {
    toggleFeatureStory(id: $id) {
      id
      featured
    }
  }
`;

export const TOGGLE_MARK_STORY_AS_COMPLETE = gql`
  mutation ToggleMarkStoryAsComplete($id: String!) {
    toggleMarkStoryAsComplete(id: $id) {
      id
      isComplete
    }
  }
`;

export const TOGGLE_PUBLISH_STORY = gql`
  mutation ToggleUpdateStory($id: String!) {
    togglePublishStory(id: $id) {
      id
      published
    }
  }
`;

export const TOGGLE_EDITOR_PICK_STORY = gql`
  mutation ToggleEditorsPick($id: String!) {
    toggleEditorsPick(id: $id) {
      id
      editorsPick
    }
  }
`;

export const CREATE_STORY_CHAPTER = gql`
  mutation CreateStoryChapter($storyChapter: CreateStoryChapterInput!) {
    createStoryChapter(storyChapter: $storyChapter) {
      id
      story {
        id
        title
        chaptersCount
        published
      }
      title
      chapterNumber
      content
      commentThreadId
      published
      deleted
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_STORY_CHAPTER = gql`
  mutation UpdateStoryChapter($storyChapter: UpdateStoryChapterInput!) {
    updateStoryChapter(storyChapter: $storyChapter) {
      id
      story {
        id
        title
        chaptersCount
      }
      title
      chapterNumber
      content
      commentThreadId
      published
      deleted
      createdAt
      updatedAt
    }
  }
`;

export const TOGGLE_PUBLISH_STORY_CHAPTER = gql`
  mutation TogglePublishStoryChapter($id: String!) {
    togglePublishStoryChapter(id: $id) {
      id
      chapterNumber
      published
      story {
        id
        published
        chaptersCount
      }
    }
  }
`;

export const DELETE_STORY_CHAPTER = gql`
  mutation DeleteStoryChapter($id: String!) {
    deleteStoryChapter(id: $id) {
      id
      deleted
    }
  }
`;

export const COMMENT_ON_STORY_CHAPTER = gql`
  mutation CommentOnChapter($chapterId: String!, $message: String!) {
    commentOnChapter(chapterId: $chapterId, message: $message) {
      id
      message
    }
  }
`;

export const RATE_STORY_CHAPTER = gql`
  mutation RateStoryChapter($id: String!, $rating: Int!) {
    rateStoryChapter(id: $id, rating: $rating) {
      id
      story {
        id
        title
        chaptersCount
      }
      title
      averageRating
      ratingsCount
      currentUserRating {
        id
        rating
      }
      chapterNumber
      content
      commentThreadId
      published
      deleted
      createdAt
      updatedAt
    }
  }
`;

export const REVIEW_STORY = gql`
  mutation ReviewStory($storyReview: StoryReviewInput!) {
    reviewStory(storyReview: $storyReview) {
      id
      story {
        id
        title
        averageRating
        reviewsCount
        currentUserReview {
          id
        }
      }
      rating
      review
      containsSpoilers
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_STORY_READING_CHECKPOINT = gql`
  mutation DeleteStoryReadingCheckpoint($storyId: String!) {
    deleteStoryReadingCheckpoint(storyId: $storyId) {
      id
    }
  }
`;

export const SAVE_ADVERTISEMENT_PREFERENCES = gql`
  mutation SaveAdvertisementPreferences(
    $preferences: AdvertisementPreferencesInput!
  ) {
    saveAdvertisementPreferences(preferences: $preferences) {
      id
      advertisementPreferences {
        preferredShopId
        preferredContactNumberIds
        preferredAddressIds
      }
    }
  }
`;

export const TOGGLE_CLOSE_SHOP = gql`
  mutation ToggleCloseShop($id: String!) {
    toggleCloseShop(id: $id) {
      id
      identifier
      closed
    }
  }
`;
