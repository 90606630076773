import React from "react";
import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    border: "none",
    backgroundColor: "transparent",
    width: "100%",
    marginBottom: "0 !important",
    paddingBottom: 0,
  },
}));

export function ListingsCardLoader() {
  const classes = useStyles();
  return (
    <div className={`ph-item ${classes.item}`}>
      <div className="ph-col-4">
        <div className="ph-picture big"></div>
      </div>
      <div className="ph-col-8">
        <div className="ph-row">
          <div className="ph-col-8 big"></div>
          <div className="ph-col-12"></div>
          <div className="ph-col-6"></div>
          <div className="ph-col-3"></div>
          <div className="ph-col-12 empty"></div>
          <div className="ph-col-4 big"></div>
        </div>
      </div>
    </div>
  );
}

export function ListingLoader({ xs, sm, md, lg, xl }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Grid item container spacing={isMobile ? 1 : 5} justifyContent="flex-start">
      <Grid item container xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <ListingsCardLoader />
      </Grid>
      <Grid item container xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <ListingsCardLoader />
      </Grid>
      <Grid item container xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <ListingsCardLoader />
      </Grid>
    </Grid>
  );
}
