import { v4 } from "uuid";

export const actionTypes = {
  LOADING: "LOADING",
  EMIT_ERROR: "EMIT_ERROR",
  INFO_MESSAGE: "INFO_MESSAGE",
  ERROR_MESSAGE: "ERROR_MESSAGE",
  DISMISS_MESSAGE: "DISMISS_MESSAGE",
  PROMPT_LOGIN: "PROMPT_LOGIN",
  DISMISS_PROMPT_LOGIN: "DISMISS_PROMPT_LOGIN",
};

const initialState = {
  loading: false,
  error: null,
  alerts: [],
  loginRequired: false,
  onLoginRedirectPath: null,
};

export function applicationReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.status,
      };
    case actionTypes.EMIT_ERROR:
      return { ...state, error: action.error };
    case actionTypes.INFO_MESSAGE:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: v4(),
            type: "info",
            message: action.message,
            autoHideDuration: action.autoHideDuration || 5000,
          },
        ],
      };
    case actionTypes.ERROR_MESSAGE:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: v4(),
            type: "error",
            message: action.message,
            autoHideDuration: action.autoHideDuration || 5000,
          },
        ],
      };
    case actionTypes.DISMISS_MESSAGE:
      return {
        ...state,
        alerts: state.alerts.filter((alert) => alert.id !== action.messageId),
      };
    case actionTypes.PROMPT_LOGIN:
      return {
        ...state,
        loginRequired: true,
        onLoginRedirectPath: action.redirectPath,
      };
    case actionTypes.DISMISS_PROMPT_LOGIN:
      return {
        ...state,
        loginRequired: false,
        onLoginRedirectPath: null,
      };
    default:
      return state;
  }
}
