import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  InputAdornment,
  IconButton,
  Paper,
  Button,
  Container,
} from "@material-ui/core";
import PageTitle from "../Common/PageTitle";
import { useHistory, useLocation } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import BootstrapInput from "../Common/BootstrapInput";
import Hyperlink from "../Common/Hyperlink";
import PageHead from "../Common/PageHead";
import DiscussionsSearchController from "./DiscussionsSearchController";
import BookChatIcon from "./BookChatIcon";
import { useLoginPrompt, useScrollTop } from "../../utils/hooks";
import ImageFadeIn from "../Common/ImageFadeIn";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  bookChatDiscoverer: {
    marginTop: theme.spacing(3),
  },
  logo: {
    maxHeight: theme.spacing(6) * 2,
  },
  pageTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bookChatSearchBox: {
    width: "100%",
  },
  bookChatSearchBoxInput: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    "&::placeholder": {
      color: theme.palette.primary.main,
    },
  },
  bookChatSearchContainer: {
    marginBottom: theme.spacing(2),
  },
}));

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export default function BookChat() {
  const classes = useStyles();
  const history = useHistory();
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const queryParams = useQueryParams();
  const [searchQuery, setSearchQuery] = useState("");

  useScrollTop([history.location.key]);

  const onKeyDown = (event) => {
    if (event.keyCode === 13) searchBookChat();
  };

  const searchBookChat = () => {
    const queryParams = {};
    if (searchQuery) queryParams.dq = searchQuery;
    const queryString = new URLSearchParams(queryParams).toString();
    history.push(`/bookchats?${queryString}`);
  };
  const onChangeSearchQuery = (event) => setSearchQuery(event.target.value);
  const onClickStartDiscussion = createLoginPromptEventHandler({
    redirectPath: "/bookchat/new",
  });

  const bookChatSearchBar = (
    <Grid
      container
      justifyContent="center"
      className={classes.bookChatSearchContainer}
    >
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={9}>
            <Paper>
              <BootstrapInput
                className={classes.bookChatSearchBox}
                defaultValue={queryParams.get("dq")}
                placeholder={t`Search BookChat`}
                onChange={onChangeSearchQuery}
                onKeyDown={onKeyDown}
                endAdornment={
                  <InputAdornment>
                    <IconButton
                      color="primary"
                      variant="contained"
                      onClick={searchBookChat}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              ></BootstrapInput>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const searchArgs = {
    query: queryParams.get("dq"),
    isbn: queryParams.get("isbn"),
  };
  const isbnList = (queryParams.get("isbnList") || "")
    .split(",")
    .map((isbn) => isbn.trim())
    .filter((isbn) => !!isbn);
  if (isbnList.length) searchArgs.isbnList = isbnList;

  return (
    <>
      <PageHead title="BookChat | bibliocircle" />
      <Container maxWidth="xl">
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          className={classes.bookChatDiscoverer}
        >
          <Grid container item xs={12} lg={8} spacing={2}>
            <Grid container item xs={12} className={classes.pageTitle}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                direction="column"
              >
                <Grid item>
                  <Grid container justifyContent="center">
                    <ImageFadeIn
                      src="/logo/logo_book-chat.svg"
                      alt="bibliocircle BookChat"
                      className={classes.logo}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <PageTitle variant="subtitle1" align="center">
                    <Trans>
                      Where else is better than a place where bibliophiles hang
                      out and discuss their favourite books?
                    </Trans>
                  </PageTitle>
                </Grid>
                <Grid item>
                  <Button
                    onClick={onClickStartDiscussion}
                    component={Hyperlink}
                    to="/bookchat/new"
                    startIcon={<BookChatIcon />}
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    <Trans>Start a discussion</Trans>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              {bookChatSearchBar}
            </Grid>
            <Grid container item xs={12}>
              <DiscussionsSearchController
                key={history.location.key}
                showGroupNames
                xs={12}
                searchArgs={searchArgs}
                noResultsMessage="No discussions found!"
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
