import React from "react";
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
  Avatar,
  Divider,
} from "@material-ui/core";
import { getFeedPostTransformer } from "../transformers";
import MediaContentViewer from "../../Common/MediaContentViewer";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import GroupIcon from "@material-ui/icons/Group";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import PeopleIcon from "@material-ui/icons/Contacts";
import StoreIcon from "@material-ui/icons/Store";
import CritiqueIcon from "../../Critique/CritiqueIcon";
import BookRequestIcon from "../../Requests/BookRequestIcon";
import BookChatIcon from "../../Discussions/BookChatIcon";
import BooksIcon from "@material-ui/icons/LibraryBooks";
import CollectionsIcon from "../../ListingCollections/CollectionsIcon";
import StoryIcon from "../../Stories/StoryIcon";
import { Trans } from "@lingui/macro";
import {
  amber,
  blue,
  blueGrey,
  brown,
  deepPurple,
  green,
  grey,
  lightBlue,
  pink,
  red,
  teal,
} from "@material-ui/core/colors";
import PostHeader from "./PostHeader";
import ImageFadeIn from "../../Common/ImageFadeIn";
import UnavailablePostContent from "./UnavailableContent";

const useStyles = makeStyles((theme) => ({
  feedPost: {
    boxShadow: "none",
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
    },
  },
  postTitle: {
    fontWeight: "bold",
    fontSize: "20px",
  },
  postContent: {
    whiteSpace: "pre-wrap",
  },
  postContainer: {
    "&": {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
  },
  postSecondaryTitle: {
    fontWeight: "bold",
    fontSize: 18,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  postSecondaryContent: {
    fontSize: 16,
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
  postSecondaryImageContainer: {
    [theme.breakpoints.up("sm")]: {
      backgroundImage: theme.palette.pattern.dottedMain,
      backgroundSize: "10px 10px",
    },
    backgroundSize: "8px 8px",
    padding: theme.spacing(1),
    backgroundImage: theme.palette.pattern.dottedGreyBackground,
    height: "100%",
    borderRadius: theme.shape.borderRadius,
  },
  postSecondaryImage: {
    width: "100%",
    height: "100px",
    objectFit: "contain",
  },
  secondaryPostContent: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.5),
      backgroundColor: theme.palette.grey[50],
    },
    borderRadius: theme.shape.borderRadius,
  },
  postCategory: {
    textDecoration: "none",
  },
  icon: {
    color: theme.palette.grey[800],
    fontSize: 16,
  },
  iconAvatar: {
    backgroundColor: theme.palette.grey[300],
    width: 24,
    height: 24,
  },
  linkText: {
    fontWeight: "bold",
    color: theme.palette.grey[800],
  },
  postDivider: {
    backgroundColor: grey[200],
  },
}));

const ICON_COLOR_DARKNESS = 400;
const ICON_BACKGROUND_DARKNESS = 50;
const entityCategories = {
  NEWS: {
    title: <Trans>News</Trans>,
    icon: AnnouncementIcon,
    iconColor: green[ICON_COLOR_DARKNESS],
    iconBackgroundColor: green[ICON_BACKGROUND_DARKNESS],
  },
  STORIES: {
    title: <Trans>Stories</Trans>,
    icon: StoryIcon,
    path: "/stories",
    iconColor: blue[ICON_COLOR_DARKNESS],
    iconBackgroundColor: blue[ICON_BACKGROUND_DARKNESS],
  },
  CRITIQUES: {
    title: <Trans>Critiques</Trans>,
    icon: CritiqueIcon,
    path: "/critiques",
    iconColor: pink[ICON_COLOR_DARKNESS],
    iconBackgroundColor: pink[ICON_BACKGROUND_DARKNESS],
  },
  BOOK_REQUESTS: {
    title: <Trans>Book Requests</Trans>,
    icon: BookRequestIcon,
    path: "/book-requests",
    iconColor: teal[ICON_COLOR_DARKNESS],
    iconBackgroundColor: teal[ICON_BACKGROUND_DARKNESS],
  },
  BOOK_RENT: {
    title: <Trans>Books for rent</Trans>,
    icon: BooksIcon,
    path: "/listings?priceModelType=RENT",
    iconColor: lightBlue[ICON_COLOR_DARKNESS],
    iconBackgroundColor: lightBlue[ICON_BACKGROUND_DARKNESS],
  },
  BOOK_SALE: {
    title: <Trans>Books for sale</Trans>,
    icon: BooksIcon,
    path: "/listings?priceModelType=SALE",
    iconColor: lightBlue[ICON_COLOR_DARKNESS],
    iconBackgroundColor: lightBlue[ICON_BACKGROUND_DARKNESS],
  },
  BOOK_EXCHANGE: {
    title: <Trans>Books for exchange</Trans>,
    icon: BooksIcon,
    path: "/listings?priceModelType=EXCHANGE",
    iconColor: lightBlue[ICON_COLOR_DARKNESS],
    iconBackgroundColor: lightBlue[ICON_BACKGROUND_DARKNESS],
  },
  DISCUSSIONS: {
    title: <Trans>Discussions</Trans>,
    icon: BookChatIcon,
    path: "/bookchats",
    iconColor: deepPurple[ICON_COLOR_DARKNESS],
    iconBackgroundColor: deepPurple[ICON_BACKGROUND_DARKNESS],
  },
  PEOPLE: {
    title: <Trans>People</Trans>,
    icon: PeopleIcon,
    path: "/people",
    iconColor: red[ICON_COLOR_DARKNESS],
    iconBackgroundColor: red[ICON_BACKGROUND_DARKNESS],
  },
  COLLECTIONS: {
    title: <Trans>Collections</Trans>,
    icon: CollectionsIcon,
    path: "/collections",
    iconColor: amber[ICON_COLOR_DARKNESS],
    iconBackgroundColor: amber[ICON_BACKGROUND_DARKNESS],
  },
  GROUPS: {
    title: <Trans>Groups</Trans>,
    icon: GroupIcon,
    path: "/groups",
    iconColor: blueGrey[ICON_COLOR_DARKNESS],
    iconBackgroundColor: blueGrey[ICON_BACKGROUND_DARKNESS],
  },
  SHOPS: {
    title: <Trans>Shops</Trans>,
    icon: StoreIcon,
    path: "",
    iconColor: brown[ICON_COLOR_DARKNESS],
    iconBackgroundColor: brown[ICON_BACKGROUND_DARKNESS],
  },
};

const isContentEmpty = (postContent) => {
  try {
    const { title, content, images } = postContent;
    if (!title && !content && (!Array.isArray(images) || images.length === 0)) {
      return true;
    }
    return false;
  } catch {
    return true;
  }
};

export default function FeedPost({ feedPost }) {
  const classes = useStyles();
  try {
    const transformer = getFeedPostTransformer(feedPost.type);
    const postCategory = entityCategories[feedPost.category];
    const {
      actorFullName,
      actorPath,
      actorImage,
      activityText,
      resourcePath,
      primaryContent,
      timestamp,
      secondaryContentReason,
      secondaryContent,
      noContent,
    } = transformer(feedPost);

    const onClickCard = (event) => {
      event.stopPropagation();
    };

    const getPrimaryContentPanel = (data) => {
      if (!data) return null;
      const { title, content, images } = data;
      if (isContentEmpty(data)) {
        return (
          <Grid item xs={12}>
            <UnavailablePostContent />
          </Grid>
        );
      }

      return (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {Array.isArray(images) && !!images.length && (
              <Grid item xs={12}>
                <MediaContentViewer
                  urls={images.slice(0, 1)} // TODO: Supports only one image for the moment
                  nozoom
                  showBackgroundPlaceholder
                />
              </Grid>
            )}

            {title && (
              <Grid item xs={12}>
                <Typography className={classes.postTitle} variant="body1">
                  {title}
                </Typography>
              </Grid>
            )}
            {content && (
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.postContent}>
                  {content}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    };

    const getSecondaryContentPanel = (data) => {
      if (!data) return null;
      const { title, content, images } = data;

      return (
        <Grid item xs={12}>
          <Grid container className={classes.secondaryPostContent}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {secondaryContentReason && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="primary">
                      {secondaryContentReason}
                    </Typography>
                  </Grid>
                )}
                {isContentEmpty(data) && (
                  <Grid item xs={12}>
                    <UnavailablePostContent />
                  </Grid>
                )}
                {!!images?.length && (
                  <Grid item xs={3}>
                    <Grid
                      container
                      alignItems="center"
                      className={classes.postSecondaryImageContainer}
                    >
                      <ImageFadeIn
                        src={images[0]}
                        className={classes.postSecondaryImage}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={9}>
                  <Grid container spacing={1}>
                    {title && (
                      <Grid item xs={12}>
                        <Typography
                          className={classes.postSecondaryTitle}
                          variant="body1"
                        >
                          {title}
                        </Typography>
                      </Grid>
                    )}
                    {content && (
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          className={classes.postSecondaryContent}
                        >
                          {content}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    };

    return (
      <Grid container>
        <Grid item xs={12}>
          <Card className={classes.feedPost}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <PostHeader
                    actorFullName={actorFullName}
                    actorImage={actorImage}
                    actorPath={actorPath}
                    activityText={activityText}
                  />
                </Grid>
                {!noContent && (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Divider className={classes.postDivider} />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        component={Link}
                        to={resourcePath}
                        className={classes.postContainer}
                        onClick={onClickCard}
                        spacing={2}
                      >
                        {primaryContent &&
                          getPrimaryContentPanel(primaryContent)}
                        {secondaryContent &&
                          getSecondaryContentPanel(secondaryContent)}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider className={classes.postDivider} />
                    </Grid>
                  </React.Fragment>
                )}
                <Grid item xs={12}>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography
                        color="textSecondary"
                        variant="caption"
                        className={classes.timestamp}
                      >
                        {dayjs(timestamp).format("MMMM D, YYYY [at] hh:mm a")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {postCategory && (
                        <Grid item xs={12}>
                          <Grid
                            container
                            component={Link}
                            to={postCategory.path}
                            spacing={1}
                            alignItems="center"
                            className={classes.postCategory}
                          >
                            <Grid item>
                              <Avatar
                                className={classes.iconAvatar}
                                style={{
                                  backgroundColor:
                                    postCategory?.iconBackgroundColor,
                                }}
                              >
                                <postCategory.icon
                                  className={classes.icon}
                                  style={{ color: postCategory?.iconColor }}
                                />
                              </Avatar>
                            </Grid>
                            <Grid item>
                              <Grid container alignItems="center">
                                <Typography
                                  className={classes.linkText}
                                  style={{ color: postCategory.iconColor }}
                                  variant="body2"
                                >
                                  {postCategory.title}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  } catch {
    return null;
  }
}
