import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import BootstrapInputBordered from "./BootstrapInputBordered";

export default function ConfirmPromptRigerous({
  title,
  question,
  assertText,
  open,
  onClose,
  onAction,
}) {
  const [confirmText, setConfirmText] = useState("");
  const confirm = () => onAction(confirmText === assertText);

  const onChangeConfirmText = (event) => setConfirmText(event.target.value);

  return (
    <div>
      <Dialog open={open} onClose={onClose} disableScrollLock={true}>
        {title ? <DialogTitle>{title}</DialogTitle> : null}
        <DialogContent>
          <DialogContentText>{question}</DialogContentText>
          <BootstrapInputBordered
            fullWidth
            onChange={onChangeConfirmText}
          ></BootstrapInputBordered>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button onClick={confirm} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmPromptRigerous.propTypes = {
  title: PropTypes.string,
  assertText: PropTypes.string,
  onClose: PropTypes.func,
  question: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

ConfirmPromptRigerous.defaultProps = {
  onClose: () => {},
  open: false,
};
