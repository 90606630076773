import { t } from "@lingui/macro";
import { PLACEHOLDER_IMAGES } from "../../consts";
import { createFeedPostDataExtractor } from "./transformerUtils";

export default function UserReviewedStoryTransformer(feedPost) {
  const primaryExtractor = createFeedPostDataExtractor(
    feedPost.primaryContent?.data
  );
  const secondaryExtractor = createFeedPostDataExtractor(
    feedPost.secondaryContent?.data
  );
  const containsSpoilers = primaryExtractor.get("containsSpoilers") === 1;
  const reviewText = primaryExtractor.get("review");
  const feedBasicInfo = {
    actorFullName: feedPost.actor.name,
    actorPath: `/profile/${feedPost.actor.id}`,
    actorImage: feedPost.actor.image || PLACEHOLDER_IMAGES.USER,
    resourcePath: `/story/${secondaryExtractor.get("storyId")}`,
    timestamp: feedPost.activityTimestamp,
  };

  if (reviewText?.length) {
    return {
      ...feedBasicInfo,
      activityText: t`reviewed and rated a story`,
      primaryContent: {
        title: null,
        content: containsSpoilers
          ? t`The review content is not previewed here as it contains spoilers. Click to read the review.`
          : primaryExtractor.get("review"),
        images: feedPost.primaryContent?.images || [],
      },
      secondaryContent: {
        title: secondaryExtractor.get("storyTitle"),
        content: secondaryExtractor.get("storyAbstract"),
        images: feedPost.secondaryContent?.images || [],
      },
      secondaryContentReason: "Story",
    };
  } else {
    return {
      ...feedBasicInfo,
      activityText: t`rated a story`,
      secondaryContent: {
        title: secondaryExtractor.get("storyTitle"),
        content: secondaryExtractor.get("storyAbstract"),
        images: feedPost.secondaryContent?.images || [],
      },
    };
  }
}
