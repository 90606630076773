import gql from "graphql-tag";

export const GET_USER = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      profilePicture
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      description
      profilePicture
      addresses {
        id
        addressLine1
        addressLine2
        addressLine3
        city
        isPrimary
        isDefault
      }
      isFollower
      isFollowee
      preferredStoryGenres
      contactNumbers {
        id
        phoneNumber
        isPrimary
        isDefault
      }
      levels {
        bibliophilePoints
        bibliophileLevel
        bibliophileTitle
        criticPoints
        criticLevel
        criticTitle
      }
    }
  }
`;

export const GET_STORY_READING_PREFERENCES = gql`
  query GetStoryReadingPreferences($id: String!) {
    user(id: $id) {
      id
      preferredStoryGenres
    }
  }
`;

export const GET_USER_PROFILE_FOR_EDITING = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      id
      email
      hasPassword
      firstName
      lastName
      description
      profilePicture
      addresses {
        id
        addressLine1
        addressLine2
        addressLine3
        city
        isPrimary
        isDefault
      }
      contactNumbers {
        id
        phoneNumber
        isPrimary
        isDefault
      }
      emailPreferences {
        orderNotifications
        bookRequestNotifications
      }
      privacyPreferences {
        activityListedOnFeed
      }
    }
  }
`;

export const GET_ADDRESSES = gql`
  {
    addresses {
      id
      userId
      addressLine1
      addressLine2
      addressLine3
      city
      isDefault
    }
  }
`;

export const GET_CONTACT_NUMBERS = gql`
  {
    contactNumbers {
      id
      phoneNumber
      isDefault
    }
  }
`;

export const SEARCH_GROUPS = gql`
  query SearchGroups(
    $query: String
    $isFollowed: Boolean
    $memberScope: String
    $isCurrentUserOwner: Boolean
    $isCurrentUserAMember: Boolean
    $pagination: GroupPaginationArgs
  ) {
    searchGroups(
      query: $query
      isFollowed: $isFollowed
      memberScope: $memberScope
      isCurrentUserOwner: $isCurrentUserOwner
      isCurrentUserAMember: $isCurrentUserAMember
      pagination: $pagination
    ) {
      groups {
        id
        name
        visibility
        description
        logoImage
        isCurrentUserAMember
        membersCount
        listingsCount
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const GET_GROUPS_AS_SHARING_LOCATIONS = gql`
  query GetGroups($isCurrentUserAMember: Boolean) {
    searchGroups(isCurrentUserAMember: $isCurrentUserAMember) {
      groups {
        id
        name
        visibility
      }
    }
  }
`;

export const GET_GROUP = gql`
  query getGroup($groupId: String!, $invitationKey: String) {
    group(id: $groupId, invitationKey: $invitationKey) {
      id
      name
      description
      owner {
        id
        firstName
        lastName
      }
      visibility
      logoImage
      membersCount
      listingsCount
      isFollowed
      createdAt
      isCurrentUserAMember
      isCurrentUserOwner
    }
  }
`;

export const GET_GROUP_FOR_EDITING = gql`
  query GetGroup($groupId: String!) {
    group(id: $groupId) {
      id
      name
      description
      visibility
      logoImage
    }
  }
`;

export const GET_USER_CONTACTS = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      id
      addresses {
        id
        addressLine1
        addressLine2
        addressLine3
        city
        isPrimary
        isDefault
      }
      contactNumbers {
        id
        phoneNumber
        isPrimary
        isDefault
      }
    }
  }
`;

export const GET_LISTING = gql`
  query GetListing($id: String!) {
    listing(id: $id) {
      id
      title
      description
      public
      groups {
        id
        visibility
        name
        logoImage
      }
      isFavourite
      seller {
        id
        firstName
        lastName
        profilePicture
        levels {
          bibliophilePoints
          bibliophileLevel
          bibliophileTitle
        }
      }
      shop {
        id
        identifier
        name
        logoImage
        contactNumbers {
          id
        }
        addresses {
          id
        }
        deliveryPreferences {
          type
          notes
        }
        closed
      }
      price
      originalPrice
      discountPercentage
      priceModelType
      addresses {
        id
        addressLine1
        addressLine2
        addressLine3
        city
      }
      contactNumbers {
        id
        phoneNumber
      }
      coverImage
      books {
        id
        title
        subtitle
        description
        isbn
        alternativeISBNs
        url
        publisher
        pageCount
        language
        category
        condition
        authors {
          name
        }
        images
        year
      }
      tags
      notes
      active
      inactiveReason
      createdAt
    }
  }
`;

export const GET_USER_FAVOURITES = gql`
  query getListings($favouritedUserScope: String) {
    searchListings(favouritedUserScope: $favouritedUserScope) {
      listings {
        id
        title
        price
        originalPrice
        discountPercentage
        priceModelType
        public
        groups {
          id
          visibility
          name
        }
        books {
          id
          images
        }
      }
    }
  }
`;

export const GET_USER_LISTINGS = gql`
  query getListings($userScope: String!) {
    searchListings(userScope: $userScope) {
      listings {
        id
        title
        price
        originalPrice
        discountPercentage
        priceModelType
        public
        groups {
          id
          visibility
          name
        }
        books {
          id
          images
        }
      }
    }
  }
`;

export const SEARCH_LISTINGS = gql`
  query SearchListings(
    $query: String
    $listingIds: [String!]
    $isbn: String
    $isbnList: [String!]
    $category: String
    $condition: String
    $groupScope: String
    $userScope: String
    $shopScope: String
    $favouritedUserScope: String
    $bookRequestScope: String
    $listingCollectionScope: String
    $priceModelType: String
    $location: String
    $language: String
    $minPrice: Float
    $maxPrice: Float
    $tags: [String!]
    $active: Boolean
    $inactiveReasons: [String!]
    $pagination: ListingPaginationArgs
  ) {
    searchListings(
      query: $query
      listingIds: $listingIds
      isbn: $isbn
      isbnList: $isbnList
      category: $category
      condition: $condition
      groupScope: $groupScope
      userScope: $userScope
      shopScope: $shopScope
      favouritedUserScope: $favouritedUserScope
      bookRequestScope: $bookRequestScope
      listingCollectionScope: $listingCollectionScope
      priceModelType: $priceModelType
      location: $location
      language: $language
      minPrice: $minPrice
      maxPrice: $maxPrice
      tags: $tags
      active: $active
      inactiveReasons: $inactiveReasons
      pagination: $pagination
    ) {
      listings {
        id
        title
        seller {
          id
          firstName
          lastName
        }
        shop {
          id
          name
          closed
        }
        price
        originalPrice
        discountPercentage
        priceModelType
        coverImage
        conditions
        booksCount
        active
        inactiveReason
        createdAt
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const SEARCH_USERS = gql`
  query SearhUsers(
    $query: String
    $isFollower: Boolean
    $isFollowee: Boolean
    $groupScope: String
    $votedCritiqueId: String
    $pagination: UserPaginationArgs
  ) {
    searchUsers(
      query: $query
      isFollower: $isFollower
      isFollowee: $isFollowee
      groupScope: $groupScope
      votedCritiqueId: $votedCritiqueId
      pagination: $pagination
    ) {
      users {
        id
        firstName
        lastName
        description
        isFollowee
        profilePicture
        levels {
          criticPoints
          criticLevel
          criticTitle
          bibliophilePoints
          bibliophileLevel
          bibliophileTitle
        }
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const GET_LISTINGS_IN_CART = gql`
  query SearchListings($listingIds: [String!]) {
    searchListings(listingIds: $listingIds) {
      listings {
        id
        title
        shop {
          id
          name
          logoImage
          deliveryPreferences {
            id
            type
            notes
          }
          closed
        }
        seller {
          id
          firstName
          lastName
        }
        price
        originalPrice
        discountPercentage
        priceModelType
        coverImage
        booksCount
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const GET_BOOK_REQUEST = gql`
  query GetBookRequest($id: String!) {
    bookRequest(id: $id) {
      id
      user {
        id
        firstName
        lastName
        profilePicture
        levels {
          bibliophilePoints
          bibliophileLevel
          bibliophileTitle
        }
      }
      title
      authors {
        id
        name
      }
      groups {
        id
        name
      }
      publisher
      year
      isbn
      alternativeISBNs
      language
      message
      priceModelType
      count
      contactNumbers {
        id
        phoneNumber
      }
      public
      expiresAt
      searchResultsCount
      lastCrawlAt
      createdAt
      updatedAt
    }
  }
`;

export const SEARCH_BOOK_REQUESTS = gql`
  query SearchBookRequests(
    $query: String
    $isbn: String
    $isbnList: [String!]
    $language: String
    $priceModelType: String
    $userScope: String
    $groupScope: String
    $pagination: BookRequestPaginationArgs
  ) {
    searchBookRequests(
      query: $query
      isbn: $isbn
      isbnList: $isbnList
      language: $language
      priceModelType: $priceModelType
      userScope: $userScope
      groupScope: $groupScope
      pagination: $pagination
    ) {
      bookRequests {
        id
        title
        priceModelType
        count
        expiresAt
        createdAt
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const SEARCH_SHOPS = gql`
  query SearchShops(
    $query: String
    $ownerId: String
    $isCurrentUserOwner: Boolean
    $isFollowed: Boolean
    $isFavourite: Boolean
    $location: String
    $pagination: ShopPaginationArgs
  ) {
    searchShops(
      query: $query
      ownerId: $ownerId
      isCurrentUserOwner: $isCurrentUserOwner
      isFollowed: $isFollowed
      isFavourite: $isFavourite
      location: $location
      pagination: $pagination
    ) {
      shops {
        id
        name
        addresses {
          city
        }
        logoImage
        identifier
        regions
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const GET_USER_SHOPS = gql`
  query SearchShops($ownerId: String) {
    shopsResult: searchShops(ownerId: $ownerId) {
      shops {
        id
        name
        logoImage
        identifier
      }
    }
  }
`;

export const GET_SHOPS_FOR_LISTING = gql`
  query GetShopsForListing($isCurrentUserOwner: Boolean) {
    searchShops(isCurrentUserOwner: $isCurrentUserOwner) {
      shops {
        id
        name
        addresses {
          id
        }
        contactNumbers {
          id
        }
        closed
      }
    }
  }
`;

export const GET_SHOP = gql`
  query GetShop($identifier: String!) {
    shop(identifier: $identifier) {
      id
      name
      description
      logoImage
      coverImage
      identifier
      isFollowed
      isFavourite
      owner {
        id
        firstName
        lastName
        profilePicture
      }
      addresses {
        id
        addressLine1
        addressLine2
        addressLine3
        city
      }
      contactNumbers {
        id
        phoneNumber
      }
      sections {
        id
        title
        tags
        featuredCount
        titleBackgroundColor
        contentBackgroundColor
      }
      pages {
        id
        title
        tags
      }
      deliveryPreferences {
        type
        notes
      }
      about
      regions
      closed
      deleted
    }
  }
`;

export const GET_SHOP_CONTACT_DATA = gql`
  query GetShopContactData($id: String!) {
    shop(id: $id) {
      id
      addresses {
        id
      }
      contactNumbers {
        id
      }
    }
  }
`;

export const GET_SHOP_FOR_EDITING = gql`
  query GetShop($identifier: String!) {
    shop(identifier: $identifier) {
      id
      name
      description
      logoImage
      coverImage
      identifier
      addresses {
        id
        addressLine1
        addressLine2
        addressLine3
        city
      }
      contactNumbers {
        id
        phoneNumber
      }
      deliveryPreferences {
        type
        notes
      }
      closed
      tier
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query GetAppNotifications($pagination: AppNotificationPaginationArgs) {
    getAppNotifications(pagination: $pagination) {
      notifications {
        id
        recipientId
        type
        title
        message
        read
        data {
          key
          value
        }
        createdAt
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const GET_NOTIFICATIONS_COUNT = gql`
  query getAppNotificationsCount {
    getAppNotificationsCount {
      count
    }
  }
`;

export const GET_ORDER = gql`
  query GetOrder($id: String!) {
    order(id: $id) {
      id
      totalValue
      deliveryPreference {
        id
        type
        notes
      }
      userContactNumbers {
        id
        phoneNumber
      }
      deliveryAddress {
        id
        addressLine1
        addressLine2
        addressLine3
        city
      }
      user {
        id
        firstName
        lastName
      }
      shop {
        id
        identifier
        name
        owner {
          id
        }
        logoImage
      }
      seller {
        id
        firstName
        lastName
      }
      items {
        quantity
        listing {
          id
          title
          public
          groups {
            id
            name
          }
          seller {
            id
            firstName
            lastName
          }
          shop {
            id
            name
          }
          price
          originalPrice
          discountPercentage
          priceModelType
          coverImage
          booksCount
          createdAt
        }
      }
      messageThreadId
      messagesCount
      status
      createdByUser {
        id
        firstName
        lastName
      }
      updatedByUser {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;

export const SEARCH_ORDERS = gql`
  query SearchOrders(
    $userId: String
    $shopId: String
    $sellerId: String
    $statuses: [String!]
    $pagination: OrderPaginationArgs
  ) {
    searchOrders(
      userId: $userId
      shopId: $shopId
      sellerId: $sellerId
      statuses: $statuses
      pagination: $pagination
    ) {
      orders {
        id
        totalValue
        messageThreadId
        messagesCount
        deliveryPreference {
          id
          type
          notes
        }
        userContactNumbers {
          id
          phoneNumber
        }
        deliveryAddress {
          id
          addressLine1
          addressLine2
          addressLine3
          city
        }
        user {
          id
          firstName
          lastName
        }
        shop {
          id
          identifier
          name
          logoImage
        }
        seller {
          id
          firstName
          lastName
        }
        items {
          quantity
          listing {
            id
            title
            public
            groups {
              id
              name
            }
            seller {
              id
              firstName
              lastName
            }
            shop {
              id
              name
            }
            price
            originalPrice
            discountPercentage
            priceModelType
            coverImage
            booksCount
            createdAt
          }
        }
        status
        createdByUser {
          id
          firstName
          lastName
        }
        updatedByUser {
          id
          firstName
          lastName
        }
        createdAt
        updatedAt
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const GET_ORDERED_LISTING_REVISION = gql`
  query GetOrderListingRevision($orderId: String!, $listingId: String!) {
    orderListingRevision(orderId: $orderId, listingId: $listingId) {
      order {
        id
        totalValue
        messagesCount
        deliveryPreference {
          id
          type
          notes
        }
        userContactNumbers {
          id
          phoneNumber
        }
        deliveryAddress {
          id
          addressLine1
          addressLine2
          addressLine3
          city
        }
        user {
          id
          firstName
          lastName
        }
        shop {
          id
          identifier
          name
          logoImage
        }
        seller {
          id
          firstName
          lastName
        }
        items {
          quantity
          listing {
            id
            title
            public
            groups {
              id
              name
            }
            seller {
              id
              firstName
              lastName
            }
            shop {
              id
              name
            }
            price
            originalPrice
            discountPercentage
            priceModelType
            coverImage
            booksCount
            createdAt
          }
        }
        status
        createdByUser {
          id
          firstName
          lastName
        }
        updatedByUser {
          id
          firstName
          lastName
        }
        createdAt
        updatedAt
      }
      listing {
        id
        title
        description
        public
        groups {
          id
          visibility
          name
          logoImage
        }
        isFavourite
        seller {
          id
          firstName
          lastName
          profilePicture
          levels {
            bibliophilePoints
            bibliophileLevel
            bibliophileTitle
          }
        }
        shop {
          id
          identifier
          name
          logoImage
          contactNumbers {
            id
          }
          addresses {
            id
          }
          deliveryPreferences {
            type
            notes
          }
        }
        price
        originalPrice
        discountPercentage
        priceModelType
        addresses {
          id
          addressLine1
          addressLine2
          addressLine3
          city
        }
        contactNumbers {
          id
          phoneNumber
        }
        books {
          id
          title
          subtitle
          description
          isbn
          alternativeISBNs
          url
          publisher
          pageCount
          language
          category
          condition
          authors {
            name
          }
          images
          year
        }
        tags
        notes
        active
        createdAt
      }
    }
  }
`;

export const GET_MESSAGES = gql`
  query GetMessages(
    $threadId: String!
    $inverse: Boolean
    $pagination: MessagePaginationArgs
  ) {
    messages(threadId: $threadId, inverse: $inverse, pagination: $pagination) {
      messages {
        id
        threadId
        user {
          id
          firstName
          lastName
          profilePicture
          levels {
            bibliophilePoints
            bibliophileLevel
            bibliophileTitle
          }
        }
        message
        createdAt
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const GET_MESSAGE_THREAD = gql`
  query GetMessageThread($id: String!) {
    messageThread(id: $id) {
      id
      messagesCount
      isFollowed
    }
  }
`;

export const GET_CHAT_GROUPS = gql`
  query GetChatGroups(
    $threadId: String
    $pagination: ChatGroupPaginationArgs!
  ) {
    chatGroups(threadId: $threadId, pagination: $pagination) {
      chatGroups {
        id
        threadId
        participants {
          id
          firstName
          lastName
          profilePicture
        }
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const GET_CHAT_GROUP = gql`
  query GetChatGroup($id: String!) {
    chatGroup(id: $id) {
      id
      threadId
      participants {
        id
        firstName
        lastName
        profilePicture
      }
    }
  }
`;

export const SEARCH_LISTING_COLLECTIONS = gql`
  query GetListingCollections(
    $query: String
    $ownerId: String
    $pagination: ListingCollectionPaginationArgs!
  ) {
    searchListingCollections(
      query: $query
      ownerId: $ownerId
      pagination: $pagination
    ) {
      listingCollections {
        id
        title
        description
        owner {
          id
          firstName
          lastName
          profilePicture
          levels {
            bibliophilePoints
            bibliophileLevel
            bibliophileTitle
          }
        }
        tags
        public
        coverImage
        createdAt
        updatedAt
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const GET_LISTING_COLLECTION = gql`
  query ListingCollection($id: String!) {
    listingCollection(id: $id) {
      id
      title
      description
      owner {
        id
        firstName
        lastName
        profilePicture
        levels {
          bibliophilePoints
          bibliophileLevel
          bibliophileTitle
        }
      }
      tags
      public
      coverImage
      createdAt
      updatedAt
    }
  }
`;

export const GET_TAGS_REF_DATA = gql`
  query GetTagsData($query: String, $limit: Int) {
    tags(query: $query, limit: $limit) {
      tags
    }
  }
`;

export const GET_AUTHORS_REF_DATA = gql`
  query GetAuthorsData($query: String, $limit: Int) {
    authors(query: $query, limit: $limit) {
      authors
    }
  }
`;

export const GET_PUBLISHERS_REF_DATA = gql`
  query GetPublishersData($query: String, $limit: Int) {
    publishers(query: $query, limit: $limit) {
      publishers
    }
  }
`;

export const SEARCH_CRITIQUES = gql`
  query SearchCritiques(
    $query: String
    $isbn: String
    $isbnList: [String!]
    $criticId: String
    $criticRecommends: Boolean
    $published: Boolean
    $pagination: CritiquePaginationArgs!
  ) {
    searchCritiques(
      query: $query
      isbn: $isbn
      isbnList: $isbnList
      criticId: $criticId
      criticRecommends: $criticRecommends
      published: $published
      pagination: $pagination
    ) {
      critiques {
        id
        title
        content
        book {
          title
        }
        criticRecommends
        critic {
          id
          firstName
          lastName
          profilePicture
          levels {
            criticPoints
            criticLevel
            criticTitle
          }
        }
        votesCount
        rating
        coverImage
        published
        containsSpoilers
        createdAt
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const GET_CRITIQUE = gql`
  query Critique($id: String!) {
    critique(id: $id) {
      id
      title
      content
      book {
        id
        title
        subtitle
        description
        isbn
        alternativeISBNs
        url
        publisher
        pageCount
        language
        category
        condition
        authors {
          name
        }
        images
        year
      }
      criticRecommends
      critic {
        id
        firstName
        lastName
        profilePicture
        levels {
          criticPoints
          criticLevel
          criticTitle
        }
      }
      votesCount
      hasVoted
      rating
      coverImage
      published
      containsSpoilers
      createdAt
      updatedAt
    }
  }
`;

export const GET_BOOK_INSIGHTS = gql`
  query BookInsights($isbnList: [String!]!) {
    bookInsights(isbnList: $isbnList) {
      critiquesCount
      criticRecommendationsCount
      similarListingsCount
      bookRequestsCount
      discussionsCount
    }
  }
`;

export const GET_CURATED_BOOK = gql`
  query CuratedBook($isbn: String!) {
    curatedBook(isbn: $isbn) {
      title
      subtitle
      authors {
        name
      }
      year
      description
      category
      url
      publisher
      pageCount
      language
    }
  }
`;

export const CHECK_CURATED_BOOK = gql`
  query CheckCuratedBook($isbn: String!) {
    curatedBook: checkCuratedBook(isbn: $isbn) {
      id
    }
  }
`;

export const GET_DISCUSSION = gql`
  query Discussion($id: String!) {
    discussion(id: $id) {
      id
      user {
        id
        firstName
        lastName
        profilePicture
        levels {
          bibliophilePoints
          bibliophileLevel
          bibliophileTitle
        }
      }
      group {
        id
        name
        logoImage
      }
      threadId
      repliesCount
      title
      message
      books {
        id
        title
        subtitle
        description
        isbn
        alternativeISBNs
        url
        publisher
        pageCount
        language
        category
        condition
        authors {
          name
        }
        images
        year
      }
      votesCount
      hasVoted
      closed
      createdAt
      updatedAt
    }
  }
`;

export const SEARCH_DISCUSSIONS = gql`
  query SearchDiscussions(
    $query: String
    $groupId: String
    $isbn: String
    $isbnList: [String!]
    $userId: String
    $pagination: DiscussionPaginationArgs!
  ) {
    searchDiscussions(
      query: $query
      groupId: $groupId
      isbn: $isbn
      isbnList: $isbnList
      userId: $userId
      pagination: $pagination
    ) {
      pagination {
        totalCount
      }
      discussions {
        id
        user {
          id
          firstName
          lastName
          profilePicture
          levels {
            bibliophilePoints
            bibliophileLevel
            bibliophileTitle
          }
        }
        group {
          id
          name
          logoImage
        }
        repliesCount
        title
        message
        coverImage
        hasVoted
        votesCount
        closed
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_RECOMMENDED_LISTINGS = gql`
  query RecommendedListings($listingId: String!) {
    recommendedListings(listingId: $listingId) {
      id
      title
      public
      groups {
        id
        name
      }
      seller {
        id
        firstName
        lastName
      }
      shop {
        id
        name
        closed
      }
      price
      originalPrice
      discountPercentage
      priceModelType
      coverImage
      booksCount
      active
      inactiveReason
      createdAt
    }
  }
`;

export const GET_USER_POINTS = gql`
  query UserPoints($userId: String!, $pagination: UserPointPaginationArgs!) {
    getUserPoints(userId: $userId, pagination: $pagination) {
      userPoints {
        id
        diff
        criteria
        resourceId
        createdAt
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const GET_SUGGESTED_STORIES = gql`
  query SuggestedStories($storyId: String!) {
    suggestedStories(storyId: $storyId) {
      id
      title
      subtitle
      coverImage
      tags
      genre
      averageRating
      reviewsCount
      chaptersCount
      audienceCategory
      language
      copyrightType
      matureContent
      isComplete
      published
      createdAt
      updatedAt
    }
  }
`;

export const SEARCH_STORIES = gql`
  query SearchStories(
    $query: String
    $authorId: String
    $shelfId: String
    $characters: [String!]
    $tags: [String!]
    $genres: [String!]
    $audienceCategory: String
    $language: String
    $matureContent: Boolean
    $isComplete: Boolean
    $editorsPick: Boolean
    $featured: Boolean
    $published: Boolean
    $pagination: StoryPaginationArgs
  ) {
    searchStories(
      query: $query
      authorId: $authorId
      shelfId: $shelfId
      characters: $characters
      tags: $tags
      genres: $genres
      audienceCategory: $audienceCategory
      language: $language
      matureContent: $matureContent
      isComplete: $isComplete
      editorsPick: $editorsPick
      featured: $featured
      published: $published
      pagination: $pagination
    ) {
      stories {
        id
        title
        subtitle
        abstract
        coverImage
        tags
        genre
        averageRating
        reviewsCount
        chaptersCount
        audienceCategory
        language
        copyrightType
        matureContent
        isComplete
        published
        createdAt
        updatedAt
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const GET_STORY = gql`
  query Story($id: String!) {
    story(id: $id) {
      id
      author {
        id
        firstName
        lastName
        profilePicture
        levels {
          bibliophilePoints
          bibliophileLevel
          bibliophileTitle
        }
      }
      canonicalAuthor {
        id
        firstName
        lastName
        profilePicture
        levels {
          bibliophilePoints
          bibliophileLevel
          bibliophileTitle
        }
      }
      title
      subtitle
      abstract
      characters
      coverImage
      tags
      genre
      averageRating
      reviewsCount
      chaptersCount
      currentUserReview {
        id
        rating
        review
        containsSpoilers
        createdAt
        updatedAt
      }
      currentUserReadingCheckpoint {
        id
        chapter {
          id
          chapterNumber
        }
      }
      finishedStoryReadingCheckpoint {
        id
      }
      audienceCategory
      language
      copyrightType
      matureContent
      isComplete
      featured
      editorsPick
      published
      deleted
      createdAt
      updatedAt
    }
  }
`;

export const SEARCH_STORY_CHAPTERS = gql`
  query SearchStoryChapters(
    $storyId: String!
    $published: Boolean
    $pagination: StoryChapterPaginationArgs
  ) {
    searchStoryChapters(
      storyId: $storyId
      published: $published
      pagination: $pagination
    ) {
      chapters {
        id
        story {
          id
        }
        readTimeStats {
          minutes
        }
        title
        chapterNumber
        published
        deleted
        createdAt
        updatedAt
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const GET_STORY_CHAPTER = gql`
  query StoryChapter($id: String!) {
    storyChapter(id: $id) {
      id
      story {
        id
        title
        subtitle
        author {
          id
          firstName
          lastName
          profilePicture
          levels {
            bibliophilePoints
            bibliophileLevel
            bibliophileTitle
          }
        }
        canonicalAuthor {
          id
          firstName
          lastName
          profilePicture
          levels {
            bibliophilePoints
            bibliophileLevel
            bibliophileTitle
          }
        }
        coverImage
        tags
        genre
        chaptersCount
        averageRating
        reviewsCount
        audienceCategory
        language
        published
        copyrightType
        matureContent
        isComplete
        createdAt
      }
      nextChapter {
        id
        chapterNumber
      }
      title
      chapterNumber
      averageRating
      ratingsCount
      currentUserRating {
        id
        rating
      }
      content
      commentThreadId
      published
      deleted
      createdAt
      updatedAt
    }
  }
`;

export const SEARCH_STORY_REVIEWS = gql`
  query SearchStoryReviews(
    $storyId: String
    $reviewerId: String
    $pagination: StoryReviewPaginationArgs
  ) {
    searchStoryReviews(
      storyId: $storyId
      reviewerId: $reviewerId
      pagination: $pagination
    ) {
      reviews {
        id
        story {
          id
          title
          reviewsCount
          averageRating
        }
        reviewer {
          id
          firstName
          lastName
          profilePicture
          levels {
            bibliophilePoints
            bibliophileLevel
            bibliophileTitle
          }
        }
        rating
        review
        containsSpoilers
        createdAt
        updatedAt
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const SEARCH_SHELVES = gql`
  query SearchShelves(
    $ownerId: String
    $public: Boolean
    $pagination: ShelfPaginationArgs
  ) {
    searchShelves(ownerId: $ownerId, public: $public, pagination: $pagination) {
      shelves {
        id
        name
        storiesCount
        public
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const GET_SHELF = gql`
  query Shelf($id: String!) {
    shelf(id: $id) {
      id
      user {
        id
        firstName
        lastName
        profilePicture
      }
      name
      storiesCount
      public
      createdAt
      updatedAt
    }
  }
`;

export const GET_STORY_READING_CHECKPOINT = gql`
  query StoryReadingCheckpoint($storyId: String!) {
    storyReadingCheckpoint(storyId: $storyId) {
      id
      user {
        firstName
        lastName
        profilePicture
      }
      story {
        id
        title
        chaptersCount
      }
      chapter {
        id
        title
        chapterNumber
      }
      finished
      createdAt
      updatedAt
    }
  }
`;

export const SEARCH_STORY_READING_CHECKPOINTS = gql`
  query SearchStoryReadingCheckpoints(
    $userId: String
    $finished: Boolean
    $pagination: StoryReadingCheckpointPaginationArgs
  ) {
    searchStoryReadingCheckpoints(
      userId: $userId
      finished: $finished
      pagination: $pagination
    ) {
      checkpoints {
        id
        story {
          id
          title
          subtitle
          abstract
          coverImage
          tags
          genre
          averageRating
          reviewsCount
          chaptersCount
          audienceCategory
          language
          copyrightType
          matureContent
          isComplete
          published
          createdAt
          updatedAt
        }
        chapter {
          id
          chapterNumber
          title
        }
        finished
        createdAt
        updatedAt
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const GET_RECOMMENDED_STORIES_FOR_USER = gql`
  query RecommendedStoriesForUser {
    recommendedStoriesForUser {
      id
      title
      subtitle
      abstract
      coverImage
      tags
      genre
      averageRating
      reviewsCount
      chaptersCount
      audienceCategory
      language
      copyrightType
      matureContent
      isComplete
      published
      deleted
      createdAt
      updatedAt
    }
  }
`;

export const GET_RANDOMISED_FEATURED_STORIES = gql`
  query RandomFeaturedStories {
    randomFeaturedStories {
      id
      title
      subtitle
      abstract
      coverImage
      tags
      genre
      averageRating
      reviewsCount
      chaptersCount
      audienceCategory
      language
      copyrightType
      matureContent
      isComplete
      published
      createdAt
      updatedAt
    }
  }
`;

export const GET_ADVERTISEMENT_PREFERENCES = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      id
      advertisementPreferences {
        preferredShopId
        preferredContactNumberIds
        preferredAddressIds
      }
    }
  }
`;

export const SEARCH_GLOBAL_NOTIFICATIONS = gql`
  query SearchGlobalNotifications(
    $senderId: String
    $pagination: GlobalAppNotificationPaginationArgs
  ) {
    searchGlobalAppNotifications(senderId: $senderId, pagination: $pagination) {
      globalAppNotifications {
        sender {
          id
          firstName
          lastName
          levels {
            bibliophilePoints
            bibliophileLevel
            bibliophileTitle
          }
        }
        type
        title
        message
        data {
          key
          value
        }
        receivedCount
        clicksCount
        createdAt
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const CHECK_SHOP_IDENTIFIER_AVAILABILITY = gql`
  query CheckIdentifierAvailability(
    $identifier: String!
    $currentIdentifier: String
  ) {
    availabilityResult: checkIdentifierAvailability(
      identifier: $identifier
      currentIdentifier: $currentIdentifier
    ) {
      available
    }
  }
`;

export const GET_FEED = gql`
  query Feed($pagination: FeedPaginationArgs!) {
    getFeed(pagination: $pagination) {
      feed {
        type
        category
        subjectUserId
        objectEntityId
        actor {
          id
          name
          image
        }
        images
        key
        primaryContent {
          images
          data {
            key
            value
          }
        }
        secondaryContent {
          images
          data {
            key
            value
          }
        }
        activityTimestamp
      }
      pagination {
        totalCount
      }
    }
  }
`;

export const GET_APP_NOTIFICATION_BY_ID = gql`
  query GetAppNotificationById($notificationId: String) {
    getAppNotificationById(notificationId: $notificationId) {
      id
      recipientId
      type
      title
      message
      read
      data {
        key
        value
      }
      createdAt
    }
  }
`;
