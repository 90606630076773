import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Link,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Hyperlink from "../../Common/Hyperlink";
import FacebookShareButton from "../../Common/FacebookShareButton";
import ImageFadeIn from "../../Common/ImageFadeIn";
import { Rating } from "@material-ui/lab";
import { amber, green } from "@material-ui/core/colors";
import UserAvatar from "../../Common/UserAvatar";
import {
  copyRightTypes,
  COPYRIGHT_DESCRIPTIONS,
  storyAudienceTypes,
  STORY_GENRES,
} from "../../consts";
import {
  Category as CategoryIcon,
  Copyright as CopyrightIcon,
  Explicit as MatureIcon,
  Language as LanguageIcon,
  SupervisorAccount as AudienceIcon,
} from "@material-ui/icons";
import StoryReporter from "../StoryReporter";
import { useMutation } from "@apollo/client";
import { DELETE_STORY_READING_CHECKPOINT } from "../../../consts/mutations";
import {
  GET_STORY,
  SEARCH_STORY_READING_CHECKPOINTS,
} from "../../../consts/queries";
import {
  useDispatchErrorMessage,
  useLoggedInUser,
  useLoginPrompt,
} from "../../../utils/hooks";
import { STORY_READING_CHECKPOINTS_PAGINATION_LIMIT } from "../../../config";
import { t, Trans } from "@lingui/macro";
import { getLanguageText } from "../../../utils/common";

const useStyles = makeStyles((theme) => ({
  coverImageItem: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  coverImageContainer: {
    justifyContent: "center",
  },
  coverImage: {
    maxHeight: 400,
    maxWidth: "100%",
    objectFit: "contain",
    borderRadius: theme.spacing(2),
    boxShadow: `4px 4px ${theme.palette.grey[300]}`,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 18,
  },
  metadata: {
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  reviewCount: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  abstract: {
    fontSize: 14,
    whiteSpace: "pre-wrap",
  },
  completeBadge: {
    fontWeight: "bold",
    color: green[600],
  },
  checkpointChapterNumber: {
    fontSize: "12px",
    textTransform: "none",
  },
  checkpointText: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  tag: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
  },
  tagText: {
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    fontSize: "12px",
  },
  storyMetadataIcon: {
    fontSize: 16,
    color: theme.palette.text.primary,
    marginRight: theme.spacing(0.5),
  },
  matureContentIcon: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(0.5),
    fontSize: 16,
  },
  matureContentText: {
    color: theme.palette.secondary.main,
  },
  matureTitleIcon: {
    fontSize: 16,
    marginLeft: theme.spacing(0.5),
  },
  storyCardActions: {
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
  },
  deleteCheckpointButton: {
    cursor: "pointer",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  checkpointButton: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 250,
    },
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  startReadButton: {
    width: "100%",
  },
  unpublishedBadge: {
    fontSize: 12,
    color: amber[900],
    fontWeight: "bold",
    backgroundColor: amber[100],
    padding: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginLeft: theme.spacing(1),
    borderRadius: theme.spacing(2),
    cursor: "default",
  },
}));

export default function LargeStoryCard({
  story,
  clickable,
  readingCheckpoint,
  finishedCheckpoint,
  showFacebookShareButton,
  showAuthor,
  showReadButton,
  showReportButton,
  showCopyrightNotice,
}) {
  const classes = useStyles();
  const loggedInUser = useLoggedInUser();
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const dispatchErrorMessage = useDispatchErrorMessage();
  const copyrightType = copyRightTypes.find(
    (c) => c.value === story.copyrightType
  )?.label;
  const audienceCategory = storyAudienceTypes.find(
    (a) => a.value === story.audienceCategory
  )?.label;
  const genre = STORY_GENRES[story.genre].text;
  const [deleteCheckpoint] = useMutation(DELETE_STORY_READING_CHECKPOINT);

  const deleteStoryCheckpoint = (event) => {
    event.preventDefault();
    deleteCheckpoint({
      variables: {
        storyId: story.id,
      },
      refetchQueries: [
        {
          query: GET_STORY,
          variables: {
            id: story.id,
          },
        },
        {
          query: SEARCH_STORY_READING_CHECKPOINTS,
          variables: {
            finished: false,
            userId: loggedInUser?.id,
            pagination: {
              limit: STORY_READING_CHECKPOINTS_PAGINATION_LIMIT,
              offset: 0,
            },
          },
        },
      ],
    }).catch(() => {
      dispatchErrorMessage(
        t`Unable to perform this action at the moment. Please try again later`
      );
    });
  };

  const onClickReadStory = createLoginPromptEventHandler({
    redirectPath: readingCheckpoint?.chapter
      ? `/story/${story.id}/chapter/${readingCheckpoint.chapter.id}`
      : `/story/${story.id}`,
  });

  const wrapAsChapterLinkButton = (component) => {
    const currentChapterId = readingCheckpoint.chapter?.id;
    if (!currentChapterId) return null;
    return (
      <Grid item xs={12}>
        <Grid container>
          <Button
            variant="contained"
            color="default"
            disableElevation
            disableRipple
            component={Hyperlink}
            onClick={onClickReadStory}
            className={classes.checkpointButton}
            to={`/story/${story.id}/chapter/${currentChapterId}`}
          >
            {component}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const getCheckpointButton = () => {
    if (!readingCheckpoint) return null;
    const currentChapterId = readingCheckpoint.chapter?.id;
    const currentChapterNumber = readingCheckpoint.chapter?.chapterNumber;
    if (!currentChapterId) return null;

    if (!readingCheckpoint.id) {
      // entry checkpoint
      return wrapAsChapterLinkButton(
        <Typography
          className={classes.checkpointText}
          variant="subtitle2"
          color="textSecondary"
        >
          {finishedCheckpoint ? (
            <Trans>Read Again</Trans>
          ) : (
            <Trans>Start Reading</Trans>
          )}
        </Typography>
      );
    }

    return (
      <Grid container spacing={1}>
        {wrapAsChapterLinkButton(
          <Grid item>
            <Grid container>
              {currentChapterNumber && (
                <Grid item xs={12}>
                  <Typography
                    className={classes.checkpointChapterNumber}
                    variant="body2"
                    color="textSecondary"
                  >
                    <Trans>You are at Chapter {currentChapterNumber}</Trans>
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography
                  className={classes.checkpointText}
                  variant="subtitle2"
                  color="textSecondary"
                >
                  <Trans>Continue Reading</Trans>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container>
            <Link
              color="textSecondary"
              onClick={deleteStoryCheckpoint}
              className={classes.deleteCheckpointButton}
            >
              <Typography variant="caption">
                <Trans>I don't read this anymore</Trans>
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const storyCard = (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={3} xl={3} className={classes.coverImageItem}>
        <Grid container className={classes.coverImageContainer}>
          <Grid item>
            <ImageFadeIn
              src={`${story.coverImage}/lg`}
              className={classes.coverImage}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={9} xl={9}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.title}
                      >
                        {story.title}
                      </Typography>
                      {story.matureContent && (
                        <Tooltip title={t`This story contains mature content.`}>
                          <MatureIcon
                            color="secondary"
                            className={classes.matureTitleIcon}
                          />
                        </Tooltip>
                      )}
                      {!story.published && (
                        <Tooltip
                          title={t`This story is not published, and only you can see the story content.`}
                        >
                          <span className={classes.unpublishedBadge}>
                            <Trans>Not Published</Trans>
                          </span>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.subtitle}
                    >
                      {story.subtitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.metadata}
                    >
                      <Trans>{story.chaptersCount} Chapters &bull;</Trans>{" "}
                      {story.isComplete ? (
                        <span className={classes.completeBadge}>
                          <Trans>Complete</Trans>
                        </span>
                      ) : (
                        <Trans>Ongoing</Trans>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <Grid container alignItems="center">
                          <Rating
                            readOnly
                            size="small"
                            value={story.averageRating}
                            precision={0.1}
                          />
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="center">
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            className={classes.reviewCount}
                          >
                            <Trans>
                              {story.reviewsCount} Rating
                              {story.reviewsCount === 1 ? "" : "s"}
                            </Trans>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    className={classes.abstract}
                  >
                    {story.abstract}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <CategoryIcon className={classes.storyMetadataIcon} />
                      <Typography color="textPrimary" variant="subtitle2">
                        {genre}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <LanguageIcon className={classes.storyMetadataIcon} />
                      <Typography color="textPrimary" variant="subtitle2">
                        {getLanguageText(story.language)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Tooltip title={t`Target audience of the story`}>
                      <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <AudienceIcon className={classes.storyMetadataIcon} />
                        <Typography color="textPrimary" variant="subtitle2">
                          {audienceCategory}
                        </Typography>
                      </Grid>
                    </Tooltip>
                  </Grid>
                  {story.matureContent && (
                    <Grid item>
                      <Tooltip
                        title={t`This story's content is not suitable for younger audience`}
                      >
                        <Grid
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <MatureIcon className={classes.matureContentIcon} />
                          <Typography
                            className={classes.matureContentText}
                            color="textPrimary"
                            variant="subtitle2"
                          >
                            <Trans>Mature Content</Trans>
                          </Typography>
                        </Grid>
                      </Tooltip>
                    </Grid>
                  )}
                  <Grid item>
                    <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <CopyrightIcon className={classes.storyMetadataIcon} />
                      <Typography color="textPrimary" variant="subtitle2">
                        {copyrightType}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {showCopyrightNotice &&
                COPYRIGHT_DESCRIPTIONS[story.copyrightType] && (
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary">
                      {COPYRIGHT_DESCRIPTIONS[story.copyrightType]}
                    </Typography>
                  </Grid>
                )}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {story.tags.map((tag) => (
                        <Grid item key={tag}>
                          <Hyperlink to={`/stories/search?tags=${tag}`}>
                            <Grid container className={classes.tag}>
                              <Typography
                                className={classes.tagText}
                                variant="body2"
                              >
                                {tag}
                              </Typography>
                            </Grid>
                          </Hyperlink>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  {showAuthor && (
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        {story.canonicalAuthor && (
                          <Grid item>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography variant="caption">
                                  <Trans>Written by</Trans>
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <UserAvatar user={story.canonicalAuthor} />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {story.author && (
                          <Grid item>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography variant="caption">
                                  {story.canonicalAuthor
                                    ? t`Published by`
                                    : t`Written by`}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <UserAvatar user={story.author} />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              {showReadButton && (
                <Grid item className={classes.startReadButton}>
                  {getCheckpointButton()}
                </Grid>
              )}
              <Grid item>
                <Grid container spacing={2}>
                  {showFacebookShareButton && (
                    <Grid item>
                      <FacebookShareButton />
                    </Grid>
                  )}
                  {showReportButton && (
                    <Grid item>
                      <StoryReporter story={story} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
  return clickable ? (
    <Hyperlink to={`/story/${story.id}`}>{storyCard}</Hyperlink>
  ) : (
    storyCard
  );
}

LargeStoryCard.propTypes = {
  story: PropTypes.object.isRequired,
  readingCheckpoint: PropTypes.object,
  finishedCheckpoint: PropTypes.object,
  clickable: PropTypes.bool,
  showFacebookShareButton: PropTypes.bool,
  showCopyrightNotice: PropTypes.bool,
};

LargeStoryCard.defaultProps = {
  clickable: true,
  showAuthor: true,
  showReadButton: true,
};
