import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import InfoIcon from "@material-ui/icons/Info";
import SupportIcon from "@material-ui/icons/ContactSupport";
import ConfirmPrompt from "./ConfirmPrompt";
import { useHistory, Link } from "react-router-dom";
import {
  Grid,
  Button,
  MenuItem,
  Menu,
  Divider,
  Typography,
  IconButton,
  Badge,
  Tooltip,
  ButtonGroup,
} from "@material-ui/core";
import { LOGOUT } from "../../Auth/auth.actionTypes";
import UserIcon from "@material-ui/icons/Person";
import BookIcon from "@material-ui/icons/Book";
import AdminIcon from "@material-ui/icons/SettingsApplications";
import { GET_USER } from "../../consts/queries";
import Hyperlink from "./Hyperlink";
import Notifications from "../Notifications";
import CartIcon from "@material-ui/icons/ShoppingCart";
import OrdersIcon from "@material-ui/icons/ShoppingBasket";
import HomeIcon from "@material-ui/icons/Home";
import { Trans } from "@lingui/macro";
import { useCookies } from "react-cookie";
import {
  BC_CART_COOKIE_NAME,
  BC_AUTH_COOKIE_NAME,
  BC_LANGUAGE_COOKIE_NAME,
  IS_TEST_ENVIRONMENT,
  BACKEND_URL,
  GENERIC_ALERT_ERROR_MESSAGE,
} from "../../config";
import BookRequestIcon from "../Requests/BookRequestIcon";
import ImageFadeIn from "./ImageFadeIn";
import {
  useDispatchErrorMessage,
  useLoggedInUser,
  useLoginPrompt,
} from "../../utils/hooks";
import { USER_ROLES } from "../consts";
import { actionTypes } from "../index.reducer";
import { useLazyQuery } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: IS_TEST_ENVIRONMENT
      ? theme.palette.gradient.navbarTest
      : theme.palette.gradient.navbar,
    boxShadow: "none",
    zIndex: 999,
    padding: 0,
  },
  toolbar: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  logoLink: {
    display: "flex",
    alignItems: "center",
    height: "64px",
  },
  logo: {
    maxHeight: "70%",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "50%",
    },
  },
  logoContainer: {
    maxWidth: "50%",
    height: "100%",
    position: "absolute",
  },
  mobileOnly: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  xsOnly: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  loggedInUser: {
    fontSize: "18px",
    marginRight: "10px",
  },
  loggedInUserLastName: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  createListingBtn: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.getContrastText(theme.palette.grey[800]),
    marginRight: theme.spacing(1),
  },
  userMenu: {
    "& *": {
      fontSize: "14px",
    },
  },
  menuIcon: {
    marginRight: theme.spacing(1),
  },
  menuDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  logoutButtonText: {
    fontWeight: "bold",
  },
  appBarButton: {
    [theme.breakpoints.up("xl")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(0.5),
    },
  },
  quickLinks: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  anchorMenuItem: {
    color: theme.palette.text.primary,
    textDecoration: "none",
  },
  icon: {
    fontSize: 16,
  },
  languageSelect: {
    "& *": {
      fontWeight: "bold",
    },
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  languageSelectButton: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.getContrastText(theme.palette.grey[800]),
  },
  helpButton: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

export default function NavigationBar() {
  const classes = useStyles();
  const history = useHistory();
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const loggedInUser = useLoggedInUser();
  const isAdmin = loggedInUser?.role === USER_ROLES.ADMINISTRATOR;
  const redirectPath = window.location.href.replace(window.location.origin, "");
  const loginPath = `/login?redirectPath=${encodeURIComponent(redirectPath)}`;
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies([
    BC_AUTH_COOKIE_NAME,
    BC_CART_COOKIE_NAME,
    BC_LANGUAGE_COOKIE_NAME,
  ]);
  const dispatch = useDispatch();
  const dispatchErrorMessage = useDispatchErrorMessage();
  const [dropDownAnchorEl, setDropDownAnchorEl] = useState(null);
  const [logoutDecisionPending, setLogoutDecisionPending] = useState(false);
  const [fetchUser, { data }] = useLazyQuery(GET_USER);
  useEffect(() => {
    if (loggedInUser) {
      fetchUser({
        variables: { id: loggedInUser.id },
      });
    }
  }, [loggedInUser, fetchUser]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const showLogoutPrompt = () => setLogoutDecisionPending(true);

  const createMenuAction =
    (actionFn) =>
    (...args) => {
      handleCloseUserMenu();
      actionFn(...args);
    };
  const handleLogoutDecision = createMenuAction((accept) => {
    setLogoutDecisionPending(false);
    if (accept) {
      removeCookie(BC_CART_COOKIE_NAME, { path: "/" });
      dispatch({
        type: actionTypes.LOADING,
        status: true,
      });
      (async () => {
        try {
          const res = await fetch(`${BACKEND_URL}/auth/logout`, {
            method: "post",
            credentials: "include",
          });
          if (res.status !== 200) {
            dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
          } else {
            dispatch({ type: LOGOUT });
            history.push("/");
          }
        } catch {
          dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
        }
        dispatch({
          type: actionTypes.LOADING,
          status: false,
        });
      })();
    }
  });

  const closeMenu = () => handleCloseUserMenu();
  const handleOpenUserMenu = (event) =>
    setDropDownAnchorEl(event.currentTarget);
  const handleCloseUserMenu = () => setDropDownAnchorEl(null);

  const handleLanguageSelect = (language) => {
    setCookie(BC_LANGUAGE_COOKIE_NAME, language, {
      path: "/",
      domain: window.location.hostname,
    });
  };
  const handleClickCreateAd = createLoginPromptEventHandler({
    redirectPath: "/listing/create",
  });

  const contactLinks = (
    <MenuItem component={Hyperlink} to="/info/about" onClick={closeMenu}>
      <SupportIcon className={classes.menuIcon} />
      <Trans>Contact Us</Trans>
    </MenuItem>
  );

  const privacyPolicy = (
    <MenuItem
      component={Hyperlink}
      to="/info/privacy-policy"
      onClick={closeMenu}
    >
      <InfoIcon className={classes.menuIcon} />
      <Trans>Privacy Policy</Trans>
    </MenuItem>
  );

  const cookiePolicy = (
    <MenuItem
      component={Hyperlink}
      to="/info/cookie-policy"
      onClick={closeMenu}
    >
      <InfoIcon className={classes.menuIcon} />
      <Trans>Cookie Policy</Trans>
    </MenuItem>
  );

  const languageSelection = [
    <Button
      disableElevation
      disableRipple
      className={classes.languageSelectButton}
      variant="contained"
      color="default"
      onClick={() => handleLanguageSelect("en")}
    >
      A
    </Button>,
    <Button
      disableElevation
      disableRipple
      className={classes.languageSelectButton}
      variant="contained"
      color="default"
      onClick={() => handleLanguageSelect("si")}
    >
      අ
    </Button>,
    <Tooltip title="We are eagerly working on translating bibliocircle to Tamil. Please check again later!">
      <Button
        disableElevation
        disableRipple
        className={classes.languageSelectButton}
        variant="contained"
        color="default"
        onClick={() => handleLanguageSelect("ta")}
      >
        அ
      </Button>
    </Tooltip>,
  ];

  const userSection = loggedInUser ? (
    <>
      <Button
        disableRipple
        endIcon={<ArrowDropDownIcon />}
        color="inherit"
        onClick={handleOpenUserMenu}
      >
        <div>
          <span>{data?.user?.firstName}</span>
          <span className={classes.loggedInUserLastName}>
            {" "}
            {data?.user?.lastName}
          </span>
        </div>
      </Button>
      <Menu
        anchorEl={dropDownAnchorEl}
        keepMounted
        open={!!dropDownAnchorEl}
        onClose={handleCloseUserMenu}
        className={classes.userMenu}
        disableScrollLock={true}
      >
        <MenuItem
          component={Hyperlink}
          to={`/profile/${loggedInUser?.id || ""}`}
          onClick={closeMenu}
        >
          <UserIcon className={classes.menuIcon} /> <Trans>My Profile</Trans>
        </MenuItem>
        <MenuItem
          component={Hyperlink}
          to={`/profile/${loggedInUser?.id || ""}/page/outgoing-orders`}
          onClick={closeMenu}
        >
          <OrdersIcon className={classes.menuIcon} />{" "}
          <Trans>Orders I placed</Trans>
        </MenuItem>
        <MenuItem
          component={Hyperlink}
          to={`/profile/${loggedInUser?.id || ""}/page/incoming-orders`}
          onClick={closeMenu}
        >
          <OrdersIcon className={classes.menuIcon} />{" "}
          <Trans>Orders I received</Trans>
        </MenuItem>
        <MenuItem
          component={Hyperlink}
          to={`/profile/${loggedInUser?.id || ""}/page/listings`}
          onClick={closeMenu}
        >
          <BookIcon className={classes.menuIcon} />{" "}
          <Trans>My Advertisements</Trans>
        </MenuItem>
        <MenuItem
          component={Hyperlink}
          to={`/profile/${loggedInUser?.id || ""}/page/book-requests`}
          onClick={closeMenu}
        >
          <BookRequestIcon className={classes.menuIcon} />{" "}
          <Trans>My Book Requests</Trans>
        </MenuItem>
        <Divider variant="middle" className={classes.menuDivider} />
        <MenuItem disableRipple className={classes.xsOnly}>
          {languageSelection}
        </MenuItem>
        {privacyPolicy}
        {cookiePolicy}
        {contactLinks}
        {isAdmin && [
          <Divider variant="middle" className={classes.menuDivider} />,
          <MenuItem component={Hyperlink} to="/admin" onClick={closeMenu}>
            <AdminIcon className={classes.menuIcon} /> <Trans>Admin</Trans>
          </MenuItem>,
        ]}
        <Divider variant="middle" className={classes.menuDivider} />
        <MenuItem onClick={showLogoutPrompt}>
          <Typography color="secondary" className={classes.logoutButtonText}>
            <Trans>Log out</Trans>
          </Typography>
        </MenuItem>
      </Menu>
    </>
  ) : (
    <>
      <Button
        disableRipple
        endIcon={<ArrowDropDownIcon />}
        color="inherit"
        onClick={handleOpenUserMenu}
        className={classes.helpButton}
      >
        Help
      </Button>
      <Menu
        anchorEl={dropDownAnchorEl}
        keepMounted
        open={!!dropDownAnchorEl}
        onClose={handleCloseUserMenu}
        className={classes.userMenu}
        disableScrollLock={true}
      >
        <MenuItem disableRipple className={classes.xsOnly}>
          {languageSelection}
        </MenuItem>
        {privacyPolicy}
        {cookiePolicy}
        {contactLinks}
      </Menu>
      <Button
        disableRipple
        component={Hyperlink}
        to={loginPath}
        color="inherit"
      >
        Login / Sign Up
      </Button>
    </>
  );

  return (
    <div className={classes.navigationBar}>
      <ConfirmPrompt
        open={logoutDecisionPending}
        onAction={handleLogoutDecision}
        question="Are you sure you want to log out?"
      />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar disableGutters className={classes.toolbar}>
          <Grid
            container
            alignContent="center"
            className={classes.logoContainer}
          >
            <Link to="/" className={classes.logoLink} onClick={scrollToTop}>
              <ImageFadeIn
                src="/logo/logo_inverted.svg"
                alt="bibliocircle"
                className={classes.logo}
              />
            </Link>
          </Grid>
          <Grid item className={classes.grow}></Grid>
          <ButtonGroup
            disableElevation
            disableRipple
            className={classes.languageSelect}
            variant="contained"
            color="primary"
          >
            {languageSelection}
          </ButtonGroup>
          <div className={classes.quickLinks}>
            <Button
              onClick={handleClickCreateAd}
              disableRipple
              disableElevation
              component={Hyperlink}
              to="/listing/create"
              variant="contained"
              className={classes.createListingBtn}
            >
              <Trans>Create Ad</Trans>
            </Button>
          </div>
          <Tooltip title="Search">
            <IconButton
              aria-label="search books"
              color="inherit"
              component={Hyperlink}
              to="/"
              onClick={scrollToTop}
              className={classes.mobileOnly}
            >
              <HomeIcon />
            </IconButton>
          </Tooltip>
          {loggedInUser && <Notifications />}
          <Tooltip title="Shopping Cart">
            <IconButton
              aria-label="show new notifications"
              color="inherit"
              component={Hyperlink}
              to="/shoppingcart"
              className={classes.appBarButton}
            >
              <Badge
                badgeContent={
                  Array.isArray(cookies[BC_CART_COOKIE_NAME])
                    ? cookies[BC_CART_COOKIE_NAME].length
                    : 0
                }
                color="secondary"
              >
                <CartIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          {userSection}
        </Toolbar>
      </AppBar>
    </div>
  );
}

NavigationBar.propTypes = {
  logo: PropTypes.bool.isRequired,
};

NavigationBar.defaultProps = {
  logo: true,
};
