import React, { useCallback, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import ClearNotifications from "@material-ui/icons/ClearAll";
import {
  IconButton,
  Badge,
  Grid,
  Typography,
  makeStyles,
  ClickAwayListener,
  Fade,
  Tooltip,
  Button,
  Menu,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationCard from "./NotificationCard";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_COUNT,
} from "../../consts/queries";
import {
  NOTIFICATIONS_PAGINATION_LIMIT,
  NOTIFICATIONS_FETCH_INTERVAL_MS,
} from "../../config";
import { NotificationsLoader } from "../Common/LoadingPlaceholders/NotificationsLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  UPDATE_APP_NOTIFICATION,
  MARK_ALL_NOTIFICATIONS_AS_READ,
} from "../../consts/mutations";
import {
  useDispatchErrorMessage,
  useDispatchInfoMessage,
} from "../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  notificationsContainer: {
    display: "flex",
    maxHeight: "50vh",
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
    width: "380px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
      backgroundColor: "green",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.primary.extraLighter,
    },
    "&::-webkit-scrollbar-thumb": {
      width: "3px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(1),
    },
  },
  endOfNotifications: {
    padding: theme.spacing(1),
    width: "100%",
    height: "40px",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  grow: {
    flex: 1,
  },
  notificationsMenu: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(6),
    },
    marginTop: theme.spacing(5),
    zIndex: 999999,
    "& ul": {
      padding: 0,
    },
  },
  markAsReadButton: {
    borderRadius: 0,
  },
}));

export default function Notifications({ className }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatchErrorMessage = useDispatchErrorMessage();
  const dispatchInfoMessage = useDispatchInfoMessage();
  const [
    fetchNotifications,
    { loading: fetchingNotifications, data, fetchMore },
  ] = useLazyQuery(GET_NOTIFICATIONS, {
    variables: {
      pagination: {
        limit: NOTIFICATIONS_PAGINATION_LIMIT,
        offset: 0,
      },
    },
  });
  const [fetchNotificationsCount, { data: notificationsCountData }] =
    useLazyQuery(GET_NOTIFICATIONS_COUNT);
  const [updateAppNotification] = useMutation(UPDATE_APP_NOTIFICATION);
  const [markAllNotificationsAsRead, { loading: markingNotificationsRead }] =
    useMutation(MARK_ALL_NOTIFICATIONS_AS_READ);

  const refreshNotifications = useCallback(() => {
    fetchNotifications();
    fetchNotificationsCount();
  }, [fetchNotifications, fetchNotificationsCount]);

  useEffect(() => {
    refreshNotifications();
    setInterval(refreshNotifications, NOTIFICATIONS_FETCH_INTERVAL_MS);
  }, [refreshNotifications]);

  const { getAppNotifications } = data || {};

  const { notifications = [], pagination = { totalCount: 0 } } =
    getAppNotifications || {};
  const totalNotificationsCount =
    notificationsCountData?.getAppNotificationsCount?.count;

  const loadMoreNotifications = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: NOTIFICATIONS_PAGINATION_LIMIT,
          offset: notifications.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          getAppNotifications: {
            __typename: prev.getAppNotifications.__typename,
            notifications: [
              ...prev.getAppNotifications.notifications,
              ...fetchMoreResult.getAppNotifications.notifications,
            ],
            pagination: fetchMoreResult.getAppNotifications.pagination,
          },
        });
      },
    }).catch(() => {});
  };

  const hasMore = () => {
    return notifications.length < pagination.totalCount;
  };

  const noNotifications = (
    <Grid
      container
      className={classes.endOfNotifications}
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="caption" align="center">
        You have no new notifications
      </Typography>
    </Grid>
  );

  const onClickNotification = (notificationId) => () => {
    updateAppNotification({
      variables: {
        notification: {
          id: notificationId,
          read: true,
          clicked: true,
        },
      },
      refetchQueries: [
        {
          query: GET_NOTIFICATIONS_COUNT,
        },
      ],
    }).catch(() => {});
  };

  const handleMarkNotificationsRead = () => {
    markAllNotificationsAsRead()
      .then(({ data }) => {
        refreshNotifications();
        const notificationsCount = data?.markAllNotificationsRead?.count || 0;
        dispatchInfoMessage(
          `${notificationsCount} notifications were marked as read!`
        );
      })
      .catch(() => {
        dispatchErrorMessage(
          `Something went wrong while marking notifications as read! Please try again later.`
        );
      });
  };

  const toggleOpenNotifications = (event) => {
    setAnchorEl((el) => (el ? null : event.currentTarget));
  };

  const handleCloseNotifications = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseNotifications}>
      <div>
        <Tooltip title="Notifications">
          <IconButton
            aria-label="show new notifications"
            color="inherit"
            className={className}
            onClick={toggleOpenNotifications}
          >
            <Badge
              badgeContent={
                totalNotificationsCount > 0 ? totalNotificationsCount : 0
              }
              color="secondary"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <Menu
          className={classes.notificationsMenu}
          open={!!anchorEl}
          anchorEl={anchorEl}
          disableScrollLock
          onClose={handleCloseNotifications}
        >
          <Fade in={true}>
            <>
              <Box
                onClick={handleCloseNotifications}
                className={classes.notificationsContainer}
              >
                <InfiniteScroll
                  style={{ overflow: "none !important" }}
                  scrollableTarget="notificationsContainer"
                  dataLength={notifications.length}
                  next={loadMoreNotifications}
                  hasMore={hasMore()}
                  loader={<NotificationsLoader />}
                >
                  {fetchingNotifications && !notifications.length && (
                    <NotificationsLoader />
                  )}
                  {notifications.map((notification) => (
                    <NotificationCard
                      key={notification.id}
                      notification={notification}
                      onClick={onClickNotification(notification.id)}
                    />
                  ))}
                  {!notifications.length && noNotifications}
                </InfiniteScroll>
              </Box>
              {!!notifications.length && (
                <Box>
                  <Button
                    fullWidth
                    className={classes.markAsReadButton}
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={handleMarkNotificationsRead}
                    disableRipple
                    startIcon={<ClearNotifications />}
                    disabled={markingNotificationsRead}
                  >
                    Clear Notifications
                  </Button>
                </Box>
              )}
            </>
          </Fade>
        </Menu>
      </div>
    </ClickAwayListener>
  );
}
