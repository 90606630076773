import React, { useState } from "react";
import {
  Container,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { Route, Switch, useHistory } from "react-router-dom";
import { useQueryParams, useScrollTop } from "../../utils/hooks";
import BootstrapInput from "../Common/BootstrapInput";
import SearchIcon from "@material-ui/icons/Search";
import { t, Trans } from "@lingui/macro";
import PageHead from "../Common/PageHead";
import ImageFadeIn from "../Common/ImageFadeIn";
import PageTitle from "../Common/PageTitle";
import StoriesHomePageContent from "./StoriesHomePageContent";
import StorySearch from "./Story/StorySearch";
import StoriesActionsPanel from "./StoriesActionsPanel";

const useStyles = makeStyles((theme) => ({
  storiesDiscoverer: {
    marginTop: theme.spacing(3),
  },
  logo: {
    maxHeight: theme.spacing(6) * 2,
  },
  storiesSearchBox: {
    width: "100%",
  },
  pageTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  storiesSearchBoxInput: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    "&::placeholder": {
      color: theme.palette.primary.main,
    },
  },
  storiesSearchContainer: {
    marginBottom: theme.spacing(2),
  },
  featuredSectionTitle: {
    textAlign: "center",
    fontWeight: "bold",
  },
  highlightsSection: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  categoryDiscoverer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export default function Stories() {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = useQueryParams();
  const [searchQuery, setSearchQuery] = useState("");

  useScrollTop([history.location.key]);

  const searchStories = () => {
    const queryParams = {};
    if (searchQuery) queryParams.q = searchQuery;
    const queryString = new URLSearchParams(queryParams).toString();
    history.push(`/stories/search?${queryString}`);
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) searchStories();
  };
  const onChangeSearchQuery = (event) => setSearchQuery(event.target.value);

  const storiesSearchBar = (
    <Grid
      container
      justifyContent="center"
      className={classes.storiesSearchContainer}
    >
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={9}>
            <Paper>
              <BootstrapInput
                name="q"
                className={classes.storiesSearchBox}
                defaultValue={queryParams.get("q")}
                placeholder={t`Search stories`}
                onChange={onChangeSearchQuery}
                onKeyDown={onKeyDown}
                endAdornment={
                  <InputAdornment>
                    <IconButton
                      color="primary"
                      variant="contained"
                      onClick={searchStories}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              ></BootstrapInput>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Container maxWidth="xl">
      <PageHead title="Stories | bibliocircle" />
      <Grid
        container
        justifyContent="center"
        className={classes.storiesDiscoverer}
      >
        <Grid container item xs={12} spacing={1}>
          <Grid container item xs={12} className={classes.pageTitle}>
            <Grid container spacing={2} alignItems="center" direction="column">
              <Grid item>
                <Grid container justifyContent="center">
                  <ImageFadeIn
                    src="/logo/logo_stories.svg"
                    alt="Stories"
                    className={classes.logo}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <PageTitle variant="subtitle1" align="center">
                  <Trans>Unleash your imagination like never before...</Trans>
                </PageTitle>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={10}>
                {storiesSearchBar}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <StoriesActionsPanel />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Switch>
              <Route path="/stories/search">
                <StorySearch />
              </Route>
              <Route>
                <StoriesHomePageContent />
              </Route>
            </Switch>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
