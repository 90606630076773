import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Hyperlink from "../../Application/Common/Hyperlink";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  plainLink: {
    color: "white",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linkText: {
    fontWeight: "bold",
    fontSize: "18px",
    padding: theme.spacing(1),
  },
  signUpButton: {
    color: theme.palette.background.paper,
    backgroundColor: green[500],
    border: "none",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}));

export default function SignUpLink({ redirectPath }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={11}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Typography
              align="center"
              variant="h6"
              className={classes.linkText}
            >
              Do not have an account?
            </Typography>
          </Grid>
          <Grid item>
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  size="small"
                  component={Hyperlink}
                  to={{
                    pathname: "/signup",
                    search: redirectPath
                      ? `?redirectPath=${encodeURIComponent(redirectPath)}`
                      : null,
                    state: history.location.state,
                  }}
                  disableElevation
                  disableRipple
                  variant="contained"
                  className={classes.signUpButton}
                >
                  Create new account
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

SignUpLink.propTypes = {
  redirectPath: PropTypes.string,
};

SignUpLink.defaultProps = {
  redirectPath: "/",
};
