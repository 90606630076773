import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useQueryParams } from "../../../utils/hooks";
import StoriesSearchController from "./StoriesSearchController";

export default function StorySearch() {
  const tokeniseQueryParams = (str) => {
    return (str || "")
      .split(",")
      .map((token) => token.trim())
      .filter((token) => !!token);
  };
  const queryParams = useQueryParams();

  const searchArgs = {
    published: true,
    query: queryParams.get("q"),
    authorId: queryParams.get("authorId"),
    storyLibraryId: queryParams.get("storyLibraryId"),
    audienceCategory: queryParams.get("audienceCategory"),
    language: queryParams.get("language"),
  };

  if (queryParams.get("characters")) {
    searchArgs.characters = tokeniseQueryParams(queryParams.get("characters"));
  }
  if (queryParams.get("tags")) {
    searchArgs.tags = tokeniseQueryParams(queryParams.get("tags"));
  }
  if (queryParams.get("genres")) {
    searchArgs.genres = tokeniseQueryParams(queryParams.get("genres"));
  }
  if (queryParams.get("isComplete")) {
    searchArgs.isComplete = queryParams.get("isComplete") === "true";
  }
  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Search Results</Typography>
        </Grid>
        <Grid item xs={12}>
          <StoriesSearchController
            searchArgs={searchArgs}
            noResultsMessage="No stories found!"
            xs={12}
            sm={6}
            lg={4}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
