import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";

export default function ConfirmPrompt({
  title,
  question,
  open,
  onClose,
  onAction,
}) {
  const accept = () => onAction(true);
  const decline = () => onAction(false);

  return (
    <div>
      <Dialog open={open} onClose={onClose} disableScrollLock={true}>
        {title ? <DialogTitle>{title}</DialogTitle> : null}
        <DialogContent>
          <DialogContentText>{question}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={decline} color="primary">
            <Trans>No</Trans>
          </Button>
          <Button onClick={accept} color="primary">
            <Trans>Yes</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmPrompt.propTypes = {
  onClose: PropTypes.func,
  question: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

ConfirmPrompt.defaultProps = {
  onClose: () => {},
  open: false,
};
