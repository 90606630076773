import { PLACEHOLDER_IMAGES } from "../../consts";
import { createFeedPostDataExtractor } from "./transformerUtils";

export default function UserVotedCritiqueTransformer(feedPost) {
  const extractor = createFeedPostDataExtractor(feedPost.primaryContent?.data);
  const otherVoteCount = +extractor.get("voteCount") - 1;
  const activityText =
    otherVoteCount > 1
      ? ` and ${otherVoteCount} others voted a critique as helpful`
      : " liked a critique";

  return {
    actorFullName: feedPost.actor.name,
    actorImage: feedPost.actor.image || PLACEHOLDER_IMAGES.USER,
    actorPath: `/profile/${feedPost.actor.id}`,
    activityText,
    resourcePath: `/critique/${extractor.get("critiqueId")}`,
    primaryContent: {
      title: extractor.get("critiqueTitle"),
      content: extractor.get("critiqueContent"),
      images: feedPost.primaryContent?.images || [],
    },
    timestamp: feedPost.activityTimestamp,
  };
}
