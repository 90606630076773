import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  InputAdornment,
  IconButton,
  Paper,
  Container,
} from "@material-ui/core";
import PageTitle from "../Common/PageTitle";
import { useHistory, useLocation } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import BootstrapInput from "../Common/BootstrapInput";
import UsersSearchController from "./UsersSearchController";
import PageHead from "../Common/PageHead";
import { useRequireLogin, useScrollTop } from "../../utils/hooks";
import ImageFadeIn from "../Common/ImageFadeIn";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  userDiscoverer: {
    marginTop: theme.spacing(3),
  },
  logo: {
    maxHeight: theme.spacing(6) * 2,
  },
  pageTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  userSearchBox: {
    width: "100%",
  },
  userSearchContainer: {
    marginBottom: theme.spacing(2),
  },
}));

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export default function People() {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = useQueryParams();
  const [searchQuery, setSearchQuery] = useState("");

  useScrollTop([history.location.key]);
  useRequireLogin();

  const onKeyDown = (event) => {
    if (event.keyCode === 13) searchUsers();
  };

  const searchUsers = () => {
    history.push(`/people?q=${searchQuery}`);
  };

  const onChangeSearchQuery = (event) => setSearchQuery(event.target.value);

  const usersSearchBar = (
    <Grid
      container
      justifyContent="center"
      className={classes.userSearchContainer}
    >
      <Grid
        container
        item
        xs={12}
        md={12}
        lg={6}
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <Paper>
            <BootstrapInput
              name="q"
              className={classes.userSearchBox}
              defaultValue={queryParams.get("q")}
              placeholder={t`Search users`}
              bordered
              onChange={onChangeSearchQuery}
              onKeyDown={onKeyDown}
              endAdornment={
                <InputAdornment>
                  <IconButton
                    color="primary"
                    variant="contained"
                    onClick={searchUsers}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            ></BootstrapInput>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Container maxWidth="xl">
      <PageHead title="People | bibliocircle" />
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        className={classes.userDiscoverer}
      >
        <Grid item xs={11} lg={10}>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} className={classes.pageTitle}>
              <Grid
                container
                alignItems="center"
                direction="column"
                spacing={2}
              >
                <Grid item>
                  <Grid container justifyContent="center">
                    <ImageFadeIn
                      src="/logo/logo_people.svg"
                      alt="people"
                      className={classes.logo}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <PageTitle variant="subtitle1" align="center">
                    <Trans>
                      Discover new friends, and follow them to get updates about
                      books they share or request, critiques they write, their
                      discussions and many more.
                    </Trans>
                  </PageTitle>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              {usersSearchBar}
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={8}>
                  <UsersSearchController
                    key={history.location.key}
                    xs={12}
                    searchArgs={{
                      query: queryParams.get("q"),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
