import { getTopLevelDomain } from "./common";
import { IMAGE_HOST_TOPLEVEL_DOMAIN } from "../config";
export const tryAndGetResizedImageUrl = (imageUrl, sizeSuffix = "") => {
  try {
    // make sure if it's an absolute url
    const { origin, pathname } = new URL(imageUrl);
    if (getTopLevelDomain(origin) !== IMAGE_HOST_TOPLEVEL_DOMAIN) {
      return imageUrl;
    }
    const newPath = `${pathname}/${sizeSuffix}`.replace(/\/+/g, "/");
    return `${origin}${newPath}`;
  } catch (err) {
    // fallback
    return imageUrl;
  }
};
