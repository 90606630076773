import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import NoResults from "../Common/NoResultsImage";
import Banner from "../Common/Banner";
import { LISTING_COLLECTIONS_PAGINATION_LIMIT } from "../../config";
import { useQuery } from "@apollo/client";
import { SEARCH_LISTING_COLLECTIONS } from "../../consts/queries";
import ListingCollectionCard from "./ListingCollectionCard";
import { ListingCollectionsLoader } from "../Common/LoadingPlaceholders/ListingCollectionsLoader";
import ErrorPage from "../Error/ErrorPage";
import { useLoader } from "../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  noCollectionsPlaceholder: {
    padding: theme.spacing(3),
  },
  errorBanner: {
    padding: theme.spacing(3),
  },
  noCollectionsPlaceholderText: {
    fontWeight: "bold",
    textAlign: "center",
  },
}));

export default function ListingCollectionsSearchController({
  fetchPolicy,
  fixedResultsCount,
  searchArgs,
  noResultsMessage,
  xs,
  md,
  lg,
  xl,
}) {
  const classes = useStyles();
  const fetchOptions = {
    variables: {
      ...searchArgs,
      pagination: {
        limit: fixedResultsCount || LISTING_COLLECTIONS_PAGINATION_LIMIT,
        offset: 0,
      },
    },
  };

  if (fetchPolicy) fetchOptions.fetchPolicy = fetchPolicy;
  const { loading, error, data, fetchMore } = useQuery(
    SEARCH_LISTING_COLLECTIONS,
    fetchOptions
  );

  useLoader(loading);

  const loadingPlaceholder = (
    <ListingCollectionsLoader xs={xs} md={md} lg={lg} xl={xl} />
  );

  if (error) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the collections due to a problem. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  if (loading && !data) {
    return (
      <Grid container justifyContent="center">
        {loadingPlaceholder}
      </Grid>
    );
  }

  const {
    searchListingCollections: { listingCollections, pagination },
  } = data;

  const loadMoreListingCollections = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: LISTING_COLLECTIONS_PAGINATION_LIMIT,
          offset: listingCollections.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          searchListingCollections: {
            __typename: prev.searchListingCollections.__typename,
            listingCollections: [
              ...prev.searchListingCollections.listingCollections,
              ...fetchMoreResult.searchListingCollections.listingCollections,
            ],
            pagination: fetchMoreResult.searchListingCollections.pagination,
          },
        });
      },
    }).catch(() => {});
  };

  if (error)
    return (
      <Grid container justifyContent="center" className={classes.errorBanner}>
        <Grid item xs={12}>
          <Banner
            type="error"
            title="Something went wrong!"
            message="We're sorry! Something went wrong while displaying collections for you!
              Please try again later."
          />
        </Grid>
      </Grid>
    );

  const noCollectionsPlaceholder = (
    <Grid
      container
      justifyContent="center"
      className={classes.noCollectionsPlaceholder}
    >
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="h5"
          color="primary"
          className={classes.noCollectionsPlaceholderText}
        >
          {noResultsMessage ||
            "Sorry, we could not find the collections you are looking for!"}
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={6} lg={4}>
          <NoResults />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      {!listingCollections.length ? noCollectionsPlaceholder : null}
      <InfiniteScroll
        style={{ overflow: "none !important" }}
        next={!fixedResultsCount && loadMoreListingCollections}
        hasMore={listingCollections.length < pagination.totalCount}
        loader={
          !fixedResultsCount && (
            <ListingCollectionsLoader xs={xs} md={md} lg={lg} xl={xl} />
          )
        }
        dataLength={listingCollections.length}
      >
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {listingCollections.map((col) => (
              <Grid key={col.id} item xs={xs} md={md} lg={lg} xl={xl}>
                <ListingCollectionCard listingCollection={col} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </InfiniteScroll>
    </Grid>
  );
}

ListingCollectionsSearchController.propTypes = {
  searchArgs: PropTypes.object,
};

ListingCollectionsSearchController.defaultProps = {
  searchArgs: {},
};
