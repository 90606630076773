import { Grid, Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Hyperlink from "../Application/Common/Hyperlink";
import PageHead from "../Application/Common/PageHead";
import ContactForm from "./ContactForm";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
}));

export default function PrivacyPolicy() {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center">
      <PageHead title="Privacy Policy | bibliocircle" />
      <Grid item xs={12} sm={10} md={8}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.title}>
              Privacy Policy
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="body1">Last updated 25/12/2020</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Introduction</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  This website is operated by bibliocircle (PVT) Ltd. and whose
                  registered address is No. 130/11, Old Kottawa Road, Nawinna,
                  Maharagama, (“We”) are committed to protecting and preserving
                  the privacy of our visitors when visiting our site or
                  communicating electronically with us. This policy sets out how
                  we process any personal data we collect from you or that you
                  provide to us through our website. We confirm that we will
                  keep your information secure and that we will comply fully
                  with all applicable Data Protection legislation and
                  regulations. Please read the following carefully to understand
                  what happens to personal data that you choose to provide to
                  us, or that we collect from you when you visit this site. By
                  visiting{" "}
                  <a
                    href="https://bibliocircle.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Link>https://bibliocircle.com</Link>
                  </a>{" "}
                  (our website) you are accepting and consenting to the
                  practices described in this policy.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Types of information we may collect from you
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  We may collect, store and use the following kinds of personal
                  information about individuals who visit and use our website:
                </Typography>
                <ul>
                  <li>
                    <Typography variant="body1">
                      Information you supply to us. You may supply us with
                      information about you by filling in forms on our website.
                      This includes information you provide when you create any
                      content (including but not limited to ads, critiques,
                      stories, book requests, discussions, chats), update your
                      profile or send us messages via our contact form. The
                      information you give us may include your name, address,
                      e-mail address and phone number.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      The information our website automatically collects about
                      you: With regard to each of your visits to our website we
                      may automatically collect information including the
                      following:
                    </Typography>
                    <ul>
                      <li>
                        <Typography variant="body1">
                          Technical information: Your IP address, browser and
                          device types and versions, operating system and
                          platform will be collected by Google Analytics to
                          investigate issues and to improve our website's
                          compatibility across different
                          devices/platforms/browsers.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          Information about your visit: Including what pages you
                          visit, how long you are on the site, how you got to
                          the site (including date and time); page response
                          times, length of visit, what you click on, and errors.
                        </Typography>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Typography variant="body1">
                      Cookies: Our website uses cookies to distinguish you from
                      other users of our website, manage your shopping cart and
                      to remember your settings across multiple visits. This
                      helps us to provide you with a good experience when you
                      browse our website and also allows us to improve our site.
                      For detailed information on the cookies we use and the
                      purposes for which we use them see our{" "}
                      <Hyperlink to={`/info/cookie-policy`}>
                        <Link>Cookie Policy</Link>
                      </Hyperlink>
                    </Typography>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  How we may use the information we collect
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  We use the information in the following ways:
                </Typography>
                <ul>
                  <li>
                    <Typography variant="body1">
                      Information you supply to us. We will use this
                      information:
                    </Typography>
                    <ul>
                      <li>
                        <Typography variant="body1">
                          to provide you with information and/or services that
                          you request from us;
                        </Typography>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Typography variant="body1">
                      Information we automatically collect about you. We will
                      use this information:
                    </Typography>
                    <ul>
                      <li>
                        <Typography variant="body1">
                          to administer our site including troubleshooting and
                          statistical purposes;
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          to improve our site to ensure that content is
                          presented in the most effective manner for you, your
                          browser and for your device;
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body1">
                          security and debugging as part of our efforts to keep
                          our site safe and secure.
                        </Typography>
                      </li>
                    </ul>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  This information is collected anonymously and is not linked to
                  information that identifies you as an individual. We use
                  Google Analytics to track this information. Find out how
                  Google uses your data at{" "}
                  <a
                    href="https://policies.google.com/privacy?hl=en-US"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Google Privacy Policy
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Disclosure of your information
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  We do not rent, sell or share personal information about you
                  with other people or entities.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  We will use all reasonable efforts to ensure that your
                  personal data is not disclosed to any institution or authority
                  unless required by law or other regulations.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Our site is secured with HTTPS however, unfortunately, the
                  transmission of information via the internet is not completely
                  secure. Although we will do our best to protect your personal
                  data, we cannot guarantee the security of your data
                  transmitted to our site; any transmission is at your own risk.
                  Once we have received your information, we will use strict
                  procedures and security features to try to prevent
                  unauthorised access.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Third party links</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Our site may, from time to time, contain links to and from the
                  third-party websites. If you follow a link to any of these
                  websites, please note that these websites have their own
                  privacy policies and that we do not accept any responsibility
                  or liability for these policies. Please check these policies
                  before you submit any personal data to these websites.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Changes to our privacy policy
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  We reserve the right to make changes to this Privacy Policy at
                  any time and for any reason. We will alert you about any
                  changes by updating the “Last Updated” date of this Privacy
                  Policy. Any changes or modifications will be effective
                  immediately upon posting the updated Privacy Policy on the
                  site, and you waive the right to receive specific notice of
                  each such change or modification.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Contact</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Questions, comments and requests regarding this privacy policy
                  are welcomed and should be addressed to bibliocircle team,{" "}
                  <a href="mailto:team@bibliocircle.com">
                    team@bibliocircle.com
                  </a>
                  , or you can reach us via our contact form below.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={8}>
                    <ContactForm />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
