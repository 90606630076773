import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Checkbox,
  Radio,
} from "@material-ui/core";
import Address from "./Address";
import AddressEditorDialog from "./AddressEditorDialog";
import ConfirmPrompt from "../ConfirmPrompt";
import AddIcon from "@material-ui/icons/AddCircle";
import { GET_ADDRESSES } from "../../../consts/queries";
import {
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
  DELETE_ADDRESS,
} from "../../../consts/mutations";
import { useDispatch } from "react-redux";
import { actionTypes } from "../../index.reducer";
import { GENERIC_ALERT_ERROR_MESSAGE } from "../../../config";
import { t } from "@lingui/macro";
import { useMutation, useQuery } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  addressContainer: {
    height: "100%",
  },
  addressEditorContainer: {
    height: "100%",
  },
  addressManagerContainer: {
    display: "flex",
    width: "100%",
  },
  addressDetailsContainer: {},
  addAddressButton: {
    minHeight: "80px",
    cursor: "pointer",
    height: "50%",
    width: "100%",
    borderRadius: theme.spacing(2),
    border: "dashed",
    color: theme.palette.grey[600],
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.background.paper,
    transition: "background-color 500ms linear",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      transition: "background-color 500ms linear",
    },
  },
  addressItem: {
    marginBottom: theme.spacing(4),
  },
  error: {
    border: "1px solid red",
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
}));

export default function AddressManager({
  error,
  selectable,
  singleSelect,
  onChangeSelection,
  selectedAddresses,
  enabledAddresses,
  disabled,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [addresses, setAddresses] = useState([]);
  const [editedAddress, setEditedAddress] = useState({});
  const [deletingAddress, setDeletingAddress] = useState(null);

  const { data } = useQuery(GET_ADDRESSES);
  const mutationRefetchConfig = {
    refetchQueries: [{ query: GET_ADDRESSES }],
  };
  const [createAddress] = useMutation(CREATE_ADDRESS, mutationRefetchConfig);
  const [updateAddress] = useMutation(UPDATE_ADDRESS, mutationRefetchConfig);
  const [deleteAddress] = useMutation(DELETE_ADDRESS, mutationRefetchConfig);
  const [addressManagerOpen, setAddressManagerOpen] = useState(false);
  const [addressEditorOpen, setAddressEditorOpen] = useState(false);
  const [removeAddressPending, setRemoveAddressPending] = useState(false);

  const openAddressManager = () => setAddressManagerOpen(true);
  const closeAddressManager = () => setAddressManagerOpen(false);
  const openAddressEditor = () => setAddressEditorOpen(true);
  const closeAddressEditor = () => setAddressEditorOpen(false);
  const onClickEditAddress = (address) => () => {
    setEditedAddress(address);
    openAddressEditor();
  };
  const onClickRemoveAddress = (address) => () => {
    setRemoveAddressPending(true);
    setDeletingAddress(address);
  };
  const onClickAddressSelection = (addressId) => (event) => {
    onChangeSelection(addressId, event.target.checked);
  };

  const handleRemoveAddressDecision = (accept) => {
    setRemoveAddressPending(false);
    if (accept) {
      return deleteAddress({
        variables: { id: deletingAddress.id },
      })
        .then(() => {
          closeAddressEditor();
          setDeletingAddress(null);
        })
        .catch(() => {
          dispatchError(GENERIC_ALERT_ERROR_MESSAGE);
        });
    }
  };

  const dispatchError = (message) => {
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });
  };

  const handleCreateAddress = (address) => {
    createAddress({
      variables: { address },
    })
      .then(() => {
        closeAddressEditor();
      })
      .catch(() => {
        dispatchError(GENERIC_ALERT_ERROR_MESSAGE);
      });
  };

  const handleUpdateAddress = (address) => {
    return updateAddress({
      variables: { address },
    })
      .then(() => {
        closeAddressEditor();
      })
      .catch(() => {
        dispatchError(GENERIC_ALERT_ERROR_MESSAGE);
      });
  };

  const onSaveEditor = (address) => {
    if (address.id) return handleUpdateAddress(address);
    return handleCreateAddress(address);
  };

  useEffect(() => {
    if (Array.isArray(data?.addresses)) {
      setAddresses(data.addresses);
    }
  }, [data]);

  const addressEnabled = (addressId) => {
    if (!enabledAddresses) return true;
    return enabledAddresses.includes(addressId);
  };

  const addressChecked = (addressId) => {
    if (!addressEnabled(addressId)) return false;
    return selectedAddresses.includes(addressId);
  };

  const addressManager = (
    <Dialog
      open={addressManagerOpen}
      onClose={closeAddressManager}
      disableBackdropClick={true}
      fullWidth
      maxWidth="md"
      disableScrollLock={true}
    >
      <DialogTitle>Edit Addresses</DialogTitle>
      <ConfirmPrompt
        open={removeAddressPending}
        onAction={handleRemoveAddressDecision}
        question="Are you sure you want to remove this address?"
      />
      <DialogContent className={classes.addressManagerContainer}>
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          alignContent="stretch"
          alignItems="stretch"
        >
          {addresses.map((address) => (
            <Grid
              key={address.id}
              item
              xs={12}
              md={6}
              lg={4}
              className={classes.addressItem}
            >
              <Grid
                container
                className={classes.addressEditorContainer}
                alignItems="flex-end"
              >
                <Grid item xs={12} className={classes.addressContainer}>
                  <Address address={address} isDefault={address.isDefault} />
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item>
                    <Button
                      variant="text"
                      size="small"
                      color="primary"
                      onClick={onClickEditAddress(address)}
                      disabled={disabled}
                    >
                      Edit
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      size="small"
                      color="secondary"
                      onClick={onClickRemoveAddress(address)}
                      disabled={disabled}
                    >
                      Remove
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12} md={6} lg={4} onClick={openAddressEditor}>
            <Grid
              container
              className={classes.addAddressButton}
              justifyContent="center"
              alignContent="center"
            >
              <Typography variant="subtitle">Add new address</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeAddressManager}
          color="default"
          disabled={disabled}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const RadioComponent = singleSelect ? Radio : Checkbox;

  return (
    <Grid container spacing={2} className={error && classes.error}>
      {addressManager}
      <AddressEditorDialog
        address={editedAddress}
        open={addressEditorOpen}
        onClose={closeAddressEditor}
        onSave={onSaveEditor}
      />
      <Grid container item spacing={2}>
        {addresses
          .filter((addr) => addressEnabled(addr.id))
          .map((address) => (
            <Grid
              key={address.id}
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              {selectable ? (
                <Grid item xs={2} container>
                  <RadioComponent
                    color="primary"
                    checked={addressChecked(address.id)}
                    onClick={onClickAddressSelection(address.id)}
                    disabled={disabled}
                  />
                </Grid>
              ) : null}
              <Grid item xs={selectable ? 10 : 12}>
                <Address address={address} isDefault={address.isDefault} />
              </Grid>
            </Grid>
          ))}
      </Grid>
      <Grid container item justifyContent="flex-start">
        <Button
          startIcon={!addresses.length ? <AddIcon /> : null}
          variant="text"
          color="primary"
          onClick={openAddressManager}
          disabled={disabled}
        >
          {addresses.length ? t`Edit Addresses` : t`Add address`}
        </Button>
      </Grid>
    </Grid>
  );
}

AddressManager.propTypes = {
  selectable: PropTypes.bool,
  singleSelect: PropTypes.bool,
  onChangeSelection: PropTypes.func,
  selectedAddresses: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

AddressManager.defaultProps = {
  selectable: false,
  singleSelect: false,
  onChangeSelection() {},
  selectedAddresses: [],
  disabled: false,
};
