import React from "react";
import PropTypes from "prop-types";
import { Grid, Link, makeStyles, Typography } from "@material-ui/core";
import Hyperlink from "../../Common/Hyperlink";
import StoriesSearchController from "./StoriesSearchController";

const useStyles = makeStyles((theme) => ({
  sectionCard: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(2),
  },
  sectionTitle: {
    fontWeight: "bold",
    fontSize: 28,
  },
  moreStoriesLinkText: {
    fontSize: 14,
    fontWeight: "bold",
  },
}));

export default function StoryHomePageSection({
  title,
  searchArgs,
  moreStoriesLinkText,
  moreStoriesLinkPath,
  noResultsMessage,
  maxStoriesCount,
}) {
  const classes = useStyles();

  return (
    <Grid container className={classes.sectionCard}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              color="textPrimary"
              className={classes.sectionTitle}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StoriesSearchController
              xs={12}
              sm={6}
              md={6}
              lg={4}
              noResultsMessage={noResultsMessage}
              searchArgs={searchArgs || {}}
              fixedResultsCount={maxStoriesCount}
            />
          </Grid>
          <Grid item xs={12}>
            <Hyperlink to={moreStoriesLinkPath}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.moreStoriesLinkText}
              >
                <Link underline="hover">{moreStoriesLinkText}</Link>
              </Typography>
            </Hyperlink>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

StoryHomePageSection.propTypes = {
  title: PropTypes.string.isRequired,
  searchArgs: PropTypes.object,
  moreStoriesLinkText: PropTypes.string,
  moreStoriesLinkPath: PropTypes.string.isRequired,
  maxStoriesCount: PropTypes.number.isRequired,
};

StoryHomePageSection.defaultProps = {
  moreStoriesLinkText: "See more stories",
  maxStoriesCount: 6,
};
