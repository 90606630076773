import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  const baseAddressContainer = {
    height: "100%",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  };

  return {
    adddressContainer: {
      ...baseAddressContainer,
      backgroundColor: theme.palette.grey[200],
    },
    disabledAddressContainer: {
      ...baseAddressContainer,
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[300],
    },
    defaultAddress: {
      position: "relative",
      zIndex: 1,
      "&::after": {
        zIndex: -1,
        content: '"Default"',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        color: theme.palette.getContrastText(theme.palette.primary.main),
        padding: "",
        position: "absolute",
        top: 0,
        right: 0,
        fontSize: "10px",
        borderRadius: theme.shape.borderRadius,
        width: "50px",
        height: "20px",
        backgroundColor: theme.palette.primary.main,
      },
    },
  };
});

export default function Address({ address, isDefault, disabled }) {
  const classes = useStyles();
  const addressClass = isDefault && !disabled ? classes.defaultAddress : null;
  const adddressContainerClass = disabled
    ? classes.disabledAddressContainer
    : classes.adddressContainer;
  return (
    <Grid
      container
      className={adddressContainerClass}
      spacing={1}
      alignItems="center"
    >
      <Grid item xs={12} container className={addressClass}>
        <Grid item xs={12}>
          <Typography variant="body2">{address.addressLine1}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">{address.addressLine2}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">{address.addressLine3}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">{address.city}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

Address.propTypes = {
  address: PropTypes.object,
  isDefault: PropTypes.bool,
  disabled: PropTypes.bool,
};

Address.defaultProps = {
  address: {},
  isDefault: false,
  disabled: false,
};
