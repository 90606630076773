import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles, Divider } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { STORY_READING_CHECKPOINTS_PAGINATION_LIMIT } from "../../../config";
import { useQuery } from "@apollo/client";
import { SEARCH_STORY_READING_CHECKPOINTS } from "../../../consts/queries";
import ErrorPage from "../../Error/ErrorPage";
import { useLoader } from "../../../utils/hooks";
import ReadingCheckpointCard from "./ReadingCheckpointsCard";
import { ReadingCheckpointsLoader } from "../../Common/LoadingPlaceholders/ReadingCheckpointsLoader";
import NoResults from "../../Common/NoResultsImage";

const useStyles = makeStyles((theme) => ({
  noCheckpointsPlaceholder: {
    padding: theme.spacing(2),
  },
  errorBanner: {
    padding: theme.spacing(3),
  },
  noCheckpointsPlaceholderText: {
    fontWeight: "bold",
    textAlign: "center",
  },
}));

export default function ReadingCheckpointsSearchController({
  fetchPolicy,
  scrollableTargetId,
  searchArgs,
  noResultsMessage,
  xs,
  md,
  lg,
  xl,
}) {
  const classes = useStyles();
  const fetchOptions = {
    variables: {
      ...searchArgs,
      pagination: {
        limit: STORY_READING_CHECKPOINTS_PAGINATION_LIMIT,
        offset: 0,
      },
    },
  };

  if (fetchPolicy) fetchOptions.fetchPolicy = fetchPolicy;
  const {
    loading,
    error: fetchCheckpointsError,
    data,
    fetchMore,
  } = useQuery(SEARCH_STORY_READING_CHECKPOINTS, fetchOptions);
  useLoader(loading);

  const loadingPlaceholder = (
    <ReadingCheckpointsLoader xs={xs} md={md} lg={lg} xl={xl} />
  );

  if (fetchCheckpointsError) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the stories you are reading due to a problem!"
        statusCode={500}
      />
    );
  }

  if (loading && !data) {
    return (
      <Grid container justifyContent="center">
        {loadingPlaceholder}
      </Grid>
    );
  }

  const {
    searchStoryReadingCheckpoints: { checkpoints, pagination },
  } = data;

  const loadMoreCheckpoints = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: STORY_READING_CHECKPOINTS_PAGINATION_LIMIT,
          offset: checkpoints.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          searchStoryReadingCheckpoints: {
            __typename: prev.searchStoryReadingCheckpoints.__typename,
            checkpoints: [
              ...prev.searchStoryReadingCheckpoints.checkpoints,
              ...fetchMoreResult.searchStoryReadingCheckpoints.checkpoints,
            ],
            pagination:
              fetchMoreResult.searchStoryReadingCheckpoints.pagination,
          },
        });
      },
    }).catch(() => {});
  };

  const noCheckpointsPlaceholder = (
    <Grid
      container
      justifyContent="center"
      className={classes.noStoriesPlaceholder}
    >
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="h5"
          color="primary"
          className={classes.noStoriesPlaceholderText}
        >
          {noResultsMessage || "No stories to display!"}
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={6} lg={4}>
          <NoResults />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      {!checkpoints.length ? noCheckpointsPlaceholder : null}
      <InfiniteScroll
        scrollableTarget={scrollableTargetId}
        style={{ overflow: "none !important" }}
        next={loadMoreCheckpoints}
        hasMore={checkpoints.length < pagination.totalCount}
        loader={loadingPlaceholder}
        dataLength={checkpoints.length}
      >
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {checkpoints
              .filter((checkpoint) => !!checkpoint.story) // exclude unpublished/deleted stories
              .map((checkpoint) => [
                <Grid key={checkpoint.id} item xs={12}>
                  <ReadingCheckpointCard checkpoint={checkpoint} />
                </Grid>,
                <Grid item xs={12}>
                  <Divider />
                </Grid>,
              ])}
          </Grid>
        </Grid>
      </InfiniteScroll>
    </Grid>
  );
}

ReadingCheckpointsSearchController.propTypes = {
  searchArgs: PropTypes.object,
};

ReadingCheckpointsSearchController.defaultProps = {
  searchArgs: {},
};
