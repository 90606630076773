import React, { useEffect, useState } from "react";
import { DISPLAYED_GENRES_COUNT, STORY_GENRES } from "../../../consts";
import randomSample from "lodash.samplesize";
import {
  Button,
  Collapse,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import HighlightedCategoryCard from "./HighlightedCategoryCard";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import CategoryCard from "./CategoryCard";
import { useStoryReadingPreferences } from "../../../../utils/hooks";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    textAlign: "center",
  },
}));

export default function CategoryDiscoverer() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [highlightedGenres, setHighlightedGenres] = useState([]);
  const { genres } = useStoryReadingPreferences();
  const [expandGenres, setExpandGenres] = useState(false);

  const handleToggleExpandGenres = () =>
    setExpandGenres((isExpanded) => !isExpanded);

  useEffect(() => {
    if (!genres || highlightedGenres?.length) return;
    if (genres.length) {
      setHighlightedGenres(randomSample(genres, DISPLAYED_GENRES_COUNT));
    } else {
      setHighlightedGenres(
        randomSample(Object.values(STORY_GENRES), DISPLAYED_GENRES_COUNT)
      );
    }
  }, [genres, highlightedGenres]);
  if (!genres) return null;
  const highlightedGenreCodes = highlightedGenres.map((genre) => genre.code);
  const nonHighlightedGenres = Object.values(STORY_GENRES).filter(
    (genre) => !highlightedGenreCodes.includes(genre.code)
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.title}>
              <Trans>Discover Categories</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ overflow: "hidden" }}>
            <Grid container justifyContent="center" spacing={isMobile ? 3 : 8}>
              {highlightedGenres.map((genre) => (
                <Grid item key={genre.code}>
                  <HighlightedCategoryCard genre={genre} />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Button
                    variant="text"
                    endIcon={<ExpandIcon />}
                    onClick={handleToggleExpandGenres}
                  >
                    <Trans>Explore more categories</Trans>
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Collapse in={expandGenres}>
                  <Grid container justifyContent="center" spacing={2}>
                    {nonHighlightedGenres.map((genre) => (
                      <Grid item key={genre.code}>
                        <CategoryCard genre={genre} />
                      </Grid>
                    ))}
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
