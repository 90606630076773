import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { red } from "@material-ui/core/colors";
import Book from "../Listing/Book";

const useStyles = makeStyles((theme) => ({
  plainLink: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  bookFieldName: {
    marginRight: theme.spacing(1),
    fontWeight: "bold",
  },
  bookField: {
    "& *": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
      fontSize: 16,
    },
  },
  critiqueBookContainer: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
  },
  rightGutter: {
    marginRight: theme.spacing(0.5),
  },
  thumbUpAvatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  thumbDownAvatar: {
    color: theme.palette.getContrastText(red[700]),
    backgroundColor: red[700],
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
}));

export default function CritiqueBook({ book }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.critiqueBookContainer}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.sectionTitle}>
              More about the critiqued book ...
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Book book={book} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
