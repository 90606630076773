import { Grid, Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Hyperlink from "../Application/Common/Hyperlink";
import PageHead from "../Application/Common/PageHead";
import ContactForm from "./ContactForm";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
}));

export default function CookiePolicy() {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center">
      <PageHead title="Cookie Policy | bibliocircle" />
      <Grid item xs={12} sm={10} md={8}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.title}>
              Cookie Policy
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="body1">Last updated 25/12/2020</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Introduction</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  bibliocircle (PVT) Ltd. (“we” or “us” or “our”) may use
                  cookies, and other tracking technologies when you visit our
                  website https://bibliocircle.com, to help customize the site
                  and improve your experience.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  We reserve the right to make changes to this Cookie Policy at
                  any time and for any reason. We will alert you about any
                  changes by updating the “Last Updated” date of this Cookie
                  Policy. Any changes or modifications will be effective
                  immediately upon posting the updated Cookie Policy on the
                  site, and you waive the right to receive specific notice of
                  each such change or modification.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  You are encouraged to periodically review this Cookie Policy
                  to stay informed of updates. You will be deemed to have been
                  made aware of, will be subject to, and will be deemed to have
                  accepted the changes in any revised Cookie Policy by your
                  continued use of the site after the date such revised Cookie
                  Policy is posted.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Use of Cookies</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  A “cookie” is a string of information which assigns you a
                  unique identifier that we store on your computer. Your browser
                  then provides that unique identifier to use each time you
                  submit a query to the site. We use cookies on the site to,
                  among other things, keep track of services you have used,
                  record registration information, record your user preferences,
                  keep you logged into the site, facilitate purchase procedures,
                  and track the pages you visit. Cookies help us understand how
                  the site is being used and improve your user experience.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Types of Cookies</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  The following types of cookies may be used when you visit the
                  site:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Analytics Cookies</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Analytics cookies monitor how users reached the site, and how
                  they interact with and move around once on the site. These
                  cookies let us know what features on the site are working the
                  best and what features on the site can be improved.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Our Cookies</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Our cookies are “first-party cookies”, and can be either
                  permanent or temporary. These are necessary cookies, without
                  which the site won’t work properly or be able to provide
                  certain features and functionalities. Some of these may be
                  manually disabled in your browser, but may affect the
                  functionality of the site.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  Personalization Cookies
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Personalization cookies are used to recognize repeat visitors
                  to the site. We use these cookies to store your settings and
                  preferences each time you visit the site.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Control of Cookies</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Most browsers are set to accept cookies by default. However,
                  you can remove or reject cookies in your browser’s settings.
                  Please be aware that such action could affect the availability
                  and functionality of the site.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  For more information on how to control cookies, check your
                  browser or device’s settings for how you can control or reject
                  cookies.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Privacy Policy</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  For more information about how we use information collected by
                  cookies and other tracking technologies, please refer to our{" "}
                  <Hyperlink to="/info/privacy-policy">
                    <Link>Privacy Policy</Link>
                  </Hyperlink>
                  . This Cookie Policy is part of and is incorporated into our
                  Privacy Policy. By using the site, you agree to be bound by
                  this Cookie Policy and our Privacy Policy.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Contact Us</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  If you have questions or comments about this Cookie Policy,
                  please contact us at{" "}
                  <a href="mailto:team@bibliocircle.com">
                    team@bibliocircle.com
                  </a>{" "}
                  or reach us via the below contact form.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={8}>
                    <ContactForm />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
