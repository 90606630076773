import React from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@material-ui/core";

export default function PremiumIcon({ fontSize, style, className }) {
  return (
    <SvgIcon style={style} className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={fontSize}
        height={fontSize}
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M2.8 5.2L7 8l4.186-5.86a1 1 0 0 1 1.628 0L17 8l4.2-2.8a1 1 0 0 1 1.547.95l-1.643 13.967a1 1 0 0 1-.993.883H3.889a1 1 0 0 1-.993-.883L1.253 6.149A1 1 0 0 1 2.8 5.2zM12 15a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
      </svg>
    </SvgIcon>
  );
}

PremiumIcon.propTypes = {
  fontSize: PropTypes.number,
};

PremiumIcon.defaultProps = {
  fontSize: 24,
};
