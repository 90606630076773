import {
  Avatar,
  Fade,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  Facebook as FacebookIcon,
  LinkedIn as LinkedInIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  Info as InfoIcon,
} from "@material-ui/icons";
import React from "react";
import ImageFadeIn from "../Application/Common/ImageFadeIn";
import PageHead from "../Application/Common/PageHead";
import { BC_DESCRIPTION } from "../config";
import Hyperlink from "../Application/Common/Hyperlink";
import ContactForm from "./ContactForm";

const useStyles = makeStyles((theme) => ({
  logo: {
    maxHeight: theme.spacing(8) * 2,
  },
  aboutPage: {
    marginTop: theme.spacing(3),
  },
  profileImage: {
    height: theme.spacing(10),
    width: theme.spacing(10),
  },
  contactForm: {
    marginBottom: theme.spacing(4),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  contactUsHeading: {
    fontWeight: "bold",
  },
}));

export default function About() {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center" className={classes.aboutPage}>
      <PageHead title="About | bibliocircle" />
      <Grid item xs={12} md={8}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <ImageFadeIn
                    src="/logo/logo_main.svg"
                    alt="bibliocircle"
                    className={classes.logo}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" variant="h6" color="primary">
                  {BC_DESCRIPTION}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  How many old books do we have in our homes, sitting in a dusty
                  cardboard box with no use for anyone? A book is knowledge, and
                  it shouldn't go to waste. Somewhere in Sri Lanka, there might
                  be someone who's looking for the exact book we have at home
                  but can't find it in any shop. What if we can sell or rent it
                  for a much cheaper price? Or better yet, help them and give it
                  away free?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  As readers, we all know that it is sometimes very difficult to
                  find a book we are looking for. We need to look on websites
                  from multiple sellers, visit many shops. Even if we find the
                  book we are looking for, we cannot make sure that we are
                  paying the right price for the book. A different seller may
                  have a discounted price for the same book. Or, there could be
                  another reader who has the book and had already read it, and
                  willing to sell or rent it for a much cheaper price?
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  Purchase of a book is always initiated by the reader.
                  Booksellers wait until readers come to them looking for a
                  book. Unfortunately, none of the booksellers have a clear
                  visibility to see what readers are looking for. Further, if
                  you are a book shop owner or a publisher, setting up your own
                  online book store is not only expensive and difficult to
                  maintain, but you will also need to work hard to attract
                  readers to your website. But, what if you can set up your
                  mobile-friendly online book shop in a few clicks and for FREE
                  on bibliocircle and be a part of the first-ever book network
                  in Sri Lanka?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  We, at bibliocircle, try to solve this unique problem. We
                  bring book readers and booksellers in Sri Lanka on to one
                  single platform, making it easy to find a book for the best
                  price. It's not just a typical online marketplace. It's a
                  network of books. It has features of a book marketplace and
                  also a knowledge-sharing platform which helps readers to find
                  other readers who have the same interests and share books. And
                  it also helps booksellers to find what readers are looking to
                  buy.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={10} lg={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Grid container justifyContent="flex-start">
                      <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <Fade in>
                              <Avatar
                                src="/deepal-profile.jpg"
                                className={classes.profileImage}
                              />
                            </Fade>
                          </Grid>
                          <Grid item>
                            <Typography variant="h6">Founder</Typography>
                            <Typography variant="body1">
                              Deepal Jayasekara
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container justifyContent="flex-start">
                      <Grid item>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <Fade in>
                              <Avatar
                                src="/vishmi-profile.jpg"
                                className={classes.profileImage}
                              />
                            </Fade>
                          </Grid>
                          <Grid item>
                            <Typography variant="h6">Co-Founder</Typography>
                            <Typography variant="body1">
                              Vishmi Money
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  We value all our customer feedback. If you have any questions
                  and suggestions, please let us know.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      className={classes.contactUsHeading}
                    >
                      Contact Us
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={5}>
                      <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item>
                                <Grid container alignItems="center">
                                  <FacebookIcon color="primary" />
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid container alignItems="center">
                                  <Link
                                    href="https://www.facebook.com/bibliocircle"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.link}
                                  >
                                    <Typography variant="body1">
                                      facebook.com/bibliocircle
                                    </Typography>
                                  </Link>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item>
                                <Grid container alignItems="center">
                                  <LinkedInIcon color="primary" />
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid container alignItems="center">
                                  <Link
                                    href="https://www.linkedin.com/company/bibliocircle"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.link}
                                  >
                                    <Typography variant="body1" bold>
                                      linkedin.com/company/bibliocircle
                                    </Typography>
                                  </Link>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item>
                                <Grid container alignItems="center">
                                  <PhoneIcon color="primary" />
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid container alignItems="center">
                                  <Typography variant="body1">
                                    +94 71 291 6080
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item>
                                <Grid container alignItems="center">
                                  <EmailIcon color="primary" />
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid container alignItems="center">
                                  <Link
                                    href="mailto:team@bibliocircle.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.link}
                                  >
                                    <Typography variant="body1" bold>
                                      team@bibliocircle.com
                                    </Typography>
                                  </Link>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} className={classes.contactForm}>
                        <Grid container>
                          <Grid item sm={8}>
                            <ContactForm />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      className={classes.contactUsHeading}
                    >
                      Resources
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item>
                                <Grid container alignItems="center">
                                  <InfoIcon color="primary" />
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid container alignItems="center">
                                  <Hyperlink
                                    to="/info/privacy-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.link}
                                  >
                                    <Typography variant="body1" bold>
                                      Privacy Policy
                                    </Typography>
                                  </Hyperlink>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item>
                                <Grid container alignItems="center">
                                  <InfoIcon color="primary" />
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid container alignItems="center">
                                  <Hyperlink
                                    to="/info/cookie-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.link}
                                  >
                                    <Typography variant="body1" bold>
                                      Cookie Policy
                                    </Typography>
                                  </Hyperlink>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
