import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import ProfileEditor from "./Editor";
import { IMAGE_SERVER_URL } from "../../config";
import * as authActionTypes from "../../Auth/auth.actionTypes";
import { actionTypes } from "../index.reducer";
import { GET_USER_PROFILE_FOR_EDITING, GET_USER } from "../../consts/queries";
import { UPDATE_PROFILE } from "../../consts/mutations";
import { dataURLtoBlob } from "../../utils/fileOperations";
import PageHead from "../Common/PageHead";
import { useLoader, useLoggedInUser, useRequireLogin } from "../../utils/hooks";

export default function EditProfile() {
  const history = useHistory();
  const loggedInUser = useLoggedInUser();
  const dispatch = useDispatch();
  const [updatingProfile, setUpdatingProfile] = useState(false);
  useRequireLogin();

  const {
    loading: retrievingUser,
    error: retrieveUserError,
    data: userData,
  } = useQuery(GET_USER_PROFILE_FOR_EDITING, {
    variables: { id: loggedInUser?.id },
    skip: !loggedInUser,
  });
  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    refetchQueries: [
      {
        query: GET_USER,
        variables: {
          id: loggedInUser?.id,
        },
      },
    ],
  });

  const dispatchErrorMessage = useCallback(
    (message) =>
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message,
      }),
    [dispatch]
  );

  useEffect(() => {
    if (retrieveUserError) {
      dispatchErrorMessage(
        "Unable to retrieve user details! Please try again later"
      );
    }
  }, [dispatchErrorMessage, retrieveUserError]);
  useLoader(retrievingUser || updatingProfile);

  const goToProfile = () => history.push(`/profile/${loggedInUser?.id}`);

  const uploadProfilePicture = (dataUrl) => {
    if (!dataUrl) return Promise.resolve();
    const formData = new FormData();
    const profilePictureBlob = dataURLtoBlob(dataUrl);
    if (!profilePictureBlob) return Promise.resolve();
    formData.append("file", profilePictureBlob);
    return fetch(`${IMAGE_SERVER_URL}/upload/md`, {
      method: "POST",
      body: formData,
      credentials: "include",
    }).then((response) => {
      // TODO: handle rate limit errors 429
      if (response.status === 201) return response.json();
      throw Error("failed to upload image!");
    });
  };

  const updateUser = (updatedUser) => {
    setUpdatingProfile(true);
    const { profilePicture, ...user } = updatedUser;
    uploadProfilePicture(profilePicture)
      .then((profileImage) => {
        const profilePicture = profileImage?.fileName;
        if (profilePicture) {
          user.profilePicture = profilePicture;
        }
        return updateProfile({
          variables: { user },
        });
      })
      .then(({ data }) => {
        dispatch({
          type: authActionTypes.UPDATE_PROFILE,
          user: data.updateUser,
        });
        goToProfile();
      })
      .catch((err) => {
        if (err.graphQLErrors?.[0]?.code === "CREDENTIAL_MISMATCH") {
          dispatchErrorMessage("Provided current password is incorrect");
          return;
        }
        dispatchErrorMessage(
          "Unable to update profile. Please try again later"
        );
      })
      .finally(() => {
        setUpdatingProfile(false);
      });
  };

  return userData?.user ? (
    <>
      <PageHead title="Edit Profile | bibliocircle" />
      <ProfileEditor
        user={userData.user}
        onSubmit={updateUser}
        disabled={updatingProfile}
      />
    </>
  ) : null;
}
