import React from "react";
import PropTypes from "prop-types";
import { Grid, Link, makeStyles, Typography } from "@material-ui/core";
import Hyperlink from "../../Common/Hyperlink";
import FacebookShareButton from "../../Common/FacebookShareButton";
import ImageFadeIn from "../../Common/ImageFadeIn";
import { Rating } from "@material-ui/lab";
import { green } from "@material-ui/core/colors";
import UserAvatar from "../../Common/UserAvatar";
import StoryReporter from "../StoryReporter";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  coverImageItem: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  coverImageContainer: {
    justifyContent: "flex-start",
  },
  coverImage: {
    maxHeight: 300,
    maxWidth: "100%",
    objectFit: "contain",
    borderRadius: theme.spacing(2),
    boxShadow: `4px 4px ${theme.palette.grey[300]}`,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 16,
  },
  metadata: {
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  reviewsAndReadsCount: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  completeBadge: {
    fontWeight: "bold",
    color: green[600],
  },
  tag: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
  },
  tagText: {
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    fontSize: "12px",
  },
  actionsPanel: {
    marginTop: theme.spacing(2),
  },
  facebookButton: {
    width: "100%",
  },
}));

export default function StoryInfoCard({ story }) {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Hyperlink to={`/story/${story.id}`}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.coverImageItem}>
              <Grid container className={classes.coverImageContainer}>
                <Grid item>
                  <ImageFadeIn
                    src={story.coverImage}
                    className={classes.coverImage}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Hyperlink to={`/story/${story.id}`}>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          className={classes.title}
                        >
                          {story.title}
                        </Typography>
                      </Hyperlink>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        className={classes.subtitle}
                      >
                        {story.subtitle}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        className={classes.metadata}
                      >
                        <Trans>{story.chaptersCount} Chapters &bull;</Trans>{" "}
                        {story.isComplete ? (
                          <span className={classes.completeBadge}>
                            <Trans>Complete</Trans>
                          </span>
                        ) : (
                          <Trans>Ongoing</Trans>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Grid container spacing={1} alignItems="center">
                            <Grid item>
                              <Grid container alignItems="center">
                                <Rating
                                  readOnly
                                  size="small"
                                  value={story.averageRating}
                                  precision={0.1}
                                />
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid container alignItems="center">
                                <Hyperlink to={`/story/${story.id}#reviews`}>
                                  <Typography
                                    variant="body2"
                                    color="textPrimary"
                                    className={classes.reviewsAndReadsCount}
                                  >
                                    <Link color="inherit">
                                      <Trans>
                                        {story.reviewsCount} Rating
                                        {story.reviewsCount === 1
                                          ? ""
                                          : "s"}{" "}
                                        &bull; {story.readsCount} Reads
                                      </Trans>
                                    </Link>
                                  </Typography>
                                </Hyperlink>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {story.tags.map((tag) => (
                      <Grid item key={tag}>
                        <Hyperlink to={`/stories/search?tags=${tag}`}>
                          <Grid container className={classes.tag}>
                            <Typography
                              className={classes.tagText}
                              variant="body2"
                            >
                              {tag}
                            </Typography>
                          </Grid>
                        </Hyperlink>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hyperlink>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {story.canonicalAuthor && (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="caption" color="textSecondary">
                        <Trans>Written by</Trans>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <UserAvatar user={story.canonicalAuthor} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {story.author && (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="caption" color="textSecondary">
                        {story.canonicalAuthor
                          ? t`Published by`
                          : t`Written by`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <UserAvatar user={story.author} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container spacing={1} className={classes.actionsPanel}>
                  <Grid item xs={12}>
                    <FacebookShareButton className={classes.facebookButton} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <StoryReporter story={story} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

StoryInfoCard.propTypes = {
  story: PropTypes.object.isRequired,
};
