import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import BootstrapInput from "../Common/BootstrapInput";
import { t, Trans } from "@lingui/macro";

const HOME_DELIVERY_TYPE = "HOME_DELIVERY";
const PICK_UP_AT_STORE_TYPE = "PICK_UP_AT_STORE";

export default function DeliveryPreferenceEditor({
  deliveryPreferences,
  onChange,
  disabled,
}) {
  const [homeDeliveryEnabled, setHomeDeliveryEnabled] = useState(false);
  const [homeDeliveryNotes, setHomeDeliveryNotes] = useState(null);
  const [pickupEnabled, setPickupEnabled] = useState(false);
  const [pickupNotes, setPickupNotes] = useState(null);

  useEffect(() => {
    if (Array.isArray(deliveryPreferences) && deliveryPreferences.length) {
      const homeDelivery = deliveryPreferences.find(
        (pref) => pref.type === HOME_DELIVERY_TYPE
      );
      const pickup = deliveryPreferences.find(
        (pref) => pref.type === PICK_UP_AT_STORE_TYPE
      );
      if (homeDelivery) {
        setHomeDeliveryEnabled(true);
        setHomeDeliveryNotes(homeDelivery.notes);
      }
      if (pickup) {
        setPickupEnabled(true);
        setPickupNotes(pickup.notes);
      }
    }
  }, [deliveryPreferences]);

  useEffect(() => {
    const deliveryPreferences = [];
    if (homeDeliveryEnabled)
      deliveryPreferences.push({
        type: HOME_DELIVERY_TYPE,
        notes: homeDeliveryNotes,
      });
    if (pickupEnabled)
      deliveryPreferences.push({
        type: PICK_UP_AT_STORE_TYPE,
        notes: pickupNotes,
      });
    onChange(deliveryPreferences);
  }, [
    onChange,
    homeDeliveryEnabled,
    homeDeliveryNotes,
    pickupEnabled,
    pickupNotes,
  ]);

  const onChangeHomeDeliveryEnabled = (event) =>
    setHomeDeliveryEnabled(event.target.checked);
  const onChangePickupEnabled = (event) =>
    setPickupEnabled(event.target.checked);
  const onChangeHomeDeliveryNotes = (event) =>
    setHomeDeliveryNotes(event.target.value);
  const onChangePickupNotes = (event) => setPickupNotes(event.target.value);

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12} md={6} container>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={homeDeliveryEnabled}
                color="primary"
                onClick={onChangeHomeDeliveryEnabled}
                disabled={disabled}
              />
            }
            label={
              <Typography variant="body2">
                <Trans>Home Delivery</Trans>
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <BootstrapInput
            multiline
            rows={5}
            value={homeDeliveryNotes}
            disabled={disabled || !homeDeliveryEnabled}
            fullWidth
            placeholder="Additional notes or terms & conditions for home delivery (e.g, supported payment methods)"
            onChange={onChangeHomeDeliveryNotes}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} container>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={pickupEnabled}
                color="primary"
                onClick={onChangePickupEnabled}
                disabled={disabled}
              />
            }
            label={
              <Typography variant="body2">
                <Trans>Available for pickup at the shop</Trans>
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <BootstrapInput
            multiline
            rows={5}
            value={pickupNotes}
            disabled={disabled || !pickupEnabled}
            fullWidth
            placeholder={t`Additional notes or terms & conditions for picking up at the shop (e.g, supported payment methods)`}
            onChange={onChangePickupNotes}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

DeliveryPreferenceEditor.propTypes = {
  deliveryPreferences: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

DeliveryPreferenceEditor.defaultProps = {
  onChange() {},
  disabled: false,
};
