import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import BrokenIcon from "@material-ui/icons/BrokenImage";

const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  brokenIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(0.5),
    transform: "rotate(90deg)",
  },
}));

export default function UnavailablePostContent() {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container className={classes.banner} alignItems="center">
          <BrokenIcon className={classes.brokenIcon} />
          <Typography variant="body2" color="textSecondary">
            This content is no longer available!
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
