import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import GlobalNotificationCard from "./GlobalNotificationCard";
import NoResults from "../../Common/NoResultsImage";
import Banner from "../../Common/Banner";
import { GLOBAL_NOTIFICATIONS_PAGINATION_LIMIT } from "../../../config";
import { GlobalNotificationsLoader } from "../../Common/LoadingPlaceholders/GlobalNotificationsLoader";
import ErrorPage from "../../Error/ErrorPage";
import { useLoader } from "../../../utils/hooks";
import { SEARCH_GLOBAL_NOTIFICATIONS } from "../../../consts/queries";
import { useQuery } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  noNotificationsPlaceholder: {
    padding: theme.spacing(3),
  },
  bookRequestsList: {
    height: "80vh",
    overflow: "auto",
  },
  errorBanner: {
    padding: theme.spacing(3),
  },
  noNotificationsPlaceholderText: {
    fontWeight: "bold",
    textAlign: "center",
  },
}));

export default function GlobalNotificationsSearchController({
  fetchPolicy,
  spacing,
  searchArgs,
  noResultsMessage,
  xs,
  md,
  lg,
  xl,
}) {
  const classes = useStyles();
  const fetchOptions = {
    variables: {
      ...searchArgs,
      pagination: {
        limit: GLOBAL_NOTIFICATIONS_PAGINATION_LIMIT,
        offset: 0,
      },
    },
  };

  if (fetchPolicy) fetchOptions.fetchPolicy = fetchPolicy;
  const { loading, error, data, fetchMore } = useQuery(
    SEARCH_GLOBAL_NOTIFICATIONS,
    fetchOptions
  );
  useLoader(loading);

  const loadingPlaceholder = <GlobalNotificationsLoader />;

  if (error) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the global notifications due to a problem. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  if (loading && !data) {
    return (
      <Grid container justifyContent="center">
        {loadingPlaceholder}
      </Grid>
    );
  }

  const {
    searchGlobalAppNotifications: { globalAppNotifications, pagination },
  } = data;

  const loadMoreNotifications = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: GLOBAL_NOTIFICATIONS_PAGINATION_LIMIT,
          offset: globalAppNotifications.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          searchGlobalAppNotifications: {
            __typename: prev.searchGlobalAppNotifications.__typename,
            globalAppNotifications: [
              ...prev.searchGlobalAppNotifications.globalAppNotifications,
              ...fetchMoreResult.searchGlobalAppNotifications
                .globalAppNotifications,
            ],
            pagination: fetchMoreResult.searchGlobalAppNotifications.pagination,
          },
        });
      },
    }).catch(() => {});
  };

  if (error)
    return (
      <Grid container justifyContent="center" className={classes.errorBanner}>
        <Grid item xs={12}>
          <Banner
            type="error"
            title="Something went wrong!"
            message="We're sorry! Something went wrong while displaying global notifications for you!
              Please try again later."
          />
        </Grid>
      </Grid>
    );

  const noNotificationsPlaceholder = (
    <Grid
      container
      justifyContent="center"
      className={classes.noNotificationsPlaceholder}
    >
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="h5"
          color="primary"
          className={classes.noNotificationsPlaceholderText}
        >
          {noResultsMessage || "No global notifications!"}
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={6} lg={4}>
          <NoResults />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      {!globalAppNotifications.length ? noNotificationsPlaceholder : null}
      <InfiniteScroll
        style={{ overflow: "none !important" }}
        next={loadMoreNotifications}
        hasMore={globalAppNotifications.length < pagination.totalCount}
        loader={loadingPlaceholder}
        dataLength={globalAppNotifications.length}
      >
        <Grid container spacing={spacing}>
          {globalAppNotifications.map((notification) => (
            <Grid key={notification.id} item xs={xs} md={md} lg={lg} xl={xl}>
              <GlobalNotificationCard notification={notification} />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
    </Grid>
  );
}

GlobalNotificationsSearchController.propTypes = {
  searchArgs: PropTypes.object,
};

GlobalNotificationsSearchController.defaultProps = {
  searchArgs: {},
};
