import React from "react";
import { Grid } from "@material-ui/core";
import UserAvatar from "../Common/UserAvatar";
import DiscussionActions from "./DiscussionActions";

export default function DiscussionFooter({
  discussion,
  compact,
  isOwnDiscussion,
}) {
  return (
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid item>
        <UserAvatar user={discussion.user} />
      </Grid>
      <Grid item xs={12} sm={compact ? 12 : 6}>
        <DiscussionActions
          compact={compact}
          discussion={discussion}
          isOwnDiscussion={isOwnDiscussion}
        />
      </Grid>
    </Grid>
  );
}
