import React, { useState } from "react";
import IncomingOrdersSearchController from "./IncomingOrdersSearchController";
import {
  Grid,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  makeStyles,
  Button,
  SwipeableDrawer,
} from "@material-ui/core";
import { orderStatuses } from "../../consts";
import ApplyIcon from "@material-ui/icons/CheckCircle";
import FilterListIcon from "@material-ui/icons/FilterList";

const {
  OPEN,
  ACCEPTED,
  REJECTED,
  READY_FOR_PICKUP,
  DISPATCHED,
  DELIVERED,
  CANCELLED,
} = orderStatuses;

const useStyles = makeStyles((theme) => ({
  desktopOnly: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobileOnly: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  mobileFiltersDrawerContainer: {
    padding: theme.spacing(4),
  },
  mobileFilter: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  ordersContainer: {
    marginBottom: theme.spacing(3),
  },
}));

export default function ShopOrders({ shopId }) {
  const classes = useStyles();
  const [filtersDrawerOpen, setFiltersDrawerOpen] = useState(false);
  const initialStatuses = Object.values(orderStatuses).filter(
    (status) => status !== CANCELLED
  );
  const [selectedOrderStatuses, setSelectedOrderStatuses] =
    useState(initialStatuses);
  const isOrderStatusSelected = (status) =>
    selectedOrderStatuses.includes(status);
  const onChangeOrderStatusFilter = (event) => {
    const status = event.target.value;
    if (event.target.checked) {
      setSelectedOrderStatuses((statuses) => [
        ...new Set([...statuses, status]),
      ]);
    } else {
      setSelectedOrderStatuses((statuses) =>
        statuses.filter((curr) => curr !== status)
      );
    }
  };
  const toggleFiltersDrawer = () => setFiltersDrawerOpen((isOpen) => !isOpen);

  const filterStatuses = [
    {
      status: OPEN,
      label: "New Orders",
    },
    {
      status: ACCEPTED,
      label: "Accepted",
    },
    {
      status: REJECTED,
      label: "Rejected",
    },
    {
      status: READY_FOR_PICKUP,
      label: "Ready for Pick-up",
    },
    {
      status: DISPATCHED,
      label: "Dispatched",
    },
    {
      status: DELIVERED,
      label: "Delivered",
    },
    {
      status: CANCELLED,
      label: "Cancelled by the customer",
    },
  ];

  const filtersForm = (
    <FormControl component="fieldset">
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <FormLabel component="legend">Filter by Order Status</FormLabel>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            {filterStatuses.map((curr) => (
              <FormControlLabel
                key={curr.status}
                control={
                  <Checkbox
                    color="primary"
                    checked={isOrderStatusSelected(curr.status)}
                    onChange={onChangeOrderStatusFilter}
                    value={curr.status}
                  />
                }
                label={curr.label}
              />
            ))}
          </FormGroup>
        </Grid>
      </Grid>
    </FormControl>
  );

  return (
    <Grid container>
      <Grid item xs={12} className={classes.mobileFilter}>
        <Grid container justifyContent="center">
          <Button
            variant="text"
            onClick={toggleFiltersDrawer}
            startIcon={<FilterListIcon />}
          >
            Filter Orders
          </Button>
          <SwipeableDrawer
            open={filtersDrawerOpen}
            disableBackdropTransition
            onClose={toggleFiltersDrawer}
            onOpen={toggleFiltersDrawer}
            hysteresis={0.1}
            disableSwipeToOpen
          >
            <Grid container className={classes.mobileFiltersDrawerContainer}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {filtersForm}
                </Grid>
                <Grid container item xs={12} justifyContent="center">
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      fullWidth
                      disableElevation
                      startIcon={<ApplyIcon />}
                      color="primary"
                      size="large"
                      onClick={toggleFiltersDrawer}
                    >
                      Done
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </SwipeableDrawer>
        </Grid>
      </Grid>
      <Grid item xs={2} className={classes.desktopOnly}>
        {filtersForm}
      </Grid>
      <Grid item xs={12} lg={10} className={classes.ordersContainer}>
        <IncomingOrdersSearchController
          searchArgs={{ shopId, statuses: selectedOrderStatuses }}
        />
      </Grid>
    </Grid>
  );
}
