import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import Hyperlink from "../Common/Hyperlink";

const useStyles = makeStyles((theme) => {
  const tabBaseStyles = {
    borderRadius: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette.primary.lighter,
    },
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    justifyContent: "flex-end",
  };
  return {
    tab: tabBaseStyles,
    selectedTab: {
      ...tabBaseStyles,
      backgroundColor: theme.palette.primary.lighter,
    },
    iconContainer: {
      marginLeft: theme.spacing(1),
      color: theme.palette.primary.main,
      "& *": {
        fontSize: 16,
      },
    },
    iconInnerContainer: {
      height: "100%",
    },
  };
});

export default function GroupSidebarTab({ title, to, icon, selected }) {
  const classes = useStyles();
  return (
    <Hyperlink to={to}>
      <Grid container className={selected ? classes.selectedTab : classes.tab}>
        <Grid item>
          <Grid container alignItems="center">
            <Typography variant="subtitle2" color="primary">
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.iconContainer}>
          <Grid
            container
            alignItems="center"
            className={classes.iconInnerContainer}
          >
            {icon}
          </Grid>
        </Grid>
      </Grid>
    </Hyperlink>
  );
}

GroupSidebarTab.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.object,
  selected: PropTypes.bool.isRequired,
};
