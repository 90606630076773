import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import UserPointsSearchController from "../UserPointsSearchController";
import { t } from "@lingui/macro";

export default function PointsHistoryPage({ userId }) {
  return (
    <Grid container role="tabpanel">
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10} lg={8}>
            <UserPointsSearchController
              searchArgs={{ userId }}
              noResultsMessage={t`No history!`}
              xs={12}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

PointsHistoryPage.propTypes = {
  userId: PropTypes.string.isRequired,
};
