import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { t } from "@lingui/macro";
import ListingsSearchController from "../../Search/ListingsSearchController";

export default function ActiveListingsPage({ userId, searchQuery }) {
  return (
    <Grid container>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10} xl={12}>
          <ListingsSearchController
            cachePolicy="cache-first"
            searchArgs={{ query: searchQuery, userScope: userId }}
            clickableCards={false}
            enableActions={true}
            showEndOfResultsBanner={false}
            noResultsMessage={t`No Ads Found!`}
            xs={12}
            xl={6}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

ActiveListingsPage.propTypes = {
  userId: PropTypes.string.isRequired,
  searchQuery: PropTypes.string,
};
