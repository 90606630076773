import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import React, { useEffect, useState } from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  useDispatchErrorMessage,
  useDispatchInfoMessage,
  useLoginPrompt,
  useStoryReadingPreferences,
} from "../../utils/hooks";
import { DISPLAYED_GENRES_COUNT, STORY_GENRES } from "../consts";
import { useMutation } from "@apollo/client";
import { UPDATE_PROFILE } from "../../consts/mutations";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => {
  const genreCardCommon = {
    cursor: "pointer",
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(4),
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  };

  return {
    readingPreferenceIcon: {
      color: orange[700],
    },
    genreCard: genreCardCommon,
    genreCardSelected: {
      ...genreCardCommon,
      backgroundColor: theme.palette.primary.lighter,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
    genreText: {
      color: theme.palette.grey[700],
      fontWeight: "bold",
    },
    genreTextSelected: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
  };
});

const ALL_GENRES = Object.values(STORY_GENRES);

export default function ReadingPreferencesDialog() {
  const classes = useStyles();
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const dispatchInfoMessage = useDispatchInfoMessage();
  const dispatchErrorMessage = useDispatchErrorMessage();
  const [invalidPrefsCount, setInvalidPrefsCount] = useState(false);
  const [updatePreferences, { loading: updatingPreferences }] =
    useMutation(UPDATE_PROFILE);
  const [prefEditorOpen, setPrefEditorOpen] = useState(false);
  const [preferredGenres, setPreferredGenres] = useState([]);
  const { error, genres } = useStoryReadingPreferences({
    skip: preferredGenres.length,
  });

  const openEditor = createLoginPromptEventHandler({
    redirectPath: "/stories",
    callback: () => {
      setPrefEditorOpen(true);
    },
  });
  const closeEditor = () => {
    setPreferredGenres([]);
    setPrefEditorOpen(false);
  };

  useEffect(() => {
    if (!genres) return;
    if (genres.length) {
      setPreferredGenres(genres.map((g) => g.code));
    }
  }, [genres]);

  if (error) return null;

  const isGenrePreferred = (genreCode) => preferredGenres.includes(genreCode);

  const createGenreSelectToggler = (genreCode) => () => {
    if (preferredGenres.includes(genreCode)) {
      setPreferredGenres((genres) => genres.filter((g) => g !== genreCode));
    } else {
      setPreferredGenres((genres) => [...genres, genreCode]);
    }
  };

  const updateReadingPreferences = () => {
    if (preferredGenres.length < DISPLAYED_GENRES_COUNT) {
      setInvalidPrefsCount(true);
      return;
    }
    updatePreferences({
      variables: {
        user: {
          preferredStoryGenres: preferredGenres,
        },
      },
    })
      .then(() => {
        dispatchInfoMessage(t`Your reading preferences were updated!`);
        closeEditor();
      })
      .catch(() => {
        dispatchErrorMessage(
          t`Unable to save your preferences at the moment. Please try again later.`
        );
      });
  };

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        open={prefEditorOpen}
        onClose={closeEditor}
        disableScrollLock
      >
        <DialogTitle>
          <Trans>Your Reading Preferences</Trans>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {ALL_GENRES.map((genreObj) => (
                  <Grid item key={genreObj.code}>
                    <Grid
                      container
                      className={
                        isGenrePreferred(genreObj.code)
                          ? classes.genreCardSelected
                          : classes.genreCard
                      }
                      onClick={createGenreSelectToggler(genreObj.code)}
                    >
                      <Typography
                        variant="body1"
                        className={
                          isGenrePreferred(genreObj.code)
                            ? classes.genreTextSelected
                            : classes.genreText
                        }
                      >
                        {genreObj.text}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {invalidPrefsCount && (
              <Grid item xs={12}>
                <Typography variant="body2" color="secondary">
                  <Trans>
                    Please select at least {DISPLAYED_GENRES_COUNT} preferences
                  </Trans>
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={updatingPreferences}
            onClick={closeEditor}
            color="default"
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            disabled={updatingPreferences}
            onClick={updateReadingPreferences}
            color="primary"
          >
            <Trans>Save Preferences</Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="text"
        onClick={openEditor}
        startIcon={<SettingsIcon className={classes.readingPreferenceIcon} />}
      >
        <Trans>Update Reading Preferences</Trans>
      </Button>
    </>
  );
}
