import { makeStyles, TextareaAutosize } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => {
  const baseMessage = {
    lineHeight: 1.5,
    borderWidth: "1px",
    borderColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    width: "100%",
    padding: theme.spacing(2),
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
    outline: "none",
    maxWidth: "100%",
    minWidth: "100%",
    minHeight: theme.spacing(8),
  };

  return {
    messageText: baseMessage,
    messageTextError: {
      ...baseMessage,
      borderColor: "red",
    },
  };
});

export default function TextEditor(props) {
  const classes = useStyles();
  const textAreaClass = props.error
    ? classes.messageTextError
    : classes.messageText;
  return (
    <TextareaAutosize
      {...props}
      className={[textAreaClass, props.className].join(" ").trim()}
    />
  );
}
