import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  makeStyles,
  Switch,
  Typography,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { REVIEW_STORY } from "../../../../consts/mutations";
import {
  useCurrentPath,
  useDispatchErrorMessage,
  useLoginPrompt,
} from "../../../../utils/hooks";
import { Rating } from "@material-ui/lab";
import TextEditor from "../../../Common/TextEditor";
import { SEARCH_STORY_REVIEWS } from "../../../../consts/queries";
import { STORY_REVIEWS_PAGINATION_LIMIT } from "../../../../config";
import { t, Trans } from "@lingui/macro";
import Required from "../../../Common/Required";

const useStyles = makeStyles(() => ({
  reviewButton: {
    textTransform: "none",
  },
}));

export default function StoryReviewEditorDialog({
  storyId,
  currentUserReview,
  buttonProps,
  disabled,
}) {
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const classes = useStyles();
  const dispatchErrorMessage = useDispatchErrorMessage();
  const [reviewerOpen, setReviewerOpen] = useState(false);
  const [reviewStory] = useMutation(REVIEW_STORY);
  const [review, setReview] = useState(null);
  const [rating, setRating] = useState(0);
  const [containsSpoilers, setContainsSpoilers] = useState(false);
  const [invalidRating, setInvalidRating] = useState(false);
  const loginRedirectPath = useCurrentPath();

  useEffect(() => {
    setInvalidRating(false);
    setReview(currentUserReview?.review);
    setRating(currentUserReview?.rating || 0);
    setContainsSpoilers(!!currentUserReview?.containsSpoilers);
  }, [currentUserReview]);

  const toggleOpenReviewer = createLoginPromptEventHandler({
    redirectPath: loginRedirectPath,
    callback: () => {
      setReviewerOpen((isOpen) => !isOpen);
    },
  });

  const submitReview = () => {
    if (!rating) {
      setInvalidRating(!rating);
      return;
    }
    const reviewText = (review || "").trim();
    reviewStory({
      variables: {
        storyReview: {
          storyId,
          rating,
          review: reviewText,
          containsSpoilers: !!reviewText && containsSpoilers,
        },
      },
      refetchQueries: [
        {
          query: SEARCH_STORY_REVIEWS,
          variables: {
            storyId,
            pagination: {
              limit: STORY_REVIEWS_PAGINATION_LIMIT,
              offset: 0,
            },
          },
        },
      ],
    })
      .then(() => {
        toggleOpenReviewer();
      })
      .catch(() => {
        dispatchErrorMessage(
          t`An error occurred while submitting the review. Please try again later!`
        );
      });
  };

  const handleChangeRating = (event, value) => setRating(value);
  const handleChangeReview = (event) => {
    setReview(event.target.value);
    if (!event.target.value) {
      setContainsSpoilers(false);
    }
  };
  const handleChangeContainsSpoilers = (event) =>
    setContainsSpoilers(event.target.checked);

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={reviewerOpen}
        onClose={toggleOpenReviewer}
        disableScrollLock
      >
        <DialogTitle>
          <Trans>Write a review</Trans>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="body1">
                    <Required>
                      <Trans>Your rating</Trans>
                    </Required>
                  </Typography>
                </Grid>
                <Grid item>
                  <Rating
                    disabled={disabled}
                    onChange={handleChangeRating}
                    value={rating}
                  />
                </Grid>
                {invalidRating && (
                  <Grid item xs={12}>
                    <FormHelperText error={invalidRating}>
                      <Trans>Rating is required</Trans>
                    </FormHelperText>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextEditor
                value={review}
                placeholder="Write your review here (optional)"
                disabled={disabled}
                rows={4}
                onChange={handleChangeReview}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="body1" className={classes.lightText}>
                    <Trans>Does this review contain spoilers?</Trans>
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography
                        variant="body1"
                        align="right"
                        className={classes.lightText}
                      >
                        <Trans>No</Trans>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Switch
                        disabled={!review}
                        checked={containsSpoilers}
                        onChange={handleChangeContainsSpoilers}
                        color="primary"
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        align="left"
                        className={classes.lightText}
                      >
                        <Trans>Yes</Trans>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={disabled}
            onClick={toggleOpenReviewer}
            color="default"
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button disabled={disabled} onClick={submitReview} color="primary">
            <Trans>Submit</Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="contained"
        color="default"
        disableElevation
        disableRipple
        size="small"
        {...buttonProps}
        className={[classes.reviewButton, buttonProps.className].join(" ")}
        onClick={toggleOpenReviewer}
      >
        {currentUserReview ? (
          <Trans>Edit your review</Trans>
        ) : (
          <Trans>Write a review</Trans>
        )}
      </Button>
    </>
  );
}

StoryReviewEditorDialog.propTypes = {
  storyId: PropTypes.string.isRequired,
  currentUserReview: PropTypes.object,
  buttonProps: PropTypes.object,
};

StoryReviewEditorDialog.defaultProps = {
  buttonProps: {},
};
