import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  Menu,
  MenuItem,
  Typography,
  Divider,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/MoreHoriz";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import ConfirmPrompt from "../Common/ConfirmPrompt";
import ConfirmPromptRigerous from "../Common/ConfirmPromptRigerous";
import {
  TOGGLE_FOLLOW_GROUP,
  LEAVE_GROUP,
  DELETE_GROUP,
} from "../../consts/mutations";
import { GET_GROUP, SEARCH_LISTINGS } from "../../consts/queries";
import {
  GENERIC_ALERT_ERROR_MESSAGE,
  LISTINGS_PAGINATION_LIMIT,
} from "../../config";
import { useDispatch } from "react-redux";
import { actionTypes } from "../index.reducer";
import FollowIcon from "@material-ui/icons/Notifications";
import Hyperlink from "../Common/Hyperlink";
import BookRequestIcon from "../Requests/BookRequestIcon";
import BookIcon from "@material-ui/icons/Book";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import LeaveIcon from "@material-ui/icons/ExitToApp";
import InviteIcon from "@material-ui/icons/PersonAdd";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CreateIcon from "@material-ui/icons/Add";
import BookChatIcon from "../Discussions/BookChatIcon";
import { useLoggedInUser } from "../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  userMenu: {
    "& *": {
      fontSize: "14px",
    },
  },
  actionButtonsPanel: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  menuIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function GroupActionButtons({
  group,
  onClickInvite,
  onLeaveGroup,
  onDeleteGroup,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const loggedInUser = useLoggedInUser();
  const [leaveGroupDecisionPending, setLeaveGroupDecisionPending] =
    useState(false);
  const [deleteGroupDecisionPending, setDeleteGroupDecisionPending] =
    useState(false);
  const [leaveGroup, { loading: leavingGroup }] = useMutation(LEAVE_GROUP, {
    refetchQueries: [
      { query: GET_GROUP, variables: { groupId: group.id } },
      {
        query: SEARCH_LISTINGS,
        variables: {
          groupScope: group.id,
          pagination: {
            limit: LISTINGS_PAGINATION_LIMIT,
            offset: 0,
          },
        },
      },
    ],
  });
  const [deleteGroup, { loading: deletingGroup }] = useMutation(DELETE_GROUP);
  const [toggleFollowGroup, { loading: toggleFollowGroupPending }] =
    useMutation(TOGGLE_FOLLOW_GROUP);
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] = useState(null);
  const [createDropDownAnchorEl, setCreateDropDownAnchorEl] = useState(null);

  const dispatchErrorMessage = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });

  const dispatchInfoMessage = (message) =>
    dispatch({
      type: actionTypes.INFO_MESSAGE,
      message,
    });

  const isPublicGroup = group?.visibility === "PUBLIC";
  const allowInviting = !isPublicGroup;
  const handleOpenMoreActions = (event) =>
    setMoreActionsAnchorEl(event.currentTarget);
  const handleCreateActions = (event) =>
    setCreateDropDownAnchorEl(event.currentTarget);
  const handleCloseMoreActions = () => setMoreActionsAnchorEl(null);
  const handleCloseCreateActions = () => setCreateDropDownAnchorEl(null);
  const createMenuAction =
    (actionFn) =>
    (...args) => {
      handleCloseMoreActions();
      handleCloseCreateActions();
      actionFn && actionFn(...args);
    };
  const handleInvite = createMenuAction(onClickInvite);
  const handleEditGroup = createMenuAction(() =>
    history.push(`/group/${group?.id}/edit`)
  );
  const showLeavePrompt = createMenuAction(() =>
    setLeaveGroupDecisionPending(true)
  );
  const showDeleteGroupPrompt = createMenuAction(() =>
    setDeleteGroupDecisionPending(true)
  );
  const closeDeleteGroupPrompt = () => setDeleteGroupDecisionPending(false);
  const handleLeaveGroupDecision = createMenuAction((accept) => {
    setLeaveGroupDecisionPending(false);
    if (accept) {
      leaveGroup({
        variables: { groupId: group.id },
      })
        .then(() => {
          onLeaveGroup();
        })
        .catch(() => {
          dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
        });
    }
  });

  const handleDeleteGroupDecision = createMenuAction((confirmed) => {
    setDeleteGroupDecisionPending(false);
    if (confirmed) {
      deleteGroup({
        variables: { groupId: group.id },
      })
        .then(() => {
          onDeleteGroup();
        })
        .catch(() => {
          dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
        });
    } else {
      dispatchInfoMessage(
        "Incorrect group name provided to confirm group deletion. Action cancelled!"
      );
    }
  });

  const handleToggleFollowGroup = () => {
    if (!loggedInUser) {
      const { pathname, search } = history.location;
      return history.push("/login", { redirectPath: `${pathname}${search}` });
    }

    toggleFollowGroup({
      variables: {
        groupId: group.id,
      },
    }).catch(() => {
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message: GENERIC_ALERT_ERROR_MESSAGE,
      });
    });
  };

  const leaveGroupMessage =
    "Are you sure you want to leave the group? Your listings shared in this group will also be removed from the group.";
  const deleteGroupMessage = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        Are you sure you want to delete the group <b>{group.name}</b>?
      </Grid>
      <Grid item xs={12}>
        This group currently has{" "}
        <b>
          {group.membersCount} member{group.membersCount > 1 ? "s" : ""}
        </b>{" "}
        and{" "}
        <b>
          {group.listingsCount} ad{group.listingsCount > 1 ? "s" : ""}.
        </b>
      </Grid>
      <Grid item xs={12}>
        Once deleted, no one will be able to post ads in this group, and this
        action cannot be undone. If you are certain, please type the group name{" "}
        <b>{group.name}</b> in the following text box and confirm.
      </Grid>
    </Grid>
  );

  return (
    <Grid container spacing={2} className={classes.actionButtonsPanel}>
      <ConfirmPrompt
        title="Leave Group"
        open={leaveGroupDecisionPending}
        onAction={handleLeaveGroupDecision}
        question={leaveGroupMessage}
      />
      <ConfirmPromptRigerous
        title="Delete Group"
        open={deleteGroupDecisionPending}
        onAction={handleDeleteGroupDecision}
        onClose={closeDeleteGroupPrompt}
        question={deleteGroupMessage}
        assertText={group.name}
      />
      <Grid item>
        <Tooltip title={"Get notified on any new activity in the group"}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            startIcon={<FollowIcon />}
            onClick={handleToggleFollowGroup}
            disabled={toggleFollowGroupPending}
          >
            {group.isFollowed ? "Unfollow Group" : "Follow Group"}
          </Button>
        </Tooltip>
      </Grid>
      <Grid item>
        <Button
          startIcon={<CreateIcon />}
          endIcon={<ArrowDropDownIcon />}
          variant="contained"
          disableElevation
          color="primary"
          onClick={handleCreateActions}
        >
          Create
        </Button>
        <Menu
          anchorEl={createDropDownAnchorEl}
          keepMounted
          open={!!createDropDownAnchorEl}
          onClose={handleCloseCreateActions}
          className={classes.userMenu}
          disableScrollLock
        >
          <MenuItem
            component={Hyperlink}
            to={`/listing/create?groupScope=${group.id}`}
          >
            <BookIcon className={classes.menuIcon} />
            New Advertisement
          </MenuItem>
          <MenuItem
            component={Hyperlink}
            to={`/book-request/create?groupScope=${group.id}`}
          >
            <BookRequestIcon className={classes.menuIcon} />
            Book Request
          </MenuItem>
          <MenuItem
            component={Hyperlink}
            to={`/bookchat/new?groupScope=${group.id}`}
            onClick={createMenuAction()}
          >
            <BookChatIcon className={classes.menuIcon} />
            Book Chat Discussion
          </MenuItem>
        </Menu>
      </Grid>
      <Grid item>
        <Button
          startIcon={<MenuIcon />}
          variant="text"
          color="primary"
          onClick={handleOpenMoreActions}
        >
          More Actions
        </Button>
        <Menu
          anchorEl={moreActionsAnchorEl}
          keepMounted
          open={!!moreActionsAnchorEl}
          onClose={handleCloseMoreActions}
          className={classes.userMenu}
          disableScrollLock
        >
          {group.isCurrentUserOwner && (
            <MenuItem onClick={handleEditGroup}>
              <EditIcon className={classes.menuIcon} />
              Edit group
            </MenuItem>
          )}
          {allowInviting && (
            <MenuItem onClick={handleInvite}>
              <InviteIcon className={classes.menuIcon} />
              Invite others
            </MenuItem>
          )}
          {group.isCurrentUserOwner || allowInviting ? <Divider /> : null}
          {group.isCurrentUserOwner && (
            <MenuItem disabled={deletingGroup} onClick={showDeleteGroupPrompt}>
              <DeleteIcon className={classes.menuIcon} color="secondary" />
              <Typography color="secondary">Delete group</Typography>
            </MenuItem>
          )}
          <MenuItem onClick={showLeavePrompt} disabled={leavingGroup}>
            <LeaveIcon className={classes.menuIcon} color="secondary" />
            <Typography color="secondary">Leave Group</Typography>
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}

GroupActionButtons.propTypes = {
  group: PropTypes.object.isRequired,
  onClickInvite: PropTypes.func,
  onLeaveGroup: PropTypes.func,
  onDeleteGroup: PropTypes.func,
};

GroupActionButtons.defaultProps = {
  enableOwnerActions: false,
  onClickInvite: () => {},
  onLeaveGroup: () => {},
  onDeleteGroup: () => {},
};
