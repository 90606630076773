import { Trans } from "@lingui/macro";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import React from "react";
import ImageFadeIn from "./Application/Common/ImageFadeIn";
import Hyperlink from "./Application/Common/Hyperlink";
import { useLoginPrompt } from "./utils/hooks";

const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: theme.spacing(10) * 2,
    width: "100%",
  },
  loginButton: {
    textTransform: "none",
  },
  signUpButton: {
    color: theme.palette.background.paper,
    backgroundColor: green[500],
    border: "none",
    "&:hover": {
      backgroundColor: green[700],
    },
    textTransform: "none",
  },
  dialogContainer: {
    overflow: "hidden",
  },
}));

export default function LoginPrompt() {
  const classes = useStyles();
  const { isPrompted, dismissLoginPrompt, onLoginRedirectPath } =
    useLoginPrompt();
  const redirectPath =
    onLoginRedirectPath ||
    `${window.location.pathname}${window.location.search}`;

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="xs"
      open={isPrompted}
      onClose={dismissLoginPrompt}
    >
      <DialogContent dividers={false} className={classes.dialogContainer}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="flex-start">
              <ImageFadeIn src="/logo/logo_main.svg" className={classes.logo} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h6" color="primary">
              <Trans>It's time to join bibliocircle!</Trans>{" "}
              <span aria-label="tada">🎉</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="body1" color="primary">
              <Trans>
                Creating a bibliocircle account will let you buy/sell/request
                books, read stories, critiques, book discussions and many more.
              </Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button
                      onClick={dismissLoginPrompt}
                      component={Hyperlink}
                      to={{
                        pathname: "/signup",
                        state: {
                          redirectPath,
                        },
                      }}
                      variant="contained"
                      size="large"
                      className={classes.signUpButton}
                      disableElevation
                      disableRipple
                    >
                      <Trans> I like to create an account</Trans>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button
                      onClick={dismissLoginPrompt}
                      component={Hyperlink}
                      to={{
                        pathname: "/login",
                        state: {
                          redirectPath,
                        },
                      }}
                      variant="contained"
                      size="large"
                      className={classes.loginButton}
                      color="primary"
                      disableElevation
                      disableRipple
                    >
                      <Trans>I already have an account</Trans>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={dismissLoginPrompt}>
          <Trans>Skip for now</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
