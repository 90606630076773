import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { STORY_CHAPTERS_PAGINATION_LIMIT } from "../../../config";
import { useQuery } from "@apollo/client";
import { SEARCH_STORY_CHAPTERS } from "../../../consts/queries";
import ErrorPage from "../../Error/ErrorPage";
import { useLoader } from "../../../utils/hooks";
import StoryChapterCard from "./StoryChapterCard";
import { StoryChaptersLoader } from "../../Common/LoadingPlaceholders/StoryChaptersLoader";

const useStyles = makeStyles((theme) => ({
  noStoryChaptersPlaceholder: {
    padding: theme.spacing(2),
  },
  errorBanner: {
    padding: theme.spacing(3),
  },
  noStoryChaptersPlaceholderText: {
    fontWeight: "bold",
    textAlign: "center",
  },
}));

export default function StoryChaptersSearchController({
  fetchPolicy,
  manageMode,
  scrollableTarget,
  fixedResultsCount,
  searchArgs,
  noResultsMessage,
  xs,
  md,
  lg,
  xl,
}) {
  const classes = useStyles();
  const fetchOptions = {
    variables: {
      ...searchArgs,
      pagination: {
        limit: fixedResultsCount || STORY_CHAPTERS_PAGINATION_LIMIT,
        offset: 0,
      },
    },
  };

  if (fetchPolicy) fetchOptions.fetchPolicy = fetchPolicy;
  const {
    loading,
    error: fetchStoryChaptersError,
    data,
    fetchMore,
  } = useQuery(SEARCH_STORY_CHAPTERS, fetchOptions);
  useLoader(loading);

  const loadingPlaceholder = (
    <StoryChaptersLoader xs={xs} md={md} lg={lg} xl={xl} />
  );

  if (fetchStoryChaptersError) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the chapters due to a problem!"
        statusCode={500}
      />
    );
  }

  if (loading && !data) {
    return (
      <Grid container justifyContent="center">
        {loadingPlaceholder}
      </Grid>
    );
  }

  const {
    searchStoryChapters: { chapters, pagination },
  } = data;

  const loadMoreStoryChapters = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: STORY_CHAPTERS_PAGINATION_LIMIT,
          offset: chapters.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          searchStoryChapters: {
            __typename: prev.searchStoryChapters.__typename,
            chapters: [
              ...prev.searchStoryChapters.chapters,
              ...fetchMoreResult.searchStoryChapters.chapters,
            ],
            pagination: fetchMoreResult.searchStoryChapters.pagination,
          },
        });
      },
    }).catch(() => {});
  };

  const noStoryChaptersPlaceholder = (
    <Grid
      container
      justifyContent="center"
      className={classes.noStoryChaptersPlaceholder}
    >
      <Grid item xs={12}>
        <Typography variant="subtitle2" color="textSecondary">
          {noResultsMessage || "No chapters!"}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      {!chapters.length ? noStoryChaptersPlaceholder : null}
      <InfiniteScroll
        scrollableTarget={scrollableTarget}
        style={{ overflow: "none !important" }}
        next={!fixedResultsCount && loadMoreStoryChapters}
        hasMore={chapters.length < pagination.totalCount}
        loader={!fixedResultsCount && loadingPlaceholder}
        dataLength={chapters.length}
      >
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {chapters.map((chapter) => (
              <Grid key={chapter.id} item xs={xs} md={md} lg={lg} xl={xl}>
                <StoryChapterCard chapter={chapter} manageMode={manageMode} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </InfiniteScroll>
    </Grid>
  );
}

StoryChaptersSearchController.propTypes = {
  searchArgs: PropTypes.object,
  manageMode: PropTypes.bool,
};

StoryChaptersSearchController.defaultProps = {
  searchArgs: {},
  cardSize: "md",
};
