import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {
  Grid,
  makeStyles,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import PriceTag from "../Common/PriceTag";
import AddToCartIcon from "@material-ui/icons/AddShoppingCart";
import Hyperlink from "../Common/Hyperlink";
import { useDispatch } from "react-redux";
import ConfirmPrompt from "../Common/ConfirmPrompt";
import { useMutation } from "@apollo/client";
import { DELETE_LISTING } from "../../consts/mutations";
import { SEARCH_LISTINGS } from "../../consts/queries";
import {
  GENERIC_ALERT_ERROR_MESSAGE,
  LISTINGS_PAGINATION_LIMIT,
} from "../../config";
import InactiveIcon from "@material-ui/icons/VisibilityOff";
import { actionTypes } from "../index.reducer";
import {
  BOOK_CONDITIONS,
  TEMP_LISTING_INACTIVE_REASONS,
  listingConditions,
} from "../consts";
import { t, Trans } from "@lingui/macro";
import { addToCartEnabled, getUserFullName } from "../../utils/common";
import ImageFadeIn from "../Common/ImageFadeIn";
import {
  useAddToCart,
  useCurrentPath,
  useLoggedInUser,
  useLoginPrompt,
} from "../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  coverImageContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    position: "relative",
    borderRadius: theme.spacing(1),
  },
  coverImage: {
    width: "100%",
    maxHeight: "200px",
    objectFit: "contain",
    zIndex: 0,
  },
  coverImagePlaceholder: {
    width: "80%",
    height: "100px",
    objectFit: "contain",
  },
  placeholderIcon: {
    color: theme.palette.primary.main,
  },
  itemContent: {
    color: theme.palette.grey[800],
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "20px",
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  inactiveListingOverlay: {
    position: "absolute",
    top: 0,
    borderRadius: theme.spacing(1),
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.grey[200],
    zIndex: 10,
    opacity: 0.8,
  },
  inactiveListingOverlayText: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20,
    fontSize: 32,
    color: theme.palette.grey[800],
  },
  inactiveListingIcon: {
    marginRight: theme.spacing(1),
  },
  thumbnailContainer: {
    position: "relative",
  },
  clickableCard: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
    borderRadius: "4px",
  },
  staticCard: {
    borderRadius: theme.shape.borderRadius,
  },
  priceTagContainer: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  actionButtonsContainer: {
    justifyContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  shopName: {
    fontWeight: "bold",
  },
  bookPackBanner: {
    backgroundColor: theme.palette.grey[600],
    opacity: 0.95,
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "15%",
    borderRadius: "0 0 4px 4px",
  },
  bookPackBannerText: {
    color: theme.palette.background.paper,
    fontWeight: "bold",
  },
  listingTitle: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      marginTop: theme.spacing(1),
    },
  },
  listingTitleText: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  newBooks: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
  usedBooks: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.grey[300],
  },
  discountBadge: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.background.paper,
    fontWeight: "bold",
    position: "absolute",
    fontSize: 14,
    height: theme.spacing(6.5),
    width: theme.spacing(6.5),
    alignItems: "flex-start",
    justifyContent: "flex-end",
    display: "flex",
    zIndex: 1,
    flexDirection: "column",
    top: 0,
    left: 0,
    borderRadius: `${theme.spacing(1)}px 0  ${theme.spacing(6)}px 0`,
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(0.8),
  },
}));

export default function ListingCard({
  item,
  clickable = true,
  actions = false,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const addToCart = useAddToCart();
  const currentPath = useCurrentPath();
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const loggedInUser = useLoggedInUser();
  const [deleteDecisionPending, setDeleteDecisionPending] = useState(false);
  const [deletingListingId, setDeletingListing] = useState(null);
  const [deleteListing] = useMutation(DELETE_LISTING);
  const isBookPack = item.booksCount > 1;

  const dispatchError = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });
  const cardClass = clickable ? classes.clickableCard : classes.staticCard;
  let thumbnail;

  if (item.coverImage) {
    thumbnail = (
      <ImageFadeIn
        src={`${item.coverImage}/sm`}
        alt={item.title}
        className={classes.coverImage}
      />
    );
  } else {
    thumbnail = (
      <ImageFadeIn
        src="/book-placeholder.svg"
        alt={item.title}
        className={classes.coverImagePlaceholder}
      />
    );
  }

  const handleClickDelete = (listingId) => () => {
    setDeleteDecisionPending(true);
    setDeletingListing(listingId);
  };

  const handleAddToCart = (event) => {
    event.preventDefault();
    createLoginPromptEventHandler({
      redirectPath: currentPath,
      callback: () => addToCart(item),
    })();
  };

  const handleDeleteDecision = (accept) => {
    setDeleteDecisionPending(false);
    if (accept) {
      return deleteListing({
        variables: { id: deletingListingId },
        refetchQueries: [
          {
            query: SEARCH_LISTINGS,
            variables: {
              userScope: loggedInUser.id,
              pagination: {
                limit: LISTINGS_PAGINATION_LIMIT,
                offset: 0,
              },
            },
          },
        ],
      })
        .then(() => {
          setDeletingListing(null);
        })
        .catch((err) => {
          dispatchError(GENERIC_ALERT_ERROR_MESSAGE);
        });
    }
  };

  const actionButtons = (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      spacing={1}
      className={classes.actionButtonsContainer}
    >
      <Grid item xs={isSmallScreen ? 10 : 0}>
        <Grid container justifyContent="center">
          <Button
            fullWidth
            component={Hyperlink}
            to={`/listing/${item.id}`}
            startIcon={<ViewIcon />}
            color="default"
            disableElevation
            variant="contained"
            size="small"
          >
            <Trans>View Listing</Trans>
          </Button>
        </Grid>
      </Grid>
      {loggedInUser?.id === item?.seller?.id && (
        <>
          <Grid item xs={isSmallScreen ? 10 : 0}>
            <Grid container justifyContent="center">
              <Button
                fullWidth
                component={Hyperlink}
                to={`/listing/${item.id}/edit`}
                startIcon={<EditIcon />}
                color="default"
                disableElevation
                variant="text"
                size="small"
              >
                <Trans>Edit</Trans>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={isSmallScreen ? 10 : 0}>
            <Grid container justifyContent="center">
              <Button
                fullWidth
                startIcon={<DeleteIcon />}
                color="secondary"
                disableElevation
                variant="text"
                size="small"
                onClick={handleClickDelete(item.id)}
              >
                <Trans>Delete</Trans>
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );

  const thumbnailContainer = (
    <Grid
      container
      item
      xs={12}
      sm={4}
      alignItems="center"
      className={classes.thumbnailContainer}
    >
      {!item.active && <div className={classes.inactiveListingOverlay}></div>}
      {!item.active && (
        <Grid
          container
          className={classes.inactiveListingOverlayText}
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <InactiveIcon className={classes.inactiveListingIcon} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              {item.inactiveReason
                ? TEMP_LISTING_INACTIVE_REASONS.find(
                    (r) => r.value === item.inactiveReason
                  )?.shortText || t`Inactive`
                : t`Inactive`}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        className={classes.coverImageContainer}
        justifyContent="center"
        alignContent="stretch"
        alignItems="center"
      >
        {!!item.discountPercentage && (
          <div className={classes.discountBadge}>
            <div>-{item.discountPercentage}%</div>
          </div>
        )}
        {thumbnail}
        {isBookPack && (
          <Grid
            container
            item
            className={classes.bookPackBanner}
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              className={classes.bookPackBannerText}
              variant="subtitle2"
              align="center"
            >
              {t`${item.booksCount} Books Pack`}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const listingCard = (
    <>
      <ConfirmPrompt
        open={deleteDecisionPending}
        onAction={handleDeleteDecision}
        question={t`Are you sure you want delete this listing?`}
      />
      <Grid container className={cardClass}>
        {thumbnailContainer}
        <Grid container item xs={12} sm={8} className={classes.itemContent}>
          <Grid container alignContent="flex-start" spacing={1}>
            <Grid container item xs={12} className={classes.listingTitle}>
              <Typography variant="h6" className={classes.listingTitleText}>
                {item.title}
              </Typography>
            </Grid>
            {!!item.conditions && (
              <Grid item>
                <Grid
                  container
                  className={
                    item.conditions === BOOK_CONDITIONS.NEW
                      ? classes.newBooks
                      : classes.usedBooks
                  }
                >
                  <Typography variant="caption">
                    {listingConditions[item.conditions]}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {item.seller && !item.shop && (
              <Grid container item xs={12} justifyContent="flex-start">
                <Typography variant="body2">
                  {t`by ${getUserFullName(item.seller)}`}
                </Typography>
              </Grid>
            )}
            {item.shop && (
              <Grid container item xs={12} justifyContent="flex-start">
                <Typography variant="body2">
                  <Trans>available in</Trans>{" "}
                  <span className={classes.shopName}>{item.shop?.name}</span>
                </Typography>
              </Grid>
            )}
            <Grid container item xs={12} justifyContent="flex-start">
              <Typography variant="body2">
                {t`on ${dayjs(item.createdAt).format(
                  "MMMM D, YYYY [at] h:mm A"
                )}`}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={8}
              justifyContent="flex-start"
              alignContent="flex-end"
              className={classes.priceTagContainer}
            >
              <PriceTag
                price={item.price}
                originalPrice={item.originalPrice}
                size="sm"
                priceModelType={item.priceModelType}
              />
            </Grid>
            {addToCartEnabled(item) && !item.shop?.closed && (
              <Grid item xs={12}>
                <Button
                  onClick={handleAddToCart}
                  startIcon={<AddToCartIcon />}
                  variant="contained"
                  disableElevation
                >
                  Add to Cart
                </Button>
              </Grid>
            )}
            {item.shop?.closed && (
              <Typography variant="caption" color="textSecondary">
                &bull;{" "}
                <Trans>Seller is not accepting orders at the moment.</Trans>
              </Typography>
            )}
            {actions ? actionButtons : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  return clickable ? (
    <Hyperlink to={`/listing/${item.id}`}>{listingCard}</Hyperlink>
  ) : (
    listingCard
  );
}

ListingCard.propTypes = {
  item: PropTypes.object.isRequired,
  clickable: PropTypes.object.isRequired,
};
