import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import ShopCard from "./ShopCard";
import NoResults from "../Common/NoResultsImage";
import Banner from "../Common/Banner";
import { SHOPS_PAGINATION_LIMIT } from "../../config";
import { useQuery } from "@apollo/client";
import { SEARCH_SHOPS } from "../../consts/queries";
import { ShopsLoader } from "../Common/LoadingPlaceholders/ShopsLoader";
import ErrorPage from "../Error/ErrorPage";
import { useLoader } from "../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  noShopsPlaceholder: {
    padding: theme.spacing(3),
  },
  bookRequestsList: {
    height: "80vh",
    overflow: "auto",
  },
  errorBanner: {
    padding: theme.spacing(3),
  },
  noShopsPlaceholderText: {
    fontWeight: "bold",
  },
}));

export default function ShopsSearchController({
  fetchPolicy,
  spacing,
  component,
  fixedResultsCount,
  searchArgs,
  noResultsMessage,
  xs,
  sm,
  md,
  lg,
  xl,
}) {
  const classes = useStyles();
  const fetchOptions = {
    variables: {
      ...searchArgs,
      pagination: {
        limit: fixedResultsCount || SHOPS_PAGINATION_LIMIT,
        offset: 0,
      },
    },
  };

  if (fetchPolicy) fetchOptions.fetchPolicy = fetchPolicy;
  const ShopCardComponent = component || ShopCard;
  const { loading, error, data, fetchMore } = useQuery(
    SEARCH_SHOPS,
    fetchOptions
  );
  useLoader(loading);

  const loadingPlaceholder = (
    <ShopsLoader xs={xs} sm={sm} md={md} lg={lg} xl={xl} />
  );

  if (error) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the shops due to a problem. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  if (loading && !data) {
    return (
      <Grid container justifyContent="center">
        {loadingPlaceholder}
      </Grid>
    );
  }

  const {
    searchShops: { shops, pagination },
  } = data;

  const loadMoreShops = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: SHOPS_PAGINATION_LIMIT,
          offset: shops.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          searchShops: {
            __typename: prev.searchShops.__typename,
            shops: [
              ...prev.searchShops.shops,
              ...fetchMoreResult.searchShops.shops,
            ],
            pagination: fetchMoreResult.searchShops.pagination,
          },
        });
      },
    }).catch(() => {});
  };

  if (error)
    return (
      <Grid container justifyContent="center" className={classes.errorBanner}>
        <Grid item xs={12}>
          <Banner
            type="error"
            title="Something went wrong!"
            message="We're sorry! Something went wrong while displaying shops for you!
              Please try again later."
          />
        </Grid>
      </Grid>
    );

  const noShopsPlaceholder = (
    <Grid
      container
      justifyContent="center"
      className={classes.noShopsPlaceholder}
    >
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="h5"
          color="primary"
          className={classes.noShopsPlaceholderText}
        >
          {noResultsMessage ||
            "Sorry, we could not find the shop you are looking for!"}
        </Typography>
      </Grid>
      <Grid container item xs={10} sm={6} lg={4}>
        <NoResults />
      </Grid>
    </Grid>
  );

  return (
    <Grid container item xs={12}>
      {!shops.length ? noShopsPlaceholder : null}
      <InfiniteScroll
        style={{ overflow: "none !important" }}
        next={!fixedResultsCount && loadMoreShops}
        hasMore={shops.length < pagination.totalCount}
        loader={
          !fixedResultsCount && (
            <ShopsLoader xs={xs} sm={sm} md={md} lg={lg} xl={xl} />
          )
        }
        dataLength={shops.length}
      >
        <Grid container item xs={12} spacing={spacing}>
          {shops.map((shop) => (
            <Grid key={shop.id} item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
              <Grid container justifyContent="flex-start">
                <ShopCardComponent shop={shop} />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
    </Grid>
  );
}

ShopsSearchController.propTypes = {
  searchArgs: PropTypes.object,
  noResultsMessage: PropTypes.string,
};

ShopsSearchController.defaultProps = {
  searchArgs: {},
};
