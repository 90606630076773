import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Grid,
  makeStyles,
  Button,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import LandingPage from "./LandingPage";
import BootstrapInput from "../Application/Common/BootstrapInput";
import SignUpLink from "./common/SignUpLink";
import { useMutation } from "@apollo/client";
import {
  VALIDATE_PASSWORD_RESET_TOKEN,
  RESET_PASSWORD,
} from "../consts/mutations";
import { useDispatch } from "react-redux";
import { actionTypes } from "../Application/index.reducer";
import { BACKEND_URL } from "../config";
import Hyperlink from "../Application/Common/Hyperlink";
import PageHead from "../Application/Common/PageHead";
import ErrorPage from "../Application/Error/ErrorPage";
import { useLoader } from "../utils/hooks";
import { initiateGoogleLogin, isWebViewAvailable } from "../mobile/events";

const useStyles = makeStyles((theme) => ({
  inputField: {
    backgroundColor: "white",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
  },
  contentHeading: {
    fontWeight: "bold",
  },
  contentDescription: {
    textAlign: "center",
  },
  confirmBtn: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.getContrastText(theme.palette.grey[800]),
  },
  resetPasswordFormContainer: {
    paddingTop: "5vh",
    paddingBottom: "5vh",
  },
}));

function ResetPassword() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [password, setPassword] = useState(null);
  const [confirmedPassword, setConfirmedPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmedPasswordError, setConfirmedPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { resetPasswordToken } = useParams();
  const [
    validateResetPasswordToken,
    {
      loading: validatingToken,
      error: tokenValidationError,
      data: validationResponse,
    },
  ] = useMutation(VALIDATE_PASSWORD_RESET_TOKEN);

  const [resetPassword, { loading: resettingPassword }] =
    useMutation(RESET_PASSWORD);

  const dispatchErrorMessage = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });

  useLoader(validatingToken || resettingPassword);

  if (tokenValidationError) {
    dispatch({
      type: actionTypes.EMIT_ERROR,
      error: tokenValidationError,
    });
  }

  useEffect(() => {
    validateResetPasswordToken({
      variables: {
        verificationArgs: { id: resetPasswordToken },
      },
    });
  }, [validateResetPasswordToken, resetPasswordToken]);

  const onChangePassword = (event) => {
    setPasswordError(false);
    setPassword(event.target.value);
  };
  const onChangeConfirmedPassword = (event) => {
    setConfirmedPasswordError(false);
    setConfirmedPassword(event.target.value);
  };

  function onSubmitForm() {
    setPasswordError(!password);
    setConfirmedPasswordError(!confirmedPassword);
    if (!password || !confirmedPassword) {
      setErrorMessage("Both fields are required!");
      return;
    }

    if (password !== confirmedPassword) {
      setPasswordError(true);
      setConfirmedPasswordError(true);
      setErrorMessage("Passwords do not match");
      return false;
    }

    resetPassword({
      variables: {
        passwordUpdate: {
          passwordResetToken: resetPasswordToken,
          newPassword: password,
        },
      },
    })
      .then(({ data }) => {
        const { resetPassword: user } = data;
        history.push("/login", {
          ...history.location.state,
          resetPasswordUser: user,
        });
      })
      .catch(() => {
        dispatchErrorMessage(
          "Something went wrong while resetting your password. Please try again!"
        );
      });
  }

  const onKeyDown = (event) => {
    if (event.keyCode === 13) onSubmitForm();
  };

  if (validationResponse && !validationResponse.validatePasswordResetToken) {
    return (
      <ErrorPage
        title="Not Found"
        description="The password reset link is either expired or invalid. Please try again later."
        statusCode={403}
        showNavigationButton
        navigationButtonText="Go To Login Page"
        navigationButtonUrl="/login"
      />
    );
  }

  const notifyMobileLogin = (event) => {
    initiateGoogleLogin();
    if (isWebViewAvailable()) {
      event.preventDefault();
    }
  };

  const resetPasswordForm = (
    <Grid container spacing={10}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={10}>
            <Grid container justifyContent="center">
              <Typography variant="h6" className={classes.contentDescription}>
                Please set a new login password for your account
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={10} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BootstrapInput
                  onKeyDown={onKeyDown}
                  type="password"
                  placeholder="New password"
                  className={classes.inputField}
                  onChange={onChangePassword}
                  error={passwordError}
                ></BootstrapInput>
              </Grid>
              <Grid item xs={12}>
                <BootstrapInput
                  onKeyDown={onKeyDown}
                  type="password"
                  placeholder="Confirm new password"
                  className={classes.inputField}
                  onChange={onChangeConfirmedPassword}
                  error={confirmedPasswordError}
                ></BootstrapInput>
              </Grid>
              <Grid item xs={12} hidden={!errorMessage}>
                <FormHelperText error>{errorMessage}</FormHelperText>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Button
                disableElevation
                variant="contained"
                onClick={onSubmitForm}
                className={classes.confirmBtn}
              >
                Update Password
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={8} md={10} lg={6}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={8} xl={6}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" align="center">
                      If you are a returning user,
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <Button
                        variant="contained"
                        fullWidth
                        disableElevation
                        className={classes.loginTypeButton}
                        component={Hyperlink}
                        to="/login"
                      >
                        Login with Password
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} xl={6}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" align="center">
                      If you are a Google user,
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      disableElevation
                      className={classes.loginTypeButton}
                      href={`${BACKEND_URL}/auth/google/login`}
                      onClick={notifyMobileLogin}
                    >
                      Login with Google
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container xs={12} justifyContent="center">
        <SignUpLink />
      </Grid>
    </Grid>
  );

  const verifyingTokenPlaceholder = (
    <Grid container xs={12} justifyContent="center">
      <Typography variant="h6" className={classes.contentDescription}>
        Please wait while we are verifying your request ...
      </Typography>
    </Grid>
  );

  return (
    <LandingPage coverImage="/forgotpassword.svg">
      <PageHead title="Reset Password | bibliocircle" />
      <Grid
        container
        justifyContent="center"
        spacing={10}
        className={classes.resetPasswordFormContainer}
      >
        <Grid item xs={10}>
          <Typography
            variant="h3"
            align="center"
            className={classes.contentHeading}
          >
            Reset Password
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {validatingToken ? verifyingTokenPlaceholder : resetPasswordForm}
        </Grid>
      </Grid>
    </LandingPage>
  );
}

export default ResetPassword;
