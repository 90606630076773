import React, { useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  Button,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import LandingPage from "./LandingPage";
import BootstrapInput from "../Application/Common/BootstrapInput";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actionTypes } from "../Application/index.reducer";
import { CREATE_USER } from "../consts/mutations";
import { BACKEND_URL, GENERIC_ALERT_ERROR_MESSAGE } from "../config";
import Hyperlink from "../Application/Common/Hyperlink";
import LoginIcon from "@material-ui/icons/ExitToApp";
import PageHead from "../Application/Common/PageHead";
import {
  extractFieldError,
  getFormattedError,
  validateObject,
} from "../utils/schemaValidator";
import signUpSchema from "./sign-up.schema";
import { DB_VALIDATION_ERRORS } from "../Application/consts";
import { useLoader, useQueryParams } from "../utils/hooks";
import GoogleIcon from "../Application/Common/GoogleIcon";
import { initiateGoogleLogin, isWebViewAvailable } from "../mobile/events";

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "100%",
  },
  contentHeading: {
    fontWeight: "bold",
  },
  contentSubHeading: {},
  signUpBtnContainer: {
    marginBottom: "30px",
  },
  signUpBtn: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.getContrastText(theme.palette.grey[800]),
    border: "none",
  },
  loginTypeButton: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.getContrastText(theme.palette.grey[800]),
    border: "none",
  },
  signUpFormContainer: {
    paddingTop: "5vh",
    paddingBottom: "5vh",
  },
  plainLink: {
    color: "white",
    textDecoration: "none",
  },
  formHelperText: {
    color: theme.palette.background.paper,
  },
}));

function SignUp() {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = useQueryParams();
  const dispatch = useDispatch();
  const [validationErrors, setValidationErrors] = useState([]);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmedPassword, setConfirmedPassword] = useState(null);
  const dispatchErrorMessage = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });
  const getFieldError = (fieldName) =>
    extractFieldError(validationErrors, fieldName);

  const getValidatedFormData = (formData) => {
    setValidationErrors([]);
    const { validatedObject, errors } = validateObject(formData, signUpSchema);
    if (errors.length) {
      setValidationErrors(errors);
      return false;
    } else {
      return validatedObject;
    }
  };

  const [createUser, { loading, data, error }] = useMutation(CREATE_USER);
  useLoader(loading);

  useEffect(() => {
    dispatch({ type: actionTypes.EMIT_ERROR, error });
  }, [dispatch, error]);

  const changeFirstName = (event) => setFirstName(event.target.value);
  const changeLastName = (event) => setLastName(event.target.value);
  const changeEmail = (event) => setEmail(event.target.value);
  const changePassword = (event) => setPassword(event.target.value);
  const changeConfirmedPassword = (event) =>
    setConfirmedPassword(event.target.value);

  const onClickSignUp = () => {
    const validatedFormData = getValidatedFormData({
      firstName,
      lastName,
      email,
      password,
      confirmedPassword,
    });

    if (!validatedFormData) {
      dispatchErrorMessage(
        "Some of the fields contain invalid values. Please check again!"
      );
      return;
    }

    createUser({
      variables: {
        user: {
          firstName: validatedFormData.firstName,
          lastName: validatedFormData.lastName,
          email: validatedFormData.email,
          password: validatedFormData.password,
        },
        params: {
          redirectPath:
            history.location.state?.redirectPath ||
            queryParams.get("redirectPath"),
        },
      },
    }).catch((err) => {
      if (err.graphQLErrors?.[0]?.code === DB_VALIDATION_ERRORS.DUPLICATE) {
        dispatchErrorMessage(
          "User account already exists with the provided email!"
        );
      } else {
        dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
      }
    });
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) onClickSignUp();
  };

  if (data && data.createUser) {
    history.push("/signup/confirm");
  }

  const getFormHelperText = (fieldName) => (
    <FormHelperText
      className={classes.formHelperText}
      hidden={!getFieldError(fieldName)}
    >
      {fieldName === "confirmedPassword"
        ? "Passwords do not match!"
        : getFormattedError(getFieldError(fieldName))}
    </FormHelperText>
  );

  const notifyMobileLogin = (event) => {
    initiateGoogleLogin();
    if (isWebViewAvailable()) {
      event.preventDefault();
    }
  };

  return (
    <LandingPage>
      <PageHead title="Sign Up | bibliocircle" />
      <Grid container spacing={6} className={classes.signUpFormContainer}>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Typography
              variant="h6"
              align="center"
              className={classes.contentSubHeading}
            >
              Join the Book Readers Network
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Typography
              align="center"
              variant="h3"
              className={classes.contentHeading}
            >
              Sign Up
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={10} md={10} lg={6} onKeyDown={onKeyDown}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <BootstrapInput
                    error={getFieldError("firstName")}
                    placeholder="First Name"
                    className={classes.inputField}
                    onChange={changeFirstName}
                  ></BootstrapInput>
                  {getFormHelperText("firstName")}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <BootstrapInput
                    error={getFieldError("lastName")}
                    placeholder="Last Name"
                    className={classes.inputField}
                    onChange={changeLastName}
                  ></BootstrapInput>
                  {getFormHelperText("lastName")}
                </Grid>
                <Grid item xs={12}>
                  <BootstrapInput
                    error={getFieldError("email")}
                    placeholder="Email"
                    className={classes.inputField}
                    onChange={changeEmail}
                  ></BootstrapInput>
                  {getFormHelperText("email")}
                </Grid>
                <Grid item xs={12}>
                  <BootstrapInput
                    error={getFieldError("password")}
                    placeholder="Password"
                    type="password"
                    className={classes.inputField}
                    onChange={changePassword}
                  ></BootstrapInput>
                  {getFormHelperText("password")}
                </Grid>
                <Grid item xs={12}>
                  <BootstrapInput
                    error={getFieldError("confirmedPassword")}
                    placeholder="Confirm Password"
                    type="password"
                    className={classes.inputField}
                    onChange={changeConfirmedPassword}
                  ></BootstrapInput>
                  {getFormHelperText("confirmedPassword")}
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="center"
                    className={classes.signUpBtnContainer}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      className={classes.signUpBtn}
                      disableElevation
                      onClick={onClickSignUp}
                    >
                      Sign up
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={10} justifyContent="center">
                    <Grid item xs={12}>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} sm={8} xl={6}>
                          <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12}>
                              <Typography variant="subtitle2" align="center">
                                If you are a returning user,
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container justifyContent="center">
                                <Button
                                  variant="contained"
                                  fullWidth
                                  disableElevation
                                  className={classes.loginTypeButton}
                                  component={Hyperlink}
                                  to="/login"
                                >
                                  Login with Password
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={8} xl={6}>
                          <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12}>
                              <Typography variant="subtitle2" align="center">
                                If you are a Google user,
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                startIcon={<GoogleIcon />}
                                variant="contained"
                                fullWidth
                                disableElevation
                                className={classes.loginTypeButton}
                                href={`${BACKEND_URL}/auth/google/login`}
                                onClick={notifyMobileLogin}
                              >
                                Login with Google
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center">
                        <Grid item xs={8}>
                          <Grid container justifyContent="center">
                            <Button
                              variant="text"
                              fullWidth
                              endIcon={<LoginIcon />}
                              disableElevation
                              style={{ color: "white" }}
                              component={Hyperlink}
                              to={"/"}
                            >
                              Continue as a guest
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LandingPage>
  );
}

export default SignUp;
