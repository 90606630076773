import React, { useState } from "react";
import PropTypes from "prop-types";
import ShopTab from "./ShopTab";
import HomeIcon from "@material-ui/icons/Home";
import { useLoader, useQueryParams } from "../../utils/hooks";
import { useLocation } from "react-router-dom";
import { Grid, Button, Tooltip, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import TabEditorDialog from "./TabEditorDialog";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { UPDATE_SHOP_PAGES } from "../../consts/mutations";
import { actionTypes } from "../index.reducer";
import { GENERIC_ALERT_ERROR_MESSAGE } from "../../config";
import { t } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}));

export default function ShopTabs({ shop, viewAsAdmin }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const location = useLocation();
  const [showEditTabsDialog, setShowEditTabsDialog] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);
  const pagesList = shop.pages || [];
  const homePagePath = `/shop/${shop.identifier}`;
  const allBooksPath = `/shop/${shop.identifier}/listings`;
  const currentPath = location.pathname;
  const [updatePages] = useMutation(UPDATE_SHOP_PAGES);
  useLoader(actionInProgress);

  const onClickEditTabs = () => setShowEditTabsDialog(true);
  const onCloseTabsDialog = () => setShowEditTabsDialog(false);
  const onSaveTabs = async (tabs) => {
    setActionInProgress(true);
    try {
      await updatePages({
        variables: {
          shopId: shop.id,
          pages: tabs,
        },
      });
    } catch (err) {
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message: GENERIC_ALERT_ERROR_MESSAGE,
      });
    }
    setShowEditTabsDialog(false);
    setActionInProgress(false);
  };

  const editTabsComponent = (
    <Grid item>
      <Tooltip title="Add/Remove/Edit or Change the order of the tabs">
        <Button
          startIcon={<EditIcon />}
          variant="text"
          color="secondary"
          onClick={onClickEditTabs}
        >
          Customize Tabs
        </Button>
      </Tooltip>
      <TabEditorDialog
        open={showEditTabsDialog}
        tabs={shop?.pages || []}
        onClose={onCloseTabsDialog}
        onSave={onSaveTabs}
        disabled={actionInProgress}
      />
    </Grid>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item>
            <Grid container className={classes.tabsContainer}>
              <ShopTab
                title={t`Home`}
                url={homePagePath}
                primary
                icon={<HomeIcon />}
                selected={currentPath === homePagePath}
              />
              <ShopTab
                title={t`All Books`}
                url={allBooksPath}
                selected={currentPath === allBooksPath && !location.search}
              />
              {pagesList.map((page) => (
                <ShopTab
                  key={page.id}
                  title={page.title}
                  tags={page.tags}
                  pageId={page.id}
                  selected={page.id === queryParams.get("page")}
                  shopIdentifier={shop.identifier}
                />
              ))}
            </Grid>
          </Grid>
          {viewAsAdmin && editTabsComponent}
        </Grid>
      </Grid>
    </Grid>
  );
}

ShopTabs.propTypes = {
  shop: PropTypes.object.isRequired,
};

ShopTab.propTypes = {
  shop: {
    pages: [],
  },
};
