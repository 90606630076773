import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import BootstrapInputBordered from "../Common/BootstrapInputBordered";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CREATE_CHAT_GROUP, SEND_CHAT_MESSAGE } from "../../consts/mutations";
import { actionTypes } from "../index.reducer";
import { GENERIC_ALERT_ERROR_MESSAGE } from "../../config";
import ChatIcon from "./ChatIcon";
import { useCurrentPath, useLoginPrompt } from "../../utils/hooks";
import { useMutation } from "@apollo/client";

const useStyles = makeStyles(() => ({
  messageText: {
    lineHeight: 1.5,
  },
}));

export default function ChatButton({
  initialMessage,
  recipient,
  label,
  buttonProps = {},
}) {
  const classes = useStyles();
  const currentPath = useCurrentPath();
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const btnProps = {
    variant: "contained",
    color: "primary",
    size: "small",
    disableElevation: true,
    startIcon: <ChatIcon />,
    ...buttonProps,
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const [createChatGroup] = useMutation(CREATE_CHAT_GROUP);
  const [sendChatMessage] = useMutation(SEND_CHAT_MESSAGE);
  const [sendChatDialogOpen, setSendChatDialogOpen] = useState(false);
  const [sendMessageInProgress, setSendMessageInProgress] = useState(false);
  const [messageText, setMessageText] = useState("");
  const toggleChatDialog = () => setSendChatDialogOpen((isOpen) => !isOpen);
  const title = `Send a message to ${
    recipient.firstName || recipient.lastName || "this user"
  }`;

  const onClickToggleChatDialog = (event) => {
    event.preventDefault();
    createLoginPromptEventHandler({
      redirectPath: currentPath,
      callback: toggleChatDialog,
    })(event);
  };

  const dispatchErrorMessage = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });

  const handleMessageChange = (event) => setMessageText(event.target.value);

  const sendMessage = () => {
    setSendMessageInProgress(true);
    const createChatMessage = async () => {
      const { data: chatGroupData } = await createChatGroup({
        variables: {
          chatGroup: {
            participantIds: [recipient.id],
          },
        },
      });
      const {
        createChatGroup: { id, threadId },
      } = chatGroupData;

      const { data: chatMessageData } = await sendChatMessage({
        variables: {
          chatGroupId: id,
          message: messageText || initialMessage,
        },
      });

      if (!chatMessageData?.createChatMessage?.id) {
        throw new Error("failed to send the chat message");
      }

      history.push(`/chat/${id}/thread/${threadId}`);
    };

    createChatMessage()
      .catch(() => {
        dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
      })
      .finally(() => {
        setSendMessageInProgress(false);
        toggleChatDialog();
      });
  };
  return (
    <>
      <Tooltip title={title}>
        <Button {...btnProps} onClick={onClickToggleChatDialog}>
          {label || "Chat"}
        </Button>
      </Tooltip>
      <Dialog
        open={sendChatDialogOpen}
        onClose={onClickToggleChatDialog}
        maxWidth="sm"
        fullWidth
        disableScrollLock
        disableBackdropClick
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <BootstrapInputBordered
                fullWidth
                multiline
                rows={4}
                placeholder="Type your message here"
                onChange={handleMessageChange}
                defaultValue={initialMessage}
                value={messageText}
                disabled={sendMessageInProgress}
                className={classes.messageText}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={sendMessageInProgress}
            onClick={onClickToggleChatDialog}
            color="default"
          >
            Cancel
          </Button>
          <Button
            disabled={sendMessageInProgress || !messageText}
            onClick={sendMessage}
            color="primary"
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ChatButton.propTypes = {
  initialMessage: PropTypes.string,
  recipient: PropTypes.object.isRequired,
  buttonProps: PropTypes.object,
};
