import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import PhoneNumberManager from "../../Common/PhoneNumbers/PhoneNumberManager";
import AddressManager from "../../Common/Addresses/AddressManager";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  sectionSubTitle: {
    color: theme.palette.grey[700],
    fontWeight: "bolder",
    paddingTop: "10px",
    paddingBottom: "20px",
    marginTop: "20px",
  },
}));

export default function UserContactDetailsPage() {
  const classes = useStyles();

  const contactDetails = (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={classes.sectionSubTitle}>
          <Trans>Phone Numbers</Trans>
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <PhoneNumberManager />
      </Grid>
    </Grid>
  );

  const addresses = (
    <Grid container alignContent="flex-end" alignItems="flex-end">
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={classes.sectionSubTitle}>
          <Trans>Addresses</Trans>
        </Typography>
      </Grid>
      <Grid item container xs={10}>
        <AddressManager />
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      <Grid item xs={12} md={10}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            {contactDetails}
          </Grid>
          <Grid item xs={12} sm={6}>
            {addresses}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
