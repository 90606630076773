export const getNotificationData = (notification, key) =>
  (notification.data || []).find((item) => item.key === key)?.value;

export const getNotificationTargetUrl = (notification) => {
  try {
    switch (notification.type) {
      case "BOOK_REQUEST_NOTIFICATION":
        return `/book-request/${getNotificationData(notification, "id")}`;
      case "WELCOME_NOTIFICATION":
        return `/profile/${getNotificationData(notification, "userId")}`;
      case "NEW_FOLLOWER_NOTIFICATION":
        return `/profile/${getNotificationData(notification, "followerId")}`;
      case "USER_FOLLOWER_LISTING_NOTIFICATION":
      case "GROUP_FOLLOWER_LISTING_NOTIFICATION":
      case "SHOP_FOLLOWER_LISTING_NOTIFICATION":
        return `/listing/${getNotificationData(notification, "listingId")}`;
      case "USER_FOLLOWER_BOOK_REQUEST_NOTIFICATION":
      case "GROUP_FOLLOWER_BOOK_REQUEST_NOTIFICATION":
        return `/book-request/${getNotificationData(
          notification,
          "bookRequestId"
        )}`;
      case "NEW_LISTING_COLLECTION_NOTIFICATION":
        return `/collection/${getNotificationData(
          notification,
          "listingCollectionId"
        )}`;
      case "NEW_CRITIQUE_NOTIFICATION":
        return `/critique/${getNotificationData(notification, "critiqueId")}`;

      case "NEW_ORDER_NOTIFICATION":
      case "ORDER_CANCELLED_NOTIFICATION":
      case "ORDER_STATUS_CHANGE_NOTIFICATION":
      case "NEW_ORDER_MESSAGE_NOTIFICATION":
        return `/order/${getNotificationData(notification, "orderId")}`;
      case "NEW_DISCUSSION_REPLY_NOTIFICATION":
      case "NEW_DISCUSSION_NOTIFICATION":
        const groupId = getNotificationData(notification, "groupId");
        const discussionId = getNotificationData(notification, "discussionId");
        if (!groupId) return `/bookchat/${discussionId}`;
        return `/group/${groupId}/bookchat/${discussionId}`;
      case "NEW_CHAT_MESSAGE_NOTIFICATION":
        return `/chat/${getNotificationData(
          notification,
          "chatGroupId"
        )}/thread/${getNotificationData(notification, "threadId")}`;
      case "NEW_STORY_NOTIFICATION":
      case "NEW_STORY_REVIEW_NOTIFICATION":
        return `/story/${getNotificationData(notification, "storyId")}`;
      case "NEW_STORY_CHAPTER_COMMENT_NOTIFICATION":
      case "NEW_STORY_CHAPTER_NOTIFICATION":
        return `/story/${getNotificationData(
          notification,
          "storyId"
        )}/chapter/${getNotificationData(notification, "chapterId")}`;
      case "GLOBAL_ANNOUNCEMENT_NOTIFICATION":
        return getNotificationData(notification, "url");
      default:
        return "/";
    }
  } catch (err) {
    return "/";
  }
};
