import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Button } from "@material-ui/core";
import BookCard from "./BookCard";
import AddIcon from "@material-ui/icons/AddCircle";
import BookEditorDialog from "./BookEditorDialog";
import { Trans } from "@lingui/macro";

export default function BookManager({
  books,
  newOnly,
  onUpdateBooks,
  disabled,
  requireISBN,
  singleton,
}) {
  const [currentBooks, setCurrentBooks] = useState([]);
  const [bookOnEditor, setBookOnEditor] = useState(null);
  const [editorOpen, setEditorOpen] = useState(false);

  useEffect(() => {
    setCurrentBooks(books.map((book, index) => ({ ...book, index })));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onUpdateBooks(currentBooks);
  }, [onUpdateBooks, currentBooks]);

  const openEditorDialog = () => setEditorOpen(true);
  const closeEditorDialog = () => setEditorOpen(false);

  const onClickAddBook = () => {
    setBookOnEditor(null);
    openEditorDialog();
  };

  const onEditBook = (book) => () => {
    setBookOnEditor({ ...book });
    openEditorDialog();
  };

  const onSaveBook = (savedBook) => {
    if (typeof savedBook.index === "number") {
      setCurrentBooks((curr) =>
        curr.map((book, index) => {
          if (index === savedBook.index) {
            return savedBook;
          }
          return book;
        })
      );
    } else {
      setCurrentBooks((curr) => [
        ...curr,
        { ...savedBook, index: curr.length },
      ]);
    }

    closeEditorDialog();
  };

  const removeBook = (bookIndex) => () => {
    setCurrentBooks((books) =>
      books.filter((book) => book.index !== bookIndex)
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} spacing={2}>
        {currentBooks.map((book) => (
          <Grid key={book.id} item xs={12}>
            <BookCard
              book={book}
              onClickEdit={onEditBook(book)}
              onClickRemove={removeBook(book.index)}
              disabled={disabled}
            />
          </Grid>
        ))}
      </Grid>
      {(!singleton || (!!singleton && currentBooks.length === 0)) && (
        <Grid container item xs={12}>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            onClick={onClickAddBook}
            disabled={disabled}
            disableElevation
          >
            <Trans>Add book</Trans>
          </Button>
        </Grid>
      )}
      <BookEditorDialog
        hideCondition={newOnly}
        open={editorOpen}
        onClose={closeEditorDialog}
        book={bookOnEditor}
        disabled={disabled}
        onSave={onSaveBook}
        resetFormOnSave
        requireISBN={requireISBN}
      />
    </Grid>
  );
}

BookManager.propTypes = {
  books: PropTypes.arrayOf(PropTypes.object),
  onUpdateBooks: PropTypes.func,
  disabled: PropTypes.bool,
  newOnly: PropTypes.bool,
  requireISBN: PropTypes.bool,
  singleton: PropTypes.bool,
};

BookManager.defaultProps = {
  books: [],
  onUpdateBooks() {},
  disabled: false,
  newOnly: false,
  requireISBN: false,
  singleton: false,
};
