import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import ReadingCheckpointsSearchController from "../../Stories/ReadingCheckpoints/ReadingCheckpointsSearchController";

export default function UserReadingStoriesPage({ userId }) {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={10}>
        <ReadingCheckpointsSearchController
          xs={12}
          searchArgs={{
            finished: false,
            userId,
          }}
        />
      </Grid>
    </Grid>
  );
}

UserReadingStoriesPage.propTypes = {
  userId: PropTypes.string.isRequired,
};
