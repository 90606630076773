import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ApolloClient, ApolloProvider } from "@apollo/client";
import { from, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { InMemoryCache } from "@apollo/client";
import { ThemeProvider } from "@material-ui/core/styles";
import LocalisationProvider from "./localisationProvider";
import { bcTheme } from "./theme";
import { CssBaseline } from "@material-ui/core";
import { Provider } from "react-redux";
import store from "./store";
import { BACKEND_URL } from "./config";
import "placeholder-loading/src/scss/placeholder-loading.scss";
import PageHead from "./Application/Common/PageHead";
import { GRAPHQL_ERRORS } from "./Application/consts";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([
    onError(({ graphQLErrors }) => {
      if (graphQLErrors) {
        // Redirect user back to login if user needs to re-authenticate
        const requireReAuth = graphQLErrors.some(
          ({ gqlErrorCode }) => gqlErrorCode === GRAPHQL_ERRORS.UNAUTHENTICATED
        );
        if (requireReAuth) {
          try {
            const redirectPath = window.location.href.replace(
              window.location.origin,
              ""
            );
            const loginPath = `/login?redirectPath=${encodeURIComponent(
              redirectPath
            )}`;
            window.location.href = new URL(
              loginPath,
              window.location.origin
            ).toString();
          } catch (err) {
            window.location.pathname = "/login";
          }
        }
      }
    }),
    new HttpLink({
      uri: `${BACKEND_URL}/graphql`,
      credentials: "include",
    }),
  ]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <LocalisationProvider>
      <PageHead title="bibliocircle" />
      <ApolloProvider client={client}>
        <ThemeProvider theme={bcTheme}>
          <CssBaseline />
          <Provider store={store}>
            <App />
          </Provider>
        </ThemeProvider>
      </ApolloProvider>
    </LocalisationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
