import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  makeStyles,
  Container,
  Button,
  InputLabel,
  FormControl,
  withStyles,
  FormHelperText,
  Switch,
} from "@material-ui/core";
import PageTitle from "../../Common/PageTitle";
import { useMutation, useQuery } from "@apollo/client";
import { IMAGE_SERVER_URL } from "../../../config";
import { useDispatch } from "react-redux";
import { actionTypes } from "../../index.reducer";
import { GET_STORY } from "../../../consts/queries";
import { CREATE_STORY, UPDATE_STORY } from "../../../consts/mutations";
import { dataURLtoBlob, isDataUrl } from "../../../utils/fileOperations";
import Required from "../../Common/Required";
import PageHead from "../../Common/PageHead";
import BootstrapInputBordered from "../../Common/BootstrapInputBordered";
import * as consts from "../../consts";
import {
  extractFieldError,
  getFormattedError,
  validateObject,
} from "../../../utils/schemaValidator";
import storySchema from "./story.schema";
import TextEditor from "../../Common/TextEditor";
import {
  useLoader,
  useLoggedInUser,
  usePrompt,
  useRequireLogin,
} from "../../../utils/hooks";
import { t, Trans } from "@lingui/macro";
import {
  serialiseArray,
  tokeniseCommaSeparatedString,
} from "../../../utils/common";
import CreateBookCoverButtons from "../../Common/CanvaDesigner/CanvaBookCoverButton";
import DropDown from "../../Common/DropDown";
import { NavigateNext as NavigateNextIcon } from "@material-ui/icons";
import Banner from "../../Common/Banner";
import { isWebViewAvailable } from "../../../mobile/events";

const useStyles = makeStyles((theme) => ({
  editorSection: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(3),
  },
  title: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  createStoryBtnContainer: {
    justifyContent: "center",
    marginBottom: theme.spacing(5),
  },
  lightText: {
    color: theme.palette.grey[700],
  },
  mobileOnly: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  desktopOnly: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  error: {
    border: "1px solid red",
  },
}));

const Label = withStyles((theme) => ({
  root: {
    fontSize: "20px",
    color: theme.palette.grey[700],
  },
}))(InputLabel);

const Field = withStyles({
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
})(FormControl);

export default function StoryEditor() {
  const isCanvaEnabled = !isWebViewAvailable();
  const loggedInUser = useLoggedInUser();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { storyId } = useParams();
  const isUpdateOperation = !!storyId;

  const [title, setTitle] = useState(null);
  const [subtitle, setSubtitle] = useState(null);
  const [abstract, setAbstract] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [characters, setCharacters] = useState(null);
  const [tags, setTags] = useState(null);
  const [genre, setGenre] = useState(null);
  const [audienceCategory, setAudienceCategory] = useState(
    consts.STORY_AUDIENCE_CATEGORY.ALL_AGES
  );
  const [language, setLanguage] = useState(null);
  const [copyrightType, setCopyrightType] = useState(
    consts.COPYRIGHT_TYPE.ALL_RIGHTS_RESERVED
  );
  const [matureContent, setMatureContent] = useState(false);
  const [published, setPublished] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [originalCover, setOriginalCover] = useState(null);
  const [isPageDirty, setPageDirty] = useState(false);

  const {
    loading: retrievingStory,
    error: retrieveStoryError,
    data: storyData,
  } = useQuery(GET_STORY, {
    variables: { id: storyId },
    skip: !isUpdateOperation || !loggedInUser,
  });

  usePrompt("Are you sure you want to leave the page?", isPageDirty);
  useEffect(() => setPageDirty(true), []);
  useRequireLogin();

  const getValidatedStory = (story) => {
    setValidationErrors([]);
    const { validatedObject, errors } = validateObject(story, storySchema);
    if (errors.length) {
      setValidationErrors(errors);
      return false;
    } else {
      return validatedObject;
    }
  };

  const getFieldError = (fieldName) =>
    extractFieldError(validationErrors, fieldName);

  const dispatchErrorMessage = useCallback(
    (message) =>
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message,
      }),
    [dispatch]
  );

  const [createStory] = useMutation(CREATE_STORY);
  const [updateStory] = useMutation(UPDATE_STORY);

  useEffect(() => {
    if (retrieveStoryError) {
      dispatchErrorMessage(
        "Something went wrong while fetching the story. Please try again later!"
      );
    }
  }, [dispatchErrorMessage, retrieveStoryError]);

  useLoader(formSubmitting || retrievingStory);

  useEffect(() => {
    if (storyData?.story) {
      const { story } = storyData;
      setTitle(story.title);
      setSubtitle(story.subtitle);
      setAbstract(story.abstract);
      setCharacters(serialiseArray(story.characters));
      setTags(serialiseArray(story.tags));
      setGenre(story.genre);
      setAudienceCategory(story.audienceCategory);
      setLanguage(story.language);
      setCopyrightType(story.copyrightType);
      setCoverImage(story.coverImage);
      setOriginalCover(story.coverImage);
      setMatureContent(!!story.matureContent);
      setPublished(!!story.published);
    }
  }, [storyData]);

  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };
  const handleChangeSubtitle = (event) => {
    setSubtitle(event.target.value);
  };
  const handleChangeAbstract = (event) => {
    setAbstract(event.target.value);
  };
  const handleChangeCharacters = (event) => {
    setCharacters(event.target.value);
  };
  const handleChangeTags = (event) => {
    setTags(event.target.value);
  };
  const handleChangeGenre = (event) => {
    setGenre(event.target.value);
  };
  const handleChangeAudienceCategory = (event) => {
    setAudienceCategory(event.target.value);
  };
  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };
  const handleChangeCopyrightType = (event) => {
    setCopyrightType(event.target.value);
  };
  const handleAttachCover = (url) => {
    setCoverImage(url);
  };
  const handleRemoveCover = () => {
    setCoverImage(null);
  };
  const handleChangeMatureContent = (event) => {
    setMatureContent(event.target.checked);
  };

  const handleSubmitForm = () => {
    const rawStoryData = {
      title,
      subtitle,
      abstract,
      coverImage: coverImage,
      characters: tokeniseCommaSeparatedString(characters),
      tags: tokeniseCommaSeparatedString(tags),
      genre,
      audienceCategory,
      language,
      copyrightType,
      matureContent: !!matureContent,
      published: !!published,
    };

    const validatedStory = getValidatedStory(rawStoryData);
    if (!validatedStory) {
      dispatchErrorMessage(
        "Some of the fields contain invalid values. Please check again!"
      );
      return;
    }

    setFormSubmitting(true);
    setPageDirty(false);

    let coverImageUrl = null;
    if (coverImage) {
      if (coverImage !== originalCover) {
        // Image was updated by the user
        coverImageUrl = coverImage;
      } else {
        // Image was not updated by the user, hence use the existing file name
        coverImageUrl = coverImage.split("/").pop();
      }
    }

    Promise.resolve()
      .then(() => {
        if (isDataUrl(coverImageUrl)) {
          const formData = new FormData();
          const imageBlob = dataURLtoBlob(coverImageUrl);
          if (!imageBlob) return Promise.resolve();
          formData.append("file", imageBlob);
          return fetch(`${IMAGE_SERVER_URL}/upload/bookcoverlg?crop=true`, {
            method: "POST",
            body: formData,
            credentials: "include",
          }).then((response) => {
            if (response.status === 201) {
              return response.json();
            }
            return Promise.reject("failed to upload image");
          });
        } else {
          return { fileName: coverImageUrl };
        }
      })
      .then((uploadedImage) => (uploadedImage.fileName || "").split("/").pop())
      .then((coverImage) => {
        if (isUpdateOperation) {
          const updatedStoryData = {
            id: storyId,
            ...validatedStory,
            coverImage: coverImage || coverImage,
          };

          return updateStory({
            variables: {
              story: updatedStoryData,
            },
          })
            .then(({ data }) => {
              if (data?.updateStory?.id) {
                history.push(`/story/${data.updateStory.id}`);
                return;
              }
              throw new Error("unable to create story");
            })
            .catch(() => {
              dispatchErrorMessage(
                "Something went wrong while updating the story. Please try again later"
              );
            });
        } else {
          const { published, ...newStoryContent } = validatedStory;
          return createStory({
            variables: {
              story: {
                ...newStoryContent,
                coverImage: coverImage || coverImage,
              },
            },
          })
            .then(({ data }) => {
              if (data?.createStory?.id) {
                history.push(`/story/${data.createStory.id}/chapter/new`);
                return;
              }
              throw new Error("unable to create story.");
            })
            .catch(() => {
              dispatchErrorMessage(
                "Something went wrong while creating the story. Please try again later"
              );
            });
        }
      })
      .finally(() => {
        setFormSubmitting(false);
      });
  };

  const editorTitle = storyId ? t`Edit Story` : t`Write New Story`;

  const submitButton = (
    <Grid container item xs={12} className={classes.createStoryBtnContainer}>
      <Grid item>
        <Button
          disableElevation
          size="large"
          variant="contained"
          color="primary"
          endIcon={!storyId && <NavigateNextIcon />}
          disabled={formSubmitting}
          onClick={handleSubmitForm}
        >
          {storyId ? t`Update Story Details` : t`Next`}
        </Button>
      </Grid>
    </Grid>
  );

  const submitInProgressBanner = (
    <Banner
      type="info"
      message={t`Please be patient while we submit your story...`}
    />
  );

  return (
    <Container maxWidth="xl">
      {isUpdateOperation ? (
        <PageHead title={t`Edit Story - ${title} | bibliocircle`} />
      ) : (
        <PageHead title={t`Write New Story | bibliocircle`} />
      )}
      <Grid container justifyContent="center">
        <Grid item xs={12} className={classes.title}>
          <Grid container spacing={2} alignItems="center" direction="column">
            <Grid item>
              <PageTitle>{editorTitle}</PageTitle>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="center" spacing={3}>
          <Grid item xs={12} lg={11}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                      <Grid container className={classes.editorSection}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <PageTitle variant="h5">
                              <Trans>Your Story</Trans>
                            </PageTitle>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography color="textSecondary" variant="body2">
                              <Trans>
                                Please provide the details about your story.
                              </Trans>
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Field className={classes.spannedInput}>
                              <Label shrink color="primary">
                                <Required>
                                  <Trans>Story Title</Trans>
                                </Required>
                              </Label>
                              <BootstrapInputBordered
                                error={getFieldError("title")}
                                onChange={handleChangeTitle}
                                value={title}
                                disabled={formSubmitting}
                              />
                              <FormHelperText
                                error
                                hidden={!getFieldError("title")}
                              >
                                {getFormattedError(getFieldError("title"))}
                              </FormHelperText>
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field className={classes.spannedInput}>
                              <Label shrink color="primary">
                                <Trans>Subtitle</Trans>
                              </Label>
                              <BootstrapInputBordered
                                onChange={handleChangeSubtitle}
                                value={subtitle}
                                disabled={formSubmitting}
                              />
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  <Required>Plot Summary</Required>
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  Please write a summary of your story's plot. A
                                  fascinating plot summary can attract more
                                  readers to your story.
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Field className={classes.spannedInput}>
                                  <TextEditor
                                    rowsMin={15}
                                    placeholder={t`Write your story plot summary here...`}
                                    error={getFieldError("abstract")}
                                    value={abstract}
                                    onChange={handleChangeAbstract}
                                    disabled={formSubmitting}
                                  />
                                  <FormHelperText
                                    error
                                    hidden={!getFieldError("abstract")}
                                  >
                                    {getFormattedError(
                                      getFieldError("abstract")
                                    )}
                                  </FormHelperText>
                                </Field>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field>
                              <Label shrink color="primary">
                                <Required>
                                  <Trans>Genre</Trans>
                                </Required>
                              </Label>
                              <DropDown
                                value={genre}
                                disableUnderline
                                onChange={handleChangeGenre}
                                disabled={formSubmitting}
                                options={consts.storyGenres}
                                error={getFieldError("genre")}
                                bordered
                              ></DropDown>
                              <FormHelperText
                                error
                                hidden={!getFieldError("genre")}
                              >
                                {getFormattedError(getFieldError("genre"))}
                              </FormHelperText>
                            </Field>
                          </Grid>
                          <Grid style={{ display: "none" }} item xs={12}>
                            <Field className={classes.spannedInput}>
                              <Label shrink color="primary">
                                <Trans>Main Characters</Trans>
                              </Label>
                              <BootstrapInputBordered
                                onChange={handleChangeCharacters}
                                value={characters}
                                disabled={formSubmitting}
                              />
                              <FormHelperText>
                                Please provide the main characters of the story
                                separated by commas. This field is not required,
                                and not displayed in your story. This
                                information is only used to make your story more
                                discoverable.
                              </FormHelperText>
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field className={classes.spannedInput}>
                              <Label shrink color="primary">
                                <Trans>Tags</Trans>
                              </Label>
                              <BootstrapInputBordered
                                error={getFieldError("tags")}
                                onChange={handleChangeTags}
                                value={tags}
                                placeholder="e.g, tag1, tag2"
                                disabled={formSubmitting}
                              />
                              <FormHelperText
                                error
                                hidden={!getFieldError("tags")}
                              >
                                {getFormattedError(getFieldError("tags"))}
                              </FormHelperText>
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field>
                              <Label shrink color="primary" htmlFor="lang">
                                <Required>
                                  <Trans>Language</Trans>
                                </Required>
                              </Label>
                              <DropDown
                                value={language}
                                disableUnderline
                                onChange={handleChangeLanguage}
                                disabled={formSubmitting}
                                options={consts.languages}
                                error={getFieldError("language")}
                                bordered
                              />
                              <FormHelperText
                                error
                                hidden={!getFieldError("language")}
                              >
                                {getFormattedError(getFieldError("language"))}
                              </FormHelperText>
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Field>
                              <Label shrink color="primary">
                                <Required>
                                  <Trans>Target Audience</Trans>
                                </Required>
                              </Label>
                              <DropDown
                                value={audienceCategory}
                                disableUnderline
                                onChange={handleChangeAudienceCategory}
                                disabled={formSubmitting}
                                options={consts.storyAudienceTypes}
                                error={getFieldError("audienceCategory")}
                                bordered
                              />
                              <FormHelperText
                                error
                                hidden={!getFieldError("audienceCategory")}
                              >
                                {getFormattedError(
                                  getFieldError("audienceCategory")
                                )}
                              </FormHelperText>
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Field>
                              <Label shrink color="primary">
                                <Required>
                                  <Trans>Copyright Type</Trans>
                                </Required>
                              </Label>
                              <DropDown
                                value={copyrightType}
                                disableUnderline
                                onChange={handleChangeCopyrightType}
                                disabled={formSubmitting}
                                options={consts.copyRightTypes}
                                bordered
                                error={getFieldError("copyrightType")}
                              />
                              <FormHelperText
                                error
                                hidden={!getFieldError("copyrightType")}
                              >
                                {getFormattedError(
                                  getFieldError("copyrightType")
                                )}
                              </FormHelperText>
                            </Field>
                            <FormHelperText>
                              Learn more about Creative Common licenses{" "}
                              <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://creativecommons.org/licenses/"
                              >
                                here
                              </a>
                            </FormHelperText>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Grid item>
                                    <Typography
                                      variant="body1"
                                      className={classes.lightText}
                                    >
                                      <Required>
                                        <Trans>
                                          Does this story contain any content
                                          which is not suitable for anyone aged
                                          below 18 years?
                                        </Trans>
                                      </Required>
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Grid container alignItems="center">
                                      <Grid item>
                                        <Typography
                                          variant="body1"
                                          align="right"
                                          className={classes.lightText}
                                        >
                                          <Trans>No</Trans>
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <Switch
                                          checked={matureContent}
                                          onChange={handleChangeMatureContent}
                                          color="primary"
                                          disabled={formSubmitting}
                                        />
                                      </Grid>
                                      <Grid item>
                                        <Typography
                                          variant="body1"
                                          align="left"
                                          className={classes.lightText}
                                        >
                                          <Trans>Yes</Trans>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {formSubmitting && (
                        <Grid item xs={12} className={classes.desktopOnly}>
                          {submitInProgressBanner}
                        </Grid>
                      )}
                      <Grid item xs={12} className={classes.desktopOnly}>
                        {submitButton}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="flex-start"
                      className={[
                        classes.editorSection,
                        getFieldError("coverImage") ? classes.error : null,
                      ].join(" ")}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <PageTitle variant="h5">
                            <Trans>Story Cover Image</Trans>
                          </PageTitle>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            <Required>
                              {isCanvaEnabled
                                ? t`You can either upload a previously created cover
                              image using "Attach Custom Image", or design a new
                              story cover and use it using "Design On Canva"
                              button.`
                                : t`You can upload a previously created cover
                            image using "Attach Custom Image"`}
                            </Required>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                alignContent="stretch"
                              >
                                <Grid item>
                                  <CreateBookCoverButtons
                                    onAttach={handleAttachCover}
                                    onRemvoe={handleRemoveCover}
                                    initialImage={coverImage}
                                    buttonProps={{
                                      disabled: formSubmitting,
                                      variant: "contained",
                                      color: "primary",
                                    }}
                                    canvaEnabled={isCanvaEnabled}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <FormHelperText
                              error
                              hidden={!getFieldError("coverImage")}
                            >
                              {isCanvaEnabled
                                ? t`Cover image is required! Please upload your own
                                cover image or use "Design in Canva" button to
                                design a cover for your story.`
                                : t`Cover image is required!`}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {formSubmitting && (
                <Grid item xs={12} className={classes.mobileOnly}>
                  {submitInProgressBanner}
                </Grid>
              )}
              <Grid item xs={12} className={classes.mobileOnly}>
                {submitButton}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
