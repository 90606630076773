import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Menu,
  Grid,
  Slider,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import DropDownIcon from "@material-ui/icons/ArrowDropDown";
import BootstrapInputBordered from "../Common/BootstrapInputBordered";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  priceRangeButton: {
    fontSize: "16px",
    fontWeight: "normal",
    textAlign: "left",
  },
  priceRangeContainer: {
    outline: "none",
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const DropDownPanelButton = withStyles({
  label: {
    float: "left",
    textAlign: "left",
    justifyContent: "left",
  },
  endIcon: {
    top: "calc(50% - 12px)",
    right: 5,
    position: "absolute",
  },
})(Button);

export default function PriceRangeDropDown({ onChange, value }) {
  const classes = useStyles();
  const [priceRangeMenuAnchor, setPriceRangeMenuAnchor] = useState(null);
  const [priceRange, setPriceRange] = useState([0, 20000]);

  const handleClickPriceRangeDropdown = (event) => {
    setPriceRangeMenuAnchor(event.currentTarget);
  };
  const handleClosePriceRangeDropdown = () => {
    setPriceRangeMenuAnchor(null);
  };

  const emitPriceChange = (priceRange) => {
    onChange(priceRange);
    setPriceRange(priceRange);
  };
  const onChangePriceRange = (event, newRange) => {
    emitPriceChange(newRange);
  };
  const onChangePriceMin = (event) => {
    const min = +event.target.value;
    emitPriceChange([min, priceRange[1]]);
  };
  const onChangePriceMax = (event) => {
    const max = +event.target.value;
    emitPriceChange([priceRange[0], max]);
  };

  useEffect(() => {
    setPriceRange(value);
  }, [value]);

  const onPressEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleClosePriceRangeDropdown();
    }
  };

  return (
    <>
      <DropDownPanelButton
        color="inherit"
        onClick={handleClickPriceRangeDropdown}
        className={classes.priceRangeButton}
        disableRipple
        size="large"
        endIcon={<DropDownIcon />}
        fullWidth
      >
        <Trans>
          Rs. {priceRange[0]} - Rs. {priceRange[1]}
        </Trans>
      </DropDownPanelButton>
      <Menu
        id="simple-menu"
        anchorEl={priceRangeMenuAnchor}
        keepMounted
        open={!!priceRangeMenuAnchor}
        onClose={handleClosePriceRangeDropdown}
      >
        <Grid
          container
          style={{ width: "300px" }}
          className={classes.priceRangeContainer}
        >
          <Grid item xs={12}>
            <Slider
              value={value}
              onChange={onChangePriceRange}
              max={20000}
              step={100}
              color="primary"
              min={0}
              valueLabelDisplay="off"
              aria-labelledby="range-slider"
              getAriaValueText={(v) => t`Rs. ${v}`}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={1}
            alignContent="center"
            alignItems="center"
          >
            <Grid item xs={2}>
              <Trans>Rs.</Trans>
            </Grid>
            <Grid item xs={5}>
              <BootstrapInputBordered
                value={priceRange[0]}
                onChange={onChangePriceMin}
                onKeyDown={onPressEnter}
              />
            </Grid>
            <Grid item xs={5}>
              <BootstrapInputBordered
                value={priceRange[1]}
                onChange={onChangePriceMax}
                onKeyDown={onPressEnter}
              />
            </Grid>
          </Grid>
        </Grid>
      </Menu>
    </>
  );
}

PriceRangeDropDown.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
};

PriceRangeDropDown.defaultProps = {
  onChange() {},
  value: [0, 20000],
};
