import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  makeStyles,
  Avatar,
  Tooltip,
  FormControlLabel,
  Radio,
  Box,
} from "@material-ui/core";
import ShopIcon from "@material-ui/icons/Store";
import { useQuery } from "@apollo/client";
import { GET_SHOPS_FOR_LISTING } from "../../consts/queries";
import { ShopSelectionLoader } from "../Common/LoadingPlaceholders/ShopSelectionLoader";
import { t } from "@lingui/macro";
import { useLoggedInUser } from "../../utils/hooks";

const useStyles = makeStyles((theme) => {
  const sectionButton = {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    cursor: "pointer",
    border: "1px dashed",
    borderColor: theme.palette.grey[500],
    borderRadius: theme.spacing(1),
  };

  return {
    sectionButton,
    selectedSectionButton: {
      ...sectionButton,
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.lighter,
    },
    iconContainer: {
      width: 40,
      height: 40,
      borderRadius: 40,
      backgroundColor: theme.palette.primary.main,
    },
    sectionTitleContainer: {
      marginBottom: theme.spacing(2),
    },
    sectionTitle: {
      fontWeight: "bold",
    },
    postAs: {
      fontWeight: "bold",
      color: theme.palette.grey[800],
    },
    shopSelection: {
      marginTop: theme.spacing(2),
    },
  };
});

export default function ShopSelection({
  onChange,
  initialSelection,
  disabled,
}) {
  const classes = useStyles();
  const loggedInUser = useLoggedInUser();
  const [postAsShop, setPostAsShop] = useState(false);
  const [selectedShop, setSelectedShop] = useState(null);
  const { loading: fetchingShops, data: shopsData } = useQuery(
    GET_SHOPS_FOR_LISTING,
    {
      variables: {
        isCurrentUserOwner: true,
      },
    }
  );

  const selectShop = useCallback(
    (shopId) => {
      const shops = shopsData?.searchShops?.shops;
      if (Array.isArray(shops)) {
        setSelectedShop(shops.find((shop) => shop.id === shopId));
      }
    },
    [shopsData]
  );

  useEffect(() => {
    if (initialSelection) {
      setPostAsShop(true);
      selectShop(initialSelection.id);
    }
  }, [initialSelection, selectShop]);

  useEffect(() => {
    onChange(selectedShop);
  }, [onChange, selectedShop]);

  const selectPostAsUser = () => {
    setPostAsShop(false);
    deselectPostAsShop();
  };

  const deselectPostAsShop = () => setSelectedShop(null);

  const handleSelectShop = (shopId) => (event) => {
    if (event.target.checked) {
      selectShop(shopId);
    } else {
      deselectPostAsShop();
    }
  };

  const selectPostAsShop = useCallback(
    (userShops) => () => {
      setPostAsShop(true);
      // If user has only one shop, select it automatically
      if (userShops?.length === 1) {
        selectShop(userShops[0].id);
      }
    },
    [selectShop]
  );

  const userShopsCount = +shopsData?.searchShops?.shops.length || 0;
  if (!userShopsCount) return null;

  const { shops } = shopsData.searchShops;
  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        className={classes.sectionTitleContainer}
      >
        <Typography variant="h6" className={classes.sectionTitle}>
          {t`Posting as`}
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={12} spacing={1}>
          <Tooltip
            title={`Post as ${loggedInUser.firstName} ${loggedInUser.lastName}`}
          >
            <Grid container item xs={6}>
              <Grid
                container
                item
                xs={12}
                spacing={1}
                className={
                  !postAsShop
                    ? classes.selectedSectionButton
                    : classes.sectionButton
                }
                onClick={!disabled && selectPostAsUser}
              >
                <Grid container item xs={12} justifyContent="center">
                  <Avatar src={loggedInUser.profilePicture} />
                </Grid>
                <Grid container item xs={12} justifyContent="center">
                  <Typography variant="subtitle1" className={classes.postAs}>
                    {t`You`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Tooltip>
          <Tooltip
            title={t`Post as the selected shop. Listing will be included in the shop`}
          >
            <Grid container item xs={6}>
              <Grid
                container
                item
                xs={12}
                spacing={1}
                className={
                  postAsShop
                    ? classes.selectedSectionButton
                    : classes.sectionButton
                }
                onClick={!disabled && selectPostAsShop(shops)}
              >
                <Grid container item xs={12} justifyContent="center">
                  <Grid
                    container
                    item
                    className={classes.iconContainer}
                    justifyContent="center"
                    alignContent="center"
                  >
                    <ShopIcon style={{ fontSize: 30, color: "white" }} />
                  </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="center">
                  <Typography variant="subtitle1" className={classes.postAs}>
                    {t`Your Shop`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Tooltip>
        </Grid>
        <Grid container item xs={12} className={classes.shopSelection}>
          <Grid container item xs={12}>
            {postAsShop && (
              <Typography variant="subtitle2">
                {t`Select your shop where this listing should be displayed:`}
              </Typography>
            )}
            {!postAsShop && (
              <Typography variant="subtitle2">
                {t`You need to post as a shop to include this listing in one of the following shops!`}
              </Typography>
            )}
          </Grid>
          <Grid container item xs={12}>
            {fetchingShops && (
              <Grid item xs={12}>
                <Box p={2}>
                  <ShopSelectionLoader />
                </Box>
              </Grid>
            )}
            {shops.map((shop) => {
              return (
                <Grid item xs={12} key={shop.id}>
                  <FormControlLabel
                    disabled={!postAsShop}
                    control={
                      <Radio
                        color="default"
                        onChange={handleSelectShop(shop.id)}
                        disabled={disabled}
                      />
                    }
                    checked={
                      initialSelection?.id === shop.id ||
                      selectedShop?.id === shop.id
                    }
                    label={<Typography variant="body2">{shop.name}</Typography>}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ShopSelection.propTypes = {
  onChange: PropTypes.func,
  isPostingAsShop: PropTypes.bool,
  disabled: PropTypes.bool,
};

ShopSelection.defaultProps = {
  onChange() {},
  isPostingAsShop: false,
  disabled: false,
};
