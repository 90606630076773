import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  InputAdornment,
  IconButton,
  Paper,
  Button,
  Container,
} from "@material-ui/core";
import PageTitle from "../Common/PageTitle";
import { useHistory, useLocation } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import BootstrapInput from "../Common/BootstrapInput";
import GroupsSearchController from "./GroupsSearchController";
import GroupIcon from "@material-ui/icons/Group";
import Hyperlink from "../Common/Hyperlink";
import PageHead from "../Common/PageHead";
import { useLoginPrompt, useScrollTop } from "../../utils/hooks";
import ImageFadeIn from "../Common/ImageFadeIn";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  groupDiscoverer: {
    marginTop: theme.spacing(3),
  },
  logo: {
    maxHeight: theme.spacing(6) * 2,
  },
  pageTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  groupSearchBox: {
    width: "100%",
  },
  groupSearchContainer: {
    marginBottom: theme.spacing(2),
  },
}));

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export default function DiscoverGroups() {
  const classes = useStyles();
  const history = useHistory();
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const queryParams = useQueryParams();
  const [searchQuery, setSearchQuery] = useState("");

  useScrollTop([history.location.key]);

  const onKeyDown = (event) => {
    if (event.keyCode === 13) searchGroups();
  };

  const searchGroups = () => {
    history.push(`/groups?q=${searchQuery}`);
  };
  const onChangeSearchQuery = (event) => setSearchQuery(event.target.value);
  const onClickCreateGroup = createLoginPromptEventHandler({
    redirectPath: "/group/create",
  });

  const groupsSearchBar = (
    <Grid
      container
      justifyContent="center"
      className={classes.groupSearchContainer}
    >
      <Grid
        container
        item
        xs={12}
        md={12}
        lg={6}
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <Paper>
            <BootstrapInput
              name="q"
              className={classes.groupSearchBox}
              defaultValue={queryParams.get("q")}
              placeholder={t`Search groups`}
              bordered
              onChange={onChangeSearchQuery}
              onKeyDown={onKeyDown}
              endAdornment={
                <InputAdornment>
                  <IconButton
                    color="primary"
                    variant="contained"
                    onClick={searchGroups}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            ></BootstrapInput>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Container maxWidth="xl">
      <PageHead title="Discover Groups | bibliocircle" />
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        className={classes.groupDiscoverer}
      >
        <Grid item xs={12} lg={10}>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} className={classes.pageTitle}>
              <Grid
                container
                alignItems="center"
                direction="column"
                spacing={2}
              >
                <Grid item>
                  <Grid container justifyContent="center">
                    <ImageFadeIn
                      src="/logo/logo_groups.svg"
                      alt="Discover Groups"
                      className={classes.logo}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <PageTitle variant="subtitle1" align="center">
                    <Trans>
                      bibliocircle Groups is the best place to share books you
                      love with fellow readers. Discover groups where you might
                      find your favourite books.
                    </Trans>
                  </PageTitle>
                </Grid>
                <Grid item>
                  <Button
                    onClick={onClickCreateGroup}
                    component={Hyperlink}
                    to="/group/create"
                    startIcon={<GroupIcon />}
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    <Trans>Create Group</Trans>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              {groupsSearchBar}
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <GroupsSearchController
                    key={history.location.key}
                    xs={12}
                    md={6}
                    lg={6}
                    xl={6}
                    searchArgs={{
                      query: queryParams.get("q"),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
