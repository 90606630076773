import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import NoResults from "../Common/NoResultsImage";
import { CRITIQUES_PAGINATION_LIMIT } from "../../config";
import { SEARCH_CRITIQUES } from "../../consts/queries";
import CritiqueCard from "./CritiqueCard";
import { CritiquesLoader } from "../Common/LoadingPlaceholders/CritiquesLoader";
import ErrorPage from "../Error/ErrorPage";
import { useLoader } from "../../utils/hooks";
import { useQuery } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  noCritiquesPlaceholder: {
    padding: theme.spacing(3),
  },
  errorBanner: {
    padding: theme.spacing(3),
  },
  noCritiquesPlaceholderText: {
    fontWeight: "bold",
    textAlign: "center",
  },
}));

export default function CritiquesSearchController({
  fetchPolicy,
  fixedResultsCount,
  searchArgs,
  noResultsMessage,
  xs,
  md,
  lg,
  xl,
}) {
  const classes = useStyles();
  const fetchOptions = {
    variables: {
      ...searchArgs,
      pagination: {
        limit: fixedResultsCount || CRITIQUES_PAGINATION_LIMIT,
        offset: 0,
      },
    },
  };

  if (fetchPolicy) fetchOptions.fetchPolicy = fetchPolicy;
  const {
    loading,
    error: fetchCritiquesError,
    data,
    fetchMore,
  } = useQuery(SEARCH_CRITIQUES, fetchOptions);
  useLoader(loading);

  const loadingPlaceholder = (
    <CritiquesLoader xs={xs} md={md} lg={lg} xl={xl} />
  );

  if (fetchCritiquesError) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the critiques due to a problem. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  if (loading && !data) {
    return (
      <Grid container justifyContent="center">
        {loadingPlaceholder}
      </Grid>
    );
  }

  if (!data?.searchCritiques) return null;

  const {
    searchCritiques: { critiques, pagination },
  } = data;

  const loadMoreCritiques = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: CRITIQUES_PAGINATION_LIMIT,
          offset: critiques.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          searchCritiques: {
            __typename: prev.searchCritiques.__typename,
            critiques: [
              ...prev.searchCritiques.critiques,
              ...fetchMoreResult.searchCritiques.critiques,
            ],
            pagination: fetchMoreResult.searchCritiques.pagination,
          },
        });
      },
    }).catch(() => {});
  };

  const noCritiquesPlaceholder = (
    <Grid
      container
      justifyContent="center"
      className={classes.noCritiquesPlaceholder}
    >
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="h5"
          color="primary"
          className={classes.noCritiquesPlaceholderText}
        >
          {noResultsMessage ||
            "Sorry, we could not find the critiques you are looking for!"}
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={6} lg={4}>
          <NoResults />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      {!critiques.length ? noCritiquesPlaceholder : null}
      <InfiniteScroll
        style={{ overflow: "none !important" }}
        next={!fixedResultsCount && loadMoreCritiques}
        hasMore={critiques.length < pagination.totalCount}
        loader={
          !fixedResultsCount && (
            <CritiquesLoader xs={xs} md={md} lg={lg} xl={xl} />
          )
        }
        dataLength={critiques.length}
      >
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {critiques.map((crtique) => (
              <Grid key={crtique.id} item xs={xs} md={md} lg={lg} xl={xl}>
                <CritiqueCard critique={crtique} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </InfiniteScroll>
    </Grid>
  );
}

CritiquesSearchController.propTypes = {
  searchArgs: PropTypes.object,
};

CritiquesSearchController.defaultProps = {
  searchArgs: {},
};
