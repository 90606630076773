import { useMutation } from "@apollo/client";
import { t, Trans } from "@lingui/macro";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import React from "react";
import { TOGGLE_PUBLISH_STORY } from "../../../consts/mutations";
import {
  useDispatchErrorMessage,
  useDispatchInfoMessage,
} from "../../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  publishStoryButton: {
    color: theme.palette.background.paper,
    backgroundColor: green[500],
    border: "none",
    "&:hover": {
      backgroundColor: green[700],
    },
    textTransform: "none",
  },
  publishLaterButtonText: {
    textTransform: "none",
  },
  dialogContainer: {
    overflow: "hidden",
    padding: theme.spacing(3),
  },
}));

export default function PublishStoryPrompt({ open, story, onClose }) {
  const classes = useStyles();
  const dispatchInfoMessage = useDispatchInfoMessage();
  const dispatchErrorMessage = useDispatchErrorMessage();
  const [togglePublishStory] = useMutation(TOGGLE_PUBLISH_STORY);

  const publishStory = () => {
    onClose();
    if (story.published) return;
    togglePublishStory({
      variables: {
        id: story.id,
      },
    })
      .then(() => {
        dispatchInfoMessage(t`Your story is now published!`);
      })
      .catch(() => {
        dispatchErrorMessage(
          t`Something went wrong while publishing your story. Please try again later.`
        );
      });
  };

  return (
    <Dialog
      disableBackdropClick
      scroll="body"
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
    >
      <DialogContent dividers={false} className={classes.dialogContainer}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12}>
            <Typography align="center" variant="h6" color="primary">
              <Trans>Hooray!</Trans> <span aria-label="tada">🎉</span>
            </Typography>
            <Typography align="center" variant="h6" color="primary">
              <Trans>Your story is ready to be published!</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="body1" color="primary">
              <Trans>
                You added the first chapter to your story. You can now make your
                story available for readers by publishing it. You can add more
                chapters to your story at any time.
              </Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button
                      onClick={publishStory}
                      variant="contained"
                      size="large"
                      className={classes.publishStoryButton}
                      disableElevation
                    >
                      <Trans>Publish story for readers</Trans>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button
                      onClick={onClose}
                      variant="text"
                      size="large"
                      color="primary"
                      disableElevation
                    >
                      <Typography
                        variant="body2"
                        className={classes.publishLaterButtonText}
                      >
                        <Trans>I will publish the story later</Trans>
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
