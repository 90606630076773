export const getChatGroupTopic = (chatGroup, currentUserId) => {
  const participants = chatGroup.participants.filter(
    (p) => p.id !== currentUserId
  );
  const participantCount = participants.length;

  let threadTitle = "Group Chat";
  if (chatGroup.title) {
    threadTitle = chatGroup.title;
  } else if (participantCount === 1) {
    const participant = participants[0];
    const userName = [participant.firstName, participant.lastName]
      .join(" ")
      .trim();
    threadTitle = userName;
  }
  return threadTitle;
};
