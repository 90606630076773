import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_BOOK_REQUEST } from "../../../consts/queries";
import { useDispatch } from "react-redux";
import { actionTypes } from "../../index.reducer";
import BookRequestDetailsCard from "./BookRequestDetailsCard";
import { DELETE_BOOK_REQUEST } from "../../../consts/mutations";
import ConfirmPrompt from "../../Common/ConfirmPrompt";
import { Fade, Grid } from "@material-ui/core";
import ListingsSearchController from "../../Search/ListingsSearchController";
import ErrorPage from "../../Error/ErrorPage";
import { useLoader } from "../../../utils/hooks";
import { useLocation } from "react-use";

export default function BookRequestDetails({
  groupScope,
  bookRequestId,
  showResultsCountBanner,
  showMatchedListings,
  afterDelete,
}) {
  const resultsRef = React.createRef(null);
  const location = useLocation();
  const [deletingBookRequestId, setDeletingBookRequestId] = useState(null);
  const [
    fetchBookRequest,
    {
      loading: fetchingBookRequest,
      error: fetchBookRequestError,
      data: bookRequestData,
    },
  ] = useLazyQuery(GET_BOOK_REQUEST);
  const dispatch = useDispatch();
  const [deleteBookRequest, { loading: deletingBookRequest }] =
    useMutation(DELETE_BOOK_REQUEST);
  useLoader(deletingBookRequest);

  useEffect(() => {
    if (bookRequestId) {
      fetchBookRequest({
        variables: { id: bookRequestId },
      });
    }
  }, [fetchBookRequest, bookRequestId]);
  useLoader(fetchingBookRequest);

  useEffect(() => {
    if (location.hash.includes("#book-request-matches") && resultsRef.current) {
      resultsRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [resultsRef, location]);

  if (fetchBookRequestError) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the book request due to a problem. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  if (!bookRequestData) return null;
  const { bookRequest } = bookRequestData;

  if (!bookRequest) {
    return (
      <ErrorPage
        title="Not Found"
        description="The book request you were looking for does not exist!"
        statusCode={404}
      />
    );
  }

  const dispatchErrorMessage = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });

  const onDeleteBookRequest = (id) => {
    setDeletingBookRequestId(id);
  };

  const onDeleteDecision = (accept) => {
    if (accept) {
      deleteBookRequest({
        variables: { id: deletingBookRequestId },
      })
        .then(() => {
          afterDelete();
        })
        .catch(() => {
          dispatchErrorMessage(
            "Failed to delete book request! Please try again later."
          );
        });
    }
    setDeletingBookRequestId(null);
  };

  return (
    <Grid container spacing={3}>
      <ConfirmPrompt
        open={deletingBookRequestId}
        title="Delete Book Request"
        question="Are you sure you want to delete this book request?"
        onAction={onDeleteDecision}
      />
      <Grid item xs={12}>
        <Fade in>
          <BookRequestDetailsCard
            groupScope={groupScope}
            bookRequest={bookRequest}
            showResultsCountBanner={showResultsCountBanner}
            onDelete={onDeleteBookRequest}
          />
        </Fade>
      </Grid>
      {showMatchedListings && (
        <Grid item xs={12} ref={resultsRef}>
          <ListingsSearchController
            searchArgs={{ bookRequestScope: bookRequestId }}
            showEndOfResultsBanner={false}
            showResultsCount
            xs={12}
            md={12}
            lg={6}
            xl={6}
            noResultsMessage="We could not find any matching books yet!"
          />
        </Grid>
      )}
    </Grid>
  );
}

BookRequestDetails.propTypes = {
  groupScope: PropTypes.string,
  requestId: PropTypes.string,
  onDelete: PropTypes.func,
  variant: PropTypes.oneOf(["compact", "complete"]),
};

BookRequestDetails.defaultProps = {
  variant: "complete",
};
