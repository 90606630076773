import { useQuery } from "@apollo/client";
import { GET_RANDOMISED_FEATURED_STORIES } from "../../../consts/queries";
import React from "react";
import { Grid } from "@material-ui/core";
import LargeStoryCard from "./LargeStoryCard";
import SmallStoryCard from "./SmallStoryCard";
import ErrorPage from "../../Error/ErrorPage";

export default function FeaturedStories() {
  const { error, data } = useQuery(GET_RANDOMISED_FEATURED_STORIES, {
    fetchPolicy: "cache-first",
  });

  if (error) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch featured stories!"
        statusCode={500}
      />
    );
  }

  if (!data?.randomFeaturedStories) return null;
  const [mainStory, ...featuredStories] = data.randomFeaturedStories;
  if (!mainStory) return null;
  const multipleFeaturedStories = featuredStories?.length >= 1;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={multipleFeaturedStories ? 8 : 12}>
        <LargeStoryCard story={mainStory} />
      </Grid>
      {multipleFeaturedStories && (
        <Grid item xs={12} lg={4}>
          <Grid container spacing={1}>
            {featuredStories.map((story) => (
              <Grid item xs={12} sm={4} lg={12} key={story.id}>
                <SmallStoryCard story={story} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
