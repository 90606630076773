import Joi from "@hapi/joi";

export default Joi.object({
  id: Joi.string().optional(),
  title: Joi.string().required(),
  subtitle: Joi.string().allow(null, "").optional(),
  abstract: Joi.string().required(),
  coverImage: Joi.string().required(),
  characters: Joi.array().items(Joi.string().required()).optional(),
  tags: Joi.array().items(Joi.string().required()).optional(),
  genre: Joi.string().required(),
  audienceCategory: Joi.string().required(),
  language: Joi.string().required(),
  copyrightType: Joi.string().required(),
  matureContent: Joi.boolean().allow(null).optional().default(false),
  published: Joi.boolean().required(),
});
