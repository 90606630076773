import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { t } from "@lingui/macro";
import DiscussionsSearchController from "../../Discussions/DiscussionsSearchController";

export default function UserDiscussionsPage({ userId, searchQuery }) {
  return (
    <Grid container>
      <DiscussionsSearchController
        showGroupNames
        searchArgs={{ query: searchQuery, userId }}
        noResultsMessage={t`No discussions found!`}
      />
    </Grid>
  );
}

UserDiscussionsPage.propTypes = {
  userId: PropTypes.string.isRequired,
  searchQuery: PropTypes.string,
};
