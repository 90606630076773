import React from "react";
import PropTypes from "prop-types";
import BookRequestEditor from "./BookRequestEditor";
import { useMutation } from "@apollo/client";
import { CREATE_BOOK_REQUEST } from "../../consts/mutations";
import { useDispatch } from "react-redux";
import { actionTypes } from "../index.reducer";
import { Grid } from "@material-ui/core";
import { useLoader, useQueryParams } from "../../utils/hooks";
import { useHistory } from "react-router-dom";
import { GENERIC_ALERT_ERROR_MESSAGE } from "../../config";
import { t } from "@lingui/macro";

export default function CreateBookRequest() {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryParams();
  const groupScope = queryParams.get("groupScope");
  const [createBookRequest, { loading: creatingBookRequest }] =
    useMutation(CREATE_BOOK_REQUEST);
  useLoader(creatingBookRequest);

  const handleCreateBookRequest = (bookRequest) => {
    createBookRequest({
      variables: {
        bookRequest,
      },
    })
      .then(({ data }) => {
        const bookRequestId = data?.createBookRequest.id;
        if (groupScope) {
          return history.push(`/group/${groupScope}/book-requests`);
        }
        history.push(`/book-request/${bookRequestId}`);
      })
      .catch(() => {
        dispatch({
          type: actionTypes.ERROR_MESSAGE,
          message: GENERIC_ALERT_ERROR_MESSAGE,
        });
      });
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <BookRequestEditor
          groupScope={groupScope}
          editorTitle={t`Create Book Request`}
          submitButtonText={t`Create Book Request`}
          onSubmit={handleCreateBookRequest}
          disabled={creatingBookRequest}
        />
      </Grid>
    </Grid>
  );
}

CreateBookRequest.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  refetchArgs: PropTypes.object,
};

CreateBookRequest.defaultProps = {
  onClose() {},
  refetchArgs: {},
};
