import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  makeStyles,
  Typography,
  Button,
  useTheme,
} from "@material-ui/core";
import ListingsSearchController from "../Search/ListingsSearchController";
import Hyperlink from "../Common/Hyperlink";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(3),
  },
  sectionTitle: {
    padding: theme.spacing(1),
    width: "100%",
    textAlign: "center",
  },
  listings: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  more: {
    "& *": {
      fontWeight: "bold",
    },
  },
}));

export default function ShopSection({
  section,
  shop,
  onClickEdit,
  viewAsAdmin,
}) {
  const { title, tags = [], featuredCount } = section;
  const theme = useTheme();
  const classes = useStyles();
  const tagsStr = Array.isArray(tags) ? tags.join(",") : null;
  const moreUrl = `/shop/${shop.identifier}/listings?tags=${tagsStr}`;

  const titleBackground =
    section.titleBackgroundColor || theme.palette.primary.lighter;
  const contentBackground = section.contentBackgroundColor || "transparent";
  const titleTextColor = section.titleBackgroundColor
    ? theme.palette.getContrastText(titleBackground)
    : theme.palette.text.secondary;

  const titleStyle = {
    color: titleTextColor,
    backgroundColor: titleBackground,
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2),
  };

  const contentStyle = {
    backgroundColor: contentBackground,
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
  };

  const editSectionButton = (
    <Grid item>
      <Button
        startIcon={<EditIcon />}
        color="secondary"
        variant="text"
        onClick={onClickEdit}
      >
        Edit Section
      </Button>
    </Grid>
  );

  return (
    <Grid container spacing={1} className={classes.section}>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          style={titleStyle}
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h6" className={classes.sectionTitle}>
              {title}
            </Typography>
          </Grid>
          {viewAsAdmin && editSectionButton}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container style={contentStyle}>
          <Grid container item xs={12} className={classes.listings}>
            <ListingsSearchController
              xs={12}
              md={6}
              lg={6}
              xl={4}
              cachePolicy="cache-first"
              searchArgs={{
                shopScope: shop.id,
                tags: tagsStr,
              }}
              resultsLimit={featuredCount}
              noResultsMessage="No books in this section!"
              showEndOfResultsBanner={false}
            />
          </Grid>
          <Grid item xs={12} className={classes.more}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button
                  component={Hyperlink}
                  to={moreUrl}
                  disableRipple
                  variant="text"
                  color="primary"
                  size="large"
                >
                  View all in {title} ...
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ShopSection.propTypes = {
  section: PropTypes.object,
  shop: PropTypes.object,
  onClickEdit: PropTypes.func,
};

ShopSection.defaultProps = {
  section: {},
  shop: {},
  onClickEdit() {},
};
