import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { BC_DESCRIPTION } from "../config";
import ImageFadeIn from "../Application/Common/ImageFadeIn";

const useStyles = makeStyles((theme) => ({
  subTitle: {
    fontWeight: "bold",
  },
  flyer: {
    height: "100vh",
    maxWidth: "1000px",
  },
  flyerText: {
    color: "white",
  },
  logo: {
    width: "50%",
  },
  vectorGraphic: {
    width: "50%",
  },
  vectorGraphicContainer: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
  },
  userContentContainer: {
    display: "flex",
    width: "100%",
    position: "relative",
    overflow: "hidden",
    "&::before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.up("lg")]: {
        borderRadius: "0 50% 50% 0/0 50% 50% 0",
        transform: "scaleY(1.5)",
      },
    },
  },
  userContent: {
    zIndex: 1,
    color: "white",
    maxWidth: "800px",
  },
  flyerDescription: {
    maxWidth: "50%",
    lineHeight: "200%",
    fontWeight: "bold",
  },
}));

function LandingPage(props) {
  const classes = useStyles();
  let imageUrl = "/shelf.svg";
  if (props.coverImage) imageUrl = props.coverImage;
  return (
    <div>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          alignItems="center"
          justifyContent="center"
          className={classes.userContentContainer}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            className={classes.userContent}
          >
            {props.children}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          lg={6}
          className={classes.flyer}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            className={classes.flyerText}
          >
            <Grid item xs={12} container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="flex-start">
                  <ImageFadeIn
                    src="/logo/logo_main.svg"
                    alt="bibliocircle"
                    className={classes.logo}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.vectorGraphicContainer}>
              <Grid container justifyContent="center" alignItems="flex-start">
                <ImageFadeIn
                  src={imageUrl}
                  alt="bibliocircle"
                  className={classes.vectorGraphic}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Typography
                color="primary"
                align="center"
                variant="h6"
                className={classes.flyerDescription}
              >
                {BC_DESCRIPTION}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

LandingPage.propTypes = {
  children: PropTypes.object,
  coverImage: PropTypes.string,
};

export default LandingPage;
