import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { capitalCase } from "change-case";
import MediaContentViewer from "../Common/MediaContentViewer";
import Hyperlink from "../Common/Hyperlink";
import BookInsights from "./BookInsights";
import { USER_ROLES } from "../consts";
import CuratedBookEditor from "./CuratedBookEditor";
import { Trans } from "@lingui/macro";
import { useLoggedInUser } from "../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
  },
  subtitle: {
    color: theme.palette.grey[500],
  },
  description: {
    whiteSpace: "pre-wrap",
  },
  plainLink: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    wordBreak: "break-all",
  },
  book: {
    borderRadius: theme.shape.borderRadius,
  },
  alternativeISBNText: {
    marginRight: theme.spacing(0.5),
  },
  rightGutter: {
    marginRight: theme.spacing(0.5),
  },
  bookTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      fontWeight: "bold",
    },
  },
  bookSubtitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  bookMeta: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      color: theme.palette.grey[600],
    },
  },
  authorHyperLink: {
    color: theme.palette.grey[800],
    "&:hover": {
      textDecoration: "underline",
    },
  },
  authorHyperlinkContainer: {
    marginLeft: theme.spacing(0.5),
  },
}));

export default function Book({ book, hideCondition }) {
  const classes = useStyles();
  const loggedInUser = useLoggedInUser();
  const isAdmin = loggedInUser?.role === USER_ROLES.ADMINISTRATOR;
  const hasImages = !!book.images?.length;
  const bookYear = book.year || null;
  const bookAuthorsList = !!book.authors?.length && (
    <Grid container>
      {book.authors.map(({ id, name }, index) => (
        <Grid key={id} item>
          <Grid
            container
            className={
              book.authors.length > 1 && classes.authorHyperlinkContainer
            }
          >
            <Grid item>
              <Hyperlink
                target="_blank"
                to={`/listings?q=${encodeURIComponent(name)}`}
                className={classes.authorHyperLink}
              >
                {name}
              </Hyperlink>
            </Grid>
            <Grid item>{index < book.authors.length - 1 && ","}</Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
  const bookMeta = (
    <Grid container spacing={1}>
      {!!bookAuthorsList && <Grid item>{bookAuthorsList}</Grid>}
      {!!book.authors?.length && !!bookYear && <Grid item>{" - "}</Grid>}
      {!!bookYear && <Grid item>{bookYear}</Grid>}
    </Grid>
  );

  const insights = (
    <Grid container>
      <Grid item xs={12}>
        <BookInsights book={book} />
      </Grid>
    </Grid>
  );

  return (
    <Grid container className={classes.book}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {hasImages && (
            <Grid item xs={12} md={4}>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  <MediaContentViewer urls={book.images} />
                </Grid>
                <Grid item xs={12}>
                  {insights}
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={hasImages ? 8 : 12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container alignContent="flex-start" spacing={3}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h5" className={classes.bookTitle}>
                          {book.title}
                        </Typography>
                      </Grid>
                      {book.subtitle && (
                        <Grid item xs={12}>
                          <Grid container className={classes.subtitle}>
                            <Typography
                              variant="h6"
                              className={classes.bookSubtitle}
                            >
                              {book.subtitle}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        {bookMeta}
                      </Grid>
                    </Grid>
                  </Grid>
                  {book.description && (
                    <Grid item xs={12}>
                      <Grid container>
                        <Typography
                          className={classes.description}
                          variant="body1"
                        >
                          {book.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {!hideCondition && !!book.condition && (
                        <Grid item xs={12}>
                          <Typography variant="subtitle2">
                            <Trans>
                              Condition - {capitalCase(book.condition)}
                            </Trans>
                          </Typography>
                        </Grid>
                      )}
                      {book.language && (
                        <Grid item xs={12}>
                          <Typography variant="subtitle2">
                            <Trans>
                              Language -{" "}
                              <Link
                                className={classes.plainLink}
                                target="_blank"
                                to={`/listings?language=${book.language}`}
                              >
                                {capitalCase(book.language)}
                              </Link>
                            </Trans>
                          </Typography>
                        </Grid>
                      )}
                      {book.publisher && (
                        <Grid item xs={12}>
                          <Typography variant="subtitle2">
                            <Trans>
                              Publisher -{" "}
                              <Link
                                className={classes.plainLink}
                                target="_blank"
                                to={`/listings?q=${book.publisher}`}
                              >
                                {book.publisher}
                              </Link>
                            </Trans>
                          </Typography>
                        </Grid>
                      )}
                      {!!book.pageCount && (
                        <Grid item xs={12}>
                          <Typography variant="subtitle2">
                            <Trans>Number of pages - {book.pageCount}</Trans>
                          </Typography>
                        </Grid>
                      )}
                      {book.category && (
                        <Grid item xs={12}>
                          <Typography variant="subtitle2">
                            <Trans>
                              Category -{" "}
                              <Link
                                className={classes.plainLink}
                                target="_blank"
                                to={`/listings?category=${book.category}`}
                              >
                                {capitalCase(book.category)}
                              </Link>
                            </Trans>
                          </Typography>
                        </Grid>
                      )}
                      {/\d+/.test(book.isbn) && (
                        <Grid item xs={12}>
                          <Grid container justifyContent="flex-start">
                            <Typography variant="subtitle2">
                              ISBN -{" "}
                              {
                                <a
                                  className={classes.plainLink}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={`https://isbnsearch.org/isbn/${book.isbn}`}
                                >
                                  {book.isbn}
                                </a>
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      {!!book.alternativeISBNs?.length && (
                        <Grid item xs={12} jusitfy="flex-start">
                          <Grid container>
                            <Grid item>
                              <Typography
                                variant="subtitle2"
                                className={classes.alternativeISBNText}
                              >
                                <Trans>Alternative ISBNs -</Trans>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Grid container alignItems="flex-end">
                                {book.alternativeISBNs.map((isbn, i) => (
                                  <React.Fragment key={isbn}>
                                    <Grid item>
                                      <Hyperlink
                                        to={`/listings?isbn=${encodeURIComponent(
                                          isbn
                                        )}`}
                                        target="_blank"
                                        className={classes.plainLink}
                                      >
                                        <Typography variant="subtitle2">
                                          {isbn}
                                        </Typography>
                                      </Hyperlink>
                                    </Grid>
                                    {i < book.alternativeISBNs.length - 1 && (
                                      <Grid
                                        item
                                        className={classes.rightGutter}
                                      >
                                        ,{" "}
                                      </Grid>
                                    )}
                                  </React.Fragment>
                                ))}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {/* Todo: Revisit if we need the book URL. Removing this for now for security purposes */}
                      {book?.url && (
                        <Grid style={{ display: "none" }} item xs={12}>
                          <Typography variant="subtitle2">
                            <Trans>
                              URL -{" "}
                              {
                                <a
                                  className={classes.plainLink}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={book.url}
                                >
                                  {book.url}
                                </a>
                              }
                            </Trans>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {isAdmin && (
                <Grid item xs={12}>
                  <CuratedBookEditor book={book} />
                </Grid>
              )}
              <Grid item xs={12} md={8}>
                {!hasImages && (
                  <Grid item xs={12}>
                    {insights}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Book.propTypes = {
  book: PropTypes.object.isRequired,
  hideCondition: PropTypes.bool,
};
