import React, { useState } from "react";
import PropTypes from "prop-types";
import BookEditorDialog from "./BookManager/BookEditorDialog";
import { Button, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import {
  CREATE_CURATED_BOOK,
  UPDATE_CURATED_BOOK,
} from "../../consts/mutations";
import { actionTypes } from "../index.reducer";
import ConfirmPrompt from "../Common/ConfirmPrompt";
import { dataURLtoBlob, isDataUrl } from "../../utils/fileOperations";
import { IMAGE_SERVER_URL } from "../../config";
import { DB_VALIDATION_ERRORS } from "../consts";
import { CHECK_CURATED_BOOK } from "../../consts/queries";
import { useLoader, usePromisifiedQuery } from "../../utils/hooks";

export default function CuratedBookEditor({ book }) {
  const dispatch = useDispatch();
  const [editorOpen, setEditorOpen] = useState(false);
  const [curatedBook, setCuratedBook] = useState(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [curatedBookUpdate, setCuratedBookUpdate] = useState(null);
  const [confirmUpdateAwaiting, setConfirmUpdateAwaiting] = useState(false);
  const checkCuratedBook = usePromisifiedQuery(CHECK_CURATED_BOOK);
  const [createCuratedBook] = useMutation(CREATE_CURATED_BOOK);
  const [updateCuratedBook] = useMutation(UPDATE_CURATED_BOOK);
  useLoader(formSubmitting);

  const startAction = () => setFormSubmitting(true);
  const endAction = () => setFormSubmitting(false);

  const dispatchErrorMessage = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });
  const dispatchInfoMessage = (message) =>
    dispatch({
      type: actionTypes.INFO_MESSAGE,
      message,
    });

  const toggleOpenEditor = () => {
    setCuratedBook({ ...book });
    setEditorOpen((isOpen) => !isOpen);
  };

  const togglePromptConfirmUpdate = () => {
    setConfirmUpdateAwaiting((isWaiting) => !isWaiting);
  };

  const uploadImages = (rawImages) => {
    return Promise.all(
      rawImages.map((dataUrl) => {
        if (isDataUrl(dataUrl)) {
          const formData = new FormData();
          const imageBlob = dataURLtoBlob(dataUrl);
          if (!imageBlob) return Promise.resolve();
          formData.append("file", imageBlob);
          return fetch(`${IMAGE_SERVER_URL}/upload/xl`, {
            method: "POST",
            body: formData,
            credentials: "include",
          }).then((response) => {
            if (response.status === 201) {
              return response.json();
            }
            return Promise.reject("failed to upload image");
          });
        } else {
          return { fileName: dataUrl };
        }
      })
    ).then((mediaContents) =>
      mediaContents.map(
        (media) => (media.fileName || "").split("/").pop() // Extract the image name from absolute URL
      )
    );
  };

  const onCreateCuratedBook = (book) => {
    const { id, index, condition, ...bookDetails } = book; // Remove unnecessary fields
    startAction();
    return uploadImages(bookDetails.images)
      .then((images) => ({ ...bookDetails, images }))
      .then((newBookDraft) => {
        return createCuratedBook({
          variables: {
            book: newBookDraft,
          },
        })
          .then(({ data }) => {
            dispatchInfoMessage("Successfully saved as a curated book!");
            toggleOpenEditor();
          })
          .catch(() => {
            dispatchErrorMessage(
              "Something went wrong while creating the curated book. Please try again later!"
            );
          });
      })
      .catch((err) => {
        dispatchErrorMessage(
          "Something went wrong while processing images. Please try again later or without images!"
        );
      })
      .finally(endAction);
  };

  const onUpdateCuratedBook = (accept) => {
    togglePromptConfirmUpdate();
    if (!accept) {
      endAction();
      toggleOpenEditor();
      return;
    }
    const { id, index, condition, ...bookDetails } = curatedBookUpdate; // Remove unnecessary fields
    startAction();
    return uploadImages(bookDetails.images)
      .then((images) => ({ ...bookDetails, images }))
      .then((bookDraft) => {
        return updateCuratedBook({
          variables: {
            book: bookDraft,
          },
        })
          .then(({ data }) => {
            dispatchInfoMessage("Successfully saved as a curated book!");
            toggleOpenEditor();
          })
          .catch((err) => {
            if (
              err.graphQLErrors?.[0]?.code === DB_VALIDATION_ERRORS.DUPLICATE
            ) {
              togglePromptConfirmUpdate();
              return;
            }

            dispatchErrorMessage(
              "Something went wrong while creating the curated book. Please try again later!"
            );
          });
      })
      .catch(() => {
        dispatchErrorMessage(
          "Something went wrong while processing images. Please try again later or without images!"
        );
      })
      .finally(endAction);
  };

  const onSubmit = (book) => {
    startAction();
    checkCuratedBook({ isbn: book.isbn })
      .then(({ data }) => {
        if (data?.curatedBook) {
          togglePromptConfirmUpdate();
          setCuratedBookUpdate(book);
        } else {
          onCreateCuratedBook(book);
        }
      })
      .catch(() => {
        dispatchErrorMessage(
          "Unable to check for existing curated book! Please try again later."
        );
        endAction();
      });
  };

  return (
    <Grid container>
      <BookEditorDialog
        book={curatedBook}
        open={editorOpen}
        onClose={toggleOpenEditor}
        onSave={onSubmit}
        disabled={formSubmitting}
        hideCondition
        requireISBN
      />
      <ConfirmPrompt
        open={confirmUpdateAwaiting}
        onAction={onUpdateCuratedBook}
        question="A curated book already exists with the same ISBN. Do you wish to update existing curated book?"
      />
      <Button
        variant="contained"
        color="primary"
        size="small"
        disableElevation
        disabled={formSubmitting}
        onClick={toggleOpenEditor}
      >
        Create Curated Book From This
      </Button>
    </Grid>
  );
}

CuratedBookEditor.propTypes = {
  book: PropTypes.object.isRequired,
};
