import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles, TextField } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { grey } from "@material-ui/core/colors";

const useNonBorderedStyles = ({ noLabel, error }) =>
  makeStyles((theme) => ({
    root: {
      paddingTop: noLabel ? 0 : theme.spacing(3),
      borderRadius: theme.shape.borderRadius,
    },
    inputRoot: {
      padding: "5px !important",
      "& .MuiInputBase-input": {
        padding: "10px !important",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: error ? `1px solid red` : "1px solid transparent",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: error ? `1px solid red` : "1px solid transparent",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: error ? `1px solid red` : "1px solid transparent",
      },
      backgroundColor: theme.palette.background.paper,
    },
  }));

const useBorderedStyles = ({ noLabel, error }) =>
  makeStyles((theme) => ({
    root: {
      paddingTop: noLabel ? 0 : theme.spacing(3),
      borderRadius: theme.shape.borderRadius,
    },
    inputRoot: {
      padding: "5px !important",
      "& .MuiInputBase-input": {
        padding: "10px !important",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: error ? `1px solid red` : `1px solid ${grey[300]}`,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: error ? `1px solid red` : `1px solid ${grey[300]}`,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: error ? `1px solid red` : `1px solid ${grey[300]}`,
      },
      backgroundColor: theme.palette.background.paper,
    },
  }));

export default function BAutoComplete({
  loading,
  noLabel,
  bordered,
  options,
  onChange,
  onInputChange,
  onFocus,
  autoSelect,
  error,
  filterOptions,
  multiple,
  defaultValue,
  value,
  disabled,
  getOptionLabel,
  freeSolo,
  placeholder,
}) {
  const classesNonBordered = useNonBorderedStyles({ noLabel, error })();
  const classesBordered = useBorderedStyles({ noLabel, error })();
  return (
    <Autocomplete
      classes={bordered ? classesBordered : classesNonBordered}
      multiple={multiple}
      options={options}
      disabled={disabled}
      onFocus={onFocus}
      autoSelect={autoSelect}
      freeSolo={freeSolo}
      onChange={onChange}
      onInputChange={onInputChange}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      defaultValue={defaultValue}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading && (
                  <CircularProgress
                    color="primary"
                    size={30}
                    style={{ marginRight: 6 }}
                  />
                )}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
