import React, { useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import LandingPage from "./LandingPage";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { VERIFY_EMAIL } from "../consts/mutations";
import { useDispatch } from "react-redux";
import { actionTypes } from "../Application/index.reducer";
import { useLoader, useQueryParams } from "../utils/hooks";
import ErrorPage from "../Application/Error/ErrorPage";

const useStyles = makeStyles((theme) => ({
  inputField: {
    backgroundColor: "white",
    width: "100%",
    marginBottom: "10px",
  },
  contentHeading: {
    fontSize: "32px",
    marginBottom: "50px",
  },
  plainLink: {
    color: "white",
    textDecoration: "none",
  },
  actionButtonContainer: {
    margin: theme.spacing(10),
  },
  goBackToLoginButton: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.getContrastText(theme.palette.grey[800]),
  },
}));

function EmailVerification() {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = useQueryParams();
  const params = useParams();
  const dispatch = useDispatch();
  const [verifyEmail, { loading, error, data }] = useMutation(VERIFY_EMAIL, {
    variables: {
      verificationArgs: { id: params.verificationId },
    },
  });
  const redirectPathQueryParam = queryParams.get("redirectPath");
  const redirectPath =
    redirectPathQueryParam && redirectPathQueryParam.startsWith("/")
      ? redirectPathQueryParam
      : "/";
  useEffect(() => {
    verifyEmail();
  }, [verifyEmail]);

  useLoader(loading);

  if (error) {
    dispatch({
      type: actionTypes.EMIT_ERROR,
      error,
    });
  }

  if (!data) {
    return (
      <LandingPage>
        <Grid container justifyContent="flex-start">
          <Grid item container xs={12} justifyContent="center">
            <h1 className={classes.contentHeading}>
              Verifying Email. Please wait...
            </h1>
          </Grid>
        </Grid>
      </LandingPage>
    );
  }

  const errorPage = (
    <ErrorPage
      title="Oops!"
      description="Email verification could not be completed at the moment. Please try again later!"
      statusCode={403}
      showNavigationButton
      navigationButtonText="Go To Home Page"
      navigationButtonUrl="/"
    />
  );

  if (data && !data.verifyEmail) {
    return errorPage;
  }

  const locationState = { signedUpUser: data.verifyEmail.user };
  locationState.redirectPath = redirectPath;
  if (data?.verifyEmail?.verified) {
    history.push("/login", locationState);
  }

  // If the email could not be verified for some reason
  return errorPage;
}

export default EmailVerification;
