import {
  AUTH_REFRESH,
  AUTH_SUCCESS,
  LOGOUT,
  UPDATE_PROFILE,
} from "./auth.actionTypes";
import * as jwt from "jose";
import * as mobileEvents from "../mobile/events";

function userReducer(state = null, action) {
  switch (action.type) {
    case AUTH_SUCCESS:
      try {
        const token = jwt.decodeJwt(action.token || "");
        if (token?.id) {
          mobileEvents.userLoggedIn({
            userId: token.id,
          });
        }
        return token;
      } catch {}
      return null;
    case AUTH_REFRESH:
      try {
        return jwt.decodeJwt(action.token || "");
      } catch {}
      return null;
    case LOGOUT:
      if (state?.id) {
        mobileEvents.userLoggedOut({
          userId: state.id,
        });
      }
      return null;
    case UPDATE_PROFILE:
      return {
        ...state,
        ...action.user,
      };
    default:
      return state;
  }
}

export default userReducer;
