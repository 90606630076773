import React from "react";
import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import ListingsSearchController from "./ListingsSearchController";
import PageHead from "../Common/PageHead";
import { useScrollTop } from "../../utils/hooks";
import SearchGroup from "./SearchGroup";

const useStyles = makeStyles((theme) => ({
  searchGroup: {
    paddingTop: theme.spacing(3),
    background: theme.palette.gradient.light,
    paddingBottom: "20px",
  },
  searchController: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2),
    },
    paddingTop: theme.spacing(4),
  },
}));

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export default function PublicSearchView() {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = useQueryParams();
  useScrollTop([history.location.key]);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const searchArgs = {
    query: queryParams.get("q"),
    isbn: queryParams.get("isbn"),
    location: queryParams.get("location"),
    priceModelType: queryParams.get("priceModelType"),
    category: queryParams.get("category"),
    condition: queryParams.get("condition"),
    language: queryParams.get("language"),
    minPrice: queryParams.get("minPrice"),
    maxPrice: queryParams.get("maxPrice"),
    tags: queryParams.get("tags"),
    bookRequestScope: queryParams.get("bookRequestScope"),
  };
  const isbnList = (queryParams.get("isbnList") || "")
    .split(",")
    .map((isbn) => isbn.trim())
    .filter((isbn) => !!isbn);
  if (isbnList.length) searchArgs.isbnList = isbnList;

  return (
    <>
      <PageHead title="Search | bibliocircle" />
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        className={classes.searchGroup}
      >
        <SearchGroup filterPanelOpen={isDesktop} />
      </Grid>
      <Grid container justifyContent="center">
        <Grid
          item
          container
          xs={11}
          lg={10}
          justifyContent="center"
          className={classes.searchController}
        >
          <ListingsSearchController
            key={history.location.key}
            searchArgs={searchArgs}
            showResultsCount
            noResultsMessage="No books found!"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={4}
          />
        </Grid>
      </Grid>
    </>
  );
}
