import { PLACEHOLDER_IMAGES } from "../../consts";
import { createFeedPostDataExtractor } from "./transformerUtils";

export default function UserCreatedCritiqueTransformer(feedPost) {
  const extractor = createFeedPostDataExtractor(feedPost.primaryContent?.data);
  return {
    actorFullName: feedPost.actor.name,
    actorImage: feedPost.actor.image || PLACEHOLDER_IMAGES.USER,
    actorPath: `/profile/${feedPost.actor.id}`,
    activityText: "published a critique",
    resourcePath: `/critique/${extractor.get("critiqueId")}`,
    primaryContent: {
      title: extractor.get("critiqueTitle"),
      content: extractor.get("critiqueContent"),
      images: feedPost.primaryContent?.images || [],
    },
    timestamp: feedPost.activityTimestamp,
  };
}
