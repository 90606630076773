import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { t } from "@lingui/macro";
import ListingsSearchController from "../../Search/ListingsSearchController";
import { useLoggedInUser } from "../../../utils/hooks";
import { TEMP_LISTING_INACTIVE_REASONS } from "../../consts";

export default function InactiveListingsPage({ userId, searchQuery }) {
  const loggedInUser = useLoggedInUser();
  const ownProfile = loggedInUser ? userId === loggedInUser.profile : false;
  const tempInactiveReasons = TEMP_LISTING_INACTIVE_REASONS.map(
    (reason) => reason.value
  );
  let searchArgs = {
    userScope: userId,
    active: false,
    query: searchQuery,
  };
  if (!ownProfile)
    searchArgs = {
      ...searchArgs,
      inactiveReasons: tempInactiveReasons,
    };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10} xl={12}>
        <ListingsSearchController
          cachePolicy="cache-first"
          searchArgs={searchArgs}
          clickableCards={false}
          enableActions={true}
          showEndOfResultsBanner={false}
          noResultsMessage={t`No Inactive Ads Found!`}
          xs={12}
          xl={6}
        />
      </Grid>
    </Grid>
  );
}

InactiveListingsPage.propTypes = {
  userId: PropTypes.string.isRequired,
  searchQuery: PropTypes.string,
};
