import React from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Fade,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import Hyperlink from "../Common/Hyperlink";
import { getChatGroupTopic } from "./chat-utils";

const useStyles = makeStyles((theme) => {
  const cardCommonStyles = {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    borderColor: theme.palette.grey[100],
    border: "1px solid",
    "&:hover": {
      backgroundColor: theme.palette.primary.lighter,
    },
    borderRadius: theme.shape.borderRadius,
  };
  return {
    cardSelected: {
      ...cardCommonStyles,
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.main,
    },
    card: {
      ...cardCommonStyles,
    },
    chatAvatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  };
});

export default function ChatGroupCard({
  chatGroup,
  currentUserId,
  selected,
  onClick,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const isXsDevice = useMediaQuery(theme.breakpoints.only("xs"));
  const participants = chatGroup.participants.filter(
    (p) => p.id !== currentUserId
  );

  const handleClickCard = () => onClick(chatGroup);
  let threadTitle = getChatGroupTopic(chatGroup, currentUserId);
  return (
    <Grid
      component={Hyperlink}
      to={`/chat/${chatGroup.id}/thread/${chatGroup.threadId}`}
      container
      onClick={handleClickCard}
      className={selected ? classes.cardSelected : classes.card}
    >
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          direction={isSmallDevice && !isXsDevice ? "column" : "row"}
        >
          <Grid item>
            <Typography align="center" variant="subtitle2">
              {threadTitle}
            </Typography>
          </Grid>
          <Grid item>
            <AvatarGroup>
              {participants.map((user) => {
                const userName = [user.firstName, user.lastName]
                  .join(" ")
                  .trim();

                return (
                  <Tooltip title={userName} key={user.id}>
                    <Fade in>
                      <Avatar
                        src={user.profilePicture}
                        className={classes.chatAvatar}
                      />
                    </Fade>
                  </Tooltip>
                );
              })}
            </AvatarGroup>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ChatGroupCard.propTypes = {
  chatGroup: PropTypes.object.isRequired,
};
