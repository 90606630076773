import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import Hyperlink from "../Common/Hyperlink";
import ImageFadeIn from "../Common/ImageFadeIn";

const useStyles = makeStyles((theme) => ({
  page: {
    padding: theme.spacing(3),
  },
  container: {
    padding: "3vw",
  },
  heading: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  imageContainer: {
    width: "100%",
  },
  image: {
    width: "60%",
    maxHeight: "80vh",
    objectFit: "contain",
  },
}));

export default function ErrorPage({
  title,
  description,
  statusCode,
  showNavigationButton,
  navigationButtonUrl,
  navigationButtonText,
}) {
  const classes = useStyles();
  let image;
  switch (statusCode) {
    case 404:
      image = "/unavailable.svg";
      break;
    case 403:
      image = "/blocked.svg";
      break;
    case 500:
      image = "/server_down.svg";
      break;
    default:
      image = "/warning.svg";
  }
  return (
    <Grid container>
      <Grid item xs={12} className={classes.page}>
        <Grid
          container
          alignContent="stretch"
          alignItems="center"
          spacing={6}
          className={classes.container}
        >
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Typography
                      variant="h3"
                      align="center"
                      className={classes.heading}
                    >
                      {title}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Typography variant="h6" align="center">
                      {description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Grid
                  container
                  className={classes.imageContainer}
                  justifyContent="center"
                >
                  <ImageFadeIn
                    src={image}
                    alt={title}
                    className={classes.image}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {showNavigationButton && (
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    component={Hyperlink}
                    to={navigationButtonUrl}
                    color="primary"
                    variant="contained"
                    size="large"
                    disableElevation
                    disableRipple
                  >
                    {navigationButtonText}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

ErrorPage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  statusCode: PropTypes.number,
  showNavigationButton: PropTypes.bool,
  navigationButtonUrl: PropTypes.string,
  navigationButtonText: PropTypes.string,
};
