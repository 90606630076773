import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Link, makeStyles, Typography } from "@material-ui/core";
import UserAvatar from "../../../Common/UserAvatar";
import { Rating } from "@material-ui/lab";
import dayjs from "dayjs";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
  },
  blurred: {
    filter: `blur(5px)`,
  },
  toggleHIdeReviewLink: {
    cursor: "pointer",
  },
}));

export default function StoryReview({ review }) {
  const classes = useStyles();
  const [hideReview, setHideReview] = useState();
  const toggleHideReview = () => setHideReview((hidden) => !hidden);

  useEffect(() => {
    setHideReview(!!review?.containsSpoilers);
  }, [review]);

  return (
    <Grid container className={classes.card}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} sm={4} md={3}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <UserAvatar user={review.reviewer} />
              </Grid>
              <Grid item xs={12}>
                <Rating readOnly value={review.rating} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <Grid container spacing={1}>
              {review.containsSpoilers && (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography color="secondary" variant="body2">
                        <Trans>* This review contains spoilers!</Trans>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Link
                        className={classes.toggleHIdeReviewLink}
                        onClick={toggleHideReview}
                      >
                        {hideReview ? t`show review` : t`hide review`}
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} className={hideReview && classes.blurred}>
                <Typography variant="body1">{review.review}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="textSecondary">
                  {dayjs(review.updatedAt).format("MMMM D, YYYY [at] hh:mm a")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

StoryReview.propTypes = {
  review: PropTypes.object.isRequired,
};
