import { createStore } from "redux";
import rootReducer from "./rootReducer";

const isDevelopment = window.location.hostname === "localhost";

export default createStore(
  rootReducer,
  isDevelopment &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
);
