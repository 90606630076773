import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles } from "@material-ui/core";
import ListingCard from "../Listing/ListingCard";
import ListingCardMobile from "../Listing/ListingCardMobile";

const useStyles = makeStyles((theme) => ({
  smallCard: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  largeCard: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

export default function Listings({
  items,
  variant,
  clickableCards,
  enableActions,
  xs,
  md,
  lg,
  xl,
}) {
  const classes = useStyles();
  const isMobile = variant === "mobile";
  return (
    <Grid item>
      <Grid container justifyContent="flex-start" spacing={isMobile ? 2 : 5}>
        {items.map((item) => (
          <Grid key={item.id} item xs={xs} md={md} lg={lg} xl={xl}>
            {!isMobile && (
              <Grid container className={classes.largeCard}>
                <ListingCard
                  key={item.id}
                  item={item}
                  clickable={clickableCards}
                  actions={enableActions}
                />
              </Grid>
            )}
            {isMobile && (
              <Grid container className={classes.smallCard}>
                <ListingCardMobile
                  key={item.id}
                  item={item}
                  clickable={clickableCards}
                  actions={enableActions}
                />
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

Listings.propTypes = {
  clickableCards: PropTypes.bool,
  enableActions: PropTypes.bool,
  xs: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
};

Listings.defaultProps = {
  clickableCards: true,
  enableActions: false,
  xs: 12,
};
