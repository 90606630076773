import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Hyperlink from "../../../Common/Hyperlink";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.spacing(4),
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  genreText: {
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    fontSize: "18px",
  },
}));

export default function CategoryCard({ genre }) {
  const classes = useStyles();
  return (
    <Hyperlink to={`/stories/search?genres=${genre.code}`}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container className={classes.card}>
            <Typography variant="body1" className={classes.genreText}>
              {genre.text}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Hyperlink>
  );
}
