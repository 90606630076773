import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  COMMENT_ON_STORY_CHAPTER,
  DELETE_STORY_CHAPTER,
  MAKE_STORY_READING_CHECKPOINT,
  TOGGLE_PUBLISH_STORY_CHAPTER,
} from "../../../consts/mutations";
import { GET_STORY_CHAPTER } from "../../../consts/queries";
import {
  useDisableCopyPaste,
  useDispatchErrorMessage,
  useDispatchInfoMessage,
  useLoader,
  useLoggedInUser,
  useRequireLogin,
  useScrollTop,
} from "../../../utils/hooks";
import Hyperlink from "../../Common/Hyperlink";
import ErrorPage from "../../Error/ErrorPage";
import MessageThread from "../../Messages/MessageThread";
import ChapterSelection from "../Story/ChapterSelection";
import StoryOpenIcon from "../StoryOpenIcon";
import ChapterHeading from "./ChapterHeading";
import ForwardIcon from "@material-ui/icons/Forward";
import FinishedIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";
import StoryIcon from "../StoryIcon";
import PublishIcon from "@material-ui/icons/Public";
import ListIcon from "@material-ui/icons/List";
import EditIcon from "@material-ui/icons/Edit";
import { GENERIC_ALERT_ERROR_MESSAGE } from "../../../config";
import { amber, green } from "@material-ui/core/colors";
import SuggestedStories from "../Story/SuggestedStories";
import LargeStoryCard from "../Story/LargeStoryCard";
import StoryInfoCard from "./StoryInfoCard";
import ConfirmPrompt from "../../Common/ConfirmPrompt";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import DropDownIcon from "@material-ui/icons/ArrowDropDown";
import MediumStoryCard from "../Story/MediumStoryCard";
import { t, Trans } from "@lingui/macro";
import PageHead from "../../Common/PageHead";
import PublishStoryPrompt from "./PublishStoryPrompt";

const useStyles = makeStyles((theme) => {
  return {
    page: {
      marginTop: theme.spacing(4),
    },
    chapterContent: {
      lineHeight: 2,
      whiteSpace: "pre-wrap",
      userSelect: "none",
      "&::selection": {
        background: "none",
      },
      "&::-moz-selection": {
        background: "none",
      },
    },
    checkpointText: {
      color: theme.palette.text.secondary,
      fontSize: "18px",
      fontWeight: "bold",
    },
    chapterBanner: {
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.grey[100],
    },
    publishedChapterNote: {
      color: green[600],
    },
    unpublishedChapterNote: {
      color: amber[900],
    },
    chapterStatusNoteIcon: {
      marginRight: theme.spacing(0.5),
      fontSize: 18,
    },
    desktopOnly: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    mobileOnly: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    mdAndAboveOnly: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    smAndBelowOnly: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    mdOnly: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    chapterActionsPanel: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        flexDirection: "row",
      },
      justifyContent: "space-between",
    },
    actionButtonContent: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    readingModeButton: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  };
});

export default function StoryChapter() {
  useDisableCopyPaste();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileChapterSelectionOpen, setMobileChapterSelectionOpen] =
    useState(false);
  const isMdDevice = useMediaQuery(theme.breakpoints.only("md"));
  const isXsDevice = useMediaQuery(theme.breakpoints.only("xs"));
  const isLargeDevice = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmallDevice = !isMdDevice && !isLargeDevice;
  const history = useHistory();
  const loggedInUser = useLoggedInUser();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [distractionsLess, setDistractionsLess] = useState(false);
  const [showPublishStoryPopup, setShowPublishStoryPopup] = useState(false);
  const { storyId, chapterId } = useParams();
  const [deleteChapterDecisionPending, setDeleteChapterDecisionPending] =
    useState(false);
  const [
    togglePublishChapterDecisionPending,
    setTogglePublishChapterDecisionPending,
  ] = useState(false);
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] = useState(null);
  const { loading, error, data } = useQuery(GET_STORY_CHAPTER, {
    variables: { id: chapterId },
    fetchPolicy: "cache-first",
    skip: !loggedInUser,
  });
  const [makeStoryReadingCheckpoint] = useMutation(
    MAKE_STORY_READING_CHECKPOINT
  );
  const [commentOnStoryChapter] = useMutation(COMMENT_ON_STORY_CHAPTER);
  const [togglePublishStoryChapter] = useMutation(TOGGLE_PUBLISH_STORY_CHAPTER);
  const [deleteChapter] = useMutation(DELETE_STORY_CHAPTER);

  useRequireLogin();
  useLoader(loading);
  useScrollTop([loading]);
  useEffect(() => {
    setShowPublishStoryPopup(!!history.location.state?.storyReadyToPublish);
  }, [history.location.state]);
  const dispatchErrorMessage = useDispatchErrorMessage();
  const dispatchInfoMessage = useDispatchInfoMessage();
  const startAction = () => setActionInProgress(true);
  const endAction = () => setActionInProgress(false);
  const handleOpenMoreActions = (event) =>
    setMoreActionsAnchorEl(event.currentTarget);
  const handleCloseMoreActions = () => setMoreActionsAnchorEl(null);

  const onClickDeleteChapter = () => setDeleteChapterDecisionPending(true);
  const onClickTogglePublishChapter = () =>
    setTogglePublishChapterDecisionPending(true);
  const handleClosePublishStoryPrompt = () => {
    if (history.location.state) {
      const state = { ...history.location.state };
      delete state.storyReadyToPublish;
      history.replace({ ...history.location, state });
    }
    setShowPublishStoryPopup(false);
  };

  const handleDeleteChapterDecision = (confirmed) => {
    setDeleteChapterDecisionPending(false);
    handleCloseMoreActions();
    if (confirmed) {
      deleteChapter({
        variables: {
          id: chapter.id,
        },
        update(cache) {
          cache.modify({
            fields: {
              searchStoryChapters(existingChapters, { readField }) {
                return existingChapters.filter(
                  (chapterRef) => chapter.id !== readField("id", chapterRef)
                );
              },
            },
          });
        },
      })
        .then(() => {
          history.push(`/story/${chapter.story.id}/manage`);
        })
        .catch(() => {
          dispatchErrorMessage(
            t`Unable to delete the chapter at the moment. Please try again later.`
          );
        });
    }
  };

  const toggleOpenMobileChapterSelection = () =>
    setMobileChapterSelectionOpen((isOpen) => !isOpen);

  useEffect(() => {
    if (data?.storyChapter) {
      const chapter = data.storyChapter;
      makeStoryReadingCheckpoint({
        variables: {
          storyReadingCheckpoint: {
            storyId: chapter.story.id,
            chapterId: chapter.id,
            finished: false,
          },
        },
      }).catch(() => {
        // Do nothing here
      });
    }
  }, [data, makeStoryReadingCheckpoint]);

  if (error) {
    return (
      <ErrorPage
        title={t`Something's not right!`}
        description={t`We could not fetch the chapter you were looking for. Please try later. Sorry for the inconvenience!`}
        statusCode={500}
      />
    );
  }

  if (!data) return null;
  const chapter = data.storyChapter;

  if (!chapter?.story) {
    return (
      <ErrorPage
        title={t`Unavailable`}
        description={t`This chapter or story is either removed, or does not exist.!`}
        statusCode={404}
      />
    );
  }

  const isAuthor = loggedInUser.id === chapter.story.author?.id;
  const toggleDistractionsLessMode = () =>
    setDistractionsLess((isEnabled) => !isEnabled);

  const commentOnChapter = (message) => {
    return commentOnStoryChapter({
      variables: {
        chapterId,
        message,
      },
    }).catch(() => {
      dispatchErrorMessage(
        t`An error occurred while commenting. Please try later.`
      );
    });
  };

  const handleTogglePublishDecision = (confirmed) => {
    setTogglePublishChapterDecisionPending(false);
    if (!confirmed) return;

    startAction();
    togglePublishStoryChapter({
      variables: {
        id: chapter.id,
      },
    })
      .then(({ data }) => {
        const updatedChapter = data?.togglePublishStoryChapter;
        if (updatedChapter) {
          history.location.state = {
            storyReadyToPublish:
              updatedChapter.published &&
              updatedChapter.chapterNumber === 1 && // this is the first chapter
              updatedChapter.story.chaptersCount === 1 && // there's only one published chapters
              !updatedChapter.story.published,
          };
          dispatchInfoMessage(
            updatedChapter.published
              ? t`Chapter was published!`
              : t`Chapter was unpublished!`
          );
        }
      })
      .catch(() => {
        dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
      })
      .finally(endAction);
  };

  const markStoryAsFinished = () => {
    makeStoryReadingCheckpoint({
      variables: {
        storyReadingCheckpoint: {
          storyId: chapter.story.id,
          chapterId: chapter.id,
          finished: true,
        },
      },
    })
      .then(() => {
        history.push(`/story/${storyId}`);
      })
      .catch(() => {
        dispatchErrorMessage(
          t`Unable to mark story as finished at the moment!`
        );
      });
  };

  const getConfirmPrompt = () => {
    let question = chapter.published
      ? t`Are you sure you want to unpublish this chapter?`
      : t`Are you sure you want to publish this chapter?`;

    if (chapter.story.published) {
      question = chapter.published
        ? t`Readers won't be able to see unpublished chapters in your story. Are you sure you want to unpublish this chapter?`
        : t`Readers of your story may get a notification that a new chapter is available. Are you sure you want to publish this chapter? `;
    }

    return (
      <ConfirmPrompt
        title={chapter.published ? t`Unpublish Chapter` : t`Publish Chapter`}
        question={question}
        open={togglePublishChapterDecisionPending}
        onAction={handleTogglePublishDecision}
      />
    );
  };

  const footer = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <MessageThread
              threadId={chapter.commentThreadId}
              inputRowsCount={4}
              inputPlaceholder={t`Type a comment`}
              sendButtonText={t`Comment`}
              heading={t`Comments`}
              closed={!chapter.published}
              threadClosureMessage={t`This chapter is unpublished and you cannot comment at the moment.`}
              noMessagesPlaceholder={
                <Typography variant="body1" color="textSecondary">
                  <Trans>No comments yet</Trans>
                </Typography>
              }
              onSendMessage={commentOnChapter}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const manageChapterBanner = (
    <Grid container className={classes.chapterBanner}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          className={classes.chapterActionsPanel}
          spacing={2}
        >
          {chapter.published ? (
            <Grid item className={classes.actionButtonContent}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                className={classes.publishedChapterNote}
              >
                <FinishedIcon className={classes.chapterStatusNoteIcon} />
                <Typography variant="subtitle2" align="center">
                  <Trans>This chapter is published.</Trans>
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid item className={classes.actionButtonContent}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                className={classes.unpublishedChapterNote}
              >
                <InfoIcon className={classes.chapterStatusNoteIcon} />
                <Typography variant="subtitle2" align="center">
                  <Trans>
                    This chapter is not published, and only you can see the
                    chapter content.
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Grid container justifyContent="center">
              <Grid item className={classes.actionButtonContent}>
                <Tooltip
                  title={t`Write new chapter under the story "${chapter.story.title}"`}
                >
                  <Button
                    disabled={actionInProgress}
                    variant="contained"
                    disableElevation
                    disableRipple
                    color="primary"
                    component={Hyperlink}
                    to={`/story/${chapter.story.id}/chapter/new`}
                    startIcon={<StoryIcon />}
                  >
                    <Trans>Write New Chapter</Trans>
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item className={classes.actionButtonContent}>
                <Button
                  disabled={actionInProgress}
                  component={Hyperlink}
                  to={`/story/${storyId}/chapter/${chapterId}/edit`}
                  variant="text"
                  disableElevation
                  disableRipple
                  color="primary"
                  startIcon={<EditIcon />}
                >
                  <Trans>Edit Chapter</Trans>
                </Button>
              </Grid>
              <Grid item className={classes.actionButtonContent}>
                {getConfirmPrompt()}
                <Tooltip
                  title={t`Others will be able to read your story on bibliocircle only when you publish it.`}
                >
                  <Button
                    disabled={actionInProgress}
                    variant="text"
                    disableElevation
                    disableRipple
                    color={chapter.published ? "secondary" : "primary"}
                    onClick={onClickTogglePublishChapter}
                    startIcon={<PublishIcon />}
                  >
                    {chapter.published ? (
                      <Trans>Unpublish Chapter</Trans>
                    ) : (
                      <Trans>Publish Chapter</Trans>
                    )}
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item className={classes.actionButtonContent}>
                <Button
                  disabled={actionInProgress}
                  variant="text"
                  disableElevation
                  disableRipple
                  color="primary"
                  endIcon={<DropDownIcon />}
                  onClick={handleOpenMoreActions}
                >
                  <Trans>More</Trans>
                </Button>
                <ConfirmPrompt
                  title={t`Delete Chapter Permanently`}
                  question={t`Are you sure you want to delete this chapter permanently? This action cannot be undone.`}
                  open={deleteChapterDecisionPending}
                  onAction={handleDeleteChapterDecision}
                />
                <Menu
                  anchorEl={moreActionsAnchorEl}
                  keepMounted
                  open={!!moreActionsAnchorEl}
                  onClose={handleCloseMoreActions}
                  className={classes.userMenu}
                  disableScrollLock
                >
                  <MenuItem onClick={onClickDeleteChapter}>
                    <DeleteIcon
                      className={classes.menuIcon}
                      color="secondary"
                    />
                    <Typography variant="body2" color="secondary">
                      <Trans>Delete Chapter Permanently</Trans>
                    </Typography>
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const nextChapterButton = chapter.nextChapter && (
    <Grid item xs={12}>
      <Button
        variant="contained"
        color="default"
        disableElevation
        disableRipple
        endIcon={<ForwardIcon />}
        component={Hyperlink}
        to={`/story/${chapter.story.id}/chapter/${chapter.nextChapter.id}`}
      >
        <Trans>Continue to Chapter {chapter.nextChapter.chapterNumber}</Trans>
      </Button>
    </Grid>
  );

  const finishReadingButton = !chapter.nextChapter && (
    <Grid item xs={12}>
      <Button
        variant="contained"
        color="default"
        disableElevation
        disableRipple
        disabled={!chapter.story.isComplete}
        onClick={markStoryAsFinished}
        endIcon={<FinishedIcon />}
      >
        <Trans>Finished Reading Story</Trans>
      </Button>
    </Grid>
  );

  const readingModeButton = (
    <Button
      variant="contained"
      color="default"
      disableElevation
      disableRipple
      fullWidth={!distractionsLess}
      startIcon={<StoryOpenIcon />}
      onClick={toggleDistractionsLessMode}
    >
      {distractionsLess ? (
        <Trans>Leave Reading Mode</Trans>
      ) : (
        <Trans>Reading Mode</Trans>
      )}
    </Button>
  );

  const chapterSelection = (
    <ChapterSelection story={chapter.story} manageMode={isAuthor} />
  );
  const suggestedStories = <SuggestedStories storyId={chapter.story.id} />;
  const storyCard = isXsDevice ? (
    <MediumStoryCard story={chapter.story} />
  ) : (
    <LargeStoryCard
      showFacebookShareButton
      showReportButton
      story={chapter.story}
    />
  );
  const storyInfoCard = <StoryInfoCard story={chapter.story} />;

  const mobileChapterSelectionDialog = (
    <Dialog size="xs" fullWidth open={mobileChapterSelectionOpen}>
      <DialogContent>{chapterSelection}</DialogContent>
      <DialogActions>
        <Button onClick={toggleOpenMobileChapterSelection}>
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Container maxWidth="xl" className={classes.page}>
      <PageHead
        title={`Chapter ${chapter.chapterNumber} - ${chapter.story.title} | bibliocircle stories`}
      />
      <PublishStoryPrompt
        story={chapter.story}
        open={showPublishStoryPopup}
        onClose={handleClosePublishStoryPrompt}
      />
      <Grid container spacing={3}>
        {isAuthor && !distractionsLess && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {manageChapterBanner}
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item md={distractionsLess ? 12 : 3} lg={2}>
              <Grid container spacing={3}>
                <Grid className={classes.readingModeButton} item xs={12}>
                  <Grid container justifyContent="center">
                    {readingModeButton}
                  </Grid>
                </Grid>
                {!distractionsLess && (
                  <>
                    <Grid item xs={12} className={classes.mdAndAboveOnly}>
                      <Grid container>
                        <Grid item xs={12}>
                          {storyInfoCard}
                        </Grid>
                      </Grid>
                    </Grid>
                    {isMdDevice && (
                      <Grid item xs={12}>
                        {chapterSelection}
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item md={distractionsLess ? 12 : 9} lg={7}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <ChapterHeading chapter={chapter} />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center">
                        <Grid item xs={12} md={10}>
                          <Typography
                            variant="body1"
                            className={classes.chapterContent}
                          >
                            <div onContextMenu={(e) => e.preventDefault()}>
                              {chapter.content}
                            </div>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {!distractionsLess && (
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} md={10}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  spacing={2}
                                  justifyContent="space-between"
                                >
                                  <Grid item xs={12}>
                                    {!chapter.story.isComplete && (
                                      <Typography
                                        variant="subtitle2"
                                        color="primary"
                                      >
                                        <Trans>
                                          This story is ongoing. You will be
                                          notified when the next chapter is
                                          published.
                                        </Trans>
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid item>
                                    <Grid
                                      container
                                      justifyContent="space-between"
                                      spacing={1}
                                    >
                                      <Grid item>
                                        <Grid container spacing={2}>
                                          {nextChapterButton}
                                          {finishReadingButton}
                                        </Grid>
                                      </Grid>
                                      {isSmallDevice && (
                                        <Grid item>
                                          {mobileChapterSelectionDialog}
                                          <Button
                                            variant="contained"
                                            color="default"
                                            disableElevation
                                            disableRipple
                                            startIcon={<ListIcon />}
                                            onClick={
                                              toggleOpenMobileChapterSelection
                                            }
                                          >
                                            All Chapters
                                          </Button>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={classes.smAndBelowOnly}
                              >
                                <Grid container>
                                  <Grid item xs={12}>
                                    {storyCard}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            {footer}
                          </Grid>
                          <Grid item xs={12} className={classes.mobileOnly}>
                            {suggestedStories}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={3} className={classes.desktopOnly}>
              {!distractionsLess && (
                <Grid container spacing={3}>
                  {isLargeDevice && (
                    <Grid item xs={12}>
                      {chapterSelection}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {suggestedStories}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
