import React from "react";
import { useQuery } from "@apollo/client";
import { SEARCH_LISTINGS } from "../../consts/queries";
import { Grid, makeStyles } from "@material-ui/core";
import ListingCard from "../Listing/ListingCard";

const useStyles = makeStyles((theme) => ({
  listingPreview: {
    border: "1px solid",
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.primary.lighter,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
}));

export default function MessageListingPreview({ message, listingIds }) {
  const classes = useStyles();
  const { error, data } = useQuery(SEARCH_LISTINGS, {
    variables: { listingIds },
    fetchPolicy: "cache-first",
  });
  if (error) return message;

  if (!data?.searchListings) return null;
  const { listings } = data.searchListings;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {message}
      </Grid>
      {listings.map((listing) => (
        <Grid item xs={12} key={listing.id}>
          <Grid container className={classes.listingPreview}>
            <Grid item xs={12}>
              <ListingCard item={listing} />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
