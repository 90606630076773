import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  const baseContactNumberContainer = {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  };

  return {
    defaultPhoneNumber: {
      position: "relative",
      "&::after": {
        content: '"Default"',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        color: theme.palette.getContrastText(theme.palette.primary.main),
        padding: "",
        position: "absolute",
        top: 5,
        right: 0,
        fontSize: "10px",
        borderRadius: theme.shape.borderRadius,
        width: "50px",
        height: "20px",
        backgroundColor: theme.palette.primary.main,
      },
    },
    contactNumberContainer: {
      ...baseContactNumberContainer,
      backgroundColor: theme.palette.grey[200],
    },
    disabledContactNumberContainer: {
      ...baseContactNumberContainer,
      color: theme.palette.grey[300],
      backgroundColor: theme.palette.grey[100],
    },
    phoneIcon: {
      marginRight: "10px",
    },
  };
});

export default function PhoneNumber({
  phoneNumber,
  isDefault,
  justify,
  disabled,
}) {
  const classes = useStyles();
  const phoneNumberClass =
    isDefault && !disabled ? classes.defaultPhoneNumber : null;
  const contactNumberContainerClass = disabled
    ? classes.disabledContactNumberContainer
    : classes.contactNumberContainer;
  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      className={contactNumberContainerClass}
    >
      <Grid item xs={12} container className={phoneNumberClass}>
        <Grid container xs={12} justifyContent={justify} alignItems="center">
          <Typography variant="subtitle2">{phoneNumber}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

PhoneNumber.propTypes = {
  phoneNumber: PropTypes.object,
  isDefault: PropTypes.bool,
  disabled: PropTypes.bool,
  justify: PropTypes.string,
};

PhoneNumber.defaultProps = {
  phoneNumber: {},
  isDefault: false,
  disabled: false,
  justify: "flex-start",
};
