import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  makeStyles,
  Typography,
  Button,
  Tooltip,
  Divider,
  Link,
  useTheme,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { capitalCase } from "change-case";
import PhoneNumber from "../../Common/PhoneNumbers/PhoneNumber";
import UserAvatar from "../../Common/UserAvatar";
import Hyperlink from "../../Common/Hyperlink";
import { priceModelTypes } from "../../consts";
import ChatButton from "../../Chat/ChatButton";
import { actionTypes } from "../../index.reducer";
import LinkIcon from "@material-ui/icons/Link";
import BookInsights from "../../Listing/BookInsights";
import Banner from "../../Common/Banner";
import ScanLoader from "../../Common/ScanLoader";
import { useLoggedInUser } from "../../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  bookRequestContainer: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
  },
  requestTitle: {
    fontWeight: "bold",
    color: theme.palette.grey[800],
  },
  titleContainer: {
    paddingBottom: theme.spacing(1),
  },
  durationContainer: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
      padding: 0,
    },
    justifyContent: "flex-end",
  },
  booksCountContainerMobile: {
    justifyContent: "flex-start",
    padding: 0,
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  durationText: {
    color: theme.palette.grey[600],
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  booksCountText: {
    color: theme.palette.grey[600],
    fontWeight: "bold",
  },
  infoContainer: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  bookCountContainerDesktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  bookCount: {
    height: "64px",
    width: "64px",
    borderRadius: "48px",
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.getContrastText(theme.palette.grey[600]),
    fontWeight: "bold",
    fontSize: "32px",
  },
  messageContainer: {
    padding: theme.spacing(2),
    borderLeft: "5px solid",
    borderColor: theme.palette.grey[200],
    wordBreak: "break-word",
  },
  phoneNumberContainer: {},
  userContainer: {
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(5),
  },
  actionsContainer: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  noDataText: {
    color: theme.palette.grey[500],
    fontStyle: "italic",
  },
  bookRequestMatchesContainer: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2),
    },
    padding: theme.spacing(1),
  },
  viewBookRequestResultsButton: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    justifyContent: "flex-end",
  },
  contactDetailsLabelText: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  bookRequestMatchesText: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  lastCrawlAt: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    fontSize: 12,
  },
  bibliobotContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    justifyContent: "center",
  },
  scanner: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const getPriceModelLabel = (priceModelType) => {
  switch (priceModelType) {
    case priceModelTypes.RENT:
      return "to Rent";
    case priceModelTypes.EXCHANGE:
      return "to Exchange";
    default:
      return "to Buy";
  }
};

const getVisibilityText = (bookRequest) => {
  let visibilityList = [];
  if (bookRequest.public) visibilityList.push("Public Book Requests");
  visibilityList = visibilityList.concat(
    bookRequest.groups.map((group) => group.name)
  );
  let visibilityText = visibilityList.join(", ");
  if (visibilityList.length > 2) {
    visibilityText = `${visibilityList[0]} and ${
      visibilityList.length - 1
    } other groups`;
  }
  return visibilityText;
};

export default function BookRequestDetailsCard({
  groupScope,
  bookRequest,
  showResultsCountBanner,
  onDelete,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const loggedInUser = useLoggedInUser();
  const bookRequestEditPath = `/book-request/${bookRequest.id}/edit`;

  const ownBookRequest = loggedInUser?.id === bookRequest?.user?.id;
  const duration = bookRequest.expiresAt
    ? dayjs(bookRequest.expiresAt).diff(new Date(), "day") + 1
    : null;
  const authors = bookRequest.authors.map(({ name }) => name).join(", ");
  const onClickDelete = () => {
    onDelete(bookRequest.id);
  };
  const noDataText = <span className={classes.noDataText}>no information</span>;

  const copyBookRequestLink = () => {
    const tempEl = document.createElement("input");
    const bookRequestUrl = new URL(
      `/book-request/${bookRequest.id}`,
      window.location.origin
    ).toString();

    document.body.appendChild(tempEl);
    tempEl.value = bookRequestUrl;
    tempEl.select();
    document.execCommand("copy");
    document.body.removeChild(tempEl);
    dispatch({
      type: actionTypes.INFO_MESSAGE,
      message: "Book request link copied to clipboard!",
      autoHideDuration: 3000,
    });
  };

  const visibilityText = getVisibilityText(bookRequest);
  const getLastCheckedText = () => {
    if (!bookRequest.lastCrawlAt) {
      return "No update from bibliobot on this book request yet.";
    }
    return `Last checked at ${dayjs(bookRequest.lastCrawlAt).format(
      "MMMM D, YYYY [at] h:mm A"
    )}`;
  };

  const lastCrawlAtComponent = (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.lastCrawlAt}
          >
            {getLastCheckedText()}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      className={classes.bookRequestContainer}
      alignItems="flex-start"
      alignContent="flex-start"
    >
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {showResultsCountBanner && (
            <Grid item xs={12}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  className={classes.bookRequestMatchesContainer}
                >
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} sm={1}>
                      <Grid
                        container
                        className={classes.bibliobotContainer}
                        alignItems="center"
                      >
                        <ScanLoader color={theme.palette.primary.main} />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={11}>
                      <Grid container alignItems="flex-start">
                        {!!bookRequest.searchResultsCount && (
                          <Grid item xs={12}>
                            <Grid container alignItems="center">
                              <Grid item xs={12} sm={9} md={10}>
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body2"
                                      color="primary"
                                      className={classes.bookRequestMatchesText}
                                    >
                                      bibliobot might have found{" "}
                                      {bookRequest.searchResultsCount} book
                                      {bookRequest.searchResultsCount > 1
                                        ? "s"
                                        : ""}{" "}
                                      matching this book request!
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    {lastCrawlAtComponent}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={3}
                                md={2}
                                className={classes.viewBookRequestResultsButton}
                              >
                                <Grid
                                  container
                                  alignItems="center"
                                  alignContent="center"
                                >
                                  <Button
                                    component={Hyperlink}
                                    to={`/book-request/${bookRequest.id}#book-request-matches`}
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    size="small"
                                  >
                                    View Results
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {!bookRequest.searchResultsCount && (
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography
                                  variant="body2"
                                  color="primary"
                                  className={classes.bookRequestMatchesText}
                                >
                                  bibliobot is still searching for this book.
                                  Please check again later.
                                </Typography>
                              </Grid>
                              {lastCrawlAtComponent}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} lg={6} className={classes.titleContainer}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container alignItems="center">
                          <Grid item>
                            <Typography
                              variant="h5"
                              className={classes.requestTitle}
                            >
                              {bookRequest.title}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="textSecondary">
                          {getPriceModelLabel(bookRequest.priceModelType)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    className={classes.booksCountContainerMobile}
                  >
                    <Typography
                      variant="subtitle1"
                      className={classes.booksCountText}
                    >
                      {bookRequest.count} book{bookRequest.count !== 1 && "s"}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    className={classes.durationContainer}
                  >
                    <Typography
                      variant="body1"
                      className={classes.durationText}
                    >
                      Expires in {duration} day{duration !== 1 && "s"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} className={classes.infoContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      ISBN - {bookRequest.isbn || noDataText}
                    </Typography>
                    <Typography variant="subtitle2">
                      Alternative ISBNs -{" "}
                      {(bookRequest.alternativeISBNs || []).join(", ") ||
                        noDataText}
                    </Typography>
                    <Typography variant="subtitle2">
                      Year - {bookRequest.year || noDataText}
                    </Typography>
                    <Typography variant="subtitle2">
                      Language -{" "}
                      {capitalCase(bookRequest.language || "") || noDataText}
                    </Typography>
                    <Typography variant="subtitle2">
                      Authors - {authors || noDataText}
                    </Typography>
                    <Typography variant="subtitle2">
                      Publisher - {bookRequest.publisher || noDataText}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={4}
                className={classes.bookCountContainerDesktop}
                alignContent="flex-start"
              >
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Grid container justifyContent="center">
                      <Grid item xs={8}>
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid item>
                            <Grid
                              container
                              justifyContent="center"
                              alignContent="center"
                              alignItems="center"
                              className={classes.bookCount}
                            >
                              <Grid item>{bookRequest.count}</Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container justifyContent="center">
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                align="center"
                              >
                                Book{bookRequest.count > 1 && "s"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {bookRequest.message && (
                <Grid item xs={12}>
                  <Grid container className={classes.messageContainer}>
                    <Typography variant="body1">
                      {bookRequest.message}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} className={classes.userContainer}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <UserAvatar user={bookRequest.user} />
                      </Grid>
                      {!ownBookRequest && (
                        <Grid item xs={12}>
                          <ChatButton
                            initialMessage={`${window.location.origin}/book-request/${bookRequest.id}/listings`}
                            recipient={bookRequest.user}
                            label={`Message User`}
                            buttonProps={{
                              variant: "text",
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {!!bookRequest.contactNumbers.length && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className={classes.phoneNumberContainer}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            className={classes.contactDetailsLabelText}
                          >
                            Contact Details:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          {loggedInUser ? (
                            <Grid container spacing={2}>
                              {bookRequest.contactNumbers.map((contact) => (
                                <Grid key={contact.id} item xs={12}>
                                  <PhoneNumber
                                    phoneNumber={contact.phoneNumber}
                                    justifyContent="center"
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          ) : (
                            <Grid container>
                              <Banner
                                type="basic"
                                message={
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    <Hyperlink
                                      to={{
                                        pathname: "/login",
                                        state: {
                                          redirectPath: `${window.location.pathname}${window.location.search}`,
                                        },
                                      }}
                                    >
                                      <Link color="primary">Log in</Link>
                                    </Hyperlink>{" "}
                                    to view contact details.
                                  </Typography>
                                }
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  {visibilityText && (
                    <Grid item xs={12}>
                      <Typography variant="caption" color="primary">
                        Shared in {visibilityText}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography color="textSecondary" variant="caption">
                          Created at{" "}
                          {dayjs(bookRequest.createdAt).format(
                            "MMMM D, YYYY [at] h:mm A"
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="textSecondary" variant="caption">
                          Last updated at{" "}
                          {dayjs(bookRequest.updatedAt).format(
                            "MMMM D, YYYY [at] h:mm A"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} lg={6}>
                <BookInsights book={bookRequest} />
              </Grid>
              <Grid item xs={12} className={classes.actionsContainer}>
                <Grid container spacing={1}>
                  <Grid item>
                    <Tooltip title="Copy Link to clipboard">
                      <Button
                        onClick={copyBookRequestLink}
                        variant="text"
                        color="primary"
                        startIcon={<LinkIcon />}
                      >
                        Copy Link
                      </Button>
                    </Tooltip>
                  </Grid>
                  {ownBookRequest && (
                    <>
                      <Grid item>
                        <Divider orientation="vertical" />
                      </Grid>
                      <Grid item>
                        <Tooltip title="Edit Book Request">
                          <Button
                            component={Hyperlink}
                            to={
                              groupScope
                                ? `${bookRequestEditPath}?groupScope=${groupScope}`
                                : bookRequestEditPath
                            }
                            variant="text"
                            color="primary"
                          >
                            Edit Book Request
                          </Button>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Divider orientation="vertical" />
                      </Grid>
                      <Grid item>
                        <Tooltip title="Delete Book Request">
                          <Button
                            variant="text"
                            color="secondary"
                            onClick={onClickDelete}
                          >
                            Delete
                          </Button>
                        </Tooltip>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

BookRequestDetailsCard.propTypes = {
  groupScope: PropTypes.string,
  bookRequest: PropTypes.object,
  onDelete: PropTypes.func,
  showResultsCountBanner: PropTypes.bool,
};

BookRequestDetailsCard.defaultProps = {
  onDelete() {},
  showResultsCountBanner: true,
};
