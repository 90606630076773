import React from "react";
import PropTypes from "prop-types";
import { Typography, Avatar, Grid, makeStyles, Fade } from "@material-ui/core";
import Hyperlink from "./Hyperlink";
import { capitalCase } from "change-case";

const useStyles = makeStyles((theme) => ({
  avatarName: {},
  clickableUserAvatar: {
    cursor: "pointer",
  },
  fullAvatarUserName: {
    fontWeight: "normal",
  },
  fullAvatarDescription: {
    color: theme.palette.grey[700],
  },
  avatarFull: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    backgroundColor: theme.palette.grey[300],
    marginRight: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  avatarMinimal: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    backgroundColor: theme.palette.grey[300],
    marginRight: theme.spacing(1),
  },
  minimalAvatarName: {
    fontSize: 12,
  },
  userTitle: {
    fontSize: 10,
    color: theme.palette.grey[600],
    fontWeight: "bold",
  },
}));

export default function UserAvatar({ user, clickable, variant, justify }) {
  const classes = useStyles();
  const makeClickable = clickable && !!user.id;
  const avatarContainerClass = makeClickable ? classes.clickableUserAvatar : "";
  const classicAvatarContent = (
    <Grid
      container
      alignItems="center"
      justifyContent={justify}
      className={avatarContainerClass}
    >
      <Grid item>
        <Fade in>
          <Avatar
            className={classes.avatar}
            alt={user.firstName}
            src={user.profilePicture}
          />
        </Fade>
      </Grid>
      <Grid item className={classes.avatarName}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body2" color="textPrimary">
              {user.firstName} {user.lastName}
            </Typography>
          </Grid>
          {user.levels && (
            <Grid item>
              <Grid container alignItems="flex-start">
                {user.levels.criticTitle && (
                  <Grid item xs={12}>
                    <Typography variant="caption" className={classes.userTitle}>
                      {capitalCase(user.levels.criticTitle)} &bull; Level{" "}
                      {user.levels.criticLevel}
                    </Typography>
                  </Grid>
                )}
                {user.levels.bibliophileLevel && (
                  <Grid item xs={12}>
                    <Typography variant="caption" className={classes.userTitle}>
                      {capitalCase(user.levels.bibliophileTitle)} &bull; Level{" "}
                      {user.levels.bibliophileLevel}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  const minimalAvatarContent = (
    <Grid
      container
      alignItems="center"
      justifyContent={justify}
      className={avatarContainerClass}
    >
      <Grid item>
        <Fade in>
          <Avatar className={classes.avatarMinimal} src={user.profilePicture} />
        </Fade>
      </Grid>
      <Grid item className={classes.avatarName}>
        <Typography
          variant="body2"
          color="textPrimary"
          className={classes.minimalAvatarName}
        >
          {user.firstName} {user.lastName}
        </Typography>
      </Grid>
    </Grid>
  );

  const fullAvatarContent = (
    <Grid
      container
      alignItems="center"
      justifyContent={justify}
      className={avatarContainerClass}
      direction="row"
    >
      <Grid item xs={3}>
        <Grid container justifyContent="flex-end">
          <Fade in>
            <Avatar
              className={classes.avatarFull}
              alt={user.firstName}
              src={user.profilePicture}
            />
          </Fade>
        </Grid>
      </Grid>
      <Grid item xs={9} className={classes.avatarName}>
        <Grid container alignItems="flex-start">
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.fullAvatarUserName}
            >
              {user.firstName} {user.lastName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              color="textPrimary"
              className={classes.fullAvatarDescription}
            >
              {user.description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const avatar =
    variant === "full"
      ? fullAvatarContent
      : variant === "minimal"
      ? minimalAvatarContent
      : classicAvatarContent;

  return makeClickable ? (
    <Hyperlink to={`/profile/${user.id}`}>{avatar}</Hyperlink>
  ) : (
    avatar
  );
}

UserAvatar.propTypes = {
  user: PropTypes.object.isRequired,
  clickable: PropTypes.bool,
  justify: PropTypes.string,
  variant: PropTypes.oneOf(["minimal", "classic", "full"]),
  showBibliophileLevel: PropTypes.bool,
  showCriticLevel: PropTypes.bool,
};

UserAvatar.defaultProps = {
  clickable: true,
  justify: "flex-start",
  variant: "classic",
  showBibliophileLevel: false,
  showCriticLevel: false,
};
