import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  Button,
  useTheme,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { SketchPicker } from "react-color";

const useStyles = makeStyles((theme) => ({
  colorDrop: {
    height: 48,
    width: 48,
    borderRadius: 48,
    cursor: "pointer",
    border: "1px solid",
    borderColor: theme.palette.grey[500],
  },
}));

export default function ColorPicker({ color, title, onChange }) {
  const classes = useStyles();
  const theme = useTheme();
  const [pickerOpen, setPickerOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(
    theme.palette.primary.light
  );

  useEffect(() => {
    if (color) {
      setSelectedColor(color);
    }
  }, [color]);

  const openPicker = () => setPickerOpen(true);
  const closePicker = () => setPickerOpen(false);
  const onChangeColor = (color) => setSelectedColor(color.hex);
  const onChangeColorComplete = (color) => onChange(color.hex);

  const colorPickerDialog = (
    <Dialog open={pickerOpen} onClose={closePicker} disableScrollLock={true}>
      <SketchPicker
        color={selectedColor}
        onChange={onChangeColor}
        onChangeComplete={onChangeColorComplete}
      />
      <DialogActions>
        <Button color="primary" onClick={closePicker}>
          Use color
        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <>
      {colorPickerDialog}
      <Grid container alignItems="center" spacing={2}>
        <Grid
          onClick={openPicker}
          item
          className={classes.colorDrop}
          style={{ backgroundColor: selectedColor }}
        ></Grid>
        <Grid item>
          <Typography variant="body1" color="textSecondary">
            {title}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

ColorPicker.propTypes = {
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

ColorPicker.defaultProps = {
  onChange() {},
  onClose() {},
  open: false,
};
