import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import UserCard from "./UserCard";
import NoResults from "../Common/NoResultsImage";
import Banner from "../Common/Banner";
import { USERS_PAGINATION_LIMIT } from "../../config";
import { useQuery } from "@apollo/client";
import { SEARCH_USERS } from "../../consts/queries";
import { UsersLoader } from "../Common/LoadingPlaceholders/UsersLoader";
import ErrorPage from "../Error/ErrorPage";
import { useLoader } from "../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  noUsersPlaceholder: {
    padding: theme.spacing(3),
  },
  bookRequestsList: {
    height: "80vh",
    overflow: "auto",
  },
  errorBanner: {
    padding: theme.spacing(3),
  },
  noUsersPlaceholderText: {
    fontWeight: "bold",
    textAlign: "center",
  },
}));

export default function UsersSearchController({
  fetchPolicy,
  spacing,
  component,
  fixedResultsCount,
  searchArgs,
  noResultsMessage,
  scrollableTargetId,
  xs,
  md,
  lg,
  xl,
}) {
  const classes = useStyles();
  const UserCardComponent = component || UserCard;
  const fetchOptions = {
    variables: {
      ...searchArgs,
      pagination: {
        limit: fixedResultsCount || USERS_PAGINATION_LIMIT,
        offset: 0,
      },
    },
  };

  if (fetchPolicy) fetchOptions.fetchPolicy = fetchPolicy;
  const { loading, error, data, fetchMore } = useQuery(
    SEARCH_USERS,
    fetchOptions
  );
  useLoader(loading);

  const loadingPlaceholder = <UsersLoader xs={xs} md={md} lg={lg} xl={xl} />;

  if (error) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the users due to a problem. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  if (loading && !data) {
    return (
      <Grid container justifyContent="center">
        {loadingPlaceholder}
      </Grid>
    );
  }

  const {
    searchUsers: { users, pagination },
  } = data;

  const loadMoreUsers = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: USERS_PAGINATION_LIMIT,
          offset: users.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          searchUsers: {
            __typename: prev.searchUsers.__typename,
            users: [
              ...prev.searchUsers.users,
              ...fetchMoreResult.searchUsers.users,
            ],
            pagination: fetchMoreResult.searchUsers.pagination,
          },
        });
      },
    }).catch(() => {});
  };

  if (error)
    return (
      <Grid container justifyContent="center" className={classes.errorBanner}>
        <Grid item xs={12}>
          <Banner
            type="error"
            title="Something went wrong!"
            message="We're sorry! Something went wrong while displaying users for you!
              Please try again later."
          />
        </Grid>
      </Grid>
    );

  const noUsersPlaceholder = (
    <Grid
      container
      justifyContent="center"
      className={classes.noUsersPlaceholder}
    >
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="h5"
          color="primary"
          className={classes.noUsersPlaceholderText}
        >
          {noResultsMessage ||
            "Sorry, we could not find the user you are looking for!"}
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={6} lg={4}>
          <NoResults />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      {!users.length ? noUsersPlaceholder : null}
      <InfiniteScroll
        scrollableTarget={scrollableTargetId}
        style={{ overflow: "none !important" }}
        next={!fixedResultsCount && loadMoreUsers}
        hasMore={users.length < pagination.totalCount}
        loader={
          !fixedResultsCount && <UsersLoader xs={xs} md={md} lg={lg} xl={xl} />
        }
        dataLength={users.length}
      >
        <Grid container spacing={spacing}>
          {users.map((user) => (
            <Grid key={user.id} item xs={xs} md={md} lg={lg} xl={xl}>
              <UserCardComponent user={user} />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
    </Grid>
  );
}

UsersSearchController.propTypes = {
  searchArgs: PropTypes.object,
};

UsersSearchController.defaultProps = {
  searchArgs: {},
};
