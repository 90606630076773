import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Container,
  Grid,
  Link,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import PageTitle from "../Common/PageTitle";
import { useHistory, useParams } from "react-router-dom";
import { GET_CRITIQUE } from "../../consts/queries";
import { useDispatch } from "react-redux";
import { actionTypes } from "../index.reducer";
import UserAvatar from "../Common/UserAvatar";
import Hyperlink from "../Common/Hyperlink";
import ConfirmPrompt from "../Common/ConfirmPrompt";
import { DELETE_CRITIQUE, TOGGLE_VOTE_CRITIQUE } from "../../consts/mutations";
import PageHead from "../Common/PageHead";
import { Rating } from "@material-ui/lab";
import CritiqueBook from "./CritiqueBook";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ReportIcon from "@material-ui/icons/Flag";
import WarningIcon from "@material-ui/icons/Error";
import { GENERIC_ALERT_ERROR_MESSAGE } from "../../config";
import Reporting from "../Common/Reporting";
import ErrorPage from "../Error/ErrorPage";
import {
  useLoader,
  useLoggedInUser,
  useLoginPrompt,
  useScrollTop,
} from "../../utils/hooks";
import { getReadingTime } from "../../utils/common";
import dayjs from "dayjs";
import FacebookIcon from "@material-ui/icons/Facebook";
import { FacebookShareButton } from "react-share";
import UsersSearchController from "../People/UsersSearchController";
import { t, Trans } from "@lingui/macro";
import Banner from "../Common/Banner";
import { useMutation, useQuery } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  critiqueContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
  },
  pageTitle: {
    marginTop: theme.spacing(2),
  },
  criticAvatarLabel: {
    color: theme.palette.grey[700],
  },
  draftIcon: {
    fontSize: 16,
    marginRight: theme.spacing(1),
  },
  critiqueContent: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
    fontSize: 18,
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    lineHeight: 2,
  },
  bookTitle: {
    color: theme.palette.grey[600],
    fontSize: 18,
  },
  voteIcon: {
    minWidth: "200px",
  },
  facebookShareButton: {
    width: "100%",
  },
}));

export default function Critique() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const dispatch = useDispatch();
  const loggedInUser = useLoggedInUser();
  const { critiqueId } = useParams();
  const [actionInProgress, setActionInProgress] = useState(false);
  const [reporterOpen, setReporterOpen] = useState(false);
  const [deleteCritiqueDecisionPending, setDeleteCritiqueDecisionPending] =
    useState(false);
  const { loading, error, data } = useQuery(GET_CRITIQUE, {
    variables: { id: critiqueId },
  });
  const [toggleVoteCritique, { loading: votingCritique }] =
    useMutation(TOGGLE_VOTE_CRITIQUE);
  const [deleteCritique] = useMutation(DELETE_CRITIQUE);
  const startAction = () => setActionInProgress(true);
  const endAction = () => setActionInProgress(false);

  const showDeleteCritiquePrompt = () => setDeleteCritiqueDecisionPending(true);
  const toggleReporter = () => setReporterOpen((isOpen) => !isOpen);

  const [votersListOpen, setVotersListOpen] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getBookWithAllImages = (critiqueCover, book) => {
    if (critiqueCover) {
      return {
        ...book,
        images: [...book.images, critiqueCover],
      };
    }
    return book;
  };

  const handleDeleteCritiqueDecision = (accept) => {
    setDeleteCritiqueDecisionPending(false);
    if (accept) {
      startAction();
      deleteCritique({
        variables: {
          id: critiqueId,
        },
      })
        .then(() => {
          dispatch({
            type: actionTypes.INFO_MESSAGE,
            message: "Successfully deleted the critique!",
          });
          history.push("/critiques");
        })
        .catch(() => {
          dispatch({
            type: actionTypes.ERROR_MESSAGE,
            message:
              "Something went wrong while deleting the critique. Please try again later.",
          });
        })
        .finally(endAction);
    }
  };

  useLoader(loading);
  useScrollTop();

  if (error) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the book you were looking for. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  if (!data) return null;
  const { critique } = data;
  if (!critique) {
    return (
      <ErrorPage
        title="Unavailable"
        description="This critique is either removed, or does not exist.!"
        statusCode={404}
      />
    );
  }
  const isOwnCritique = loggedInUser && loggedInUser.id === critique.critic?.id;
  const isLoggedIn = !!loggedInUser?.id;

  const handleToggleVote = createLoginPromptEventHandler({
    redirectPath: `/critique/${critique.id}`,
    callback: () => {
      toggleVoteCritique({
        variables: { critiqueId: critique.id },
      }).catch(() => {
        dispatch({
          type: actionTypes.ERROR_MESSAGE,
          message: GENERIC_ALERT_ERROR_MESSAGE,
        });
      });
    },
  });

  const toggleVotersList = () => {
    if (!isLoggedIn) {
      const { pathname, search } = history.location;
      history.push("/login", { redirectPath: `${pathname}${search}` });
      return;
    }
    setVotersListOpen((isOpen) => !isOpen);
  };

  return (
    <>
      <PageHead title={`${critique.title} | bibliocircle`} />
      {!critique.published && (
        <Grid item xs={12}>
          <Banner
            rounded={false}
            title={t`This is a draft!`}
            message={
              <Typography justifyContent="center" variant="subtitle2">
                <Trans>
                  This critique is not published yet. Go to{" "}
                  <Hyperlink to={`/critique/${critiqueId}/edit`}>
                    <Trans>Edit Critique</Trans>
                  </Hyperlink>{" "}
                  to publish it when it's ready.
                </Trans>
              </Typography>
            }
            type="info"
          />
        </Grid>
      )}
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              className={classes.critiqueContainer}
            >
              <Grid item xs={12} md={10} lg={8}>
                <Grid container spacing={4}>
                  <Grid item xs={12} className={classes.pageTitle}>
                    <Grid
                      container
                      spacing={3}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={12}>
                        <Grid
                          spacing={2}
                          container
                          alignItems="center"
                          alignContent="center"
                          justifyContent="center"
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant="h6"
                              align="center"
                              className={classes.bookTitle}
                            >
                              A Critique on "{critique.book.title}"
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item xs={12}>
                                <Grid container justifyContent="center">
                                  <Grid item>
                                    <Grid container>
                                      <Grid
                                        item
                                        component={Hyperlink}
                                        to={`/critique/${critiqueId}`}
                                      >
                                        <PageTitle variant="h4" align="center">
                                          {critique.title}
                                        </PageTitle>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <Grid container justifyContent="center">
                                      <Rating
                                        value={critique.rating}
                                        readOnly
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Grid container justifyContent="center">
                                      <Typography
                                        variant="body2"
                                        color="primary"
                                      >
                                        {critique.criticRecommends
                                          ? t`Recommended by the critic`
                                          : `Not Recommended by the critic`}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {isOwnCritique && (
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    spacing={1}
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    <Grid item>
                                      <Button
                                        size="small"
                                        component={Hyperlink}
                                        to={`/critique/${critiqueId}/edit`}
                                        variant="outlined"
                                        color="primary"
                                        disabled={actionInProgress}
                                      >
                                        Edit Critique
                                      </Button>
                                    </Grid>
                                    <Grid item>
                                      <ConfirmPrompt
                                        open={deleteCritiqueDecisionPending}
                                        onAction={handleDeleteCritiqueDecision}
                                        question="Are you sure you want to delete this critique?"
                                      />
                                      <Button
                                        onClick={showDeleteCritiquePrompt}
                                        size="small"
                                        variant="outlined"
                                        color="secondary"
                                        disabled={actionInProgress}
                                      >
                                        Delete Critique
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignContent="center"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Grid item>
                                    <Typography
                                      align="right"
                                      variant="caption"
                                      className={classes.criticAvatarLabel}
                                    >
                                      A Critique By
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <UserAvatar user={critique.critic} />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <CritiqueBook
                      book={getBookWithAllImages(
                        critique.coverImage,
                        critique.book
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {critique.containsSpoilers && (
                        <Grid item xs={12}>
                          <Grid container spacing={1} alignItems="center">
                            <Grid item>
                              <Grid container alignItems="center">
                                <WarningIcon color="secondary" />
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid container alignItems="center">
                                <Typography variant="h6" color="secondary">
                                  This critique contains spoilers!
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary">
                          <b>{getReadingTime(critique.content)}</b> &bull;{" "}
                          Published at{" "}
                          {dayjs(critique.createdAt).format(
                            "MMMM D, YYYY [at] hh:mm a"
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          className={classes.critiqueContent}
                        >
                          {critique.content}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      alignItems="flex-start"
                      spacing={2}
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item>
                                <Button
                                  onClick={handleToggleVote}
                                  disabled={votingCritique || isOwnCritique}
                                  variant={
                                    critique.hasVoted ? "contained" : "text"
                                  }
                                  color="primary"
                                  disableElevation
                                  startIcon={<ThumbUpIcon />}
                                  className={classes.voteIcon}
                                >
                                  This critique is helpful
                                </Button>
                              </Grid>
                              <Grid item>
                                <FacebookShareButton
                                  className={classes.facebookShareButton}
                                  url={window.location.href}
                                  hashtag="#bibliocircle"
                                >
                                  <Button
                                    variant="text"
                                    color="primary"
                                    fullWidth
                                    disableElevation
                                    disableRipple
                                    startIcon={<FacebookIcon />}
                                  >
                                    Share on Facebook
                                  </Button>
                                </FacebookShareButton>
                              </Grid>
                            </Grid>
                          </Grid>
                          {!!critique.votesCount && (
                            <Grid item xs={12}>
                              <Link
                                component="button"
                                variant="body2"
                                color="primary"
                                onClick={toggleVotersList}
                              >
                                {critique.votesCount} Reader
                                {critique.votesCount > 1 ? "s" : ""} voted this
                                critique as helpful!
                              </Link>
                              <Dialog
                                open={votersListOpen}
                                disableBackdropClick={true}
                                maxWidth="sm"
                                disableScrollLock={true}
                                fullScreen={isSmallScreen}
                                fullWidth
                              >
                                <DialogContent id="votersListContainer">
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <UsersSearchController
                                        scrollableTargetId="votersListContainer"
                                        searchArgs={{
                                          votedCritiqueId: critiqueId,
                                        }}
                                        xs={12}
                                      />
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={toggleVotersList}>
                                    close
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>

                      {!isOwnCritique && (
                        <Grid item>
                          <Reporting
                            type="CRITIQUE"
                            resourceId={critique.id}
                            open={reporterOpen}
                            onClose={toggleReporter}
                          />
                          <Tooltip
                            title="Is the critique content inappropriate? Let us know!"
                            aria-label="Is the critique content inappropriate? Let us know!"
                          >
                            <Button
                              color="secondary"
                              fullWidth
                              startIcon={<ReportIcon />}
                              onClick={toggleReporter}
                            >
                              Report Critique
                            </Button>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
