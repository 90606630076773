import { Trans } from "@lingui/macro";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { lightGreen } from "@material-ui/core/colors";
import { Visibility } from "@material-ui/icons";
import dayjs from "dayjs";
import React from "react";
import Hyperlink from "../Common/Hyperlink";

const useStyles = makeStyles((theme) => {
  const pointBox = {
    borderRadius: 50,
    height: "50px",
    width: "50px",
  };
  const pointText = {
    fontWeight: "bolder",
    fontSize: "16px",
  };
  return {
    card: {
      backgroundColor: theme.palette.grey[100],
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
    },
    positivePointBox: {
      ...pointBox,
      background: lightGreen[200],
    },
    positivePointText: {
      ...pointText,
      color: lightGreen[900],
    },
    negativePointBox: {
      ...pointBox,
      background: theme.palette.secondary.lighter,
    },
    negativePointText: {
      ...pointText,
      color: theme.palette.secondary.main,
    },
    timestamp: {
      color: theme.palette.grey[500],
    },
    viewButton: {
      float: "right",
    },
    pointDescription: {
      fontSize: "14px",
      fontWeight: "bold",
    },
  };
});

const userPointCriteriaMap = {
  SIGN_UP: { text: <Trans>Signed up for bibliocircle</Trans> },
  LISTING_COLLECTION_CREATED: {
    text: <Trans>Created a collection</Trans>,
    resourceType: "collection",
  },
  LISTING_COLLECTION_DELETED: {
    text: <Trans>Deleted a collection</Trans>,
    resourceType: "collection",
  },
  LISTING_CREATED_PUBLIC: {
    text: <Trans>Created a public advertisement</Trans>,
    resourceType: "listing",
  },
  LISTING_DELETED_PUBLIC: {
    text: <Trans>Deleted a public advertisement</Trans>,
    resourceType: "listing",
  },
  LISTING_CREATED_NON_PUBLIC: {
    text: <Trans>Created a private advertisement</Trans>,
    resourceType: "listing",
  },
  LISTING_DELETED_NON_PUBLIC: {
    text: <Trans>Deleted a private advertisement</Trans>,
    resourceType: "listing",
  },
  CRITIQUE_CREATED: {
    text: <Trans>Published a critique</Trans>,
    resourceType: "critique",
  },
  CRITIQUE_DELETED: {
    text: <Trans>Deleted a critique</Trans>,
    resourceType: "critique",
  },
  CRITIQUE_RECEIVED_VOTE: {
    text: <Trans>Critique received a vote</Trans>,
    resourceType: "critique",
  },
  CRITIQUE_LOST_VOTE: {
    text: <Trans>Critique lost a vote</Trans>,
    resourceType: "critique",
  },
  VOTED_ON_CRITIQUE: {
    text: <Trans>Voted on a critique</Trans>,
    resourceType: "critique",
  },
  UNDO_VOTE_ON_CRITIQUE: {
    text: <Trans>Removed a vote on a critique</Trans>,
    resourceType: "critique",
  },
  ORDER_COMPLETED: {
    text: <Trans>Completed the order</Trans>,
    resourceType: "order",
  },
  DISCUSSION_CREATED: {
    text: <Trans>Started a discussion</Trans>,
    resourceType: "bookchat",
  },
  DISCUSSION_DELETED: {
    text: <Trans>Deleted a discussion</Trans>,
    resourceType: "bookchat",
  },
  DISCUSSION_RECEIVED_VOTE: {
    text: <Trans>Received a vote for your discussion</Trans>,
    resourceType: "bookchat",
  },
  DISCUSSION_LOST_VOTE: {
    text: <Trans>Lost a vote for your discussion</Trans>,
    resourceType: "bookchat",
  },
  VOTED_ON_DISCUSSION: {
    text: <Trans>Voted on a discussion</Trans>,
    resourceType: "bookchat",
  },
  UNDO_VOTE_ON_DISCUSSION: {
    text: <Trans>Removed a vote on a discussion</Trans>,
    resourceType: "bookchat",
  },
  STORY_CREATED: {
    text: <Trans>Created a story</Trans>,
    resourceType: "story",
  },
  STORY_CHAPTER_CREATED: {
    text: <Trans>Created a story chapter</Trans>,
    resourceType: "chapter",
  },
  POSITIVE_STORY_REVIEW_CREATED: {
    text: <Trans>Wrote a positive story review</Trans>,
    resourceType: "story",
  },
  NEGATIVE_STORY_REVIEW_CREATED: {
    text: <Trans>Wrote a negative story review</Trans>,
    resourceType: "story",
  },
  STORY_DELETED: { text: <Trans>Deleted a story</Trans> },
  STORY_CHAPTER_DELETED: {
    text: <Trans>Deleted a chapter</Trans>,
  },
  POSITIVE_STORY_REVIEW_DELETED: {
    text: <Trans>Deleted a positive story review</Trans>,
    resourceType: "story",
  },
  NEGATIVE_STORY_REVIEW_DELETED: {
    text: <Trans>Deleted a negative story review</Trans>,
    resourceType: "story",
  },
  STORY_RECEIVED_FIVE_STAR_REVIEW: {
    text: <Trans>Received a five star review for your story</Trans>,
    resourceType: "story",
  },
  STORY_RECEIVED_FOUR_STAR_REVIEW: {
    text: <Trans>Received a four star review for your story</Trans>,
    resourceType: "story",
  },
  STORY_LOST_FIVE_STAR_REVIEW: {
    text: <Trans>Lost a five star review for your story</Trans>,
    resourceType: "story",
  },
  STORY_LOST_FOUR_STAR_REVIEW: {
    text: <Trans>Lost a four star review for your story</Trans>,
    resourceType: "story",
  },
  POSITIVE_STORY_CHAPTER_RATING_CREATED: {
    text: <Trans>Rated a story chapter postively</Trans>,
    resourceType: "chapter",
  },
  NEGATIVE_STORY_CHAPTER_RATING_CREATED: {
    text: <Trans>Rated a story chapter negatively</Trans>,
    resourceType: "chapter",
  },
  POSITIVE_STORY_CHAPTER_RATING_DELETED: {
    text: <Trans>Deleted a positive story chapter rating</Trans>,
    resourceType: "chapter",
  },
  NEGATIVE_STORY_CHAPTER_RATING_DELETED: {
    text: <Trans>Deleted a negative story chapter rating</Trans>,
    resourceType: "chapter",
  },
  STORY_CHAPTER_RECEIVED_FIVE_STAR_RATING: {
    text: <Trans>Received a five star rating for your story chapter</Trans>,
    resourceType: "chapter",
  },
  STORY_CHAPTER_RECEIVED_FOUR_STAR_RATING: {
    text: <Trans>Received a four star rating for your story chapter</Trans>,
    resourceType: "chapter",
  },
  STORY_CHAPTER_LOST_FIVE_STAR_RATING: {
    text: <Trans>Lost a five star rating for your story chapter</Trans>,
    resourceType: "chapter",
  },
  STORY_CHAPTER_LOST_FOUR_STAR_RATING: {
    text: <Trans>Lost a four star rating for your story chapter</Trans>,
    resourceType: "chapter",
  },
  BONUS: { text: <Trans>Received bonus points</Trans> },
};

export default function UserPointCard({ userPoint }) {
  const classes = useStyles();
  const positivePoint = userPoint.diff >= 0;
  return (
    <Grid container className={classes.card}>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={3} sm={2}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Grid
                      container
                      className={
                        positivePoint
                          ? classes.positivePointBox
                          : classes.negativePointBox
                      }
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography
                          align="center"
                          variant="body2"
                          className={
                            positivePoint
                              ? classes.positivePointText
                              : classes.negativePointText
                          }
                        >
                          {userPoint.diff > 0
                            ? `+${userPoint.diff}`
                            : `${userPoint.diff}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={9} sm={10}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.pointDescription}
                    >
                      {userPointCriteriaMap[userPoint.criteria].text}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" className={classes.timestamp}>
                      {dayjs(userPoint.createdAt).format(
                        "MMMM D, YYYY [at] hh:mm a"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container justifyContent="flex-end">
              {userPointCriteriaMap[userPoint.criteria].resourceType && (
                <Button
                  component={Hyperlink}
                  to={`/${
                    userPointCriteriaMap[userPoint.criteria].resourceType
                  }/${userPoint.resourceId}`}
                  startIcon={<Visibility />}
                  variant="text"
                  color="primary"
                  size="small"
                  disableElevation
                >
                  <Trans>View</Trans>
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
