import React from "react";
import { useParams } from "react-router-dom";
import ProfileViewer from "./ProfileViewer";
import { useLoggedInUser } from "../../utils/hooks";

export default function UserProfile() {
  const params = useParams();
  const { userId, pageId } = params;
  const loggedInUser = useLoggedInUser();
  const ownProfile = loggedInUser?.id === userId;

  let profileSubPageId = null;
  if (pageId && typeof pageId === "string") {
    profileSubPageId = pageId.toLowerCase();
  }

  return (
    <ProfileViewer
      userId={userId}
      ownProfile={ownProfile}
      pageId={profileSubPageId}
    />
  );
}
