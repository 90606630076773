import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { GET_STORY_CHAPTER } from "../../../consts/queries";
import ErrorPage from "../../Error/ErrorPage";

export default function ChapterRedirector() {
  const history = useHistory();
  const { chapterId } = useParams();
  const { error, data } = useQuery(GET_STORY_CHAPTER, {
    variables: {
      id: chapterId,
    },
  });

  if (error) {
    history.replace(`/stories`);
  }

  if (!data) return null;

  if (data?.storyChapter) {
    const { story } = data?.storyChapter;
    if (!story) {
      history.replace(`/stories`);
      return;
    }
    history.replace(`/story/${story.id}/chapter/${chapterId}`);
    return null;
  }

  return (
    <ErrorPage
      title="Unavailable"
      description="This chapter is either removed, or does not exist.!"
      statusCode={404}
    />
  );
}
