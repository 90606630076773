import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IMAGE_SERVER_URL } from "../../config";
import { useDispatch } from "react-redux";
import { useMutation, useLazyQuery } from "@apollo/client";
import ListingEditor from "./ListingEditor";
import { actionTypes } from "../index.reducer";
import {
  UPDATE_LISTING,
  SAVE_ADVERTISEMENT_PREFERENCES,
} from "../../consts/mutations";
import { GET_LISTING } from "../../consts/queries";
import { dataURLtoBlob, isDataUrl } from "../../utils/fileOperations";
import PageHead from "../Common/PageHead";
import { useLoader, useLoggedInUser, useRequireLogin } from "../../utils/hooks";

export default function UpdateListing() {
  const [updateListing] = useMutation(UPDATE_LISTING);
  const [saveAdvertisementPreferences] = useMutation(
    SAVE_ADVERTISEMENT_PREFERENCES
  );
  const { listingId } = useParams();
  const history = useHistory();
  const loggedInUser = useLoggedInUser();
  const dispatch = useDispatch();
  useRequireLogin();

  const [
    getListing,
    {
      loading: retrievingListing,
      error: retrieveListingError,
      data: listingData,
    },
  ] = useLazyQuery(GET_LISTING);
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(null);

  const goToListing = (listingId) => history.push(`/listing/${listingId}`);
  const dispatchErrorMessage = useCallback(
    (message) =>
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message,
      }),
    [dispatch]
  );
  useLoader(formSubmitInProgress || retrievingListing);

  useEffect(() => {
    getListing({ variables: { id: listingId } });
  }, [listingId, getListing]);

  useEffect(() => {
    if (retrieveListingError) {
      dispatchErrorMessage(
        "Failed to load the listing for editing. Please try again later!"
      );
    }
  }, [dispatchErrorMessage, retrieveListingError]);

  const updateExistingListing = (formData) => {
    return Promise.all(
      formData.books.map((book) => {
        return Promise.all(
          book.images.map((dataUrl) => {
            if (isDataUrl(dataUrl)) {
              const formData = new FormData();
              const imageBlob = dataURLtoBlob(dataUrl);
              if (!imageBlob) return Promise.resolve();
              formData.append("file", imageBlob);
              return fetch(`${IMAGE_SERVER_URL}/upload/lg`, {
                method: "POST",
                body: formData,
                credentials: "include",
              }).then((response) => {
                if (response.status === 201) {
                  return response.json();
                }
                return Promise.reject("failed to upload image");
              });
            } else {
              return { fileName: dataUrl };
            }
          })
        )
          .then((mediaContents) =>
            mediaContents.map(
              (media) => (media.fileName || "").split("/").pop() // Extract the image name from absolute URL
            )
          )
          .then((images) => ({ ...book, images }));
      })
    )
      .then((books) => {
        return updateListing({
          variables: {
            listing: {
              id: listingId,
              title: formData.listingTitle,
              description: formData.listingDescription,
              groupIds: formData.shareTargetGroups,
              originalPrice: +formData.originalPrice,
              price: +formData.price,
              priceModelType: formData.priceModelType,
              public: formData.public,
              addressIds: formData.listingAddressIds,
              contactNumberIds: formData.listingContactNumberIds,
              tags: formData.tags,
              shopId: formData.shopId,
              books,
              notes: formData.notes,
            },
          },
        });
      })
      .then(({ data }) => {
        if (!data?.updateListing?.id) {
          dispatchErrorMessage(
            "Something went wrong while updating the listing. Please try again later!"
          );
        } else {
          if (formData.savePreferences) {
            saveAdvertisementPreferences({
              variables: {
                preferences: {
                  preferredShopId: formData.shopId,
                  preferredContactNumberIds: formData.listingContactNumberIds,
                  preferredAddressIds: formData.listingAddressIds,
                },
              },
            }).catch(() => {});
          }

          goToListing(data.updateListing.id);
        }
      })
      .catch(() => {
        dispatchErrorMessage(
          "Something went wrong while updating the listing. Please try again later!"
        );
      });
  };

  const submitListing = (formData) => {
    setFormSubmitInProgress(true);
    return updateExistingListing(formData).finally(() => {
      setFormSubmitInProgress(false);
    });
  };

  if (!loggedInUser || !listingData?.listing) return null;

  return (
    <>
      <PageHead title="Edit Ad | bibliocircle" />
      <ListingEditor
        listing={listingData.listing}
        onSubmit={submitListing}
        editorTitle="Edit Advertisement"
        submitButtonText="Update Advertisement"
        disabled={formSubmitInProgress}
      />
    </>
  );
}
