import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  makeStyles,
  Radio,
} from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import Banner from "../Common/Banner";
import { GET_GROUPS_AS_SHARING_LOCATIONS } from "../../consts/queries";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  groupVisibility: {
    color: theme.palette.grey[400],
  },
}));

export default function GroupSelector({
  radio,
  isCurrentUserAMember,
  onSelectPublic,
  onAddTargetGroup,
  onRemoveTargetGroup,
  sharePublic,
  targetGroups,
  disabled,
}) {
  const classes = useStyles();
  const CheckboxComponent = radio ? Radio : Checkbox;
  const [
    getGroups,
    { loading: fetchingGroups, error: fetchGroupsError, data: groupData },
  ] = useLazyQuery(GET_GROUPS_AS_SHARING_LOCATIONS, {
    variables: { isCurrentUserAMember },
  });
  const onChangePublicTarget = (event) => onSelectPublic(event.target.checked);
  const onChangeTargetGroup = (groupId) => (event) => {
    if (event.target.checked) {
      onAddTargetGroup(groupId);
    } else {
      onRemoveTargetGroup(groupId);
    }
  };

  useEffect(() => {
    const gqlQueryVariables = { isCurrentUserAMember };
    getGroups({ variables: gqlQueryVariables });
  }, [isCurrentUserAMember, getGroups]);

  if (!groupData) return null;
  const {
    searchGroups: { groups },
  } = groupData;

  const groupSelection = Array.isArray(groups)
    ? groups.map((group) => {
        const groupType = group.visibility === "PUBLIC" ? "Public" : "Private";
        return (
          <Grid item xs={12} key={group.id}>
            <FormControlLabel
              control={
                <CheckboxComponent
                  color="default"
                  onClick={onChangeTargetGroup(group.id)}
                  checked={targetGroups.includes(group.id)}
                  disabled={disabled}
                />
              }
              label={
                <Typography variant="body2">
                  {group.name}{" "}
                  <span className={classes.groupVisibility}>
                    &bull; {groupType}
                  </span>
                </Typography>
              }
            />
          </Grid>
        );
      })
    : null;

  const groupSelectionView = (
    <>
      {fetchingGroups ? (
        <Grid container item xs={12}>
          <Typography variant="body2" color="textSecondary">
            <Trans> Loading your groups...</Trans>
          </Typography>
        </Grid>
      ) : null}
      <Grid container item xs={12}>
        {fetchGroupsError ? (
          <Banner
            type="error"
            message="Unfortunately, we cannot load your groups at this moment. You can
          still share this book public. If you'd still like to share it in a
          group, please try later."
          />
        ) : (
          groupSelection
        )}
      </Grid>
    </>
  );
  return (
    <Grid container>
      <Grid container item xs={12}>
        <FormControlLabel
          control={
            <CheckboxComponent
              color="primary"
              onClick={onChangePublicTarget}
              checked={sharePublic}
              disabled={disabled}
            />
          }
          label={
            <Typography color="primary" variant="body2">
              <Trans>Public (Can be seen by everyone)</Trans>
            </Typography>
          }
        />
      </Grid>
      {groups?.length ? groupSelectionView : null}
    </Grid>
  );
}

GroupSelector.propTypes = {
  isCurrentUserAMember: PropTypes.bool,
  groupScope: PropTypes.string,
  onSelectPublic: PropTypes.func,
  onAddTargetGroup: PropTypes.func,
  onRemoveTargetGroup: PropTypes.func,
  sharePublic: PropTypes.bool,
  radio: PropTypes.bool,
  targetGroups: PropTypes.arrayOf(PropTypes.string),
};

GroupSelector.defaultProps = {
  radio: false,
  sharePublic: true,
  targetGroups: [],
  isCurrentUserAMember: false,
  onSelectPublic: () => {},
  onAddTargetGroup: () => {},
  onRemoveTargetGroup: () => {},
};
