import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { Select } from "@material-ui/core";

export default function BDropDown(props) {
  const borderColor = props.bordered ? grey[400] : "transparent";
  const StyledInput = withStyles((theme) => ({
    root: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      border: "1px solid",
      borderColor: borderColor,
      backgroundColor: theme.palette.background.paper,
      "&:focus": {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
      },
    },
    icon: {
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(1),
    },
  }))(Select);

  return <StyledInput disableUnderline {...props} />;
}
