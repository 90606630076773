import React from "react";
import PropTypes from "prop-types";
import { Typography, Avatar, Grid, makeStyles, Fade } from "@material-ui/core";
import Hyperlink from "./Hyperlink";

const useStyles = makeStyles((theme) => ({
  avatarTextSm: {
    fontSize: "14px",
  },
  avatarTextMd: {
    fontSize: "14px",
  },
  clickableShopAvatar: {
    borderRadius: theme.shape.borderRadius,
    padding: "5px 10px 5px 10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  logoAvatarSm: {
    fontSize: "128px",
  },
  logoAvatarMd: {
    fontSize: "128px",
  },
  hyperlink: {
    width: "100%",
  },
}));

export default function ShopAvatar({ shop, size }) {
  const classes = useStyles();
  const clickable = !!shop.identifier;
  const isSmall = size === "sm";

  const avatarTextClass = isSmall ? classes.avatarTextSm : classes.avatarTextMd;
  const avatarLogoClass = isSmall ? classes.logoAvatarSm : classes.logoAvatarMd;
  const avatarContainerClass = clickable ? classes.clickableShopAvatar : "";
  const avatarContent = (
    <Grid container className={classes.shopDataContainer}>
      <Grid
        container
        alignItems="center"
        spacing={2}
        direction="row"
        className={avatarContainerClass}
      >
        <Grid item>
          {shop.logoImage ? (
            <Fade in>
              <Avatar
                alt={shop.name}
                src={`${shop.logoImage}/xs`}
                className={avatarLogoClass}
              />
            </Fade>
          ) : (
            <Fade in>
              <Avatar
                className={classes.avatarLogoClass}
                src="/shop-placeholder.svg"
              />
            </Fade>
          )}
        </Grid>
        <Grid item className={classes.avatarName}>
          <Typography
            variant="body1"
            className={avatarTextClass}
            color="textPrimary"
          >
            {shop.name}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  return clickable ? (
    <Hyperlink className={classes.hyperlink} to={`/shop/${shop.identifier}`}>
      {avatarContent}
    </Hyperlink>
  ) : (
    avatarContent
  );
}

ShopAvatar.propTypes = {
  shop: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

ShopAvatar.defaultProps = {
  color: "textPrimary",
  size: "md",
};
