import React from "react";
import { Trans } from "@lingui/macro";
import BookRequestIcon from "../Requests/BookRequestIcon";
import CritiqueIcon from "../Critique/CritiqueIcon";
import StoryIcon from "../Stories/StoryIcon";
import BookChatIcon from "../Discussions/BookChatIcon";
import { blue, deepPurple, green, pink, teal } from "@material-ui/core/colors";
import BookIcon from "../Listing/BookIcon";
import ScrollableLinkTabs from "../Common/ScrollableLinkTabs";
import { useLoginPrompt } from "../../utils/hooks";

const ICON_COLOR_DARKNESS = 400;
const ICON_BACKGROUND_DARKNESS = 50;

export default function FeedActionButtons() {
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const links = [
    {
      title: <Trans>Create Advertisement</Trans>,
      path: "/listing/create",
      onClickHandler: createLoginPromptEventHandler({
        redirectPath: "/listing/create",
      }),
      icon: BookIcon,
      iconColor: green[ICON_COLOR_DARKNESS],
      iconBackgroundColor: green[ICON_BACKGROUND_DARKNESS],
      mobileDrawerOnly: true,
    },
    {
      title: <Trans>Write Story</Trans>,
      path: "/story/new",
      onClickHandler: createLoginPromptEventHandler({
        redirectPath: "/story/new",
      }),
      icon: StoryIcon,
      iconColor: blue[ICON_COLOR_DARKNESS],
      iconBackgroundColor: blue[ICON_BACKGROUND_DARKNESS],
    },
    {
      title: <Trans>Discuss Something</Trans>,
      path: "/bookchat/new",
      onClickHandler: createLoginPromptEventHandler({
        redirectPath: "/bookchat/new",
      }),
      icon: BookChatIcon,
      iconColor: deepPurple[ICON_COLOR_DARKNESS],
      iconBackgroundColor: deepPurple[ICON_BACKGROUND_DARKNESS],
    },
    {
      title: <Trans>Write Critique</Trans>,
      path: "/critique/new",
      onClickHandler: createLoginPromptEventHandler({
        redirectPath: "/critique/new",
      }),
      icon: CritiqueIcon,
      iconColor: pink[ICON_COLOR_DARKNESS],
      iconBackgroundColor: pink[ICON_BACKGROUND_DARKNESS],
    },
    {
      title: <Trans>Request Book</Trans>,
      path: "/book-request/create",
      onClickHandler: createLoginPromptEventHandler({
        redirectPath: "/book-request/create",
      }),
      icon: BookRequestIcon,
      iconColor: teal[ICON_COLOR_DARKNESS],
      iconBackgroundColor: teal[ICON_BACKGROUND_DARKNESS],
    },
  ];

  return <ScrollableLinkTabs links={links} />;
}
