import { createTheme, responsiveFontSizes } from "@material-ui/core";

export const bcTheme = responsiveFontSizes(
  createTheme({
    typography: {
      button: {
        textTransform: "capitalize",
      },
    },
    palette: {
      gradient: {
        light:
          "linear-gradient(0deg, rgba(250,250,250,1) 0%, rgba(207,228,255,1) 100%)",
        main: "linear-gradient(0deg, rgba(250,250,250,1) 0%, rgba(60,115,183,1) 100%)",
        navbar:
          "linear-gradient(90deg, rgba(60,115,183,1) 0%, rgba(138,111,80,1) 87%)",
        navbarTest:
          "linear-gradient(90deg, rgba(47,147,53,1) 0%, rgba(138,106,80,1) 87%)",
      },
      pattern: {
        dottedDark: "radial-gradient(#064789 0.5px, #FFFFFF 0.5px)",
        dottedMain: "radial-gradient(#3C73B7 0.5px, #FFFFFF 0.5px)",
        dottedMainLightBackground:
          "radial-gradient(#3C73B7 0.5px, #f0f7ff 0.5px)",
        dottedGreyBackground: "radial-gradient(#616161 0.5px, #fafafa 0.5px)",
      },
      primary: {
        extraLighter: "#f0f7ff",
        lighter: "#e0eeff",
        light: "#cfe4ff",
        main: "#3C73B7",
        dark: "#064789",
        darker: "#203d60",
        contrastText: "#fff",
      },
      secondary: {
        extraLighter: "#ffebee",
        lighter: "#ffcdd2",
        light: "#ef9a9a",
        main: "#bc0000",
        dark: "#6c0000",
        contrastText: "#fff",
      },
    },
  })
);
