import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  makeStyles,
  Container,
  Button,
  InputLabel,
  FormControl,
  withStyles,
  FormControlLabel,
  Radio,
  FormHelperText,
  Switch,
} from "@material-ui/core";
import PageTitle from "../Common/PageTitle";
import { IMAGE_SERVER_URL } from "../../config";
import { useDispatch } from "react-redux";
import { actionTypes } from "../index.reducer";
import { GET_CRITIQUE } from "../../consts/queries";
import { CREATE_CRITIQUE, UPDATE_CRITIQUE } from "../../consts/mutations";
import { dataURLtoBlob, isDataUrl } from "../../utils/fileOperations";
import Required from "../Common/Required";
import PageHead from "../Common/PageHead";
import Banner from "../Common/Banner";
import BootstrapInputBordered from "../Common/BootstrapInputBordered";
import { Rating } from "@material-ui/lab";
import {
  extractFieldError,
  getFormattedError,
  validateObject,
} from "../../utils/schemaValidator";
import critiqueSchema from "./critique.schema";
import TextEditor from "../Common/TextEditor";
import { useLoader, usePrompt, useRequireLogin } from "../../utils/hooks";
import BookManager from "../Listing/BookManager";
import { stripBookMetaProps } from "../../utils/common";
import { t, Trans } from "@lingui/macro";
import { useLazyQuery, useMutation } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  editorSection: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
  },
  title: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  createCritiqueBtnContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  ratingAndRecommendation: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  lightText: {
    color: theme.palette.grey[700],
  },
  rating: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  searchISBNButtonContainer: {
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
  },
}));

const Label = withStyles((theme) => ({
  root: {
    fontSize: "20px",
    color: theme.palette.grey[700],
  },
}))(InputLabel);

const Field = withStyles({
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
})(FormControl);

export default function CritiqueEditor() {
  const [
    getCritique,
    {
      loading: retrievingCritique,
      error: retrieveCritiqueError,
      data: critiqueData,
    },
  ] = useLazyQuery(GET_CRITIQUE);

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { critiqueId } = useParams();
  const isUpdateOperation = !!critiqueId;
  const [draftCritique, setDraftCritique] = useState(false);
  const [critiqueTitle, setCritiqueTitle] = useState(null);
  const [book, setBook] = useState(null);
  const [critiqueContent, setCritiqueContent] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [rating, setRating] = useState(0);
  const [criticRecommends, setCriticRecommends] = useState(true);
  const [containsSpoilers, setContainsSpoilers] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [originalCover, setOriginalCover] = useState(null);
  const [isPageDirty, setPageDirty] = useState(false);

  usePrompt("Are you sure you want to leave the page?", isPageDirty);
  useEffect(() => setPageDirty(true), []);
  useRequireLogin();

  const getValidatedCritique = (critique) => {
    setValidationErrors([]);
    const { validatedObject, errors } = validateObject(
      critique,
      critiqueSchema
    );

    if (errors.length) {
      setValidationErrors(errors);
      return false;
    } else {
      return validatedObject;
    }
  };

  const getFieldError = (fieldName) =>
    extractFieldError(validationErrors, fieldName);

  const dispatchErrorMessage = useCallback(
    (message) =>
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message,
      }),
    [dispatch]
  );

  const [createCritique, { data: createCritiqueResponse }] =
    useMutation(CREATE_CRITIQUE);
  if (createCritiqueResponse?.createCritique?.id) {
    history.push(`/critique/${createCritiqueResponse.createCritique.id}`);
  }

  const [updateCritique, { data: updateCritiqueResponse }] =
    useMutation(UPDATE_CRITIQUE);
  if (updateCritiqueResponse?.updateCritique?.id) {
    history.push(`/critique/${updateCritiqueResponse.updateCritique.id}`);
  }

  useEffect(() => {
    if (retrieveCritiqueError) {
      dispatchErrorMessage(
        "Something went wrong while fetching the critique. Please try again later!"
      );
    }
  }, [dispatchErrorMessage, retrieveCritiqueError]);

  useLoader(formSubmitting || retrievingCritique);
  useEffect(() => {
    if (isUpdateOperation) {
      getCritique({ variables: { id: critiqueId } });
    }
  }, [isUpdateOperation, critiqueId, getCritique]);

  useEffect(() => {
    if (critiqueData?.critique) {
      const { critique } = critiqueData;
      setCritiqueTitle(critique.title);
      setCritiqueContent(critique.content);
      setRating(critique.rating);
      setCriticRecommends(critique.criticRecommends);
      setBook(critique.book);
      setCoverImage(critique.coverImage);
      setOriginalCover(critique.coverImage);
      setDraftCritique(critique.published !== true);
      setContainsSpoilers(critique.containsSpoilers);
    }
  }, [critiqueData]);

  const handleSetCritiqueDraft = (isDraft) => () => setDraftCritique(isDraft);
  const handleChangeTitle = (event) => {
    setCritiqueTitle(event.target.value);
  };
  const handleChangeDescription = (event) => {
    setCritiqueContent(event.target.value);
  };
  const handleChangeBook = useCallback((books) => setBook(books[0]), []);

  const handleChangeRating = (event, newValue) => {
    if (!newValue) return; // ignore setting it to 0
    setRating(newValue);
  };
  const handleChangeCriticRecommends = (event) =>
    setCriticRecommends(event.target.checked);
  const handleChangeContainsSpoilers = (event) =>
    setContainsSpoilers(event.target.checked);
  const handleSubmitForm = () => {
    const rawCritiqueData = {
      title: critiqueTitle,
      content: critiqueContent,
      rating,
      criticRecommends,
      coverImage,
      book: !!book ? stripBookMetaProps(book) : null,
      published: !draftCritique,
      containsSpoilers: !!containsSpoilers,
    };

    const validatedCritique = getValidatedCritique(rawCritiqueData);
    if (!validatedCritique) {
      dispatchErrorMessage(
        "Some of the fields contain invalid values. Please check again!"
      );
      return;
    }

    setFormSubmitting(true);
    setPageDirty(false);

    let coverImageUrl = null;
    if (coverImage) {
      if (coverImage !== originalCover) {
        // Image was updated by the user
        coverImageUrl = coverImage;
      } else {
        // Image was not updated by the user, hence use the existing file name
        coverImageUrl = coverImage.split("/").pop();
      }
    }

    Promise.all(
      [coverImageUrl, ...validatedCritique.book.images].map((dataUrl) => {
        if (isDataUrl(dataUrl)) {
          const formData = new FormData();
          const imageBlob = dataURLtoBlob(dataUrl);
          if (!imageBlob) return Promise.resolve();
          formData.append("file", imageBlob);
          return fetch(`${IMAGE_SERVER_URL}/upload/xl`, {
            method: "POST",
            body: formData,
            credentials: "include",
          }).then((response) => {
            if (response.status === 201) {
              return response.json();
            }
            return Promise.reject("failed to upload image");
          });
        } else {
          return { fileName: dataUrl };
        }
      })
    )
      .then((mediaContents) =>
        mediaContents.map(
          (media) => (media.fileName || "").split("/").pop() // Extract the image name from absolute URL
        )
      )
      .then(([coverImage, ...bookImages]) => {
        if (isUpdateOperation) {
          const updatedCritiqueData = {
            id: critiqueId,
            ...validatedCritique,
            book: { ...validatedCritique.book, images: bookImages },
            coverImage,
          };

          return updateCritique({
            variables: {
              critique: updatedCritiqueData,
            },
          }).catch(() => {
            dispatchErrorMessage(
              "Something went wrong while updating the critique. Please try again later"
            );
          });
        } else {
          return createCritique({
            variables: {
              critique: {
                ...validatedCritique,
                book: { ...validatedCritique.book, images: bookImages },
                coverImage,
              },
            },
          }).catch(() => {
            dispatchErrorMessage(
              "Something went wrong while creating the critique. Please try again later"
            );
          });
        }
      })
      .finally(() => {
        setFormSubmitting(false);
      });
  };

  const editorTitle = critiqueId ? t`Edit Critique` : t`Create Critique`;

  const getBookManager = () => {
    // Book manager doesn't re-render based on the 'books'. Therefore, books property
    // has to be passed when books are available.
    const bookManagerProps = {
      newOnly: true,
      disabled: retrievingCritique || formSubmitting,
      onUpdateBooks: handleChangeBook,
      requireISBN: true,
      singleton: true,
    };
    if (isUpdateOperation) {
      if (critiqueData?.critique) {
        return (
          <BookManager
            books={
              critiqueData?.critique?.book ? [critiqueData?.critique?.book] : []
            }
            {...bookManagerProps}
          />
        );
      }
    } else {
      return <BookManager books={book ? [book] : []} {...bookManagerProps} />;
    }
  };

  return (
    <Container maxWidth="xl">
      {isUpdateOperation ? (
        <PageHead title={t`Edit Critique - ${critiqueTitle} | bibliocircle`} />
      ) : (
        <PageHead title={t`Create Critique | bibliocircle`} />
      )}
      <Grid container justifyContent="center">
        <Grid item xs={12} className={classes.title}>
          <Grid container spacing={2} alignItems="center" direction="column">
            <Grid item>
              <PageTitle>{editorTitle}</PageTitle>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="center" spacing={3}>
          <Grid container item xs={12} sm={10} md={10} lg={8}>
            <Grid container>
              <Grid item xs={12} className={classes.editorSection}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PageTitle variant="h5">
                      <Trans>Book Details</Trans>
                    </PageTitle>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="textSecondary" variant="body2">
                      <Required>
                        <Trans>
                          Provide details about the book you are critiquing
                        </Trans>
                      </Required>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        {getBookManager()}
                      </Grid>
                      <Grid item xs={12}>
                        <FormHelperText error hidden={!getFieldError("book")}>
                          <Trans>
                            Please provide all required details of the book.
                          </Trans>
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.editorSection}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PageTitle variant="h5">
                      <Trans>Your Critique</Trans>
                    </PageTitle>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="textSecondary" variant="body2">
                      <Trans>Provide details about your critique</Trans>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field className={classes.spannedInput}>
                      <Label shrink color="primary" htmlFor="bootstrap-input">
                        <Required>
                          <Trans>Critique Title</Trans>
                        </Required>
                      </Label>
                      <BootstrapInputBordered
                        error={getFieldError("title")}
                        onChange={handleChangeTitle}
                        value={critiqueTitle}
                        disabled={formSubmitting}
                      />
                      <FormHelperText error hidden={!getFieldError("title")}>
                        {getFormattedError(getFieldError("title"))}
                      </FormHelperText>
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container className={classes.ratingAndRecommendation}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid container className={classes.rating}>
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Grid item>
                                    <Typography
                                      variant="body1"
                                      className={classes.lightText}
                                    >
                                      <Required>
                                        <Trans>
                                          How would you rate this book?
                                        </Trans>
                                      </Required>
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Grid container alignItems="center">
                                      <Rating
                                        value={rating}
                                        onChange={handleChangeRating}
                                        disabled={formSubmitting}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <FormHelperText
                                  error
                                  hidden={!getFieldError("rating")}
                                >
                                  {getFormattedError(getFieldError("rating"))}
                                </FormHelperText>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography
                                  variant="body1"
                                  className={classes.lightText}
                                >
                                  <Required>
                                    <Trans>
                                      Would you recommend this book to other
                                      readers?
                                    </Trans>
                                  </Required>
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Grid container alignItems="center">
                                  <Grid item>
                                    <Typography
                                      variant="body1"
                                      align="right"
                                      className={classes.lightText}
                                    >
                                      <Trans>No</Trans>
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Switch
                                      checked={criticRecommends}
                                      onChange={handleChangeCriticRecommends}
                                      color="primary"
                                      disabled={formSubmitting}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      variant="body1"
                                      align="left"
                                      className={classes.lightText}
                                    >
                                      <Trans>Yes</Trans>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography
                                  variant="body1"
                                  className={classes.lightText}
                                >
                                  <Required>
                                    <Trans>
                                      Does this critique contain spoilers?
                                    </Trans>
                                  </Required>
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Grid container alignItems="center">
                                  <Grid item>
                                    <Typography
                                      variant="body1"
                                      align="right"
                                      className={classes.lightText}
                                    >
                                      <Trans>No</Trans>
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Switch
                                      checked={containsSpoilers}
                                      onChange={handleChangeContainsSpoilers}
                                      color="primary"
                                      disabled={formSubmitting}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      variant="body1"
                                      align="left"
                                      className={classes.lightText}
                                    >
                                      <Trans>Yes</Trans>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Banner
                          type="dark"
                          title={t`Tips for a great critique!`}
                          message={
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography variant="body2">
                                  <Trans>
                                    We always try to make bibliocircle a lovely
                                    place for all our book lovers. Here are some
                                    tips you can follow to help us by making
                                    your critique amazing!
                                  </Trans>
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <ul>
                                  <Trans>
                                    <li style={{ marginBottom: "12px" }}>
                                      <b>Be Reasonable</b> - Criticism is good.
                                      But please be reasonable, and explain
                                      clearly why you are criticising. Critiques
                                      are not just meant for criticising.
                                      Whenever possible, appreciate the author
                                      and give them credit if you think they did
                                      a great job.
                                    </li>
                                  </Trans>
                                  <Trans>
                                    <li style={{ marginBottom: "12px" }}>
                                      <b>Be Professional</b> - You can write
                                      critiques in any language. But please use
                                      your words wisely, and don't use abusive
                                      words or an abusive tone. bibliocircle
                                      critiques are visible to all the users of
                                      all ages. Any critique that is reported as
                                      abusive or inappropriate for general
                                      audience will be removed.
                                    </li>
                                  </Trans>
                                  <Trans>
                                    <li style={{ marginBottom: "12px" }}>
                                      <b>Be Helpful</b> - Critiques are
                                      enormously helpful for other readers. More
                                      details you include, more helpful it will
                                      be. And you will get more votes too.
                                    </li>
                                  </Trans>
                                </ul>
                              </Grid>
                            </Grid>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field className={classes.spannedInput}>
                          <TextEditor
                            rowsMin={30}
                            placeholder={t`Write your critique here...`}
                            error={getFieldError("content")}
                            value={critiqueContent}
                            onChange={handleChangeDescription}
                            disabled={formSubmitting}
                          />
                          <FormHelperText
                            error
                            hidden={!getFieldError("content")}
                          >
                            {getFormattedError(getFieldError("content"))}
                          </FormHelperText>
                        </Field>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.editorSection}>
                <Grid container item xs={12} justifyContent="flex-start">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <PageTitle variant="h5">
                        <Trans>Publishing</Trans>
                      </PageTitle>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={!draftCritique}
                              color="primary"
                              onClick={handleSetCritiqueDraft(false)}
                              disabled={formSubmitting}
                            />
                          }
                          label={
                            <Typography variant="body2">
                              <Trans>
                                This critique is ready to be published
                              </Trans>
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={draftCritique}
                              color="primary"
                              onClick={handleSetCritiqueDraft(true)}
                              disabled={formSubmitting}
                            />
                          }
                          label={
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography variant="body2">
                                  <Trans>
                                    I'm still working on this. I'll publish
                                    later.
                                  </Trans>
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              className={classes.createCritiqueBtnContainer}
            >
              <Grid item>
                <Button
                  disableElevation
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={formSubmitting}
                  onClick={handleSubmitForm}
                >
                  {critiqueId ? t`Update Critique` : t`Create Critique`}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
