import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Tooltip, Typography } from "@material-ui/core";
import Hyperlink from "../../Common/Hyperlink";
import ImageFadeIn from "../../Common/ImageFadeIn";
import { Rating } from "@material-ui/lab";
import { truncateText } from "../../../utils/common";
import { green } from "@material-ui/core/colors";
import {
  Explicit as MatureIcon,
  Category as CategoryIcon,
  SupervisorAccount as AudienceIcon,
} from "@material-ui/icons";
import { t, Trans } from "@lingui/macro";
import { storyAudienceTypes, STORY_GENRES } from "../../consts";

const useStyles = makeStyles((theme) => ({
  coverImageContainer: {
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
    },
  },
  coverImageContent: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 200,
    },
  },
  coverImage: {
    maxHeight: 200,
    maxWidth: "100%",
    objectFit: "contain",
    borderRadius: theme.shape.borderRadius,
    boxShadow: `4px 4px ${theme.palette.grey[300]}`,
  },
  card: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  subtitle: {
    fontWeight: "bold",
    color: theme.palette.grey[700],
    marginBottom: theme.spacing(0.5),
  },
  metadata: {
    fontSize: 12,
    marginBottom: theme.spacing(0.5),
  },
  reviewCount: { fontSize: 12 },
  reviewsContainer: {
    marginBottom: theme.spacing(1),
  },
  abstract: {
    fontSize: 14,
  },
  completeBadge: {
    fontWeight: "bold",
    color: green[600],
  },
  matureTitleIcon: {
    fontSize: 14,
    marginLeft: theme.spacing(0.5),
  },
  storyMetadataIcon: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(0.5),
  },
  storyMetadata: {
    marginTop: "1px",
  },
}));

export default function MediumStoryCard({ story }) {
  const classes = useStyles();
  const genre = STORY_GENRES[story.genre].text;
  const audienceCategory = storyAudienceTypes.find(
    (a) => a.value === story.audienceCategory
  )?.label;
  return (
    <Hyperlink to={`/story/${story.id}`}>
      <Grid container className={classes.card}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} className={classes.coverImageContent}>
              <Grid
                container
                className={classes.coverImageContainer}
                justifyContent="center"
              >
                <ImageFadeIn
                  alt={story.title}
                  src={`${story.coverImage}/sm`}
                  className={classes.coverImage}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      className={classes.title}
                    >
                      {story.title}
                    </Typography>
                    {story.matureContent && (
                      <MatureIcon
                        as
                        MatureIcon
                        color="secondary"
                        className={classes.matureTitleIcon}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    className={classes.subtitle}
                  >
                    {story.subtitle}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    className={classes.metadata}
                  >
                    <Trans>{story.chaptersCount} Chapters &bull;</Trans>{" "}
                    {story.isComplete ? (
                      <span className={classes.completeBadge}>
                        <Trans>Complete</Trans>
                      </span>
                    ) : (
                      <Trans>Ongoing</Trans>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    className={classes.reviewsContainer}
                  >
                    <Grid item>
                      <Grid container alignItems="center">
                        <Rating
                          readOnly
                          size="small"
                          value={story.averageRating}
                          precision={0.1}
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center">
                        <Typography
                          variant="body2"
                          color="textPrimary"
                          className={classes.reviewCount}
                        >
                          <Trans>
                            {story.reviewsCount} Rating
                            {story.reviewsCount === 1 ? "" : "s"}
                          </Trans>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.abstract}
                    >
                      {truncateText(story.abstract, 100)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} className={classes.storyMetadata}>
                    <Grid item>
                      <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <CategoryIcon className={classes.storyMetadataIcon} />
                        <Typography color="textSecondary" variant="caption">
                          {genre}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Tooltip title={t`Target audience of the story`}>
                        <Grid
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <AudienceIcon className={classes.storyMetadataIcon} />
                          <Typography color="textSecondary" variant="caption">
                            {audienceCategory}
                          </Typography>
                        </Grid>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Hyperlink>
  );
}

MediumStoryCard.propTypes = {
  story: PropTypes.object.isRequired,
};
