import React from "react";
import PropTypes from "prop-types";
import { Typography, Avatar, Grid, makeStyles, Fade } from "@material-ui/core";
import Hyperlink from "./Hyperlink";

const useStyles = makeStyles((theme) => ({
  avatarTextSm: {
    fontSize: "14px",
  },
  avatarTextMd: {
    fontSize: "14px",
  },
  clickableGroupAvatar: {
    borderRadius: theme.shape.borderRadius,
    padding: "5px 10px 5px 10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  logoAvatarSm: {
    fontSize: "128px",
  },
  logoAvatarMd: {
    fontSize: "128px",
  },
}));

export default function GroupAvatar({ group, size }) {
  const classes = useStyles();
  const clickable = !!group.id;
  const isSmall = size === "sm";

  const avatarTextClass = isSmall ? classes.avatarTextSm : classes.avatarTextMd;
  const avatarContainerClass = clickable ? classes.clickableGroupAvatar : "";
  const avatarContent = (
    <Grid className={classes.groupDataContainer}>
      <Grid
        container
        alignItems="center"
        spacing={2}
        direction="row"
        className={avatarContainerClass}
      >
        <Grid item>
          {group.logoImage ? (
            <Fade in>
              <Avatar
                alt={group.name}
                src={`${group.logoImage}/xs`}
                className={classes.avatarLogoClass}
              />
            </Fade>
          ) : (
            <Fade in>
              <Avatar
                src="/group-placeholder.svg"
                className={classes.avatarLogoClass}
              />
            </Fade>
          )}
        </Grid>
        <Grid item xs={8} className={classes.avatarName}>
          <Typography
            variant="body1"
            className={avatarTextClass}
            color="textPrimary"
          >
            {group.name}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  return clickable ? (
    <Hyperlink to={`/group/${group.id}`}>{avatarContent}</Hyperlink>
  ) : (
    avatarContent
  );
}

GroupAvatar.propTypes = {
  group: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

GroupAvatar.defaultProps = {
  color: "textPrimary",
  size: "md",
};
