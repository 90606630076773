import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { useCopyToClipboard } from "react-use";
import { CREATE_GROUP_INVITATION } from "../../consts/mutations";
import { actionTypes } from "../index.reducer";
import BootstrapInputBordered from "../Common/BootstrapInputBordered";
import CopyToClipboardIcon from "../Common/CopyToClipboardIcon";
import Banner from "../Common/Banner";
import Hyperlink from "../Common/Hyperlink";

export default function CreateInvitationForm({ groupId, open, onClose }) {
  const dispatch = useDispatch();
  const linkTextBox = useRef();
  const [createInvitationError, setCreateInvitationError] = useState(null);
  const [createGroupInvitation, { data }] = useMutation(
    CREATE_GROUP_INVITATION
  );
  useEffect(() => {
    if (open) {
      createGroupInvitation({
        variables: { groupId },
      }).catch((err) => {
        setCreateInvitationError(
          "Unable to create group invitations at this time. Please try again later!"
        );
      });
    }
  }, [createGroupInvitation, groupId, open]);
  const [{ value: copiedLink, error: copyLinkError }, copyToClipboard] =
    useCopyToClipboard();

  const invitationLink = data?.createGroupInvitation?.invitationKey
    ? new URL(
        `/group/${groupId}/invitation/${data.createGroupInvitation.invitationKey}`,
        window.location.origin
      ).toString()
    : null;

  useEffect(() => {
    if (copiedLink) {
      dispatch({
        type: actionTypes.INFO_MESSAGE,
        message: "Group invitation link copied to clipboard!",
        autoHideDuration: 3000,
      });
    }
  }, [copiedLink, dispatch]);

  useEffect(() => {
    if (copyLinkError) {
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message: "Could not copy group invitation link to clipboard!",
        autoHideDuration: 3000,
      });
    }
  }, [copyLinkError, dispatch]);

  const selectText = () => {
    linkTextBox.current.select();
  };

  const copyLinkToClipboard = () => {
    selectText();
    copyToClipboard(invitationLink);
  };

  const invitationLinkContainer = (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <DialogContentText>
          Share this link with your friends!
        </DialogContentText>
      </Grid>
      {!invitationLink && (
        <Grid item xs={12}>
          <Banner
            type="basic"
            message={
              <Typography variant="body2">
                Unable to create an invitation link at this time. Please{" "}
                <Hyperlink
                  to={{
                    pathname: "/login",
                    state: {
                      redirectPath: `${window.location.pathname}${window.location.search}`,
                    },
                  }}
                >
                  Log in
                </Hyperlink>{" "}
                and try again later.
              </Typography>
            }
          />
        </Grid>
      )}
      {invitationLink && (
        <>
          <Grid item xs={12}>
            <BootstrapInputBordered
              disabled={!invitationLink}
              inputProps={{ ref: linkTextBox }}
              value={invitationLink}
              onClick={selectText}
              readOnly
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Tooltip title="Copy link to clipboard">
              <Button
                disabled={!invitationLink}
                onClick={copyLinkToClipboard}
                variant="text"
                color="primary"
                startIcon={<CopyToClipboardIcon />}
              >
                Copy Link
              </Button>
            </Tooltip>
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      disableScrollLock={true}
    >
      <DialogTitle>{"Create Invitation Link"}</DialogTitle>
      <DialogContent>
        {createInvitationError ? (
          <Grid container>{createInvitationError}</Grid>
        ) : (
          invitationLinkContainer
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateInvitationForm.propTypes = {
  groupId: PropTypes.string.isRequired,
  inviterId: PropTypes.string.isRequired,
};
