import React from "react";
import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import LandingPage from "./LandingPage";
import { useHistory } from "react-router-dom";
import PageHead from "../Application/Common/PageHead";

const useStyles = makeStyles((theme) => ({
  inputField: {
    backgroundColor: "white",
    width: "100%",
    marginBottom: "10px",
  },
  contentHeading: {
    fontSize: "56px",
    marginBottom: "50px",
  },
  plainLink: {
    color: "white",
    textDecoration: "none",
  },
  actionButtonContainer: {
    margin: theme.spacing(10),
  },
  goBackToLoginButton: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.getContrastText(theme.palette.grey[800]),
  },
}));

function SignUpConfirmation() {
  const classes = useStyles();
  const history = useHistory();

  const goToLogin = () => history.push("/login", history.location.state);
  return (
    <LandingPage coverImage="/mailbox.svg">
      <PageHead title="Email Sent | bibliocircle" />
      <Grid container justifyContent="flex-start">
        <Grid item container xs={12} justifyContent="center">
          <h1 className={classes.contentHeading}>Email on the way!</h1>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Typography align="center" variant="h6">
            We sent you an email to verify your email address. Please click the
            confirmation link in the email to verify your email address! Also,
            please check your junk/spam folder as well in case if you couldn't
            find the email in the inbox.
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          className={classes.actionButtonContainer}
        >
          <Button
            className={classes.goBackToLoginButton}
            variant="contained"
            size="large"
            disableElevation
            disableRipple
            onClick={goToLogin}
          >
            Go Back To Login
          </Button>
        </Grid>
      </Grid>
    </LandingPage>
  );
}

export default SignUpConfirmation;
