import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import GroupCard from "./GroupCard";
import NoResults from "../Common/NoResultsImage";
import Banner from "../Common/Banner";
import { GROUPS_PAGINATION_LIMIT } from "../../config";
import { useQuery } from "@apollo/client";
import { SEARCH_GROUPS } from "../../consts/queries";
import { GroupsLoader } from "../Common/LoadingPlaceholders/GroupsLoader";
import ErrorPage from "../Error/ErrorPage";
import { useLoader } from "../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  noGroupsPlaceholder: {
    padding: theme.spacing(3),
  },
  bookRequestsList: {
    height: "80vh",
    overflow: "auto",
  },
  errorBanner: {
    padding: theme.spacing(3),
  },
  noGroupsPlaceholderText: {
    fontWeight: "bold",
    textAlign: "center",
  },
}));

export default function GroupsSearchController({
  fetchPolicy,
  spacing,
  component,
  fixedResultsCount,
  searchArgs,
  noResultsMessage,
  xs,
  md,
  lg,
  xl,
}) {
  const classes = useStyles();
  const GroupCardComponent = component || GroupCard;
  const fetchOptions = {
    variables: {
      ...searchArgs,
      pagination: {
        limit: fixedResultsCount || GROUPS_PAGINATION_LIMIT,
        offset: 0,
      },
    },
  };

  if (fetchPolicy) fetchOptions.fetchPolicy = fetchPolicy;
  const { loading, error, data, fetchMore } = useQuery(
    SEARCH_GROUPS,
    fetchOptions
  );
  useLoader(loading);

  const loadingPlaceholder = <GroupsLoader xs={xs} md={md} lg={lg} xl={xl} />;

  if (error) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the groups due to a problem. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  if (loading && !data) {
    return (
      <Grid container justifyContent="center">
        {loadingPlaceholder}
      </Grid>
    );
  }

  const {
    searchGroups: { groups, pagination },
  } = data;

  const loadMoreGroups = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: GROUPS_PAGINATION_LIMIT,
          offset: groups.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          searchGroups: {
            __typename: prev.searchGroups.__typename,
            groups: [
              ...prev.searchGroups.groups,
              ...fetchMoreResult.searchGroups.groups,
            ],
            pagination: fetchMoreResult.searchGroups.pagination,
          },
        });
      },
    }).catch(() => {});
  };

  if (error)
    return (
      <Grid container justifyContent="center" className={classes.errorBanner}>
        <Grid item xs={12}>
          <Banner
            type="error"
            title="Something went wrong!"
            message="We're sorry! Something went wrong while displaying groups for you!
              Please try again later."
          />
        </Grid>
      </Grid>
    );

  const noGroupsPlaceholder = (
    <Grid
      container
      justifyContent="center"
      className={classes.noGroupsPlaceholder}
    >
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="h5"
          color="primary"
          className={classes.noGroupsPlaceholderText}
        >
          {noResultsMessage ||
            "Sorry, we could not find the group you are looking for!"}
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={6} lg={4}>
          <NoResults />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      {!groups.length ? noGroupsPlaceholder : null}
      <InfiniteScroll
        style={{ overflow: "none !important" }}
        next={!fixedResultsCount && loadMoreGroups}
        hasMore={groups.length < pagination.totalCount}
        loader={
          !fixedResultsCount && <GroupsLoader xs={xs} md={md} lg={lg} xl={xl} />
        }
        dataLength={groups.length}
      >
        <Grid container spacing={spacing}>
          {groups.map((group) => (
            <Grid key={group.id} item xs={xs} md={md} lg={lg} xl={xl}>
              <GroupCardComponent group={group} />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
    </Grid>
  );
}

GroupsSearchController.propTypes = {
  searchArgs: PropTypes.object,
};

GroupsSearchController.defaultProps = {
  searchArgs: {},
};
