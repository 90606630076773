import React from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@material-ui/core";

export default function StoryOpenIcon({ fontSize, style, className }) {
  return (
    <SvgIcon style={style} className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={fontSize}
        height={fontSize}
        viewBox="0 0 24 24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M21 21h-8V6a3 3 0 0 1 3-3h5a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1zm-10 0H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h5a3 3 0 0 1 3 3v15zm0 0h2v2h-2v-2z" />
      </svg>
    </SvgIcon>
  );
}

StoryOpenIcon.propTypes = {
  fontSize: PropTypes.number,
};

StoryOpenIcon.defaultProps = {
  fontSize: 24,
};
