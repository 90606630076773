import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  makeStyles,
  Avatar,
  Button,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
  withStyles,
  Tooltip,
  Paper,
  Fade,
  Container,
} from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import { Route, Switch, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actionTypes } from "../index.reducer";
import { GET_USER_PROFILE } from "../../consts/queries";
import Hyperlink from "../Common/Hyperlink";
import FollowIcon from "@material-ui/icons/Notifications";
import ChatButton from "../Chat/ChatButton";
import { TOGGLE_FOLLOW_USER } from "../../consts/mutations";
import { GENERIC_ALERT_ERROR_MESSAGE } from "../../config";
import PageTitle from "../Common/PageTitle";
import BootstrapInput from "../Common/BootstrapInput";
import debounce from "lodash.debounce";
import PageHead from "../Common/PageHead";
import { getUserFullName } from "../../utils/common";
import UserLevels from "./UserLevels";
import ErrorPage from "../Error/ErrorPage";
import { useLoader, useLoggedInUser } from "../../utils/hooks";
import { t, Trans } from "@lingui/macro";
import PointsHistoryPage from "./ProfilePages/PointsHistory";
import UserContactDetailsPage from "./ProfilePages/ContactDetails";
import ActiveListingsPage from "./ProfilePages/ActiveListings";
import InactiveListingsPage from "./ProfilePages/InactiveListings";
import FavouriteListingsPage from "./ProfilePages/FavouriteListings";
import UserListingCollectionsPage from "./ProfilePages/UserListingsCollections";
import UserCritiquesPage from "./ProfilePages/UserCritiques";
import UserStoriesPage from "./ProfilePages/UserStories";
import UserUnpublishedStoriesPage from "./ProfilePages/UserUnpublishedStories";
import UserReadingStoriesPage from "./ProfilePages/UserReadingStories";
import UserDiscussionsPage from "./ProfilePages/Discussions";
import UserBookRequestsPage from "./ProfilePages/UserBookRequests";
import UserOutgoingOrdersPage from "./ProfilePages/OutgoingOrders";
import UserIncomingOrdersPage from "./ProfilePages/IncomingOrders";
import UserShopsPage from "./ProfilePages/UserShops";
import UserFavouriteShopsPage from "./ProfilePages/UserFavouriteShops";
import UserFollowedShopsPage from "./ProfilePages/UserFollowedShops";
import UserJoinedGroupsPage from "./ProfilePages/UserJoinedGroups";
import UserFollowedGroupsPage from "./ProfilePages/UserFollowedGroups";
import UserOwnGroupsPage from "./ProfilePages/UserOwnGroups";
import FollowersPage from "./ProfilePages/Followers";
import FolloweesPage from "./ProfilePages/Followees";
import UserIcon from "@material-ui/icons/Person";
import AdsIcon from "@material-ui/icons/MenuBook";
import ShopIcon from "@material-ui/icons/Store";
import GroupIcon from "@material-ui/icons/People";
import PeopleIcon from "@material-ui/icons/Contacts";
import StoryIcon from "../Stories/StoryIcon";
import {
  amber,
  blueGrey,
  brown,
  green,
  purple,
  red,
} from "@material-ui/core/colors";
import ProfileHomePage from "./ProfilePages/ProfileHomePage";

const DesktopProfileTab = withStyles({
  wrapper: {
    alignItems: "flex-start",
    textAlign: "left",
  },
})(Tab);

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    color: theme.palette.grey[700],
    fontWeight: "bold",
    background: "rgba(250, 250, 250, 0.8)",
  },
  profilePicture: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  userName: {
    color: theme.palette.grey[700],
    textAlign: "center",
  },
  description: {
    color: theme.palette.grey[600],
    textAlign: "center",
  },
  followStatus: {
    color: theme.palette.primary.main,
    textAlign: "center",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  tabContainer: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
  profileContentContainer: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  profileDataContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  profileContainer: {
    backgroundColor: theme.palette.background.default,
  },
  tabsButtonContainer: {
    paddingTop: theme.spacing(1),
  },
  profileHeaderContainer: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: "url('/background.jpg')",
  },
  profileActionButtonContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    justifyContent: "center",
  },
  profilePictureContainer: {
    justifyContent: "center",
    marginBottom: theme.spacing(1),
  },
  toggleFollowButton: {
    minWidth: 100,
  },
  sectionStartTitle: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(8),
  },
  sectionStartTitleText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  levels: {
    padding: theme.spacing(2),
    "& *": {
      fontWeight: "bold",
    },
  },
  profileHomePageContainer: {
    paddingTop: theme.spacing(3),
  },
  profileHeader: {
    padding: theme.spacing(2),
  },
}));

const PROFILE_DETAILS_TAB = "PROFILE_DETAILS_TAB";
const ACTIVE_LISTINGS_TAB = "ACTIVE_LISTINGS_TAB";
const INACTIVE_LISTINGS_TAB = "INACTIVE_LISTINGS_TAB";
const LISTING_COLLECTIONS_TAB = "LISTING_COLLECTIONS_TAB";
const FAVOURITE_LISTINGS_TAB = "FAVOURITE_LISTINGS_TAB";
const PUBLISHED_CRITIQUES_TAB = "PUBLISHED_CRITIQUES_TAB";
const UNPUBLISHED_CRITIQUES_TAB = "UNPUBLISHED_CRITIQUES_TAB";
const USER_STORIES_TAB = "USER_STORIES_TAB";
const USER_UNPUBLISHED_STORIES_TAB = "USER_UNPUBLISHED_STORIES_TAB";
const READING_STORIES_TAB = "STORIES_READING_TAB";
const BOOK_REQUESTS_TAB = "BOOK_REQUESTS_TAB";
const USER_SHOPS_TAB = "USER_SHOPS_TAB";
const FAVOURITE_SHOPS_TAB = "FAVOURITE_SHOPS_TAB";
const FOLLOWED_SHOPS_TAB = "FOLLOWED_SHOPS_TAB";
const FOLLOWED_GROUPS_TAB = "FOLLOWED_GROUPS_TAB";
const JOINED_GROUPS_TAB = "JOINED_GROUPS_TAB";
const OWN_GROUPS_TAB = "OWN_GROUPS_TAB";
const FOLLOWERS_TAB = "FOLLOWERS_TAB";
const FOLLOWEES_TAB = "FOLLOWEES_TAB";
const OUTGOING_ORDERS_TAB = "OUTGOING_ORDERS_TAB";
const INCOMING_ORDERS_TAB = "INCOMING_ORDERS_TAB";
const DISCUSSIONS_TAB = "DISCUSSIONS_TAB";
const USER_POINTS_TAB = "USER_POINTS_TAB";

const PROFILE_DETAILS_PAGE_ID = "profile-details";
const ACTIVE_LISTINGS_PAGE_ID = "listings";
const INACTIVE_LISTINGS_PAGE_ID = "inactive-listings";
const LISTING_COLLECTIONS_PAGE_ID = "collections";
const PUBLISHED_CRITIQUES_PAGE_ID = "published-critiques";
const UNPUBLISHED_CRITIQUES_PAGE_ID = "unpublished-critiques";
const USER_STORIES_PAGE_ID = "stories";
const USER_UNPUBLISHED_STORIES_PAGE_ID = "unpublished-stories";
const READING_STORIES_PAGE_ID = "stories-reading";
const FAVOURITE_LISTINGS_PAGE_ID = "favourites-listings";
const BOOK_REQUESTS_PAGE_ID = "book-requests";
const USER_SHOPS_PAGE_ID = "shops";
const OUTGOING_ORDERS_PAGE_ID = "outgoing-orders";
const INCOMING_ORDERS_PAGE_ID = "incoming-orders";
const FAVOURITE_SHOPS_PAGE_ID = "favourite-shops";
const FOLLOWED_SHOPS_PAGE_ID = "followed-shops";
const FOLLOWED_GROUPS_PAGE_ID = "followed-groups";
const JOINED_GROUPS_PAGE_ID = "groups";
const OWN_GROUPS_PAGE_ID = "own-groups";
const FOLLOWERS_PAGE_ID = "followers";
const FOLLOWEES_PAGE_ID = "followees";
const DISCUSSIONS_PAGE_ID = "discussions";
const USER_POINTS_PAGE_ID = "user-points";

const tabPageMap = {
  [PROFILE_DETAILS_PAGE_ID]: PROFILE_DETAILS_TAB,
  [ACTIVE_LISTINGS_PAGE_ID]: ACTIVE_LISTINGS_TAB,
  [INACTIVE_LISTINGS_PAGE_ID]: INACTIVE_LISTINGS_TAB,
  [LISTING_COLLECTIONS_PAGE_ID]: LISTING_COLLECTIONS_TAB,
  [PUBLISHED_CRITIQUES_PAGE_ID]: PUBLISHED_CRITIQUES_TAB,
  [UNPUBLISHED_CRITIQUES_PAGE_ID]: UNPUBLISHED_CRITIQUES_TAB,
  [USER_STORIES_PAGE_ID]: USER_STORIES_TAB,
  [USER_UNPUBLISHED_STORIES_PAGE_ID]: USER_UNPUBLISHED_STORIES_TAB,
  [READING_STORIES_PAGE_ID]: READING_STORIES_TAB,
  [FAVOURITE_LISTINGS_PAGE_ID]: FAVOURITE_LISTINGS_TAB,
  [BOOK_REQUESTS_PAGE_ID]: BOOK_REQUESTS_TAB,
  [USER_SHOPS_PAGE_ID]: USER_SHOPS_TAB,
  [OUTGOING_ORDERS_PAGE_ID]: OUTGOING_ORDERS_TAB,
  [INCOMING_ORDERS_PAGE_ID]: INCOMING_ORDERS_TAB,
  [FAVOURITE_SHOPS_PAGE_ID]: FAVOURITE_SHOPS_TAB,
  [FOLLOWED_SHOPS_PAGE_ID]: FOLLOWED_SHOPS_TAB,
  [FOLLOWED_GROUPS_PAGE_ID]: FOLLOWED_GROUPS_TAB,
  [JOINED_GROUPS_PAGE_ID]: JOINED_GROUPS_TAB,
  [OWN_GROUPS_PAGE_ID]: OWN_GROUPS_TAB,
  [FOLLOWERS_PAGE_ID]: FOLLOWERS_TAB,
  [FOLLOWEES_PAGE_ID]: FOLLOWEES_TAB,
  [DISCUSSIONS_PAGE_ID]: DISCUSSIONS_TAB,
  [USER_POINTS_PAGE_ID]: USER_POINTS_TAB,
};

export default function ProfileViewer({ userId, ownProfile, pageId }) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const loggedInUser = useLoggedInUser();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const TabComponent = isSmallScreen ? Tab : DesktopProfileTab;
  const dispatch = useDispatch();
  const [activeAdsQuery, setActiveAdsQuery] = useState(null);
  const [inactiveAdsQuery, setInactiveAdsQuery] = useState(null);
  const [favouriteAdsQuery, setFavouriteAdsQuery] = useState(null);
  const [collectionsQuery, setCollectionsQuery] = useState(null);
  const [critiquesQuery, setCritiquesQuery] = useState(null);
  const [userStoriesQuery, setUserStoriesQuery] = useState(null);
  const [userUnpublishedStoriesQuery, setUserUnpublishedStoriesQuery] =
    useState(null);
  const [discussionsQuery, setDiscussionsQuery] = useState(null);
  const [bookRequestsQuery, setBookRequestsQuery] = useState(null);
  const [userShopsQuery, setUserShopsQuery] = useState(null);
  const [favouriteShopsQuery, setFavouriteShopsQuery] = useState(null);
  const [followedShopsQuery, setFollowedShopsQuery] = useState(null);
  const [userGroupsQuery, setUserGroupsQuery] = useState(null);
  const [followedGroupsQuery, setFollowedGroupsQuery] = useState(null);
  const [ownGroupsQuery, setOwnGroupsQuery] = useState(null);
  const selectedTabId = tabPageMap[pageId];

  const getTabPageUrl = (pId) => `/profile/${userId}/page/${pId}`;
  const {
    loading,
    error,
    data: userData,
  } = useQuery(GET_USER_PROFILE, {
    variables: { id: userId },
  });
  const [toggleFollowUser, { loading: toggleFollowUserPending }] =
    useMutation(TOGGLE_FOLLOW_USER);
  useLoader(loading);

  if (error) {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <ErrorPage
            title={t`Something's not right!`}
            description={t`We could not fetch the user profile you were looking for. Please try later. Sorry for the inconvenience!`}
            statusCode={500}
          />
        </Grid>
      </Grid>
    );
  }

  if (!userData) return null;

  const { user } = userData;
  if (!user) {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <ErrorPage
            title={t`M.I.A.`}
            description={t`The user profile you were looking for does not exist!`}
            statusCode={404}
          />
        </Grid>
      </Grid>
    );
  }

  const handleToggleFollowUser = (event) => {
    event.preventDefault();
    if (!loggedInUser) {
      const { pathname, search } = history.location;
      return history.push("/login", { redirectPath: `${pathname}${search}` });
    }
    toggleFollowUser({
      variables: {
        targetUserId: user.id,
      },
    }).catch(() => {
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message: GENERIC_ALERT_ERROR_MESSAGE,
      });
    });
  };

  const getSearchBar = ({ placeholder, onChangeSearchQuery }) => {
    const debouncedSearch = debounce(onChangeSearchQuery, 800);
    return (
      <Paper elevation={2}>
        <BootstrapInput
          fullWidth
          placeholder={placeholder}
          bordered
          onChange={(event) => {
            debouncedSearch(event.target.value);
          }}
        />
      </Paper>
    );
  };

  const editProfileButton = (
    <Grid item xs={12}>
      <Grid container className={classes.profileActionButtonContainer}>
        <Grid item>
          <Button
            component={Hyperlink}
            to={`/profile/edit`}
            variant="contained"
            color="primary"
            size="small"
            disableElevation
          >
            <Trans>Edit Profile</Trans>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
  const followButton = (
    <Grid item xs={12}>
      <Grid container justifyContent="center" spacing={1}>
        <Grid item>
          <Grid container className={classes.profileActionButtonContainer}>
            <Tooltip
              title={t`Get notified on any new activity by ${
                user.firstName || user.lastName || t`this user`
              }`}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                disableElevation
                startIcon={<FollowIcon />}
                onClick={handleToggleFollowUser}
                disabled={toggleFollowUserPending}
                className={classes.toggleFollowButton}
              >
                {user.isFollowee ? t`Unfollow` : t`Follow`}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container className={classes.profileActionButtonContainer}>
            <ChatButton recipient={user} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const navigateProfileHome = () => {
    history.push(`/profile/${user.id}`);
  };

  const getProfileDetails = () => (
    <Grid container alignItems="flex-start" className={classes.profileHeader}>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          component={Hyperlink}
          to={`/profile/${user.id}`}
        >
          <Grid item className={classes.sectionTitle}>
            <Grid container alignItems="center" direction={"column"}>
              <Grid item xs={12}>
                <Grid
                  container
                  className={classes.profilePictureContainer}
                  onClick={navigateProfileHome}
                >
                  <Grid item>
                    <Fade in>
                      <Avatar
                        src={user.profilePicture}
                        className={classes.profilePicture}
                      />
                    </Fade>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="h6" className={classes.userName}>
                      {user.firstName} {user.lastName}
                    </Typography>
                  </Grid>
                  {user.description && (
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        className={classes.description}
                      >
                        {user.description}
                      </Typography>
                    </Grid>
                  )}
                  {user.isFollower && (
                    <Grid item xs={12}>
                      <Grid container justifyContent="center">
                        <Typography
                          variant="caption"
                          className={classes.followStatus}
                        >
                          <Trans>Follows you</Trans>
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {ownProfile && editProfileButton}
                  {!ownProfile && followButton}
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} md={8}>
                        <UserLevels levels={user.levels} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const sectionsList = [
    {
      id: "PROFILE",
      title: t`Profile`,
      private: true,
      icon: <UserIcon style={{ color: amber[400] }} />,
    },
    {
      id: "BOOKS",
      title: t`Books and Ads`,
      private: false,
      icon: <AdsIcon style={{ color: green[500] }} />,
    },
    {
      id: "CREATIVE_HUB",
      title: t`Creative Hub`,
      private: false,
      icon: <StoryIcon style={{ color: purple[400] }} />,
    },
    {
      id: "SHOPS",
      title: t`Shops`,
      private: false,
      icon: <ShopIcon style={{ color: brown[400] }} />,
    },
    {
      id: "GROUPS",
      title: t`Groups`,
      private: false,
      icon: <GroupIcon style={{ color: blueGrey[400] }} />,
    },
    {
      id: "PEOPLE",
      title: t`People`,
      private: true,
      icon: <PeopleIcon style={{ color: red[400] }} />,
    },
  ];

  const tabsList = [
    {
      pageId: OUTGOING_ORDERS_PAGE_ID,
      tabId: OUTGOING_ORDERS_TAB,
      label: t`Orders I placed`,
      private: true,
      component: <UserOutgoingOrdersPage />,
      sectionId: "PROFILE",
    },
    {
      pageId: INCOMING_ORDERS_PAGE_ID,
      tabId: INCOMING_ORDERS_TAB,
      label: t`Orders I received`,
      private: true,
      component: <UserIncomingOrdersPage userId={user.id} />,
      sectionId: "PROFILE",
    },
    {
      pageId: USER_POINTS_PAGE_ID,
      tabId: USER_POINTS_TAB,
      label: t`Your Points History`,
      private: true,
      sectionId: "PROFILE",
      component: <PointsHistoryPage userId={user.id} />,
      searchBar: false,
    },
    {
      pageId: PROFILE_DETAILS_PAGE_ID,
      tabId: PROFILE_DETAILS_TAB,
      label: t`Contact Details`,
      private: true,
      sectionId: "PROFILE",
      component: <UserContactDetailsPage />,
      searchBar: false,
    },
    {
      pageId: ACTIVE_LISTINGS_PAGE_ID,
      tabId: ACTIVE_LISTINGS_TAB,
      label: ownProfile
        ? t`My Active Ads`
        : t`${(user.firstName || "User").trim()}'s Ads`,
      private: false,
      component: (
        <ActiveListingsPage userId={user.id} searchQuery={activeAdsQuery} />
      ),
      sectionId: "BOOKS",
      searchBar: true,
      searchPlaceholder: t`Search Active Ads`,
      onChangeSearchQuery: (query) => setActiveAdsQuery(query),
    },
    {
      pageId: INACTIVE_LISTINGS_PAGE_ID,
      tabId: INACTIVE_LISTINGS_TAB,
      label: ownProfile
        ? t`My Inactive Ads`
        : t`${(user.firstName || "User").trim()}'s Inactive Ads`,
      private: false,
      component: (
        <InactiveListingsPage userId={user.id} searchQuery={inactiveAdsQuery} />
      ),
      sectionId: "BOOKS",
      searchBar: true,
      searchPlaceholder: t`Search Inactive Ads`,
      onChangeSearchQuery: (query) => setInactiveAdsQuery(query),
    },
    {
      pageId: FAVOURITE_LISTINGS_PAGE_ID,
      tabId: FAVOURITE_LISTINGS_TAB,
      label: t`Favourite Ads`,
      private: true,
      component: (
        <FavouriteListingsPage
          userId={user.id}
          searchQuery={favouriteAdsQuery}
        />
      ),
      sectionId: "BOOKS",
      searchBar: true,
      searchPlaceholder: t`Search Favourite Ads`,
      onChangeSearchQuery: (query) => setFavouriteAdsQuery(query),
    },
    {
      pageId: LISTING_COLLECTIONS_PAGE_ID,
      tabId: LISTING_COLLECTIONS_TAB,
      label: ownProfile
        ? t`My Collections`
        : t`${(user.firstName || "User").trim()}'s Collections`,
      private: false,
      component: (
        <UserListingCollectionsPage
          userId={user.id}
          searchQuery={collectionsQuery}
        />
      ),
      sectionId: "BOOKS",
      searchBar: true,
      searchPlaceholder: t`Search Collections`,
      onChangeSearchQuery: (query) => setCollectionsQuery(query),
    },
    {
      pageId: PUBLISHED_CRITIQUES_PAGE_ID,
      tabId: PUBLISHED_CRITIQUES_TAB,
      label: ownProfile
        ? t`My Published Critiques`
        : t`${(user.firstName || "User").trim()}'s Critiques`,
      private: false,
      component: (
        <UserCritiquesPage
          userId={user.id}
          published
          searchQuery={critiquesQuery}
        />
      ),
      sectionId: "CREATIVE_HUB",
      searchBar: true,
      searchPlaceholder: t`Search Published Critiques`,
      onChangeSearchQuery: (query) => setCritiquesQuery(query),
    },
    {
      pageId: UNPUBLISHED_CRITIQUES_PAGE_ID,
      tabId: UNPUBLISHED_CRITIQUES_TAB,
      label: t`My Unpublished Critiques`,
      private: true,
      component: (
        <UserCritiquesPage userId={user.id} searchQuery={critiquesQuery} />
      ),
      sectionId: "CREATIVE_HUB",
      searchBar: true,
      searchPlaceholder: t`Search Critiques`,
      onChangeSearchQuery: (query) => setCritiquesQuery(query),
    },
    {
      pageId: USER_STORIES_PAGE_ID,
      tabId: USER_STORIES_TAB,
      label: ownProfile
        ? t`My Published Stories`
        : t`${(user.firstName || "User").trim()}'s Stories`,
      private: false,
      component: (
        <UserStoriesPage userId={user.id} searchQuery={userStoriesQuery} />
      ),
      sectionId: "CREATIVE_HUB",
      searchBar: true,
      searchPlaceholder: t`Search Stories`,
      onChangeSearchQuery: (query) => setUserStoriesQuery(query),
    },
    {
      pageId: USER_UNPUBLISHED_STORIES_PAGE_ID,
      tabId: USER_UNPUBLISHED_STORIES_TAB,
      label: t`My Unpublished Stories`,
      private: true,
      component: (
        <UserUnpublishedStoriesPage
          userId={user.id}
          searchQuery={userUnpublishedStoriesQuery}
        />
      ),
      sectionId: "CREATIVE_HUB",
      searchBar: true,
      searchPlaceholder: t`Search Stories`,
      onChangeSearchQuery: (query) => setUserUnpublishedStoriesQuery(query),
    },
    {
      pageId: READING_STORIES_PAGE_ID,
      tabId: READING_STORIES_TAB,
      label: t`Stories I'm Reading`,
      private: true,
      component: <UserReadingStoriesPage userId={user.id} />,
      searchBar: false,
      searchPlaceholder: t`Search Stories`,
    },
    {
      pageId: DISCUSSIONS_PAGE_ID,
      tabId: DISCUSSIONS_TAB,
      label: ownProfile
        ? t`My Discussions`
        : t`${(user.firstName || "User").trim()}'s Discussions`,
      private: false,
      component: (
        <UserDiscussionsPage userId={user.id} searchQuery={discussionsQuery} />
      ),
      sectionId: "CREATIVE_HUB",
      searchBar: true,
      searchPlaceholder: t`Search Discussions`,
      onChangeSearchQuery: (query) => setDiscussionsQuery(query),
    },
    {
      pageId: BOOK_REQUESTS_PAGE_ID,
      tabId: BOOK_REQUESTS_TAB,
      label: ownProfile
        ? t`My Book Requests`
        : t`${user.firstName}'s Book Requests`,
      private: false,
      component: (
        <UserBookRequestsPage
          userId={user.id}
          searchQuery={bookRequestsQuery}
        />
      ),
      sectionId: "BOOKS",
      searchBar: true,
      searchPlaceholder: t`Search Book Requests`,
      onChangeSearchQuery: (query) => setBookRequestsQuery(query),
    },
    {
      pageId: USER_SHOPS_PAGE_ID,
      tabId: USER_SHOPS_TAB,
      label: ownProfile
        ? t`My Shops`
        : t`${(user.firstName || "User").trim()}'s Shops`,
      private: false,
      component: (
        <UserShopsPage userId={user.id} searchQuery={userShopsQuery} />
      ),
      sectionId: "SHOPS",
      searchBar: true,
      searchPlaceholder: t`Search shops`,
      onChangeSearchQuery: (query) => setUserShopsQuery(query),
    },
    {
      pageId: FAVOURITE_SHOPS_PAGE_ID,
      tabId: FAVOURITE_SHOPS_TAB,
      label: t`Favourite Shops`,
      private: true,
      component: <UserFavouriteShopsPage searchQuery={favouriteShopsQuery} />,
      sectionId: "SHOPS",
      searchBar: true,
      searchPlaceholder: t`Search Favourite Shops`,
      onChangeSearchQuery: (query) => setFavouriteShopsQuery(query),
    },
    {
      pageId: FOLLOWED_SHOPS_PAGE_ID,
      tabId: FOLLOWED_SHOPS_TAB,
      label: t`Followed Shops`,
      private: true,
      component: <UserFollowedShopsPage searchQuery={followedShopsQuery} />,
      sectionId: "SHOPS",
      searchBar: true,
      searchPlaceholder: t`Search Followed Shops`,
      onChangeSearchQuery: (query) => setFollowedShopsQuery(query),
    },
    {
      pageId: JOINED_GROUPS_PAGE_ID,
      tabId: JOINED_GROUPS_TAB,
      label: ownProfile
        ? t`My Groups`
        : t`${(user.firstName || "User").trim()}'s Groups`,
      private: false,
      component: (
        <UserJoinedGroupsPage userId={user.id} searchQuery={userGroupsQuery} />
      ),
      sectionId: "GROUPS",
      searchBar: true,
      searchPlaceholder: t`Search Groups`,
      onChangeSearchQuery: (query) => setUserGroupsQuery(query),
    },
    {
      pageId: FOLLOWED_GROUPS_PAGE_ID,
      tabId: FOLLOWED_GROUPS_TAB,
      label: t`Followed Groups`,
      private: true,
      component: <UserFollowedGroupsPage searchQuery={followedGroupsQuery} />,
      sectionId: "GROUPS",
      searchBar: true,
      searchPlaceholder: t`Search Followed Groups`,
      onChangeSearchQuery: (query) => setFollowedGroupsQuery(query),
    },
    {
      pageId: OWN_GROUPS_PAGE_ID,
      tabId: OWN_GROUPS_TAB,
      label: t`Groups I Created`,
      private: true,
      component: <UserOwnGroupsPage searchQuery={ownGroupsQuery} />,
      sectionId: "GROUPS",
      searchBar: true,
      searchPlaceholder: t`Search Own Groups`,
      onChangeSearchQuery: (query) => setOwnGroupsQuery(query),
    },
    {
      pageId: FOLLOWERS_PAGE_ID,
      tabId: FOLLOWERS_TAB,
      label: t`My Followers`,
      private: true,
      component: <FollowersPage />,
      sectionId: "PEOPLE",
    },
    {
      pageId: FOLLOWEES_PAGE_ID,
      tabId: FOLLOWEES_TAB,
      label: t`People I'm Following`,
      private: true,
      component: <FolloweesPage />,
      sectionId: "PEOPLE",
    },
  ];

  const visibleSectionsList = sectionsList.filter(
    (section) => ownProfile || !section.private
  );
  const visibleTabsList = tabsList.filter(
    (tabData) => ownProfile || !tabData.private
  );

  const profilePageContent = (
    <Grid container item xs={12} className={classes.profileDataContainer}>
      <Grid item xs={12}>
        <Grid
          container
          direction="row-reverse"
          alignContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} lg={2}>
            <Tabs
              value={selectedTabId}
              indicatorColor="primary"
              textColor="primary"
              orientation={!isSmallScreen ? "vertical" : "horizontal"}
              className={classes.tabsButtonContainer}
              variant="scrollable"
              scrollButtons={isSmallScreen ? "on" : "auto"}
              TabIndicatorProps={
                isSmallScreen
                  ? {}
                  : {
                      style: {
                        left: 0,
                      },
                    }
              }
            >
              {visibleSectionsList.map((section) => [
                (ownProfile || !section.private) && (
                  <Grid container className={classes.sectionStartTitle}>
                    <Typography
                      variant="caption"
                      className={classes.sectionStartTitleText}
                    >
                      {section.title}
                    </Typography>
                  </Grid>
                ),
                visibleTabsList.map(
                  (tabData) =>
                    tabData.sectionId === section.id && (
                      <TabComponent
                        component={Hyperlink}
                        to={getTabPageUrl(tabData.pageId)}
                        value={tabData.tabId}
                        label={tabData.label}
                      />
                    )
                ),
              ])}
            </Tabs>
          </Grid>
          <Grid item xs={12} lg={10}>
            <Grid container className={classes.tabContainer}>
              <Switch>
                {visibleTabsList.map((tabData) => (
                  <Route
                    key={tabData.pageId}
                    path={`/profile/:userId/page/${tabData.pageId}`}
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Grid container justifyContent="center">
                          <PageTitle variant="h6" align="center">
                            {tabData.label}
                          </PageTitle>
                        </Grid>
                      </Grid>
                      {tabData.searchBar && (
                        <Grid item xs={12}>
                          <Grid container justifyContent="center">
                            <Grid item xs={12} lg={10}>
                              {getSearchBar({
                                placeholder:
                                  tabData.searchPlaceholder || "Search",
                                onChangeSearchQuery:
                                  tabData.onChangeSearchQuery,
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={12} key={history.location.key}>
                        {tabData.component}
                      </Grid>
                    </Grid>
                  </Route>
                ))}
              </Switch>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const profileHomePage = (
    <Grid container className={classes.profileHomePageContainer}>
      <ProfileHomePage
        userId={user.id}
        sectionsList={visibleSectionsList}
        pagesList={visibleTabsList}
      />
    </Grid>
  );

  return (
    <Grid container item xs={12}>
      <PageHead title={`${getUserFullName(user)} | bibliocircle`} />
      <Grid
        container
        justifyContent="center"
        alignContent="flex-start"
        className={classes.profileContainer}
      >
        <Grid container justifyContent="center">
          <Grid item xs={12} className={classes.profileHeaderContainer}>
            {getProfileDetails()}
          </Grid>
        </Grid>
        <Container className={classes.profileContentContainer} maxWidth="xl">
          <Grid container justifyContent="center">
            <Grid item xs={12} md={11}>
              <Switch>
                <Route path="/profile/:userId/page">{profilePageContent}</Route>
                <Route>{profileHomePage}</Route>
              </Switch>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}

ProfileViewer.propTypes = {
  userId: PropTypes.string,
  ownProfile: PropTypes.bool,
};
