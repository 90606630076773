import {
  withStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

export const BDialogTitle = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.lighter,
  },
}))(DialogTitle);

export const BDialogActions = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.lighter,
    padding: theme.spacing(2),
  },
}))(DialogActions);

export const BDialogContent = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.lighter,
  },
}))(DialogContent);
