import {
  Avatar,
  Box,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import GroupIcon from "@material-ui/icons/Group";
import HelpIcon from "@material-ui/icons/Help";
import PeopleIcon from "@material-ui/icons/Contacts";
import StoreIcon from "@material-ui/icons/Store";
import HomeIcon from "@material-ui/icons/Home";
import CritiqueIcon from "../Critique/CritiqueIcon";
import GiftIcon from "./GiftIcon";
import BookRequestIcon from "../Requests/BookRequestIcon";
import BookChatIcon from "../Discussions/BookChatIcon";
import CollectionsIcon from "../ListingCollections/CollectionsIcon";
import Hyperlink from "./Hyperlink";
import { Trans } from "@lingui/macro";
import { useLocation } from "react-router-dom";
import {
  amber,
  blue,
  blueGrey,
  brown,
  deepPurple,
  green,
  grey,
  lightBlue,
  orange,
  pink,
  red,
  teal,
} from "@material-ui/core/colors";
import StoryIcon from "../Stories/StoryIcon";
import ChatIcon from "../Chat/ChatIcon";
import { useLoggedInUser } from "../../utils/hooks";
import { GET_USER_SHOPS } from "../../consts/queries";
import { truncateText } from "../../utils/common";
import BookIcon from "../Listing/BookIcon";
import { useQuery } from "@apollo/client";

const useStyles = makeStyles((theme) => {
  const quickLinkContent = {
    padding: theme.spacing(1.5),
    color: theme.palette.text.primary,
    fontWeight: "bold",
    transitionDuration: 200,
    "&:hover": {
      backgroundColor: theme.palette.primary.extraLighter,
      transitionDuration: 200,
    },
  };
  return {
    quickLinkContent,
    selectedQuickLinkContent: {
      ...quickLinkContent,
      backgroundColor: theme.palette.primary.lighter,
    },
    linkIcon: {
      marginRight: theme.spacing(2),
    },
    icon: {
      color: theme.palette.grey[800],
      fontSize: 20,
    },
    iconAvatar: {
      backgroundColor: theme.palette.grey[300],
      width: 30,
      height: 30,
    },
    mobileOnly: {
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
  };
});

const ICON_COLOR_DARKNESS = 400;
const ICON_BACKGROUND_DARKNESS = 50;
export const quickLinks = [
  {
    title: <Trans>Home</Trans>,
    onClick: () => {
      window.scrollTo(0, 0);
    },
    path: "/",
    icon: HomeIcon,
    iconColor: green[ICON_COLOR_DARKNESS],
    iconBackgroundColor: green[ICON_BACKGROUND_DARKNESS],
    mobileDrawerOnly: false,
  },
  {
    title: <Trans>All Books</Trans>,
    path: "/listings",
    icon: BookIcon,
    iconColor: lightBlue[ICON_COLOR_DARKNESS],
    iconBackgroundColor: lightBlue[ICON_BACKGROUND_DARKNESS],
    mobileDrawerOnly: false,
  },
  {
    title: <Trans>Free Books</Trans>,
    path: "/listings?minPrice=0&maxPrice=0",
    icon: GiftIcon,
    iconColor: orange[ICON_COLOR_DARKNESS],
    iconBackgroundColor: orange[ICON_BACKGROUND_DARKNESS],
    mobileDrawerOnly: false,
  },
  {
    title: <Trans>Shops</Trans>,
    path: "/shops",
    icon: StoreIcon,
    iconColor: brown[ICON_COLOR_DARKNESS],
    iconBackgroundColor: brown[ICON_BACKGROUND_DARKNESS],
    mobileDrawerOnly: false,
    divideAfter: true,
  },
  {
    title: <Trans>Book Requests</Trans>,
    path: "/book-requests",
    icon: BookRequestIcon,
    iconColor: teal[ICON_COLOR_DARKNESS],
    iconBackgroundColor: teal[ICON_BACKGROUND_DARKNESS],
    mobileDrawerOnly: false,
  },
  {
    title: <Trans>Critiques</Trans>,
    path: "/critiques",
    icon: CritiqueIcon,
    iconColor: pink[ICON_COLOR_DARKNESS],
    iconBackgroundColor: pink[ICON_BACKGROUND_DARKNESS],
    mobileDrawerOnly: false,
  },
  {
    title: <Trans>Stories</Trans>,
    path: "/stories",
    icon: StoryIcon,
    iconColor: blue[ICON_COLOR_DARKNESS],
    iconBackgroundColor: blue[ICON_BACKGROUND_DARKNESS],
    mobileDrawerOnly: false,
  },
  {
    title: <Trans>Discussions</Trans>,
    path: "/bookchats",
    icon: BookChatIcon,
    iconColor: deepPurple[ICON_COLOR_DARKNESS],
    iconBackgroundColor: deepPurple[ICON_BACKGROUND_DARKNESS],
    mobileDrawerOnly: false,
  },
  {
    title: <Trans>Collections</Trans>,
    path: "/collections",
    icon: CollectionsIcon,
    iconColor: amber[ICON_COLOR_DARKNESS],
    iconBackgroundColor: amber[ICON_BACKGROUND_DARKNESS],
    mobileDrawerOnly: false,
  },
  {
    title: <Trans>Groups</Trans>,
    path: "/groups",
    icon: GroupIcon,
    iconColor: blueGrey[ICON_COLOR_DARKNESS],
    iconBackgroundColor: blueGrey[ICON_BACKGROUND_DARKNESS],
    mobileDrawerOnly: false,
  },
  {
    title: <Trans>People</Trans>,
    path: "/people",
    icon: PeopleIcon,
    iconColor: red[ICON_COLOR_DARKNESS],
    iconBackgroundColor: red[ICON_BACKGROUND_DARKNESS],
    mobileDrawerOnly: false,
  },
  {
    title: <Trans>Chat</Trans>,
    path: "/chat",
    icon: ChatIcon,
    iconColor: green[ICON_COLOR_DARKNESS],
    iconBackgroundColor: green[ICON_BACKGROUND_DARKNESS],
    mobileDrawerOnly: false,
  },
];

export default function Sidebar() {
  const classes = useStyles();
  const location = useLocation();
  const loggedInUser = useLoggedInUser();
  const { data: userShopsData } = useQuery(GET_USER_SHOPS, {
    variables: { ownerId: loggedInUser?.id },
    skip: !loggedInUser,
  });

  const isMatchingPath = (testStr) => {
    return `${location.pathname}${location.search}` === testStr;
  };

  const getQuickLink = (linkInfo) => (
    <Grid
      item
      xs={12}
      className={linkInfo.mobileDrawerOnly && classes.mobileOnly}
    >
      <Grid container>
        <Grid item xs={12}>
          <Grid
            {...(typeof linkInfo?.onClick === "function"
              ? { onClick: linkInfo.onClick }
              : {})}
            container
            component={Hyperlink}
            to={linkInfo.path}
            alignItems="center"
            className={
              isMatchingPath(linkInfo.path)
                ? classes.selectedQuickLinkContent
                : classes.quickLinkContent
            }
          >
            <Grid item>
              <Grid container alignItems="center" className={classes.linkIcon}>
                {linkInfo.image ? (
                  <Avatar className={classes.iconAvatar} src={linkInfo.image} />
                ) : (
                  <Avatar
                    className={classes.iconAvatar}
                    style={{
                      backgroundColor: linkInfo.iconBackgroundColor,
                    }}
                  >
                    <linkInfo.icon
                      className={classes.icon}
                      style={{ color: linkInfo.iconColor }}
                    />
                  </Avatar>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                {linkInfo.title}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>{quickLinks.map(getQuickLink)}</Grid>
          </Grid>
          {!!userShopsData?.shopsResult?.shops?.length && (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Box p={2}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Your Shops
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    {userShopsData.shopsResult.shops.map((shop) => (
                      <Grid item xs={12}>
                        <Grid container>
                          {getQuickLink({
                            title: truncateText(shop.name, 20),
                            path: `/shop/${shop.identifier}`,
                            image: `${shop.logoImage}/xs`,
                            icon: StoreIcon,
                            iconColor: brown[ICON_COLOR_DARKNESS],
                            iconBackgroundColor:
                              brown[ICON_BACKGROUND_DARKNESS],
                          })}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  {getQuickLink({
                    title: <Trans>About bibliocircle</Trans>,
                    path: "/info/about",
                    icon: HelpIcon,
                    iconColor: grey[ICON_COLOR_DARKNESS],
                    iconBackgroundColor: grey[ICON_BACKGROUND_DARKNESS],
                    mobileDrawerOnly: true,
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
