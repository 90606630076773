import React, { useEffect, useState } from "react";
import BookRequestsSearchController from "./BookRequestsSearchController";
import {
  Grid,
  Button,
  makeStyles,
  InputLabel,
  Paper,
  InputAdornment,
  IconButton,
  Collapse,
  Container,
} from "@material-ui/core";
import PageTitle from "../Common/PageTitle";
import { useLocation, useHistory } from "react-router-dom";
import PageHead from "../Common/PageHead";
import BookRequestIcon from "./BookRequestIcon";
import FormControlField from "../Common/FormControlField";
import DropDown from "../Common/DropDown";
import * as consts from "../consts";
import BootstrapInput from "../Common/BootstrapInput";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import ImageFadeIn from "../Common/ImageFadeIn";
import { useLoginPrompt, useScrollTop } from "../../utils/hooks";
import { t, Trans } from "@lingui/macro";
import Hyperlink from "../Common/Hyperlink";

const languages = [
  {
    default: true,
    label: "Any Language",
    value: 0,
  },
  ...consts.languages,
];
const priceModelTypes = [
  {
    default: true,
    label: "Buy/Rent/Exchange",
    value: 0,
  },
  ...consts.buyingPriceModelTypes,
];

const useStyles = makeStyles((theme) => ({
  page: {
    marginTop: theme.spacing(3),
  },
  pageTitle: {
    marginTop: theme.spacing(2),
  },
  logo: {
    maxHeight: theme.spacing(6) * 2,
  },
  requestBookButton: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
  },
  pageTitleText: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      justifyContent: "center",
    },
  },
  pageTitleTextContainer: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  mobileOnly: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  desktopOnly: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const expiryDateSortOrders = [
  {
    default: true,
    label: "Expiring Soon Last",
    value: -1,
  },
  {
    label: "Expiring Soon First",
    value: 1,
  },
];

export default function BookRequestsPage() {
  const classes = useStyles();
  const queryParams = useQueryParams();
  const history = useHistory();
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [filterLanguage, setFilterLanguage] = useState(0);
  const [filterPriceModelType, setFilterPriceModelType] = useState(0);
  const [sortByExpiry, setSortByExpiry] = useState(-1);

  useScrollTop([history.location.key]);

  const onChangeFilterLanguage = (event) =>
    setFilterLanguage(event.target.value);
  const onChangeFilterPriceModel = (event) =>
    setFilterPriceModelType(event.target.value);
  const onChangeSortByExpiry = (event) => setSortByExpiry(event.target.value);
  const onClickRequestBook = createLoginPromptEventHandler({
    redirectPath: "/book-request/create",
  });

  const bookRequestFilters = (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <FormControlField>
          <InputLabel shrink color="primary" htmlFor="bootstrap-input">
            <Trans>Price Model</Trans>
          </InputLabel>
          <DropDown
            value={filterPriceModelType}
            onChange={onChangeFilterPriceModel}
            options={priceModelTypes}
            label="Price Model"
            bordered={true}
          />
        </FormControlField>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControlField>
          <InputLabel shrink color="primary" htmlFor="bootstrap-input">
            <Trans>Language</Trans>
          </InputLabel>
          <DropDown
            value={filterLanguage}
            onChange={onChangeFilterLanguage}
            options={languages}
            label="Any Language"
            bordered={true}
          />
        </FormControlField>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControlField>
          <InputLabel shrink color="primary" htmlFor="bootstrap-input">
            <Trans>Sort By Expiry</Trans>
          </InputLabel>
          <DropDown
            value={sortByExpiry}
            onChange={onChangeSortByExpiry}
            options={expiryDateSortOrders}
            bordered={true}
          />
        </FormControlField>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    setSearchQuery(queryParams.get("q"));
    setFilterLanguage(queryParams.get("language") || 0);
    setFilterPriceModelType(queryParams.get("priceModelType") || 0);
    setSortByExpiry(queryParams.get("expiryDateOrder") || -1);
  }, [history.location]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchBookRequests = () => {
    const queryParams = {};
    if (searchQuery) queryParams.q = searchQuery;
    if (filterLanguage) queryParams.language = filterLanguage;
    if (filterPriceModelType) queryParams.priceModelType = filterPriceModelType;
    if (sortByExpiry) queryParams.expiryDateOrder = sortByExpiry;
    const queryString = new URLSearchParams(queryParams).toString();
    if (searchArgs?.groupScope) {
      history.push(
        `/group/${searchArgs.groupScope}/book-requests?${queryString}`
      );
    } else {
      history.push(`/book-requests?${queryString}`);
    }
  };

  const searchArgs = {
    query: queryParams.get("q"),
    isbn: queryParams.get("isbn"),
    location: queryParams.get("location"),
    language: queryParams.get("language"),
    priceModelType: queryParams.get("priceModelType"),
    pagination: {
      expiryDateOrder: queryParams.get("expiryDateOrder"),
    },
  };
  const isbnList = (queryParams.get("isbnList") || "")
    .split(",")
    .map((isbn) => isbn.trim())
    .filter((isbn) => !!isbn);
  if (isbnList.length) searchArgs.isbnList = isbnList;

  const toggleMobileFilters = () => setMobileFiltersOpen((isOpen) => !isOpen);

  const onKeyDown = (event) => {
    if (event.keyCode === 13) handleSearchBookRequests();
  };
  const onChangeSearchQuery = (event) => setSearchQuery(event.target.value);

  const searchPanel = (
    <Grid item xs={12}>
      <Grid container justifyContent="flex-start">
        <Grid item xs={12}>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12} lg={6}>
              <Paper>
                <BootstrapInput
                  fullWidth
                  autoFocus
                  className={classes.groupSearchBox}
                  value={searchQuery}
                  defaultValue={queryParams.get("q")}
                  placeholder={t`Search book requests: Title, ISBN, Author or Publisher`}
                  bordered
                  onChange={onChangeSearchQuery}
                  onKeyDown={onKeyDown}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        color="primary"
                        variant="contained"
                        onClick={handleSearchBookRequests}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                ></BootstrapInput>
              </Paper>
            </Grid>
            <Grid item xs={12} className={classes.mobileOnly}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Button
                      variant="text"
                      startIcon={<FilterListIcon />}
                      onClick={toggleMobileFilters}
                    >
                      Filter Book Requests
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={mobileFiltersOpen}>
                    {bookRequestFilters}
                  </Collapse>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} className={classes.desktopOnly}>
              {bookRequestFilters}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Container maxWidth="xl">
      <Grid container justifyContent="center" className={classes.page}>
        <PageHead title="Book Requests | bibliocircle" />
        <Grid item xs={11} md={8} lg={12}>
          <Grid container spacing={4}>
            <Grid container item xs={12} className={classes.pageTitle}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                direction="column"
              >
                <Grid item>
                  <Grid container justifyContent="center">
                    <ImageFadeIn
                      src="/logo/logo_book-requests.svg"
                      alt="Discover Book Requests"
                      className={classes.logo}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <PageTitle variant="subtitle1" align="center">
                    <Trans>
                      Looking for a book? Go ahead and create a Book Request and
                      let us and other readers know. We will also automatically
                      search for your book and let you know when it's available.
                    </Trans>
                  </PageTitle>
                </Grid>
                <Grid item>
                  <Button
                    startIcon={<BookRequestIcon />}
                    component={Hyperlink}
                    to="/book-request/create"
                    variant="contained"
                    color="primary"
                    onClick={onClickRequestBook}
                    disableElevation
                  >
                    <Trans>Request Book</Trans>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {searchPanel}
            </Grid>
            <Grid item xs={12}>
              <BookRequestsSearchController
                key={history.location.key}
                searchArgs={searchArgs}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
