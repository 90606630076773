import { Button, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import Reporting from "../Common/Reporting";
import ReportIcon from "@material-ui/icons/Flag";
import { useLoggedInUser } from "../../utils/hooks";

export default function StoryReporter({ story }) {
  const loggedInUser = useLoggedInUser();
  const isAuthor = loggedInUser?.id === story.author?.id;
  const [reporterOpen, setReporterOpen] = useState(false);

  const toggleReporterOpen = () => setReporterOpen((isOpen) => !isOpen);
  return (
    !isAuthor && (
      <React.Fragment>
        <Reporting
          type="STORY"
          resourceId={story.id}
          open={reporterOpen}
          onClose={toggleReporterOpen}
        />
        <Tooltip
          title="Is the story inappropriate? Let us know!"
          aria-label="Is the story inappropriate? Let us know!"
        >
          <Button
            color="secondary"
            startIcon={<ReportIcon />}
            onClick={toggleReporterOpen}
          >
            Report Story
          </Button>
        </Tooltip>
      </React.Fragment>
    )
  );
}
