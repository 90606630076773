import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { priceModelTypes } from "../consts";

const useStyles = makeStyles((theme) => {
  const cardBaseStyles = {
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    cursor: "pointer",
    borderRadius: theme.spacing(2),
  };

  return {
    requestCard: {
      ...cardBaseStyles,
      backgroundColor: theme.palette.background.paper,
      "&:hover": {
        backgroundColor: theme.palette.primary.extraLighter,
      },
    },
    requestCardSelected: {
      ...cardBaseStyles,
      backgroundColor: theme.palette.primary.lighter,
    },
    urgent: {
      color: theme.palette.secondary.main,
    },
    nonUrgent: {
      color: theme.palette.primary.main,
    },
    bookRequestTitle: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
      },
    },
  };
});

export default function BookRequestCard({ bookRequest, onClick, selected }) {
  const classes = useStyles();

  const handleOnClick = () => {
    onClick(bookRequest.id);
  };

  const duration = bookRequest.expiresAt
    ? dayjs(bookRequest.expiresAt).diff(new Date(), "day") + 1
    : null;

  const expiry = (
    <span className={duration < 10 ? classes.urgent : classes.nonUrgent}>
      Expires in {duration} day{duration !== 1 && "s"}
    </span>
  );

  const getPriceModelLabel = (priceModelType) => {
    switch (priceModelType) {
      case priceModelTypes.RENT:
        return "to Rent";
      case priceModelTypes.EXCHANGE:
        return "to Exchange";
      default:
        return "to Buy";
    }
  };

  return (
    <Grid
      container
      item
      xs={12}
      className={selected ? classes.requestCardSelected : classes.requestCard}
      spacing={1}
      onClick={handleOnClick}
    >
      <Grid
        container
        item
        xs={12}
        alignItems="flex-start"
        alignContent="flex-start"
        spacing={1}
      >
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              className={classes.bookRequestTitle}
              color="textPrimary"
            >
              {bookRequest.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textPrimary">
              {bookRequest.count || 1} book{bookRequest.count > 1 && "s"}{" "}
              {getPriceModelLabel(bookRequest.priceModelType)} &bull; {expiry}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary" variant="caption">
              on{" "}
              {dayjs(bookRequest.createdAt).format("MMMM D, YYYY [at] h:mm A")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

BookRequestCard.propTypes = {
  bookRequest: PropTypes.object.isRequired,
  selected: PropTypes.bool,
};
