import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import UserAvatar from "../../../Common/UserAvatar";
import { Rating } from "@material-ui/lab";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function StoryRatingCard({ review }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.card}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4} md={3}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <UserAvatar variant="minimal" user={review.reviewer} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <Grid container alignItems="center">
                      <Typography variant="body2" color="textSecondary">
                        Rated
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center">
                      <Rating size="small" readOnly value={review.rating} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="caption" color="textSecondary">
                  {dayjs(review.updatedAt).format("MMMM D, YYYY [at] hh:mm a")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

StoryRatingCard.propTypes = {
  review: PropTypes.object.isRequired,
};
