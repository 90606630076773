import { Grid, Paper } from "@material-ui/core";
import debounce from "lodash.debounce";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQueryParams } from "../../utils/hooks";
import BootstrapInput from "../Common/BootstrapInput";
import DiscussionsSearchController from "./DiscussionsSearchController";

export default function GroupDiscussions() {
  const { groupId } = useParams();
  const history = useHistory();
  const queryParams = useQueryParams();

  const onChangeSearchQuery = (query) => {
    const queryString = new URLSearchParams({ dq: query }).toString();
    history.push(`/group/${groupId}/bookchats?${queryString}`);
  };
  const debouncedSearch = debounce(onChangeSearchQuery, 800);

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={11} md={10} lg={8}>
                <Paper elevation={2}>
                  <BootstrapInput
                    defaultValue={queryParams.get("dq")}
                    fullWidth
                    placeholder="Search Book Chat"
                    bordered
                    onChange={(event) => {
                      debouncedSearch(event.target.value);
                    }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DiscussionsSearchController
              searchArgs={{ groupId, query: queryParams.get("dq") }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
