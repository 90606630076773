import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import { SEARCH_BOOK_REQUESTS } from "../../consts/queries";
import { useDispatch } from "react-redux";
import { actionTypes } from "../index.reducer";
import NoResults from "../Common/NoResultsImage";
import BookRequestCard from "./BookRequestCard";
import BookRequestDetails from "./BookRequestDetails";
import { BOOK_REQUESTS_PAGINATION_LIMIT } from "../../config";
import InfiniteScroll from "react-infinite-scroll-component";
import { BookRequestsLoader } from "../Common/LoadingPlaceholders/BookRequestsLoader";
import ErrorPage from "../Error/ErrorPage";
import Hyperlink from "../Common/Hyperlink";

const useStyles = makeStyles((theme) => ({
  noBookRequestsPlaceholder: {
    padding: theme.spacing(3),
  },
  bookRequestsList: {
    height: "80vh",
    overflow: "auto",
  },
  noBookRequestsPlaceholderText: {
    fontWeight: "bold",
  },
  bookRequestDetailsDesktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  bookRequestDetailsMobile: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
}));

export default function BookRequestsSearchController({
  fetchPolicy,
  listOnly,
  fixedResultsCount,
  searchArgs,
  noResultsMessage,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isXsDevice = useMediaQuery(theme.breakpoints.down("xs"));
  const isMdDevice = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [bookRequestDialogOpen, setBookRequestDialogOpen] = useState(false);
  const [selectedBookRequestId, setSelectedBookRequestId] = useState(null);

  const openBookRequestDialog = () => setBookRequestDialogOpen(true);
  const closeBookRequestDialog = () => setBookRequestDialogOpen(false);

  const { pagination: paginationParams, ...searchQueryArgs } = searchArgs;
  const expiryDateOrder = paginationParams?.expiryDateOrder;

  const fetchOptions = {
    variables: {
      ...searchQueryArgs,
      pagination: {
        limit: fixedResultsCount || BOOK_REQUESTS_PAGINATION_LIMIT,
        offset: 0,
        expiryDateOrder: +expiryDateOrder,
      },
    },
  };

  if (fetchPolicy) fetchOptions.fetchPolicy = fetchPolicy;
  const [
    fetchBookRequests,
    {
      loading: fetchingBookRequests,
      error: fetchBookRequestsError,
      data,
      fetchMore,
    },
  ] = useLazyQuery(SEARCH_BOOK_REQUESTS, fetchOptions);

  useEffect(() => {
    fetchBookRequests();
  }, [fetchBookRequests]);

  const dispatchErrorMessage = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });

  if (fetchBookRequestsError) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the book requests due to a problem. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  const { searchBookRequests } = data || {};

  const {
    bookRequests = [],
    pagination = { totalCount: -1 }, // TODO: Terribly hacky way to prevent rendering no book requests placeholder while data is being fetched
  } = searchBookRequests || {};

  const loadMoreBookRequests = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: BOOK_REQUESTS_PAGINATION_LIMIT,
          offset: bookRequests.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          searchBookRequests: {
            __typename: prev.searchBookRequests.__typename,
            bookRequests: [
              ...prev.searchBookRequests.bookRequests,
              ...fetchMoreResult.searchBookRequests.bookRequests,
            ],
            pagination: fetchMoreResult.searchBookRequests.pagination,
          },
        });
      },
    }).catch(() => {
      dispatchErrorMessage(
        "Failed to fetch book requests! Please try again later."
      );
    });
  };

  const noBookRequestsPlaceholder = (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      alignContent="flex-start"
      spacing={3}
      className={classes.noBookRequestsPlaceholder}
    >
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="h5"
          color="primary"
          className={classes.noBookRequestsPlaceholderText}
        >
          {noResultsMessage || "No book requests found!"}
        </Typography>
      </Grid>
      <Grid container item xs={10} sm={6} lg={4}>
        <NoResults />
      </Grid>
    </Grid>
  );

  const onClickBookRequestCard = (bookRequestId) => {
    setSelectedBookRequestId(bookRequestId);
    isMdDevice && openBookRequestDialog();
  };

  const afterDeleteBookRequest = () => {
    fetchBookRequests();
    setSelectedBookRequestId(null);
  };

  const bookRequestDetails = (
    <BookRequestDetails
      groupScope={searchArgs.groupScope}
      bookRequestId={
        selectedBookRequestId || (isMdDevice ? null : bookRequests?.[0]?.id)
      }
      afterDelete={afterDeleteBookRequest}
    />
  );

  const getBookRequestCard = (request) => {
    const card = (
      <BookRequestCard
        key={request.id}
        selected={
          !listOnly &&
          !isMdDevice &&
          request.id === (selectedBookRequestId || bookRequests[0].id)
        }
        bookRequest={request}
        onClick={onClickBookRequestCard}
      />
    );
    return listOnly ? (
      <Hyperlink to={`/book-request/${request.id}`}>{card}</Hyperlink>
    ) : (
      card
    );
  };

  const bookRequestsContent = (
    <Grid container alignItems="flex-start">
      <Grid item xs={12}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid
            id="bookRequestsListContainer"
            item
            xs={12}
            lg={listOnly ? 12 : 4}
            className={!listOnly && classes.bookRequestsList}
          >
            <Grid container alignContent="flex-start">
              <InfiniteScroll
                style={{ overflow: "none !important" }}
                scrollableTarget="bookRequestsListContainer"
                next={!fixedResultsCount && loadMoreBookRequests}
                hasMore={bookRequests.length < pagination.totalCount}
                loader={fixedResultsCount && <BookRequestsLoader />}
                dataLength={bookRequests.length}
              >
                {fetchingBookRequests && !bookRequests.length && (
                  <BookRequestsLoader />
                )}
                {bookRequests.map(getBookRequestCard)}
              </InfiniteScroll>
            </Grid>
          </Grid>
          {!listOnly && (
            <React.Fragment>
              <Grid item xs={8} className={classes.bookRequestDetailsDesktop}>
                <Grid container>
                  <Grid item xs={12}>
                    {bookRequestDetails}
                  </Grid>
                </Grid>
              </Grid>
              <Dialog
                open={bookRequestDialogOpen}
                onClose={closeBookRequestDialog}
                fullScreen={isXsDevice}
                maxWidth="lg"
                fullWidth
                className={classes.bookRequestDetailsMobile}
                disableScrollLock={true}
              >
                <DialogContent>
                  <Grid container>{bookRequestDetails}</Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeBookRequestDialog}>Close</Button>
                </DialogActions>
              </Dialog>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Grid container alignItems="flex-start" alignContent="flex-start">
            {pagination.totalCount
              ? bookRequestsContent
              : noBookRequestsPlaceholder}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

BookRequestsSearchController.propTypes = {
  searchArgs: PropTypes.object,
};
