import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Hyperlink from "../../Common/Hyperlink";
import { useQuery } from "@apollo/client";
import { GET_RECOMMENDED_STORIES_FOR_USER } from "../../../consts/queries";
import ErrorPage from "../../Error/ErrorPage";
import MediumStoryCard from "./MediumStoryCard";
import { t, Trans } from "@lingui/macro";
import ReadingPreferencesDialog from "../ReadingPreferencesDialog";

const useStyles = makeStyles((theme) => ({
  sectionCard: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(2),
  },
  sectionTitle: {
    fontWeight: "bold",
    fontSize: 28,
  },
  readingPrefsBottom: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  readingPrefsTop: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default function RecommendedStoriesForUser() {
  const classes = useStyles();
  const { error, data } = useQuery(GET_RECOMMENDED_STORIES_FOR_USER, {
    fetchPolicy: "cache-first",
  });

  if (error) {
    return (
      <ErrorPage
        title={t`Something's not right!`}
        description={t`We could not fetch the stories due to a problem.`}
        statusCode={500}
      />
    );
  }

  if (!data?.recommendedStoriesForUser) return null;
  const stories = data.recommendedStoriesForUser;

  return (
    <Grid container className={classes.sectionCard}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  className={classes.sectionTitle}
                >
                  <Trans>Recommended for you</Trans>
                </Typography>
              </Grid>
              <Grid item className={classes.readingPrefsTop}>
                <ReadingPreferencesDialog />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {stories.map((story) => (
                <Grid key={story.id} item xs={12} sm={6} lg={4}>
                  <Hyperlink to={story.id}>
                    <MediumStoryCard story={story} />
                  </Hyperlink>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item className={classes.readingPrefsBottom}>
                <ReadingPreferencesDialog />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
