import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Hyperlink from "../../Common/Hyperlink";
import { useQuery } from "@apollo/client";
import { GET_SUGGESTED_STORIES } from "../../../consts/queries";
import ErrorPage from "../../Error/ErrorPage";
import SmallStoryCard from "./SmallStoryCard";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "18px",
    fontWeight: "bold",
  },
}));

export default function SuggestedStories({ storyId }) {
  const classes = useStyles();
  const { error, data } = useQuery(GET_SUGGESTED_STORIES, {
    fetchPolicy: "cache-first",
    skip: !storyId,
    variables: { storyId },
  });

  if (!storyId) return null;

  if (error) {
    return (
      <ErrorPage
        title={t`Something's not right!`}
        description={t`We could not fetch the stories due to a problem.`}
        statusCode={500}
      />
    );
  }

  if (!data?.suggestedStories?.length) return null;
  const stories = data.suggestedStories;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          color="textSecondary"
          className={classes.title}
        >
          <Trans>Other stories you might like</Trans>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {stories.map((story) => (
            <Grid key={story.id} item xs={12} sm={6} lg={12}>
              <Hyperlink to={`/story/${story.id}`}>
                <SmallStoryCard story={story} />
              </Hyperlink>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
