import React from "react";
import HomePage from "./Search/HomePage";
import NavigationBar from "./Common/NavigationBar";
import { Route, Switch } from "react-router-dom";
import PublicSearchView from "./Search/PublicSearchView";
import Listing from "./Listing";
import UserProfile from "./Profile";
import GroupEditor from "./Group/GroupEditor";
import Group from "./Group";
import DiscoverGroups from "./Group/DiscoverGroups";
import UpdateListing from "./Listing/UpdateListing";
import CreateListing from "./Listing/CreateListing";
import EditProfile from "./Profile/EditProfile";
import { useSelector } from "react-redux";
import Loader from "./Common/Loader";
import { makeStyles, Grid } from "@material-ui/core";
import BookRequestsPage from "./Requests/BookRequestsPage";
import Shop from "./Shop";
import DiscoverShops from "./Shop/DiscoverShops";
import CreateShop from "./Shop/CreateShop";
import BookRequestMatches from "./Requests/BookRequestMatches";
import Checkout from "./Checkout";
import Order from "./Orders";
import Help from "../Help";
import Chat from "./Chat";
import ListingCollections from "./ListingCollections";
import ListingCollection from "./ListingCollections/ListingCollection";
import ListingCollectionEditor from "./ListingCollections/ListingCollectionEditor";
import Critique from "./Critique/Critique";
import Critiques from "./Critique";
import CritiqueEditor from "./Critique/CritiqueEditor";
import UpdateBookRequest from "./Requests/UpdateBookRequest";
import CreateBookRequest from "./Requests/CreateBookRequest";
import ErrorPage from "./Error/ErrorPage";
import BookChat from "./Discussions/BookChat";
import DiscussionEditor from "./Discussions/DiscussionEditor";
import PublicBookChatDiscussion from "./Discussions/PublicBookChatDiscussion";
import Sidebar from "./Common/Sidebar";
import People from "./People";
import Stories from "./Stories";
import BibliocircleStoryPicks from "./Stories/Story/BibliocirclePicks";
import Story from "./Stories/Story";
import StoryChapter from "./Stories/StoryChapter";
import ChapterEditor from "./Stories/StoryChapter/ChapterEditor";
import StoryEditor from "./Stories/Story/StoryEditor";
import ChapterRedirector from "./Stories/StoryChapter/ChapterRedirector";
import { useLoggedInUser } from "../utils/hooks";
import { USER_ROLES } from "./consts";
import Admin from "./Admin";
import Feed from "./Feed";
import MobilePushNotificationRedirector from "./Notifications/MobilePushNotificationRedirector";
import MobileBottomNavigation from "./MobileBottomNavigation";

const useStyles = makeStyles((theme) => ({
  appContainer: {
    paddingBottom: theme.spacing(10),
    "@media (min-width: 0px) and (orientation: landscape)": {
      // TODO: Need to find a way to do this in a clean way
      marginTop: theme.spacing(6),
    },
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(7),
    },
    marginTop: theme.spacing(7),
  },
  contactLink: {
    color: theme.palette.background.paper,
    fontSize: 14,
    paddingLeft: theme.spacing(2),
    textDecoration: "none",
    zIndex: 9999,
  },
  sidebar: {
    position: "fixed",
    top: 64,
    left: 0,
    bottom: 0,
    width: "inherit",
    paddingTop: theme.spacing(3),
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.background.paper,
    overflowY: "auto",
  },
  sidebarContainer: {
    width: "250px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  content: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      marginLeft: "250px",
    },
    overflow: "hidden",
  },
  mobileBottomNavigation: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

export default function Application() {
  const classes = useStyles();
  const loggedInUser = useLoggedInUser();
  const isAdmin = loggedInUser?.role === USER_ROLES.ADMINISTRATOR;
  const application = useSelector((state) => state.application);
  return (
    <>
      <NavigationBar />
      <Loader loading={application.loading} />
      <Grid container className={classes.appContainer}>
        <Grid item className={classes.sidebarContainer}>
          <Grid container className={classes.sidebar}>
            <Sidebar />
          </Grid>
        </Grid>
        <Grid item className={classes.mobileBottomNavigation}>
          <MobileBottomNavigation />
        </Grid>
        <Grid item className={classes.content}>
          <Switch>
            {isAdmin && (
              <Route path="/admin">
                <Admin />
              </Route>
            )}
            <Route path="/group/create">
              <GroupEditor />
            </Route>
            <Route path="/group/:groupId/edit">
              <GroupEditor />
            </Route>
            <Route path="/group/:groupId/invitation/:invitationKey">
              <Group />
            </Route>
            <Route path="/group/:groupId">
              <Group />
            </Route>
            <Route path="/collection/create">
              <ListingCollectionEditor />
            </Route>
            <Route path="/collection/:collectionId/edit">
              <ListingCollectionEditor />
            </Route>
            <Route path="/collection/:collectionId">
              <ListingCollection />
            </Route>
            <Route path="/collections">
              <ListingCollections />
            </Route>
            <Route path="/critique/new">
              <CritiqueEditor />
            </Route>
            <Route path="/critique/:critiqueId/edit">
              <CritiqueEditor />
            </Route>
            <Route path="/critique/:critiqueId">
              <Critique />
            </Route>
            <Route path="/critiques">
              <Critiques />
            </Route>
            <Route path="/chapter/:chapterId">
              <ChapterRedirector />
            </Route>
            <Route path="/story/:storyId/chapter/new">
              <ChapterEditor />
            </Route>
            <Route path="/story/:storyId/chapter/:chapterId/edit">
              <ChapterEditor />
            </Route>
            <Route path="/story/:storyId/chapter/:chapterId">
              <StoryChapter />
            </Route>
            <Route path="/story/new">
              <StoryEditor />
            </Route>
            <Route path="/story/:storyId/edit">
              <StoryEditor />
            </Route>
            <Route path="/story/:storyId">
              <Story />
            </Route>
            <Route path="/stories/list/bibliocircle-picks">
              <BibliocircleStoryPicks />
            </Route>
            <Route
              path="/stories"
              render={(props) => <Stories key={props.location.key} />}
            />
            <Route path="/bookchat/new">
              <DiscussionEditor />
            </Route>
            <Route path="/bookchat/:discussionId/edit">
              <DiscussionEditor />
            </Route>
            <Route path="/bookchat/:discussionId">
              <PublicBookChatDiscussion />
            </Route>
            <Route path="/bookchats">
              <BookChat />
            </Route>
            <Route path="/listing/create">
              <CreateListing />
            </Route>
            <Route path="/listing/:listingId/edit">
              <UpdateListing />
            </Route>
            <Route
              path="/listing/:listingId"
              render={(props) => <Listing key={props.location.key} />}
            />
            <Route path="/listings">
              <PublicSearchView />
            </Route>
            <Route path="/groups">
              <DiscoverGroups />
            </Route>
            <Route path="/people">
              <People />
            </Route>
            <Route path="/profile/edit">
              <EditProfile />
            </Route>
            <Route path="/profile/:userId/page/:pageId">
              <UserProfile />
            </Route>
            <Route exact path="/profile/:userId">
              <UserProfile />
            </Route>
            <Route path="/book-requests">
              <BookRequestsPage />
            </Route>
            <Route path="/book-request/create">
              <CreateBookRequest />
            </Route>
            <Route path="/book-request/:bookRequestId/edit">
              <UpdateBookRequest />
            </Route>
            <Route path="/book-request/:bookRequestId">
              <BookRequestMatches />
            </Route>
            <Route path="/shops">
              <DiscoverShops />
            </Route>
            <Route path="/order/:orderId">
              <Order />
            </Route>
            <Route path="/shop/create">
              <CreateShop />
            </Route>
            <Route path="/shop/:shopIdentifier">
              <Shop />
            </Route>
            <Route
              path="/shoppingcart"
              render={(props) => <Checkout key={props.location.key} />}
            />
            <Route
              path="/chat/:chatGroupId/thread/:threadId"
              render={(props) => <Chat key={props.location.key} />}
            />
            <Route path="/notification/:notificationId/redirect">
              <MobilePushNotificationRedirector />
            </Route>
            <Route path="/chat">
              <Chat />
            </Route>
            <Route path="/feed">
              <Feed />
            </Route>
            <Route path="/dashboard">
              <HomePage />
            </Route>
            <Route path="/info">
              <Help />
            </Route>
            <Route path="/" exact>
              <Feed />
            </Route>
            <Route>
              <ErrorPage
                title="Not Found!"
                description="We could not find the page you were looking for!"
                statusCode={404}
              />
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </>
  );
}
