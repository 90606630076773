import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { t } from "@lingui/macro";
import ListingCollectionsSearchController from "../../ListingCollections/ListingCollectionsSearchController";

export default function UserListingCollectionsPage({ userId, searchQuery }) {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10} lg={8} xl={12}>
        <ListingCollectionsSearchController
          searchArgs={{ query: searchQuery, ownerId: userId }}
          noResultsMessage={t`No Collections Found!`}
          xs={12}
          xl={6}
        />
      </Grid>
    </Grid>
  );
}

UserListingCollectionsPage.propTypes = {
  userId: PropTypes.string.isRequired,
  searchQuery: PropTypes.string,
};
