import React from "react";
import { Helmet } from "react-helmet";
import { BC_DESCRIPTION } from "../../config";

export default function PageHead({ title, description }) {
  return (
    <Helmet>
      <title>{title || "bibliocircle"}</title>
      <meta name="description" content={description || BC_DESCRIPTION} />
    </Helmet>
  );
}
