import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    border: "none",
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(1),
    width: "100%",
    marginBottom: 0,
    borderLeft: "10px solid",
    borderColor: theme.palette.grey[200],
  },
}));

export function FeedCardLoader() {
  const classes = useStyles();
  return (
    <div className={`ph-item ${classes.item}`}>
      <div>
        <div className="ph-col-12">
          <div className="ph-row">
            <div className="ph-col-8"></div>
            <div className="ph-col-12 empty"></div>
          </div>
          <div class="ph-picture"></div>
          <div className="ph-row">
            <div className="ph-col-8"></div>

            <div className="ph-col-12"></div>
            <div className="ph-col-12"></div>
            <div className="ph-col-12 empty"></div>
            <div className="ph-col-12 empty"></div>
            <div className="ph-col-4"></div>
            <div className="ph-col-6 empty"></div>
            <div className="ph-col-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function FeedLoader({ xs, md, lg, xl }) {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      spacing={3}
      justifyContent="flex-start"
      className={classes.groups}
    >
      <Grid item container xs={xs} md={md} lg={lg} xl={xl}>
        <FeedCardLoader />
      </Grid>
      <Grid item container xs={xs} md={md} lg={lg} xl={xl}>
        <FeedCardLoader />
      </Grid>
      <Grid item container xs={xs} md={md} lg={lg} xl={xl}>
        <FeedCardLoader />
      </Grid>
    </Grid>
  );
}
