import React, { useState, useEffect, useCallback } from "react";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LandingPage from "./LandingPage";
import BootstrapInput from "../Application/Common/BootstrapInput";
import { AUTH_SUCCESS, LOGOUT } from "./auth.actionTypes";
import {
  BACKEND_URL,
  GENERIC_ALERT_ERROR_MESSAGE,
  BC_AUTH_REDIRECT_COOKIE_NAME,
} from "../config";
import SignUpLink from "./common/SignUpLink";
import { actionTypes } from "../Application/index.reducer";
import Hyperlink from "../Application/Common/Hyperlink";
import LoginIcon from "@material-ui/icons/ExitToApp";
import PageHead from "../Application/Common/PageHead";
import { isValidEmail } from "../utils/common";
import { useLoader, useQueryParams } from "../utils/hooks";
import { useCookies } from "react-cookie";
import GoogleIcon from "../Application/Common/GoogleIcon";
import { initiateGoogleLogin, isWebViewAvailable } from "../mobile/events";

const useStyles = makeStyles((theme) => ({
  loginFormContainer: {
    paddingTop: "5vh",
    paddingBottom: "5vh",
  },
  inputField: {
    width: "100%",
  },
  contentHeading: {
    fontWeight: "bold",
  },
  plainLink: {
    color: "white",
  },
  newUserGreeting: {
    marginBottom: theme.spacing(3),
  },
  loginBtn: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.getContrastText(theme.palette.grey[800]),
    border: "none",
  },
  loginTypeButton: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.getContrastText(theme.palette.grey[800]),
    border: "none",
  },
}));

function Login() {
  const classes = useStyles();
  const queryParams = useQueryParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginInProgress, setLoginInProgress] = useState(false);
  const [, setCookie] = useCookies([]);
  const isDuplicationUserLoginFailure =
    queryParams.get("failureReason") === "duplicate";
  const isGoogleLoginFailure = queryParams.get("provider") === "google";
  const redirectPath =
    history.location.state?.redirectPath || queryParams.get("redirectPath");
  setCookie(BC_AUTH_REDIRECT_COOKIE_NAME, redirectPath || "", {
    path: "/",
    domain: window.location.hostname,
  });
  const dispatchErrorMessage = useCallback(
    (message) =>
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message,
      }),
    [dispatch]
  );

  useLoader(loginInProgress);

  useEffect(() => {
    if (isGoogleLoginFailure && isDuplicationUserLoginFailure) {
      dispatchErrorMessage(
        "Google account is not linked to your profile. Please login with username and password!"
      );
    }
  }, [
    dispatchErrorMessage,
    isDuplicationUserLoginFailure,
    isGoogleLoginFailure,
  ]);

  useEffect(() => {
    dispatch({ type: LOGOUT });
  }, [dispatch]);

  const goToRedirectedPage = () => {
    if (history.location.state?.redirectPath) {
      const { redirectPath } = history.location.state;
      history.location.state = null;
      return history.push(redirectPath);
    } else if (queryParams.get("redirectPath")) {
      // Fallback check for query parameter
      return history.push(queryParams.get("redirectPath"));
    }
    return history.push("/");
  };
  const handleEmailChange = (event) => {
    setUsernameError(false);
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPasswordError(false);
    setPassword(event.target.value);
  };

  const submitLogin = () => {
    const isEmailValid = isValidEmail(username);
    const isPasswordValid = !!password;

    if (!isEmailValid || !isPasswordValid) {
      setUsernameError(!isEmailValid);
      setPasswordError(!isPasswordValid);
      return dispatchErrorMessage(
        "Both Email and Password require valid values!"
      );
    }

    setLoginInProgress(true);
    fetch(`${BACKEND_URL}/auth/login`, {
      credentials: "include",
      method: "post",
      body: JSON.stringify({ username: username.trim(), password }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 401) {
          dispatchErrorMessage("Invalid Email or Password!");
        } else if (data.token) {
          setLoginInProgress(false);
          dispatch({
            type: AUTH_SUCCESS,
            token: data.token,
          });
          goToRedirectedPage();
        } else {
          setLoginInProgress(false);
          throw new Error("login failed");
        }
      })
      .catch(() => {
        setLoginInProgress(false);
        dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
      });
  };
  const onKeyDown = (event) => {
    if (event.keyCode === 13) submitLogin();
  };

  const greeting = history.location.state?.signedUpUser && (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent="center"
        className={classes.newUserGreeting}
      >
        <Typography variant="h6" align="center">
          Welcome aboard {history.location.state.signedUpUser?.firstName}!
          Please sign in to continue!
        </Typography>
      </Grid>
    </Grid>
  );

  const redirectionReason = redirectPath && (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent="center"
        className={classes.newUserGreeting}
      >
        <Typography variant="h6" align="center">
          Please sign in to continue!
        </Typography>
      </Grid>
    </Grid>
  );

  const passwordResetHelperMessage = history.location.state
    ?.resetPasswordUser && (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent="center"
        className={classes.newUserGreeting}
      >
        <Typography variant="h6" align="center">
          Hi {history.location.state.resetPasswordUser?.firstName}! Your
          password was successfully reset. Please login with your new password.
        </Typography>
      </Grid>
    </Grid>
  );

  const notifyMobileLogin = (event) => {
    initiateGoogleLogin();
    if (isWebViewAvailable()) {
      event.preventDefault();
    }
  };

  return (
    <LandingPage>
      <PageHead title="Login | bibliocircle" />
      <Grid container spacing={6} className={classes.loginFormContainer}>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Typography variant="h3" className={classes.contentHeading}>
              Log In
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={10} sm={6}>
              <Grid container spacing={2}>
                {greeting}
                {redirectionReason}
                {passwordResetHelperMessage}
                <Grid item xs={12}>
                  <BootstrapInput
                    error={usernameError}
                    onKeyDown={onKeyDown}
                    onChange={handleEmailChange}
                    placeholder="Email"
                    className={classes.inputField}
                  ></BootstrapInput>
                </Grid>
                <Grid item xs={12}>
                  <BootstrapInput
                    error={passwordError}
                    onKeyDown={onKeyDown}
                    onChange={handlePasswordChange}
                    type="password"
                    placeholder="Password"
                    className={classes.inputField}
                  ></BootstrapInput>
                </Grid>

                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Button
                      variant="contained"
                      size="large"
                      className={classes.loginBtn}
                      disableElevation
                      onClick={submitLogin}
                    >
                      Log In
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Typography variant="subtitle2" align="center">
                      <Hyperlink
                        to="/resetpassword"
                        className={classes.plainLink}
                      >
                        Forgot Password?
                      </Hyperlink>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" align="center">
                    If you are a Google user
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Button
                      startIcon={<GoogleIcon />}
                      variant="contained"
                      disableElevation
                      className={classes.loginTypeButton}
                      href={`${BACKEND_URL}/auth/google/login`}
                      onClick={notifyMobileLogin}
                    >
                      Login with Google
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <SignUpLink redirectPath={redirectPath} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={8}>
              <Grid container justifyContent="center">
                <Button
                  variant="text"
                  fullWidth
                  endIcon={<LoginIcon />}
                  disableElevation
                  style={{ color: "white" }}
                  component={Hyperlink}
                  to={"/"}
                >
                  Continue as a guest
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LandingPage>
  );
}

export default Login;
