import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import OutgoingOrderCard from "./OutgoingOrderCard";
import NoResults from "../../Common/NoResultsImage";
import Banner from "../../Common/Banner";
import { ORDERS_PAGINATION_LIMIT } from "../../../config";
import { useQuery } from "@apollo/client";
import { SEARCH_ORDERS } from "../../../consts/queries";
import { OrdersLoader } from "../../Common/LoadingPlaceholders/OrdersLoader";

const useStyles = makeStyles((theme) => ({
  noOrdersPlaceholder: {
    padding: theme.spacing(3),
  },
  bookRequestsList: {
    height: "80vh",
    overflow: "auto",
  },
  errorBanner: {
    padding: theme.spacing(3),
  },
  noOrdersPlaceholderText: {
    fontWeight: "bold",
  },
}));

export default function OutgoingOrdersSearchController({ searchArgs }) {
  const classes = useStyles();
  const { loading, error, data, fetchMore } = useQuery(SEARCH_ORDERS, {
    variables: {
      ...searchArgs,
      pagination: {
        limit: ORDERS_PAGINATION_LIMIT,
        offset: 0,
      },
    },
  });

  const loadingPlaceholder = <OrdersLoader />;

  if (loading && !data) {
    return (
      <Grid container justifyContent="center">
        {loadingPlaceholder}
      </Grid>
    );
  }

  const {
    searchOrders: { orders, pagination },
  } = data;

  const loadMoreOrders = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: ORDERS_PAGINATION_LIMIT,
          offset: orders.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          searchOrders: {
            __typename: prev.searchOrders.__typename,
            orders: [
              ...prev.searchOrders.orders,
              ...fetchMoreResult.searchOrders.orders,
            ],
            pagination: fetchMoreResult.searchOrders.pagination,
          },
        });
      },
    }).catch(() => {});
  };

  if (error)
    return (
      <Grid container justifyContent="center" className={classes.errorBanner}>
        <Grid item xs={12}>
          <Banner
            type="error"
            title="Something went wrong!"
            message="We're sorry! Something went wrong while displaying orders for you!
              Please try again later."
          />
        </Grid>
      </Grid>
    );

  const noOrdersPlaceholder = (
    <Grid
      container
      justifyContent="center"
      className={classes.noOrdersPlaceholder}
    >
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="h5"
          color="primary"
          className={classes.noOrdersPlaceholderText}
        >
          No orders found!
        </Typography>
      </Grid>
      <Grid container item xs={10} sm={6} lg={4}>
        <NoResults />
      </Grid>
    </Grid>
  );

  return (
    <Grid container item xs={12}>
      {!orders.length ? noOrdersPlaceholder : null}
      <InfiniteScroll
        style={{ overflow: "none !important" }}
        next={loadMoreOrders}
        hasMore={orders.length < pagination.totalCount}
        loader={<OrdersLoader />}
        dataLength={orders.length}
      >
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {orders.map((order) => (
              <Grid item xs={12} key={order.id}>
                <OutgoingOrderCard order={order} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </InfiniteScroll>
    </Grid>
  );
}

OutgoingOrdersSearchController.propTypes = {
  searchArgs: PropTypes.object,
};

OutgoingOrdersSearchController.defaultProps = {
  searchArgs: {},
};
