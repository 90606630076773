import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Typography, Link, Avatar } from "@material-ui/core";
import Hyperlink from "../../Common/Hyperlink";

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
    height: "100%",
  },
  sectionIcon: {
    "& *": {
      fontSize: "24px",
    },
    marginRight: theme.spacing(0.5),
  },
  sectionTitle: {
    fontSize: "18px",
  },
  sectionAvatar: {
    backgroundColor: theme.palette.grey[100],
  },
}));

export default function ProfileHomePage({ userId, sectionsList, pagesList }) {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      {sectionsList.map((section) => (
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <Grid container className={classes.section}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      className={classes.sectionTitle}
                      variant="subtitle2"
                    >
                      {section.title}
                    </Typography>
                    <span className={classes.sectionIcon}>
                      <Avatar className={classes.sectionAvatar}>
                        {section.icon}
                      </Avatar>
                    </span>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {pagesList.map(
                      (page) =>
                        page.sectionId === section.id && (
                          <Grid item xs={12}>
                            <Hyperlink
                              to={`/profile/${userId}/page/${page.pageId}`}
                            >
                              <Link color="primary">
                                <Typography variant="body1">
                                  {page.label}
                                </Typography>
                              </Link>
                            </Hyperlink>
                          </Grid>
                        )
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

ProfileHomePage.defaultProps = {
  sectionsList: [],
  pagesList: [],
};

ProfileHomePage.propTypes = {
  sectionsList: PropTypes.array,
  pagesList: PropTypes.array,
};
