import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import NoResults from "../Common/NoResultsImage";
import { DISCUSSIONS_PAGINATION_LIMIT } from "../../config";
import { useQuery } from "@apollo/client";
import { SEARCH_DISCUSSIONS } from "../../consts/queries";
import DiscussionCard from "./DiscussionCard";
import { DiscussionsLoader } from "../Common/LoadingPlaceholders/DiscussionsLoader";
import ErrorPage from "../Error/ErrorPage";
import { useLoader } from "../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  noDiscussionsPlaceholder: {
    padding: theme.spacing(3),
  },
  errorBanner: {
    padding: theme.spacing(3),
  },
  noDiscussionsPlaceholderText: {
    fontWeight: "bold",
    textAlign: "center",
  },
}));

export default function DiscussionsSearchController({
  fetchPolicy,
  compactCards,
  descriptionMaxLength,
  fixedResultsCount,
  showGroupNames,
  searchArgs,
  noResultsMessage,
  xs,
  md,
  lg,
  xl,
}) {
  const classes = useStyles();
  const fetchOptions = {
    variables: {
      ...searchArgs,
      pagination: {
        limit: fixedResultsCount || DISCUSSIONS_PAGINATION_LIMIT,
        offset: 0,
      },
    },
  };

  if (fetchPolicy) fetchOptions.fetchPolicy = fetchPolicy;
  const { loading, error, data, fetchMore } = useQuery(
    SEARCH_DISCUSSIONS,
    fetchOptions
  );
  useLoader(loading);

  const loadingPlaceholder = (
    <DiscussionsLoader xs={xs} md={md} lg={lg} xl={xl} />
  );

  if (error) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the discussions due to a problem. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  if (loading && !data) {
    return (
      <Grid container justifyContent="center">
        {loadingPlaceholder}
      </Grid>
    );
  }

  const {
    searchDiscussions: { discussions, pagination },
  } = data;

  const loadMoreDiscussions = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: DISCUSSIONS_PAGINATION_LIMIT,
          offset: discussions.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          searchDiscussions: {
            __typename: prev.searchDiscussions.__typename,
            discussions: [
              ...prev.searchDiscussions.discussions,
              ...fetchMoreResult.searchDiscussions.discussions,
            ],
            pagination: fetchMoreResult.searchDiscussions.pagination,
          },
        });
      },
    }).catch(() => {});
  };

  const noDiscussionsPlaceholder = (
    <Grid
      container
      justifyContent="center"
      className={classes.noDiscussionsPlaceholder}
    >
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="h5"
          color="primary"
          className={classes.noDiscussionsPlaceholderText}
        >
          {noResultsMessage || "No Discussions!"}
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={6} lg={4}>
          <NoResults />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      {!discussions.length ? noDiscussionsPlaceholder : null}
      <InfiniteScroll
        style={{ overflow: "none !important" }}
        next={!fixedResultsCount && loadMoreDiscussions}
        hasMore={discussions.length < pagination.totalCount}
        loader={
          !fixedResultsCount && (
            <DiscussionsLoader xs={xs} md={md} lg={lg} xl={xl} />
          )
        }
        dataLength={discussions.length}
      >
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {discussions.map((discussion) => (
              <Grid key={discussion.id} item xs={xs} md={md} lg={lg} xl={xl}>
                <DiscussionCard
                  compact={compactCards}
                  descriptionMaxLength={descriptionMaxLength}
                  discussion={discussion}
                  showGroupName={showGroupNames}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </InfiniteScroll>
    </Grid>
  );
}

DiscussionsSearchController.propTypes = {
  searchArgs: PropTypes.object,
  showGroupNames: PropTypes.bool,
};

DiscussionsSearchController.defaultProps = {
  searchArgs: {},
  xs: 12,
};
