import Joi from "@hapi/joi";

export default function getShopSchema(customIdentifierValidator) {
  return Joi.object({
    id: Joi.string().optional(),
    name: Joi.string().trim().required(),
    identifier: Joi.string()
      .trim()
      .lowercase()
      .required()
      .external(customIdentifierValidator),
    description: Joi.string().allow("", null).optional(),
    tier: Joi.string().allow("", null).optional().strip(), // Todo: Strip this field until we implement pricing plans
    logoImage: Joi.string().allow("", null).optional(),
    coverImage: Joi.string().allow("", null).optional(),
    contactNumberIds: Joi.array().items(Joi.string()),
    addressIds: Joi.array().items(Joi.string()).min(1),
    deliveryPreferences: Joi.array().items(
      Joi.object({
        type: Joi.string().required(),
        notes: Joi.string().allow(null, "").optional(),
      })
    ),
  });
}
