import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  makeStyles,
  Container,
  Button,
  FormHelperText,
  FormControlLabel,
  Radio,
  Checkbox,
  FormControl,
} from "@material-ui/core";
import AddressManager from "../Common/Addresses/AddressManager";
import PageTitle from "../Common/PageTitle";
import { useDispatch } from "react-redux";
import GroupSelector from "../Group/GroupSelector";
import PhoneNumberManager from "../Common/PhoneNumbers/PhoneNumberManager";
import InputLabel from "../Common/InputLabel";
import FormControlField from "../Common/FormControlField";
import ShopSelection from "./ShopSelection";
import BookManager from "./BookManager";
import { actionTypes } from "../index.reducer";
import Required from "../Common/Required";
import { priceModelTypes } from "../consts";
import BAutoComplete from "../Common/BAutoComplete";
import { useLazyQuery } from "@apollo/client";
import { GET_TAGS_REF_DATA } from "../../consts/queries";
import { TAGS_REF_DATA_BULK_LIMIT } from "../../config";
import Banner from "../Common/Banner";
import { useLoggedInUser, usePrompt } from "../../utils/hooks";
import BootstrapInputBordered from "../Common/BootstrapInputBordered";
import { stripBookMetaProps } from "../../utils/common";
import { t, Trans } from "@lingui/macro";
import TextEditor from "../Common/TextEditor";

const useStyles = makeStyles((theme) => ({
  editorSection: {
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(3),
  },
  title: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  createListingBtnContainerDesktop: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  createListingBtnContainerMobile: {
    marginBottom: theme.spacing(5),
    paddingTop: `0 !important`,
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  contactData: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(5),
    backgroundColor: theme.palette.grey[200],
  },
  barcodeSample: {
    padding: theme.spacing(2),
  },
  sidebar: {
    paddingTop: "0 !important", // TODO: An ugly way to override. Fix it later
  },
  priceAdornment: {
    width: "80px",
    marginLeft: theme.spacing(1),
  },
  desktopOnly: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobileOnly: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  formSubmittingNotice: {
    marginBottom: theme.spacing(3),
  },
  descriptionText: {
    lineHeight: 1.5,
  },
}));

export default function ListingEditor({
  listing,
  groupScope,
  shopScope,
  editorTitle,
  submitButtonText,
  onSubmit,
  initialShopId,
  initialAddressIds,
  initialContactNumberIds,
  disabled,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loggedInUser = useLoggedInUser();
  const [getTagsRefData, { loading: loadingTagsRefData, data: tagsRefData }] =
    useLazyQuery(GET_TAGS_REF_DATA);

  // Form State
  const [listingTitle, setListingTitle] = useState("");
  const [listingDescription, setListingDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [tags, setTags] = useState([]);
  const [sharePublic, setSharePublic] = useState(true);
  const [shareTargetGroups, setShareTargetGroups] = useState([]);
  const [priceModelType, setPriceModelType] = useState(priceModelTypes.SALE);
  const [priceAmount, setPriceAmount] = useState(0);
  const [originalPriceAmount, setOriginalPriceAmount] = useState(0);
  const [listingAddressIds, setListingAddressIds] = useState([]);
  const [listingContactNumberIds, setListingContactNumberIds] = useState([]);
  const [selectedShop, setSelectedShop] = useState(null);
  const [books, setBooks] = useState([]);
  const [unsavedTag, setUnsavedTag] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [isPageDirty, setPageDirty] = useState(true);
  const [savePreferences, setSavePreferences] = useState(false);

  usePrompt("Are you sure you want to leave the page?", isPageDirty);

  // Form field validity status
  const [invalidListingTitle, setInvalidListingTitle] = useState(false);
  const [invalidBooksCount, setInvalidBooksCount] = useState(false);

  // Form State Actions
  const handleChangeListingTitle = (event) => {
    setInvalidListingTitle(false);
    setListingTitle(event.target.value);
  };
  const handleChangeListingDescription = (event) =>
    setListingDescription(event.target.value);
  const handleChangeNotes = (event) => setNotes(event.target.value);
  const handleChangeSharePublic = (sharePublic) => setSharePublic(sharePublic);
  const handleAddTargetGroup = (groupId) =>
    setShareTargetGroups((groupScopes) =>
      Array.from(new Set([...groupScopes, groupId]))
    );
  const handleRemoveTargetGroup = (groupId) =>
    setShareTargetGroups((groupScopes) =>
      groupScopes.filter((id) => id !== groupId)
    );
  const handleChangePriceModelType = (priceModelType) => () => {
    if (priceModelType === priceModelTypes.EXCHANGE) {
      setPriceAmount(0);
      setOriginalPriceAmount(0);
    }
    setPriceModelType(priceModelType);
  };

  const handleChangePriceAmount = (event) => {
    if (!/^([0-9]+(.[0-9]{0,2})?)?$/.test(event.target.value)) return;
    setPriceAmount(event.target.value);
  };
  const handleFocusPrice = () => {
    if (!priceAmount) setPriceAmount("");
  };
  const handleBlurPrice = () => {
    if (!priceAmount) setPriceAmount(0);
  };

  const handleChangeOriginalPriceAmount = (event) => {
    if (!/^([0-9]+(.[0-9]{0,2})?)?$/.test(event.target.value)) return;
    setOriginalPriceAmount(event.target.value);
  };
  const handleFocusOriginalPrice = () => {
    if (!originalPriceAmount) setOriginalPriceAmount("");
  };
  const handleBlurOriginalPrice = () => {
    if (!originalPriceAmount) setOriginalPriceAmount(0);
  };

  const handleChangeTags = (event, tags) => {
    setUnsavedTag(null);
    setTags(tags);
  };
  const handleSearchTags = (event) => {
    const tagDraft = event?.target?.value;
    setUnsavedTag(tagDraft);
    if (!tagDraft) return;
    getTagsRefData({
      variables: {
        query: tagDraft,
        limit: TAGS_REF_DATA_BULK_LIMIT,
      },
    });
  };

  const handleChangeBooks = useCallback((books) => {
    // Clear books error when books are updated. But this is verified again before submitted.
    setInvalidBooksCount(false);
    setBooks(books);
  }, []);

  const handleChangeListingContacts = (contactNumberId, selected) => {
    if (selected) {
      setListingContactNumberIds((ids) =>
        Array.from(new Set([...ids, contactNumberId]))
      );
    } else {
      setListingContactNumberIds((ids) =>
        ids.filter((id) => id !== contactNumberId)
      );
    }
  };
  const handleChangeListingAddresses = (addressId, selected) => {
    if (selected) {
      setListingAddressIds((ids) => Array.from(new Set([...ids, addressId])));
    } else {
      setListingAddressIds((ids) => ids.filter((id) => id !== addressId));
    }
  };
  const handleChangeSelectedShop = useCallback((shop) => {
    setSelectedShop(shop);
    if (!shop) return;
    try {
      const shopAddresses = (shop?.addresses || []).map((addr) => addr.id);
      const shopContactNumbers = (shop?.contactNumbers || []).map(
        (contact) => contact.id
      );
      setListingContactNumberIds((listingContactNumberIds) => {
        return listingContactNumberIds.filter((contactId) =>
          shopContactNumbers.includes(contactId)
        );
      });
      setListingAddressIds((listingAddressIds) => {
        return listingAddressIds.filter((addressId) =>
          shopAddresses.includes(addressId)
        );
      });
    } catch (err) {}
  }, []);

  const validateForm = () => {
    setInvalidListingTitle(!listingTitle);
    setInvalidBooksCount(!books?.length);
    return listingTitle && !!books.length;
  };

  useEffect(() => {
    if (listing && Object.keys(listing).length) {
      setListingTitle(listing.title);
      setListingDescription(listing.description);
      setSharePublic(listing.public);
      setShareTargetGroups(listing.groups.map((group) => group.id));
      setPriceAmount(listing.price);
      setOriginalPriceAmount(listing.originalPrice);
      setPriceModelType(listing.priceModelType);
      setListingContactNumberIds(
        listing.contactNumbers.map((contact) => contact.id)
      );
      setListingAddressIds(listing.addresses.map((addr) => addr.id));
      setTags(listing.tags);
      setSelectedShop(listing.shop);
      setBooks(listing.books);
      setNotes(listing.notes);
    }
  }, [listing]);

  useEffect(() => {
    if (initialShopId) {
      setSelectedShop({ id: initialShopId });
    }
  }, [initialShopId]);

  useEffect(() => {
    if (selectedShop) {
      // If the a shop is selected, auto-select the default address/contact number
      // only if the shop is associated with the address/contact number.
      const shopAddresses = (selectedShop?.addresses || []).map(
        (addr) => addr.id
      );
      const shopContactNumbers = (selectedShop?.contactNumbers || []).map(
        (contact) => contact.id
      );

      if (initialAddressIds?.length) {
        setListingAddressIds(
          initialAddressIds.filter((addr) => shopAddresses.includes(addr))
        );
      }
      if (initialContactNumberIds?.length) {
        setListingContactNumberIds(
          initialContactNumberIds.filter((contact) =>
            shopContactNumbers.includes(contact)
          )
        );
      }
    } else {
      if (initialAddressIds?.length) {
        setListingAddressIds(initialAddressIds);
      }
      if (initialContactNumberIds?.length) {
        setListingContactNumberIds(initialContactNumberIds);
      }
    }
  }, [selectedShop, initialAddressIds, initialContactNumberIds]);

  useEffect(() => {
    if (groupScope) {
      setShareTargetGroups((groups) => [...groups, groupScope]);
    }
  }, [groupScope]);

  useEffect(() => {
    if (shopScope) {
      setSelectedShop({ id: shopScope });
    }
  }, [shopScope]);

  const dispatchErrorMessage = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });

  const submitListing = () => {
    if (!validateForm()) {
      dispatchErrorMessage("One or more fields have invalid values!");
      return;
    }
    setPageDirty(false);

    // If form submission take more than 1 second, display the banner to convince the
    // user to wait.
    const delayTimer = setTimeout(() => {
      setFormSubmitting(true);
    }, 1000);

    Promise.resolve()
      .then(() =>
        onSubmit({
          listingTitle,
          listingDescription,
          shareTargetGroups,
          price: +priceAmount,
          originalPrice: +originalPriceAmount,
          priceModelType: priceModelType,
          listingAddressIds,
          listingContactNumberIds,
          public: sharePublic,
          tags,
          shopId: selectedShop?.id || null,
          books: books.map(stripBookMetaProps),
          notes,
          savePreferences,
        })
      )
      .finally(() => {
        setFormSubmitting(false);
        clearTimeout(delayTimer);
      });
  };

  const getShopContactNumberIds = () => {
    const contactNumbers = selectedShop?.contactNumbers;
    if (Array.isArray(contactNumbers)) {
      return contactNumbers.map((cNo) => cNo.id);
    }
    return null;
  };

  const getShopAddressIds = () => {
    const addresses = selectedShop?.addresses;
    if (Array.isArray(addresses)) {
      return addresses.map((addr) => addr.id);
    }
    return null;
  };

  const onChangeSavePreferences = () => {
    setSavePreferences(!savePreferences);
  };

  const formSubmittingNotice = (
    <Grid item xs={12} className={classes.formSubmittingNotice}>
      <Grid item>
        <Banner
          align="center"
          message="We are submitting your advertisement with any images you attached. Please wait..."
        />
      </Grid>
    </Grid>
  );

  return (
    <Container maxWidth="xl">
      <Grid container justifyContent="center">
        <Grid item xs={11} sm={10} lg={9} className={classes.title}>
          <Grid container justifyContent="center">
            <PageTitle>{editorTitle}</PageTitle>
          </Grid>
        </Grid>
        <Grid item xs={11} sm={10} lg={9}>
          <Grid
            container
            spacing={8}
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid container item xs={12} lg={8}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} justifyContent="center">
                    <Grid container className={classes.editorSection}>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <PageTitle variant="h5">
                              <Trans>Advertisement Details</Trans>
                            </PageTitle>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography color="textSecondary" variant="body2">
                              <Trans>
                                Provide details about your advertisement. The
                                advertisement title will be displayed in Search
                                Results.
                              </Trans>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              color="textSecondary"
                              variant="body2"
                            ></Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlField className={classes.spannedInput}>
                              <InputLabel
                                shrink
                                color="primary"
                                htmlFor="bootstrap-input"
                              >
                                <Required>
                                  <Trans>Advertisement Title</Trans>
                                </Required>
                              </InputLabel>
                              <BootstrapInputBordered
                                inputProps={{ maxlength: 150 }}
                                value={listingTitle}
                                onChange={handleChangeListingTitle}
                                error={invalidListingTitle}
                                disabled={disabled}
                              />
                              {invalidListingTitle && (
                                <FormHelperText>
                                  <Typography
                                    color="secondary"
                                    variant="caption"
                                  >
                                    <Trans>
                                      Advertisement Title is required
                                    </Trans>
                                  </Typography>
                                </FormHelperText>
                              )}
                            </FormControlField>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlField className={classes.spannedInput}>
                              <InputLabel
                                shrink
                                color="primary"
                                htmlFor="bootstrap-input"
                              >
                                <Trans>Description</Trans>
                              </InputLabel>
                              <BootstrapInputBordered
                                value={listingDescription}
                                multiline
                                rows={5}
                                onChange={handleChangeListingDescription}
                                disabled={disabled}
                                className={classes.descriptionText}
                              />
                              <FormHelperText>
                                <Typography variant="caption">
                                  <Trans>
                                    Tell us more about this advertisement. You
                                    don't need to include details about the
                                    book(s) here. You can do that when you add a
                                    book to this advertisement.
                                  </Trans>
                                </Typography>
                              </FormHelperText>
                            </FormControlField>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <Grid container className={classes.editorSection}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <PageTitle variant="h5">
                                <Trans>Books</Trans>
                              </PageTitle>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography color="textSecondary" variant="body2">
                                <Required>
                                  <Trans>
                                    Add one or more books to include in this
                                    advertisement
                                  </Trans>
                                </Required>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <BookManager
                              books={listing?.books || []}
                              disabled={disabled}
                              onUpdateBooks={handleChangeBooks}
                            />
                          </Grid>
                        </Grid>
                        {invalidBooksCount && (
                          <Grid item xs={12}>
                            <Typography variant="caption" color="secondary">
                              <Trans>You need to add at least one book!</Trans>
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <Grid container className={classes.editorSection}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <PageTitle variant="h5">
                                <Trans>Share In</Trans>
                              </PageTitle>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography color="textSecondary" variant="body2">
                                <Trans>
                                  Select at least one location/group where you
                                  want to display this advertisement in.
                                </Trans>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <GroupSelector
                              sharePublic={sharePublic}
                              targetGroups={shareTargetGroups}
                              isCurrentUserAMember={true}
                              onSelectPublic={handleChangeSharePublic}
                              onAddTargetGroup={handleAddTargetGroup}
                              onRemoveTargetGroup={handleRemoveTargetGroup}
                              disabled={disabled}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} justifyContent="center">
                    <Grid container className={classes.editorSection}>
                      <Grid item xs={12}>
                        <PageTitle variant="h5">
                          <Trans>Tags</Trans>
                        </PageTitle>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlField className={classes.spannedInput}>
                          <BAutoComplete
                            bordered
                            multiple
                            defaultValue={listing?.tags || []}
                            value={tags}
                            options={
                              !!unsavedTag ? tagsRefData?.tags?.tags || [] : []
                            }
                            freeSolo
                            autoSelect
                            loading={loadingTagsRefData}
                            onChange={handleChangeTags}
                            onInputChange={handleSearchTags}
                            placeholder={t`Type and press enter to add a new tag`}
                            getOptionLabel={(option) => option}
                          />
                          <FormHelperText id="my-helper-text">
                            <Trans>
                              Tags are helpful to discover similar books, and
                              design shops' homepages. You can add a tag by
                              typing the tag and pressing enter in the above.
                            </Trans>
                          </FormHelperText>
                        </FormControlField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container className={classes.editorSection}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <PageTitle variant="h5">
                            <Trans>Price</Trans>
                          </PageTitle>
                        </Grid>
                        {priceModelType !== priceModelTypes.EXCHANGE && (
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={6}>
                                <FormControlField
                                  className={classes.spannedInput}
                                >
                                  <InputLabel shrink color="primary">
                                    <Trans>Current Price (Rs.)</Trans>
                                  </InputLabel>
                                  <BootstrapInputBordered
                                    value={priceAmount}
                                    type="number"
                                    inputProps={{
                                      min: 0,
                                    }}
                                    onChange={handleChangePriceAmount}
                                    onFocus={handleFocusPrice}
                                    onBlur={handleBlurPrice}
                                    disabled={disabled}
                                    endAdornment={
                                      priceModelType ===
                                        priceModelTypes.RENT && (
                                        <Typography
                                          className={classes.priceAdornment}
                                          variant="body1"
                                        >
                                          <Trans>/ day</Trans>
                                        </Typography>
                                      )
                                    }
                                  />
                                </FormControlField>
                              </Grid>
                              <Grid item xs={12} lg={6}>
                                <FormControlField
                                  className={classes.spannedInput}
                                >
                                  <InputLabel shrink color="primary">
                                    <Trans>Original Price (Rs.)</Trans>
                                  </InputLabel>
                                  <BootstrapInputBordered
                                    value={originalPriceAmount}
                                    type="number"
                                    inputProps={{
                                      min: 0,
                                    }}
                                    onChange={handleChangeOriginalPriceAmount}
                                    onFocus={handleFocusOriginalPrice}
                                    onBlur={handleBlurOriginalPrice}
                                    disabled={disabled}
                                    endAdornment={
                                      priceModelType ===
                                        priceModelTypes.RENT && (
                                        <Typography
                                          className={classes.priceAdornment}
                                          variant="body1"
                                        >
                                          <Trans>/ day</Trans>
                                        </Typography>
                                      )
                                    }
                                  />
                                  <FormHelperText>
                                    Please provide the original price of the
                                    book, if the current price has a discount
                                    applied
                                  </FormHelperText>
                                </FormControlField>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        <Grid
                          container
                          item
                          xs={12}
                          justifyContent="flex-start"
                        >
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={
                                    priceModelType === priceModelTypes.SALE
                                  }
                                  color="primary"
                                  onClick={handleChangePriceModelType(
                                    priceModelTypes.SALE
                                  )}
                                  disabled={disabled}
                                />
                              }
                              label={
                                <Typography variant="body2">
                                  <Trans>For Sale</Trans>
                                </Typography>
                              }
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={
                                    priceModelType === priceModelTypes.RENT
                                  }
                                  color="primary"
                                  onClick={handleChangePriceModelType(
                                    priceModelTypes.RENT
                                  )}
                                  disabled={disabled}
                                />
                              }
                              label={
                                <Typography variant="body2">
                                  <Trans>For Rent</Trans>
                                </Typography>
                              }
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={
                                    priceModelType === priceModelTypes.EXCHANGE
                                  }
                                  color="primary"
                                  onClick={handleChangePriceModelType(
                                    priceModelTypes.EXCHANGE
                                  )}
                                  disabled={disabled}
                                />
                              }
                              label={
                                <Typography variant="body2">
                                  <Trans>For Exchange</Trans>
                                </Typography>
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container className={classes.editorSection}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <PageTitle variant="h5">
                                  <Trans>Private Notes</Trans>
                                </PageTitle>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  <Trans>
                                    If you need to remember something about this
                                    advertisement, you can write those here as
                                    private notes.{" "}
                                    <b>Only you will be able to see them</b>.
                                  </Trans>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <TextEditor
                              rowsMin={3}
                              placeholder={t`Write your private notes about this advertisement here.`}
                              value={notes}
                              onChange={handleChangeNotes}
                              disabled={formSubmitting}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container className={classes.editorSection}>
                      <Typography variant="body2">
                        <Trans>
                          To maintain the quality and accuracy of the content on
                          bibliocircle, every new advertisement undergoes
                          careful review and curation after submission. As a
                          result, some content of the advertisement might be
                          slightly changed after you submit your advertisement.
                          Please contact us if you'd like to learn more.
                        </Trans>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.createListingBtnContainerDesktop}
              >
                <Grid container justifyContent="center">
                  {formSubmitting && (
                    <Grid container>{formSubmittingNotice}</Grid>
                  )}
                  <Grid item>
                    <Button
                      onClick={submitListing}
                      size="large"
                      variant="contained"
                      color="primary"
                      disableElevation
                      disabled={disabled}
                    >
                      {submitButtonText}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className={classes.sidebar}
              container
              item
              xs={12}
              lg={4}
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container>
                      {loggedInUser && (
                        <ShopSelection
                          onChange={handleChangeSelectedShop}
                          initialSelection={selectedShop}
                          disabled={disabled}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={8} md={6} lg={12}>
                        <Grid container spacing={5}>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  <Trans>Contact Numbers</Trans>
                                </Typography>
                              </Grid>
                              {selectedShop && (
                                <Grid item xs={12}>
                                  <Typography variant="caption">
                                    <Trans>
                                      You can configure your shop's contact
                                      numbers in Shop Settings
                                    </Trans>
                                  </Typography>
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <Grid container>
                                  <PhoneNumberManager
                                    selectable
                                    onChangeSelection={
                                      handleChangeListingContacts
                                    }
                                    selectedNumbers={listingContactNumberIds}
                                    enabledNumbers={getShopContactNumberIds()}
                                    disabled={disabled}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={8} md={6} lg={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              <Trans>Addresses</Trans>
                            </Typography>
                          </Grid>
                          {selectedShop && (
                            <Grid item xs={12}>
                              <Typography variant="caption">
                                <Trans>
                                  You can configure your shop's addresses in
                                  Shop Settings
                                </Trans>
                              </Typography>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <AddressManager
                              selectable
                              onChangeSelection={handleChangeListingAddresses}
                              selectedAddresses={listingAddressIds}
                              enabledAddresses={getShopAddressIds()}
                              disabled={disabled}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            value={savePreferences}
                            onChange={onChangeSavePreferences}
                          />
                        }
                        label={
                          <Typography variant="subtitle2">
                            <Trans>
                              Save these preferences for future advertisements
                            </Trans>
                          </Typography>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              className={classes.createListingBtnContainerMobile}
            >
              {formSubmitting && <Grid container>{formSubmittingNotice}</Grid>}
              <Grid item>
                <Button
                  onClick={submitListing}
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={disabled}
                >
                  {submitButtonText}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

ListingEditor.propTypes = {
  groupScope: PropTypes.string,
  shopScope: PropTypes.string,
  listing: PropTypes.object,
  editorTitle: PropTypes.string,
  submitButtonText: PropTypes.string,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};
