import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { SHOP_PRICING_TIERS } from "../consts";
import PremiumIcon from "../Common/PremiumIcon";

const useStyles = makeStyles((theme) => ({
  premiumFeature: {
    backgroundColor: "#dbd4c8",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  premiumIconContainer: {
    maxWidth: "32px",
  },
  premiumBanner: {
    color: "#ad7632",
  },
  overlayContainer: {
    position: "relative",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#dbd4c8",
    opacity: 0.6,
    zIndex: 10,
  },
}));

const pricingTiersToReadableText = (tiers) => {
  const tiersTexts = tiers.map((tier) => SHOP_PRICING_TIERS[tier]);
  if (tiersTexts.length === "1") return tiersTexts[0];

  const lastTier = tiersTexts.pop();
  return `${tiersTexts.join(", ")} or ${lastTier} pricing plans`;
};

export default function ShopPremiumFeature({
  shopTier,
  featureTiers,
  children,
}) {
  const classes = useStyles();
  if (featureTiers.includes(shopTier)) {
    return children;
  }

  return null;

  /**
   * When we have payments integration, we can uncomment the followings
   * 
    return (
        <Grid container className={classes.premiumFeature}>
        <Grid item xs={12}>
            <Grid container spacing={1}>
            <Grid item xs={12} className={classes.premiumBanner}>
                <Grid container alignItems="center" spacing={1}>
                <Grid item xs={1} className={classes.premiumIconContainer}>
                    <PremiumIcon />
                </Grid>
                <Grid item xs={11}>
                    <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">
                        This is a premium feature.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption">
                        Upgrade to {pricingTiersToReadableText(featureTiers)} to unlock
                        this feature.
                        </Typography>
                    </Grid>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.overlayContainer}>
                <div className={classes.overlay}></div>
                {children}
            </Grid>
            </Grid>
        </Grid>
        </Grid>
    );
   */
}
