import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  makeStyles,
  Container,
  Button,
  InputLabel,
  FormControl,
  withStyles,
  FormHelperText,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import PageTitle from "../../Common/PageTitle";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { actionTypes } from "../../index.reducer";
import { GET_STORY, GET_STORY_CHAPTER } from "../../../consts/queries";
import {
  CREATE_STORY_CHAPTER,
  UPDATE_STORY_CHAPTER,
} from "../../../consts/mutations";
import Required from "../../Common/Required";
import PageHead from "../../Common/PageHead";
import BootstrapInputBordered from "../../Common/BootstrapInputBordered";
import {
  extractFieldError,
  getFormattedError,
  validateObject,
} from "../../../utils/schemaValidator";
import chapterSchema from "./chapter.schema";
import TextEditor from "../../Common/TextEditor";
import {
  useLoader,
  useLoggedInUser,
  usePrompt,
  useRequireLogin,
} from "../../../utils/hooks";
import { t, Trans } from "@lingui/macro";
import LargeStoryCard from "../Story/LargeStoryCard";
import MediumStoryCard from "../Story/MediumStoryCard";

const useStyles = makeStyles((theme) => ({
  editorSection: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(3),
  },
  title: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  textEditor: {
    lineHeight: 2,
  },
  actions: {
    marginTop: theme.spacing(2),
  },
}));

const Label = withStyles((theme) => ({
  root: {
    fontSize: "20px",
    color: theme.palette.grey[700],
  },
}))(InputLabel);

const Field = withStyles({
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
})(FormControl);

export default function ChapterEditor() {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const loggedInUser = useLoggedInUser();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { storyId, chapterId } = useParams();
  const isUpdateOperation = !!chapterId;

  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [published, setPublished] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [isPageDirty, setPageDirty] = useState(false);

  const {
    loading: retrievingChapter,
    error: retrieveChapterError,
    data: chapterData,
  } = useQuery(GET_STORY_CHAPTER, {
    variables: { id: chapterId },
    skip: !isUpdateOperation || !loggedInUser,
  });
  const { data: storyData } = useQuery(GET_STORY, {
    variables: { id: storyId },
  });

  usePrompt("Are you sure you want to leave the page?", isPageDirty);
  useEffect(() => setPageDirty(true), []);
  useRequireLogin();

  const getValidatedChapter = (story) => {
    setValidationErrors([]);
    const { validatedObject, errors } = validateObject(story, chapterSchema);
    if (errors.length) {
      setValidationErrors(errors);
      return false;
    } else {
      return validatedObject;
    }
  };

  const getFieldError = (fieldName) =>
    extractFieldError(validationErrors, fieldName);

  const dispatchErrorMessage = useCallback(
    (message) =>
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message,
      }),
    [dispatch]
  );

  const [createChapter] = useMutation(CREATE_STORY_CHAPTER);

  const [updateChapter, { data: updateChapterResponse }] =
    useMutation(UPDATE_STORY_CHAPTER);
  if (updateChapterResponse?.updateStoryChapter?.id) {
    history.push(
      `/story/${storyId}/chapter/${updateChapterResponse.updateStoryChapter.id}`
    );
  }

  useEffect(() => {
    if (retrieveChapterError) {
      dispatchErrorMessage(
        "Something went wrong while fetching the chapter. Please try again later!"
      );
    }
  }, [dispatchErrorMessage, retrieveChapterError]);

  useLoader(formSubmitting || retrievingChapter);

  useEffect(() => {
    if (chapterData?.storyChapter) {
      const { storyChapter } = chapterData;
      setTitle(storyChapter.title);
      setContent(storyChapter.content);
      setPublished(!!storyChapter.published);
    }
  }, [chapterData]);

  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };
  const handleChangeAbstract = (event) => {
    setContent(event.target.value);
  };

  const createSubmitAction =
    ({ publishChapter } = {}) =>
    () => {
      const rawChapterData = {
        title,
        storyId,
        content,
        published: !!(published || publishChapter),
      };

      const validatedChapter = getValidatedChapter(rawChapterData);
      if (!validatedChapter) {
        dispatchErrorMessage(
          "Some of the fields contain invalid values. Please check again!"
        );
        return;
      }

      setFormSubmitting(true);
      setPageDirty(false);

      if (isUpdateOperation) {
        const { storyId, ...updatedData } = validatedChapter;
        const updatedStoryData = {
          id: chapterId,
          ...updatedData,
        };

        updateChapter({
          variables: {
            storyChapter: updatedStoryData,
          },
        })
          .catch(() => {
            dispatchErrorMessage(
              "Something went wrong while updating the story. Please try again later"
            );
          })
          .finally(() => setFormSubmitting(false));
      } else {
        return createChapter({
          variables: {
            storyChapter: validatedChapter,
          },
        })
          .then(({ data: createChapterResponse }) => {
            if (createChapterResponse?.createStoryChapter?.id) {
              const createdChapter = createChapterResponse.createStoryChapter;
              history.push(`/story/${storyId}/chapter/${createdChapter.id}`, {
                storyReadyToPublish:
                  createdChapter.published &&
                  createdChapter.chapterNumber === 1 && // this is the first chapter
                  createdChapter.story.chaptersCount === 1 && // has only one published chapter
                  !createdChapter.story.published,
              });
            } else {
              dispatchErrorMessage(
                "Something went wrong while creating the story. Please try again later"
              );
            }
          })
          .catch(() => {
            dispatchErrorMessage(
              "Something went wrong while creating the story. Please try again later"
            );
          })
          .finally(() => setFormSubmitting(false));
      }
    };

  const editorTitle = chapterId ? t`Edit Chapter` : t`Write New Chapter`;

  const saveChapterButton = (
    <Grid item>
      <Button
        disableElevation
        size="large"
        variant="contained"
        color="default"
        disabled={formSubmitting}
        onClick={createSubmitAction({ publishChapter: false })}
      >
        {t`Save Chapter`}
      </Button>
    </Grid>
  );

  return (
    <Container maxWidth="xl">
      {isUpdateOperation ? (
        <PageHead title={t`Edit Chapter - ${title} | bibliocircle`} />
      ) : (
        <PageHead title={t`Write New Chapter | bibliocircle`} />
      )}
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.title}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                direction="column"
              >
                <Grid item>
                  <PageTitle>{editorTitle}</PageTitle>
                </Grid>
              </Grid>
            </Grid>
            {storyData?.story && (
              <Grid item xs={12}>
                {!isSmallDevice ? (
                  <LargeStoryCard
                    story={storyData.story}
                    clickable
                    showAuthor={false}
                  />
                ) : (
                  <MediumStoryCard story={storyData.story} />
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container item xs={12} className={classes.editorSection}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field className={classes.spannedInput}>
                      <Label shrink color="primary">
                        <Required>
                          <Trans>Chapter Title</Trans>
                        </Required>
                      </Label>
                      <BootstrapInputBordered
                        error={getFieldError("title")}
                        onChange={handleChangeTitle}
                        value={title}
                        disabled={formSubmitting}
                      />
                      <FormHelperText error hidden={!getFieldError("title")}>
                        {getFormattedError(getFieldError("title"))}
                      </FormHelperText>
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="body1" color="textSecondary">
                          <Required>
                            <Trans>Content</Trans>
                          </Required>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Field className={classes.spannedInput}>
                          <TextEditor
                            rowsMin={15}
                            placeholder={t`Write your story here...`}
                            error={getFieldError("content")}
                            value={content}
                            onChange={handleChangeAbstract}
                            disabled={formSubmitting}
                            className={classes.textEditor}
                          />
                          <FormHelperText
                            error
                            hidden={!getFieldError("content")}
                          >
                            {getFormattedError(getFieldError("content"))}
                          </FormHelperText>
                        </Field>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.actions}>
                <Grid
                  container
                  justifyContent="center"
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item>{saveChapterButton}</Grid>
                  {!published && (
                    <Grid item>
                      <Button
                        disableElevation
                        size="large"
                        variant="contained"
                        color="primary"
                        disabled={formSubmitting}
                        onClick={createSubmitAction({ publishChapter: true })}
                      >
                        <Trans>Save And Publish Chapter</Trans>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
