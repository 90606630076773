import { PLACEHOLDER_IMAGES } from "../../consts";
import { createFeedPostDataExtractor } from "./transformerUtils";

export default function UserVotedDiscussionTransformer(feedPost) {
  const extractor = createFeedPostDataExtractor(feedPost.primaryContent?.data);
  const otherVoteCount = +extractor.get("voteCount") - 1;
  const activityText =
    otherVoteCount > 1
      ? ` and ${otherVoteCount} others liked a discussion`
      : " liked a discussion";

  return {
    actorFullName: feedPost.actor.name,
    actorImage: feedPost.actor.image || PLACEHOLDER_IMAGES.USER,
    actorPath: `/profile/${feedPost.actor.id}`,
    activityText,
    resourcePath: `/bookchat/${extractor.get("discussionId")}`,
    primaryContent: {
      title: extractor.get("discussionTitle"),
      content: extractor.get("discussionContent"),
      images: feedPost.primaryContent?.images || [],
    },
    timestamp: feedPost.activityTimestamp,
  };
}
