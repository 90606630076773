import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import StoriesSearchController from "./StoriesSearchController";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  sectionCard: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(2),
  },
  sectionTitle: {
    fontWeight: "bold",
    fontSize: 28,
  },
}));

export default function AllStories({ noResultsMessage }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.sectionCard}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              color="textPrimary"
              className={classes.sectionTitle}
            >
              <Trans>All Stories</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StoriesSearchController
              xs={12}
              sm={6}
              md={6}
              lg={4}
              noResultsMessage={noResultsMessage}
              searchArgs={{ published: true }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

AllStories.propTypes = {
  title: PropTypes.string.isRequired,
};
