import { languages, priceModelTypes } from "../Application/consts";

export const composeFullName = (firstName, lastName) => {
  return [(firstName || "").trim(), (lastName || "").trim()].join(" ").trim();
};

export const getUserFullName = (user) => {
  return composeFullName(user?.firstName, user?.lastName);
};

export const truncateText = (text, maxLength) => {
  if (!maxLength) return text;
  if (!text || text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}...`;
};

export const isValidEmail = (email) => {
  if (!email) return false;
  return /^[^@]+@[^@.]+\.[^@]+$/.test(email);
};

export const ISBN_REGEX = /^(?:\d{9}[\dXx]|\d{13})$/;

export const isValidISBN = (str) => {
  return typeof str === "string" && ISBN_REGEX.test(str);
};

export const getISBNList = (primaryISBN, alternativeISBNs) => {
  return [...new Set([primaryISBN, ...(alternativeISBNs || [])])].filter(
    isValidISBN
  );
};

export const stripTypename = (entity) => {
  if (!entity) return entity;
  const { __typename, ...normalisedEntity } = entity;
  return normalisedEntity;
};

export const stripBookMetaProps = (book) => {
  // TODO: index property should be removed by BookManager itself
  const { __typename, index, ...bookDetails } = book;
  const authors = (bookDetails.authors || []).map((author) => {
    const { __typename, ...authorDetails } = author;
    return authorDetails;
  });
  return {
    ...bookDetails,
    authors,
  };
};

export const getReadingTime = (text) => {
  try {
    const wordsPerMinute = 200; // Average case.

    let textLength = (text || "").split(" ").length; // Split by words
    if (textLength > 0) {
      let value = Math.ceil(textLength / wordsPerMinute);
      return `${value} min read`;
    }
  } catch {}
  return "";
};

export const serialiseArray = (arr = []) => {
  if (!Array.isArray(arr)) return null;
  return arr.join(",");
};

export const tokeniseCommaSeparatedString = (str = "") => {
  if (str && typeof str === "string") {
    return str
      .split(",")
      .map((token) => token.trim())
      .filter((token) => !!token);
  }
};

export const getLanguageText = (languageCode) => {
  return (
    languages.find(({ value }) => value === languageCode)?.label ||
    "Not Specified"
  );
};

export const addToCartEnabled = (listing) => {
  return (
    listing.active &&
    !listing.shop?.closed &&
    listing.priceModelType === priceModelTypes.SALE
  );
};

export const isAbsoluteUrl = (urlLike) => {
  try {
    if (!!new URL(urlLike).protocol) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

export const getTopLevelDomain = (url) => {
  const { hostname } = new URL(url);
  return hostname.split(".").splice(-2, 2).join(".");
};
