import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import Hyperlink from "../Common/Hyperlink";
import { truncateText } from "../../utils/common";
import DiscussionFooter from "./DiscussionFooter";
import ImageFadeIn from "../Common/ImageFadeIn";
import { Trans } from "@lingui/macro";
import { useLoggedInUser } from "../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      transitionDuration: 800,
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
        transitionDuration: 800,
      },
    },
  },
  discussionMessageText: {
    color: theme.palette.grey[800],
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  readMoreNote: {
    fontWeight: "bold",
  },
  timestamp: {
    fontSize: "12px",
    color: theme.palette.grey[500],
    width: "100%",
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  activity: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
    color: theme.palette.grey[600],
  },
  activityIcon: {
    marginRight: theme.spacing(1),
    fontSize: 16,
  },
  coverImageContainer: {
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    height: "100%",
    borderRadius: theme.spacing(2),
  },
  coverImage: {
    maxWidth: "100%",
    objectFit: "contain",
    maxHeight: "250px",
    borderRadius: theme.shape.borderRadius,
  },
  groupName: {
    color: theme.palette.grey[500],
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
}));

const MAX_TITLE_LENGTH = 200;
const MAX_MESSAGE_LENGTH = 1200;

export default function DiscussionCard({
  descriptionMaxLength,
  discussion,
  showGroupName,
  compact,
}) {
  const classes = useStyles();
  const hasCoverImage = !!discussion.coverImage;
  const isPublic = !discussion.group;
  const loggedInUser = useLoggedInUser();

  const publicLinkPath = `/bookchat/${discussion.id}`;
  const groupLinkPath =
    !isPublic && `/group/${discussion.group.id}${publicLinkPath}`;
  const isOwnDiscussion =
    loggedInUser && loggedInUser.id === discussion.user?.id;

  const coverImageContainerProps = {
    md: compact ? 12 : 3,
    lg: compact ? 12 : 2,
    xs: 12,
  };

  const discussionContentProps = {
    xs: 12,
    md: hasCoverImage && !compact ? 9 : 12,
    lg: hasCoverImage && !compact ? 10 : 12,
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container className={classes.card}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Hyperlink to={isPublic ? publicLinkPath : groupLinkPath}>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          {showGroupName && !!discussion.group && (
                            <Grid item xs={12}>
                              <Grid container>
                                <Hyperlink to={`/group/${discussion.group.id}`}>
                                  <Typography
                                    variant="body2"
                                    className={classes.groupName}
                                  >
                                    {discussion.group.name}
                                  </Typography>
                                </Hyperlink>
                              </Grid>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Grid container spacing={2} direction="row-reverse">
                              {hasCoverImage && (
                                <Grid item {...coverImageContainerProps}>
                                  <Grid
                                    container
                                    className={classes.coverImageContainer}
                                    justifyContent="center"
                                    alignItems="center"
                                    alignContent="center"
                                  >
                                    <ImageFadeIn
                                      src={discussion.coverImage}
                                      alt={discussion.title}
                                      className={classes.coverImage}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                              <Grid item {...discussionContentProps}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" color="primary">
                                      {truncateText(
                                        discussion.title,
                                        MAX_TITLE_LENGTH
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body1"
                                      className={classes.discussionMessageText}
                                    >
                                      {truncateText(
                                        discussion.message,
                                        descriptionMaxLength ||
                                          MAX_MESSAGE_LENGTH
                                      )}
                                    </Typography>
                                  </Grid>
                                  {discussion.message?.length >
                                    (descriptionMaxLength ||
                                      MAX_MESSAGE_LENGTH) && (
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="body1"
                                        color="primary"
                                        className={classes.readMoreNote}
                                      >
                                        <Trans>More to read...</Trans>
                                      </Typography>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Hyperlink>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <DiscussionFooter
                      compact={compact}
                      discussion={discussion}
                      isOwnDiscussion={isOwnDiscussion}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

DiscussionCard.propTypes = {
  discussion: PropTypes.object.isRequired,
  showGroupName: PropTypes.bool,
};
