import React from "react";
import MakeLink from "linkifyjs/react";
import MessageListingPreview from "./MessageListingPreview";

const LISTING_REGEX_STR = `(http|https):\\/\\/[^:]+(:\\d+)?\\/listing\\/([^/]{36})`;
const LISTING_REGEX = new RegExp(LISTING_REGEX_STR, "g");
export default function Linkify({ children }) {
  if (!children) return children;
  const linkifiedString = <MakeLink>{children}</MakeLink>;
  const listingMatches = children.match(LISTING_REGEX);
  if (!listingMatches) {
    return linkifiedString;
  }

  try {
    const parsedListingIds = listingMatches.map(
      (listingUrl) => listingUrl.match(new RegExp(LISTING_REGEX_STR))[3]
    );

    return (
      <MessageListingPreview
        message={linkifiedString}
        listingIds={parsedListingIds}
      />
    );
  } catch (err) {
    return linkifiedString;
  }
}
