import { Trans } from "@lingui/macro";
import { Button } from "@material-ui/core";
import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import { FacebookShareButton as FacebookButton } from "react-share";

export default function FacebookShareButton({ className }) {
  return (
    <FacebookButton
      className={className}
      url={window.location.href}
      hashtag="#bibliocircle"
    >
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disableElevation
        disableRipple
        startIcon={<FacebookIcon />}
      >
        <Trans>Share on Facebook</Trans>
      </Button>
    </FacebookButton>
  );
}
