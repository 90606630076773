import { useLazyQuery } from "@apollo/client";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { GET_BOOK_INSIGHTS } from "../../consts/queries";
import Hyperlink from "../Common/Hyperlink";
import { getISBNList } from "../../utils/common";
import ImageFadeIn from "../Common/ImageFadeIn";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  insights: {
    backgroundColor: theme.palette.primary.extraLighter,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    borderRadius: theme.spacing(2),
  },
  insightLink: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  titleText: {
    marginRight: theme.spacing(0.5),
    fontWeight: "bold",
  },
}));

export default function BookInsights({ book }) {
  const classes = useStyles();
  const [getBookInsights, { error, data }] = useLazyQuery(GET_BOOK_INSIGHTS);
  const isbnStr = getISBNList(book.isbn, book.alternativeISBNs).join(",");

  useEffect(() => {
    if (book?.isbn || Array.isArray(book?.alternativeISBNs)) {
      getBookInsights({
        variables: { isbnList: getISBNList(book.isbn, book.alternativeISBNs) },
      });
    }
  }, [book, getBookInsights]);

  if (error || !data?.bookInsights) return null;

  const {
    bookInsights: {
      critiquesCount,
      criticRecommendationsCount,
      similarListingsCount,
      bookRequestsCount,
      discussionsCount,
    },
  } = data;

  if (
    !critiquesCount &&
    !criticRecommendationsCount &&
    !bookRequestsCount &&
    !discussionsCount &&
    similarListingsCount <= 1 // only this listing contains this book
  ) {
    // No insights to display
    return null;
  }

  const critiquesCountInsight = !!critiquesCount && (
    <Grid item xs={12}>
      <Typography variant="subtitle2" color="primary">
        <li>
          <Hyperlink
            className={classes.insightLink}
            target="_blank"
            to={`/critiques?isbnList=${isbnStr}`}
          >
            <Trans>
              {critiquesCount} Critique{critiquesCount > 1 ? "s" : ""} written
              on this book.
            </Trans>
          </Hyperlink>
        </li>
      </Typography>
    </Grid>
  );

  const recommendationsCountInsight = !!criticRecommendationsCount && (
    <Grid item xs={12}>
      <Typography variant="subtitle2" color="primary">
        <li>
          <Hyperlink
            className={classes.insightLink}
            target="_blank"
            to={`/critiques?isbnList=${isbnStr}&criticRecommends=true`}
          >
            <Trans>
              {criticRecommendationsCount} Critic
              {criticRecommendationsCount > 1 ? "s" : ""} recommended this book.
            </Trans>
          </Hyperlink>
        </li>
      </Typography>
    </Grid>
  );

  const similarListingsCountInsight = !!similarListingsCount && (
    <Grid item xs={12}>
      <Typography variant="subtitle2" color="primary">
        <li>
          <Hyperlink
            className={classes.insightLink}
            target="_blank"
            to={`/listings?isbnList=${isbnStr}`}
          >
            {t`Available in ${similarListingsCount} Ad${
              similarListingsCount > 1 ? "s" : ""
            }.`}
          </Hyperlink>
        </li>
      </Typography>
    </Grid>
  );

  const bookRequestsCountInsight = !!bookRequestsCount && (
    <Grid item xs={12}>
      <Typography variant="subtitle2" color="primary">
        <li>
          <Hyperlink
            className={classes.insightLink}
            target="_blank"
            to={`/book-requests?isbnList=${isbnStr}`}
          >
            <Trans>
              {bookRequestsCount} Request{bookRequestsCount > 1 ? "s" : ""} for
              this book in Book Requests.
            </Trans>
          </Hyperlink>
        </li>
      </Typography>
    </Grid>
  );

  const discussionsCountInsight = !!discussionsCount && (
    <Grid item xs={12}>
      <Typography variant="subtitle2" color="primary">
        <li>
          <Hyperlink
            className={classes.insightLink}
            target="_blank"
            to={`/bookchats?isbnList=${isbnStr}`}
          >
            <Trans>
              {discussionsCount} Discussion{discussionsCount > 1 ? "s" : ""} on
              this book in BookChat.
            </Trans>
          </Hyperlink>
        </li>
      </Typography>
    </Grid>
  );

  return (
    <Grid container className={classes.insights}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item>
                <Grid container alignItems="center">
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    className={classes.titleText}
                  >
                    <Trans>About this book, from</Trans>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <ImageFadeIn
                    src="/logo/logo_insights.svg"
                    height={32}
                    alt={book.isbn}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {critiquesCountInsight}
              {recommendationsCountInsight}
              {similarListingsCountInsight}
              {bookRequestsCountInsight}
              {discussionsCountInsight}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
