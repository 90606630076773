import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Button, Typography } from "@material-ui/core";
import ShopSection from "./ShopSection";
import AddIcon from "@material-ui/icons/AddCircle";
import SectionEditorDialog from "./SectionEditorDialog";
import { useMutation } from "@apollo/client";
import {
  CREATE_SHOP_SECTION,
  UPDATE_SHOP_SECTION,
  DELETE_SHOP_SECTION,
  UPDATE_SHOP,
} from "../../consts/mutations";
import { useDispatch } from "react-redux";
import { GET_SHOP } from "../../consts/queries";
import { useLoader, useScrollTop } from "../../utils/hooks";
import { useHistory } from "react-router-dom";
import { actionTypes } from "../index.reducer";
import { GENERIC_ALERT_ERROR_MESSAGE } from "../../config";
import { SwapVert } from "@material-ui/icons";
import SectionReorderDialog from "./SectionReorderDialog";
import ShopSearch from "./ShopSearch";

const useStyles = makeStyles((theme) => ({
  addSectionBar: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1),
    borderColor: theme.palette.secondary.light,
    border: "1px dashed",
    borderRadius: theme.spacing(2),
  },
  addSectionButton: {
    borderRadius: theme.spacing(2),
  },
  booksSVG: {
    marginTop: theme.spacing(1),
    width: "50px",
  },
  sectionsHelperPanel: {
    marginBottom: theme.spacing(2),
  },
  actionBar: {
    marginBottom: theme.spacing(2),
  },
  allBooksTitle: {
    padding: theme.spacing(1),
    width: "100%",
    textAlign: "center",
  },
  allBooksTitleContainer: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary.lighter,
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
  },
  allBooksContent: {
    padding: theme.spacing(3),
  },
}));

export default function ShopHomePageContent({ shop, viewAsAdmin }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const sectionsList = shop.sections || [];
  const [showSectionEditor, setShowSectionEditor] = useState(false);
  const [showSectionsReorderEditor, setShowSectionsReorderEditor] =
    useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [createSection] = useMutation(CREATE_SHOP_SECTION);
  const [updateSection] = useMutation(UPDATE_SHOP_SECTION);
  const [deleteSection] = useMutation(DELETE_SHOP_SECTION);
  const [updateShop] = useMutation(UPDATE_SHOP);
  useLoader(actionInProgress);
  useScrollTop();

  const onClickAddSection = () => setShowSectionEditor(true);
  const onCloseAddSection = () => {
    setShowSectionEditor(false);
    setCurrentSection(null);
  };

  const onClickReorderSections = () => setShowSectionsReorderEditor(true);
  const onCloseReorderSections = () => {
    setShowSectionsReorderEditor(false);
  };

  const dispatchErrorMessage = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });

  const onSaveSection = async (draftSection) => {
    setActionInProgress(true);
    try {
      if (draftSection.id) {
        await updateSection({
          variables: { section: draftSection, shopId: shop.id },
        });
      } else {
        await createSection({
          variables: { section: draftSection, shopId: shop.id },
          // TODO: This refetch shouldn't be necessary because the apollo client updates the cache from the createSection mutation response.
          // added this refetch because the UI is still not updated after cache update: https://github.com/deepal/unu-web/issues/6
          refetchQueries: [
            { query: GET_SHOP, variables: { identifier: shop.identifier } },
          ],
        });
      }
    } catch (err) {
      dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
    }
    setActionInProgress(false);
    onCloseAddSection();
  };

  const onSaveReorderedSections = async (updatedSections) => {
    setActionInProgress(true);
    try {
      await updateShop({
        variables: {
          shop: {
            id: shop.id,
            sections: updatedSections,
          },
        },
      });
    } catch (err) {
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message: GENERIC_ALERT_ERROR_MESSAGE,
      });
    }
    setShowSectionsReorderEditor(false);
    setActionInProgress(false);
  };

  const onDeleteSection = async (sectionId) => {
    deleteSection({
      variables: { shopId: shop.id, sectionId },
    }).catch(() => {
      dispatchErrorMessage(GENERIC_ALERT_ERROR_MESSAGE);
    });
  };

  const onClickEditSection = (section) => () => {
    setCurrentSection(section);
    setShowSectionEditor(true);
  };

  const addSectionComponent = (
    <Grid
      item
      xs={12}
      className={classes.addSectionBar}
      justifyContent="center"
    >
      <Button
        fullWidth
        startIcon={<AddIcon />}
        color="secondary"
        variant="text"
        className={classes.addSectionButton}
        onClick={onClickAddSection}
      >
        Add Section
      </Button>
      <SectionEditorDialog
        open={showSectionEditor}
        onClose={onCloseAddSection}
        onSave={onSaveSection}
        onDelete={onDeleteSection}
        section={currentSection}
        disabled={actionInProgress}
      />
    </Grid>
  );

  const reorderSectionsComponent = (
    <Grid item>
      <Button
        variant="text"
        color="secondary"
        startIcon={<SwapVert />}
        disableElevation
        disableRipple
        onClick={onClickReorderSections}
        disabled={sectionsList.length < 2}
      >
        Reorder Sections
      </Button>
      <SectionReorderDialog
        open={showSectionsReorderEditor}
        onClose={onCloseReorderSections}
        onSave={onSaveReorderedSections}
        sections={sectionsList}
      />
    </Grid>
  );

  const editSectionComponent = (
    <Grid item xs={12}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.actionBar}
      >
        <Grid item xs={6} md={8} lg={9}>
          {addSectionComponent}
        </Grid>
        <Grid item>{reorderSectionsComponent}</Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          {viewAsAdmin && editSectionComponent}
          <Grid item xs={12} key={history.location.key}>
            {sectionsList.map((section) => (
              <Grid container item xs={12}>
                <ShopSection
                  viewAsAdmin={viewAsAdmin}
                  key={section.id}
                  section={section}
                  shop={shop}
                  onClickEdit={onClickEditSection(section)}
                />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container className={classes.allBooksTitleContainer}>
                  <Typography variant="h6" className={classes.allBooksTitle}>
                    All Books
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.allBooksContent}>
                  <ShopSearch shop={shop} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ShopHomePageContent.propTypes = {
  shop: PropTypes.object,
};
