import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  Button,
  Grid,
  FormHelperText,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  useTheme,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import FormControlField from "../Common/FormControlField";
import BootstrapInputBordered from "../Common/BootstrapInputBordered";
import {
  validateObject,
  getFormattedError,
  extractFieldError,
  excludeFieldError,
} from "../../utils/schemaValidator";
import shopSectionSchema from "./shopSection.schema";
import InputLabel from "../Common/InputLabel";
import ColorPicker from "../Common/ColorPicker";
import Required from "../Common/Required";
import { useLazyQuery } from "@apollo/client";
import { GET_TAGS_REF_DATA } from "../../consts/queries";
import { TAGS_REF_DATA_BULK_LIMIT } from "../../config";
import BAutoComplete from "../Common/BAutoComplete";

const useStyles = makeStyles((theme) => ({
  deleteSection: {
    marginTop: theme.spacing(2),
  },
  sectionsHelperTextContainer: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.lighter,
  },
}));

const DEFAULT_FEATURED_COUNT = 8;
const INITIAL_SECTION = { featuredCount: DEFAULT_FEATURED_COUNT };

export default function SectionEditorDialog({
  open,
  onClose,
  onSave,
  onDelete,
  section,
  disabled,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [currentSection, setCurrentSection] = useState(INITIAL_SECTION);
  const [validationErrors, setValidationErrors] = useState([]);
  const [tags, setTags] = useState([]);
  const [unsavedTag, setUnsavedTag] = useState(null);

  const getFieldError = (fieldName) =>
    extractFieldError(validationErrors, fieldName);
  const handleDeleteSection = () => {
    onDelete(currentSection.id);
    onClose();
  };

  const changeTags = useCallback(
    (tags) => {
      setUnsavedTag(null);
      setTags(tags);
      if (tags.length > 0) {
        setValidationErrors(excludeFieldError(validationErrors, "tags"));
      }
    },
    [validationErrors]
  );

  const handleChangeTags = (event, tags) => changeTags(tags);
  const handleSearchTags = (event) => {
    const tagDraft = event?.target?.value;
    setUnsavedTag(tagDraft);
    if (!tagDraft) return;
    getTagsRefData({
      variables: {
        query: tagDraft,
        limit: TAGS_REF_DATA_BULK_LIMIT,
      },
    });
  };

  const [getTagsRefData, { loading: loadingTagsRefData, data: tagsRefData }] =
    useLazyQuery(GET_TAGS_REF_DATA);

  useEffect(() => {
    if (section?.id !== currentSection?.id) {
      setCurrentSection({
        ...section,
      });
      changeTags(section?.tags || []);
    }
  }, [section, changeTags, currentSection]);

  const createTextFieldUpdater = (fieldName) => (event) => {
    setCurrentSection({ ...currentSection, [fieldName]: event.target.value });
    setValidationErrors(excludeFieldError(validationErrors, fieldName));
  };

  const onChangeTitle = createTextFieldUpdater("title");
  const onChangeFeaturedCount = createTextFieldUpdater("featuredCount");
  const onChangeTitleBackgroundColor = (color) => {
    setCurrentSection({ ...currentSection, titleBackgroundColor: color });
  };
  const onChangeContentBackgroundColor = (color) => {
    setCurrentSection({
      ...currentSection,
      contentBackgroundColor: color,
    });
  };
  const clearForm = () => {
    setCurrentSection(INITIAL_SECTION);
    setTags([]);
    setUnsavedTag("");
  };
  const discardAndClose = () => {
    onClose();
    clearForm();
  };

  const onSaveSection = () => {
    const formattedSection = {
      id: currentSection.id,
      title: currentSection.title,
      tags,
      featuredCount: +currentSection.featuredCount || DEFAULT_FEATURED_COUNT,
      titleBackgroundColor: currentSection.titleBackgroundColor,
      contentBackgroundColor: currentSection.contentBackgroundColor,
    };

    const { validatedObject, isValid, errors } = validateObject(
      formattedSection,
      shopSectionSchema
    );
    if (isValid) {
      onSave(validatedObject).then(clearForm);
    } else {
      setValidationErrors(errors);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      disableScrollLock={true}
      maxWidth="lg"
    >
      <DialogTitle>{section ? "Edit Section" : "Add Section"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="row-reverse">
          <Grid item xs={12} lg={6}>
            <Grid container className={classes.sectionsHelperTextContainer}>
              <Typography variant="body1">
                Section is a grouped set of advertisements in your home page.
                You can categorize advertisements into sections using the 'Tags'
                you provide when you create advertisements.
              </Typography>
              <ul>
                <li className={classes.tabHelperTextListItem}>
                  <Typography variant="body1">
                    <b>Section Title</b>: Title to be displayed at the top of
                    the section.
                  </Typography>
                </li>
                <li className={classes.tabHelperTextListItem}>
                  <Typography variant="body1">
                    <b>Advertisement Tags</b>: A list of advertisement tags to
                    match. You can add these tags to the advertisements that you
                    need to display under this tab.
                  </Typography>
                </li>
                <li className={classes.tabHelperTextListItem}>
                  <Typography variant="body1">
                    <b>Featured Items Count</b>: Maximum number of
                    advertisements displayed in this section.
                  </Typography>
                </li>
                <li className={classes.tabHelperTextListItem}>
                  <Typography variant="body1">
                    <b>Heading Background Color</b>: Background color of the
                    heading of the section.
                  </Typography>
                </li>
                <li className={classes.tabHelperTextListItem}>
                  <Typography variant="body1">
                    <b>Section Body Background Color</b>: Background color of
                    the section body.
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControlField>
                  <InputLabel shrink color="primary">
                    <Required>Section Title</Required>
                  </InputLabel>
                  <Tooltip title="Provide an eye-catching title for this section.">
                    <BootstrapInputBordered
                      error={getFieldError("title")}
                      value={currentSection?.title}
                      onChange={onChangeTitle}
                      disabled={disabled}
                    />
                  </Tooltip>
                  <FormHelperText
                    error={getFieldError("title")}
                    hidden={!getFieldError("title")}
                  >
                    {getFormattedError(getFieldError("title"))}
                  </FormHelperText>
                </FormControlField>
              </Grid>
              <Grid item xs={12}>
                <FormControlField>
                  <InputLabel shrink color="primary">
                    <Required>Advertisement Tags</Required>
                  </InputLabel>
                  <BAutoComplete
                    bordered
                    multiple
                    error={getFieldError("tags")}
                    disabled={disabled}
                    defaultValue={section?.tags || []}
                    value={tags}
                    options={!!unsavedTag ? tagsRefData?.tags?.tags || [] : []}
                    freeSolo
                    autoSelect
                    loading={loadingTagsRefData}
                    onChange={handleChangeTags}
                    onInputChange={handleSearchTags}
                    placeholder="Type and press enter to add a new tag"
                    getOptionLabel={(option) => option}
                  />
                  <FormHelperText hidden={getFieldError("tags")}>
                    Any advertisements in your shop that have one or more of
                    these tags will be displayed in this section.
                  </FormHelperText>
                  <FormHelperText
                    error={getFieldError("tags")}
                    hidden={!getFieldError("tags")}
                  >
                    {getFormattedError(getFieldError("title"))}
                  </FormHelperText>
                </FormControlField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControlField>
                  <InputLabel shrink color="primary">
                    <Required>Featured Items Count</Required>
                  </InputLabel>
                  <Tooltip title="Maximum number of items to be displayed under this section on the home page.">
                    <BootstrapInputBordered
                      type="number"
                      error={getFieldError("featuredCount")}
                      value={currentSection?.featuredCount}
                      onChange={onChangeFeaturedCount}
                      disabled={disabled}
                    />
                  </Tooltip>
                  <FormHelperText
                    error={getFieldError("featuredCount")}
                    hidden={!getFieldError("featuredCount")}
                  >
                    This field has to be a positive number
                  </FormHelperText>
                </FormControlField>
              </Grid>
              <Grid item xs={12}>
                <Box p={1}>
                  <Grid container item xs={12} spacing={4}>
                    <Grid container item xs={12}>
                      <ColorPicker
                        color={
                          currentSection.titleBackgroundColor ||
                          theme.palette.primary.lighter
                        }
                        title="Heading Background Color"
                        onChange={onChangeTitleBackgroundColor}
                      />
                    </Grid>
                    <Grid container item xs={12}>
                      <ColorPicker
                        color={
                          currentSection.contentBackgroundColor || "transparent"
                        }
                        title="Section Body Background Color"
                        onChange={onChangeContentBackgroundColor}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {!!section && (
                <Grid item xs={12} className={classes.deleteSection}>
                  <Button
                    onClick={handleDeleteSection}
                    color="secondary"
                    variant="contained"
                    size="small"
                    disableElevation
                    disabled={disabled}
                  >
                    Delete this section!
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={discardAndClose} color="primary" disabled={disabled}>
          Discard and Close
        </Button>
        <Button
          onClick={onSaveSection}
          color="primary"
          variant="contained"
          disableElevation
          disabled={disabled}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SectionEditorDialog.propTypes = {
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  section: PropTypes.object,
};

SectionEditorDialog.defaultProps = {
  onClose() {},
  onSave() {},
};
