import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  InputAdornment,
  IconButton,
  Paper,
  Button,
  Container,
} from "@material-ui/core";
import PageTitle from "../Common/PageTitle";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import BootstrapInput from "../Common/BootstrapInput";
import CritiquesSearchController from "./CritiquesSearchController";
import Hyperlink from "../Common/Hyperlink";
import PageHead from "../Common/PageHead";
import CritiqueIcon from "./CritiqueIcon";
import {
  useLoginPrompt,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import ImageFadeIn from "../Common/ImageFadeIn";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  critiqueDiscoverer: {
    marginTop: theme.spacing(3),
  },
  logo: {
    maxHeight: theme.spacing(6) * 2,
  },
  pageTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  critiqueSearchBox: {
    width: "100%",
  },
  critiqueSearchBoxInput: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    "&::placeholder": {
      color: theme.palette.primary.main,
    },
  },
  critiqueSearchContainer: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Critiques() {
  const classes = useStyles();
  const history = useHistory();
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const queryParams = useQueryParams();
  const [searchQuery, setSearchQuery] = useState("");

  useScrollTop([history.location.key]);

  const onKeyDown = (event) => {
    if (event.keyCode === 13) searchCritiques();
  };

  const searchCritiques = () => {
    const queryParams = {};
    if (searchQuery) queryParams.q = searchQuery;
    const queryString = new URLSearchParams(queryParams).toString();
    history.push(`/critiques?${queryString}`);
  };
  const onChangeSearchQuery = (event) => setSearchQuery(event.target.value);
  const onClickCreateCritique = createLoginPromptEventHandler({
    redirectPath: "/critique/new",
  });

  const critiquesSearchBar = (
    <Grid
      container
      justifyContent="center"
      className={classes.critiqueSearchContainer}
    >
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={9}>
            <Paper>
              <BootstrapInput
                name="q"
                className={classes.critiqueSearchBox}
                defaultValue={queryParams.get("q")}
                placeholder={t`Search critiques`}
                onChange={onChangeSearchQuery}
                onKeyDown={onKeyDown}
                endAdornment={
                  <InputAdornment>
                    <IconButton
                      color="primary"
                      variant="contained"
                      onClick={searchCritiques}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              ></BootstrapInput>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const searchArgs = {
    query: queryParams.get("q"),
    isbn: queryParams.get("isbn"),
    published: true,
  };
  const isbnList = (queryParams.get("isbnList") || "")
    .split(",")
    .map((isbn) => isbn.trim())
    .filter((isbn) => !!isbn);
  if (isbnList.length) searchArgs.isbnList = isbnList;

  if (queryParams.get("criticRecommends")) {
    searchArgs.criticRecommends =
      queryParams.get("criticRecommends") === "true";
  }

  return (
    <>
      <PageHead title="Critiques | bibliocircle" />
      <Container maxWidth="xl">
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          className={classes.critiqueDiscoverer}
        >
          <Grid container item xs={12} lg={10} spacing={2}>
            <Grid container item xs={12} className={classes.pageTitle}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                direction="column"
              >
                <Grid item>
                  <Grid container justifyContent="center">
                    <ImageFadeIn
                      src="/logo/logo_critiques.svg"
                      alt="Discover Critiques"
                      className={classes.logo}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <PageTitle variant="subtitle1" align="center">
                    <Trans>
                      Express your thoughts about the books you've read!
                    </Trans>
                  </PageTitle>
                </Grid>
                <Grid item>
                  <Button
                    onClick={onClickCreateCritique}
                    component={Hyperlink}
                    to="/critique/new"
                    startIcon={<CritiqueIcon />}
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    <Trans>Write A Critique</Trans>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              {critiquesSearchBar}
            </Grid>
            <Grid container item xs={12}>
              <CritiquesSearchController
                key={history.location.key}
                xs={12}
                md={6}
                lg={6}
                xl={6}
                fetchPolicy="cache-and-network"
                searchArgs={searchArgs}
                noResultsMessage="No critiques found!"
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
