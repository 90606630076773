import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { t } from "@lingui/macro";
import GroupsSearchController from "../../Group/GroupsSearchController";

export default function UserOwnGroupsPage({ searchQuery }) {
  return (
    <Grid container>
      <GroupsSearchController
        xs={12}
        md={6}
        searchArgs={{
          query: searchQuery,
          isCurrentUserOwner: true,
        }}
        noResultsMessage={t`No groups found!`}
      />
    </Grid>
  );
}

UserOwnGroupsPage.propTypes = {
  searchQuery: PropTypes.string,
};
