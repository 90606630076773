import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  FormHelperText,
  makeStyles,
  Typography,
  Container,
} from "@material-ui/core";
import DateUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import BootstrapInputBordered from "../Common/BootstrapInputBordered";
import InputLabel from "../Common/InputLabel";
import FormControlField from "../Common/FormControlField";
import DropDown from "../Common/DropDown";
import { languages, buyingPriceModelTypes, priceModelTypes } from "../consts";
import PhoneNumberManager from "../Common/PhoneNumbers/PhoneNumberManager";
import DatePicker from "../Common/DatePicker";
import dayjs from "dayjs";
import Required from "../Common/Required";
import GroupSelector from "../Group/GroupSelector";
import PageTitle from "../Common/PageTitle";
import BAutoComplete from "../Common/BAutoComplete";
import { useLazyQuery } from "@apollo/client";
import {
  GET_AUTHORS_REF_DATA,
  GET_PUBLISHERS_REF_DATA,
} from "../../consts/queries";
import {
  AUTHORS_REF_DATA_BULK_LIMIT,
  PUBLISHERS_REF_DATA_BULK_LIMIT,
} from "../../config";
import {
  extractFieldError,
  getFormattedError,
  validateObject,
} from "../../utils/schemaValidator";
import bookRequestSchema from "./bookRequest.schema";
import { useDispatch } from "react-redux";
import { actionTypes } from "../index.reducer";
import { useRequireLogin, useScrollTop } from "../../utils/hooks";
import { t, Trans } from "@lingui/macro";

const MAX_EXPIRY = dayjs().add(90, "day").toDate();

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    padding: theme.spacing(2),
  },
  contactNumbersSection: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  title: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sidebar: {
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      borderRadius: theme.spacing(2),
    },
  },
  groupSelector: {
    [theme.breakpoints.up("sm")]: {
      maxHeight: "45vh",
      overflow: "auto",
    },
  },
}));

export default function BookRequestEditor({
  bookRequest,
  groupScope,
  editorTitle,
  submitButtonText,
  onSubmit,
  disabled,
}) {
  useRequireLogin();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [title, setTitle] = useState(null);
  const [authors, setAuthors] = useState([]);
  const [publisher, setPublisher] = useState(null);
  const [isbn, setISBN] = useState(null);
  const [alternativeISBNs, setAlternativeISBNs] = useState(null);
  const [year, setYear] = useState(null);
  const [language, setLanguage] = useState(null);
  const [expiresAt, setExpiresAt] = useState(MAX_EXPIRY);
  const [message, setMessage] = useState(null);
  const [booksCount, setBooksCount] = useState(1);
  const [priceModelType, setPriceModelType] = useState(priceModelTypes.SALE);
  const [contactNumberIds, setContactNumberIds] = useState([]);
  const [groupIds, setGroupIds] = useState([]);
  const [sharePublic, setSharePublic] = useState(true);
  const [unsavedAuthor, setUnsavedAuthor] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  useScrollTop();

  const getValidatedBookRequest = (bookRequest) => {
    setValidationErrors([]);
    const { validatedObject, errors } = validateObject(
      bookRequest,
      bookRequestSchema
    );
    if (errors.length) {
      setValidationErrors(errors);
      return false;
    } else {
      return validatedObject;
    }
  };

  const getFieldError = (fieldName) =>
    extractFieldError(validationErrors, fieldName);

  const formatISBNArray = (isbnStr) => {
    return (isbnStr || "")
      .split(",")
      .map((isbn) => isbn.trim())
      .filter((isbn) => !!isbn);
  };

  const serialiseAlternativeISBNs = (isbnArr) => (isbnArr || []).join(", ");
  useEffect(() => {
    if (bookRequest) {
      setTitle(bookRequest.title);
      setAuthors(bookRequest.authors.map((author) => author.name));
      setPublisher(bookRequest.publisher);
      setISBN(bookRequest.isbn);
      setAlternativeISBNs(
        serialiseAlternativeISBNs(bookRequest.alternativeISBNs)
      );
      setYear(bookRequest.year);
      setLanguage(bookRequest.language);
      setMessage(bookRequest.message);
      setBooksCount(bookRequest.count);
      setPriceModelType(bookRequest.priceModelType);
      setContactNumberIds(bookRequest.contactNumbers.map((cNo) => cNo.id));
      setGroupIds(bookRequest.groups.map((group) => group.id));
      setSharePublic(bookRequest.public);
    }
  }, [bookRequest]);

  useEffect(() => {
    if (groupScope) {
      setGroupIds((groups) => [...groups, groupScope]);
    }
  }, [groupScope]);

  const [
    getAuthorsRefData,
    { loading: loadingAuthorsRefData, data: authorsRefData },
  ] = useLazyQuery(GET_AUTHORS_REF_DATA);

  const [
    getPublishersRefData,
    { loading: loadingPublishersRefData, data: publishersRefData },
  ] = useLazyQuery(GET_PUBLISHERS_REF_DATA);

  const handleSearchAuthors = (event) => {
    const authorDraft = event?.target?.value;
    setUnsavedAuthor(authorDraft);
    if (!authorDraft) return;
    getAuthorsRefData({
      variables: {
        query: authorDraft,
        limit: AUTHORS_REF_DATA_BULK_LIMIT,
      },
    });
  };

  const handleSearchPublishers = (event) => {
    if (!event?.target?.value) return;
    getPublishersRefData({
      variables: {
        query: event.target.value,
        limit: PUBLISHERS_REF_DATA_BULK_LIMIT,
      },
    });
  };

  const dispatchErrorMessage = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });

  const onChangeTitle = (event) => setTitle(event.target.value);
  const onChangeAuthors = (event, newAuthors) => {
    setUnsavedAuthor(null);
    if (!Array.isArray(newAuthors)) return;
    setAuthors(newAuthors);
  };
  const onChangePublisher = (event, newValue) => setPublisher(newValue);
  const onChangeISBN = (event) => setISBN(event.target.value);
  const onChangeAlternativeISBNs = (event) =>
    setAlternativeISBNs(event.target.value);
  const onChangeYear = (event) => {
    if (!!event.target.value && !/^[12][0-9]{0,3}$/.test(event.target.value)) {
      return;
    }
    setYear(event.target.value);
  };
  const onChangeLanguage = (event) => setLanguage(event.target.value);
  const onChangeMessage = (event) => setMessage(event.target.value);
  const onChangeBooksCount = (event) => {
    if (!/^[0-9]*$/.test(event.target.value)) return;
    setBooksCount(event.target.value);
  };
  const onFocusBooksCount = () => {
    if (booksCount === 0) setBooksCount("");
  };
  const onBlurBooksCount = () => {
    if (!booksCount) setBooksCount(0);
  };
  const onChangePriceModelType = (event) =>
    setPriceModelType(event.target.value);
  const onChangeExpiresAt = (date) => setExpiresAt(date);
  const onChangeContactNumberIds = (contactNumberId, selected) => {
    if (selected) {
      setContactNumberIds((cIds) =>
        Array.from(new Set([...cIds, contactNumberId]))
      );
    } else {
      setContactNumberIds((cIds) =>
        cIds.filter((cId) => cId !== contactNumberId)
      );
    }
  };
  const onChangeSharePublic = (sharePublic) => setSharePublic(sharePublic);
  const onAddTargetGroup = (groupId) =>
    setGroupIds((groupIds) => Array.from(new Set([...groupIds, groupId])));
  const onRemoveTargetGroup = (groupId) =>
    setGroupIds((groupIds) => groupIds.filter((gId) => gId !== groupId));

  const handleClickSubmit = () => {
    const bookRequest = {
      title,
      authors: authors.map((authorName) => ({ name: authorName })),
      publisher,
      language,
      priceModelType,
      year: +year,
      isbn,
      alternativeISBNs: formatISBNArray(alternativeISBNs),
      count: +booksCount,
      message,
      expiresAt,
      contactNumberIds,
      groupIds,
      public: sharePublic,
    };
    const validatedBookRequest = getValidatedBookRequest(bookRequest);
    if (!validatedBookRequest) {
      dispatchErrorMessage(
        "Some of the fields contain invalid values. Please check again!"
      );
      return;
    }

    onSubmit(validatedBookRequest);
  };

  return (
    <Container maxWidth="xl">
      <Grid container justifyContent="center">
        <Grid item xs={11} sm={10} lg={9} className={classes.title}>
          <Grid container justifyContent="center">
            <PageTitle>{editorTitle}</PageTitle>
          </Grid>
        </Grid>
        <Grid item xs={11} sm={10} lg={10}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1">
                <Trans>
                  Please fill the following information about the book you are
                  looking for, so that others can reach out to you if they have
                  the book and would like to offer it. Also, we will send you a
                  notification as soon as someone posts this book in any of your
                  groups or in public.
                </Trans>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12}>
                          <FormControlField>
                            <InputLabel shrink color="primary">
                              <Required>
                                <Trans>Book Title</Trans>
                              </Required>
                            </InputLabel>
                            <BootstrapInputBordered
                              error={getFieldError("title")}
                              placeholder={t`Title of the book you are looking for`}
                              onChange={onChangeTitle}
                              value={title}
                              disabled={disabled}
                            />
                            <FormHelperText
                              error
                              hidden={!getFieldError("title")}
                            >
                              {getFormattedError(getFieldError("title"))}
                            </FormHelperText>
                          </FormControlField>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlField className={classes.spannedInput}>
                            <InputLabel
                              shrink
                              color="primary"
                              htmlFor="bootstrap-input"
                            >
                              <Trans>Authors</Trans>
                            </InputLabel>
                            <BAutoComplete
                              disabled={disabled}
                              bordered
                              multiple
                              value={authors}
                              options={
                                !!unsavedAuthor
                                  ? authorsRefData?.authors?.authors || []
                                  : []
                              }
                              freeSolo
                              autoSelect
                              loading={loadingAuthorsRefData}
                              onChange={onChangeAuthors}
                              onInputChange={handleSearchAuthors}
                              placeholder={t`Type and press enter to add a new author`}
                              getOptionLabel={(option) => option}
                            />
                          </FormControlField>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlField className={classes.spannedInput}>
                            <InputLabel
                              shrink
                              color="primary"
                              htmlFor="pub-auto-complete"
                            >
                              <Trans>Publisher</Trans>
                            </InputLabel>
                            <BAutoComplete
                              disabled={disabled}
                              bordered
                              value={publisher}
                              options={
                                publishersRefData?.publishers?.publishers || []
                              }
                              freeSolo
                              autoSelect
                              loading={loadingPublishersRefData}
                              onChange={onChangePublisher}
                              onInputChange={handleSearchPublishers}
                              getOptionLabel={(option) => option}
                            />
                          </FormControlField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <FormControlField>
                            <InputLabel shrink color="primary">
                              <Trans>ISBN</Trans>
                            </InputLabel>
                            <BootstrapInputBordered
                              error={getFieldError("isbn")}
                              placeholder={t`e.g, 9876543212345`}
                              onChange={onChangeISBN}
                              value={isbn}
                              disabled={disabled}
                            />
                            <FormHelperText
                              error
                              hidden={!getFieldError("isbn")}
                            >
                              <Trans>This field requires a valid ISBN</Trans>
                            </FormHelperText>
                          </FormControlField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControlField>
                            <InputLabel shrink color="primary">
                              <Trans>Year</Trans>
                            </InputLabel>
                            <BootstrapInputBordered
                              type="number"
                              onChange={onChangeYear}
                              value={year}
                              disabled={disabled}
                            />
                          </FormControlField>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormControlField className={classes.spannedInput}>
                            <InputLabel shrink color="primary">
                              <Trans>Alternative ISBN Numbers</Trans>
                            </InputLabel>
                            <BootstrapInputBordered
                              value={alternativeISBNs}
                              type="string"
                              error={getFieldError("alternativeISBNs")}
                              onChange={onChangeAlternativeISBNs}
                              disabled={disabled}
                            />
                            <FormHelperText id="my-helper-text">
                              <Trans>
                                Please provide any additional ISBN numbers
                                (depending on variation/edition) separated by
                                commas
                              </Trans>
                            </FormHelperText>
                            <FormHelperText
                              error
                              hidden={!getFieldError("alternativeISBNs")}
                            >
                              <Trans>
                                This field contains one or more invalid ISBNs
                              </Trans>
                            </FormHelperText>
                          </FormControlField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlField>
                            <InputLabel shrink color="primary">
                              <Trans>Language</Trans>
                            </InputLabel>
                            <DropDown
                              bordered
                              options={languages}
                              onChange={onChangeLanguage}
                              value={language}
                              disabled={disabled}
                            />
                          </FormControlField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlField>
                            <InputLabel shrink color="primary">
                              <Trans>Expires On</Trans>
                            </InputLabel>
                            <MuiPickersUtilsProvider utils={DateUtils}>
                              <DatePicker
                                disablePast
                                disableToolbar
                                variant="inline"
                                format="yyyy/MM/dd"
                                margin="normal"
                                maxDate={MAX_EXPIRY}
                                value={expiresAt}
                                onChange={onChangeExpiresAt}
                                KeyboardButtonProps={{
                                  "aria-label": t`change date`,
                                }}
                                disabled={disabled}
                              />
                            </MuiPickersUtilsProvider>
                            <FormHelperText
                              error
                              hidden={!getFieldError("expiresAt")}
                            >
                              {getFormattedError(getFieldError("expiresAt"))}
                            </FormHelperText>
                          </FormControlField>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlField>
                            <InputLabel shrink color="primary">
                              <Trans>Additional information</Trans>
                            </InputLabel>
                            <BootstrapInputBordered
                              multiline
                              rows={10}
                              placeholder={t`Please specify any additional information about the book you wish to provide`}
                              onChange={onChangeMessage}
                              value={message}
                              disabled={disabled}
                            />
                          </FormControlField>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <FormControlField>
                            <InputLabel shrink color="primary">
                              <Required>
                                <Trans>Number of books needed</Trans>
                              </Required>
                            </InputLabel>
                            <BootstrapInputBordered
                              defaultValue={1}
                              type="number"
                              onChange={onChangeBooksCount}
                              onFocus={onFocusBooksCount}
                              onBlur={onBlurBooksCount}
                              value={booksCount}
                              error={getFieldError("count")}
                              disabled={disabled}
                            />
                            <FormHelperText
                              error
                              hidden={!getFieldError("count")}
                            >
                              {getFormattedError(getFieldError("count"))}
                            </FormHelperText>
                          </FormControlField>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <FormControlField>
                            <InputLabel shrink color="primary">
                              <Trans>To Buy/Rent/Exchange?</Trans>
                            </InputLabel>
                            <DropDown
                              bordered
                              options={buyingPriceModelTypes}
                              onChange={onChangePriceModelType}
                              value={priceModelType}
                              disabled={disabled}
                            />
                          </FormControlField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Grid container spacing={4} className={classes.sidebar}>
                        <Grid
                          item
                          xs={12}
                          className={classes.contactNumbersSection}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h6">
                                <Trans>Contact Details</Trans>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body1">
                                <Trans>
                                  Please select one or more contact numbers so
                                  that others can contact you if they have the
                                  book.
                                </Trans>
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={12}>
                              <PhoneNumberManager
                                selectedNumbers={contactNumberIds}
                                selectable
                                onChangeSelection={onChangeContactNumberIds}
                                disabled={disabled}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h6">
                                <Trans>Sharing Location</Trans>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body1">
                                <Trans>
                                  Select at least one location/group where you
                                  want to display this book request in.
                                </Trans>
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes.groupSelector}
                            >
                              <GroupSelector
                                sharePublic={sharePublic}
                                targetGroups={groupIds}
                                isCurrentUserAMember={true}
                                onSelectPublic={onChangeSharePublic}
                                onAddTargetGroup={onAddTargetGroup}
                                onRemoveTargetGroup={onRemoveTargetGroup}
                                disabled={disabled}
                              />
                            </Grid>
                            <FormHelperText
                              error
                              hidden={!getFieldError("groupIds")}
                            >
                              {getFormattedError(getFieldError("groupIds"))}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Button
                      onClick={handleClickSubmit}
                      color="primary"
                      size="large"
                      disableElevation
                      variant="contained"
                      disabled={disabled}
                    >
                      {submitButtonText}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

BookRequestEditor.propTypes = {
  bookRequest: PropTypes.object,
  groupScope: PropTypes.string,
  editorTitle: PropTypes.string,
  submitButtonText: PropTypes.string,
  onSubmit: PropTypes.func,
  initialContactNumberIds: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

BookRequestEditor.defaultProps = {
  editorTitle: t`Create Book Request`,
  submitButtonText: t`Create Book Request`,
  onSubmit() {},
  initialContactNumberIds: [],
  disabled: false,
};
