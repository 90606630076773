import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  makeStyles,
  Typography,
  IconButton,
  Button,
  InputBase,
} from "@material-ui/core";
import PriceTag from "../Common/PriceTag";
import Hyperlink from "../Common/Hyperlink";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ImageFadeIn from "../Common/ImageFadeIn";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  coverImageContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    padding: theme.spacing(2),
    position: "relative",
    borderRadius: "4px",
  },
  coverImage: {
    width: "100%",
    height: "100px",
    objectFit: "contain",
  },
  placeholderIcon: {
    color: theme.palette.primary.main,
  },
  itemContent: {
    color: theme.palette.grey[800],
    paddingLeft: "20px",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  cartItemCard: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2),
  },
  cartItemCardClickableArea: {
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
  },
  priceTagContainer: {
    [theme.breakpoints.up("md")]: {
      maxWidth: 150,
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    marginBottom: theme.spacing(1),
  },
  shopName: {
    fontWeight: "bold",
  },
  bookPackBanner: {
    backgroundColor: theme.palette.grey[600],
    opacity: 0.95,
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "15%",
    borderRadius: "0 0 4px 4px",
  },
  bookPackBannerText: {
    color: theme.palette.background.paper,
    fontWeight: "bold",
  },
  itemTitle: {
    fontSize: 18,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  removeFromCartButton: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  coverImagePlaceholder: {
    width: "80%",
    height: "100px",
    objectFit: "contain",
  },
}));

export default function CartItem({
  item,
  quantity,
  onChangeQuantity,
  onRemoveFromCart,
}) {
  const classes = useStyles();
  let thumbnail;
  if (item.coverImage) {
    thumbnail = (
      <ImageFadeIn
        src={item.coverImage}
        alt={item.title}
        className={classes.coverImage}
      />
    );
  } else {
    thumbnail = (
      <ImageFadeIn
        src="/book-placeholder.svg"
        alt={item.title}
        className={classes.coverImagePlaceholder}
      />
    );
  }

  const handleChangeQuantityField = (event) => {
    onChangeQuantity(event.target.value);
  };

  const thumbnailContainer = (
    <Grid container item xs={12} md={3} alignItems="center">
      <Grid
        item
        className={classes.coverImageContainer}
        justifyContent="center"
        alignContent="stretch"
        alignItems="center"
      >
        {thumbnail}
        {item.booksCount > 1 && (
          <Grid
            container
            item
            className={classes.bookPackBanner}
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              className={classes.bookPackBannerText}
              variant="subtitle2"
              align="center"
            >
              <Trans>{item.booksCount} Books Pack</Trans>
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const cartItem = (
    <Grid container item xs={12} className={classes.cartItemCard}>
      <Grid
        container
        item
        xs={12}
        md={9}
        className={classes.cartItemCardClickableArea}
      >
        <Hyperlink to={`/listing/${item.id}`}>
          <Grid container item xs={12}>
            {thumbnailContainer}
            <Grid
              container
              item
              xs={12}
              md={9}
              alignContent="flex-start"
              spacing={1}
              className={classes.itemContent}
            >
              <Grid container item xs={12} justifyContent="flex-start">
                <Typography variant="h6" className={classes.itemTitle}>
                  {item.title}
                </Typography>
              </Grid>
              {item.shop && (
                <Grid container item xs={12} justifyContent="flex-start">
                  <Typography variant="body2">
                    <Trans>sold by</Trans>{" "}
                    <span className={classes.shopName}>{item.shop?.name}</span>
                  </Typography>
                </Grid>
              )}
              <Grid
                container
                item
                alignContent="flex-end"
                className={classes.priceTagContainer}
              >
                <PriceTag price={item.price} size="sm" />
              </Grid>
            </Grid>
          </Grid>
        </Hyperlink>
      </Grid>
      <Grid container item xs={12} md={3} alignItems="flex-start" spacing={1}>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          spacing={1}
          justifyContent="center"
        >
          <Grid container item xs={12} justifyContent="center">
            <Typography variant="caption" color="primary">
              <Trans>Quantity</Trans>
            </Typography>
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <Grid item>
              <IconButton
                size="small"
                onClick={() => onChangeQuantity(quantity - 1)}
                color="primary"
              >
                <RemoveIcon />
              </IconButton>
            </Grid>
            <Grid item xs={3}>
              <InputBase
                value={quantity}
                type="tel"
                onChange={handleChangeQuantityField}
                inputProps={{ min: 0, style: { textAlign: "center" } }}
              />
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                onClick={() => onChangeQuantity(quantity + 1)}
                color="primary"
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          spacing={1}
          className={classes.removeFromCartButton}
        >
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            onClick={onRemoveFromCart}
          >
            <Trans>Remove from cart</Trans>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return cartItem;
}

CartItem.propTypes = {
  item: PropTypes.object.isRequired,
  quantity: PropTypes.number,
  onChangeQuantity: PropTypes.func,
  onRemoveFromCart: PropTypes.func,
};

CartItem.defaultProps = {
  quantity: 1,
  onChangeQuantity() {},
  onRemoveFromCart() {},
};
