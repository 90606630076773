import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import Discussion from "./Discussion";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(5),
  },
}));

export default function PublicBookChatDiscussion() {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center" className={classes.container}>
      <Grid item xs={12} md={10} lg={8}>
        <Discussion />
      </Grid>
    </Grid>
  );
}
