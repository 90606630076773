import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Checkbox,
} from "@material-ui/core";
import PhoneNumber from "./PhoneNumber";
import PhoneNumberEditorDialog from "./PhoneNumberEditorDialog";
import ConfirmPrompt from "../ConfirmPrompt";
import AddIcon from "@material-ui/icons/AddCircle";
import { GET_CONTACT_NUMBERS } from "../../../consts/queries";
import {
  CREATE_CONTACT_NUMBER,
  UPDATE_CONTACT_NUMBER,
  DELETE_CONTACT_NUMBER,
} from "../../../consts/mutations";
import { useDispatch } from "react-redux";
import { actionTypes } from "../../index.reducer";
import { GENERIC_ALERT_ERROR_MESSAGE } from "../../../config";
import { t } from "@lingui/macro";
import { useLazyQuery, useMutation } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  phoneNumber: {
    height: "100%",
    marginBottom: theme.spacing(1),
  },
  contacNumberManagerContainer: {
    display: "flex",
    width: "100%",
  },
  addContactNumberButton: {
    height: "60px",
    cursor: "pointer",
    width: "100%",
    borderRadius: theme.spacing(2),
    border: "dashed",
    color: theme.palette.grey[600],
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.background.paper,
    transition: "background-color 500ms linear",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      transition: "background-color 500ms linear",
    },
  },
  contactNumberItem: {
    marginBottom: theme.spacing(1),
  },
}));

export default function PhoneNumberManager({
  selectable,
  onChangeSelection,
  selectedNumbers,
  enabledNumbers,
  disabled,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [editedPhoneNumber, setEditedPhoneNumber] = useState({});
  const [deletingPhoneNumber, setDeletingPhoneNumber] = useState(null);
  const [getPhoneNumbers, { data }] = useLazyQuery(GET_CONTACT_NUMBERS);

  const dispatchError = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });
  const mutationConfig = {
    refetchQueries: [{ query: GET_CONTACT_NUMBERS }],
  };
  const [createContactNumber] = useMutation(
    CREATE_CONTACT_NUMBER,
    mutationConfig
  );
  const [updateContactNumbers] = useMutation(
    UPDATE_CONTACT_NUMBER,
    mutationConfig
  );
  const [deleteContactNumbers] = useMutation(
    DELETE_CONTACT_NUMBER,
    mutationConfig
  );
  const [phoneNumberManagerOpen, setPhoneNumberManagerOpen] = useState(false);
  const [phoneNumberEditorOpen, setPhoneNumberEditorOpen] = useState(false);
  const [removePhoneNumberPending, setRemovePhoneNumberPending] =
    useState(false);

  const openPhoneNumberManager = () => setPhoneNumberManagerOpen(true);
  const closePhoneNumberManager = () => setPhoneNumberManagerOpen(false);
  const openPhoneNumberEditor = () => setPhoneNumberEditorOpen(true);
  const closePhoneNumberEditor = () => setPhoneNumberEditorOpen(false);
  const onClickEditPhoneNumber = (contactNumber) => () => {
    setEditedPhoneNumber(contactNumber);
    openPhoneNumberEditor();
  };
  const onClickRemovePhoneNumber = (phoneNumber) => () => {
    setRemovePhoneNumberPending(true);
    setDeletingPhoneNumber(phoneNumber);
  };
  const onClickContactNumberSelection = (contactNumberId) => (event) => {
    onChangeSelection(contactNumberId, event.target.checked);
  };

  const handleRemoveContactNumberDecision = (accept) => {
    setRemovePhoneNumberPending(false);
    if (accept) {
      return deleteContactNumbers({
        variables: { id: deletingPhoneNumber.id },
      })
        .then(() => {
          closePhoneNumberEditor();
          setDeletingPhoneNumber(null);
        })
        .catch(() => {
          dispatchError(GENERIC_ALERT_ERROR_MESSAGE);
        });
    }
  };

  const handleCreatePhoneNumber = (contactNumber, success) => {
    createContactNumber({
      variables: { contactNumber },
    })
      .then(() => {
        success();
        closePhoneNumberEditor();
      })
      .catch(() => {
        dispatchError(GENERIC_ALERT_ERROR_MESSAGE);
      });
  };

  const handleUpdatePhoneNumber = (contactNumber, success) => {
    return updateContactNumbers({
      variables: { contactNumber },
    })
      .then(() => {
        success();
        closePhoneNumberEditor();
      })
      .catch((err) => {
        dispatchError(GENERIC_ALERT_ERROR_MESSAGE);
      });
  };

  const onSaveEditor = (phoneNumber, onSuccess) => {
    if (phoneNumber.id) return handleUpdatePhoneNumber(phoneNumber, onSuccess);
    return handleCreatePhoneNumber(phoneNumber, onSuccess);
  };

  useEffect(() => {
    getPhoneNumbers();
  }, [getPhoneNumbers]);

  useEffect(() => {
    if (Array.isArray(data?.contactNumbers)) {
      setPhoneNumbers(data.contactNumbers);
    }
  }, [data]);

  const phoneNumberManager = (
    <Dialog
      open={phoneNumberManagerOpen}
      onClose={closePhoneNumberManager}
      disableBackdropClick={true}
      fullWidth
      maxWidth="md"
      disableScrollLock={true}
    >
      <DialogTitle>Edit Phone Numbers</DialogTitle>
      <ConfirmPrompt
        open={removePhoneNumberPending}
        onAction={handleRemoveContactNumberDecision}
        question="Are you sure you want to remove this contact?"
      />
      <DialogContent className={classes.contacNumberManagerContainer}>
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          alignContent="stretch"
          alignItems="stretch"
        >
          {phoneNumbers.map((contactNumber) => (
            <Grid
              key={contactNumber.id}
              item
              xs={12}
              md={6}
              lg={4}
              className={classes.contactNumberItem}
            >
              <Grid container className={classes.phoneNumberEditorContainer}>
                <Grid item xs={12} className={classes.phoneNumber}>
                  <PhoneNumber
                    phoneNumber={contactNumber.phoneNumber}
                    isDefault={contactNumber.isDefault}
                  />
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item>
                    <Button
                      variant="text"
                      size="small"
                      color="primary"
                      onClick={onClickEditPhoneNumber(contactNumber)}
                      disabled={disabled}
                    >
                      Edit
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      size="small"
                      color="secondary"
                      onClick={onClickRemovePhoneNumber(contactNumber)}
                      disabled={disabled}
                    >
                      Remove
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12} md={6} lg={4} onClick={openPhoneNumberEditor}>
            <Grid
              container
              className={classes.addContactNumberButton}
              justifyContent="center"
              alignContent="center"
            >
              <Typography variant="subtitle">Add new phone number</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closePhoneNumberManager}
          color="default"
          disabled={disabled}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const numberEnabled = (contactNumberId) => {
    if (!enabledNumbers) return true;
    return enabledNumbers.includes(contactNumberId);
  };

  const numberChecked = (contactNumberId) => {
    if (!numberEnabled(contactNumberId)) return false;
    return selectedNumbers.includes(contactNumberId);
  };

  return (
    <Grid container spacing={2}>
      {phoneNumberManager}
      <PhoneNumberEditorDialog
        contactNumber={editedPhoneNumber}
        open={phoneNumberEditorOpen}
        onClose={closePhoneNumberEditor}
        onSave={onSaveEditor}
      />
      <Grid container item spacing={2}>
        {phoneNumbers
          .filter((cNo) => numberEnabled(cNo.id))
          .map((contactNumber) => (
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              key={contactNumber.id}
            >
              {selectable && (
                <Grid item xs={2}>
                  <Checkbox
                    color="primary"
                    checked={numberChecked(contactNumber.id)}
                    onChange={onClickContactNumberSelection(contactNumber.id)}
                    disabled={disabled}
                  />
                </Grid>
              )}
              <Grid item xs={selectable ? 10 : 12}>
                <PhoneNumber
                  phoneNumber={contactNumber.phoneNumber}
                  isDefault={contactNumber.isDefault}
                />
              </Grid>
            </Grid>
          ))}
      </Grid>
      <Grid container item justifyContent="flex-start">
        <Button
          startIcon={!phoneNumbers.length ? <AddIcon /> : null}
          variant="text"
          color="primary"
          onClick={openPhoneNumberManager}
          disabled={disabled}
        >
          {phoneNumbers.length
            ? t`Edit contact numbers`
            : t`Add contact number`}
        </Button>
      </Grid>
    </Grid>
  );
}

PhoneNumberManager.propTypes = {
  selectable: PropTypes.bool,
  onChangeSelection: PropTypes.func,
  selectedNumbers: PropTypes.arrayOf(PropTypes.string),
  enabledNumbers: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

PhoneNumberManager.defaultProps = {
  selectable: false,
  onChangeSelection() {},
  selectedNumbers: [],
  disabled: false,
};
