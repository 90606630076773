import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { DropzoneArea } from "@dpjayasekara/material-ui-dropzone";

const useStyles = makeStyles((theme) => ({
  dropZone: {
    outline: "none",
    borderColor: theme.palette.grey[400],
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[300],
  },
  dropZoneText: {
    color: theme.palette.grey[600],
  },
  previewContainer: {},
  previewItem: {
    padding: "0 !important",
    "& img": {
      maxWidth: "80px",
      objectFit: "contain",
      boxShadow: "none",
    },
  },
  previewImage: {
    maxWidth: "20px",
    objectFit: "contain",
  },
}));

export default function DropZoneArea({
  onChange,
  onDrop,
  onDelete,
  initialFiles,
  acceptedFiles,
  limit,
  dropZoneText,
  disabled,
}) {
  const classes = useStyles();

  return (
    <DropzoneArea
      dialogTitle="Attach Images"
      submitButtonText="Attach"
      acceptedFiles={acceptedFiles}
      showPreviews={false}
      showAlerts={["error"]}
      maxFileSize={10485760}
      filesLimit={disabled ? 0 : limit}
      dropzoneText={
        dropZoneText || "Drag and drop an image here or click to attach"
      }
      previewText="Preview"
      dropzoneClass={classes.dropZone}
      dropzoneParagraphClass={classes.dropZoneText}
      showFileNamesInPreview={false}
      getFileAddedMessage={(name) => `Attached image ${name}`}
      getFileRemovedMessage={(name) => `Removed image ${name}`}
      initialFiles={initialFiles}
      previewGridClasses={{
        container: classes.previewContainer,
        item: classes.previewItem,
        image: classes.previewImage,
      }}
      onChange={onChange}
      onDrop={onDrop}
      onDelete={onDelete}
    />
  );
}

DropZoneArea.propTypes = {
  acceptedFiles: PropTypes.arrayOf(PropTypes.string),
  onDelete: PropTypes.func,
  onDrop: PropTypes.func,
  onChange: PropTypes.func,
  initialFiles: PropTypes.arrayOf(PropTypes.string),
  limit: PropTypes.number,
  disabled: PropTypes.bool,
};
