import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { Route, Switch } from "react-router-dom";
import About from "./About";
import PrivacyPolicy from "./PrivacyPolicy";
import CookiePolicy from "./CookiePolicy";

const useStyles = makeStyles((theme) => ({
  page: {
    padding: theme.spacing(4),
  },
}));

export default function () {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center" className={classes.page}>
      <Grid item xs={12} lg={10}>
        <Switch>
          <Route exact path="/info/about">
            <About />
          </Route>
          <Route path="/info/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/info/cookie-policy">
            <CookiePolicy />
          </Route>
          <Route>
            <About />
          </Route>
        </Switch>
      </Grid>
    </Grid>
  );
}
