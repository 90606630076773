import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import VotesIcon from "@material-ui/icons/ThumbUp";
import dayjs from "dayjs";
import BookChatIcon from "./BookChatIcon";
import { TOGGLE_VOTE_DISCUSSION } from "../../consts/mutations";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GENERIC_ALERT_ERROR_MESSAGE } from "../../config";
import { actionTypes } from "../index.reducer";
import Hyperlink from "../Common/Hyperlink";
import { Trans } from "@lingui/macro";
import { useCurrentPath, useLoginPrompt } from "../../utils/hooks";

const useStyles = makeStyles((theme) => {
  const timestampBaseStyles = {
    fontSize: "12px",
    color: theme.palette.grey[500],
    width: "100%",
  };
  return {
    activityIcon: {
      marginRight: theme.spacing(1),
      color: theme.palette.grey,
    },
    activity: {
      justifyContent: "flex-end",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
      color: theme.palette.grey[600],
    },
    activity_compact: {
      justifyContent: "flex-start",
      color: theme.palette.grey[600],
    },
    timestamp: {
      ...timestampBaseStyles,
      textAlign: "right",
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
    timestamp_compact: timestampBaseStyles,
  };
});

export default function DiscussionActions({
  discussion,
  compact,
  isOwnDiscussion,
}) {
  const classes = useStyles();
  const currentPath = useCurrentPath();
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const activityClass = compact ? classes.activity_compact : classes.activity;
  const timestampClass = compact
    ? classes.timestamp_compact
    : classes.timestamp;

  const dispatch = useDispatch();
  const [toggleVoteDiscussion, { loading: votingDiscussion }] = useMutation(
    TOGGLE_VOTE_DISCUSSION
  );

  const handleToggleVote = createLoginPromptEventHandler({
    redirectPath: currentPath,
    callback: () => {
      toggleVoteDiscussion({
        variables: { discussionId: discussion.id },
      }).catch(() => {
        dispatch({
          type: actionTypes.ERROR_MESSAGE,
          message: GENERIC_ALERT_ERROR_MESSAGE,
        });
      });
    },
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container className={activityClass} spacing={1}>
          <Grid item>
            <Grid container alignItems="center">
              <Button
                variant="text"
                disableElevation
                disableRipple
                color={discussion.hasVoted ? "primary" : "default"}
                startIcon={<VotesIcon />}
                onClick={handleToggleVote}
                disabled={isOwnDiscussion || votingDiscussion}
              >
                <Trans>
                  {discussion.votesCount} Vote
                  {discussion.votesCount === 1 ? "" : "s"}
                </Trans>
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Button
                component={Hyperlink}
                to={
                  discussion.group
                    ? `/group/${discussion.group.id}/bookchat/${discussion.id}`
                    : `/bookchat/${discussion.id}`
                }
                variant="text"
                startIcon={<BookChatIcon />}
              >
                <Trans>
                  {discussion.repliesCount}{" "}
                  {discussion.repliesCount === 1 ? "Reply" : "Replies"}
                </Trans>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" className={timestampClass}>
          {dayjs(discussion.createdAt).format("MMMM D, YYYY [at] HH:mm")}
        </Typography>
      </Grid>
    </Grid>
  );
}

DiscussionActions.propTypes = {
  discussion: PropTypes.object.isRequired,
};
