import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  makeStyles,
  Container,
  Button,
  InputLabel,
  FormControl,
  withStyles,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@material-ui/core";
import BootstrapInput from "../Common/BootstrapInput";
import ImageUploader from "../Common/ImageUploader";
import PageTitle from "../Common/PageTitle";
import { useMutation, useLazyQuery } from "@apollo/client";
import { IMAGE_SERVER_URL, TAGS_REF_DATA_BULK_LIMIT } from "../../config";
import { useDispatch } from "react-redux";
import { actionTypes } from "../index.reducer";
import {
  GET_LISTING_COLLECTION,
  GET_TAGS_REF_DATA,
} from "../../consts/queries";
import {
  CREATE_LISTING_COLLECTION,
  UPDATE_LISTING_COLLECTION,
} from "../../consts/mutations";
import { dataURLtoBlob } from "../../utils/fileOperations";
import Required from "../Common/Required";
import BAutoComplete from "../Common/BAutoComplete";
import PageHead from "../Common/PageHead";
import { useLoader, useRequireLogin } from "../../utils/hooks";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  editorSection: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
  },
  title: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  createCollectionBtnContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
}));

const Label = withStyles((theme) => ({
  root: {
    fontSize: "20px",
    color: theme.palette.grey[700],
  },
}))(InputLabel);

const Field = withStyles({
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
})(FormControl);

export default function ListingCollectionEditor() {
  const [
    getListingCollection,
    {
      loading: retrievingCollection,
      error: retrieveCollectionError,
      data: collectionData,
    },
  ] = useLazyQuery(GET_LISTING_COLLECTION);

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { collectionId } = useParams();
  const isUpdateOperation = !!collectionId;
  const [invalidCollectionTitle, setInvalidCollectionTitle] = useState(false);
  const [invalidTags, setInvalidTags] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [isPrivateCollection, setPrivateCollection] = useState(false);
  const [collectionTitle, setCollectionTitle] = useState(null);
  const [collectionDescription, setCollectionDescription] = useState(null);
  const [collectionTags, setCollectionTags] = useState([]);
  const [coverImage, setCoverImage] = useState(null);
  const [originalCover, setOriginalCover] = useState(null);
  const [unsavedTag, setUnsavedTag] = useState(false);
  useRequireLogin();

  const [getTagsRefData, { loading: loadingTagsRefData, data: tagsRefData }] =
    useLazyQuery(GET_TAGS_REF_DATA);

  const dispatchErrorMessage = useCallback(
    (message) =>
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message,
      }),
    [dispatch]
  );

  const [createCollection, { data: createCollectionResponse }] = useMutation(
    CREATE_LISTING_COLLECTION
  );
  if (createCollectionResponse?.createListingCollection?.id) {
    history.push(
      `/collection/${createCollectionResponse.createListingCollection.id}`
    );
  }

  const [updateCollection, { data: updateCollectionResponse }] = useMutation(
    UPDATE_LISTING_COLLECTION
  );
  if (updateCollectionResponse?.updateListingCollection?.id) {
    history.push(
      `/collection/${updateCollectionResponse.updateListingCollection.id}`
    );
  }

  useEffect(() => {
    if (retrieveCollectionError) {
      dispatchErrorMessage(
        "Something went wrong while fetching the collection. Please try again later!"
      );
    }
  }, [dispatchErrorMessage, retrieveCollectionError]);

  useLoader(formSubmitting || retrievingCollection);

  useEffect(() => {
    if (isUpdateOperation) {
      getListingCollection({ variables: { id: collectionId } });
    }
  }, [isUpdateOperation, collectionId, getListingCollection]);

  useEffect(() => {
    if (collectionData?.listingCollection) {
      const { listingCollection } = collectionData;
      setCollectionTitle(listingCollection.title);
      setCollectionDescription(listingCollection.description);
      setCollectionTags(listingCollection.tags);
      setOriginalCover(listingCollection.coverImage);
      setCoverImage(listingCollection.coverImage);
      setPrivateCollection(listingCollection.public !== true);
    }
  }, [collectionData]);

  const uploadCoverImage = () => {
    if (!coverImage) return Promise.resolve();
    const formData = new FormData();
    const coverImageBlob = dataURLtoBlob(coverImage);
    if (!coverImageBlob) return Promise.resolve();
    formData.append("file", coverImageBlob);
    return fetch(`${IMAGE_SERVER_URL}/upload/xl`, {
      method: "POST",
      body: formData,
      credentials: "include",
    }).then((response) => {
      // TODO: handle rate limit errors 429
      if (response.status === 201) return response.json();
      throw Error("failed to upload image!");
    });
  };

  const handleSetCollectionPrivate = (isPrivate) => () =>
    setPrivateCollection(isPrivate);
  const handleChangeTitle = (event) => {
    setInvalidCollectionTitle(false);
    setCollectionTitle(event.target.value);
  };
  const handleChangeDescription = (event) => {
    setCollectionDescription(event.target.value);
  };

  const handleChangeTags = (event, tags) => {
    setUnsavedTag(null);
    setInvalidTags(false);
    setCollectionTags(tags);
  };
  const handleSearchTags = (event) => {
    const tagDraft = event?.target?.value;
    setUnsavedTag(tagDraft);
    if (!tagDraft) return;
    getTagsRefData({
      variables: {
        query: tagDraft,
        limit: TAGS_REF_DATA_BULK_LIMIT,
      },
    });
  };

  const handleAttachImage = ([image]) => {
    setCoverImage(image);
  };
  const handleRemoveImage = () => {
    setCoverImage(null);
  };

  const handleSubmitForm = () => {
    if (!collectionTitle || !collectionTags?.length) {
      dispatchErrorMessage("Some of the required fields are missing!");
      setInvalidCollectionTitle(!collectionTitle);
      setInvalidTags(!collectionTags?.length);
      return;
    }

    setFormSubmitting(true);

    let imageUploadPromise = Promise.resolve(null);

    if (coverImage) {
      if (coverImage !== originalCover) {
        // Image was updated by the user
        imageUploadPromise = uploadCoverImage();
      } else {
        // Image was not updated by the user, hence use the existing file name
        imageUploadPromise = Promise.resolve({
          fileName: coverImage.split("/").pop(),
        });
      }
    }

    imageUploadPromise
      .then((coverImgObj) => {
        let coverImage = coverImgObj?.fileName || null;
        if (isUpdateOperation) {
          const updatedCollectionData = {
            id: collectionId,
            title: collectionTitle,
            description: collectionDescription,
            coverImage,
            tags: collectionTags,
            public: !isPrivateCollection,
          };

          return updateCollection({
            variables: {
              listingCollection: updatedCollectionData,
            },
          }).catch(() => {
            dispatchErrorMessage(
              "Something went wrong while updating the collection. Please try again later"
            );
          });
        } else {
          return createCollection({
            variables: {
              listingCollection: {
                title: collectionTitle,
                description: collectionDescription,
                tags: collectionTags,
                public: !isPrivateCollection,
                coverImage,
              },
            },
          }).catch(() => {
            dispatchErrorMessage(
              "Something went wrong while creating the collection. Please try again later"
            );
          });
        }
      })
      .finally(() => {
        setFormSubmitting(false);
      });
  };

  const editorTitle = collectionId ? t`Edit Collection` : t`Create Collection`;

  const renderImageUploader = () => {
    if (isUpdateOperation && !collectionData?.listingCollection) return null;
    const coverImage = collectionData?.listingCollection?.coverImage;
    return (
      <ImageUploader
        variant="zone"
        initialImages={coverImage ? [coverImage] : []}
        limit={1}
        onDrop={handleAttachImage}
        onDelete={handleRemoveImage}
        buttonText={collectionId ? t`Attach new cover` : t`Attach cover`}
        disabled={formSubmitting}
      />
    );
  };

  return (
    <Container maxWidth="xl">
      {isUpdateOperation ? (
        <PageHead
          title={t`Edit Collection - ${collectionTitle} | bibliocircle`}
        />
      ) : (
        <PageHead title={t`Create Collection | bibliocircle`} />
      )}
      <Grid container justifyContent="center">
        <Grid item xs={12} className={classes.title}>
          <Grid container spacing={2} alignItems="center" direction="column">
            <Grid item>
              <PageTitle>{editorTitle}</PageTitle>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="center" spacing={3}>
          <Grid container item xs={12} sm={10} md={10} lg={6}>
            <Grid container>
              <Grid container item xs={12} className={classes.editorSection}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PageTitle variant="h5">
                      <Trans>Collection Details</Trans>
                    </PageTitle>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="textSecondary" variant="body2">
                      <Trans>Provide details about your collection</Trans>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field className={classes.spannedInput}>
                      <Label shrink color="primary" htmlFor="bootstrap-input">
                        <Required>
                          <Trans>Collection Title</Trans>
                        </Required>
                      </Label>
                      <BootstrapInput
                        error={invalidCollectionTitle}
                        onChange={handleChangeTitle}
                        value={collectionTitle}
                        disabled={formSubmitting}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field className={classes.spannedInput}>
                      <Label shrink color="primary" htmlFor="bootstrap-input">
                        <Trans>Description</Trans>
                      </Label>
                      <BootstrapInput
                        multiline
                        rows={5}
                        onChange={handleChangeDescription}
                        value={collectionDescription}
                        disabled={formSubmitting}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <Trans>
                        Collections are an easy and convenient way to organize
                        your advertisements, and share among fellow readers. Let
                        us know below, which tags to be included in this
                        collection, and we will automatically add any
                        advertisements that have at least of these tags in them.
                      </Trans>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field className={classes.spannedInput}>
                      <Label shrink color="primary" htmlFor="bootstrap-input">
                        <Required>
                          <Trans>Tags to Match</Trans>
                        </Required>
                      </Label>
                      <BAutoComplete
                        multiple
                        value={collectionTags}
                        options={
                          !!unsavedTag ? tagsRefData?.tags?.tags || [] : []
                        }
                        freeSolo
                        autoSelect
                        loading={loadingTagsRefData}
                        onChange={handleChangeTags}
                        onInputChange={handleSearchTags}
                        placeholder={t`Type and press enter to add a new tag`}
                        getOptionLabel={(option) => option}
                        disabled={formSubmitting}
                      />
                      <FormHelperText>
                        <Trans>
                          Any advertisements that have any of these tags will be
                          automatically added to your collection
                        </Trans>
                      </FormHelperText>
                      {invalidTags && (
                        <FormHelperText>
                          <Typography color="secondary" variant="caption">
                            <Trans>At least one tag is required</Trans>
                          </Typography>
                        </FormHelperText>
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.editorSection}>
                <Grid container item xs={12} justifyContent="flex-start">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <PageTitle variant="h5">
                        <Trans>Visibility</Trans>
                      </PageTitle>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={!isPrivateCollection}
                              color="primary"
                              onClick={handleSetCollectionPrivate(false)}
                              disabled={formSubmitting}
                            />
                          }
                          label={
                            <Typography variant="body2">
                              <Trans>Public Collection</Trans>
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={isPrivateCollection}
                              color="primary"
                              onClick={handleSetCollectionPrivate(true)}
                              disabled={formSubmitting}
                            />
                          }
                          label={
                            <Typography variant="body2">
                              <Trans>Private Collection</Trans>
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.editorSection}>
                <Grid container item xs={12} justifyContent="flex-start">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <PageTitle variant="h5">
                        <Trans>Collection Cover Image</Trans>
                      </PageTitle>
                    </Grid>
                    <Grid item xs={12}>
                      {renderImageUploader()}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              className={classes.createCollectionBtnContainer}
            >
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={formSubmitting}
                  onClick={handleSubmitForm}
                >
                  {collectionId ? t`Update Collection` : t`Create Collection`}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
