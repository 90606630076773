import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  makeStyles,
  Button,
  FormHelperText,
  Dialog,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import ImageUploader from "../../Common/ImageUploader";
import PageTitle from "../../Common/PageTitle";
import { useDispatch } from "react-redux";
import * as consts from "../../consts";
import Banner from "../../Common/Banner";
import InputLabel from "../../Common/InputLabel";
import SearchIcon from "@material-ui/icons/Search";
import { actionTypes } from "../../index.reducer";
import FormControlField from "../../Common/FormControlField";
import BootstrapInputBordered from "../../Common/BootstrapInputBordered";
import DropDown from "../../Common/DropDown";
import Required from "../../Common/Required";
import { useLazyQuery } from "@apollo/client";
import {
  GET_AUTHORS_REF_DATA,
  GET_CURATED_BOOK,
  GET_PUBLISHERS_REF_DATA,
} from "../../../consts/queries";
import {
  AUTHORS_REF_DATA_BULK_LIMIT,
  PUBLISHERS_REF_DATA_BULK_LIMIT,
} from "../../../config";
import BAutoComplete from "../../Common/BAutoComplete";
import {
  extractFieldError,
  getFormattedError,
  validateObject,
} from "../../../utils/schemaValidator";
import bookSchema from "./book.schema";
import { ISBN_REGEX, isValidISBN } from "../../../utils/common";
import { useLoader } from "../../../utils/hooks";
import ImageFadeIn from "../../Common/ImageFadeIn";
import Joi from "@hapi/joi";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  imageUploader: {
    borderRadius: theme.shape.borderRadius,
  },
  actions: {
    padding: theme.spacing(2),
  },
  searchISBNButtonContainer: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
    justifyContent: "flex-start",
  },
  descriptionText: {
    lineHeight: 1.5,
  },
}));

export default function BookEditorDialog({
  book,
  onSave,
  disabled,
  open,
  onClose,
  hideCondition,
  resetFormOnSave,
  requireISBN,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("xs"));
  const dispatch = useDispatch();

  // Form State
  const [bookIndex, setBookIndex] = useState(null);
  const [bookId, setBookId] = useState(null);
  const [bookTitle, setBookTitle] = useState(null);
  const [bookYear, setBookYear] = useState(null);
  const [bookCondition, setBookCondition] = useState(null);
  const [bookSubtitle, setBookSubtitle] = useState(null);
  const [bookDescription, setBookDescription] = useState(null);
  const [bookAuthors, setBookAuthors] = useState([]);
  const [bookISBN, setBookISBN] = useState(null);
  const [bookAlternativeISBNs, setBookAlternativeISBNs] = useState(null);
  const [bookUrl, setBookUrl] = useState(null);
  const [bookPublisher, setBookPublisher] = useState(null);
  const [bookPageCount, setBookPageCount] = useState(0);
  const [bookCategory, setBookCategory] = useState(null);
  const [bookLanguage, setBookLanguage] = useState(null);
  const [images, setImages] = useState([]);
  const [unsavedAuthor, setUnsavedAuthor] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  const getFieldError = (fieldName) =>
    extractFieldError(validationErrors, fieldName);

  const [
    getAuthorsRefData,
    { loading: loadingAuthorsRefData, data: authorsRefData },
  ] = useLazyQuery(GET_AUTHORS_REF_DATA);

  const [
    getPublishersRefData,
    { loading: loadingPublishersRefData, data: publishersRefData },
  ] = useLazyQuery(GET_PUBLISHERS_REF_DATA);

  const [
    getCuratedBook,
    {
      loading: loadingCuratedBook,
      data: curatedBookData,
      error: fetchCuratedBookError,
    },
  ] = useLazyQuery(GET_CURATED_BOOK);

  useLoader(loadingCuratedBook);

  const handleSearchAuthors = (event) => {
    const authorDraft = event?.target?.value;
    setUnsavedAuthor(authorDraft);
    if (!authorDraft) return;
    getAuthorsRefData({
      variables: {
        query: authorDraft,
        limit: AUTHORS_REF_DATA_BULK_LIMIT,
      },
    });
  };

  const handleSearchPublishers = (event) => {
    if (!event?.target?.value) return;
    getPublishersRefData({
      variables: {
        query: event.target.value,
        limit: PUBLISHERS_REF_DATA_BULK_LIMIT,
      },
    });
  };

  const dispatchErrorMessage = useCallback(
    (message) =>
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message,
      }),
    [dispatch]
  );

  const dispatchInfoMessage = useCallback(
    (message) =>
      dispatch({
        type: actionTypes.INFO_MESSAGE,
        message,
      }),
    [dispatch]
  );

  const formatISBNArray = (isbnStr) => {
    return (isbnStr || "")
      .split(",")
      .map((isbn) => isbn.trim())
      .filter((isbn) => !!isbn);
  };

  const serialiseAlternativeISBNs = (isbnArr) => (isbnArr || []).join(", ");

  const handleChangeBookTitle = (event) => {
    setBookTitle(event.target.value);
  };
  const handleChangeBookYear = (event) => {
    if (!!event.target.value && !/^[12][0-9]{0,3}$/.test(event.target.value)) {
      return;
    }
    setBookYear(event.target.value);
  };
  const handleChangeBookCondition = (event) =>
    setBookCondition(event.target.value);
  const handleChangeBookSubtitle = (event) =>
    setBookSubtitle(event.target.value);
  const handleChangeBookDescription = (event) => {
    setBookDescription(event.target.value);
  };
  const handleChangeBookAuthors = (event, newAuthors) => {
    setUnsavedAuthor(null);
    if (!Array.isArray(newAuthors)) return;
    setBookAuthors(newAuthors);
  };
  const formatBookAuthors = (authors) => {
    return authors.map((author) => ({ name: author }));
  };
  const handleChangeBookISBN = (event) => setBookISBN(event.target.value);
  const handleChangeAlternativeISBNs = (event) =>
    setBookAlternativeISBNs(event.target.value);
  const handleChangeBookUrl = (event) => setBookUrl(event.target.value);
  const handleChangePublisher = (event, newValue) => setBookPublisher(newValue);
  const handleChangePageCount = (event) => {
    setBookPageCount(event.target.value);
  };
  const handleFocusPageCount = () => {
    if (bookPageCount === 0) setBookPageCount("");
  };
  const handleBlurPageCount = () => {
    if (!bookPageCount) setBookPageCount(0);
  };
  const handleChangeBookCategory = (event) =>
    setBookCategory(event.target.value);
  const handleChangeBookLanguage = (event) =>
    setBookLanguage(event.target.value);

  const resetForm = () => {
    setBookIndex("");
    setBookId("");
    setBookTitle("");
    setBookYear("");
    setBookCondition("");
    setBookSubtitle("");
    setBookDescription("");
    setBookAuthors([]);
    setBookISBN("");
    setBookAlternativeISBNs("");
    setBookUrl("");
    setBookCategory("");
    setBookLanguage("");
    setBookPublisher("");
    setImages([]);
    setBookPageCount(0);
  };

  useEffect(() => {
    if (book && Object.keys(book).length) {
      setBookIndex(book.index);
      setBookId(book.id);
      setBookTitle(book.title);
      setBookYear(book.year);
      setImages(book.images);
      setBookCondition(book.condition);
      setBookSubtitle(book.subtitle);
      setBookDescription(book.description);
      const bookAuthors = book.authors.map((author) => author.name);
      setBookAuthors(bookAuthors);
      setBookISBN(book.isbn);
      setBookAlternativeISBNs(serialiseAlternativeISBNs(book.alternativeISBNs));
      setBookUrl(book.url);
      setBookCategory(book.category);
      setBookPageCount(book.pageCount);
      setBookLanguage(book.language);
      setBookPublisher(book.publisher);
    } else {
      resetForm();
    }
  }, [book]);

  const lookupByISBN = () => {
    if (!isValidISBN(bookISBN)) {
      dispatchErrorMessage(
        `Provided value ${bookISBN} doesn't look like a valid ISBN`
      );
      return;
    }
    getCuratedBook({ variables: { isbn: bookISBN } });
  };

  useEffect(() => {
    if (loadingCuratedBook) return;
    if (curatedBookData && !curatedBookData.curatedBook) {
      dispatchInfoMessage(
        "We could not locate the ISBN in our database. Please enter the details of the book manually."
      );
    }
  }, [curatedBookData, dispatchInfoMessage, loadingCuratedBook]);

  useEffect(() => {
    if (loadingCuratedBook) return;
    if (fetchCuratedBookError) {
      dispatchInfoMessage(
        "Unfortunately we could not lookup your ISBN at this time. Please enter the details of the book manually."
      );
      return;
    }
  }, [fetchCuratedBookError, dispatchInfoMessage, loadingCuratedBook]);

  useEffect(() => {
    const data = curatedBookData?.curatedBook;
    if (!data) return;

    setBookTitle(data.title);
    setBookSubtitle(data.subtitle);
    setBookDescription(data.description);
    setBookCategory(data.category);
    setBookYear(data.year);
    setBookAuthors((data.authors || []).map((author) => author.name));
    setBookUrl(data.url);
    setBookLanguage(data.language);
    setBookPageCount(data.pageCount || 0);
    setBookPublisher(data.publisher);
  }, [curatedBookData, loadingCuratedBook]);

  const getValidatedBook = (bookDraft) => {
    let schema = bookSchema;
    if (requireISBN) {
      schema = schema.concat(
        Joi.object({
          isbn: Joi.string()
            .trim()
            .disallow("", null)
            .regex(ISBN_REGEX)
            .required(),
        })
      );
    }
    setValidationErrors([]);
    const { validatedObject, errors } = validateObject(bookDraft, schema);

    if (errors.length) {
      setValidationErrors(errors);
      return false;
    } else {
      return validatedObject;
    }
  };

  const onSaveBook = () => {
    const bookDraft = {
      id: bookId,
      images,
      title: bookTitle,
      description: bookDescription,
      year: +bookYear,
      condition: bookCondition,
      subtitle: bookSubtitle,
      authors: formatBookAuthors(bookAuthors),
      isbn: bookISBN,
      alternativeISBNs: formatISBNArray(bookAlternativeISBNs),
      url: bookUrl,
      publisher: bookPublisher,
      pageCount: +bookPageCount,
      category: bookCategory,
      language: bookLanguage,
    };

    const validatedBook = getValidatedBook(bookDraft);
    if (!validatedBook) {
      dispatchErrorMessage(
        "Some of the fields contain invalid values. Please check again!"
      );
      return;
    }

    onSave({
      index: bookIndex,
      ...validatedBook,
    });

    if (resetFormOnSave) resetForm();
  };

  const discardAndClose = () => {
    resetForm();
    onClose();
  };

  const onKeyDownIsbn = (event) => {
    if (event.keyCode === 13) lookupByISBN();
  };

  const onAttachImages = (newImages) => {
    setImages((images) => [...new Set([...images, ...newImages])]);
  };

  const onDeleteImage = (deletedImage, index) => {
    setImages((images) => images.filter((img, i) => i !== index));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={isSmallDevice}
      disableScrollLock
      disableBackdropClick
    >
      <DialogContent>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={5}>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <PageTitle variant="h5">
                        <Trans>Book Details</Trans>
                      </PageTitle>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="textSecondary" variant="body2">
                        <Trans>Details of the book you are sharing</Trans>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Banner
                            type="info"
                            title={t`Tell us the book's ISBN`}
                            message={t`We may be able to help you by auto populating some fields if we know the book's ISBN (International Standard Book Number). On most books, the ISBN number (most probably 10 or 13 digits) can be found on the back cover, near the barcode similar to the following sample:`}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            justifyContent="center"
                            alignContent="flex-start"
                            alignItems="flex-start"
                          >
                            <ImageFadeIn
                              width="200"
                              src="/isbn_sample.png"
                              alt="isbn_example"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        <Grid item xs={12} sm={6}>
                          <FormControlField className={classes.spannedInput}>
                            <InputLabel
                              shrink
                              color="primary"
                              htmlFor="bootstrap-input"
                            >
                              {requireISBN ? (
                                <Required>
                                  <Trans>ISBN Number</Trans>
                                </Required>
                              ) : (
                                t`ISBN Number`
                              )}
                            </InputLabel>
                            <BootstrapInputBordered
                              value={bookISBN}
                              onChange={handleChangeBookISBN}
                              onKeyDown={onKeyDownIsbn}
                              disabled={disabled}
                              error={getFieldError("isbn")}
                            />
                            <FormHelperText id="my-helper-text">
                              <Trans>
                                Providing the correct ISBN number will help more
                                readers discover this book on bibliocircle.
                                Please provide the ISBN number without dashes
                                (e.g, 1234567890)
                              </Trans>
                            </FormHelperText>
                            <FormHelperText
                              error
                              hidden={!getFieldError("isbn")}
                            >
                              <Trans>This field requires a valid ISBN</Trans>
                            </FormHelperText>
                          </FormControlField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid
                            container
                            className={classes.searchISBNButtonContainer}
                          >
                            <Button
                              disableElevation
                              startIcon={<SearchIcon />}
                              variant="contained"
                              color="primary"
                              onClick={lookupByISBN}
                              disabled={disabled || loadingCuratedBook}
                            >
                              <Trans>Search ISBN</Trans>
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControlField className={classes.spannedInput}>
                        <InputLabel shrink color="primary">
                          <Trans>Alternative ISBN Numbers</Trans>
                        </InputLabel>
                        <BootstrapInputBordered
                          value={bookAlternativeISBNs}
                          type="string"
                          error={getFieldError("alternativeISBNs")}
                          onChange={handleChangeAlternativeISBNs}
                          disabled={disabled}
                        />
                        <FormHelperText id="my-helper-text">
                          <Trans>
                            Please provide any additional ISBN numbers
                            (depending on variation/edition) separated by commas
                          </Trans>
                        </FormHelperText>
                        <FormHelperText
                          error
                          hidden={!getFieldError("alternativeISBNs")}
                        >
                          <Trans>
                            This field contains one or more invalid ISBNs
                          </Trans>
                        </FormHelperText>
                      </FormControlField>
                    </Grid>
                    <Grid item xs={12}>
                      <Banner
                        type="info"
                        message={t`Please attach up to 5 images to be displayed on the listing. The first image will be displayed as the cover in search results.`}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.imageUploader}>
                      <ImageUploader
                        limit={5}
                        onDrop={onAttachImages}
                        onDelete={onDeleteImage}
                        multiple
                        buttonText={t`Attach images`}
                        variant="zone"
                        initialImages={book?.images || []}
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlField className={classes.spannedInput}>
                        <InputLabel
                          shrink
                          color="primary"
                          htmlFor="bootstrap-input"
                        >
                          <Required>
                            <Trans>Title</Trans>
                          </Required>
                        </InputLabel>
                        <BootstrapInputBordered
                          value={bookTitle}
                          inputProps={{ maxlength: 150 }}
                          onChange={handleChangeBookTitle}
                          error={getFieldError("title")}
                          disabled={disabled}
                        />
                        <FormHelperText error hidden={!getFieldError("title")}>
                          {getFormattedError(getFieldError("title"))}
                        </FormHelperText>
                      </FormControlField>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlField className={classes.spannedInput}>
                        <InputLabel
                          shrink
                          color="primary"
                          htmlFor="bootstrap-input"
                        >
                          <Trans>Subtitle</Trans>
                        </InputLabel>
                        <BootstrapInputBordered
                          inputProps={{ maxlength: 250 }}
                          onChange={handleChangeBookSubtitle}
                          value={bookSubtitle}
                          disabled={disabled}
                        />
                        <FormHelperText>
                          <Typography variant="caption">
                            <Trans>
                              This is optional. Some books have a sub-title too.
                              example: "The secret documents of Sherlock Holmes
                              #1"
                            </Trans>
                          </Typography>
                        </FormHelperText>
                      </FormControlField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlField className={classes.spannedInput}>
                        <InputLabel
                          shrink
                          color="primary"
                          htmlFor="bootstrap-input"
                        >
                          <Trans>Year</Trans>
                        </InputLabel>
                        <BootstrapInputBordered
                          value={bookYear}
                          type="number"
                          inputProps={{ min: 0 }}
                          onChange={handleChangeBookYear}
                          disabled={disabled}
                        />
                        <FormHelperText>
                          <Typography variant="caption">
                            <Trans>When was this copy published?</Trans>
                          </Typography>
                        </FormHelperText>
                      </FormControlField>
                    </Grid>
                    {!hideCondition && (
                      <Grid item xs={12} md={6}>
                        <FormControlField>
                          <InputLabel shrink color="primary" htmlFor="category">
                            <Trans>Condition</Trans>
                          </InputLabel>
                          <DropDown
                            value={bookCondition}
                            disableUnderline
                            id="category"
                            onChange={handleChangeBookCondition}
                            disabled={disabled}
                            options={consts.bookConditions}
                            bordered
                          />
                        </FormControlField>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormControlField className={classes.spannedInput}>
                        <InputLabel
                          shrink
                          color="primary"
                          htmlFor="bootstrap-input"
                        >
                          <Trans>Description</Trans>
                        </InputLabel>
                        <BootstrapInputBordered
                          value={bookDescription}
                          multiline
                          rows={15}
                          onChange={handleChangeBookDescription}
                          disabled={disabled}
                          className={classes.descriptionText}
                        />
                      </FormControlField>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlField className={classes.spannedInput}>
                        <InputLabel
                          shrink
                          color="primary"
                          htmlFor="bootstrap-input"
                        >
                          <Trans>Authors</Trans>
                        </InputLabel>
                        <BAutoComplete
                          bordered
                          multiple
                          value={bookAuthors}
                          options={
                            !!unsavedAuthor
                              ? authorsRefData?.authors?.authors || []
                              : []
                          }
                          freeSolo
                          autoSelect
                          loading={loadingAuthorsRefData}
                          onChange={handleChangeBookAuthors}
                          onInputChange={handleSearchAuthors}
                          placeholder={t`Type and press enter to add a new author`}
                          getOptionLabel={(option) => option}
                        />
                      </FormControlField>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlField className={classes.spannedInput}>
                        <InputLabel
                          shrink
                          color="primary"
                          htmlFor="pub-auto-complete"
                        >
                          <Trans>Publisher</Trans>
                        </InputLabel>
                        <BAutoComplete
                          id="pub-auto-complete"
                          bordered
                          value={bookPublisher}
                          options={
                            publishersRefData?.publishers?.publishers || []
                          }
                          freeSolo
                          autoSelect
                          loading={loadingPublishersRefData}
                          onChange={handleChangePublisher}
                          onInputChange={handleSearchPublishers}
                          getOptionLabel={(option) => option}
                        />
                      </FormControlField>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlField className={classes.spannedInput}>
                        <InputLabel
                          shrink
                          color="primary"
                          htmlFor="bootstrap-input"
                        >
                          <Trans>Number of Pages</Trans>
                        </InputLabel>
                        <BootstrapInputBordered
                          type="number"
                          onChange={handleChangePageCount}
                          onFocus={handleFocusPageCount}
                          onBlur={handleBlurPageCount}
                          value={bookPageCount}
                          disabled={disabled}
                        />
                      </FormControlField>
                    </Grid>
                    <Grid style={{ display: "none" }} item xs={12}>
                      <FormControlField className={classes.spannedInput}>
                        <InputLabel
                          shrink
                          color="primary"
                          htmlFor="bootstrap-input"
                        >
                          <Trans>URL</Trans>
                        </InputLabel>
                        <BootstrapInputBordered
                          inputProps={{ maxlength: 1000 }}
                          onChange={handleChangeBookUrl}
                          value={bookUrl}
                          disabled={disabled}
                        />
                        <FormHelperText id="my-helper-text">
                          <Trans>
                            URL which gives more information about the book
                            (e.g, goodreads, Google Books etc.)
                          </Trans>
                        </FormHelperText>
                      </FormControlField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlField>
                        <InputLabel shrink color="primary" htmlFor="category">
                          <Trans>Category</Trans>
                        </InputLabel>
                        <DropDown
                          value={bookCategory}
                          disableUnderline
                          id="category"
                          onChange={handleChangeBookCategory}
                          disabled={disabled}
                          options={consts.categories}
                          bordered
                        ></DropDown>
                      </FormControlField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlField>
                        <InputLabel shrink color="primary" htmlFor="lang">
                          <Trans>Language</Trans>
                        </InputLabel>
                        <DropDown
                          value={bookLanguage}
                          disableUnderline
                          onChange={handleChangeBookLanguage}
                          disabled={disabled}
                          options={consts.languages}
                          bordered
                        ></DropDown>
                      </FormControlField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid container justifyContent="flex-start" spacing={1}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={disabled}
              onClick={onSaveBook}
              disableElevation
            >
              <Trans>Save Book</Trans>
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={discardAndClose}
              disabled={disabled}
              disableElevation
            >
              <Trans>Discard Changes</Trans>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

BookEditorDialog.propTypes = {
  book: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  resetFormOnSave: PropTypes.bool,
  requireISBN: PropTypes.bool,
};
