import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Container,
  Divider,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { GENERIC_ALERT_ERROR_MESSAGE } from "../../config";
import {
  DELETE_DISCUSSION,
  REPLY_DISCUSSION,
  TOGGLE_CLOSE_DISCUSSION,
} from "../../consts/mutations";
import { GET_DISCUSSION } from "../../consts/queries";
import ErrorPage from "../Error/ErrorPage";
import { actionTypes } from "../index.reducer";
import Book from "../Listing/Book";
import MessageThread from "../Messages/MessageThread";
import CloseIcon from "@material-ui/icons/Lock";
import OpenIcon from "@material-ui/icons/LockOpen";
import DeleteIcon from "@material-ui/icons/Delete";
import ReportIcon from "@material-ui/icons/Flag";
import ConfirmPrompt from "../Common/ConfirmPrompt";
import EditIcon from "@material-ui/icons/Edit";
import Hyperlink from "../Common/Hyperlink";
import MakeLink from "linkifyjs/react";
import DiscussionFooter from "./DiscussionFooter";
import Reporting from "../Common/Reporting";
import { useLoader, useLoggedInUser, useScrollTop } from "../../utils/hooks";
import PageHead from "../Common/PageHead";
import FacebookIcon from "@material-ui/icons/Facebook";
import { FacebookShareButton } from "react-share";

const useStyles = makeStyles((theme) => ({
  discussion: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.spacing(2),
  },
  discussonTitle: {
    fontWeight: "bold",
  },
  discussionMessageText: {
    color: theme.palette.grey[800],
    whiteSpace: "pre-wrap",
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  },
  actionsContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
    justifyContent: "flex-end",
  },
}));

export default function Discussion() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [reporterOpen, setReporterOpen] = useState(false);
  const [awaitingDeletionDecision, setAwaitingDeletionDecision] =
    useState(false);
  const history = useHistory();
  const loggedInUser = useLoggedInUser();
  const { groupId, discussionId } = useParams();
  const {
    loading,
    error: fetchDiscussionError,
    data: discussionData,
  } = useQuery(GET_DISCUSSION, {
    variables: {
      id: discussionId,
    },
  });
  const [deleteDiscussion, { loading: deletingDiscussion }] =
    useMutation(DELETE_DISCUSSION);
  const [toggleCloseDiscussion, { loading: togglingDiscussionClosure }] =
    useMutation(TOGGLE_CLOSE_DISCUSSION);
  const [replyDiscussion, { loading: replyingDiscussion }] =
    useMutation(REPLY_DISCUSSION);

  useScrollTop();
  useLoader(loading);

  if (fetchDiscussionError) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the discussion. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  if (!discussionData) return null;
  const { discussion } = discussionData;
  if (!discussion || (discussion.group && discussion.group.id !== groupId)) {
    return (
      <ErrorPage
        title="Unavailable"
        description="This discussion is either removed, or does not exist.!"
        statusCode={404}
      />
    );
  }

  const isOwnDiscussion =
    loggedInUser && loggedInUser.id === discussion.user?.id;

  const handleToggleCloseDiscussion = () => {
    toggleCloseDiscussion({
      variables: { id: discussion.id },
    }).catch(() => {
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message: GENERIC_ALERT_ERROR_MESSAGE,
      });
    });
  };

  const handleDeleteDiscussion = (confirmed) => {
    setAwaitingDeletionDecision(false);
    if (confirmed) {
      deleteDiscussion({
        variables: { id: discussion.id },
      })
        .then(() => {
          setTimeout(() => {
            dispatch({
              type: actionTypes.INFO_MESSAGE,
              message: "Discussion was deleted!",
              autoHideDuration: 3000,
            });
          });
          if (groupId) {
            history.push(`/group/${groupId}/bookchats`);
          } else {
            history.push(`/bookchats`);
          }
        })
        .catch(() => {
          dispatch({
            type: actionTypes.ERROR_MESSAGE,
            message: GENERIC_ALERT_ERROR_MESSAGE,
          });
        });
    }
  };

  const handleReplyDiscussion = (message) => {
    return replyDiscussion({
      variables: { discussionId: discussion.id, message },
    }).catch(() => {
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message: GENERIC_ALERT_ERROR_MESSAGE,
      });
    });
  };

  const onClickDelete = () => {
    setAwaitingDeletionDecision(true);
  };

  const toggleOpenReporter = () => {
    setReporterOpen((isOpen) => !isOpen);
  };

  const facebookShareButton = (
    <Grid item>
      <FacebookShareButton url={window.location.href} hashtag="#bibliocircle">
        <Button
          size="small"
          variant="text"
          color="primary"
          fullWidth
          disableElevation
          disableRipple
          startIcon={<FacebookIcon />}
        >
          Share On Facebook
        </Button>
      </FacebookShareButton>
    </Grid>
  );

  return (
    <Container maxWidth="xl">
      <Grid container>
        <PageHead title={`${discussion.title} | bibliocircle`} />
        <ConfirmPrompt
          open={awaitingDeletionDecision}
          onAction={handleDeleteDiscussion}
          question="Are you sure you want to delete this discussion. This action cannot be undone!"
        />
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container className={classes.discussion}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        color="primary"
                        className={classes.discussonTitle}
                      >
                        {discussion.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        className={classes.discussionMessageText}
                      >
                        <MakeLink>{discussion.message}</MakeLink>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={5}>
                        {discussion.books.map((book, i) => (
                          <Grid item xs={12} key={book.id}>
                            <Grid container spacing={5}>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              <Grid item xs={12}>
                                <Book book={book} hideCondition />
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent="flex-end">
                        {!isOwnDiscussion && (
                          <Grid item xs={12} sm={6}>
                            <Reporting
                              type="DISCUSSION"
                              resourceId={discussion.id}
                              open={reporterOpen}
                              onClose={toggleOpenReporter}
                            />
                            <Grid
                              container
                              className={classes.actionsContainer}
                              spacing={2}
                            >
                              {facebookShareButton}
                              <Grid item>
                                <Tooltip title="Is this discussion inappropriate on bibliocircle? Let us know.">
                                  <Button
                                    variant="text"
                                    color="secondary"
                                    size="small"
                                    startIcon={<ReportIcon />}
                                    onClick={toggleOpenReporter}
                                  >
                                    Report Discussion
                                  </Button>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {isOwnDiscussion && (
                          <Grid item xs={12} sm={6}>
                            <Grid
                              container
                              className={classes.actionsContainer}
                              spacing={2}
                            >
                              {facebookShareButton}
                              <Grid item>
                                <Tooltip title="Permanently delete the discussion.">
                                  <Button
                                    variant="text"
                                    size="small"
                                    startIcon={<DeleteIcon />}
                                    onClick={onClickDelete}
                                    disabled={deletingDiscussion}
                                  >
                                    Delete
                                  </Button>
                                </Tooltip>
                              </Grid>
                              <Grid item>
                                <Tooltip title="Closing the discussion will block any new replies.">
                                  <Button
                                    size="small"
                                    variant="text"
                                    startIcon={
                                      discussion.closed ? (
                                        <OpenIcon />
                                      ) : (
                                        <CloseIcon />
                                      )
                                    }
                                    onClick={handleToggleCloseDiscussion}
                                    disabled={togglingDiscussionClosure}
                                  >
                                    {discussion.closed
                                      ? "Reopen Discussion"
                                      : "Close Discussion"}
                                  </Button>
                                </Tooltip>
                              </Grid>
                              <Grid item>
                                <Button
                                  size="small"
                                  component={Hyperlink}
                                  to={
                                    groupId
                                      ? `/bookchat/${discussionId}/edit?groupScope=${groupId}`
                                      : `/bookchat/${discussionId}/edit`
                                  }
                                  variant="text"
                                  startIcon={<EditIcon />}
                                >
                                  Edit
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <DiscussionFooter
                        discussion={discussion}
                        isOwnDiscussion={isOwnDiscussion}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MessageThread
                threadId={discussion.threadId}
                heading="Replies"
                noMessagesPlaceholder={
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body2">No replies!</Typography>
                    </Grid>
                  </Grid>
                }
                inputPlaceholder="Type a reply"
                sendButtonText="Reply Discussion"
                onSendMessage={handleReplyDiscussion}
                disabled={replyingDiscussion}
                closed={discussion.closed}
                threadClosureTitle="Discussion Closed!"
                threadClosureMessage="This discussion is closed and you cannot reply anymore."
                sendButtonjustifyContent="flex-start"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
