import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_SHOP } from "../../consts/mutations";
import { IMAGE_SERVER_URL } from "../../config";
import { useHistory } from "react-router-dom";
import ShopEditor from "./ShopEditor";
import { dataURLtoBlob } from "../../utils/fileOperations";
import PageHead from "../Common/PageHead";
import { DB_VALIDATION_ERRORS } from "../consts";
import {
  useDispatchErrorMessage,
  useLoader,
  useLoggedInUser,
  useRequireLogin,
} from "../../utils/hooks";

export default function CreateShop() {
  const dispatchErrorMessage = useDispatchErrorMessage();
  const history = useHistory();
  const loggedInUser = useLoggedInUser();
  const [createShop] = useMutation(CREATE_SHOP);
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
  useRequireLogin();
  useLoader(formSubmitInProgress);

  const handleCreateShop = (shop) => {
    setFormSubmitInProgress(true);
    Promise.all([
      uploadImage(shop.logoImage, "md"),
      uploadImage(shop.coverImage, "xxl"),
    ])
      .then(([logoImage, coverImage]) => {
        return createShop({
          variables: {
            shop: {
              ...shop,
              logoImage,
              coverImage,
            },
          },
        });
      })
      .then(({ data }) => {
        history.push(`/shop/${data.createShop.identifier}`);
      })
      .catch((err) => {
        if (err.graphQLErrors?.[0]?.code === DB_VALIDATION_ERRORS.DUPLICATE) {
          dispatchErrorMessage(
            "A shop already exists with the provided public URL. Please choose another identifier for your URL!"
          );
        } else {
          dispatchErrorMessage(
            "Something went wrong while creating your shop. Please contact us if the issue persists."
          );
        }
      })
      .finally(() => {
        setFormSubmitInProgress(false);
      });
  };

  const uploadImage = (dataUrl, uploadSize = "md") => {
    if (!dataUrl) return Promise.resolve();
    const formData = new FormData();
    const logoBlob = dataURLtoBlob(dataUrl);
    if (!logoBlob) return Promise.resolve();
    formData.append("file", logoBlob);
    return fetch(`${IMAGE_SERVER_URL}/upload/${uploadSize}`, {
      method: "POST",
      body: formData,
      credentials: "include",
    })
      .then((response) => {
        // TODO: handle rate limit errors 429
        if (response.status === 201) return response.json();
        throw Error("failed to upload image!");
      })
      .then((logoImage) => logoImage?.fileName);
  };

  if (!loggedInUser) return null;

  return (
    <>
      <PageHead title="Create Shop | bibliocircle" />
      <ShopEditor onSave={handleCreateShop} disabled={formSubmitInProgress} />
    </>
  );
}
