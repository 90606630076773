import { Trans } from "@lingui/macro";
import { makeStyles, useTheme } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  badge: {
    fontSize: "0.8em",
  },
}));

export default function Required({ children, color }) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      {children}{" "}
      <span
        className={classes.badge}
        style={{ color: color || theme.palette.secondary.main }}
      >
        <sup>
          <Trans>* required</Trans>
        </sup>
      </span>
    </>
  );
}
