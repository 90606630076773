import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { DropzoneDialog } from "@dpjayasekara/material-ui-dropzone";

const useStyles = makeStyles((theme) => ({
  dropZone: {
    outline: "none",
    borderColor: theme.palette.grey[400],
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[300],
  },
  dropZoneText: {
    color: theme.palette.grey[600],
  },
}));

export default function DropDialog({
  open,
  onSave,
  onClose,
  initialFiles,
  limit,
  acceptedFiles,
  disabled,
}) {
  const classes = useStyles();
  return (
    <DropzoneDialog
      open={open}
      dialogTitle="Attach Images"
      submitButtonText="Attach"
      acceptedFiles={acceptedFiles}
      showPreviews={true}
      maxFileSize={10485760}
      filesLimit={disabled ? 0 : limit}
      dropzoneText="Drag and drop an image here or click"
      previewText="Preview"
      dropzoneClass={classes.dropZone}
      dropzoneParagraphClass={classes.dropZoneText}
      showFileNamesInPreview={false}
      getFileAddedMessage={(name) => `Attached image ${name}`}
      getFileRemovedMessage={(name) => `Removed image ${name}`}
      initialFiles={initialFiles}
      onSave={onSave}
      onClose={onClose}
    />
  );
}

DropDialog.propTypes = {
  onAttach: PropTypes.func,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  initialFiles: PropTypes.arrayOf(PropTypes.string),
  limit: PropTypes.number,
  disabled: PropTypes.bool,
};
