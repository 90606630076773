import Joi from "@hapi/joi";
import { ISBN_REGEX } from "../../utils/common";

export default Joi.object({
  id: Joi.string().optional(),
  title: Joi.string().required(),
  authors: Joi.array()
    .items(
      Joi.object({
        name: Joi.string().required(),
      })
    )
    .optional(),
  publisher: Joi.string().optional().allow("", null).optional(),
  language: Joi.string().optional().allow("", null).optional(),
  priceModelType: Joi.string(),
  year: Joi.number().integer().allow("", null).optional(),
  isbn: Joi.string().trim().allow("", null).optional().regex(ISBN_REGEX),
  alternativeISBNs: Joi.array()
    .items(Joi.string().trim().required().regex(ISBN_REGEX).optional())
    .optional(),
  count: Joi.number().min(1),
  message: Joi.string().allow("", null),
  expiresAt: Joi.date().optional(),
  contactNumberIds: Joi.array().items(Joi.string()),
  public: Joi.bool(),
  groupIds: Joi.any().when("public", {
    is: false,
    then: Joi.array().items(Joi.string()).min(1),
    otherwise: Joi.array().items(Joi.string()).min(0),
  }),
});
