import { Grid, makeStyles } from "@material-ui/core";
import SearchGroup from "../Search/SearchGroup";
import FeedPostList from "./FeedPostList";
import FeedActionButtons from "./FeedActionButtons";

const useStyles = makeStyles((theme) => ({
  feedContainer: {
    paddingTop: theme.spacing(3),
    backgroundColor: theme.palette.grey[100],
  },
  feedListContainer: {
    [theme.breakpoints.up("xl")]: {
      minWidth: 732,
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: 732,
    },
  },
}));

export default function Feed() {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center" className={classes.feedContainer}>
      <Grid item xs={12} lg={10} xl={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SearchGroup />
              </Grid>
              <Grid item xs={12}>
                <FeedActionButtons />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid
                className={classes.feedListContainer}
                item
                xs={12}
                sm={8}
                lg={8}
                xl={6}
              >
                <FeedPostList />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
