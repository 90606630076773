import { Chip, Grid, makeStyles, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import React from "react";
import UserAvatar from "../../Common/UserAvatar";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(2),
  },
  title: {
    fontSize: "16px",
  },
}));

export default function GlobalNotificationCard({ notification }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.card}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={12} lg={9}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.title}>
                  {notification.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {notification.message}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {notification.data.map((dataItem) => (
                    <Grid item>
                      <Chip
                        size="small"
                        label={`${dataItem.key}: ${dataItem.value}`}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="textSecondary">
                  {dayjs(notification.createdAt).format(
                    "MMMM D, YYYY [at] hh:mm a"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Grid
              container
              spacing={2}
              direction="column"
              alignItems="flex-end"
            >
              <Grid item>
                <UserAvatar user={notification.sender} />
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography variant="body2" color="textSecondary">
                      <b>Received: </b>
                      {notification.receivedCount}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="textSecondary">
                      <b>Clicked: </b>
                      {notification.clicksCount}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
