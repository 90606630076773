import React from "react";
import { Typography, makeStyles, Grid } from "@material-ui/core";
import Hyperlink from "./Hyperlink";

const useStyles = makeStyles((theme) => ({
  banner: {
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.lighter,
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    textDecoration: "underline",
  },
}));

export default function ListingsEndBanner() {
  const classes = useStyles();
  return (
    <Grid container className={classes.banner} spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6" color="primary">
          Didn't find what you were looking for?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" color="primary">
          Go ahead and{" "}
          <Hyperlink to="/book-requests" className={classes.link}>
            create a book request
          </Hyperlink>{" "}
          to let us and other users know what you are looking for! If the book
          becomes available on bibliocircle, we will send you a notification.
        </Typography>
      </Grid>
    </Grid>
  );
}
