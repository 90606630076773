import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import MessageCard from "./MessageCard";
import Banner from "../Common/Banner";
import {
  CHAT_PAGINATION_LIMIT,
  GENERIC_ALERT_ERROR_MESSAGE,
  MESSAGES_PAGINATION_LIMIT,
} from "../../config";
import { useMutation, useQuery } from "@apollo/client";
import { GET_MESSAGES } from "../../consts/queries";
import { MessagesLoader } from "../Common/LoadingPlaceholders/MessagesLoader";
import { useDispatch } from "react-redux";
import { DELETE_MESSAGE } from "../../consts/mutations";
import { actionTypes } from "../index.reducer";
import { useLoggedInUser } from "../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  noMessagesPlaceholder: {
    padding: theme.spacing(3),
  },
  bookRequestsList: {
    height: "80vh",
    overflow: "auto",
  },
  errorBanner: {
    padding: theme.spacing(3),
  },
  noMessagesPlaceholderText: {
    fontWeight: "bold",
    textAlign: "center",
  },
  messagesContainer: {
    maxHeight: "60vh",
    overflow: "auto",
    display: "flex",
    padding: theme.spacing(2),
    flexDirection: "column-reverse",
  },
}));

export default function MessagesSearchController({
  searchArgs,
  variant,
  noMessagesPlaceholder,
  xs,
  sm,
  md,
  lg,
  xl,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loggedInUser = useLoggedInUser();
  const isChatVariant = variant === "chat";
  const paginationLimit = isChatVariant
    ? CHAT_PAGINATION_LIMIT
    : MESSAGES_PAGINATION_LIMIT;
  const { loading, error, data, fetchMore } = useQuery(GET_MESSAGES, {
    variables: {
      ...searchArgs,
      inverse: isChatVariant,
      pagination: {
        limit: paginationLimit,
        offset: 0,
      },
    },
  });

  const [deleteMessage] = useMutation(DELETE_MESSAGE);

  const loadingPlaceholder = (
    <MessagesLoader xs={xs} sm={sm} md={md} lg={lg} xl={xl} />
  );

  if (loading && !data) {
    return (
      <Grid container justifyContent="center">
        {loadingPlaceholder}
      </Grid>
    );
  }

  const {
    messages: { messages, pagination },
  } = data;

  const loadMoreMessages = () => {
    const reorderMessages = (prevMessages, newMessages) => {
      if (isChatVariant) {
        return [...newMessages, ...prevMessages];
      } else {
        return [...prevMessages, ...newMessages];
      }
    };
    fetchMore({
      variables: {
        inverse: isChatVariant,
        pagination: {
          limit: paginationLimit,
          offset: messages.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          messages: {
            __typename: prev.messages.__typename,
            messages: reorderMessages(
              prev.messages.messages,
              fetchMoreResult.messages.messages
            ),
            pagination: fetchMoreResult.messages.pagination,
          },
        });
      },
    }).catch(() => {});
  };

  const handleDeleteMessage = (message) => {
    return deleteMessage({
      variables: { id: message.id, threadId: message.threadId },
      refetchQueries: [
        {
          query: GET_MESSAGES,
          variables: {
            ...searchArgs,
            inverse: isChatVariant,
            pagination: {
              limit: paginationLimit,
              offset: 0,
            },
          },
        },
      ],
    }).catch(() => {
      dispatch({
        type: actionTypes.ERROR_MESSAGE,
        message: GENERIC_ALERT_ERROR_MESSAGE,
      });
    });
  };

  if (error)
    return (
      <Grid container justifyContent="center" className={classes.errorBanner}>
        <Grid item xs={12}>
          <Banner
            type="error"
            title="Something went wrong!"
            message="We're sorry! Something went wrong while displaying messages for
              you! Please try again later."
          />
        </Grid>
      </Grid>
    );

  const scrolledContent = (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        {messages.map((message) => (
          <Grid key={message.id} item xs={xs} md={md} lg={lg} xl={xl}>
            <MessageCard
              message={message}
              variant={variant}
              ownMessage={loggedInUser?.id === message?.user?.id}
              onDelete={handleDeleteMessage}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  if (!messages.length) {
    return (
      <Grid container>
        <Grid item xs={12}>
          {noMessagesPlaceholder}
        </Grid>
      </Grid>
    );
  }

  if (isChatVariant) {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          id="messagesScrolllableDiv"
          className={classes.messagesContainer}
        >
          <InfiniteScroll
            style={{
              overflow: "none !important",
              display: "flex",
              flexDirection: "column-reverse",
            }}
            inverse={true}
            scrollableTarget="messagesScrolllableDiv"
            next={loadMoreMessages}
            hasMore={messages.length < pagination.totalCount}
            loader={<MessagesLoader xs={xs} md={md} lg={lg} xl={xl} />}
            dataLength={messages.length}
          >
            {scrolledContent}
          </InfiniteScroll>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container item xs={12}>
      <InfiniteScroll
        style={{ overflow: "none !important" }}
        next={loadMoreMessages}
        hasMore={messages.length < pagination.totalCount}
        loader={<MessagesLoader xs={xs} md={md} lg={lg} xl={xl} />}
        dataLength={messages.length}
      >
        {scrolledContent}
      </InfiniteScroll>
    </Grid>
  );
}

MessagesSearchController.propTypes = {
  searchArgs: PropTypes.object,
  variant: PropTypes.string,
  noMessagesPlaceholder: PropTypes.object,
};

MessagesSearchController.defaultProps = {
  searchArgs: {},
  variant: "thread",
  noMessagesPlaceholder: null,
};
