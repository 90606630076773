import Joi from "@hapi/joi";
import { ISBN_REGEX } from "../../../utils/common";

export default Joi.object({
  id: Joi.string().optional().allow("", null),
  title: Joi.string().required(),
  images: Joi.array().items(Joi.string().optional()).optional(),
  subtitle: Joi.string().optional().allow("", null),
  year: Joi.number().optional(),
  condition: Joi.string().allow("", null).optional(),
  description: Joi.string().allow("", null).optional(),
  authors: Joi.array().items(
    Joi.object({
      name: Joi.string().required(),
    })
  ),
  isbn: Joi.string().trim().regex(ISBN_REGEX).optional().allow("", null),
  alternativeISBNs: Joi.array()
    .items(Joi.string().trim().required().regex(ISBN_REGEX).optional())
    .optional(),
  publisher: Joi.optional().allow("", null),
  pageCount: Joi.number().optional(),
  url: Joi.optional().allow("", null),
  category: Joi.optional().allow("", null),
  language: Joi.optional().allow("", null),
});
