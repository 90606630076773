import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { t } from "@lingui/macro";
import ShopsSearchController from "../../Shop/ShopsSearchController";

export default function UserFavouriteShopsPage({ searchQuery }) {
  return (
    <Grid container>
      <ShopsSearchController
        xs={12}
        md={6}
        searchArgs={{ query: searchQuery, isFollowed: true }}
        noResultsMessage={t`No favourite shops found!`}
      />
    </Grid>
  );
}

UserFavouriteShopsPage.propTypes = {
  searchQuery: PropTypes.string,
};
