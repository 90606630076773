import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import ArrowUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDownIcon from "@material-ui/icons/ArrowDropDown";
import ImageFadeIn from "../ImageFadeIn";

const useStyles = makeStyles((theme) => ({
  controlIcon: {
    fontSize: "72px",
    color: theme.palette.grey[500],
    marginBottom: "-15px",
    marginTop: "-15px",
  },
  controlIconDisabled: {
    fontSize: "72px",
    color: theme.palette.grey[300],
    marginBottom: "-15px",
    marginTop: "-15px",
  },
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  hidden: {
    display: "none",
  },
  navigationButton: {
    cursor: "pointer",
  },
  imageContainer: {
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "300px",
  },
  plainBackground: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(2),
  },
  placeholderBackground: {
    padding: theme.spacing(2),
    backgroundImage: theme.palette.pattern.dottedDark,
    backgroundSize: "10px 10px",
  },
}));

export default function MediaContainer({
  urls,
  nozoom,
  showBackgroundPlaceholder,
}) {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = urls;

  const viewPrevious = () => setCurrentIndex(Math.max(currentIndex - 1, 0));
  const viewNext = () =>
    setCurrentIndex(Math.min(currentIndex + 1, images.length - 1));
  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={
        showBackgroundPlaceholder
          ? classes.placeholderBackground
          : classes.plainBackground
      }
    >
      {images.length > 1 ? (
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          onClick={viewPrevious}
          className={classes.navigationButton}
        >
          <ArrowUpIcon
            className={
              currentIndex ? classes.controlIcon : classes.controlIconDisabled
            }
          />
        </Grid>
      ) : (
        ""
      )}
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignContent="center"
        className={classes.imageContainer}
      >
        {images.map((url, i) => (
          <ImageFadeIn
            key={url}
            className={currentIndex !== i ? classes.hidden : classes.image}
            style={nozoom ? {} : { cursor: "zoom-in" }}
            src={url}
            alt="cover"
          />
        ))}
      </Grid>
      {images.length > 1 ? (
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          onClick={viewNext}
          className={classes.navigationButton}
        >
          <ArrowDownIcon
            className={
              currentIndex < images.length - 1
                ? classes.controlIcon
                : classes.controlIconDisabled
            }
          />
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
}

MediaContainer.propTypes = {
  urls: PropTypes.arrayOf(PropTypes.string).isRequired,
  nozoom: PropTypes.bool,
  showBackgroundPlaceholder: PropTypes.bool,
};
