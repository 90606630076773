import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {
  Grid,
  Typography,
  makeStyles,
  useTheme,
  Button,
} from "@material-ui/core";
import IncomingOrderItem from "./IncomingOrderItem";
import Address from "../../Common/Addresses/Address";
import PhoneNumber from "../../Common/PhoneNumbers/PhoneNumber";
import ConfirmPrompt from "../../Common/ConfirmPrompt";
import PriceTag from "../../Common/PriceTag";
import StoreIcon from "@material-ui/icons/Store";
import { green } from "@material-ui/core/colors";
import { useMutation } from "@apollo/client";
import {
  ACCEPT_ORDER,
  REJECT_ORDER,
  SET_ORDER_DISPATCHED,
  SET_ORDER_READY_FOR_PICKUP,
  SET_ORDER_DELIVERED,
} from "../../../consts/mutations";
import { useDispatch } from "react-redux";
import { actionTypes } from "../../index.reducer";
import Hyperlink from "../../Common/Hyperlink";
import { orderStatuses } from "../../consts";
import Banner from "../../Common/Banner";
import { useLoader } from "../../../utils/hooks";
import { Trans } from "@lingui/macro";

const {
  OPEN,
  ACCEPTED,
  REJECTED,
  READY_FOR_PICKUP,
  DISPATCHED,
  DELIVERED,
  CANCELLED,
} = orderStatuses;

const useStyles = makeStyles((theme) => ({
  orderHeading: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2),
    borderRadius: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 0`,
  },
  orderUserLink: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  orderShopLink: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  sidebar: {
    backgroundColor: theme.palette.grey[100],
  },
  statusTitle: {
    fontWeight: "bold",
  },
  statusDescription: {},
  statusContainer: {},
  orderCard: {
    backgroundColor: theme.palette.grey[100],
    paddingBottom: theme.spacing(4),
    borderRadius: theme.spacing(2),
    border: "1px solid",
    borderColor: theme.palette.grey[200],
  },
  orderContainer: {
    padding: theme.spacing(3),
  },
  desktopOnly: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  orderInfoText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
}));

export default function IncomingOrderCard({ order, showMessageThread }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [pendingOrderAction, setPendingOrderAction] = useState(false);
  const [acceptOrder, { loading: acceptingOrder }] = useMutation(ACCEPT_ORDER);
  const [rejectOrder, { loading: rejectingOrder }] = useMutation(REJECT_ORDER);
  const [setOrderDispatched, { loading: settingOrderDispatched }] =
    useMutation(SET_ORDER_DISPATCHED);
  const [setOrderReadyForPickup, { loading: settingOrderReadyForPickup }] =
    useMutation(SET_ORDER_READY_FOR_PICKUP);
  const [setOrderDelivered, { loading: settingOrderDelivered }] =
    useMutation(SET_ORDER_DELIVERED);
  const loading =
    acceptingOrder ||
    rejectingOrder ||
    settingOrderDispatched ||
    settingOrderReadyForPickup ||
    settingOrderDelivered;

  useLoader(loading);

  const openOrderActionPrompt = (action) => () => setPendingOrderAction(action);
  const closeOrderActionPrompt = () => setPendingOrderAction(null);

  const orderActions = [
    {
      query: ACCEPT_ORDER,
      performAction: acceptOrder,
      buttonTitle: "Accept",
      buttonColor: "primary",
      promptTitle: "Accept Order",
      promptQuestion: "Are you sure you want to accept this order?",
      successMessage: "Order accepted!",
      errorMessage: "Error occurred while accepting the order!",
      parentStatuses: [OPEN],
    },
    {
      query: SET_ORDER_DISPATCHED,
      performAction: setOrderDispatched,
      buttonTitle: "Mark as dispatched",
      buttonColor: "primary",
      promptTitle: "Mark order as dispatched",
      promptQuestion: "Are you sure you want to mark this order as dispatched?",
      successMessage: "Order marked as dispatched!",
      errorMessage: "Error occurred while marking the order as dispatched!",
      parentStatuses: [ACCEPTED],
    },
    {
      query: SET_ORDER_READY_FOR_PICKUP,
      performAction: setOrderReadyForPickup,
      buttonTitle: "Mark as Ready for Pick-up",
      buttonColor: "primary",
      promptTitle: "Mark order as Ready for Pick-up",
      promptQuestion:
        "Are you sure you want to mark this order as Ready for Pick-up?",
      successMessage: "Order marked as Ready for Pick-up!",
      errorMessage:
        "Error occurred while marking the order as Ready for Pick-up!",
      parentStatuses: [ACCEPTED],
    },
    {
      query: SET_ORDER_DELIVERED,
      performAction: setOrderDelivered,
      buttonTitle: "Mark as delivered",
      buttonColor: "primary",
      promptTitle: "Mark order as delivered",
      promptQuestion: "Are you sure you want to mark this order as delivered?",
      successMessage: "Order marked as delivered!",
      errorMessage: "Error occurred while marking the order as delivered!",
      parentStatuses: [READY_FOR_PICKUP, DISPATCHED],
    },
    {
      query: REJECT_ORDER,
      performAction: rejectOrder,
      buttonTitle: "Reject",
      buttonColor: "secondary",
      promptTitle: "Reject Order",
      promptQuestion: "Are you sure you want to reject this order?",
      successMessage: "Order rejected!",
      errorMessage: "Error occurred while rejecting the order!",
      parentStatuses: [OPEN, ACCEPTED, READY_FOR_PICKUP, DISPATCHED],
    },
  ];

  const getFormattedOrderStatus = (orderStatus) => {
    switch (orderStatus) {
      case OPEN:
        return {
          title: "Pending",
          description: "Waiting for your approval",
          color: theme.palette.primary.main,
        };
      case ACCEPTED:
        return {
          title: "Accepted",
          description: "You have accepted the order. Order is being processed.",
          color: green[800],
        };
      case REJECTED:
        return {
          title: "Rejected",
          description:
            "You have rejected this order. The customer has been notified.",
          color: theme.palette.secondary.main,
        };
      case DISPATCHED:
        return {
          title: "Dispatched",
          description: "You have dispatched the order",
          color: green[800],
        };
      case DELIVERED:
        return {
          title: "Delivered",
          description: "The order is delivered to the customer",
          color: green[800],
        };
      case READY_FOR_PICKUP:
        return {
          title: "Ready for Pick-up",
          description:
            "The customer has been informed that the order is available for pick-up at your shop",
          color: green[800],
        };
      case CANCELLED:
        return {
          title: "Cancelled",
          description: "Customer cancelled the order",
          color: theme.palette.grey[700],
        };
      default:
        return {
          title: "Unknown",
          description:
            "This order status is unknown. Please contact support if you believe this is a mistake.",
          color: theme.palette.secondary.main,
        };
    }
  };

  const handleOrderAction = (action) => (doAction) => {
    closeOrderActionPrompt();
    if (doAction) {
      const { performAction, successMessage, errorMessage } = action;
      performAction({
        variables: {
          id: order.id,
        },
      })
        .then(() => {
          dispatch({
            type: actionTypes.INFO_MESSAGE,
            message: successMessage,
          });
        })
        .catch(() => {
          dispatch({
            type: actionTypes.ERROR_MESSAGE,
            message: errorMessage,
          });
        });
    }
  };

  const status = getFormattedOrderStatus(order.status);
  const { userContactNumbers, deliveryPreference } = order;
  const contactNumbers = userContactNumbers?.length ? userContactNumbers : [];
  const deliveryPrefType = deliveryPreference?.type;
  const isPickupOrder = deliveryPrefType === "PICK_UP_AT_STORE";
  const isDelivery = deliveryPrefType === "HOME_DELIVERY";
  const isOrderCancelled = order.status === CANCELLED;
  const isOrderAwaitingDelivery = [
    OPEN,
    ACCEPTED,
    DISPATCHED,
    READY_FOR_PICKUP,
  ].includes(order.status);

  return (
    <Grid container className={classes.orderCard}>
      <Grid item xs={12} className={classes.orderHeading}>
        <Grid container spacing={1}>
          <Grid item>
            <Typography className={classes.orderInfoText} variant="subtitle2">
              Order placed by{" "}
              <Hyperlink
                className={classes.orderUserLink}
                target="_blank"
                to={`/profile/${order.user.id}`}
              >
                {[order.user?.firstName, order.user?.lastName].join(" ").trim()}
              </Hyperlink>
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>&bull;</Grid>
              {order.shop ? (
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    className={classes.orderInfoText}
                  >
                    <Hyperlink
                      className={classes.orderShopLink}
                      target="_blank"
                      to={`/shop/${order.shop.identifier}`}
                    >
                      {order.shop.name}
                    </Hyperlink>
                  </Typography>
                </Grid>
              ) : (
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    className={classes.orderInfoText}
                  >
                    <Trans>Your Personal Advertising</Trans>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item className={classes.desktopOnly}>
            &bull;
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" className={classes.orderInfoText}>
              Order ID{" "}
              <Hyperlink
                className={classes.orderShopLink}
                target="_blank"
                to={`/order/${order.id}`}
              >
                # {(order.id || "").toUpperCase()}
              </Hyperlink>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        className={classes.orderContainer}
        direction="row-reverse"
      >
        <Grid
          container
          item
          xs={12}
          md={4}
          alignContent="flex-start"
          className={classes.sidebar}
        >
          <Grid container item xs={12} spacing={3} justifyContent="center">
            <Grid container item xs={12} className={classes.statusContainer}>
              <Grid item xs={12}>
                <Typography
                  className={classes.statusTitle}
                  style={{ color: status.color }}
                  variant="h6"
                  align="center"
                >
                  {status.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={classes.statusDescription}
                  style={{ color: status.color }}
                  variant="body2"
                  align="center"
                >
                  {status.description}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <PriceTag price={order.totalValue} label="Order Value" />
            </Grid>
            {isOrderAwaitingDelivery && (
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={10}>
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      align="center"
                    >
                      <Trans>
                        Please contact the customer to arrange the payment.
                      </Trans>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!isOrderCancelled && isDelivery && !order.deliveryAddress && (
              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={10}>
                    <Banner
                      type="warning"
                      message="This order is a delivery, but it seems that the customer has either removed the delivery address or has not specified. Please contact the customer for the delivery address."
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!isOrderCancelled && order.deliveryAddress && (
              <Grid container item xs={12} spacing={2} justifyContent="center">
                <Grid item xs={10}>
                  <Typography variant="body2" align="center">
                    Delivery Address
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Address address={order.deliveryAddress} />
                </Grid>
              </Grid>
            )}
            {!isOrderCancelled && isPickupOrder && (
              <Grid container item xs={12} spacing={2} justifyContent="center">
                <Grid
                  container
                  item
                  xs={10}
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <StoreIcon />
                  <Typography variant="subtitle2" align="center">
                    This order will be picked up at the store
                  </Typography>
                </Grid>
              </Grid>
            )}
            {!isOrderCancelled && !!contactNumbers.length && (
              <Grid container item xs={12} spacing={2} justifyContent="center">
                <Grid item xs={10}>
                  <Typography variant="body2" align="center">
                    Contact Customer
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  container
                  justifyContent="center"
                  spacing={2}
                >
                  {contactNumbers.map((contactNumber) => (
                    <Grid key={contactNumber.id} item xs={10}>
                      <PhoneNumber
                        phoneNumber={contactNumber.phoneNumber}
                        justifyContent="center"
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
            {!showMessageThread && (
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Hyperlink to={`/order/${order.id}`}>
                      <Typography variant="subtitle2" color="primary">
                        Messages ({order.messagesCount || 0})
                      </Typography>
                    </Hyperlink>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="body2" align="center">
                Order placed on{" "}
                {dayjs(order.createdAt).format("MMMM D, YYYY [at] h:mm A")}
              </Typography>
            </Grid>
            {pendingOrderAction && (
              <ConfirmPrompt
                open={pendingOrderAction}
                title={pendingOrderAction.promptTitle}
                onClose={closeOrderActionPrompt}
                question={pendingOrderAction.promptQuestion}
                onAction={handleOrderAction(pendingOrderAction)}
              />
            )}
            <Grid container item xs={12} spacing={2}>
              {orderActions
                .filter(({ query, parentStatuses }) => {
                  if (parentStatuses.includes(order.status)) {
                    if (order.status === ACCEPTED) {
                      if (query === REJECT_ORDER) return true;
                      if (isPickupOrder)
                        return query === SET_ORDER_READY_FOR_PICKUP;
                      else return query === SET_ORDER_DISPATCHED;
                    }
                    return true;
                  }
                  return false;
                })
                .map((orderAction) => (
                  <Grid
                    key={orderAction.id}
                    container
                    item
                    xs={12}
                    justifyContent="center"
                  >
                    <Grid item xs={8}>
                      <Button
                        size="large"
                        variant="outlined"
                        disableElevation
                        fullWidth
                        color={orderAction.buttonColor}
                        onClick={openOrderActionPrompt(orderAction)}
                        disabled={loading}
                      >
                        {orderAction.buttonTitle}
                      </Button>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          {order.items.map((item) => (
            <IncomingOrderItem
              key={item.listing.id}
              orderId={order.id}
              item={item.listing}
              quantity={item.quantity}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

IncomingOrderCard.propTypes = {
  order: PropTypes.object,
};
