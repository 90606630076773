import React from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@material-ui/core";

export default function StoryIcon({ fontSize, style, className }) {
  return (
    <SvgIcon style={style} className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={fontSize}
        height={fontSize}
        viewBox="0 0 24 24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M21 2C6 2 4 16 3 22h1.998c.666-3.333 2.333-5.166 5.002-5.5 4-.5 7-4 8-7l-1.5-1 1-1c1-1 2.004-2.5 3.5-5.5z" />
      </svg>
    </SvgIcon>
  );
}

StoryIcon.propTypes = {
  fontSize: PropTypes.number,
};

StoryIcon.defaultProps = {
  fontSize: 24,
};
