import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    border: "none",
    width: "100%",
    marginBottom: theme.spacing(1),
    padding: 0,
    backgroundColor: "transparent",
  },
}));

export function ShopSelectionItemLoader({ length }) {
  const classes = useStyles();
  return (
    <div className={`ph-item ${classes.item}`}>
      <div>
        <div className="ph-row">
          <div className={`ph-col-${length}`}></div>
        </div>
      </div>
    </div>
  );
}

export function ShopSelectionLoader() {
  return (
    <Grid container justifyContent="flex-start">
      <Grid item container>
        <ShopSelectionItemLoader length={6} />
      </Grid>
      <Grid item container>
        <ShopSelectionItemLoader length={10} />
      </Grid>
      <Grid item container>
        <ShopSelectionItemLoader length={8} />
      </Grid>
    </Grid>
  );
}
