import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";
import { Grid } from "@material-ui/core";
import MediaContainer from "./MediaContainer";

export default function MediaContentViewer({
  urls,
  nozoom,
  showBackgroundPlaceholder,
}) {
  let imageUrls = urls;
  const viewerRef = React.createRef();
  if (!imageUrls.length) imageUrls = ["/book-placeholder.svg"];

  const component = (
    <Grid container id="imageviewer" ref={viewerRef}>
      <MediaContainer
        urls={imageUrls}
        nozoom={nozoom}
        showBackgroundPlaceholder={showBackgroundPlaceholder}
      />
    </Grid>
  );

  useEffect(() => {
    if (nozoom) return;
    const viewer = new Viewer(viewerRef.current, {
      backdrop: true,
      fullscreen: true,
      loading: true,
      loop: true,
      keyboard: true,
      navbar: true,
      rotatable: true,
      reset: true,
      scalable: true,
      button: true,
      movable: true,
      title: false,
      toggleOnDblclick: true,
      toolbar: true,
      tooltip: true,
      transition: false,
      zoomable: true,
      viewed() {
        viewer.zoomTo(1);
      },
    });
  }, [urls]);

  return component;
}

MediaContentViewer.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  nozoom: PropTypes.bool,
  showBackgroundPlaceholder: PropTypes.bool,
};
