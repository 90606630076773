import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import Hyperlink from "../../Common/Hyperlink";
import { Trans } from "@lingui/macro";
import { useLoginPrompt } from "../../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  chapterNumber: {
    fontSize: "10px",
  },
  unpublishedBadge: {
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    "& *": {
      fontSize: 10,
      fontWeight: "bold",
      color: theme.palette.grey[800],
    },
  },
}));

export default function StoryChapterCard({ chapter, manageMode }) {
  const classes = useStyles();
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const onClickVisitChapter = createLoginPromptEventHandler({
    redirectPath: `/story/${chapter.story.id}/chapter/${chapter.id}`,
  });

  return (
    <Grid
      container
      className={classes.card}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        item
        xs={manageMode ? 8 : 12}
        sm={manageMode ? 9 : 12}
        md={manageMode ? 10 : 12}
      >
        <Hyperlink
          onClick={onClickVisitChapter}
          to={`/story/${chapter.story.id}/chapter/${chapter.id}`}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.chapterNumber}
              >
                <Trans>Chapter {chapter.chapterNumber} &bull;</Trans>{" "}
                <Trans>
                  {Math.ceil(chapter.readTimeStats?.minutes)} min read
                </Trans>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                color="textPrimary"
                className={classes.title}
              >
                {chapter.title}
              </Typography>
            </Grid>
          </Grid>
        </Hyperlink>
      </Grid>
      {manageMode && (
        <Grid item xs={4} sm={3} md={2}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            {!chapter.published && (
              <Grid item>
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  className={classes.unpublishedBadge}
                >
                  <Typography variant="body2">
                    <Trans>Unpublished</Trans>
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

StoryChapterCard.propTypes = {
  chapter: PropTypes.object.isRequired,
  manageMode: PropTypes.bool,
};
