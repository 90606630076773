import React, { useState, useEffect, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import {
  Dialog,
  Button,
  Grid,
  makeStyles,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Cancel";
import {
  BDialogActions,
  BDialogContent,
  BDialogTitle,
} from "../Common/BDialog";
import BootstrapInput from "../Common/BootstrapInput";
import {
  extractFieldError,
  excludeFieldError,
  validateObject,
} from "../../utils/schemaValidator";
import shopPageSchema from "./shopPage.schema";
import { TAGS_REF_DATA_BULK_LIMIT } from "../../config";
import { GET_TAGS_REF_DATA } from "../../consts/queries";
import { useLazyQuery } from "@apollo/client";
import BAutoComplete from "../Common/BAutoComplete";
import ReorderButtons from "../Common/ReorderButtons";

const useStyles = makeStyles((theme) => ({
  tab: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
  },
  tabsHeading: {
    marginBottom: theme.spacing(1),
  },
  tabHelperTextListItem: {
    marginBottom: theme.spacing(0.5),
  },
}));

export default function TabEditorDialog({
  open,
  onClose,
  onSave,
  tabs,
  disabled,
}) {
  const classes = useStyles();
  const [pages, setPages] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);

  const getFieldError = (fieldName, pageId) => {
    const fieldValidationError = validationErrors.find(
      ({ id }) => pageId === id
    );
    if (!fieldValidationError) return null;
    return extractFieldError(fieldValidationError.errors || [], fieldName);
  };

  const changeTags = useCallback(
    (tags, pageId) => {
      setPages(
        pages.map((page) => {
          if (page.id === pageId) {
            return {
              ...page,
              tags,
              unsavedTag: null,
            };
          }
          return page;
        })
      );
      setValidationErrors(
        validationErrors
          .map(({ id, errors }, i) => {
            if (id === pageId) {
              const filteredErrors = excludeFieldError(errors, "tags");
              if (filteredErrors?.length) {
                return { id, errors: filteredErrors };
              }
              return null;
            }
            return { id, errors };
          })
          .filter((errorObj) => !!errorObj)
      );
    },
    [pages, validationErrors]
  );

  const handleChangeTags = (pageId) => (event, tags) =>
    changeTags(tags, pageId);
  const handleSearchTags = (index) => (event) => {
    const tagDraft = event?.target?.value;
    setPages(
      pages.map((page, i) => {
        if (index === i) {
          return {
            ...page,
            unsavedTag: tagDraft,
          };
        }
        return page;
      })
    );
    if (!tagDraft) return;
    getTagsRefData({
      variables: {
        query: tagDraft,
        limit: TAGS_REF_DATA_BULK_LIMIT,
      },
    });
  };
  const onFocusTagsSearch = (index) => () => {
    const tagDraft = pages[index]?.unsavedTag || null;
    if (!tagDraft) return;
    getTagsRefData({
      variables: {
        query: tagDraft,
        limit: TAGS_REF_DATA_BULK_LIMIT,
      },
    });
  };

  const [getTagsRefData, { loading: loadingTagsRefData, data: tagsRefData }] =
    useLazyQuery(GET_TAGS_REF_DATA);

  useEffect(() => {
    if (tabs?.length) {
      setPages(
        tabs.map((tab) => ({
          ...tab,
          tags: tab.tags || [],
        }))
      );
    }
  }, [tabs]);

  const onClickAddTab = () => {
    setPages([...pages, { id: uuidv4() }]);
  };

  const onClickRemoveTab = (pageId) => () => {
    setPages(pages.filter((page) => page.id !== pageId));
    setValidationErrors(validationErrors.filter(({ id }) => id !== pageId));
  };

  const onChangeTitle = (pageId) => (event) => {
    setPages(
      pages.map((page) => {
        if (page.id === pageId) {
          page["title"] = event.target.value;
        }
        return page;
      })
    );
    setValidationErrors(
      validationErrors
        .map(({ id, errors }, i) => {
          if (id === pageId) {
            const filteredErrors = excludeFieldError(errors, "title");
            if (filteredErrors?.length) {
              return { id, errors: filteredErrors };
            }
            return null;
          }
          return { id, errors };
        })
        .filter((errorObj) => !!errorObj)
    );
  };

  const onSaveTabs = () => {
    const validatedPages = [];
    const fieldValidationErrors = [];
    setValidationErrors([]);
    pages.forEach(({ id, title, tags }, i) => {
      const { validatedObject, errors } = validateObject(
        { title, tags },
        shopPageSchema
      );
      if (validatedObject) validatedPages.push(validatedObject);
      if (errors.length)
        fieldValidationErrors.push({
          id,
          errors,
        });
    });
    if (!fieldValidationErrors.length) {
      onSave(validatedPages);
    } else {
      setValidationErrors(fieldValidationErrors);
    }
  };

  const onReorderButtonClick = (current, type) => {
    const updatedPages = [...pages];

    if (type === "up") {
      // current would be always greater than 0, as up on 0th is disabled
      [updatedPages[current - 1], updatedPages[current]] = [
        updatedPages[current],
        updatedPages[current - 1],
      ];
    } else if (type === "down") {
      // current would be never be the last as down on last is disabled
      [updatedPages[current], updatedPages[current + 1]] = [
        updatedPages[current + 1],
        updatedPages[current],
      ];
    }

    setPages([...updatedPages]);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      disableScrollLock={true}
    >
      <BDialogTitle>Customize Tabs</BDialogTitle>
      <BDialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  You can customize how the tabs panel at the top of your shop
                  will look like.
                </Typography>
                <ul>
                  <li className={classes.tabHelperTextListItem}>
                    <Typography variant="body1">
                      <b>Tab Title</b>: Short and interesting title for your tab
                    </Typography>
                  </li>
                  <li className={classes.tabHelperTextListItem}>
                    <Typography variant="body1">
                      <b>Tags</b>: You can add these tags to the advertisements
                      that you need to display under this tab.
                    </Typography>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container className={classes.tabsHeading}>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={4}>
                        <Typography color="textSecondary" variant="subtitle2">
                          Tab Title
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography color="textSecondary" variant="subtitle2">
                          Tags
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} alignItems="center">
                    {pages.map((page, i) => (
                      <Grid key={page.id} item xs={12} className={classes.tab}>
                        <Grid container spacing={1} alignItems="flex-start">
                          <Grid item xs={1}>
                            <Grid container justifyContent="center">
                              <Grid item>
                                <ReorderButtons
                                  isStart={i === 0}
                                  isEnd={i === pages.length - 1}
                                  onMoveUp={() => onReorderButtonClick(i, "up")}
                                  onMoveDown={() =>
                                    onReorderButtonClick(i, "down")
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4}>
                            <Paper>
                              <BootstrapInput
                                placeholder="Tab Title"
                                fullWidth
                                error={getFieldError("title", page.id)}
                                onChange={onChangeTitle(page.id)}
                                value={page.title}
                                disabled={disabled}
                              />
                            </Paper>
                          </Grid>
                          <Grid item xs={6}>
                            <Paper>
                              <BAutoComplete
                                multiple
                                noLabel
                                disabled={disabled}
                                defaultValue={page.tags || []}
                                error={getFieldError("tags", page.id)}
                                value={page.tags}
                                options={
                                  !!page.unsavedTag
                                    ? tagsRefData?.tags?.tags || []
                                    : []
                                }
                                loading={loadingTagsRefData}
                                freeSolo
                                autoSelect
                                onChange={handleChangeTags(page.id)}
                                onInputChange={handleSearchTags(i)}
                                onFocus={onFocusTagsSearch(i)}
                                placeholder="Type and press enter to add a tag"
                                getOptionLabel={(option) => option}
                              />
                            </Paper>
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              onClick={onClickRemoveTab(page.id)}
                              disabled={disabled}
                            >
                              <DeleteIcon color="primary" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {!!validationErrors.length && (
              <Grid container>
                <Grid container item xs={12}>
                  <Typography variant="body2" color="secondary">
                    Ouch! Some fields have invalid values! Please correct the
                    highlighted fields.
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Button
                startIcon={<AddIcon />}
                variant="text"
                color="primary"
                onClick={onClickAddTab}
                disabled={disabled}
              >
                Add Tab
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </BDialogContent>
      <BDialogActions>
        <Button onClick={onClose} color="primary" disabled={disabled}>
          Discard and Close
        </Button>
        <Button
          onClick={onSaveTabs}
          color="primary"
          variant="contained"
          disableElevation
          disabled={disabled}
        >
          Save
        </Button>
      </BDialogActions>
    </Dialog>
  );
}

TabEditorDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  disabled: PropTypes.bool,
};

TabEditorDialog.defaultProps = {
  onClose() {},
  onSave() {},
};
