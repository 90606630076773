import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { grey } from "@material-ui/core/colors";

export default withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${grey[300]}`,
  },
  error: {
    borderRadius: theme.shape.borderRadius,
    border: "1px solid red",
    "& input": {
      "&::placeholder": {
        color: "red",
        opacity: 0.7,
      },
    },
  },
  input: {
    "&::placeholder": {
      fontSize: 16,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    "&:disabled": {
      backgroundColor: theme.palette.grey[100],
    },
    borderRadius: theme.shape.borderRadius,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    padding: "15px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
  inputMultiline: {
    resize: "vertical",
  },
  multiline: {
    padding: 0,
  },
}))(InputBase);
