import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { STORY_REVIEWS_PAGINATION_LIMIT } from "../../../../config";
import { useQuery } from "@apollo/client";
import { SEARCH_STORY_REVIEWS } from "../../../../consts/queries";
import ErrorPage from "../../../Error/ErrorPage";
import { useLoader } from "../../../../utils/hooks";
import StoryReviewCard from "./StoryReviewCard";
import { StoryReviewsLoader } from "../../../Common/LoadingPlaceholders/StoryReviewsLoader";
import { t, Trans } from "@lingui/macro";
import StoryRatingCard from "./StoryRatingCard";

const useStyles = makeStyles((theme) => ({
  noStoryReviewsPlaceholder: {
    padding: theme.spacing(3),
  },
  errorBanner: {
    padding: theme.spacing(3),
  },
  noStoryReviewsPlaceholderText: {
    fontWeight: "bold",
    textAlign: "center",
  },
}));

export default function StoryReviewsSearchController({
  fetchPolicy,
  fixedResultsCount,
  searchArgs,
  noResultsMessage,
  xs,
  md,
  lg,
  xl,
}) {
  const classes = useStyles();
  const fetchOptions = {
    variables: {
      ...searchArgs,
      pagination: {
        limit: fixedResultsCount || STORY_REVIEWS_PAGINATION_LIMIT,
        offset: 0,
      },
    },
  };

  if (fetchPolicy) fetchOptions.fetchPolicy = fetchPolicy;
  const {
    loading,
    error: fetchStoryReviewsError,
    data,
    fetchMore,
  } = useQuery(SEARCH_STORY_REVIEWS, fetchOptions);
  useLoader(loading);

  const loadingPlaceholder = (
    <StoryReviewsLoader xs={xs} md={md} lg={lg} xl={xl} />
  );

  if (fetchStoryReviewsError) {
    return (
      <ErrorPage
        title={t`Something's not right!`}
        description={t`We could not fetch the reviews due to a problem!`}
        statusCode={500}
      />
    );
  }

  if (loading && !data) {
    return (
      <Grid container justifyContent="center">
        {loadingPlaceholder}
      </Grid>
    );
  }

  const {
    searchStoryReviews: { reviews, pagination },
  } = data;

  const loadMoreStoryReviews = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: STORY_REVIEWS_PAGINATION_LIMIT,
          offset: reviews.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          searchStoryReviews: {
            __typename: prev.searchStoryReviews.__typename,
            reviews: [
              ...prev.searchStoryReviews.reviews,
              ...fetchMoreResult.searchStoryReviews.reviews,
            ],
            pagination: fetchMoreResult.searchStoryReviews.pagination,
          },
        });
      },
    }).catch(() => {});
  };

  const noStoryReviewsPlaceholder = (
    <Grid container className={classes.noStoryReviewsPlaceholder}>
      {noResultsMessage ? (
        noResultsMessage
      ) : (
        <Typography variant="body2" color="textSecondary">
          <Trans>This story has no reviews yet!</Trans>
        </Typography>
      )}
    </Grid>
  );

  return (
    <Grid container>
      {!reviews.length ? noStoryReviewsPlaceholder : null}
      <InfiniteScroll
        style={{ overflow: "none !important" }}
        next={!fixedResultsCount && loadMoreStoryReviews}
        hasMore={reviews.length < pagination.totalCount}
        loader={
          !fixedResultsCount && (
            <StoryReviewsLoader xs={xs} md={md} lg={lg} xl={xl} />
          )
        }
        dataLength={reviews.length}
      >
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {reviews.map((review) => (
              <Grid key={review.id} item xs={xs} md={md} lg={lg} xl={xl}>
                {review.review ? (
                  <StoryReviewCard review={review} />
                ) : (
                  <StoryRatingCard review={review} />
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </InfiniteScroll>
    </Grid>
  );
}

StoryReviewsSearchController.propTypes = {
  searchArgs: PropTypes.object,
};

StoryReviewsSearchController.defaultProps = {
  searchArgs: {},
  cardSize: "md",
};
