import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { t } from "@lingui/macro";
import ShopsSearchController from "../../Shop/ShopsSearchController";

export default function UserShopsPage({ userId, searchQuery }) {
  return (
    <Grid container>
      <ShopsSearchController
        xs={12}
        md={6}
        searchArgs={{ query: searchQuery, ownerId: userId }}
        noResultsMessage={t`No shops found!`}
      />
    </Grid>
  );
}

UserShopsPage.propTypes = {
  userId: PropTypes.string.isRequired,
  searchQuery: PropTypes.string,
};
