import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import Hyperlink from "../Common/Hyperlink";
import UserAvatar from "../Common/UserAvatar";
import dayjs from "dayjs";
import { Rating } from "@material-ui/lab";
import { red } from "@material-ui/core/colors";
import BookIcon from "@material-ui/icons/Book";
import WarningIcon from "@material-ui/icons/Error";
import PrivateIcon from "@material-ui/icons/VisibilityOff";
import ImageFadeIn from "../Common/ImageFadeIn";
import { getReadingTime } from "../../utils/common";

const useStyles = makeStyles((theme) => {
  return {
    critiqueCard: {
      display: "flex",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(0.5),
      },
    },
    critiqueCardContent: {
      backgroundColor: theme.palette.grey[200],
      border: "2px solid",
      borderColor: theme.palette.grey[200],
      borderRadius: theme.spacing(2),
      position: "relative",
      "&:hover": {
        backgroundColor: theme.palette.primary.lighter,
        borderColor: theme.palette.primary.light,
        borderRadius: theme.spacing(2),
      },
    },
    critiqueName: {
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      marginBottom: theme.spacing(1),
    },
    visibilityIcon: {
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      fontSize: "18px",
    },
    critiqueContent: {
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      wordBreak: "break-word",
    },
    critiqueContentContainer: {
      marginBottom: theme.spacing(1),
    },
    timestamp: {
      fontSize: "12px",
      color: theme.palette.grey[500],
      width: "100%",
      textAlign: "right",
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
    critiqueCardDetails: {
      padding: theme.spacing(2),
      "&:hover": {
        backgroundColor: theme.palette.primary.lighter,
        borderColor: theme.palette.primary.light,
        borderRadius: theme.spacing(2),
      },
    },
    coverImage: {
      maxHeight: "150px",
      minHeight: "150px",
      width: "100%",
      objectFit: "cover",
      borderRadius: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 0`,
    },
    thumbUpAvatar: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      backgroundColor: theme.palette.primary.main,
    },
    thumbDownAvatar: {
      color: theme.palette.getContrastText(red[700]),
      backgroundColor: red[700],
    },
    votesCountContainer: {
      marginBottom: theme.spacing(0.5),
    },
    votesCountText: {
      textAlign: "right",
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
    bookTitle: {
      color: theme.palette.grey[700],
    },
    bookIcon: {
      color: theme.palette.grey[600],
      marginRight: theme.spacing(1),
      fontSize: 16,
    },
    visibility: {
      textAlign: "right",
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
    privateIcon: {
      fontSize: 14,
    },
    visibilityTypography: {
      justifyContent: "flex-end",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
    },
    visibilityContainer: {
      marginBottom: theme.spacing(0.5),
    },
    containsSpoilersFlag: {
      fontSize: 16,
    },
  };
});

export default function CritiqueCard({ critique }) {
  const classes = useStyles();
  const trimContent = (content) => {
    const desc = content || "";
    return desc.length <= 300 ? desc : desc.substring(0, 300) + "...";
  };

  return (
    <Hyperlink to={`/critique/${critique.id}`} className={classes.critiqueCard}>
      <Grid container className={classes.critiqueCardContent}>
        {critique.coverImage && (
          <Grid item xs={12}>
            <ImageFadeIn
              src={`${critique.coverImage}/lg`}
              className={classes.coverImage}
              alt={critique.title}
            />
          </Grid>
        )}
        <Grid item xs={12} className={classes.critiqueCardDetails}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  <Grid container>
                    {!!critique.book?.title && (
                      <Grid item xs={12}>
                        <Grid container alignItems="center">
                          <BookIcon
                            color="disabled"
                            className={classes.bookIcon}
                          />
                          <Typography
                            variant="subtitle1"
                            color="textPrimary"
                            className={classes.bookTitle}
                          >
                            {critique.book.title}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        color="textPrimary"
                        className={classes.critiqueName}
                      >
                        {critique.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        color="textPrimary"
                        className={classes.rating}
                      >
                        <Rating value={critique.rating} readOnly />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.critiqueContentContainer}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.critiqueContent}
                  >
                    {trimContent(critique.content)}
                  </Typography>
                </Grid>
                {critique.containsSpoilers && (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item>
                            <Grid container alignItems="center">
                              <WarningIcon color="secondary" />
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container alignItems="center">
                              <Typography
                                variant="body1"
                                color="secondary"
                                className={classes.containsSpoilersFlag}
                              >
                                This critique contains spoilers!
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <UserAvatar user={critique.critic} clickable={false} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container justifyContent="flex-end">
                    {!!critique.votesCount && (
                      <Grid
                        item
                        xs={12}
                        className={classes.votesCountContainer}
                      >
                        <Typography
                          variant="body2"
                          color="primary"
                          className={classes.votesCountText}
                        >
                          {critique.votesCount} Reader
                          {critique.votesCount > 1 ? "s" : ""} found this
                          helpful
                        </Typography>
                      </Grid>
                    )}
                    {!critique.published && (
                      <Grid
                        item
                        xs={12}
                        className={classes.visibilityContainer}
                      >
                        <Typography
                          variant="body2"
                          color="primary"
                          className={classes.visibility}
                        >
                          <Grid
                            container
                            spacing={1}
                            alignContent="stretch"
                            alignItems="center"
                            className={classes.visibilityTypography}
                          >
                            <Grid item>
                              <Grid container alignItems="center">
                                <PrivateIcon className={classes.privateIcon} />
                              </Grid>
                            </Grid>
                            <Grid item>Draft</Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Typography variant="body2" className={classes.timestamp}>
                        {getReadingTime(critique.content)} &bull;{" "}
                        {dayjs(critique.createdAt).format("MMMM D, YYYY")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Hyperlink>
  );
}

CritiqueCard.propTypes = {
  critique: PropTypes.object.isRequired,
};
