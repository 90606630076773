export const BC_MOTTO = "It's worth reading!";
export const BC_DESCRIPTION =
  "Sri Lanka’s First Distributed Online Library, Marketplace and Network for Books";

export const BACKEND_URL =
  import.meta.env.VITE_BACKEND_URL || "http://localhost:3001";
export const IMAGE_SERVER_URL =
  import.meta.env.VITE_IMAGE_SERVER_URL || "http://localhost:3002";
export const IS_TEST_ENVIRONMENT =
  import.meta.env.VITE_IS_TEST_ENVIRONMENT === "1";
export const FRONTEND_URL =
  import.meta.env.VITE_FRONTEND_URL || "http://localhost:3000";
export const IMAGE_HOST_TOPLEVEL_DOMAIN =
  import.meta.env.VITE_IMAGE_HOST_TOPLEVEL_DOMAIN || "bibliocircle.com";
export const LISTINGS_PAGINATION_LIMIT = 18;
export const LISTING_COLLECTIONS_PAGINATION_LIMIT = 24;
export const CRITIQUES_PAGINATION_LIMIT = 24;
export const FEED_PAGINATION_LIMIT = 12;
export const STORIES_PAGINATION_LIMIT = 18;
export const STORY_CHAPTERS_PAGINATION_LIMIT = 24;
export const STORY_REVIEWS_PAGINATION_LIMIT = 24;
export const STORY_READING_CHECKPOINTS_PAGINATION_LIMIT = 18;
export const DISCUSSIONS_PAGINATION_LIMIT = 10;
export const BOOK_REQUESTS_PAGINATION_LIMIT = 10;
export const GROUPS_PAGINATION_LIMIT = 24;
export const MESSAGES_PAGINATION_LIMIT = 24;
export const CHAT_PAGINATION_LIMIT = 12;
export const CHAT_GROUPS_PAGINATION_LIMIT = 24;
export const ORDERS_PAGINATION_LIMIT = 12;
export const SHOPS_PAGINATION_LIMIT = 24;
export const NOTIFICATIONS_PAGINATION_LIMIT = 15;
export const USERS_PAGINATION_LIMIT = 48;
export const USER_POINTS_PAGINATION_LIMIT = 20;
export const GLOBAL_NOTIFICATIONS_PAGINATION_LIMIT = 20;
export const NOTIFICATIONS_FETCH_INTERVAL_MS = 30 * 1000;
export const BC_CART_COOKIE_NAME = "bc_cart";
export const BC_AUTH_COOKIE_NAME = "bc_jwt";
export const BC_AUTH_REDIRECT_COOKIE_NAME = "bc_redirect";
export const BC_LANGUAGE_COOKIE_NAME = "bc_language";
export const RECAPTCHA_SITE_KEY = "6LdUwM8ZAAAAAGc7Eg7E1jk0OLOIpcpsX2VoX2My";

export const ALERT_AUTOHIDE_DURATION = 8000;
export const GENERIC_ALERT_ERROR_MESSAGE =
  "Something went wrong. Please try again later!";

export const TAGS_REF_DATA_BULK_LIMIT = 30;
export const AUTHORS_REF_DATA_BULK_LIMIT = 30;
export const PUBLISHERS_REF_DATA_BULK_LIMIT = 30;
