export const FREE_SHOP_TIER = "FREE";
export const EARLY_BIRD_SHOP_TIER = "EARLY_BIRD";
export const STARTER_SHOP_TIER = "STARTER";
export const ADVANCED_SHOP_TIER = "ADVANCED";
export const PREMIUM_BUSINESS_SHOP_TIER = "PREMIUM_BUSINESS";

export const ALL_SHOP_TIERS = [
  FREE_SHOP_TIER,
  EARLY_BIRD_SHOP_TIER,
  STARTER_SHOP_TIER,
  ADVANCED_SHOP_TIER,
  PREMIUM_BUSINESS_SHOP_TIER,
];

export const STARTER_UP_SHOP_TIERS = [
  STARTER_SHOP_TIER,
  ADVANCED_SHOP_TIER,
  PREMIUM_BUSINESS_SHOP_TIER,
];
export const ADVANCED_UP_SHOP_TIERS = [
  ADVANCED_SHOP_TIER,
  PREMIUM_BUSINESS_SHOP_TIER,
];
