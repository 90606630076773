import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { t } from "@lingui/macro";
import StoriesSearchController from "../../Stories/Story/StoriesSearchController";

export default function UserUnpublishedStoriesPage({ userId, searchQuery }) {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={10}>
        <StoriesSearchController
          searchArgs={{
            query: searchQuery,
            published: false,
            authorId: userId,
          }}
          xs={12}
          noResultsMessage={t`No Stories Found!`}
          cardSize="md"
        />
      </Grid>
    </Grid>
  );
}

UserUnpublishedStoriesPage.propTypes = {
  userId: PropTypes.string.isRequired,
  searchQuery: PropTypes.string,
};
