import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import { locations } from "../../consts";
import BootstrapInputBordered from "../BootstrapInputBordered";
import BDropDown from "../BDropDown";
import Required from "../Required";
import { grey } from "@material-ui/core/colors";
import addressSchema from "./address.schema";
import {
  extractFieldError,
  getFormattedError,
  validateObject,
} from "../../../utils/schemaValidator";

const useStyles = makeStyles((theme) => ({
  spannedInput: {
    width: "100%",
  },
}));

export default function AddressEditorDialog({
  address,
  onSave,
  open,
  onClose,
}) {
  const classes = useStyles();
  const [addressId, setAddressId] = useState(null);
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  const [city, setCity] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);
  const getFieldError = (fieldName) =>
    extractFieldError(validationErrors, fieldName);

  useEffect(() => {
    setAddressId(address.id);
    setAddressLine1(address.addressLine1);
    setAddressLine2(address.addressLine2);
    setAddressLine3(address.addressLine3);
    setCity(address.city);
    setIsDefault(address.isDefault);
  }, [address]);

  const onChangeAddressLine1 = (event) => setAddressLine1(event.target.value);
  const onChangeAddressLine2 = (event) => setAddressLine2(event.target.value);
  const onChangeAddressLine3 = (event) => setAddressLine3(event.target.value);
  const onChangeCity = (event) => setCity(event.target.value);
  const onChangeIsDefault = (event) => setIsDefault(event.target.checked);

  const onClickSave = () => {
    const addressData = {
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      isDefault,
    };
    if (addressId) addressData.id = addressId;

    const {
      validatedObject: validatedAddress,
      isValid,
      errors: validationErrors,
    } = validateObject(addressData, addressSchema);

    if (!isValid) {
      setValidationErrors(validationErrors);
      return;
    }

    onSave(validatedAddress);

    // Clear state
    setAddressId(null);
    setAddressLine1("");
    setAddressLine2("");
    setAddressLine3("");
    setCity("");
    setIsDefault(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick={true}
      maxWidth="xs"
      disableScrollLock={true}
    >
      <DialogTitle>New address</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl className={classes.spannedInput}>
              <InputLabel shrink color="primary">
                <Required>Address Line 1</Required>
              </InputLabel>
              <BootstrapInputBordered
                error={getFieldError("addressLine1")}
                bordered
                value={addressLine1}
                onChange={onChangeAddressLine1}
              />
              <FormHelperText
                error={getFieldError("addressLine1")}
                hidden={!getFieldError("addressLine1")}
              >
                {getFormattedError(getFieldError("addressLine1"))}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.spannedInput}>
              <InputLabel shrink color="primary">
                Address Line 2
              </InputLabel>
              <BootstrapInputBordered
                bordered
                error={getFieldError("addressLine2")}
                onChange={onChangeAddressLine2}
                value={addressLine2}
              />
              <FormHelperText
                error={getFieldError("addressLine2")}
                hidden={!getFieldError("addressLine2")}
              >
                {getFormattedError(getFieldError("addressLine2"))}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.spannedInput}>
              <InputLabel shrink color="primary">
                Address Line 3
              </InputLabel>
              <BootstrapInputBordered
                bordered
                error={getFieldError("addressLine3")}
                onChange={onChangeAddressLine3}
                value={addressLine3}
              />
              <FormHelperText
                error={getFieldError("addressLine3")}
                hidden={!getFieldError("addressLine3")}
              >
                {getFormattedError(getFieldError("addressLine3"))}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.spannedInput}>
              <InputLabel shrink color="primary">
                <Required>City</Required>
              </InputLabel>
              <BDropDown
                error={getFormattedError(getFieldError("city"))}
                bordered
                onChange={onChangeCity}
                value={city}
              >
                {locations.map((location) => (
                  <MenuItem key={location.value} value={location.value}>
                    {location.label}
                  </MenuItem>
                ))}
              </BDropDown>
              <FormHelperText
                error={getFieldError("city")}
                hidden={!getFieldError("city")}
              >
                {getFormattedError(getFieldError("city"))}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDefault}
                  onChange={onChangeIsDefault}
                  color="primary"
                />
              }
              label="Set this address as the default for future listings"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: grey[600] }}>
          Discard
        </Button>
        <Button onClick={onClickSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddressEditorDialog.propTypes = {
  address: PropTypes.object,
  onSave: PropTypes.func,
};

AddressEditorDialog.defaultProps = {
  address: {},
  onSave() {},
  open() {},
  onClose() {},
};
