import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  heading: {
    padding: theme.spacing(2),
  },
  chapterNumber: {
    fontSize: "16px",
  },
  title: {
    fontWeight: "bold",
  },
}));

export default function ChapterHeading({ chapter }) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              align="center"
              className={classes.chapterNumber}
            >
              <Trans>Chapter {chapter.chapterNumber}</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              color="textPrimary"
              align="center"
              className={classes.title}
            >
              {chapter.title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ChapterHeading.propTypes = {
  chapter: PropTypes.object.isRequired,
};
