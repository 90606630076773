import React from "react";
import { Grid, makeStyles, Avatar } from "@material-ui/core";
import NotificationIcon from "@material-ui/icons/Notifications";

const useStyles = makeStyles((theme) => ({
  item: {
    border: "none",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    marginBottom: 0,
    padding: 0,
    "& > *": {
      padding: 0,
    },
  },
  notifications: {
    paddingRight: "5px",
  },
  avatar: {
    width: "40px",
    minWidth: 0,
    margin: 0,
  },
}));

export function NotificationsCardLoader() {
  const classes = useStyles();
  return (
    <Grid container className={`ph-item ${classes.item}`}>
      <Grid container item xs={2} justifyContent="center" alignContent="center">
        <Grid item>
          <div className={`ph-avatar ${classes.avatar}`}></div>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <div className="ph-row">
          <div className="ph-col-8 empty"></div>
          <div className="ph-col-6 big"></div>
          <div className="ph-col-10"></div>
        </div>
      </Grid>
    </Grid>
  );
}

export function NotificationsLoader() {
  return (
    <>
      <NotificationsCardLoader />
      <NotificationsCardLoader />
      <NotificationsCardLoader />
    </>
  );
}
