import React from "react";
import PropTypes from "prop-types";
import ListingsSearchController from "../Search/ListingsSearchController";
import { useLocation } from "react-router-dom";
import { Grid } from "@material-ui/core";

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export default function ShopSearch({ shop }) {
  const queryParams = useQueryParams();
  return (
    <Grid container>
      <Grid container item xs={12}>
        <ListingsSearchController
          cachePolicy="cache-first"
          searchArgs={{
            query: queryParams.get("q"),
            location: queryParams.get("location"),
            category: queryParams.get("category"),
            priceModelType: queryParams.get("priceModelType"),
            shopScope: shop.id,
            language: queryParams.get("language"),
            minPrice: queryParams.get("minPrice"),
            maxPrice: queryParams.get("maxPrice"),
            tags: queryParams.get("tags"),
          }}
          noResultsMessage="No books found!"
          showEndOfResultsBanner={false}
          showResultsCount
          xs={12}
          md={6}
          lg={6}
          xl={4}
        />
      </Grid>
    </Grid>
  );
}

ShopSearch.propTypes = {
  shop: PropTypes.object,
};

ShopSearch.defaultProps = {
  shop: {},
};
