import React from "react";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { messages as enMessages } from "./locales/en/messages.mjs";
import { messages as siMessages } from "./locales/si/messages.mjs";
import { messages as taMessages } from "./locales/ta/messages.mjs";
import { useCookies } from "react-cookie";
import { BC_LANGUAGE_COOKIE_NAME } from "./config.js";

i18n.load("en", enMessages);
i18n.load("si", siMessages);
i18n.load("ta", taMessages);

const catalogs = {
  en: enMessages,
  si: siMessages,
  ta: taMessages,
};

const LanguageProvider = ({ children }) => {
  const [cookies] = useCookies([BC_LANGUAGE_COOKIE_NAME]);

  const preferredLanguage = cookies[BC_LANGUAGE_COOKIE_NAME] || "en";

  i18n.load(preferredLanguage, catalogs[preferredLanguage]);
  i18n.activate(preferredLanguage);

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};

export default LanguageProvider;
