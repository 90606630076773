import { Avatar, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { tryAndGetResizedImageUrl } from "../../../utils/images";
import Hyperlink from "../../Common/Hyperlink";
import { IMAGE_SIZES } from "../../consts";

const useStyles = makeStyles((theme) => ({
  actorImage: {
    height: 36,
    width: 36,
    marginRight: theme.spacing(1),
    borderWidth: theme.spacing(0.5),
    borderColor: theme.palette.primary.extraLighter,
    borderStyle: "solid",
  },
  actorImageContainer: {
    maxWidth: 42,
  },
  actorName: {
    color: theme.palette.primary.main,
  },
  actorDetails: {
    marginRight: theme.spacing(0.5),
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
}));

export default function PostHeader({
  actorFullName,
  actorImage,
  activityText,
  actorPath,
}) {
  const classes = useStyles();
  return (
    <Grid
      className={classes.actorDetails}
      container
      alignItems="center"
      spacing={3}
    >
      {actorImage && (
        <Grid className={classes.actorImageContainer} item xs={2}>
          <Grid container alignItems="center">
            <Avatar
              component={Link}
              to={actorPath}
              className={classes.actorImage}
              src={tryAndGetResizedImageUrl(actorImage, IMAGE_SIZES.XXS)}
            />
          </Grid>
        </Grid>
      )}
      <Grid item xs={actorImage ? 10 : 12}>
        <Grid container alignItems="center">
          <Typography variant="subtitle2">
            <Hyperlink to={actorPath} className={classes.actorName}>
              {actorFullName}
            </Hyperlink>{" "}
            {activityText}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
