import Joi from "@hapi/joi";

export default Joi.object({
  resourceId: Joi.string().required(),
  type: Joi.string().required(),
  reason: Joi.string().required(),
  message: Joi.any().when("reason", {
    is: Joi.string().regex(/^OTHER$/),
    then: Joi.string().required(),
    otherwise: Joi.string().optional().allow("", null),
  }),
  reporterEmail: Joi.string()
    .email({ tlds: { allow: false } })
    .optional(),
});
