import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Listings from "../Common/Listings";
import { useQuery } from "@apollo/client";
import NoResults from "../Common/NoResultsImage";
import { SEARCH_LISTINGS } from "../../consts/queries";
import InfiniteScroll from "react-infinite-scroll-component";
import { LISTINGS_PAGINATION_LIMIT } from "../../config";
import ListingsEndBanner from "../Common/ListingsEndBanner";
import { ListingLoader } from "../Common/LoadingPlaceholders/ListingsLoader";
import ErrorPage from "../Error/ErrorPage";
import { useLoader, useQueryParams } from "../../utils/hooks";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  noBooksPlaceholder: {
    padding: theme.spacing(3),
  },
  noBooksPlaceholderText: {
    fontWeight: "bold",
  },
  searchTitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function ListingsSearchController({
  searchArgs,
  cachePolicy,
  clickableCards,
  enableActions,
  noResultsMessage,
  showEndOfResultsBanner,
  showResultsCount,
  resultsLimit,
  xs,
  sm,
  md,
  lg,
  xl,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const queryParams = useQueryParams();
  const sanitiseSearchArgs = (searchArgs) => {
    return Object.keys(searchArgs)
      .filter(
        (key) => searchArgs[key] !== null && searchArgs[key] !== undefined
      )
      .reduce((result, key) => {
        result[key] = searchArgs[key];
        return result;
      }, {});
  };
  const formattedSearchArgs = {
    query: searchArgs.query,
    isbn: searchArgs.isbn,
    isbnList: searchArgs.isbnList,
    location: searchArgs.location,
    priceModelType: searchArgs.priceModelType,
    category: searchArgs.category,
    language: searchArgs.language,
    groupScope: searchArgs.groupScope,
    userScope: searchArgs.userScope,
    shopScope: searchArgs.shopScope,
    condition: searchArgs.condition,
    favouritedUserScope: searchArgs.favouritedUserScope,
    bookRequestScope: searchArgs.bookRequestScope,
    listingCollectionScope: searchArgs.listingCollectionScope,
    listingIds: searchArgs.listingIds,
    active: searchArgs.active,
    inactiveReasons: searchArgs.inactiveReasons,
    tags: searchArgs.tags,
  };
  if (
    ![null, undefined].includes(searchArgs.minPrice) &&
    !isNaN(searchArgs.minPrice)
  ) {
    formattedSearchArgs.minPrice = +searchArgs.minPrice;
  }
  if (
    ![null, undefined].includes(searchArgs.maxPrice) &&
    !isNaN(searchArgs.maxPrice)
  ) {
    formattedSearchArgs.maxPrice = +searchArgs.maxPrice;
  }

  if (searchArgs.tags?.length) {
    try {
      formattedSearchArgs.tags = searchArgs.tags
        .split(",")
        .map((tag) => tag.trim());
    } catch {}
  }

  const fetchOptions = {
    variables: {
      ...sanitiseSearchArgs(formattedSearchArgs),
      pagination: {
        limit: resultsLimit || LISTINGS_PAGINATION_LIMIT,
        offset: 0,
      },
    },
  };
  if (cachePolicy) fetchOptions.fetchPolicy = cachePolicy;
  const { loading, error, data, fetchMore } = useQuery(
    SEARCH_LISTINGS,
    fetchOptions
  );

  useLoader(loading);

  const loadingPlaceholder = (
    <ListingLoader xs={xs} sm={sm} md={md} lg={lg} xl={xl} />
  );

  if (loading && !data) {
    return (
      <Grid container justifyContent="center">
        {loadingPlaceholder}
      </Grid>
    );
  }

  if (error) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the book you were looking for. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  const {
    searchListings: { listings, pagination },
  } = data;

  const noBooksPlaceHolder = (
    <Grid
      container
      justifyContent="center"
      className={classes.noBooksPlaceholder}
    >
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="h5"
          color="primary"
          className={classes.noBooksPlaceholderText}
        >
          {noResultsMessage ? noResultsMessage : "Sorry, no books here :("}
        </Typography>
      </Grid>
      <Grid container item xs={10} sm={6} lg={4}>
        <NoResults />
      </Grid>
    </Grid>
  );

  const loadMoreListings = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: LISTINGS_PAGINATION_LIMIT,
          offset: listings.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          searchListings: {
            __typename: prev.searchListings.__typename,
            listings: [
              ...prev.searchListings.listings,
              ...fetchMoreResult.searchListings.listings,
            ],
            pagination: fetchMoreResult.searchListings.pagination,
          },
        });
      },
    }).catch(() => {});
  };

  const hasMore = () => {
    const more = listings.length < pagination.totalCount;
    if (resultsLimit) {
      return more && listings.length < resultsLimit;
    }
    return more;
  };

  const getSearchResultsCount = () => {
    try {
      const isSearch = [...queryParams.keys()].length > 0;
      if (isSearch) {
        return (
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.searchTitle}>
              <Trans>{pagination.totalCount} Results found!</Trans>
            </Typography>
          </Grid>
        );
      }
    } catch (err) {}
    return null;
  };

  return (
    <Grid container spacing={2}>
      {showResultsCount && getSearchResultsCount()}
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          {!listings.length ? noBooksPlaceHolder : null}
          <InfiniteScroll
            style={{ overflow: "none !important" }}
            dataLength={listings.length}
            next={loadMoreListings}
            hasMore={hasMore()}
            loader={<ListingLoader xs={xs} sm={sm} md={md} lg={lg} xl={xl} />}
            endMessage={showEndOfResultsBanner ? <ListingsEndBanner /> : null}
          >
            <Listings
              variant={isMobile ? "mobile" : "desktop"}
              items={listings}
              clickableCards={clickableCards}
              enableActions={enableActions}
              xs={xs}
              sm={sm}
              md={md}
              lg={lg}
              xl={xl}
            />
          </InfiniteScroll>
        </Grid>
      </Grid>
    </Grid>
  );
}

ListingsSearchController.propTypes = {
  searchArgs: PropTypes.object,
  cachePolicy: PropTypes.string,
  clickableCards: PropTypes.bool,
  enableActions: PropTypes.bool,
  noResultsMessage: PropTypes.string,
  showEndOfResultsBanner: PropTypes.bool,
  showResultsCount: PropTypes.bool,
  resultsLimit: PropTypes.number,
  xs: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
};

ListingsSearchController.defaultProps = {
  clickableCards: true,
  enableActions: false,
  showEndOfResultsBanner: true,
  showResultsCount: false,
  searchArgs: {},
};
