import React from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  Fade,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import LocationIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Call";
import FollowIcon from "@material-ui/icons/Notifications";
import FavoriteOnIcon from "@material-ui/icons/Favorite";
import FavoriteOffIcon from "@material-ui/icons/FavoriteBorder";
import AboutIcon from "@material-ui/icons/Info";
import Hyperlink from "../Common/Hyperlink";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
  heading: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
    padding: theme.spacing(2),
    backgroundColor: "rgba(250, 250, 250, 0.7)",
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  headingInnerContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  shopName: {
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      textAlign: "center",
    },
  },
  shopDescription: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      textAlign: "center",
    },
    fontSize: 16,
  },
  shopDescriptionContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  logo: {
    height: "150px",
    width: "150px",
    objectFit: "contain",
    border: "5px solid white",
    backgroundColor: "white",
  },
  logoContainer: {
    justifyContent: "center",
    height: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  imageContainer: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: 100,
    height: "150px",
    width: "150px",
  },
  mobileContactsSection: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mobileOnly: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  desktopOnly: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  contactsIcon: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    fontSize: 18,
  },
  contactNumbersContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    paddingBottom: theme.spacing(1),
  },
  addressesContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      marginTop: theme.spacing(1),
    },
    paddingBottom: theme.spacing(1),
  },
  actionButtonsContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  followBtn: {
    minWidth: 155,
  },
  favouriteBtn: {
    minWidth: 225,
  },
}));

export default function ShopHeading({
  shop,
  onClickToggleFavourites,
  onClickToggleFollow,
}) {
  const classes = useStyles();
  const { name, description, contactNumbers, logoImage } = shop;
  const addressesComponent = (
    <Grid item xs={12}>
      <Grid
        container
        alignItems="center"
        className={classes.addressesContainer}
        alignContent="stretch"
        spacing={1}
      >
        <Grid item>
          <Grid container alignItems="center">
            <LocationIcon color="primary" className={classes.contactsIcon} />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <Typography variant="body2" color="textPrimary">
                {(shop.regions || []).join(", ")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const contactNumbersComponent = (
    <Grid item xs={12}>
      <Grid
        container
        alignItems="center"
        alignContent="stretch"
        spacing={1}
        className={classes.contactNumbersContainer}
      >
        <Grid item>
          <Grid container alignItems="center">
            <PhoneIcon color="primary" className={classes.contactsIcon} />
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textPrimary">
            {contactNumbers.map(({ phoneNumber }) => phoneNumber).join(", ")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const contactsSection = (
    <Grid container item xs={12}>
      {!!(shop.regions || []).length && (
        <Grid container item xs={12}>
          {addressesComponent}
        </Grid>
      )}
      {!!contactNumbers.length && (
        <Grid container item xs={12}>
          {contactNumbersComponent}
        </Grid>
      )}
    </Grid>
  );

  const actionButtons = (
    <Grid container spacing={2} className={classes.actionButtonsContainer}>
      <Grid item>
        <Tooltip title={t`You can find your favourite shops in your profile`}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            startIcon={
              shop.isFavourite ? <FavoriteOnIcon /> : <FavoriteOffIcon />
            }
            onClick={onClickToggleFavourites}
            className={classes.favouriteBtn}
          >
            {shop.isFavourite
              ? t`Remove from Favourites`
              : t`Add to Favourites`}
          </Button>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title={t`Get notified when new books are added to the shop`}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            startIcon={<FollowIcon />}
            onClick={onClickToggleFollow}
            className={classes.followBtn}
          >
            {shop.isFollowed ? t`Unfollow Shop` : t`Follow Shop`}
          </Button>
        </Tooltip>
      </Grid>
      <Grid item>
        <Button
          component={Hyperlink}
          to={`/shop/${shop.identifier}/about`}
          variant="contained"
          color="primary"
          disableElevation
          startIcon={<AboutIcon />}
        >
          <Trans>About Shop</Trans>
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Grid container className={classes.heading}>
      <Grid item>
        <Grid container spacing={3} className={classes.headingInnerContainer}>
          <Grid item>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={classes.logoContainer}
            >
              <Grid item className={classes.imageContainer}>
                <Grid container justifyContent="center" alignItems="center">
                  <Fade in>
                    <Avatar
                      src={logoImage || "/shop-placeholder.svg"}
                      className={classes.logo}
                    />
                  </Fade>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  className={classes.shopName}
                  color="textPrimary"
                >
                  {name}
                </Typography>
              </Grid>
              {description && (
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.shopDescriptionContainer}
                >
                  <Typography
                    variant="subtitle2"
                    className={classes.shopDescription}
                  >
                    {description}
                  </Typography>
                </Grid>
              )}
              <Grid container item xs={12} className={classes.desktopOnly}>
                {contactsSection}
              </Grid>
              <Grid item xs={12} className={classes.mobileContactsSection}>
                <Grid container>{contactsSection}</Grid>
              </Grid>
              <Grid item xs={12}>
                {actionButtons}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ShopHeading.propTypes = {
  shop: PropTypes.object.isRequired,
  onClickToggleFavourites: PropTypes.func.isRequired,
  onClickToggleFollow: PropTypes.func.isRequired,
};

ShopHeading.defaultProps = {
  onClickToggleFavourites: () => {},
  onClickToggleFollow: () => {},
};
