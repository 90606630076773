import { Grid, Typography } from "@material-ui/core";
import React from "react";
import GlobalNotificationsSearchController from "./GlobalNotificationsSearchController";

export default function GlobalNotifications() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography align="center" variant="h6">
              Global Notifications
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <GlobalNotificationsSearchController spacing={2} xs={12} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
