import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    border: "1px solid",
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.grey[200],
    backgroundColor: theme.palette.grey[100],
    width: "100%",
    marginBottom: 0,
  },
  loader: {
    marginBottom: theme.spacing(2),
  },
}));

export function MessagesCardLoader() {
  const classes = useStyles();
  return (
    <div className={`ph-item ${classes.item}`}>
      <div>
        <div className="ph-row">
          <div className="ph-col-12"></div>
          <div className="ph-col-4"></div>
          <div className="ph-col-6 empty"></div>
          <div className="ph-col-2"></div>
        </div>
      </div>
    </div>
  );
}

export function MessagesLoader({ xs, sm, md, lg, xl }) {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="flex-start"
      spacing={1}
      className={classes.loader}
    >
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <MessagesCardLoader />
      </Grid>
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <MessagesCardLoader />
      </Grid>
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        <MessagesCardLoader />
      </Grid>
    </Grid>
  );
}
