import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { TEMP_LISTING_INACTIVE_REASONS } from "../consts";

const useStyles = makeStyles((theme) => ({
  deactivateOption: {
    marginBottom: theme.spacing(2),
  },
}));

export default function DeactivateListingDialog({ open, onCancel, onSubmit }) {
  const classes = useStyles();
  const [reason, setReason] = useState("OTHER");
  const onChangeReason = (event) => setReason(event.target.value);
  const handleDecision = () => onSubmit(reason === "OTHER" ? null : reason);
  return (
    <Dialog open={open} onClose={onCancel} disableScrollLock={true}>
      <DialogTitle>Temporarily Deactivate Advertisement</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Please select the reason for deactivating the advertisement.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="deactivate-reason"
                  value={reason}
                  onChange={onChangeReason}
                >
                  {TEMP_LISTING_INACTIVE_REASONS.map(
                    ({ label, value, sellerDescription }) => (
                      <FormControlLabel
                        value={value}
                        className={classes.deactivateOption}
                        control={<Radio />}
                        label={
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography variant="body1">{label}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="caption">
                                {sellerDescription}
                              </Typography>
                            </Grid>
                          </Grid>
                        }
                      />
                    )
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="default">
          Cancel
        </Button>
        <Button onClick={handleDecision} color="secondary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
