import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import ImageFadeIn from "./ImageFadeIn";

const useStyles = makeStyles((theme) => ({
  noResultsImage: {
    marginTop: theme.spacing(2),
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(5),
    },
  },
}));

export default function NoResults() {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="center"
      alignItems="flex-start"
    >
      <ImageFadeIn
        src={`/no_results_1.svg`}
        alt="no results"
        className={classes.noResultsImage}
      />
    </Grid>
  );
}
