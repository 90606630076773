import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@material-ui/core";
import CartItem from "./CartItem";
import Currency from "../Common/Currency";
import Banner from "../Common/Banner";
import PhoneNumberManager from "../Common/PhoneNumbers/PhoneNumberManager";
import AddressManager from "../Common/Addresses/AddressManager";
import { useDispatch } from "react-redux";
import { actionTypes } from "../index.reducer";
import { t, Trans } from "@lingui/macro";
import TextEditor from "../Common/TextEditor";
import { getUserFullName } from "../../utils/common";

const useStyles = makeStyles((theme) => ({
  orderSummaryTitle: {
    fontWeight: "bold",
    color: theme.palette.grey[700],
    fontSize: "18px",
  },
  cartTotal: {
    fontWeight: "bold",
  },
  cartGroup: {
    backgroundColor: theme.palette.grey[100],
    border: "5px solid",
    borderColor: theme.palette.grey[200],
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  shopName: {
    fontWeight: "bold",
    color: theme.palette.grey[700],
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  deliveryOptionTitle: {
    fontWeight: "bold",
    color: theme.palette.grey[700],
  },
  orderSummaryContainer: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },
  orderItemsContainer: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
  desktopOnly: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mobileOnly: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  customerNotesContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  shopClosedBanner: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function CartGroup({
  items,
  shop,
  seller,
  onRemoveCartItem,
  onChangeItemQuantity,
  onPlaceOrder,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let defaultDeliveryPreference;
  let noDeliveryPrefsSet = true;
  if (shop) {
    defaultDeliveryPreference = shop.deliveryPreferences.find(
      (pref) => pref.type === "PICK_UP_AT_STORE"
    );
    noDeliveryPrefsSet = !shop.deliveryPreferences.length;
  }
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [contactNumbers, setContactNumbers] = useState([]);
  const [deliveryPreference, setDeliveryPreference] = useState(
    defaultDeliveryPreference || null
  );
  const [deliveryPreferenceError, setDeliveryPreferenceError] = useState("");
  const [deliveryAddressRequiredError, setDeliveryAddressRequiredError] =
    useState(false);
  const [customerNotes, setCustomerNotes] = useState(null);

  const dispatchErrorMessage = (message) =>
    dispatch({
      type: actionTypes.ERROR_MESSAGE,
      message,
    });
  const handleRemoveCartItem = (listingId) => () => onRemoveCartItem(listingId);
  const handleChangeCustomerNotes = (event) =>
    setCustomerNotes(event.target.value);

  const onClickPlaceOrder = () => {
    if (!noDeliveryPrefsSet && shop) {
      // This order is from a Shop
      if (!deliveryPreference) {
        const errorMessage = t`Please select a delivery preference`;
        setDeliveryPreferenceError(errorMessage);
        dispatchErrorMessage(errorMessage);
        return;
      }
      if (deliveryPreference.type === "HOME_DELIVERY" && !deliveryAddress) {
        return setDeliveryAddressRequiredError(t`Delivery address is required`);
      }
    }
    onPlaceOrder({
      shopId: shop?.id,
      sellerId: seller?.id,
      items: items.map((item) => ({
        id: item.listing.id,
        quantity: item.quantity,
      })),
      deliveryPreferenceId: deliveryPreference?.id,
      userContactNumberIds: contactNumbers,
      deliveryAddressId: deliveryAddress,
      customerNotes,
    });
  };

  const onChangeDeliveryPreference = (event) => {
    setDeliveryPreferenceError(null);
    const prefId = event.target.value;
    setDeliveryPreference(
      shop.deliveryPreferences.find((pref) => pref.id === prefId)
    );
  };

  const getOrderTotal = (cartItems) =>
    cartItems.reduce((total, cartItem) => {
      const { listing, quantity } = cartItem;
      if (!listing || !quantity) return total;
      return total + listing.price * quantity;
    }, 0);

  const onChangeDeliveryAddress = (addressId) => {
    setDeliveryAddressRequiredError("");
    setDeliveryAddress(addressId);
  };
  const onChangeContactNumbers = (contactNumberId, selected) => {
    if (selected) {
      setContactNumbers((ids) =>
        Array.from(new Set([...ids, contactNumberId]))
      );
    } else {
      setContactNumbers((ids) => ids.filter((id) => id !== contactNumberId));
    }
  };

  const orderItems = (
    <Grid
      container
      item
      xs={12}
      md={8}
      alignContent="flex-start"
      className={classes.orderItemsContainer}
    >
      <Grid container spacing={2}>
        {items.map((cartItem) => (
          <Grid item xs={12} key={cartItem.listing.id}>
            <CartItem
              key={cartItem.listing.id}
              item={cartItem.listing}
              quantity={cartItem.quantity}
              onChangeQuantity={onChangeItemQuantity(cartItem.listing.id)}
              onRemoveFromCart={handleRemoveCartItem(cartItem.listing.id)}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  const orderTotal = (
    <Grid
      container
      item
      xs={12}
      spacing={2}
      className={classes.orderSummaryContainer}
    >
      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12}>
            <Typography
              variant="h6"
              align="center"
              className={classes.orderSummaryTitle}
            >
              <Trans>Order Total</Trans>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="h5"
              align="center"
              color="primary"
              className={classes.cartTotal}
            >
              <Currency value={getOrderTotal(items)} displayZero />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Typography variant="subtitle2" color="primary" align="center">
              <Trans>
                Please contact the seller regarding the payment methods they
                accept. (e.g, cash on delivery/bank deposit &amp; etc.)
              </Trans>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const orderContacts = (
    <Grid container item xs={12} justifyContent="center" spacing={2}>
      <Grid item xs={10}>
        <Typography variant="body2">
          <Trans>
            Please select contact numbers that the seller can reach you by if
            needed:
          </Trans>
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <PhoneNumberManager
          selectable
          selectedNumbers={contactNumbers}
          onChangeSelection={onChangeContactNumbers}
        />
      </Grid>
    </Grid>
  );

  const orderDeliveryAddress = (
    <Grid container item xs={12} justifyContent="center" spacing={2}>
      <Grid item xs={10}>
        <Typography variant="body2">
          <Trans>Please select the delivery address for this order</Trans>
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AddressManager
              error={!!deliveryAddressRequiredError}
              selectable
              singleSelect
              selectedAddresses={deliveryAddress ? [deliveryAddress] : []}
              onChangeSelection={onChangeDeliveryAddress}
            />
          </Grid>
          <Grid item xs={12}>
            {deliveryAddressRequiredError && (
              <FormHelperText error>
                {deliveryAddressRequiredError}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const placeOrderButton = (
    <Grid item xs={12} justifyContent="center">
      <Grid container spacing={2}>
        {shop?.closed && (
          <Grid item xs={12}>
            <Grid container className={classes.shopClosedBanner}>
              <Banner
                type="warning"
                message={t`${shop.name} is temporarily not accepting orders. Please check again later.`}
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Button
              disabled={shop?.closed}
              disableElevation
              variant="contained"
              color="primary"
              onClick={onClickPlaceOrder}
              size="large"
            >
              <Trans>Place Order</Trans>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const getFormattedDeliveryPreference = (type) => {
    switch (type) {
      case "HOME_DELIVERY":
        return t`Home Delivery`;
      case "PICK_UP_AT_STORE":
        return t`Pick-up at Store`;
      default:
        return t`Unknown Delivery Type (Contact Seller)`;
    }
  };

  const orderDeliveryPreference = (
    <Grid container item xs={12} justifyContent="center" spacing={2}>
      <Grid container item xs={12} md={10} spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            align="center"
            className={classes.orderSummaryTitle}
          >
            <Trans>Delivery Preference</Trans>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <RadioGroup aria-label="gender" name="gender1">
              <Grid container spacing={2}>
                {shop &&
                  shop.deliveryPreferences.map((pref, i) => (
                    <Grid item xs={12} key={`${i}-${pref.type}`}>
                      <FormControlLabel
                        value={pref.id}
                        checked={pref.id === deliveryPreference?.id}
                        onChange={onChangeDeliveryPreference}
                        control={<Radio />}
                        label={
                          <>
                            <Typography variant="body1">
                              {getFormattedDeliveryPreference(pref.type)}
                            </Typography>
                            <Typography variant="caption">
                              {pref.notes}
                            </Typography>
                          </>
                        }
                      />
                    </Grid>
                  ))}
              </Grid>
            </RadioGroup>
          </FormControl>
          {deliveryPreferenceError && (
            <FormHelperText error>{deliveryPreferenceError}</FormHelperText>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  const noDeliveryPrefsBanner = (
    <Grid container item xs={12} justifyContent="center">
      <Grid container item xs={10}>
        <Banner
          type="error"
          message={t`${
            shop?.name || "This seller"
          } has not specified whether these items can be picked up or delivered. Please contact the seller to agree on a delivery method.`}
        />
      </Grid>
    </Grid>
  );

  const orderSidebar = (
    <Grid item xs={12} md={4}>
      <Grid container alignContent="stretch" spacing={3}>
        <Grid item xs={12} className={classes.desktopOnly}>
          {orderTotal}
        </Grid>
        {orderContacts}
        {!noDeliveryPrefsSet && orderDeliveryPreference}
        {shop && noDeliveryPrefsSet && noDeliveryPrefsBanner}
        {deliveryPreference?.type === "HOME_DELIVERY" && orderDeliveryAddress}
        <Grid item xs={12}>
          <Grid container className={classes.customerNotesContainer}>
            <TextEditor
              disabled={shop?.closed}
              rowsMin={3}
              value={customerNotes}
              placeholder={t`Additional message you'd like to send to the seller regarding this order.`}
              onChange={handleChangeCustomerNotes}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {placeOrderButton}
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container spacing={2} className={classes.cartGroup}>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.shopName}>
            <Trans>
              Order from {shop ? shop.name : getUserFullName(seller)}
            </Trans>
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.mobileOnly}>
          {orderTotal}
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        {orderItems}
        {orderSidebar}
      </Grid>
    </Grid>
  );
}

CartGroup.propTypes = {
  items: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
};
