import Joi from "@hapi/joi";

export default Joi.object({
  id: Joi.string().optional().allow("", null),
  addressLine1: Joi.string().required(),
  addressLine2: Joi.string().optional().allow("", null),
  addressLine3: Joi.string().optional().allow("", null),
  city: Joi.string().required(),
  isDefault: Joi.boolean().optional(),
});
