import Joi from "@hapi/joi";
import { ISBN_REGEX } from "../../utils/common";

export default Joi.object({
  id: Joi.string().optional(),
  title: Joi.string().required(),
  content: Joi.string().required(),
  rating: Joi.number().min(1).max(5).required(),
  criticRecommends: Joi.boolean().required(),
  published: Joi.boolean().required(),
  containsSpoilers: Joi.boolean().required(),
  book: Joi.object({
    index: Joi.any().optional().strip(),
    id: Joi.string().optional().allow("", null),
    title: Joi.string().required(),
    images: Joi.array().items(Joi.string().optional()).optional(),
    subtitle: Joi.string().optional().allow("", null),
    year: Joi.number().optional(),
    condition: Joi.string().allow("", null).optional(),
    description: Joi.string().allow("", null).optional(),
    authors: Joi.array().items(
      Joi.object({
        name: Joi.string().required(),
      })
    ),
    isbn: Joi.string().trim().required().regex(ISBN_REGEX),
    alternativeISBNs: Joi.array()
      .items(Joi.string().trim().required().regex(ISBN_REGEX).optional())
      .optional(),
    publisher: Joi.optional().allow("", null),
    pageCount: Joi.number().allow("", null).optional(),
    url: Joi.optional().allow("", null),
    category: Joi.optional().allow("", null),
    language: Joi.optional().allow("", null),
  }).required(),
  coverImage: Joi.optional().allow("", null),
});
