import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import IncomingOrdersSearchController from "../../Orders/IncomingOrders/IncomingOrdersSearchController";

export default function UserIncomingOrdersPage({ userId }) {
  return (
    <Grid container spacing={2}>
      <IncomingOrdersSearchController searchArgs={{ sellerId: userId }} />
    </Grid>
  );
}

UserIncomingOrdersPage.propTypes = {
  userId: PropTypes.string.isRequired,
};
