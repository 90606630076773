import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { Select, InputBase } from "@material-ui/core";
import BootstrapInputBordered from "./BootstrapInputBordered";

const useStyles = makeStyles((theme) => ({
  dropDown: {
    width: "100%",
  },
  dropDownBackDrop: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    fill: theme.palette.text.primary,
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    color: theme.palette.text.primary,
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    border: "none",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
  },
}))(InputBase);

export default function DropDown(props) {
  const classes = useStyles();
  const { label, options = [], bordered } = props;
  return (
    <Select
      {...props}
      placeholder={label}
      input={bordered ? <BootstrapInputBordered /> : <BootstrapInput />}
      defaultValue={options.find((op) => op.default)?.value}
      className={classes.dropDown}
      inputProps={{
        classes: {
          icon: classes.icon,
        },
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

DropDown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      default: PropTypes.bool,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
};
