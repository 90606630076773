import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { blue, deepPurple, lime, orange } from "@material-ui/core/colors";
import StoryIcon from "./StoryIcon";
import StoryOpenIcon from "./StoryOpenIcon";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { Trans } from "@lingui/macro";
import { useLoggedInUser, useLoginPrompt } from "../../utils/hooks";
import ScrollableLinkTabs from "../Common/ScrollableLinkTabs";

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    padding: theme.spacing(1),
  },
}));

const ICON_COLOR_DARKNESS = 400;
const ICON_BACKGROUND_DARKNESS = 50;

export default function StoriesActionsPanel() {
  const classes = useStyles();
  const loggedInUser = useLoggedInUser();
  const { createLoginPromptEventHandler } = useLoginPrompt();
  const links = [
    {
      icon: StoryIcon,
      iconColor: lime[ICON_COLOR_DARKNESS],
      iconBackgroundColor: lime[ICON_BACKGROUND_DARKNESS],
      title: <Trans>Write New Story</Trans>,
      path: "/story/new",
      onClickHandler: createLoginPromptEventHandler({
        redirectPath: "/story/new",
      }),
    },
    {
      icon: LibraryBooksIcon,
      iconColor: orange[ICON_COLOR_DARKNESS],
      iconBackgroundColor: orange[ICON_BACKGROUND_DARKNESS],
      title: <Trans>Your Published Stories</Trans>,
      path: !!loggedInUser ? `/profile/${loggedInUser.id}/page/stories` : "#",
      onClickHandler: createLoginPromptEventHandler({
        redirectPath: loggedInUser
          ? `/profile/${loggedInUser.id}/page/stories`
          : null,
      }),
    },
    {
      icon: LibraryBooksIcon,
      iconColor: deepPurple[ICON_COLOR_DARKNESS],
      iconBackgroundColor: deepPurple[ICON_BACKGROUND_DARKNESS],
      title: <Trans>Your Draft Stories</Trans>,
      path: !!loggedInUser
        ? `/profile/${loggedInUser.id}/page/unpublished-stories`
        : "#",
      onClickHandler: createLoginPromptEventHandler({
        redirectPath: loggedInUser
          ? `/profile/${loggedInUser.id}/page/unpublished-stories`
          : null,
      }),
    },
    {
      icon: StoryOpenIcon,
      iconColor: blue[ICON_COLOR_DARKNESS],
      iconBackgroundColor: blue[ICON_BACKGROUND_DARKNESS],
      title: <Trans>Stories you are reading</Trans>,
      path: !!loggedInUser
        ? `/profile/${loggedInUser.id}/page/stories-reading`
        : "#",
      onClickHandler: createLoginPromptEventHandler({
        redirectPath: loggedInUser
          ? `/profile/${loggedInUser.id}/page/stories-reading`
          : null,
      }),
    },
  ];
  return (
    <Grid container className={classes.actionsContainer}>
      <Grid item xs={12}>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <ScrollableLinkTabs links={links} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
