import React from "react";
import { useParams } from "react-router-dom";
import OutgoingOrderCard from "./OutgoingOrders/OutgoingOrderCard";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ORDER } from "../../consts/queries";
import { Grid, makeStyles } from "@material-ui/core";
import IncomingOrderCard from "./IncomingOrders/IncomingOrderCard";
import MessageThread from "../Messages/MessageThread";
import { CREATE_ORDER_MESSAGE } from "../../consts/mutations";
import PageHead from "../Common/PageHead";
import ErrorPage from "../Error/ErrorPage";
import { useLoader, useLoggedInUser, useRequireLogin } from "../../utils/hooks";

const useStyles = makeStyles((theme) => ({
  orderCardContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export default function Order() {
  const classes = useStyles();
  const params = useParams();
  const [sendMessage, { loading: sendingMessage }] =
    useMutation(CREATE_ORDER_MESSAGE);
  const { orderId } = params;
  const loggedInUser = useLoggedInUser();
  const {
    data,
    error: fetchOrderError,
    loading,
  } = useQuery(GET_ORDER, {
    variables: { id: orderId },
    skip: !loggedInUser, // do not attempt to fetch if the user is not logged in
  });
  useRequireLogin();

  useLoader(sendingMessage || loading);

  if (fetchOrderError) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the order you were looking for. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  if (!data) return null;
  const { order } = data;

  if (!order) {
    return (
      <ErrorPage
        title="Not Found"
        description="The order you were looking for does not exist!"
        statusCode={404}
      />
    );
  }

  const onSendOrderMessage = (message) => {
    return sendMessage({
      variables: {
        message,
        orderId,
      },
    });
  };

  const isBuyer = order.user?.id === loggedInUser.id;
  const isShopOwner = order.shop && order.shop.owner.id === loggedInUser.id;
  const isIndividualSeller = !order.shop && order.seller.id === loggedInUser.id;

  const OrderCardComponent = isBuyer
    ? OutgoingOrderCard
    : isShopOwner || isIndividualSeller
    ? IncomingOrderCard
    : null;

  return OrderCardComponent ? (
    <Grid container justifyContent="center">
      <PageHead title="Order Details | bibliocircle" />
      <Grid item xs={12} md={10} lg={8} className={classes.orderCardContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OrderCardComponent order={order} showMessageThread />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item xs={11} sm={10} lg={10}>
                <MessageThread
                  threadId={order.messageThreadId}
                  inputPlaceholder={`Type a message to the ${
                    isBuyer ? "seller" : "customer"
                  }`}
                  xs={12}
                  onSendMessage={onSendOrderMessage}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
}
