import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import ImageFadeIn from "../../../Common/ImageFadeIn";
import Hyperlink from "../../../Common/Hyperlink";

const CATEGORY_CARD_SIZE = 150;
const CATEGORY_CARD_MOBILE_SIZE = 120;

const useStyles = makeStyles((theme) => ({
  highlightedCategoryCard: {
    zIndex: 1,
    maxHeight: CATEGORY_CARD_SIZE,
    maxWidth: CATEGORY_CARD_SIZE,
    borderRadius: CATEGORY_CARD_SIZE * 2,
    [theme.breakpoints.down("xs")]: {
      maxHeight: CATEGORY_CARD_MOBILE_SIZE,
      maxWidth: CATEGORY_CARD_MOBILE_SIZE,
      borderRadius: CATEGORY_CARD_MOBILE_SIZE * 2,
    },
    position: "relative",
  },
  genreImage: {
    maxHeight: CATEGORY_CARD_SIZE,
    maxWidth: CATEGORY_CARD_SIZE,
    borderRadius: CATEGORY_CARD_SIZE * 2,
    [theme.breakpoints.down("xs")]: {
      maxHeight: CATEGORY_CARD_MOBILE_SIZE,
      maxWidth: CATEGORY_CARD_MOBILE_SIZE,
      borderRadius: CATEGORY_CARD_MOBILE_SIZE * 2,
    },
    objectFit: "contain",
    boxShadow: `5px 5px 5px ${theme.palette.grey[400]}`,
  },
  genreTextContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  genreTextWrapper: {
    height: "100%",
    padding: theme.spacing(3),
  },
  genreText: {
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
    zIndex: 3,
    fontWeight: "bold",
    color: theme.palette.background.paper,
    textShadow: `2px 2px 2px ${theme.palette.grey[800]}`,
  },
  genreImageOverlay: {
    maxHeight: CATEGORY_CARD_SIZE,
    maxWidth: CATEGORY_CARD_SIZE,
    borderRadius: CATEGORY_CARD_SIZE * 2,
    [theme.breakpoints.down("xs")]: {
      maxHeight: CATEGORY_CARD_MOBILE_SIZE,
      maxWidth: CATEGORY_CARD_MOBILE_SIZE,
      borderRadius: CATEGORY_CARD_MOBILE_SIZE * 2,
    },
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.3)",
    zIndex: 2,
  },
}));

export default function HighlightedCategoryCard({ genre }) {
  const classes = useStyles();
  return (
    <Hyperlink to={`/stories/search?genres=${genre.code}`}>
      <Grid container className={classes.highlightedCategoryCard}>
        <div className={classes.genreImageOverlay}></div>
        <ImageFadeIn src={genre.image} className={classes.genreImage} />
        <Grid item xs={12} className={classes.genreTextContainer}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            className={classes.genreTextWrapper}
          >
            <Typography
              variant="h5"
              className={classes.genreText}
              align="center"
            >
              {genre.text}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Hyperlink>
  );
}
