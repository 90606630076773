import React from "react";
import BookRequestEditor from "./BookRequestEditor";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_BOOK_REQUEST } from "../../consts/mutations";
import { useDispatch } from "react-redux";
import { GET_BOOK_REQUEST } from "../../consts/queries";
import { actionTypes } from "../index.reducer";
import { useHistory, useParams } from "react-router-dom";
import { GENERIC_ALERT_ERROR_MESSAGE } from "../../config";
import ErrorPage from "../Error/ErrorPage";
import { useLoader, useQueryParams } from "../../utils/hooks";

export default function UpdateBookRequest() {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryParams();
  const groupScope = queryParams.get("groupScope");
  const { bookRequestId } = useParams();
  const {
    loading: fetchingBookRequest,
    error: fetchBookRequestError,
    data: bookRequestData,
  } = useQuery(GET_BOOK_REQUEST, { variables: { id: bookRequestId } });

  const [updateBookRequest, { loading: updatingBookRequest }] =
    useMutation(UPDATE_BOOK_REQUEST);
  useLoader(updatingBookRequest);

  const handleUpdateBookRequest = (bookRequest) => {
    updateBookRequest({
      variables: {
        bookRequest: {
          id: bookRequestId,
          ...bookRequest,
        },
      },
    })
      .then(() => {
        if (groupScope) {
          return history.push(`/group/${groupScope}/book-requests`);
        }
        history.push(`/book-request/${bookRequestId}`);
      })
      .catch(() => {
        dispatch({
          type: actionTypes.ERROR_MESSAGE,
          message: GENERIC_ALERT_ERROR_MESSAGE,
        });
      });
  };

  if (fetchBookRequestError) {
    return (
      <ErrorPage
        title="Something's not right!"
        description="We could not fetch the book request due to a problem. Please try later. Sorry for the inconvenience!"
        statusCode={500}
      />
    );
  }

  if (!bookRequestData) return null;
  const { bookRequest } = bookRequestData;

  if (!bookRequest) {
    return (
      <ErrorPage
        title="Not Found"
        description="The book request you were looking for does not exist!"
        statusCode={404}
      />
    );
  }
  return (
    <BookRequestEditor
      bookRequest={bookRequest}
      editorTitle="Edit Book Request"
      submitButtonText="Update Book Request"
      onSubmit={handleUpdateBookRequest}
      disabled={fetchingBookRequest || updatingBookRequest}
    />
  );
}
