import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import ChatGroupCard from "./ChatGroupCard";
import Banner from "../Common/Banner";
import { CHAT_GROUPS_PAGINATION_LIMIT } from "../../config";
import { GET_CHAT_GROUPS } from "../../consts/queries";
import { ChatGroupsLoader } from "../Common/LoadingPlaceholders/ChatGroupLoader";
import { useLoggedInUser, useRequireLogin } from "../../utils/hooks";
import { useQuery } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  errorBanner: {
    padding: theme.spacing(3),
  },
  chatGroupsList: {
    height: "80vh",
    overflow: "auto",
    [theme.breakpoints.down("xs")]: {
      height: "100vh",
    },
  },
  chatGroupCardContainer: {
    margin: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(1),
    },
  },
}));

export default function ChatGroupsSearchController({
  searchArgs,
  selectedThreadId,
  onClickChatGroupCard,
  xs,
  sm,
  md,
  lg,
  xl,
}) {
  const classes = useStyles();
  const loggedInUser = useLoggedInUser();
  const { loading, error, data, fetchMore } = useQuery(GET_CHAT_GROUPS, {
    variables: {
      ...searchArgs,
      pagination: {
        limit: CHAT_GROUPS_PAGINATION_LIMIT,
        offset: 0,
      },
    },
    skip: !loggedInUser,
  });

  useRequireLogin();

  const loadingPlaceholder = (
    <ChatGroupsLoader xs={xs} sm={sm} md={md} lg={lg} xl={xl} />
  );

  if (loading && !data) {
    return (
      <Grid container justifyContent="center">
        {loadingPlaceholder}
      </Grid>
    );
  }

  if (!data?.chatGroups) return null;

  const {
    chatGroups: { chatGroups, pagination },
  } = data;

  const loadMoreMessages = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: CHAT_GROUPS_PAGINATION_LIMIT,
          offset: chatGroups.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          chatGroups: {
            __typename: prev.chatGroups.__typename,
            chatGroups: [
              ...prev.chatGroups.chatGroups,
              ...fetchMoreResult.chatGroups.chatGroups,
            ],
            pagination: fetchMoreResult.chatGroups.pagination,
          },
        });
      },
    }).catch(() => {});
  };

  if (error)
    return (
      <Grid container justifyContent="center" className={classes.errorBanner}>
        <Grid item xs={12}>
          <Banner
            type="error"
            title="Something went wrong!"
            message="We're sorry! Something went wrong while displaying your chats! Please try again later."
          />
        </Grid>
      </Grid>
    );

  const noChatGroupsMessage = (
    <Grid item xs={12}>
      <Banner
        type="info"
        title="No Chats"
        message="No one has sent you any messages yet. Once someone sends you a chat message, they will appear here."
      />
    </Grid>
  );

  return (
    <Grid
      container
      item
      xs={12}
      id="chatGroupsContainer"
      className={classes.chatGroupsList}
    >
      {!chatGroups.length && noChatGroupsMessage}
      <InfiniteScroll
        style={{ overflow: "none !important" }}
        scrollableTarget="chatGroupsContainer"
        next={loadMoreMessages}
        hasMore={chatGroups.length < pagination.totalCount}
        loader={<ChatGroupsLoader xs={xs} md={md} lg={lg} xl={xl} />}
        dataLength={chatGroups.length}
      >
        <Grid item xs={12}>
          <Grid container>
            {chatGroups.map((chatGroup) => (
              <Grid item xs={12} className={classes.chatGroupCardContainer}>
                <ChatGroupCard
                  selected={
                    selectedThreadId && selectedThreadId === chatGroup.threadId
                  }
                  key={chatGroup.id}
                  chatGroup={chatGroup}
                  currentUserId={loggedInUser?.id}
                  onClick={onClickChatGroupCard}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </InfiniteScroll>
    </Grid>
  );
}

ChatGroupsSearchController.propTypes = {
  searchArgs: PropTypes.object,
  onClickChatGroupCard: PropTypes.func,
};

ChatGroupsSearchController.defaultProps = {
  searchArgs: {},
};
