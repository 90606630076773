import { t } from "@lingui/macro";
import { createFeedPostDataExtractor } from "./transformerUtils";

export default function StoryFeaturedTransformer(feedPost) {
  const extractor = createFeedPostDataExtractor(feedPost.primaryContent?.data);
  return {
    actorFullName: null,
    actorImage: feedPost.actorImage,
    activityText: t`⭐️ Featured Story`,
    resourcePath: `/story/${extractor.get("storyId")}`,
    primaryContent: {
      title: extractor.get("storyTitle"),
      content: extractor.get("storyAbstract"),
      images: feedPost.primaryContent?.images || [],
    },
    timestamp: feedPost.activityTimestamp,
  };
}
