import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useRef } from "react";
import StoryChaptersSearchController from "../StoryChapter/StoryChaptersSearchController";

const useStyles = makeStyles((theme) => ({
  chaptersContainer: {
    maxHeight: "50vh",
    overflow: "auto",
    overflowX: "hidden",
    padding: theme.spacing(1),
  },
  sidebar: {
    fontSize: "18px",
    fontWeight: "bold",
  },
}));

export default function ChapterSelection({ story, manageMode }) {
  const classes = useStyles();
  const chapterContainerRef = useRef();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              color="textSecondary"
              className={classes.sidebar}
            >
              Jump to chapter
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              className={classes.chaptersContainer}
              ref={chapterContainerRef}
            >
              <Grid item xs={12}>
                <StoryChaptersSearchController
                  manageMode={manageMode}
                  scrollableTarget={chapterContainerRef.current}
                  searchArgs={{ storyId: story.id }}
                  xs={12}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
