import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Snackbar, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  const alertContainerStyles = {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    cursor: "default",
    minWidth: "350px",
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
    alignItems: "center",
  };
  return {
    infoMessage: {
      ...alertContainerStyles,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    errorMessage: {
      ...alertContainerStyles,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.getContrastText(theme.palette.secondary.main),
    },
    snackbar: {
      zIndex: 999999999999,
      [theme.breakpoints.down("xs")]: {
        bottom: theme.spacing(8),
      },
    },
  };
});

export default function Alert({
  type,
  message,
  open,
  onClose,
  autoHideDuration,
}) {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    onClose();
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      onClick={onClose}
      className={classes.snackbar}
    >
      <Grid
        container
        className={
          type === "error" ? classes.errorMessage : classes.infoMessage
        }
        spacing={1}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle2">{message}</Typography>
        </Grid>
      </Grid>
    </Snackbar>
  );
}

Alert.propTypes = {
  type: PropTypes.oneOf(["success", "warning", "error", "info"]),
  message: PropTypes.string.isRequired,
  autoHideDuration: PropTypes.number,
};

Alert.defaultProps = {
  type: "info",
  autoHideDuration: 5000,
};
